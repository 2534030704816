<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						<!-- translated widget name -->
						{{ "CONTROLLED_DOCUMENTS.WIDGET_NAME" | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
					<!-- other buttons like config button -->
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<!-- body content -->
		<ag-grid-angular #agGrid style="height: 500px;width: 100%" class="ag-theme-balham" [enableSorting]="true"
		 [enableFilter]="true" [rowData]="rowData" [columnDefs]="columnDefs" [frameworkComponents]="components" rowSelection="multiple" [rowDeselection]="true"
		 [rowMultiSelectWithClick]="false" (cellClicked)="onCellClicked($event)" [enableColResize]="true" [floatingFilter]="true"
		 (gridReady)="onGridReady($event)" [pagination]="true" [paginationPageSize]="10" [suppressMenuHide]="true"
		 [suppressRowClickSelection]="true">
		</ag-grid-angular>
	</div>
</div>

<!-- popup templates -->
<!-- modal templates -->