import { Injectable } from '@angular/core';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { HttpErrorResponse, HttpClient, HttpEventType } from '@angular/common/http';
import { UserVariable } from 'src/app/util/common/user-variable';
import { HttpParams } from '@angular/common/http';
import { CommonUtil } from 'src/app/util/common/common-utils';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient, private commonUtil: CommonUtil) { }

  public uploadFile(fileUploadData: FormData, callBack: WsCallback) {
    var token = UserVariable.getUserToken();
    this.http.post(ServiceUrls.ADD_NEW_PUNCHLIST_DOCUMENT, fileUploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      event => {
        if (event.type == HttpEventType.UploadProgress) {
          //progress
        } else if (event.type == HttpEventType.Response) {
          var modified = JSON.parse(JSON.stringify(event.body));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          callBack.onSuccess(res, WsType.ADD_NEW_PUNCHLIST_DOCUMENT);
        }
      },
      error => {
        if (error.error.payload != undefined) {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          callBack.onFail(res, WsType.ADD_NEW_PUNCHLIST_DOCUMENT);
        } else {
          var res: WsResponse;
          if (error.error.actionErrors != undefined) {
            res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
          } else {
            res = new WsResponse(error.message);
          }
          callBack.onFail(res, WsType.ADD_NEW_PUNCHLIST_DOCUMENT);
        }
      }
    );
  }

  public downloadFile(outputId: string, punchlistId: string, projectId: string, contentType: string, fileName: string) {
    return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
       responseType: "blob", params: new HttpParams().
          set("documentId", outputId).set("punchlistId", punchlistId).set("projectId", projectId).set("type", contentType).set("mode", "ViewPunchlistDoc").set("fileName", fileName)
    });
 }

  public uploadFile_Images(fileUploadData: FormData, callBack: WsCallback) {
    var token = UserVariable.getUserToken();
    this.http.post(ServiceUrls.ADD_NEW_PUNCHLIST_IMAGE, fileUploadData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      event => {
        if (event.type == HttpEventType.UploadProgress) {
          //progress
        } else if (event.type == HttpEventType.Response) {
          var modified = JSON.parse(JSON.stringify(event.body));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          callBack.onSuccess(res, WsType.ADD_NEW_PUNCHLIST_IMAGE);
        }
      },
      error => {
        if (error.error.payload != undefined) {
          var val = (error as HttpErrorResponse).error;
          var modified = JSON.parse(JSON.stringify(val));
          var res = new WsResponse(
            modified.status.description,
            modified.status.code,
            modified.status.name,
            modified.payload);
          callBack.onFail(res, WsType.ADD_NEW_PUNCHLIST_IMAGE);
        } else {
          var res: WsResponse;
          if (error.error.actionErrors != undefined) {
            res = new WsResponse(error.statusText + ' - ' + error.error.actionErrors);
          } else {
            res = new WsResponse(error.message);
          }
          callBack.onFail(res, WsType.ADD_NEW_PUNCHLIST_IMAGE);
        }
      }
    );
  }
}
