<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						{{ 'STN.WIDGET_NAME' | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<!-- <i class="fa " [ngClass]="{'fa-chevron-down': isMinimized, 'fa-chevron-up': !isMinimized}" aria-hidden="true"></i> -->
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}" aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<div *ngIf="eventType == widgetEvent.SHOW_TABLE">
			<div class="row my-1">
				<div class="col-sm-6 ">					 
					 <ng-select [active]="selectedVendor" (removed)="removedVendor($event)" (selected)="selectedVendorId($event)"
					 [items]="vendorList" placeholder="{{ 'STN.SELECT_VENDOR' | translate }}" name="vendor" id="vendor">
					</ng-select>
					<!--<div class="col-sm-6 px-0 btn-group" dropdown>
						<button id="button-basic" dropdownToggle type="button" class="btn btn-outline-dark btn-sm dropdown-toggle" aria-controls="dropdown-basic">
							{{ selectedVendor[0].text }}
							<span class="caret float-right"></span>
						</button>
						<ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
							<li *ngFor="let item of vendorList" role="menuitem" (click)="selectedVendorId(item)"><p class="dropdown-item my-1">{{ item.text }}</p></li>
						</ul>
					</div>-->
				</div>
				<div class="col-sm-3 ">
				</div>
				<div class="col-sm-3">
					<button class="btn btn-primary btn-sm float-right" (click)="exportToExcel()">{{
						'STN.EXPORT_TO_EXCEL' | translate
						}}</button>
				</div>
			</div>
			<div class="my-2">
				<ag-grid-angular #agGrid style="width: 100%; height: 450px;" id="systemTurnoverNotice" [rowData]="rowData" class="ag-theme-balham"
				 [columnDefs]="columnDefs" [frameworkComponents]="components" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" [floatingFilter]="true"
				 [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true" (columnResized)="onColumnResized($event)"
				 (gridReady)="onGridReady($event)" (cellClicked)="onCellClick($event)"  [gridOptions]="gridOptions" [suppressMenuHide]="true"></ag-grid-angular>
			</div>
		</div>

		<div *ngIf="eventType == widgetEvent.SHOW_WIZARD">
			<div class="row">
				<button type="button" class="btn btn-primary btn-sm" (click)="backToTable()">
					<i class="fa fa-arrow-left mx-2" aria-hidden="true"></i>{{ 'STN.BACK_TO_TABLE' | translate }}
				</button>
			</div>
			<div class="row">
				<div class="col-sm-12 my-2">
					<aw-wizard class="custom-css">
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.stnStatus" class="badge mr-2" [ngClass]="getStyle(selectedItem.stnStatus)">{{getStatus(selectedItem.stnStatus)}}</span>
										<span *ngIf="selectedItem.ptnStatus" class="badge mr-2" [ngClass]="getStyle(selectedItem.ptnStatus)">{{getStatus(selectedItem.ptnStatus)}}</span>
										{{selectedItem.systemName}}
									</h6>
									<hr />
									<div>
										<p>{{ 'STN.WORKFLOW_TITLE' | translate }}
										</p>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck1" disabled [(ngModel)]="ch1">
												<label class="form-check-label" for="exampleCheck1">
													<p class="m-0 text-muted">{{ 'STN.CH1' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck2" disabled [(ngModel)]="ch2">
												<label class="form-check-label" for="exampleCheck2">
													<p class="m-0 text-muted">{{ 'STN.CH2' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck3" [(ngModel)]="ch3">
												<label class="form-check-label" for="exampleCheck3">
													<p class="m-0 text-muted">{{ 'STN.CH3' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck4" [(ngModel)]="ch4">
												<label class="form-check-label" for="exampleCheck4">
													<p class="m-0 text-muted">{{ 'STN.CH4' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck5" [(ngModel)]="ch5">
												<label class="form-check-label" for="exampleCheck5">
													<p class="m-0 text-muted">{{ 'STN.CH5' | translate }}</p>
												</label>
											</li>
											<!-- <li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck6" [(ngModel)]="ch6">
												<label class="form-check-label" for="exampleCheck6">
													<p class="m-0 text-muted">{{ 'STN.CH6' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck7" [(ngModel)]="ch7">
												<label class="form-check-label" for="exampleCheck7">
													<p class="m-0 text-muted">{{ 'STN.CH7' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck8" [(ngModel)]="ch8">
												<label class="form-check-label" for="exampleCheck8">
													<p class="m-0 text-muted">{{ 'STN.CH8' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck9" [(ngModel)]="ch9">
												<label class="form-check-label" for="exampleCheck9">
													<p class="m-0 text-muted">{{ 'STN.CH9' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck10" [(ngModel)]="ch10">
												<label class="form-check-label" for="exampleCheck10">
													<p class="m-0 text-muted">{{ 'STN.CH10' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck11" [(ngModel)]="ch11">
												<label class="form-check-label" for="exampleCheck11">
													<p class="m-0 text-muted">{{ 'STN.CH11' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck12" [(ngModel)]="ch12">
												<label class="form-check-label" for="exampleCheck12">
													<p class="m-0 text-muted">{{ 'STN.CH12' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck13" [(ngModel)]="ch13">
												<label class="form-check-label" for="exampleCheck13">
													<p class="m-0 text-muted">{{ 'STN.CH13' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck14" [(ngModel)]="ch14">
												<label class="form-check-label" for="exampleCheck14">
													<p class="m-0 text-muted">{{ 'STN.CH14' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck15" [(ngModel)]="ch15">
												<label class="form-check-label" for="exampleCheck15">
													<p class="m-0 text-muted">{{ 'STN.CH15' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck16" [(ngModel)]="ch16">
												<label class="form-check-label" for="exampleCheck16">
													<p class="m-0 text-muted">{{ 'STN.CH16' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck17" [(ngModel)]="ch17">
												<label class="form-check-label" for="exampleCheck17">
													<p class="m-0 text-muted">{{ 'STN.CH17' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck18" [(ngModel)]="ch18">
												<label class="form-check-label" for="exampleCheck18">
													<p class="m-0 text-muted">{{ 'STN.CH18' | translate }}</p>
												</label>
											</li> -->
										</ul>
										<p>{{ 'STN.SUMMARY' | translate }}</p>
										<table class="table" *ngIf="openPunchlists.length>0">
											<tr>
												<th>{{ 'STN.CATEGORY' | translate }}</th>
												<th>{{ 'STN.DESCRIPTION' | translate }}</th>
												<th>{{ 'STN.ASSET' | translate }}</th>
												<th>{{ 'STN.SYSTEM_SUBSYSTEM' | translate }}</th>
											</tr>
											<tr *ngFor="let item of openPunchlists">
												<td>{{ item.category }}</td>
												<td>{{ item.punchlistDescription }}</td>
												<td>{{ item.assetName }}</td>
												<td>{{ item.systemName }}</td>
											</tr>
										</table>
										<p *ngIf="!openPunchlists.length>0" class="m-0 text-muted">{{ 'STN.NO_PUNCHLISTS' | translate }}</p>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck19" [(ngModel)]="ch19">
												<label class="form-check-label" for="exampleCheck19">
													<p class="m-0 text-muted">{{ 'STN.REM_ITEMS' | translate }}</p>
												</label>
											</li>
										</ul>
										<p>{{ 'STN.COMMENTS' | translate }}</p>
										<textarea id="comments" name="comments" class="form-control" [(ngModel)]="comments"></textarea>
									</div>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-primary btn-sm" awNextStep>{{ 'STN.NEXT' |
									translate }}</button>
							</div>
						</aw-wizard-step>
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.stnStatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.stnStatus)}}</span>
										<span *ngIf="selectedItem.ptnStatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.ptnStatus)}}</span>
										{{selectedItem.systemName}}
									</h6>
									<hr />
									<form>
										<div class="row" *ngFor="let task of stnWorkflowTaskList; let i = index">
											<div class="col-sm-10 form-group">
												<label class="my-1 mx-0"><b>{{task.taskName}}</b></label>
												<div class="form-row">
													<div class="col">
														<app-custom-typeahead *ngIf="i==0" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee1 && (isSubmitted && i==0) ">
															Empty user
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail1 && (isSubmitted && i==0) ">
															Invalid email address
														</p>

														<app-custom-typeahead *ngIf="i==1" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee2 && (isSubmitted && i==1) ">
															Empty user
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail2 && (isSubmitted && i==1) ">
															Invalid email address
														</p>

														<app-custom-typeahead *ngIf="i==2" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee3 && (isSubmitted && i==2) ">
															Empty user
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail3 && (isSubmitted && i==2) ">
															Invalid email address
														</p>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-secondary btn-sm mx-2" (click)="previous()" awPreviousStep>{{
									'STN.PREVIOUS' | translate }}</button>
								<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">{{
									'STN.START' | translate }}</button>
							</div>
						</aw-wizard-step>
						<!-- <aw-wizard-step stepTitle="">
										<div class="card">
											<div class="card-body">
												This is some text within a card body. step 3
											</div>
										</div>
										<div class="my-2 float-right">
											<button type="button" class="btn btn-secondary btn-sm mx-2" awPreviousStep>Previous</button>
											<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">Finish</button>
										</div>
									</aw-wizard-step> -->
					</aw-wizard>
				</div>
			</div>
		</div>

		<div *ngIf="eventType == widgetEvent.SHOW_EDIT">
			<div class="row">
				<div class="col-sm-12 my-2">
					<h6 class="my-2">
						<span *ngIf="selectedItem.stnStatus" [ngClass]="getStyle(selectedItem.stnStatus)" class="badge mr-2">{{getStatus(selectedItem.stnStatus)}}</span>
						<span *ngIf="selectedItem.ptnStatus" [ngClass]="getStyle(selectedItem.ptnStatus)" class="badge mr-2">{{getStatus(selectedItem.ptnStatus)}}</span>
						{{selectedItem.systemName}}
					</h6>
					<hr />
					<div class="container">
						<div class="form-group">
							<div class="col-sm-6">
								<label>{{ 'STN.TARGET_COMPLETION_DATE' | translate }}</label>
								<input [(ngModel)]="targetCompletionDate" type="text" placeholder="Select target completion date" class="form-control is-invalid"
								 [bsConfig]="bsConfig" placement="top" bsDatepicker>
							</div>
						</div>
						<div class="form-group form-check">
							<div class="col-sm-6">
								<label class="container_checkbox">
									<input [(ngModel)]="required" type="checkbox" checked="checked" id="required">
									<span class="checkmark rounded"></span>
								</label>
								<!-- <input [(ngModel)]="required" type="checkbox" class="form-check-input" id="required">-->
								<label class="ml-2">{{ 'STN.REQUIRED' | translate }}</label>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-6">
								<p class="alert alert-danger mb-0 mt-1 py-1 px-4" role="alert" *ngIf="showRequiredErr && !isInputValid()">
									{{ 'STN.REQUIRED_FIELDS' | translate }}
								</p>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-6">
								<label>{{ 'STN.SYSTEM_PHASE' | translate }}</label>
								<ng-select [active]="selectedSystemPhaseVal" id="systemPhaseName" name="systemPhaseName" [items]="systemPhaseValues" 
								(selected)="systemPhaseSelected($event)">
								</ng-select>
							</div>
						</div>
						<div class="form-group my-2">
							<button type="button" class="btn btn-primary btn-sm mx-1 float-right" (click)="saveSTN()">{{
								'STN.SAVE' | translate }}</button>
							<button type="button" class="btn btn-secondary btn-sm mx-2 float-right" (click)="backToTable()">{{
								'STN.CANCEL'
								| translate
								}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<ng-template #deleteSTNModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'STN.REVOKE_STN_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'STN.REVOKE_STN_MSG' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="delete()">{{ 'STN.YES' | translate }}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancel()">{{ 'STN.NO' | translate }}</button>
		</div>
	</div>
</ng-template>
<ng-template #incompleteSTNModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'STN.INCOMPLETE_STN_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="incompleteModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'STN.INCOMPLETE_STN_MSG' | translate }} </p>
		<p> {{ 'STN.ASK_TO_CONTINUE' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="continue()">{{ 'STN.CONTINUE' | translate
				}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancelWorkflow()">{{ 'STN.CANCEL' | translate }}</button>
		</div>
	</div>
</ng-template>
<ng-template #editSTNModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'STN.DELETE_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'STN.DELETE_MSG' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="deleteSTN()">{{ 'STN.YES' | translate }}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancelDeleteSTN()">{{ 'STN.CANCEL' | translate }}</button>
		</div>
	</div>
</ng-template>