import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid';
import { AlertService } from 'src/app/util/alert/alert.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { AdhocReportsWidgetService } from './adhoc-reports-widget.service';
import { AdhocReportsCellRenderComponent } from './cell-render/adhoc-reports-cell-render';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-adhoc-reports-widget',
    templateUrl: './adhoc-reports-widget.component.html',
    styleUrls: ['./adhoc-reports-widget.component.css']
})
export class AdhocReportsWidgetComponent implements OnInit {

    public isMinimized: boolean = false;
    public gridOptions: GridOptions;
    public rowData: any[] = []
    public columnDefs: any;
    public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;

    private gridApi;
    private gridColumnApi;
    private adhocreports_translated_data: any = null;

    constructor(
        private translate: TranslateService,
        private adhocReportsService: AdhocReportsWidgetService,
		private alertService: AlertService,
		private loadingService: LoadingService
    ) {

        this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};

       


        let browserLang = translate.getBrowserLang();
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.adhocreports_translated_data = res['ADHOC_REPORTS']
            this.columnDefs = [
                { headerName: this.adhocreports_translated_data.REPORT_NAME, field: 'reportName', autoHeight: true, cellClass: "cell-wrap-text", width: 500, filter: "agTextColumnFilter" },
                { headerName: this.adhocreports_translated_data.CATEGORY, field: 'category', autoHeight: true, cellClass: "cell-wrap-text", width: 180 },
                { headerName: this.adhocreports_translated_data.VIEW_PDF, field: 'pdf', autoHeight: true, cellClass: "cell-wrap-text", cellStyle: { 'text-align': 'center' }, width: 120, suppressFilter: true, cellRendererFramework: AdhocReportsCellRenderComponent },
                { headerName: this.adhocreports_translated_data.VIEW_XLSX, field: 'xlsx', autoHeight: true, cellClass: "cell-wrap-text", cellStyle: { 'text-align': 'center' }, width: 120, suppressFilter: true, cellRendererFramework: AdhocReportsCellRenderComponent },
                { headerName: this.adhocreports_translated_data.VIEW_CSV, field: 'csv', autoHeight: true, cellClass: "cell-wrap-text", cellStyle: { 'text-align': 'center' }, width: 120, suppressFilter: true, cellRendererFramework: AdhocReportsCellRenderComponent },
                { headerName: this.adhocreports_translated_data.VIEW_HTML, field: 'html', autoHeight: true, cellClass: "cell-wrap-text", cellStyle: { 'text-align': 'center' }, width: 120, suppressFilter: true, cellRendererFramework: AdhocReportsCellRenderComponent }
            ];
        });
        
        //custom layout templates for loading and empty data sets
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
        this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
    }

    ngOnInit() {

    }

    onGridReady(params) {
		this.gridApi = params.api;
        this.refresh();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }

    public refresh() {
		this.gridApi.showLoadingOverlay();
        this.adhocReportsService.getAllReportDesignsByModuleAndProjectId("Dashboard/Commissioning")
            .subscribe(data => {
                if (data.payload) {
					var rowData = [];
					var payload = data.payload;

                    for (let i = 0; i < payload.length; i++) {
                        rowData.push({
                            id: payload[i]["id"],
                            reportName: payload[i]["reportName"],
                            category: payload[i]["category"]
                        });
                    }
					this.rowData = rowData;
                }
            },
            error => {
                console.log('[getAllReportDesignsByModuleAndProjectId] (error) ' + JSON.stringify(error))
                this.alertService.error(error.statusDescription);
            });
    }

    public viewReport(reportId: string, reportName: string, reportType: string) {
		this.loadingService.showLoading(false, false, '', 0)
        this.adhocReportsService.downloadPDF(reportId, reportType)
            .subscribe(response => {
                this.loadingService.hideLoading();
                let file;
				if (reportType == "html") {
					file = new Blob([response], { type: 'text/html'});
				}
				else if (reportType == "xlsx") {
					file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
				}
				else if (reportType == "csv") {
					file = new Blob([response], { type: 'text/csv'});
				}
				else if (reportType == "pdf") {
					file = new Blob([response], { type: 'application/pdf'});
				}
                else {
                    file = new Blob([response], { type: 'application/' +  reportType});
                }
				var fileURL = window.URL.createObjectURL(file);

				if (reportType == "html") {
					window.open(fileURL, '_blank', '');
				}
				else {
					FileSaver.saveAs(file, reportName + "." + reportType);
				}

            },
            error => {
                this.loadingService.hideLoading()
                this.alertService.clear()
                this.alertService.error(this.translate.instant('ADHOC_REPORTS.ERROR_VIEW_REPORT'));
            });
	}

}

