import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LinkyModule } from 'angular-linky';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule, HIGHCHARTS_MODULES, Highcharts } from 'angular-highcharts';
import exporting from '../../node_modules/highcharts/modules/exporting.src.js'
import { AgGridModule } from 'ag-grid-angular';
import { SelectModule } from 'ng2-select';
import { ArchwizardModule } from 'angular-archwizard';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ModalModule } from 'ngx-bootstrap/modal';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TagInputModule } from 'ngx-chips';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SplashComponent } from './splash/splash.component';

import { AppComponent } from './app.component';
import { LoginComponent } from './user/login/login.component';
import { ChangepasswordComponent } from './user/login/changepassword/changepassword.component';
import { KeycodeverificationComponent } from './user/login/keycodeverification/keycodeverification.component';
import { AppRoutingModule } from './app-routing.module';
import { AlertComponent } from './util/alert/alert.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Sample2chartComponent } from './widgets/sample2chart/sample2chart.component';
import { LoadingComponent } from './util/loading/loading.component'
import { WsCallback } from './util/ws-callback.interface';
import { WsType } from './util/ws-type';
import { CommonUtil } from './util/common/common-utils'

// service
import { AlertService } from './util/alert/alert.service';
import { LoadingService } from './util/loading/loading.service';
import { SharedService } from './util/shared.service';
import { WidgetService } from './widgets/widget.service';
import { InspectionReleaseNoticeService } from './widgets/inspection-release-notice/inspection-release-notice.service'
import { SystemHandoverWidgetService } from './widgets/system-handover-widget/system-handover-widget.service';
import { PunchlistService } from './widgets/punchlist.service';
import { OpenWorkItemsService } from './widgets/my-open-workitems.service';
import { SystemTurnoverNoticeService } from './widgets/system-turnover-notice/system-turnover-notice.service';
import { LocalStorage } from './util/localstorage.service';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider } from "angular-6-social-login-v2";
import { Constants } from './util/constants';
import { BasicRequest } from './util/basic-request';
import { SessionValidationService } from './user/login/session-validation-request.service';
import { ResetUrlService } from './user/login/forgotpassword/reset-url.service';
import { Keycodeverify } from './user/login/keycodeverification/keycodeverify.service';
import { ResetPasswordValidation } from './user/login/forgotpassword/reset-password-validation.service';
import { WorkflowService } from './widgets/workflow.service'
import { UserService } from './widgets/user.service'
import { SystemService } from './widgets/system.service'
import { ProjectService } from './widgets/project.service'
import { MechanicalCompletionService } from './widgets/mechanical-completion.service'
import { FacilityAcceptanceService } from './widgets/facility-acceptance/facility-acceptance.service';
import { CheckSheetService } from './widgets/checksheet.service';
import { CapabilityService } from './util/capability.service';
import { PrecommissioningCompletionService } from './widgets/precommissioning-completion.service'
import { CommCompletionService } from './widgets/comm-completion.service'
import { TopicmapService } from './widgets/topicmap.service'
import { PageNotFoundComponent } from './util/page-not-found/page-not-found.component';

import { SidebarComponent } from './sidebar/sidebar.component';
import { NotificationComponent } from './header/notification/notification.component';
import { ForgotpasswordComponent } from './user/login/forgotpassword/forgotpassword.component';
import { SamplepiechartComponent } from './widgets/samplepiechart/samplepiechart.component';
import { SamplebarchartComponent } from './widgets/samplebarchart/samplebarchart.component';
import { InspectionReleaseNoticeComponent } from './widgets/inspection-release-notice/inspection-release-notice.component';
import { PunchlistBySystemWidgetComponent } from './widgets/punchlist-by-system-widget/punchlist-by-system-widget.component'

import { DecodeHtmlString } from './util/common/decode-html-string.pipe'

//ngx modules
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap';

import { MechanicalCompletionWidgetComponent } from './widgets/mechanical-completion-widget/mechanical-completion-widget.component';
import { ResetPassword } from './user/login/forgotpassword/reset-password.service';
import { PasswordChangeStatusComponent } from './user/login/changepassword/password-change-status/password-change-status.component';
import { SystemTurnoverNoticeComponent } from './widgets/system-turnover-notice/system-turnover-notice.component';
import { CustomTypeaheadComponent } from './util/custom-typeahead/custom-typeahead.component';
import { PunchlistDetailWidgetComponent } from './widgets/punchlist-detail-widget/punchlist-detail-widget.component';
import { StnptnRundownWidgetComponent } from './widgets/stnptn-rundown-widget/stnptn-rundown-widget.component';
import { StnptnRundownWidgetService } from './widgets/stnptn-rundown-widget/stnptn-rundown-widget.service';
import { LandingComponent } from './landing/landing.component';
import { RecentProject } from './landing/recent-project.service';
import { LandingService } from './landing/landing.service';
import { WorkflowApprovalComponent } from './workflow-approval/workflow-approval.component';
import { WorkflowApprovalService } from './workflow-approval/workflow-approval.service';
import { OpenWorkItemsComponent } from './widgets/open-work-items/open-work-items.component';
import { LicenseManager } from "ag-grid-enterprise/main";
import { McChecksheetRundownWidgetComponent } from './widgets/mc-checksheet-rundown-widget/mc-checksheet-rundown-widget.component';
import { ControlledDocumentsWidgetComponent } from './widgets/controlled-documents-widget/controlled-documents-widget.component';
import { SystemHandoverWidgetComponent } from './widgets/system-handover-widget/system-handover-widget.component';
import { MccBehindScheduledWidgetComponent } from './widgets/mcc-behind-scheduled-widget/mcc-behind-scheduled-widget.component';
import { MechanicalCompletionDetailWidgetComponent } from './widgets/mechanical-completion-detail-widget/mechanical-completion-detail-widget.component';
import { McSystemSkylineWidgetComponent } from './widgets/mc-system-skyline-widget/mc-system-skyline-widget.component';
import { FacilityAcceptanceComponent } from './widgets/facility-acceptance/facility-acceptance.component';
import { EditButtonRenderer } from './widgets/mechanical-completion-widget/cell-renderer/edit-button/edit-button.component'
import { PrecommissioningSystemSkylineWidgetComponent } from './widgets/precommissioning-system-skyline-widget/precommissioning-system-skyline-widget.component'
import { DeleteButtonRenderer } from './widgets/mechanical-completion-widget/cell-renderer/delete-button/delete-button.component'
import { StartWorkflowRenderer } from './widgets/mechanical-completion-widget/cell-renderer/start-workflow/start-workflow.component'
import { FileIconRenderer } from './widgets/mechanical-completion-widget/cell-renderer/file-icon/approved-file-icon.component'
import { SentFileIconRenderer } from './widgets/mechanical-completion-widget/cell-renderer/file-icon/sent-file-icon.component';
import { CheckSheetSentFileIconRenderer } from './widgets/common-cell-renderer/file-icon/checksheet-sent-file-icon.component'
import { CheckSheetApprovedFileIconRenderer } from './widgets/common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import { PrecommissioningDetailWidgetComponent } from './widgets/precommissioning-detail-widget/precommissioning-detail-widget.component';
import { PrecommissioningChecksheetRundownWidgetComponent } from './widgets/precommissioning-checksheet-rundown-widget/precommissioning-checksheet-rundown-widget.component'
import { PrecommissioningEditButtonRenderer } from './widgets/precommissioning-detail-widget/cell-renderer/edit-button/edit-button.component'
import { PrecommissioningDeleteButtonRenderer } from './widgets/precommissioning-detail-widget/cell-renderer/delete-button/delete-button.component'
import { PrecommissioningSentFileIconRenderer } from './widgets/precommissioning-detail-widget/cell-renderer/file-icon/sent-file-icon.component';
import { PrecommissioningFileIconRenderer } from './widgets/precommissioning-detail-widget/cell-renderer/file-icon/approved-file-icon.component';
import { PrecommissioningStartWorkflowRenderer } from './widgets/precommissioning-detail-widget/cell-renderer/start-workflow/start-workflow.component';
import { WorkItemSkylineWidgetComponent } from './widgets/work-item-skyline-widget/work-item-skyline-widget.component';
import { CommCompletionWidgetComponent } from './widgets/comm-completion-widget/comm-completion-widget.component';
import { CommCompletionEditButtonRenderer } from './widgets/comm-completion-widget/cell-renderer/edit-button/edit-button.component'
import { CommCompletionDeleteButtonRenderer } from './widgets/comm-completion-widget/cell-renderer/delete-button/delete-button.component'
import { CommCompletionSentFileIconRenderer } from './widgets/comm-completion-widget/cell-renderer/file-icon/sent-file-icon.component';
import { CommCompletionFileIconRenderer } from './widgets/comm-completion-widget/cell-renderer/file-icon/approved-file-icon.component';
import { CommCompletionStartWorkflowRenderer } from './widgets/comm-completion-widget/cell-renderer/start-workflow/start-workflow.component';
import { ChecksheetsWidgetComponent } from './widgets/checksheets-widget/checksheets-widget.component';
import { ProceduresInStoreCountWidgetComponent } from './widgets/procedures-in-store-count-widget/procedures-in-store-count-widget.component';
import { ProceduresInTheStoreWidgetComponent } from './widgets/procedures-in-the-store-widget/procedures-in-the-store-widget.component';
import { WorkItemSkylineWidgetCellRenderComponent } from './widgets/work-item-skyline-widget/cell-renderer/work-item-skyline-widget-cell-render.component';
import { ProceduresInstancesWidgetComponent } from './widgets/procedures-instances-widget/procedures-instances-widget.component';
import { PunchlistPiechartComponent } from './widgets/punchlist-open-punchlist/punchlist-open-punchlist.component';
import { WIEditButtonRenderer } from './widgets/work-item-skyline-widget/cell-renderer/edit-button/edit-button.component';
import { WIAddButtonRenderer } from './widgets/work-item-skyline-widget/cell-renderer/add-button/add-button.component';
import { WIDeleteButtonRenderer } from './widgets/work-item-skyline-widget/cell-renderer/delete-button/delete-button.component';
import { ProceduresExecutedByTimeWidgetComponent } from './widgets/procedures-executed-by-time-widget/procedures-executed-by-time-widget.component';
import { PunchlistItemComponent } from './widgets/punchlist-item/punchlist-item.component';
import { PunchlistItemsPerCategoryComponent } from './widgets/punchlist-items-per-category/punchlist-items-per-category.component';
import { ProceduresExecutedCountWidgetComponent } from './widgets/procedures-executed-count-widget/procedures-executed-count-widget.component';
import { MyOpenWorkItemCountComponent } from './widgets/my-open-work-item-count/my-open-work-item-count.component';
import { DocumentationTagCountComponent } from './widgets/documentation-tag-count/documentation-tag-count.component';
import { OpenWorkItemsPiechartComponent } from './widgets/work-items-piechart/work-items-piechart.component';
import { OpenWorkItemsPiechartService } from './widgets/work-items-piechart/work-items-piechart.service';
import { DocumentationUniqueStatementCountComponent } from './widgets/documentation-unique-statement-count/documentation-unique-statement-count.component';
import { DocumentationTotalRevisionsComponent } from './widgets/documentation-total-revisions/documentation-total-revisions.component';
import { DocumentationPIdComponent } from './widgets/documentation-p-id/documentation-p-id.component';
import { PccBehindScheduledWidgetComponent } from './widgets/pcc-behind-scheduled-widget/pcc-behind-scheduled-widget.component';
import { OWAddButtonRenderer } from './widgets/open-work-items/cell-renderer/add-button/add-button.component';
import { OWEditButtonRenderer } from './widgets/open-work-items/cell-renderer/edit-button/edit-button.component';
import { OWDeleteButtonRenderer } from './widgets/open-work-items/cell-renderer/delete-button/delete-button.component';
import { OpenWorkItemCellRenderComponent } from './widgets/open-work-items/open-work-item-cell-render.component';
import { PunchListImageIconRenderer } from './widgets/punchlist-detail-widget/cell-renderer/image-icon/image-icon.component';
import { PunchListLocationIconRenderer } from './widgets/punchlist-detail-widget/cell-renderer/location-icon/location-icon.component';
import { PunchListDocumentIconRenderer } from './widgets/punchlist-detail-widget/cell-renderer/document-icon/document-icon.component';
import { DownloadAllIconRenderer } from './widgets/facility-acceptance/header/download-all.component';
import { CustomDateComponent} from './custom-date-component.component';
import { CommCompletionDocuSignRenderer } from './widgets/comm-completion-widget/cell-renderer/comm-completion-docu-sign/comm-completion-docu-sign.component';
import { SystemTurnoverNoticeDocuSignRenderer } from './widgets/system-turnover-notice/system-turnover-notice-docu-sign/system-turnover-notice-docu-sign.component';
import { PrecommissioningDocuSignRenderer } from './widgets/precommissioning-detail-widget/cell-renderer/precommissioning-docu-sign/precommissioning-docu-sign.component';
import { MechanicalCompletionDocuSignRenderer } from './widgets/mechanical-completion-widget/cell-renderer/mechanical-completion-docu-sign/mechanical-completion-docu-sign.component';
import { DocusignPesonStatusComponent } from './widgets/docusign-peson-status/docusign-peson-status.component';
import {SystemCompletionStatusWidgetComponent} from './widgets/system-completion-status-widget/system-completion-status-widget.component';
import { StatusEditor } from "./widgets/system-completion-status-widget/status/status-editor.component";
import { StatusRenderer } from "./widgets/system-completion-status-widget/status/status-renderer.component";

import { PunchlistDetailsBySystemComponent } from './widgets/punchlist-by-system-widget/cell-renderer/punchlist-details-by-system.component';

import { MCCFileDownloadRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/mcc.component";
import { SHCFileDownloadRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/shc.component";
import { STNPTNFileDownloadRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/stn-ptn.component";
import { CheckSheetSentFileDownloadRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/checksheet-sent.component";
import { CheckSheetRecievedFileDownloadRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/checksheet-recieved.component";
import { PunchListDocumentDownloadRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/punchlist-document-cell-renderer.component";
import { IRNRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/irn.component";
import { OTNRenderer } from "./widgets/punchlist-by-system-widget/cell-renderer/otn.component";
import { PunchlistDetailsRenderer } from "./widgets/inspection-release-notice/cell-renderer/punchlist-details.component";
import { PLDocumentDownloadRenderer } from "./widgets/inspection-release-notice/cell-renderer/punchlist-document.component";
import { ProfileComponent } from './header/profile/profile.component';
import { Auth0Component } from './user/login/auth0/auth0.component';
import { TopicMapsInStoreCountWidgetComponent } from "./widgets/topicmaps-in-store-count-widget/topicmaps-in-store-count-widget.component";
import { TopicMapsInTheStoreWidgetComponent } from './widgets/topicmaps-in-the-store-widget/topicmaps-in-the-store-widget.component';
import { FacilitiesCellRender } from './widgets/topicmaps-in-the-store-widget/cell-render/facilitiescellrender.component';
import { OnshoreTurnoverNoticeWidgetComponent } from './widgets/onshore-turnover-notice-widget/onshore-turnover-notice-widget.component';
import { ManageCellRenderComponent } from './widgets/onshore-turnover-notice-widget/cell-render/manage-cell-render/manage-cell-render.component';
import { DeleteCellComponent } from './widgets/onshore-turnover-notice-widget/cell-render/delete-cell/delete-cell.component';
import { ReturnedComponent } from './widgets/onshore-turnover-notice-widget/cell-render/returned/returned.component';
import { SentComponent } from './widgets/onshore-turnover-notice-widget/cell-render/sent/sent.component';
import { BulkDataImportWidgetComponent } from './widgets/bulk-data-import-widget/bulk-data-import-widget.component';
import { AddeditotnComponent } from './widgets/onshore-turnover-notice-widget/addeditotn/addeditotn.component';
import { AddpunchlistComponent } from './widgets/punchlist-detail-widget/addpunchlist/addpunchlist.component';
import { PunchlistDocuSignCellRenderer } from './widgets/punchlist-detail-widget/cell-renderer/punchlist-docu-sign/punchlist-docu-sign.component';
import { OnshoreTurnoverNoticeDocuSignRenderer } from './widgets/onshore-turnover-notice-widget/cell-render/onshore-turnover-notice-docu-sign/onshore-turnover-notice-docu-sign.component.js';
import { DependenciesModalComponent } from './util/dependenciesmodal/dependenciesmodal.component';
import { PunchlistsCellRenderComponent } from './widgets/punchlist-detail-widget/cell-renderer/punchlists-cell-render-component.component';
import { PunchlistCellRenderComponent } from './widgets/punchlist-by-system-widget/cell-renderer/punchlist-cell-render-component.component';

import { EditpunchlistComponent } from './widgets/punchlist-detail-widget/cell-renderer/editpunchlist/editpunchlist.component';
import { AdhocReportsWidgetComponent } from './widgets/adhoc-reports-widget/adhoc-reports-widget.component';
import { AdhocReportsCellRenderComponent } from './widgets/adhoc-reports-widget/cell-render/adhoc-reports-cell-render';
import { OtnRundownWidgetComponent } from './widgets/otn-rundown-widget/otn-rundown-widget.component';
import { IrnRundownWidgetComponent } from './widgets/irn-rundown-widget/irn-rundown-widget.component';

LicenseManager.setLicenseKey("Axis_Limited_Liability_Company__Symbiosis_1Devs9_August_2019__MTU2NTMwNTIwMDAwMA==05ee934c9692ee00e78759c773a7218c");
exporting(Highcharts);
export function HttpLoaderFactory(HttpClient: HttpClient) {
	return new TranslateHttpLoader(HttpClient, "./assets/i18n/", ".json");
}

export function getAuthServiceConfigs() {
	let config = new AuthServiceConfig(
		[
			{
				id: GoogleLoginProvider.PROVIDER_ID,
				provider: new GoogleLoginProvider(localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID))
			}
		]
	);
	return config;
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ChangepasswordComponent,
		KeycodeverificationComponent,
		AlertComponent,
		HeaderComponent,
		DashboardComponent,
		Sample2chartComponent,
		LoadingComponent,
		SidebarComponent,
		PunchlistDetailWidgetComponent,
		NotificationComponent,
		ForgotpasswordComponent,
		SamplepiechartComponent,
		SamplebarchartComponent,
		PunchlistPiechartComponent,
		InspectionReleaseNoticeComponent,
		MechanicalCompletionWidgetComponent,
		PasswordChangeStatusComponent,
		SystemTurnoverNoticeComponent,
		CustomTypeaheadComponent,
		PunchlistDetailWidgetComponent,
		StnptnRundownWidgetComponent,
		LandingComponent,
		PageNotFoundComponent,
		McChecksheetRundownWidgetComponent,
		ControlledDocumentsWidgetComponent,
		SystemHandoverWidgetComponent,
		MccBehindScheduledWidgetComponent,
		WorkflowApprovalComponent,
		MechanicalCompletionDetailWidgetComponent,
		McSystemSkylineWidgetComponent,
		FacilityAcceptanceComponent,
		EditButtonRenderer,
		PrecommissioningSystemSkylineWidgetComponent,
		StartWorkflowRenderer,
		DeleteButtonRenderer,
		FileIconRenderer,
		SentFileIconRenderer,
		CheckSheetSentFileIconRenderer,
		CheckSheetApprovedFileIconRenderer,
		PrecommissioningDetailWidgetComponent,
		PrecommissioningEditButtonRenderer,
		PrecommissioningStartWorkflowRenderer,
		PrecommissioningDeleteButtonRenderer,
		PrecommissioningFileIconRenderer,
		PrecommissioningSentFileIconRenderer,
		WorkItemSkylineWidgetComponent,
		OpenWorkItemsComponent,
		PrecommissioningChecksheetRundownWidgetComponent,
		CommCompletionWidgetComponent,
		CommCompletionEditButtonRenderer,
		CommCompletionStartWorkflowRenderer,
		CommCompletionDeleteButtonRenderer,
		CommCompletionFileIconRenderer,
		CommCompletionSentFileIconRenderer,
		ChecksheetsWidgetComponent,
		BulkDataImportWidgetComponent,
		ProceduresInStoreCountWidgetComponent,
		ProceduresInTheStoreWidgetComponent,
		ProceduresInstancesWidgetComponent,
		WorkItemSkylineWidgetCellRenderComponent,
		PunchlistPiechartComponent,
		WIEditButtonRenderer,
		WIAddButtonRenderer,
		WIDeleteButtonRenderer,
		ProceduresExecutedByTimeWidgetComponent,
		PunchlistItemComponent,
		ProceduresExecutedCountWidgetComponent,
		PunchlistItemsPerCategoryComponent,
		DocumentationTagCountComponent,
		MyOpenWorkItemCountComponent,
		OpenWorkItemsPiechartComponent,
		DocumentationUniqueStatementCountComponent,
		DocumentationTotalRevisionsComponent,
		DocumentationPIdComponent,
		PccBehindScheduledWidgetComponent,
		DecodeHtmlString,
		OWAddButtonRenderer,
		OWEditButtonRenderer,
		OWDeleteButtonRenderer,
		OpenWorkItemCellRenderComponent,
		PunchListImageIconRenderer,
		PunchListLocationIconRenderer,
		PunchListDocumentIconRenderer,
      	DownloadAllIconRenderer,
	  	SplashComponent,
	  	CustomDateComponent,
	  	CommCompletionDocuSignRenderer,
	  	SystemTurnoverNoticeDocuSignRenderer,
	  	PrecommissioningDocuSignRenderer,
	  	MechanicalCompletionDocuSignRenderer,
		DocusignPesonStatusComponent,
		PunchlistBySystemWidgetComponent,
		SystemCompletionStatusWidgetComponent,
		StatusEditor,
		StatusRenderer,
		PunchlistDetailsBySystemComponent,
		StatusRenderer,
		MCCFileDownloadRenderer,
		SHCFileDownloadRenderer,
		STNPTNFileDownloadRenderer,
		CheckSheetSentFileDownloadRenderer,
		CheckSheetRecievedFileDownloadRenderer,
		PunchListDocumentDownloadRenderer,
		IRNRenderer,
		OTNRenderer,
		PunchlistDetailsRenderer,
		PLDocumentDownloadRenderer,
		ProfileComponent,
		TopicMapsInStoreCountWidgetComponent,
		TopicMapsInTheStoreWidgetComponent,
		FacilitiesCellRender,
		Auth0Component,
		OnshoreTurnoverNoticeWidgetComponent,
		ManageCellRenderComponent,
		DeleteCellComponent,
		ReturnedComponent,
		SentComponent,
		AddpunchlistComponent,
		BulkDataImportWidgetComponent,
		OnshoreTurnoverNoticeDocuSignRenderer,
		PunchlistDocuSignCellRenderer,
		AddeditotnComponent,
		DependenciesModalComponent,
		PunchlistsCellRenderComponent,
		EditpunchlistComponent,
		PunchlistsCellRenderComponent,
		PunchlistCellRenderComponent,
		AdhocReportsWidgetComponent,
		AdhocReportsCellRenderComponent,
		OtnRundownWidgetComponent,
		IrnRundownWidgetComponent
	],
	imports: [
		BrowserModule,
		NgbModule.forRoot(),
		PopoverModule.forRoot(),
		TypeaheadModule.forRoot(),
		BsDatepickerModule.forRoot(),
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		HighchartsChartModule,
		ChartModule,
		AgGridModule.withComponents([WorkItemSkylineWidgetCellRenderComponent, CustomDateComponent,StatusEditor,StatusRenderer]),
		ArchwizardModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		SocialLoginModule,
		SelectModule,
		SignaturePadModule,
		TagInputModule,
		BrowserAnimationsModule,
		DeviceDetectorModule.forRoot(),
		LinkyModule,
		ReactiveFormsModule
	],
	entryComponents: [
		Sample2chartComponent,
		SamplebarchartComponent,
		SamplepiechartComponent,
		PunchlistPiechartComponent,
		InspectionReleaseNoticeComponent,
		MechanicalCompletionWidgetComponent,
		SystemTurnoverNoticeComponent,
		PunchlistDetailWidgetComponent,
		StnptnRundownWidgetComponent,
		McChecksheetRundownWidgetComponent,
		ControlledDocumentsWidgetComponent,
		SystemHandoverWidgetComponent,
		OpenWorkItemsComponent,
		MccBehindScheduledWidgetComponent,
		MechanicalCompletionDetailWidgetComponent,
		McSystemSkylineWidgetComponent,
		FacilityAcceptanceComponent,
		EditButtonRenderer,
		PrecommissioningSystemSkylineWidgetComponent,
		StartWorkflowRenderer,
		DeleteButtonRenderer,
		FileIconRenderer,
		SentFileIconRenderer,
		CheckSheetSentFileIconRenderer,
		CheckSheetApprovedFileIconRenderer,
		PrecommissioningDetailWidgetComponent,
		PrecommissioningEditButtonRenderer,
		PrecommissioningStartWorkflowRenderer,
		PrecommissioningDeleteButtonRenderer,
		PrecommissioningFileIconRenderer,
		PrecommissioningSentFileIconRenderer,
		WorkItemSkylineWidgetComponent,
		PrecommissioningChecksheetRundownWidgetComponent,
		CommCompletionWidgetComponent,
		CommCompletionEditButtonRenderer,
		CommCompletionStartWorkflowRenderer,
		CommCompletionDeleteButtonRenderer,
		CommCompletionFileIconRenderer,
		CommCompletionSentFileIconRenderer,
		ChecksheetsWidgetComponent,
		BulkDataImportWidgetComponent,
		ProceduresInStoreCountWidgetComponent,
		ProceduresInTheStoreWidgetComponent,
		WorkItemSkylineWidgetCellRenderComponent,
		WIEditButtonRenderer,
		WIAddButtonRenderer,
		WIDeleteButtonRenderer,
		ProceduresInstancesWidgetComponent,
		ProceduresExecutedByTimeWidgetComponent,
		PunchlistItemComponent,
		PunchlistItemsPerCategoryComponent,
		MyOpenWorkItemCountComponent,
		ProceduresExecutedCountWidgetComponent,
		DocumentationTagCountComponent,
		DocumentationUniqueStatementCountComponent,
		OpenWorkItemsPiechartComponent,
		DocumentationTotalRevisionsComponent,
		DocumentationPIdComponent,
		PccBehindScheduledWidgetComponent,
		OWAddButtonRenderer,
		OWEditButtonRenderer,
		OWDeleteButtonRenderer,
		OpenWorkItemCellRenderComponent,
		PunchListImageIconRenderer,
		PunchListLocationIconRenderer,
		PunchListDocumentIconRenderer,
		DownloadAllIconRenderer,
		CustomDateComponent,
		CommCompletionDocuSignRenderer,
		SystemTurnoverNoticeDocuSignRenderer,
		PrecommissioningDocuSignRenderer,
		MechanicalCompletionDocuSignRenderer,
		PunchlistBySystemWidgetComponent,
		SystemCompletionStatusWidgetComponent,
		StatusEditor,
		StatusRenderer,
		MCCFileDownloadRenderer,
		SHCFileDownloadRenderer,
		STNPTNFileDownloadRenderer,
		PunchlistDetailsBySystemComponent,
		CheckSheetSentFileDownloadRenderer,
		CheckSheetRecievedFileDownloadRenderer,
		PunchListDocumentDownloadRenderer,
		IRNRenderer,
		OTNRenderer,
		PunchlistDetailsRenderer,
		PLDocumentDownloadRenderer,
		TopicMapsInStoreCountWidgetComponent,
		TopicMapsInTheStoreWidgetComponent,
		FacilitiesCellRender,
		AddpunchlistComponent,
		OnshoreTurnoverNoticeDocuSignRenderer,
		PunchlistDocuSignCellRenderer,
		OnshoreTurnoverNoticeWidgetComponent,
		ManageCellRenderComponent,
		AddeditotnComponent,
		DeleteCellComponent,
		ReturnedComponent,
		SentComponent,
		DependenciesModalComponent,
		PunchlistsCellRenderComponent,
		EditpunchlistComponent,
		PunchlistsCellRenderComponent,
		PunchlistCellRenderComponent,
		AdhocReportsWidgetComponent,
		AdhocReportsCellRenderComponent,
		OtnRundownWidgetComponent,
		IrnRundownWidgetComponent
	],
	providers: [
		AlertService,
		LoadingService,
		SharedService,
		WidgetService,
		InspectionReleaseNoticeService,
		SystemHandoverWidgetService,
		OpenWorkItemsService,
		SessionValidationService,
		PunchlistService,
		ResetUrlService,
		LocalStorage,
		WsCallback,
		WsType,
		ResetPasswordValidation,
		Keycodeverify,
		ResetPassword,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BasicRequest,
			multi: true
		},
		{
			provide: AuthServiceConfig,
			useFactory: getAuthServiceConfigs
		},
		// {
		// 	provide: HIGHCHARTS_MODULES,
		// 	useFactory: HighchartsChartModule
		// },		
		SystemTurnoverNoticeService,
		StnptnRundownWidgetService,
		WorkflowService,
		UserService,
		SystemService,
		RecentProject,
		LandingService,		
		ProjectService,
		MechanicalCompletionService,
		FacilityAcceptanceService,
		CheckSheetService,
		CapabilityService,
		PrecommissioningCompletionService,
		CommCompletionService,
		TopicmapService,
		OpenWorkItemsPiechartService,
		CommonUtil,
		DecodeHtmlString,
		WorkflowApprovalService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }