<div class="my-login-page" data-gr-c-s-loaded="true">
      <section class="h-100">
         <div class="container h-100">
            <div class="row justify-content-md-center h-100">
               <div class="card-wrapper">
                  <div class="mt-3 mb-3">
                     <img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
                  </div>
                  <div class="card fat">
                     <div class="card-body">
                        <h4 class="card-title">Change Password</h4>
                        <div class="form-group">
                            <span for="oldPassword">Password change success, please 
                              <a class="link-primary" (click)="loginLinkClicked()">
                                login
                              </a> to continue
                            </span>
                         </div>
                     </div>
                  </div>
                  <div class="footer">
                     2018 © AXIS. All Rights Reserved
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>