import { Component, OnInit, ViewChild, Pipe, EventEmitter } from '@angular/core';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkItemSkylineWidgetService } from '../work-item-skyline-widget/work-item-skyline-widget.service';

@Component({
    selector: 'app-cell-render',
    templateUrl: './open-work-item-cell-render.component.html',
})

export class OpenWorkItemCellRenderComponent {

    workItemId: any;
    public showStatusDropDown: boolean = false;
    public value: string = "value";
    public statusList: Array<any> = [
        { 'id': "Open", 'text': "Open" },
        { 'id': "Closed", 'text': "Closed" }
    ]
    params: any;

    constructor(private WorkItemSkylineService: WorkItemSkylineWidgetService, private alertService: AlertService, private translate: TranslateService) {
    }

    agInit(params: any): void {
        this.params = params;
        this.workItemId = params.data.workItemId;
        if (params.colDef.field == "status") {
            this.showStatusDropDown = true;
            this.value = params.value;
            let selectedStatus: Array<any> = [{ 'id': params.value, 'text': params.value }]
        }

    }

    public selectedStatusId(params) {
        // this.params.context.componentParent.iconClicked = true
        if (this.value != params.text) {
            this.WorkItemSkylineService.updateWorkItemStatus(this.workItemId, params.text).subscribe(
                data => {
                    this.params.context.componentParent.selectedOpenWorkItemStatusId(this.params)
                },
                error => {
                    console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
                    this.alertService.error(error.statusDescription)
                }
            )
        }
    }

    public selectedOpenWorkItemStatusId(params){

    }
}