/**
 * This service is use to communicate between components
 * using output
 */
import { Injectable, Output, EventEmitter, ElementRef } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SharedService {

	
	@Output() private changeSideBarVisibility: EventEmitter<any> = new EventEmitter()
	@Output() private toggleNotification: EventEmitter<any> = new EventEmitter()
	@Output() private showFullScreenWidget: EventEmitter<any> = new EventEmitter()
	@Output() private changeSelectedMenuItem: EventEmitter<any> = new EventEmitter()
	@Output() private loadWidgetList: EventEmitter<any> = new EventEmitter()
	@Output() private scrollPosition: EventEmitter<any> = new EventEmitter()
	@Output() auth0Login: EventEmitter<any> = new EventEmitter();
	@Output() disableCellRenderer: EventEmitter<any> = new EventEmitter()
	@Output() editPunchlistId: EventEmitter<any> = new EventEmitter()
	@Output() punchlistIdList: EventEmitter<any> = new EventEmitter()

	private fileId: any = null;
   	private fileType: any = null;
	private action: string = null;
	private status: any = null;
	private dependencyMessageType: string = null;
	private multipleSelectedList: string;
	private parent: any = null;

	constructor() { }

	/**
	 * change side bar visibility request can use this from any component
	 */
	public csbvRequest() {
		this.changeSideBarVisibility.emit()
	}

	/**
	 * change side bar visibility execute 
	 * used this in dashboard component to show sidebar
	 */
	public csbvExecute() {
		return this.changeSideBarVisibility
	}

	/**
	 * hide notification content after click on a notification
	 */
	public tnRequest() {
		this.toggleNotification.emit()
	}

	/**
	 *  hide notification content after click on a notification
	 */
	public tnExecute() {
		return this.toggleNotification
	}

	/**
	 * show full screen widget request can use this from any component
	 */
	public sfswRequest() {
		this.showFullScreenWidget.emit()
	}

	/**
	 * show full screen widget execute 
	 * used this in dashboard component to show sidebar
	 */
	public sfswExecute() {
		return this.showFullScreenWidget
	}

	/**
	 * change select menu item request can use this from any component
	 */
	public csmiRequest(menuItem: string, group: string) {
		this.changeSelectedMenuItem.emit(
			{
				menuItem: menuItem,
				group: group
			}
		)
	}

	/**
	 * change select menu item execute 
	 * used this in sidebar component to show sidebar
	 */
	public csmiExecute() {
		return this.changeSelectedMenuItem
	}

	public loadWidgetListRequest(widgetList: any) {
		this.loadWidgetList.emit(
			{
				widgetList: widgetList
			}
		)
	}

	public loadWidgetListExecute() {
		return this.loadWidgetList
	}
	
	/**
	 * change the scroll position when needed 
	 * using in dashboard ts and send request from widgets wizards
	 */
	public changeScrollRequest(er: ElementRef) {
		this.scrollPosition.emit(
			{
				elementRef: er
			}
		)
	}
	public changeScrollExecute() {
		return this.scrollPosition
	}

	public auth0LoginRequest( userEmail:string, emailValidated:string) {
		let userData = {'userEmail': userEmail, 'emailValidated' :emailValidated}
		this.auth0Login.emit(userData);
	}

	public auth0LoginResponse() {
		return this.auth0Login;
	}

	public setFileType(fileType: any) {
		this.fileType = fileType
	}

	public getFileType() {
		return this.fileType
	}

	public setFileId(fileId: any) {
		this.fileId = fileId
	}

	public getFileId() {
		return this.fileId
	}

	public getAction() {
		return this.action;
	}

	public setAction(action: any) {
		this.action = action;
	}

	public getDependencyMessageType(): string {
		return this.dependencyMessageType;
	}
	public setDependencyMessageType(value: string) {
		this.dependencyMessageType = value;
	}
	
	/**
	 * set multiple selected items
	 * @param multipleItems 
	 */
	public setMultipleSelectedItems(multipleItems: any){
			this.multipleSelectedList = multipleItems;
		}
	
	/**
	 * get multiple selected items
	 */
	public getMultipleSelectedItems(){
		return this.multipleSelectedList;
	}

	public setStatus(status: any) {
		this.status = status
	}

	public getStatus() {
		return this.status
	}

	public setParent(parent: any) {
		this.parent = parent;
	}

	public getParent() {
		return this.parent;
	}

	public disableCellRendererRequest(disable: any, fileId: String) {
		this.disableCellRenderer.emit({ data: disable, id: fileId })
	}

	public disableCellRendererExecute() {
		return this.disableCellRenderer
	}

	public editPunchlistRequest() {
		this.editPunchlistId.emit();
	}

	public editPunchlistResponse() {
		return this.editPunchlistId;
	}

	public setAddedPunchlists(punchlists: any){
		this.punchlistIdList.emit({ punchlistIds: punchlists})
	}

	public getAddedPunchlists(){
		return this.punchlistIdList
	}
}
