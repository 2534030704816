import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createInput } from '@angular/compiler/src/core';
import { AlertService } from '../../../util/alert/alert.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { Keycode } from './keycode.model';
import { Keycodeverify } from './keycodeverify.service';
import { LocalStorage } from '../../../util/localstorage.service';
import { LoggedUser } from '../../logged-user.model';
import { UserVariable } from '../../../util/common/user-variable';
import { CapabilityService } from '../../../util/capability.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-keycodeverification',
    templateUrl: './keycodeverification.component.html',
    styleUrls: ['./keycodeverification.component.css']
})
export class KeycodeverificationComponent implements OnInit, WsCallback {

    private keyCodeRequest: Keycode;
    public keyCode: String = null;
    private loggedInUser: LoggedUser;
    public translatedData: any = null;

    constructor(private router: Router,
        private alertService: AlertService,
        private capabilityService: CapabilityService,
        private keyCodeService: Keycodeverify,
        private translate: TranslateService) {

        let browserLang = translate.getBrowserLang();
        //translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.translatedData = res['KEY_CODE_VERIFICATION'];
        })
    }

    ngOnInit() {
        this.keyCodeService.setCallback(this);
        this.alertService.error('this is the name', false);
        this.keyCodeRequest = new Keycode();
        this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
    }

    public verifyKeyCode() {
        console.log('[verifyKeyCode]' + JSON.stringify(this.keyCode));
        this.alertService.clear()
        if (this.keyCode != null) {
            this.keyCodeRequest.keycode = this.keyCode;
            this.keyCodeRequest.userid = localStorage.getItem(LocalStorage.USER_ID);
            this.keyCodeRequest.deviceid = localStorage.getItem(LocalStorage.DEVICE_ID);
            // this.keyCodeRequest.authtoken = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken;
            this.keyCodeService.verifyKeyCode(JSON.stringify(this.keyCodeRequest));
        } else {
            this.alertService.error(this.translatedData.KEY_CODE_EMPTY_MESSAGE)
        }
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.KEY_CODE_VERIFICATION) {
            //new code
            var userJason = JSON.parse(JSON.stringify(data.payload));

            var user = new LoggedUser(userJason.currentusername, userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
            localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
            UserVariable.clientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
            UserVariable.isClient = data.payload.isclient;
            UserVariable.userId = data.payload.currentuserid;
            UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";
            localStorage.removeItem(LocalStorage.USER_ID);
            let userPreference = JSON.parse(JSON.parse(JSON.stringify(data.payload.preferences)));
            localStorage.setItem(LocalStorage.TIMEZONE, userPreference.UserPreference.Portal.General.TimeZone);

            if ((userJason.recentproject != null || userJason.recentproject != undefined)
                && (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 3)) {
                UserVariable.projectId = userJason.recentproject.projectId;
                localStorage.setItem(LocalStorage.PROJECT_NAME, userJason.recentproject.projectName);
                //this will available only for recent projects
                UserVariable.clientId = userJason.recentproject.clientId;
                this.capabilityService.getAllCapabilityList()
                    .subscribe(data => {
                        localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload))
                        //Check if the user has capability to see the dashboard.
                        if (this.capabilityService.isCapabilityAssigned("viewDashboard")) {
                            this.router.navigateByUrl('dashboard');
                        } else {
                            this.router.navigateByUrl('landing');
                        }
                    },
                        error => {
                            this.alertService.clear()
                            this.alertService.error(error.statusDescription)
                        })
            } else {
                this.router.navigateByUrl('landing');
            }
            // //check th default project
            // //this.recentProjectService.getDefaultRecentProject(userJason.currentuserid);

            // localStorage.setItem(LocalStorage.IS_CLIENT,JSON.stringify(data.payload.clientid));
            // localStorage.setItem(LocalStorage.USER_ID,userJason.currentuserid);

            // var user = new LoggedUser(userJason.currentusername, userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
            // localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
            // UserVariable.userClientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
            // UserVariable.isClient = data.payload.isclient;
            // UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";

            // if ((data.payload.recentproject != null) && (data.payload.recentproject.isDefaultProject == 1)) {
            //     localStorage.setItem(LocalStorage.PROJECT_NAME, data.payload.recentproject.projectName);
            //     UserVariable.projectId = data.payload.recentproject.projectId;
            //     UserVariable.clientId = data.payload.recentproject.clientId;
            //     localStorage.setItem(LocalStorage.PROJECT_ID, UserVariable.projectId);
            //     localStorage.setItem(LocalStorage.CLIENT_ID, UserVariable.clientId);

            //     this.router.navigateByUrl('dashboard');
            // } else {
            //     this.router.navigateByUrl('landing');
            // }

            //localStorage.removeItem(LocalStorage.USER_ID);

            // if (data.payload = 'true') {
            //     this.router.navigateByUrl('landing');
            // }
        }
    }

    onFail(data: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.KEY_CODE_VERIFICATION) {
            //console.log("error => " + data);
            this.alertService.error(data.statusDescription, false);
        }
    }

    public cancelBtnClickEvent(): void {
        this.router.navigateByUrl('login');
        localStorage.removeItem(LocalStorage.LOGGED_USER);
    }
}
