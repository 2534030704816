import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PunchlistService } from '../punchlist.service';
import { SharedService } from '../../util/shared.service';
import { UserVariable } from '../../util/common/user-variable'
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SystemService } from '../system.service';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
	selector: 'app-punchlist-piechart',
	templateUrl: './punchlist-open-punchlist.component.html',
	styleUrls: ['./punchlist-open-punchlist.component.css']
})
export class PunchlistPiechartComponent implements OnInit {
	public chart: Chart
	public finalOpenAPLCount: number = 0;
	public finalOpenBPLCount: number = 0;
	public finalOpenCPLCount: number = 0;
	public finalOpenDPLCount: number = 0;
	public isSubSystem: string = "false"
	public showFilterOptions: boolean = false
	public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	// showSubSystems: any;
	public systemList: Array<any> = []
	public isMinimized: boolean = false
	private translatedData: any = null
	public isLoadingData: boolean = true
	public isNoDataToShow: boolean = false	
	public systemId: any = 'ALL'
	public showSubSysem: boolean = false;

	constructor(private punchlistService: PunchlistService, private sharedService: SharedService,
		private systemService: SystemService, private translate: TranslateService, private alertService: AlertService) {
		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['OPEN_PUNCHLIST']
			this.getDataFromServer(this.systemId, this.isSubSystem);
		})
	}


	ngOnInit() {
		this.systemService.getAllSystemList().subscribe(
			data => {
				this.systemList = []
				this.systemList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.systemList.push({ 'id': element.id, 'text': element.systemNo + ' - ' + element.systemName })
					});
				} else {
					// do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
			}
		)
	}

	selectedSystemId(value: any): void {
		this.selectedSystem.pop()
		this.selectedSystem.push(value)
		this.systemId = value.id
		this.getDataFromServer(this.systemId, this.isSubSystem);
	}

	/**
	 * 
	 * @param systemId 
	 * @param isSubSystem 
	 */
	public getDataFromServer(systemId: string, isSubSystem: string) {		
		this.isLoadingData = true
		this.isNoDataToShow = false
		console.log(systemId + "=== " + isSubSystem)
		this.punchlistService.getOpenPunchListCount(systemId, isSubSystem).subscribe(
			data => {
				if (data.payload != 0) {
					this.loadData(data.payload);
					this.initChart();
					this.isLoadingData = false
				} else {
					this.isNoDataToShow = true
					this.isLoadingData = false
				}
			},
			error => {
				this.isNoDataToShow = true
				this.isLoadingData = false
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}

	loadData(payload: any) {
		this.finalOpenAPLCount = payload.openPunchListACount;
		this.finalOpenBPLCount = payload.openPunchListBCount;
		this.finalOpenCPLCount = payload.openPunchListCCount;
		this.finalOpenDPLCount = payload.openPunchListDCount;
		if (this.finalOpenAPLCount == 0 && this.finalOpenBPLCount == 0 && this.finalOpenCPLCount == 0 && this.finalOpenDPLCount == 0) {
			this.isNoDataToShow = true
		} else {
			this.isNoDataToShow = false
		}
	}

	private initChart() {
		this.chart = new Chart({
			chart: {
				type: 'pie'
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			loading: {
				labelStyle: {
					color: '#FAFAFA'
				},
				style: {
					backgroundColor: '#BDBDBD'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b>: {point.y}'
					}
				}
			},
			series: [{
				name: this.translatedData.WIDGET_NAME,
				data: [{
					color: '#D32F2F',
					name: this.translatedData.OPENAPLCOUNT,
					y: this.finalOpenAPLCount,

				},
				{
					color: '#F57C00',
					name: this.translatedData.OPENBPLCOUNT,
					y: this.finalOpenBPLCount,

				},
				{
					color: '#FBC02D',
					name: this.translatedData.OPENCPLCOUNT,
					y: this.finalOpenCPLCount,

				},
				{
					color: '#388E3C',
					name: this.translatedData.OPENDPLCOUNT,
					y: this.finalOpenDPLCount,

				},
				]
			}],
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					}
				}
			}

		})
	}

	removedSystem(params) {
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.systemId = 'ALL'
		this.finalOpenAPLCount = 0;
		this.finalOpenBPLCount = 0;
		this.finalOpenCPLCount = 0;
		this.finalOpenDPLCount = 0;
		this.getDataFromServer(this.systemId, this.isSubSystem)
	}

	public refresh() {
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.systemId = 'ALL'
		this.finalOpenAPLCount = 0;
		this.finalOpenBPLCount = 0;
		this.finalOpenCPLCount = 0;
		this.finalOpenDPLCount = 0;
		this.isSubSystem = "false"	
		this.showSubSysem = false		
		this.getDataFromServer(this.systemId, this.isSubSystem)
	}

	checkboxFunction(event) {				
		if (event.target.checked) {
			this.isSubSystem = "true";
		} else {
			this.isSubSystem = "false";
		}
		this.finalOpenAPLCount = 0;
		this.finalOpenBPLCount = 0;
		this.finalOpenCPLCount = 0;
		this.finalOpenDPLCount = 0;			
		this.getDataFromServer(this.systemId, this.isSubSystem)
	}
}
