import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button *ngIf="show()" id="edit" (click)="edit()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="far fa-edit mx-2" aria-hidden="true"></i> </button>'

})
export class PrecommissioningEditButtonRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;		
	}

	public show() {
		if (this.params.data) {
			return true
		} else {
			return false
		}
	}

	public edit() {
		console.log(this.params.context.componentParent)
		this.params.context.componentParent.iconClicked = true
		this.params.context.componentParent.edit(this.params)
	}

	refresh(): boolean {
		return false;
	}
}