import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { WsCallback } from '../../util/ws-callback.interface';
import { OpenWorkItemsService } from '../my-open-workitems.service';
import { OWAddButtonRenderer } from './cell-renderer/add-button/add-button.component';
import { OWEditButtonRenderer } from './cell-renderer/edit-button/edit-button.component';
import { OWDeleteButtonRenderer } from './cell-renderer/delete-button/delete-button.component';
import { OpenWorkItemCellRenderComponent } from './open-work-item-cell-render.component';
import { GridOptions } from 'ag-grid';
import { WorkItemSkylineWidgetService } from '../work-item-skyline-widget/work-item-skyline-widget.service';
import { UserVariable } from '../../util/common/user-variable';
import { formatDate } from '@angular/common';
import { LocalStorageConst, WIDGET_EVENT } from '../../util/constants';

@Component({
	selector: 'app-open-work-items',
	templateUrl: './open-work-items.component.html',
	styleUrls: ['./open-work-items.component.css']
})
export class OpenWorkItemsComponent implements OnInit {

	public isMinimized: boolean = false
	rowData: any[] = [];
	detailRowData: any[] = [];
	private gridApi;
	private gridColumnApi;
	columnDefs;
	owi_translated_data: any = null;
	liftCritical;
	self: this;
	public gridOptions: GridOptions;
	public detailGridOptions: GridOptions;
	public showAddComment: boolean = false;
	public commentsColumnDefs
	checkedAddClicked: boolean = false;
	isLoadingData: boolean;
	workPackageId: any;
	commentState: string;
	public userComment: string = "";
	workItemId: any;
	comment: string;
	checkedEditClicked: boolean;
	detailGridApi: any;
	public commWorkItemCommentModel: any;
	commentId: string;
	private detailCellRendererParams;
	eventType: any;
	widgetEvent = WIDGET_EVENT;
	private backEvent: any = null
	public getRowHeight: (params: any) => number;
	currentDate: string;

	constructor(private openWorkItemsService: OpenWorkItemsService, private sharedService: SharedService,
		private translate: TranslateService, private alertService: AlertService, private WorkItemSkylineService: WorkItemSkylineWidgetService) {

		this.self = this
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			autoHeight: true,
			// rowHeight: 130
			// rowTemplate: 
			rowData: this.rowData,

			// enable master detail
			masterDetail: true,

			// specify params for default detail cell renderer
			detailCellRendererParams: {
				// provide detail grid options
				detailGridOptions: this.detailGridOptions,
			}
			// rowHeight: 130
			// rowTemplate: 
		};
		this.detailGridOptions = <GridOptions>{
			columnDefs: this.columnDefs,
			context: {
				componentParent: this
			},
			// paginationPageSize: 5,
			// autoHeight: true,
		};
		let browserLang = translate.getBrowserLang();
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.owi_translated_data = res['OWI_REPORT']
			this.columnDefs = [
				{ headerName: this.owi_translated_data.ASSET, field: 'asset', filter: "agTextColumnFilter", cellClass: "cell-wrap-text", minWidth: 200, cellRenderer: "agGroupCellRenderer", autoHeight: true },
				{ headerName: this.owi_translated_data.DESCRIPTION, filter: "agTextColumnFilter", field: 'description', cellClass: "cell-wrap-text", minWidth: 300, autoHeight: true },
				{ headerName: this.owi_translated_data.TYPE, field: 'type', cellClass: "cell-wrap-text", minWidth: 100, autoHeight: true },
				{ headerName: this.owi_translated_data.PRIORITY, field: 'priority', cellClass: "cell-wrap-text", minWidth: 150, autoHeight: true },
				{
					headerName: this.owi_translated_data.LIFT_CRITICAL,
					field: 'lift_critical',
					cellClass: "cell-wrap-text",
					minWidth: 100,
					autoHeight: true,
					editable: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: [this.owi_translated_data.YES, this.owi_translated_data.NO],
					},
					singleClickEdit: true
				},
				{
					headerName: this.owi_translated_data.STATUS,
					field: 'status',
					cellClass: "cell-wrap-text",
					minWidth: 100,
					autoHeight: true,
					// cellRendererFramework: OpenWorkItemCellRenderComponent,
					editable: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: [this.owi_translated_data.OPEN, this.owi_translated_data.CLOSED]
					},
					singleClickEdit: true
				},
				{ headerName: this.owi_translated_data.COMMENT, field: 'comment', cellClass: "cell-wrap-text", minWidth: 100, cellRendererFramework: OWAddButtonRenderer, autoHeight: true, suppressFilter: true}
			];

		});

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.owi_translated_data = res['WORK_ITEM']
			this.detailCellRendererParams = {
				detailGridOptions: {
					context: {
						componentParent: this
					},
					columnDefs: [
						{
							headerName: this.owi_translated_data.COMMENT,
							field: 'comment',
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 300,
						},
						{
							headerName: this.owi_translated_data.DATE,
							field: 'addedDate',
							// valueFormatter: "x.toLocaleDateString()",
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 150,
							filter: 'agDateColumnFilter',
							filterParams: {
								comparator: function (filterLocalDateAtMidnight, cellValue) {
									var dateAsString = cellValue;
									var cellDate = new Date(dateAsString)
									if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
										return 0;
									}
									if (cellDate < filterLocalDateAtMidnight) {
										return -1;
									}
									if (cellDate > filterLocalDateAtMidnight) {
										return 1;
									}
								}
							},
						},
						{
							headerName: this.owi_translated_data.USER,
							field: 'userName',
							autoHeight: true,
							cellClass: "cell-wrap-box",
							minWidth: 200,
						},
						{
							headerName: this.owi_translated_data.EDIT,
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 70,
							suppressFilter: true,
							cellRendererFramework: OWEditButtonRenderer
						},
						{
							headerName: this.owi_translated_data.DELETE,
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 80,
							suppressFilter: true,
							cellRendererFramework: OWDeleteButtonRenderer
						}

					],
					defaultColDef: { editable: true },
					onGridReady: function (params) {
						params.api.setGridAutoHeight(true);
						setTimeout(() => {
							params.api.sizeColumnsToFit();
						}, 500);
						window.addEventListener("resize", function () {
							setTimeout(function () {
								params.api.sizeColumnsToFit();
							});
						});
					},
					onFirstDataRendered(params) {
						params.api.sizeColumnsToFit();
					}
				},
				getDetailRowData: function (params) {
					let detailRowData = [];
					if (params.data.comment.length != 0) {
						for (let i = 0; i < params.data.comment.length; i++) {
							detailRowData.push({
								comment: params.data.comment[i]["comment"],
								addedDate: new Date(params.data.comment[i]["addedDate"]).toLocaleDateString(),
								userName: params.data.comment[i]["userName"],
								workItemId: params.data["workItemId"],
								commWorkItemCommentId: params.data.comment[i]["id"]
							});
							params.successCallback(detailRowData);
						}
					} else {
						params.successCallback(params.data.comment);
					}
				}
			};
			this.getRowHeight = function (params) {
				if (params.node && params.node.detail) {
					var offset = 400;
					var commentsLength = 0
					if (params.data.comments) {
						offset = 350;
						commentsLength = params.data.comments.length
					}
					var allDetailRowHeight = commentsLength * 28;
					return allDetailRowHeight + offset;
				} else {
					return 60;
				}
			};
		})
	}
	ngOnInit() {
		this.eventType = WIDGET_EVENT.SHOW_TABLE_SCREEN
		// this.eventType = WIDGET_EVENT.SHOW_TABLE
		let date = new Date();
		this.currentDate = formatDate(date, 'yyy-MM-dd', 'en-US');
	}

	loadData(payload: any) {
		this.rowData = [];
		this.gridApi.hideOverlay()
		this.gridApi.showLoadingOverlay()

		for (let i = 0; i < payload.length; i++) {
			if (payload[i].installCritical == true) {

				this.liftCritical = "Yes"
			}
			else {

				this.liftCritical = "No"
			}

			this.rowData.push({
				asset: payload[i]["assetName"],
				description: payload[i]["description"],
				type: payload[i]["type"],
				priority: payload[i]["priorityCode"],
				lift_critical: this.liftCritical,
				status: payload[i]["status"],
				comment: payload[i]['comments'],
				workItemId: payload[i]['id']
			});
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		// params.api.sizeColumnsToFit();
		this.gridApi.showLoadingOverlay();
		this.openWorkItemsService.getOpenWorkItems()
			.subscribe(
			data => {
				this.loadData(data.payload)
				setTimeout(function () {
					params.api.sizeColumnsToFit();
					params.api.resetRowHeights();
				}, 500)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});

		this.backToTable("")
	}

	// onDetailGridReady(params) {

	//     this.detailGridApi = params.api;
	//     this.gridColumnApi = params.columnApi;
	//     params.api.sizeColumnsToFit();

	//     this.openWorkItemsService.getOpenWorkItems()
	//         .subscribe(
	//             data => {
	//                 this.loadData(data.payload)
	//             },
	//             error => {
	//                 this.alertService.clear()
	//                 this.alertService.error(error.statusDescription)
	//             });
	//     setTimeout(function () {
	//         params.api.sizeColumnsToFit();
	//         params.api.resetRowHeights();
	//     }, 500);
	// }

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	// onDetailColumnResized(event) {
	//     if (event.finished) {
	//         this.detailGridApi.resetRowHeights();
	//     }
	// }

	onCellValueChanged(params) {
		let colId = params.column.getId()
		let workItemId = params.data.workItemId
		let newValue = params.newValue
		// console.log(params)
		console.log('[onCellValueChanged] (colId/newValue) ' + colId + '/' + newValue);
		switch (colId) {
			case 'status':
				this.changeStatusId(workItemId, newValue, params)
				break;
			case 'lift_critical':
				this.changeLiftCritical(workItemId, newValue)
				break;
		}
	}

	public changeLiftCritical(workItemId, value) {
		this.WorkItemSkylineService.updateWorkItemLiftCritical(workItemId, value).subscribe(
			data => {
				console.log('[changeLiftCritical]' + JSON.stringify(data))
			},
			error => {
				console.log('[changeLiftCritical] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)
	}

	public changeStatusId(workItemId, value, params) {
		// this.params.context.componentParent.iconClicked = true
		this.WorkItemSkylineService.updateWorkItemStatus(workItemId, value).subscribe(
			data => {
				console.log('[changeStatusId]' + JSON.stringify(data))
				this.selectedOpenWorkItemStatusId(params)
			},
			error => {
				console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)

	}

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.openWorkItemsService.getOpenWorkItems()
			.subscribe(
			data => {
				this.loadData(data.payload)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	// onCellClicked(params) {
	//     let colId = params.column.getId()
	//     console.log(colId)
	//     switch (colId) {
	//         case 'asset':
	//             this.cellClickEvent(params)
	//             break;
	//         case 'description':
	//             this.cellClickEvent(params)
	//             break;
	//         case 'type':
	//             this.cellClickEvent(params)
	//             break;
	//         case 'priority':
	//             this.cellClickEvent(params)
	//             break;
	//         case 'lift_critical':
	//             this.cellClickEvent(params)
	//             break;
	//         case 'status':
	//             break;
	//         case 'comment':
	//             this.cellClickEvent(params)
	//             break;
	//     }
	// }

	// private cellClickEvent(event) {
	//     if (event != null) {
	//         // this.isLoadingData = false;
	//         this.gridApi.showLoadingOverlay();
	//         this.openWorkItemsService.getOpenWorkItems()
	//             .subscribe(
	//                 data => {
	//                     this.loadData(data.payload)
	//                 },
	//                 error => {
	//                     this.alertService.clear()
	//                     this.alertService.error(error.statusDescription)
	//                 });

	//         if (event.data.comment != null) {
	//             event.api.hideOverlay();
	//             this.detailRowData = [];
	//             for (let i = 0; i < event.data.comment.length; i++) {
	//                 this.detailRowData.push({
	//                     comment: event.data.comment[i]["comment"], // to get pdf
	//                     addedDate: new Date(event.data.comment[i]["addedDate"]).toLocaleDateString(),
	//                     userName: event.data.comment[i]["userName"],
	//                     workItemId: event.data["id"],
	//                     commWorkItemCommentId: event.data.comment[i]["id"]
	//                 });
	//             }
	//         }
	//     }
	// }

	onRowClick(params) {
		if (this.checkedAddClicked == true) {
		}
		else {
			if (params != null) {
				// this.isLoadingData = false;
				this.gridApi.showLoadingOverlay();
				this.openWorkItemsService.getOpenWorkItems()
					.subscribe(
					data => {
						this.loadData(data.payload)
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});

				if (params.data.comment != null) {
					params.api.hideOverlay();
					this.detailRowData = [];
					for (let i = 0; i < params.data.comment.length; i++) {
						this.detailRowData.push({
							comment: params.data.comment[i]["comment"], // to get pdf
							addedDate: new Date(params.data.comment[i]["addedDate"]).toLocaleDateString(),
							userName: params.data.comment[i]["userName"],
							workItemId: params.data["id"],
							commWorkItemCommentId: params.data.comment[i]["id"]
						});
					}
				}
			}
		}
	}



	private edit(data: any) {
		this.commentId = data.data.commWorkItemCommentId;
		this.commentState = "Edit";
		this.userComment = data.data.comment
		this.showAddComment = true;
		this.workItemId = data.data.workItemId
		this.checkedEditClicked = true;
		this.eventType = WIDGET_EVENT.SHOW_EDIT
		this.backEvent = WIDGET_EVENT.SHOW_COMMENT
	}
	private add(data: any) {
		this.commentState = "Add";
		this.checkedAddClicked = true;
		this.showAddComment = true;
		this.userComment = '';
		this.eventType = WIDGET_EVENT.SHOW_ADD
		this.backEvent = WIDGET_EVENT.SHOW_TABLE
		this.workItemId = data.data.workItemId
		//this.selectedItem = data.data

	}

	private delete(data: any) {
		// data.api.showLoadingOverlay()
		if (data.data != null) {
			this.WorkItemSkylineService.deleteWorkItemComments(data.data.commWorkItemCommentId).subscribe(
				data => {
					this.openWorkItemsService.getOpenWorkItems()
						.subscribe(
						data => {
							this.loadData(data.payload)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				},
				error => {
					this.alertService.error(error.statusDescription)
				}
			);
		}
	}

	public selectedOpenWorkItemStatusId(params) {
		params.api.showLoadingOverlay()
		if (params.value != null) {
			this.openWorkItemsService.getOpenWorkItems()
				.subscribe(
				data => {
					this.loadData(data.payload)
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
		}
	}

	saveComment(data): void {
		let userId = UserVariable.userId;
		let date = new Date();
		let currentDate = formatDate(date, 'yyy-MM-dd hh:mm:ss', 'en-US');
		this.comment = currentDate + "#splitcomment#" + userId + "#splitcomment#" + this.userComment;
		if (this.checkedAddClicked) {
			this.WorkItemSkylineService.addWorkItemComments(this.workItemId, this.comment).subscribe(
				data => {
					this.openWorkItemsService.getOpenWorkItems()
						.subscribe(
						data => {
							this.loadData(data.payload)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				},
				error => {
					console.log('[getSkylineData] (error) ' + JSON.stringify(error))
					this.alertService.error(error.statusDescription)
					this.isLoadingData = false
				}
			)
			// this.showWIDetail = true
			// this.detailGridApi.hideOverlay();
		} else {
			this.WorkItemSkylineService.updateWorkItemComments(this.commentId, this.userComment, currentDate).subscribe(
				data => {
					this.openWorkItemsService.getOpenWorkItems()
						.subscribe(
						data => {
							this.loadData(data.payload)
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						});
				},
				error => {
					console.log('[getSkylineData] (error) ' + JSON.stringify(error))
					this.alertService.error(error.statusDescription)
					this.isLoadingData = false
				}
			)
		}
		this.userComment = "";
		this.eventType = WIDGET_EVENT.SHOW_TABLE;
		this.showAddComment = false;
	}

	onKey(value: string) {
		this.userComment = value;
	}

	backToTable(event) {
		this.checkedAddClicked = false;
		this.showAddComment = false;
		this.checkedEditClicked = false;
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	isInputValid() {
		if (this.userComment != null) {
			return true
		} else {
			return false
		}
	}

	onRowGroupOpened(params) {
		if (params.node.rowIndex === params.api.paginationProxy.bottomRowIndex) {
			this.gridApi.paginationGoToNextPage();
		}
	}
}
