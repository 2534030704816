import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';

import { ProceduresExecutedByTimeWidgetService } from './procedures-executed-by-time-widget.service';
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { SharedService } from '../../util/shared.service';

@Component({
    selector: 'app-procedures-executed-by-time-widget',
    templateUrl: './procedures-executed-by-time-widget.component.html',
    styleUrls: ['./procedures-executed-by-time-widget.component.css']
})
export class ProceduresExecutedByTimeWidgetComponent implements OnInit {
    chart: Chart
    public today = new Date()
    public dateInt: number;
    public y_axis_text: string = ''
    public x_axis_text: string = ''
    public isMinimized: boolean = false

    private translatedData: any = null
    public isLoadingData: boolean = true
    public isNoDataToShow: boolean = false
    public showFilterOptions: boolean = false

    constructor(private proceduresExecutedByTimeWidgetService: ProceduresExecutedByTimeWidgetService,
        private translate: TranslateService,
        private alertService: AlertService,
        private sharedService: SharedService) {

        this.dateInt = this.today.setUTCHours(0, 0, 0, 0)
        let browserLang = translate.getBrowserLang()
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.translatedData = res['PROCEDURES_EXECUTED_BY_TIME']
            this.initChart()
            this.getExecutedProceduresData()
        })
    }

    ngOnInit() {
    }

    public initChart() {
        console.log('[initChart] (START)');
        this.chart = new Chart({
            chart: {
                type: 'line',
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                title: {
                    text: this.translatedData.DATE
                },
                type: 'datetime',
                tickWidth: 1, 
                labels: {
                    enabled: true,
                    rotation: -45,
					format: '{value:%Y-%b-%d}'
                }
            },
            yAxis: [{ // left y axis	
                gridLineWidth: 1,
                tickWidth: 1,
                minTickInterval: 1,
                title: {
                    text: this.translatedData.NUM_OF_PROCEDURES
                }
            }],
            plotOptions: {
                line: {
                    marker: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                crosshairs: true,
                shared: true,
                xDateFormat: '%Y-%b-%d'
            }

        });
    }

    public addSeriesToChart(executedProceduresSet: any) {
        console.log('[addSeriesToChart] (START)');
        this.chart.addSerie({
            name: this.translatedData.NUM_OF_PROCEDURES,
            color: '#1E88E5',
            marker: {
                symbol: 'dot'
            },
            lineWidth: 2,
            data: executedProceduresSet,
        })
        this.isLoadingData = false
    }

    public removeSerie() {
        let num = this.chart.ref.series.length
        // console.log('[removeSerie] num ' + JSON.stringify(num));
        for (let i = 0; i < num; i++) {
            this.chart.removeSerie(0);
        }
    }

    public refresh() {
        console.log('[ProceduresExecutedByTimeWidgetComponent] (refresh)');
		this.initChart()
        this.getExecutedProceduresData()
    }

    /**
	 * get procedures executed by time data  
	 */
    private getExecutedProceduresData() {
        this.isLoadingData = true
        this.isNoDataToShow = false

        this.proceduresExecutedByTimeWidgetService.getExecutedByTimeProceduresData().subscribe(
            data => {
                let procedureData: any = []

                if (data.payload != null) {
                    if (data.payload.length != 0) {
                        data.payload.forEach(element => {
                            let execDate = new Date(element.dateString).getTime();
                            procedureData.push([execDate, element.procCount])
                        }); 
                    } else {
                        // do nothing no remaining items
                    }
                    if (procedureData.length == 0) {
                        //do not have data to show
                        this.isLoadingData = false
                        this.isNoDataToShow = true
                    } else {
                        this.removeSerie()
                        this.addSeriesToChart(procedureData)
                    }
                } else {
                    //do not have data to show
                    this.isLoadingData = false
                    this.isNoDataToShow = true
                }
            },
            error => {
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
                this.isLoadingData = false
            });
    }
}
