import { Component, OnInit, ViewChild, Pipe, EventEmitter } from '@angular/core';
import { WorkItemSkylineWidgetService } from '../work-item-skyline-widget.service'
import { LoggedUser } from '../../../user/logged-user.model';
import { Chart } from 'angular-highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SystemService } from '../../system.service';
import { AlertService } from '../../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageConst, WIDGET_EVENT } from '../../../util/constants';
import { SharedService } from '../../../util/shared.service';
import { MechanicalCompletionService } from '../../mechanical-completion.service'
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { GridOptions } from 'ag-grid';
import { LoadingService } from '../../../util/loading/loading.service'
import { UserVariable } from '../../../util/common/user-variable';
import { CheckSheetSentFileIconRenderer } from '../../common-cell-renderer/file-icon/checksheet-sent-file-icon.component';
import { CheckSheetApprovedFileIconRenderer } from '../../common-cell-renderer/file-icon/checksheet-approved-file-icon.component';
import { LocalStorage } from '../../../util/localstorage.service';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { WIEditButtonRenderer } from './edit-button/edit-button.component';
import { WIDeleteButtonRenderer } from './delete-button/delete-button.component';

@Pipe({
    name: 'dateFormat'
})
@Component({
    selector: 'app-cell-render',
    templateUrl: './work-item-skyline-widget-cell-render.component.html',
    outputs: ['workItemID', 'showCommentInput'],
    inputs: ['showCommentInput']
})


export class WorkItemSkylineWidgetCellRenderComponent {

    public showStatusDropDown: boolean = false;
    private selectedPoint: any = null
    public showCriticalDropDown: boolean = false;
    public status: string = "";
    public liftCritical: string = "";
    public value: string = "value";
    public workItemId: string = "";
    public disable: boolean = true;
    public statusList: Array<any> = [
        { 'id': "Open", 'text': "Open" },
        { 'id': "Closed", 'text': "Closed" }
    ]
    public criticalList: Array<any> = [
        { 'id': "Yes", 'text': "Yes" },
        { 'id': "No", 'text': "No" }
    ]
    selectedItem: any;
    eventType: WIDGET_EVENT;
    public comment: string;
    isLoadingData: boolean;
    public userId: string;
    public currentDate: Date;
    private translatedData: any = null;
    public commentsColumnDefs: any;
    showCommentInput: boolean = false;
    workItemID = new EventEmitter<string>();

    constructor(private WorkItemSkylineService: WorkItemSkylineWidgetService, private alertService: AlertService, private translate: TranslateService) {
    }

    agInit(params: any): void {
        this.workItemId = params.data.workItemId;
        this.workItemID = params.data.workItemId;
        if (params.colDef.field == "status") {
            this.showStatusDropDown = true;
            this.value = params.value;
            let selectedStatus: Array<any> = [{ 'id': params.value, 'text': params.value }]

        }
        else if (params.colDef.field == "liftCritical") {
            this.showCriticalDropDown = true;
            this.value = params.value;
            let selectedSCritical: Array<any> = [{ 'id': params.value, 'text': params.value }]
        }

        else if (params.colDef.field == "comments") {
            this.disable = false;
        }
    }

    public selectedStatusId(params) {
        if (this.value != params.text) {
            this.WorkItemSkylineService.updateWorkItemStatus(this.workItemId, params.text).subscribe(
                data => {
                    // console.log(data)
                },
                error => {
                    console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
                    this.alertService.error(error.statusDescription)
                }
            )
        }
    }

    public selectedCriticalId(params) {
        if (this.value != params.text) {
            this.WorkItemSkylineService.updateWorkItemLiftCritical(this.workItemId, params.text).subscribe(
                data => {
                    // console.log(data)
                },
                error => {
                    console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
                    this.alertService.error(error.statusDescription)
                }
            )
        }
    }



    public invoke(type: String, data: any) {

    }

    public onChange(value: string) {
        this.workItemID.emit(value);
    }


}