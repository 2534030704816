import { Component, OnInit } from '@angular/core';
import { PunchlistService } from '../punchlist.service';
import { SharedService } from '../../util/shared.service';
import { UserVariable } from '../../util/common/user-variable'
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SystemService } from '../system.service';
import { FormsModule } from '@angular/forms';
import { PunchListImageIconRenderer } from './cell-renderer/image-icon/image-icon.component';
import { PunchListLocationIconRenderer } from './cell-renderer/location-icon/location-icon.component';
import { PunchListDocumentIconRenderer } from './cell-renderer/document-icon/document-icon.component';
import { GridOptions } from "ag-grid";
import * as FileSaver from 'file-saver';
import { DecodeHtmlString } from '../../util/common/decode-html-string.pipe';
import { LoadingService } from '../../util/loading/loading.service';
import { AddpunchlistComponent } from './addpunchlist/addpunchlist.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PunchlistDocuSignCellRenderer } from './cell-renderer/punchlist-docu-sign/punchlist-docu-sign.component';
import { Constants, WIDGET_EVENT } from '../../util/constants';
import { UserService } from '../user.service';
import { WorkflowService } from '../workflow.service';
import { WsType } from 'src/app/util/ws-type';
import { WsResponse } from 'src/app/util/ws-response.model';
import { PunchlistsCellRenderComponent } from './cell-renderer/punchlists-cell-render-component.component';
import { DependenciesModalService } from 'src/app/util/dependenciesmodal/dependencies-modal.service';
import { Subscription } from 'rxjs';
import { EditpunchlistComponent } from './cell-renderer/editpunchlist/editpunchlist.component';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-punchlist-detail-widget',
	templateUrl: './punchlist-detail-widget.component.html',
	styleUrls: ['./punchlist-detail-widget.component.css']
})
export class PunchlistDetailWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	public rowData: any[] = [];
	public columnDefs;
	private rowSelection;
	private isRowSelectable;
	private gridApi;
	private gridColumnApi;
	punchlistdetail_translated_data: any = null;
	public gridOptions: GridOptions;
	bsModalRef: any;
	eventType: any = WIDGET_EVENT.SHOW_TABLE
	widgetEvent = WIDGET_EVENT
	public userList: any[] = []
	public workflowTaskListOpen: any[] = []
	public workflowTaskListClose: any[] = []
	public selectedAssignee: any[] = null
	assignee1: any
	assignee2: any
	assignee3: any
	assignee4: any
	taskname1: any
	taskname2: any
	taskname3: any
	taskname4: any

	isSubmitted: boolean = false
	validAssignee1: boolean = true
	validAssignee2: boolean = true
	validAssignee3: boolean = true
	validAssignee4: boolean = true
	validAssigneeEmail1: boolean = true
	validAssigneeEmail2: boolean = true
	validAssigneeEmail3: boolean = true
	validAssigneeEmail4: boolean = true
	public selectedPunchlists: any[] = []
	private selectedItem: any = null
	public punchlistStatus: any = ""
	//public previousPLStatus: any = ""
	selectedInitialPunchlists: any[] = []
	selectedOpenPunchlists: any[] = []
	openAndInitialPLWarning: string = ""
	multiplePLWarning: string = ""
	inactivePLWarning: string = ""
	maxFileSizeWarning: string = ""
	public plClosureComments: any[] = []

	private editPlId: Subscription;
	private deletePunchlistSub:Subscription
	private enableDisableSub:Subscription
	private loadDataSub: Subscription
	private punchlistIds: Subscription;
	public punchlistId: string;
	public selectedPLSystemNo: string = "";
	public selectedPLAssets: string = "";
	private MAX_FILE_SIZE : any = 26214400;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
		
	  };

	constructor(
		private punchlistService: PunchlistService,
		private systemService: SystemService,
		private sharedService: SharedService,
		private translate: TranslateService,
		private alertService: AlertService,
		private decodeHTMLString: DecodeHtmlString,
		private loadingService: LoadingService,
		private modalService: BsModalService,
		private userService: UserService,
		private workflowService: WorkflowService,
		private dependenciesmodalService: DependenciesModalService) {

		let browserLang = translate.getBrowserLang();
		
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.punchlistdetail_translated_data = res['PUNCHLISTDETAIL_REPORT']
			var self = this;
			this.columnDefs = [
				{ headerName: '', field: 'chkselection', autoHeight: true, //cellRenderer: 'checkboxRenderer',
					resizable: true, width: 40, minWidth: 40, maxWidth: 40, suppressFilter: true, checkboxSelection: true, 
					cellClass: params => {
					return params.data.itemStatus != "Closed" ? ((params.data.docuSignStatus == null ||  params.data.docuSignStatus == '') ? ((params.data.plstatus == 'Active') ? '' : 'disabled-checkbox') : 'disabled-checkbox') : 'disabled-checkbox'}
				},
				{
				  headerName: this.punchlistdetail_translated_data.PUNCHLIST_DEPENDENCY,
				  field: "dependencies",
				  width: 75, minWidth: 75, maxWidth: 75,
				  cellStyle: { 'text-align': 'center' },
				  cellRendererFramework: PunchlistsCellRenderComponent
				},
				{ headerName: this.punchlistdetail_translated_data.CATEGORY, field: 'category', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
				{ headerName: this.punchlistdetail_translated_data.ASSET, field: 'asset', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, filter: "agTextColumnFilter"  },
				{ headerName: this.punchlistdetail_translated_data.VENDOR, field: 'vendor', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
				{ headerName: this.punchlistdetail_translated_data.DESCRIPTION, field: 'description', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300, filter: "agTextColumnFilter" },
				{ headerName: this.punchlistdetail_translated_data.PUNCHLIST_ITEM_STATUS, field: 'itemStatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 100, enableRowGroup: true },
				{
					headerName: this.punchlistdetail_translated_data.COMPLETION_DATE, field: 'targetCompletionDate', filter: 'agDateColumnFilter', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								cellValue = cellValue.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				},
				{
					headerName: this.punchlistdetail_translated_data.DATE_CLOSED, field: 'dateClosed', filter: 'agDateColumnFilter', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				},
				{ headerName: this.punchlistdetail_translated_data.SUB_SYSTEM, field: 'sub_system', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 250, filter: "agTextColumnFilter" },
				{ headerName: this.punchlistdetail_translated_data.RAISED_BY, field: 'raised_by', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 130, enableRowGroup: true },
				{
					headerName: this.punchlistdetail_translated_data.PUNCHLIST_IMAGES,
					field: 'images',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 300,
					suppressFilter: true,
					cellRendererFramework: PunchListImageIconRenderer,
					suppressSorting: true,
					
				},
				{
					headerName: this.punchlistdetail_translated_data.LOCATION,
					field: 'location',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100,
					suppressFilter: true,
					cellRendererFramework: PunchListLocationIconRenderer,
					suppressSorting: true,
					
				},
				{
					headerName: this.punchlistdetail_translated_data.DOCUMENTS,
					field: 'documents',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 300,
					cellRendererFramework: PunchListDocumentIconRenderer
				},
				{
					headerName: this.punchlistdetail_translated_data.START_WORKFLOW,
					//field: 'isStart',
					// valueGetter: function(params) {
					// 	if(params.data.isStart) {
					// 		return params.data.isStart;
					// 	} else {
					// 		return params.data.docuSignStatus == null ? '' : params.data.docuSignStatus;
					// 	}
					// },

					valueGetter: function(params) {
						if(params.data.isStart) {
							return params.data.isStart;
						} else {
							if(params.data.docuSignStatus == null){
								return '';
							}
							else{
								if(params.data.itemStatus.toLowerCase() == 'open'){
									return 'close '+params.data.docuSignStatus
								}
								else if(params.data.itemStatus.toLowerCase() == 'initial'){
									return 'open '+params.data.docuSignStatus
								} else {
									return params.data.docuSignStatus;
								}

							}
							
							
						}
					},
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 125,
					// suppressFilter: true,
					filter: "agTextColumnFilter",
					cellRendererFramework: PunchlistDocuSignCellRenderer,
					suppressSorting: true
				},
				{
					headerName: this.punchlistdetail_translated_data.FAILURE_REASON,
					field: 'errorMessage',
					suppressFilter: true,
					hide: true,
					minWidth: 200
				},
				{
					headerName: this.punchlistdetail_translated_data.PUNCHLIST_OPEN_SENT_PDF,
					field: 'opensentpdf',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					cellStyle: {'white-space': 'normal' },
					minWidth: 200,
					cellRendererFramework: PunchlistsCellRenderComponent
				},
				{
					headerName: this.punchlistdetail_translated_data.PUNCHLIST_OPEN_RETURN_PDF,
					field: 'openreturnedpdf',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					cellStyle: {'white-space': 'normal' },
					minWidth: 200,
					cellRendererFramework: PunchlistsCellRenderComponent
				},
				{
					headerName: this.punchlistdetail_translated_data.PUNCHLIST_CLOSE_SENT_PDF,
					field: 'closesentpdf',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					cellStyle: {'white-space': 'normal' },
					minWidth: 200,
					cellRendererFramework: PunchlistsCellRenderComponent
				},
				{
					headerName: this.punchlistdetail_translated_data.PUNCHLIST_CLOSE_RETURN_PDF,
					field: 'closereturnedpdf',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					cellStyle: {'white-space': 'normal' },
					minWidth: 200,
					cellRendererFramework: PunchlistsCellRenderComponent
				},
				{
					headerName: this.punchlistdetail_translated_data.FIRST_OIL_REQUIRED,
					field: "firstOilRequired",
					width: 140,
					cellStyle: { 'text-align': 'center' },
				},
				{
				  headerName: this.punchlistdetail_translated_data.STATUS,
				  field: "status",
				  width: 75,
				  minWidth: 75,
				  maxWidth: 75,
				  //hide:!this.disableCap,
				  cellStyle: { 'text-align': 'center' },
				  cellRendererFramework: PunchlistsCellRenderComponent,
				},
				{
				  headerName: this.punchlistdetail_translated_data.EDIT,
				  field: "edit",
				  width: 75,
				  minWidth: 75,
				  maxWidth: 75,
				  //hide:!this.enableEditPunchlist,
				  cellStyle: { 'text-align': 'center' },
				  cellRendererFramework: PunchlistsCellRenderComponent,
				},
				{
				  headerName: this.punchlistdetail_translated_data.DELETE,
				  field: "delete",
				  width: 120,
				  minWidth: 120,
				  maxWidth: 120,
				  //hide:!this.enableDeletePunchlist,
				  cellStyle: { 'text-align': 'center' },
				  cellRendererFramework: PunchlistsCellRenderComponent,
				}
				

			];

		});

		this.rowSelection = 'multiple';
		this.isRowSelectable = function (rowNode) {
			return rowNode.data
		  };
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			getRowNodeId: function (data) { return data.id }
		};
		
	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {
		this.deletePunchlistSub = this.punchlistService.deletePunchlistTableDataResponse().subscribe((punchlistId: any) => this.updateUIAfterDelete(punchlistId));
		this.enableDisableSub = this.punchlistService.disableEnablePunchlistTableDataResponse().subscribe((option: any) => this.updateUIAfterEnableDisable(option.punchlistId, option.status));
		this.loadDataSub = this.punchlistService.loadPLTableDataResponse().subscribe((option: any) => this.refresh());
	}

	updateUIAfterEnableDisable(punchlistId: any, status: any) {
		console.log(punchlistId);
		console.log(status);
		this.refresh()
		let itemIndex = this.rowData.findIndex(item => item.id == punchlistId);
		if (this.rowData[itemIndex]) {
			this.rowData[itemIndex].edit= "edit:" + punchlistId + ":" + status;
			this.rowData[itemIndex].delete= "delete:" + punchlistId + ":" + status;
			this.rowData[itemIndex].status= "status:" + punchlistId + ":" + status;

			this.gridApi.setRowData(this.rowData);
		}
	}
	
	updateUIAfterDelete(punchlistId: any) {
		let itemIndex = this.rowData.findIndex(item => item.id == punchlistId);
		if (this.rowData[itemIndex]) {
			this.rowData.splice(itemIndex, 1);
		}
		this.gridApi.setRowData(this.rowData);
	}


	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//params.api.sizeColumnsToFit();
		// setTimeout(() => {
		// 	params.api.sizeColumnsToFit();
		// }, 500);
		// window.addEventListener("resize", function () {
		// 	setTimeout(function () {
		// 		params.api.sizeColumnsToFit();
		// 	});
		// });
		this.getDataFromServer()
	}

	onCellClick(event) {
		// console.log(event)
	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'Initial':
				return '<span class="badge badge-warning px-2 py-1">Initial</span>'
			case 'Open':
				return '<span class="badge badge-warning px-2 py-1">Open</span>'
			case 'Closed':
				return '<span class="badge badge-success px-2 py-1">Closed</span>'
			default:
				return ''
		}
	}

	loadData(payload: any) {
		this.rowData = [];
		this.gridApi.hideOverlay()
		this.gridApi.showLoadingOverlay()
		for (let i = 0; i < payload.length; i++) {
			let targetCompletionDate = ''
			let dateClosed = ''
			let location = ''
			if (payload[i]["estimatedCompletionDate"]) {
				targetCompletionDate = new Date(payload[i]["estimatedCompletionDate"]).toLocaleDateString()
			}
			if (payload[i]["dateClosed"]) {
				dateClosed = new Date(payload[i]["dateClosed"]).toLocaleDateString()
			}
			if (payload[i]["locationLat"] && payload[i]["locationLong"]) {
				location = payload[i]["locationLat"] + "," + payload[i]["locationLong"]
			}

			this.rowData.push({
				id: payload[i]["id"],
				category: payload[i]["category"],
				asset: this.decodeHTMLString.transform(payload[i]["assetName"]),
				vendor: this.decodeHTMLString.transform(payload[i]["vendorName"]),
				description: this.decodeHTMLString.transform(payload[i]["punchlistDescription"]),
				itemStatus: payload[i]["itemStatus"],
				dateClosed: dateClosed,
				targetCompletionDate: targetCompletionDate,
				sub_system: this.decodeHTMLString.transform(payload[i]["subSystemName"]),
				raised_by: payload[i]["raisedBy"],
				photo: payload[i]["photoName"],
				location: location,
				checkSheetInstanceId: payload[i]["checkSheetInstanceId"],
				docuSignId:  payload[i]["docuSignId"],
				docuSignStatus: payload[i]["docuSignStatus"],
				documents: payload[i]["punchlistDocuments"],
				dependencies: payload[i]["hasDependencies"] ? "dependencies:" + payload[i]["id"] : "disable_dependencies:" + payload[i]["id"],
				opensentpdf: "opensentpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                openreturnedpdf: "openreturnedpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                closesentpdf: "closesentpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                closereturnedpdf: "closereturnedpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
                openPunchlistGroupId: payload[i]["openPunchlistGroupId"],
            	closePunchlistGroupId: payload[i]["closePunchlistGroupId"],
                isOpenReturnPDFUploaded: payload[i]["isOpenReturnPDFUploaded"],
                isCloseReturnPDFUploaded: payload[i]["isCloseReturnPDFUploaded"],
				edit: "edit:" + payload[i]["id"] + ":" + payload[i]["status"],
				delete: "delete:" + payload[i]["id"] + ":" + payload[i]["status"],
				status: "status:" + payload[i]["id"] + ":" + payload[i]["status"],
				plstatus: payload[i]["status"],
				images: payload[i]["punchlistImages"],
				firstOilRequired: payload[i]["firstOilRequired"] ? "Yes" : "No",
				errorMessage: payload[i]["outputErrorMessage"] != null ? payload[i]["outputErrorMessage"] : payload[i]["docusignFailureReason"] != null ?  payload[i]["docusignFailureReason"] : ''
			});
			//this.dependenciesmodalService.getDependeciesWithId( payload[i]["id"], Constants.DEPENDENCY_ITEM_TYPE.PUNCHLIST, this);
		}
		this.gridApi.hideOverlay()
	}

	public refresh() {
		this.selectedItem = null
		this.selectedPunchlists = []
		this.punchlistStatus = ''
		this.resetWorkflowVariables()
		this.getDataFromServer()
	}

    /**
     * get data from server
     * @param systemId 
     * @param isSubSystem 
     */
	public getDataFromServer() {
		this.gridApi.showLoadingOverlay()
		this.punchlistService.getPunchlists()
			.subscribe(
			data => {
				console.log(data.payload);
				this.loadData(data.payload)
			},
			error => {
				this.gridApi.hideOverlay()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	downloadImage(image:any) {
        let imageId = image.id;
        let punchlistId = image.itemId;
        let projectId = image.projectId;
        let fileName = image.fileName;
        let filePath = image.filePath;
        let fileExtention = fileName.substring(fileName.lastIndexOf("."));
        
        this.loadingService.showLoading(false, false, '', 0);
        this.punchlistService.downloadImage(projectId, punchlistId, filePath)
        .subscribe(
            response => {
                this.loadingService.hideLoading()
                let file = new Blob([response], { type: 'image/' + fileExtention });
                FileSaver.saveAs(file, "PL_" + fileName);
            },
            error => {
                this.loadingService.hideLoading()
                this.alertService.clear()
                this.alertService.error("Could not download the file.");
            });
    }

	
	public downloadDocument(doc:any) {
		let fileExtention = doc.contentType;
		let outputId = doc.id;
		let outputFile = null;
		let punchlistId = doc.punchlistId;
		let projectId = doc.projectId;
		let fileName = doc.fileName;
	  
		this.loadingService.showLoading(false, false, '', 0);
		this.punchlistService.downloadFile(outputId, punchlistId, projectId, fileExtention, fileName)
		.subscribe(response => {
		  console
			  this.loadingService.hideLoading()
			  let file = new Blob([response], { type: 'application/pdf' });
			  //let file = new Blob([response], { type: response.type });
			  FileSaver.saveAs(file, fileName);
			},
			error => {
			  this.loadingService.hideLoading()
			  this.alertService.clear()
			  this.alertService.error("Could not download the file.");
			});
	  }

	  public loadAddPunchlistView() {
		var loadingMessage;  
		this.translate.get('GENERAL.LOADING').subscribe((res: string) => {
			loadingMessage = res;
		});
		//show loading
		this.loadingService.showLoading(true, false, loadingMessage, 0); 
		this.bsModalRef = this.modalService.show(AddpunchlistComponent, { class: 'modal-lg', backdrop: 'static', keyboard: false })
	}

	/**
	 * get signers list for opening punchlists
	 */
	getSignersListOpen(systemNo:any, assetNo:any){
		var signersIdList = [this.assignee1, this.assignee2, this.assignee3, this.assignee4]
		var taskNameList = [this.taskname1, this.taskname2, this.taskname3, this.taskname4];
		var signersList: any[] = [];
		var i= 1;		
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 
				'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 
				'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskListOpen[i-1].id, 
				'wfOrder': i, 'doctype':'PunchlistGroup', 'actiontype':'Open', 'systemno': systemNo.replace(/"/g, '\\"') , 'assetno' : assetNo.replace(/"/g, '\\"') });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				signersList.push({'signerId': "" , 'signerName': assigneeName , 'signerEmail': assignee, 
				'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 
				'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskListOpen[i-1].id,
				 'wfOrder': i, 'doctype':'PunchlistGroup', 'actiontype':'Open', 'systemno': systemNo.replace(/"/g, '\\"') , 'assetno' : assetNo.replace(/"/g, '\\"') });
				i++;
			}
		});
		return signersList;
			
		
	 }

	/**
	 *  get signers list for closing punchlists
	 */
	 getSignersListClose(systemNo:any, assetNo:any){
		var signersIdList = [this.assignee1, this.assignee2, this.assignee3]
		var taskNameList = [this.taskname1, this.taskname2, this.taskname3];
		var signersList: any[] = [];
		var i= 1;
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 
				'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation,
				 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskListClose[i-1].id, 
				 'wfOrder': i, 'doctype':'PunchlistGroup', 'actiontype':'Close', 'systemno': systemNo.replace(/"/g, '\\"') , 'assetno' : assetNo.replace(/"/g, '\\"') });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				signersList.push({'signerId': "" , 'signerName': assigneeName , 'signerEmail': assignee, 
				'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 
				'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskListClose[i-1].id, 
				'wfOrder': i, 'doctype':'PunchlistGroup', 'actiontype':'Close', 'systemno': systemNo.replace(/"/g, '\\"') , 'assetno' : assetNo.replace(/"/g, '\\"') });
				i++;
			}
		});
		return signersList;
	 }


	 /**
	  * start the workflow for the multiple punchlists
	  */
	 startWorkflowMultiple(){
		 if(this.selectedInitialPunchlists.length > 0 && this.selectedOpenPunchlists.length > 0){
			this.alertService.clear()
			this.openAndInitialPLWarning = this.translate.instant('PUNCHLIST_WORKFLOW.SELECT_BOTH_OPEN_AND_INITIAL_PUNCHLISTS_WARNING');
			this.alertService.warn(this.openAndInitialPLWarning)
		 } else if(this.hasFileSizeExceededMaxSize()){
			this.alertService.clear()
			this.maxFileSizeWarning = this.translate.instant('PUNCHLIST_WORKFLOW.MAX_FILE_SIZE_WARNING');
			this.alertService.warn(this.maxFileSizeWarning)
		 } else if(this.selectedInitialPunchlists.length > 0 && this.selectedOpenPunchlists.length == 0){
			this.punchlistStatus = "Initial"
			this.startWorkflowOpen()
		 } else if(this.selectedInitialPunchlists.length == 0 && this.selectedOpenPunchlists.length > 0){
			this.punchlistStatus = "Open"
			this.startWorkflowClose()
		 } else{
			 //do nothing
		 }
	}

	/**
	 * start the workflow for a single punchlist
	 * @param params 
	 * @param action 
	 */
	startWorkflowSingle(params: any){
		this.selectedItem = params.data;
		if(this.selectedPunchlists.length > 1){
			var isPLAvailable = this.punchlistExists(this.selectedItem.id, this.selectedPunchlists)
			if(isPLAvailable){
				this.alertService.clear()
				this.multiplePLWarning = this.translate.instant('PUNCHLIST_WORKFLOW.MULTIPLE_PL_WARNING')
				this.alertService.warn(this.multiplePLWarning)
			}else{
				this.selectedPunchlists = []
				this.addElementToObjectArray(this.selectedItem)
				if(this.selectedItem.itemStatus == "Initial"){
					this.punchlistStatus = "Initial";
					this.startWorkflowOpen()
				} else if(this.selectedItem.itemStatus == "Open"){
					this.punchlistStatus = "Open";
					this.startWorkflowClose()
				} else{
					//do nothing
				}
			}
		} else{
			if(this.selectedItem.plstatus == "Inactive"){
				this.alertService.clear()
				this.inactivePLWarning = this.translate.instant('PUNCHLIST_WORKFLOW.INACTIVE_PL_WARNING')
				this.alertService.warn(this.inactivePLWarning)
			} else{
				this.selectedPunchlists = []
				this.addElementToObjectArray(this.selectedItem)
				if(this.hasFileSizeExceededMaxSize()){
					this.alertService.clear()
					this.maxFileSizeWarning = this.translate.instant('PUNCHLIST_WORKFLOW.MAX_FILE_SIZE_WARNING');
					this.alertService.warn(this.maxFileSizeWarning)
				} else{
					if(this.selectedItem.itemStatus == "Initial"){
						this.punchlistStatus = "Initial";
						this.startWorkflowOpen()
					} else if(this.selectedItem.itemStatus == "Open"){
						this.punchlistStatus = "Open";
						this.startWorkflowClose()
					} else{
						//do nothing
					}
				}
			}
		}
	}

	/**
	 * Get the newly added punchlist details and navigate to the punchlist open workflow
	 * @param punchlistIds 
	 */
	saveAndStartWorkflow(punchlistIds: string){
		this.punchlistService.getPunchlistDetailsByPunchlistIds(punchlistIds).subscribe(
			data =>{
				if(data.payload != null){
					this.punchlistStatus = 'Initial'; //set punchlist status to Initial since those are newly added punchlists from the dashboard
					this.selectedPunchlists = [] //initialize the selectedPunchlists
					data.payload.forEach(element => {
						var plObject = {
							"id": element.id,
							"category": element.category,
							"description": element.punchlistDescription,
							"asset": element.assetName,
							"sub_system": element.systemNumber + " " + element.subSystemName
						}
						this.addElementToObjectArray(plObject); //set the selected punchlists
					});
					this.startWorkflowMultiple(); 
				}
			},
			error =>{
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		);
	}

	 /**
	  * display the selected punchlists and the signatory page for opening punchlists
	  */
	  startWorkflowOpen(){
		this.loadingService.showLoading(false, false, '', 0)
		this.resetWorkflowVariables()
		this.eventType = this.widgetEvent.SHOW_WIZARD
		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id , "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('PunchlistOpen')
					.subscribe(data => {
						this.loadingService.hideLoading();
						this.workflowTaskListOpen = data.payload
						if (data.payload) {
							this.workflowTaskListOpen.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					}, error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			}, error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})
	  }

	  /**
	   * display the selected punchlists and the signatory page for closing punchlists
	   */
	  startWorkflowClose(){
		this.loadingService.showLoading(false, false, '', 0)
		this.resetWorkflowVariables()
		this.eventType = this.widgetEvent.SHOW_WIZARD
		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id , "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('PunchlistClose')
					.subscribe(data => {
						this.loadingService.hideLoading()
						this.workflowTaskListClose = data.payload
						if (data.payload) {
							this.workflowTaskListClose.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					}, error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			}, error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})
	  }

	  /**
	   * check the punchlist status and execute the relevant method
	   */
		finishFunction(){
			this.isSubmitted = true;
		if(!this.validateAssigneeData()){
			return;

		}
		else{
				if(this.punchlistStatus == "Initial"){
					this.finishFunctionOpen()
				} else if(this.punchlistStatus == "Open"){
					this.finishFunctionClose()
				} else{

				}
	  	}}

		  validateAssigneeData(): boolean{
			this.validAssignee1 = true;
			this.validAssignee2= true
			this.validAssignee3= true
			this.validAssignee4 = true
			this.validAssigneeEmail1 = true;
			this.validAssigneeEmail2= true
			this.validAssigneeEmail3= true
			this.validAssigneeEmail4 = true
	
			let validation = true;

			if(this.punchlistStatus == "Initial"){
	
				if (this.assignee1 == null ||this.assignee1 == ''){
					this.validAssignee1 = false;
					validation = false;
					
				}
				
				if (this.assignee2 == null ||this.assignee2 == ''){
					this.validAssignee2 = false;
					validation = false;
				}
				
				if (this.assignee3 == null ||this.assignee3 == ''){
					this.validAssignee3 = false;
					validation = false;
				}
				
				if (this.assignee4 == null ||this.assignee4 == ''){
					this.validAssignee4 = false;
					validation = false;
				}
				if(this.assignee1 !='' && this.assignee1 != null ){
				if(!this.userList.find(user => user.id == this.assignee1)){
					if(!this.emailValidate(this.assignee1)){
						this.validAssigneeEmail1 = false;
						validation = false;
	
					}
				}
				}
				if(this.assignee2 !='' && this.assignee2 != null ){
				if(!this.userList.find(user => user.id == this.assignee2)){
					if(!this.emailValidate(this.assignee2)){
						this.validAssigneeEmail2 = false;
						validation = false;
	
					}
				}
				}
				if(this.assignee3 !='' && this.assignee3 != null ){
				if(!this.userList.find(user => user.id == this.assignee3)){
					if(!this.emailValidate(this.assignee3)){
						this.validAssigneeEmail3 = false;
						validation = false;
	
					}
				}
				}
				
				if(this.assignee4 !='' && this.assignee4 != null ){
				if(!this.userList.find(user => user.id == this.assignee4)){
					if(!this.emailValidate(this.assignee4)){
						this.validAssigneeEmail4 = false;
						validation = false;
	
					}
				}
				}
			}
			else if(this.punchlistStatus == "Open"){
	
				if (this.assignee1 == null ||this.assignee1 == ''){
					this.validAssignee1 = false;
					validation = false;
					
				}
				
				if (this.assignee2 == null ||this.assignee2 == ''){
					this.validAssignee2 = false;
					validation = false;
				}
				
				if (this.assignee3 == null ||this.assignee3 == ''){
					this.validAssignee3 = false;
					validation = false;
				}
				
				if(this.assignee1 !='' && this.assignee1 != null ){
				if(!this.userList.find(user => user.id == this.assignee1)){
					if(!this.emailValidate(this.assignee1)){
						this.validAssigneeEmail1 = false;
						validation = false;
	
					}
				}
				}
				if(this.assignee2 !='' && this.assignee2 != null ){
				if(!this.userList.find(user => user.id == this.assignee2)){
					if(!this.emailValidate(this.assignee2)){
						this.validAssigneeEmail2 = false;
						validation = false;
	
					}
				}
				}
				if(this.assignee3 !='' && this.assignee3 != null ){
				if(!this.userList.find(user => user.id == this.assignee3)){
					if(!this.emailValidate(this.assignee3)){
						this.validAssigneeEmail3 = false;
						validation = false;
	
					}
				}
				}

			}
				return validation;
	
		}
	  /**
	   * Generate the Open punchlist
	   */
	private finishFunctionOpen() {
		this.loadingService.showLoading(false, false, '', 0)
		var systemNo = "";
		var assetNo = "";
		//Getting asset/system no for docusign email
		var selectedPLIdList = this.getPunchlistIdsDocuSign();
		this.punchlistService.getPunchlistDetailsByPunchlistIds(selectedPLIdList).subscribe(
			data => {
				if (data.payload != null) {
					//this.punchlistStatus = 'Initial'; //set punchlist status to Initial since those are newly added punchlists from the dashboard
					//this.selectedPunchlists = [] //initialize the selectedPunchlists
					data.payload.forEach(element => {
						if (element.assetName != null && element.assetName.trim() != "") {
							assetNo += element.assetName + ", "
						} else {
							systemNo += element.fullSystemNo + ", "
						}
					});
				}
				if (systemNo.trim() != "") {
					systemNo = systemNo.trim().slice(0, -1)
				}
				if (assetNo.trim() != "") {
					assetNo = assetNo.trim().slice(0, -1)
				}
				var signerslist = this.getSignersListOpen(systemNo, assetNo);
				var selectedPLIdList = this.getPunchlistIds();
				var plOpenObject = {
					"punchlistIds": selectedPLIdList,
					"signersList": JSON.stringify(signerslist),
					"doctype": "PLOpen",
					"action": "Open"
				}
				console.log(JSON.stringify(plOpenObject))
				this.punchlistService.generatePunchlistPDF(plOpenObject, this)
			},
			error => {
				this.alertService.error(error.statusDescription)
			}
		);

	}

	/**
	 * Generate the Close punchlist
	 */
	private finishFunctionClose() {
		this.loadingService.showLoading(false, false, '', 0);
		var systemNo = "";
		var assetNo = "";
		//Getting asset/system no for docusign email
		var selectedPLIdList = this.getPunchlistIdsDocuSign();
		this.punchlistService.getPunchlistDetailsByPunchlistIds(selectedPLIdList).subscribe(
			data =>{
				if(data.payload != null){
					//this.punchlistStatus = 'Initial'; //set punchlist status to Initial since those are newly added punchlists from the dashboard
					//this.selectedPunchlists = [] //initialize the selectedPunchlists
					data.payload.forEach(element => {
						if (element.assetName != null && element.assetName.trim() != "") {
							assetNo += element.assetName + ", "	
						} else {
							systemNo += element.fullSystemNo + ", "
						}
					});
				}
				if (systemNo.trim() != "") {
					systemNo = systemNo.trim().slice(0, -1)
				}
				if (assetNo.trim() != "") {
					assetNo = assetNo.trim().slice(0,-1)
				}
				var signerslist = this.getSignersListClose(systemNo, assetNo);
				var selectedPLIdList = this.getPunchlistIds();
				var plCloseObject = {
					"punchlistIds": selectedPLIdList,
					"signersList": JSON.stringify(signerslist),
					"doctype": "PLClose",
					"action": "Close"
				}
				console.log(JSON.stringify(plCloseObject))
				this.punchlistService.generatePunchlistPDF(plCloseObject, this)
			},
			error =>{
				this.alertService.error(error.statusDescription)
			}			
		);

		// //////////////////////////////////////////
		// var signerslist =  this.getSignersListClose();
		// var selectedPLIdList = this.getPunchlistIds();
		// var plCloseObject = {
		// 	"punchlistIds": selectedPLIdList, 
		// 	"signersList": JSON.stringify(signerslist), 
		// 	"doctype": "PLClose",
		// 	"action": "Close"
		// }
		// console.log(JSON.stringify(plCloseObject))
		// this.punchlistService.generatePunchlistPDF(plCloseObject, this)
	}

	/**
	 * Get selected punchlist id list 
	 */
	getPunchlistIds(): string{
		var plIdList = "";
		for(var i = 0; i < this.selectedPunchlists.length; i++){
			if(i != this.selectedPunchlists.length - 1){
				plIdList += this.selectedPunchlists[i].key + ","
			}else{
				plIdList += this.selectedPunchlists[i].key 
			}	
		}
		return plIdList;
	}


	/**
	 * Get selected punchlist id list for docusign
	 */
	 getPunchlistIdsDocuSign(): string{
		var plIdList = "";
		for(var i = 0; i < this.selectedPunchlists.length; i++){
			if(i != this.selectedPunchlists.length - 1){
				plIdList += this.selectedPunchlists[i].key + "_"
			}else{
				plIdList += this.selectedPunchlists[i].key 
			}	
		}
		return plIdList;
	}

	/**
	 * Get the style for the punchlist status
	 * @param status 
	 */
	public getStyle(status: any) {
		if (status == 'Closed') {
			return 'badge-success'
		} else {
			return 'badge-warning'
		}
	}

	/**
	 * fire this event when selects/deselects checkboxes
	 * @param event 
	 */
	onRowSelected(event: any){
		if(event.data){
			var isPLAvailable = this.punchlistExists(event.data.id, this.selectedPunchlists)
			if(!isPLAvailable){
				this.addElementToObjectArray(event.data)
			}else{
				this.removeElementFromObjectArray(event.data.id)
			}
		}
	}

	/**
	 * restrict to add either types(Initail and Open) of punchlists
	 * @param data 
	 */
	addElementToObjectArray(data: any){
		if(data.itemStatus == 'Initial' || this.punchlistStatus == 'Initial'){
			this.selectedInitialPunchlists.push({key: data.id, value: data})
		}else if(data.itemStatus == 'Open' || this.punchlistStatus == 'Open'){
			this.selectedOpenPunchlists.push({key: data.id, value: data})
		}else{
			//do nothing
		}
		this.selectedPunchlists.push({key: data.id, value: data})
	}
	
	/**
	 * check whether the punchlist exists or not
	 * @param plId 
	 * @param selectedItems 
	 */
	punchlistExists(plId: string, selectedItems: any) {
		return selectedItems.some(function(el) {
		  return el.key === plId;
		}); 
	  }

	/**
	 * remove unselected item from the list
	 * @param key 
	 */
	removeElementFromObjectArray(key: string) {
		let itemIndex = this.selectedPunchlists.findIndex(item => item.value.id == key);
		this.selectedPunchlists.splice(itemIndex, 1);

		var isInitialPLAvailable = this.punchlistExists(key, this.selectedInitialPunchlists)
		if(isInitialPLAvailable){
			let itemIndexInitial = this.selectedInitialPunchlists.findIndex(item => item.value.id == key);
			this.selectedInitialPunchlists.splice(itemIndexInitial, 1);
		} else{
			//do nothing
		}
		
		var isOpenPLAvailable = this.punchlistExists(key, this.selectedOpenPunchlists)
		if(isOpenPLAvailable){
			let itemIndexOpen = this.selectedOpenPunchlists.findIndex(item => item.value.id == key);
			this.selectedOpenPunchlists.splice(itemIndexOpen, 1);	
		} else{
			//do nothing
		}
	} 

	/**
	 * Check the total file size of punchlist documents and images before starting the workflow
	 */
	hasFileSizeExceededMaxSize() : boolean{
		let totalFileSize = 0;
		this.selectedPunchlists.forEach(punchlist => {
			if(punchlist.value.documents != null){
				punchlist.value.documents.forEach(plDocument => {
					totalFileSize += plDocument.fileSize
				});
			}
			if(punchlist.value.images != null){
				punchlist.value.images.forEach(plImage => {
					totalFileSize += plImage.fileSize
				});
			}
		});
		if(totalFileSize > this.MAX_FILE_SIZE){
			return true
		} else{
			return false
		}
	}

	/**
	 * reset variables
	 */
	resetWorkflowVariables(){
		this.workflowTaskListOpen = []
		this.workflowTaskListClose = []
		this.selectedAssignee = []
		this.assignee1 = null
		this.assignee2 = null
		this.assignee3 = null
		this.assignee4 = null
		this.taskname1 = null
		this.taskname2 = null
		this.taskname3 = null
		this.taskname4 = null
		this.selectedInitialPunchlists = []
		this.selectedOpenPunchlists = []
	}

	/**
	 * Validate the entered email address
	 * @param value 
	 * @param i 
	 */
	isValidEmailAddress(value: any, i: any) {
		let result = true;
		if (i == 0) {
			if (this.assignee1) {
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
			}
		} else if (i == 1) {
			if (this.assignee2) {
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
			}
		} else if (i == 2) {
			if (this.assignee3) {
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
			}
		} else if (i == 3) {
			if (this.assignee4) {
				if (!this.userList.find(user => user.id == this.assignee4)) {
					if (!this.emailValidate(this.assignee4)) {
						result = false;
					}
				}
			}
		}
		return result
	}

	emailValidate(value: any) {
		let isValid = /^[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+(\.[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
		return isValid
	}

	getTaskName(taskName: any) {
		var correctTaskName = taskName.replace(/([a-z])([A-Z])/g, '$1 $2')
		return correctTaskName
	}

	textChanged(event: any, i: any , taskname: any) {
		console.log('[textChanged] (event) ' + JSON.stringify(event));
		if (event) {
			if (i == 1) {
				this.assignee1 = event.value
				this.taskname1 = this.getTaskName(taskname);
			} else if (i == 2) {
				this.assignee2 = event.value
				this.taskname2 = this.getTaskName(taskname);
			} else if (i == 3) {
				this.assignee3 = event.value
				this.taskname3 = this.getTaskName(taskname);
			} else if (i == 4) {
				this.assignee4 = event.value
				this.taskname4 = this.getTaskName(taskname);
			}
		} else {
			if (i == 1) {
				this.assignee1 = null
				this.taskname1 = null
			} else if (i == 2) {
				this.assignee2 = null
				this.taskname2 = null
			} else if (i == 3) {
				this.assignee3 = null
				this.taskname3 = null
			} else if (i == 4) {
				this.assignee4 = null
				this.taskname4 = null
			}
		}
	}

	isAssigneesAdded() {
		if(this.punchlistStatus == "Initial"){
			if (this.assignee1 && this.assignee2 && this.assignee3 && this.assignee4) {
				let result = true;
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
				if (!this.userList.find(user => user.id == this.assignee4)) {
					if (!this.emailValidate(this.assignee4)) {
						result = false;
					}
				}
				return result
			} else {
				return false
			}
		} else if(this.punchlistStatus == "Open"){
			if (this.assignee1 && this.assignee2 && this.assignee3) {
				let result = true;
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
				return result
			} else {
				return false
			}
		} else{
			return false
		}
	}

	private backToTable(callDeleteClosureComment: boolean = true) {
		this.isSubmitted = false;
		this.selectedItem = null
		this.selectedPunchlists = []
		if (callDeleteClosureComment) {
			this.deletePLClosureComments();
		}
		this.plClosureComments = []
		this.punchlistStatus = ''
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	onSuccess(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading()
		if (serviceType == WsType.GENERATE_PUNCHLIST) {
			this.backToTable(false)
			this.resetWorkflowVariables()
		}
		if (data.payload != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
		  	let items = data.payload.split("_")
			if(items[1]=="Yes")
			{
				for(let i = 0; i < this.rowData.length; i++)
				{
					if(this.rowData[i].id == items[0])
					{
						//if dependencies are available, then enable the dependency link
						var rowNode = this.gridApi.getRowNode(items[0]);
						rowNode.setDataValue("dependencies", "dependencies:" + items[0])
					}
				}
			}
		}
		else if (serviceType == WsType.REVOKE_PUNCHLIST) {
			this.alertService.success("Successfully revoked the punchlist(s)");
			this.refresh(); //refresh the punchlist grid 
		}
				
	}

	onFail(data: WsResponse, serviceType: WsType) {
		this.loadingService.hideLoading();
		if (serviceType == WsType.GENERATE_PUNCHLIST) {
			this.alertService.error(data.statusDescription);
		}
		if (serviceType == WsType.REVOKE_PUNCHLIST) {
			this.alertService.error(data.statusDescription);
		}

	}

	ngAfterViewInit(): void {
		this.editPlId = this.sharedService.editPunchlistResponse().subscribe((option: any) => this.edit());
		//this is called when clicks on the 'Save and Workflow' from the add punchlist
		this.punchlistIds = this.sharedService.getAddedPunchlists().subscribe((data:any) => {
			this.saveAndStartWorkflow(data.punchlistIds)
		});
	  }
	  
	  ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.editPlId) {
		  this.editPlId.unsubscribe()
		}
		if (this.punchlistIds) {
			this.punchlistIds.unsubscribe()
		}
		if (this.deletePunchlistSub) {
		  this.deletePunchlistSub.unsubscribe()
		}
		if (this.enableDisableSub) {
		  this.enableDisableSub.unsubscribe()
		}
		if (this.loadDataSub) {
		  this.loadDataSub.unsubscribe()
		}
	  }
	  public edit() {
		//open edit popup
		this.bsModalRef = this.modalService.show(EditpunchlistComponent, { class: 'modal-lg', backdrop: 'static', keyboard: false })
	}
	onFieldBlur(item: any) {
        // save the editable text along with the pl's ID .

        var itemId = item.key;
        var editedText = item.editableText;
		

		// Check if there's an existing entry with the same ID
		var existingEntryIndex = this.plClosureComments.findIndex(entry => entry.id === itemId);
		// Save the ID and comment to the  array
		if (existingEntryIndex !== -1) {
			if(editedText != '' && editedText != null){
				// Update the existing entry
				this.plClosureComments[existingEntryIndex].closureComment = editedText;
			}
			else if (editedText == '' || editedText == null){
		
				// Update the existing entry 
				this.plClosureComments[existingEntryIndex].closureComment = '';
			}
		} else {
			if(editedText != '' && editedText != null){
				// Add a new entry
				this.plClosureComments.push({ id: itemId, closureComment: editedText });
			} else if (editedText == '' || editedText == null){
				// Add a new entry
			this.plClosureComments.push({ id: itemId, closureComment: '' });
			}
			
		}
       
    }

	public saveComment(){
		for (const item of this.selectedPunchlists) {
			// Check if the corresponding comment exists in plClosureComments
			var existingComment = this.plClosureComments.find(closureComment => closureComment.id === item.value.id);
		  
			// If no comment exists, add an empty string to plClosureComments
			if (!existingComment) {
			  this.plClosureComments.push({ id: item.value.id, closureComment: '' });
			}
		  }
		  const payload = { itemList: this.plClosureComments };

		  var json = JSON.stringify(this.plClosureComments);
		  this.punchlistService.updatePunchlistWithClousreCommentWithId(json,this);
		  
		
	}

	public deletePLClosureComments(){

		if(this.plClosureComments != null){
			let ids = "";
			for(let i = 0; i < this.plClosureComments.length; i++){
				ids += this.plClosureComments[i].id+",";
			}
			this.punchlistService.deletePLClosureComments(ids,this);

		}
	}
	public previous(){
		this.isSubmitted = false;
	}

	
}
