import { Component, OnInit, AfterContentInit, ComponentFactoryResolver, ElementRef, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ViewChildren, ViewContainerRef, QueryList } from '@angular/core'
import { LocalStorage } from '../util/localstorage.service';
import { Widget } from '../widgets/widget.model';
import { SharedService } from '../util/shared.service';
import { WidgetService } from '../widgets/widget.service';
import { UserVariable } from '../util/common/user-variable';
import { DashboardService } from './dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../util/alert/alert.service';

declare var $: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

	public widgetList: Array<Widget> = new Array<Widget>()
	public activeWidgetList: Array<Widget> = new Array<Widget>()
	public isSideBar: boolean = true
	public isShowFullScreenWidget: boolean = false
	public fullScreenWidget: Array<Widget> = new Array<Widget>()
	public projectName: string

	@ViewChildren('widgetBody', { read: ViewContainerRef }) widgetHosts: QueryList<ViewContainerRef>;
	@ViewChild('main') main: ElementRef
	@ViewChild('navPanel') navPanel: ElementRef

	public paddingtop: number = 59;
	public widgetListName: string //new
	public widgetlist: any //new
	public temp: any //new

	constructor(private sharedService: SharedService,
		private widgetService: WidgetService,
		private resolver: ComponentFactoryResolver,
		private router: Router,
		private alertService: AlertService) {

		this.projectName = localStorage.getItem(LocalStorage.PROJECT_NAME);
		// UserVariable.projectId = localStorage.getItem(LocalStorage.PROJECT_ID);
		// UserVariable.userId = localStorage.getItem(LocalStorage.USER_ID);
		// UserVariable.clientId = localStorage.getItem(LocalStorage.CLIENT_ID);
		if (UserVariable.userId == null) {
			//Navigate to Login Page
			this.router.navigateByUrl('login');
		} else if (UserVariable.projectId == null) {
			//Navigate to Project selection page
			this.router.navigateByUrl('landing');
		} else {
			//Has Necessary info, so continue.
		}

		// this.widgetList = this.widgetService.loadWidgetListByNumber('dashboard')


		// this.fullScreenWidget = [
		// 	{
		// 		widgetId: '004',
		// 		widgetName: 'Mechanical Completion',
		// 		component: null,
		// 		widgetSize: 'col-lg-12',
		// 		isMinimized: false,
		// 		canRefresh: true
		// 	}]
	}

	ngOnInit() {
		this.widgetList = []

		this.widgetService.getProjectDashboard('dashBoardWidgetList').subscribe(
			data => {
				console.log('[ngOnInit] (data) ' + JSON.stringify(data));
				if (data.payload != null) {
					this.widgetList = JSON.parse(data.payload.widgetConfig);
					setTimeout(() => {
						this.loadComponents(this.widgetList)
					}, 0);

				} else {
					//do nothing
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		if (this.navPanel.nativeElement.offsetHeight > 59) {
			this.paddingtop = 80
		} else {
			this.paddingtop = 48
		}
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.csbvExecute().subscribe(data => this.showHideSideBar())
		// this.sharedService.sfswExecute().subscribe(data => this.showFullScreenWidget())
		this.sharedService.loadWidgetListExecute().subscribe(data => this.changeWidgetList(data))
		// this.sharedService.changeScrollExecute().subscribe(data => this.changeScrollPosition(data.elementRef))
	}

	ngAfterViewInit() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
	}

	public showHideSideBar() {
		this.isSideBar = !this.isSideBar		
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 0);
	}

	public minimizeWidget(widget: Widget) {
		widget.isMinimized = !widget.isMinimized
	}

	// public showFullScreenWidget() {
	// 	this.isShowFullScreenWidget = true
	// }

	// public exitFullScreen(widget: Widget) {
	// 	this.sharedService.csmiRequest()
	// 	this.isShowFullScreenWidget = false
	// }

	private loadComponents(widgetList: any) {
		let i: number = 0
		widgetList.forEach(widget => {
			this.loadComponent(widget, i)
			i++
		});
	}

	private loadComponent(widget, index) {
		const component = this.widgetService.loadComponentByComponentName(widget.component);
		if (component) {
			const target = this.widgetHosts.toArray()[index];
			const widgetComponent = this.resolver.resolveComponentFactory(component);
			const cmpRef: any = target.createComponent(widgetComponent);
		}
	}

	public changeWidgetList(widgetList: any) {
		this.widgetList = []
		// console.log('[changeWidgetList] (widgetList) ' + JSON.stringify(widgetList));
		this.widgetList = widgetList.widgetList
		setTimeout(() => {
			this.loadComponents(widgetList.widgetList)
		}, 0);
	}

	/**
	 * change scroll
	 */
	private changeScrollPosition(er: ElementRef) {
		this.main.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
		// this.main.nativeElement.scrollTo({left: 0, top: 100, behavior: 'smooth'})
	}


}
