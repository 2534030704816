import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import { SystemTurnoverNoticeService } from './system-turnover-notice.service';
import { SharedService } from '../../util/shared.service';
import { WIDGET_EVENT } from '../../util/constants';
import { WorkflowService } from '../workflow.service';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { CustomTypeaheadComponent } from '../../util/custom-typeahead/custom-typeahead.component';
import { UserVariable } from '../../util/common/user-variable';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Guid } from "guid-typescript";
import { CapabilityService } from '../../util/capability.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import "ag-grid-enterprise";
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { SystemTurnoverNoticeDocuSignRenderer } from './system-turnover-notice-docu-sign/system-turnover-notice-docu-sign.component';
import { GridOptions } from "ag-grid";
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-system-turnover-notice',
	templateUrl: './system-turnover-notice.component.html',
	styleUrls: ['./system-turnover-notice.component.css']
})
export class SystemTurnoverNoticeComponent implements OnInit {

	public isMinimized: boolean = false
	rowData: any[] = [];
	private gridApi;
	private gridColumnApi;
	columnDefs;
	stn_translated_data: any = null;
	public vendorList: Array<any> = [];
	selectedItem: any = null
	public items: Array<any> = [];
	public selectedVendor: Array<any> = [];
	eventType: any = WIDGET_EVENT.SHOW_TABLE
	widgetEvent = WIDGET_EVENT
	public stnWorkflowTaskList: any[] = [];
	public userList: any[] = []
	public selectedSTN: any = null;
	public selectedSystem: any = null;
	@ViewChild("deleteSTNModal") deleteSTNModal: TemplateRef<any>;
	@ViewChild("editSTNModal") editSTNModal: TemplateRef<any>;
	@ViewChild("incompleteSTNModal") incompleteSTNModal: TemplateRef<any>;
	public deleteModalRef: BsModalRef;
	public editModalRef: BsModalRef;
	public incompleteModalRef: BsModalRef;
	public bsConfig: Partial<BsDatepickerConfig>
	public targetCompletionDate: Date = null
	public selectedSystemPhase: String;
	public required: boolean = null
	public allPunchlistCompleted: boolean = false;
	public allChecksheetsCompleted: boolean = false;
	public openPunchlists: any[] = [];
	public temp: any
	public selectedAssignee: any[] = null
	public ch1: any = false
	public ch2: any = false
	public ch3: any = true
	public ch4: any = true
	public ch5: any = true
	public ch6: any = false
	public ch7: any = false
	public ch8: any = false
	public ch9: any = false
	public ch10: any = false
	public ch11: any = false
	public ch12: any = false
	public ch13: any = false
	public ch14: any = false
	public ch15: any = false
	public ch16: any = false
	public ch17: any = false
	public ch18: any = false
	public ch19: any = false
	public comments: any = null
	public loading: boolean = false
	public workflowId: any = null
	public taskCount: any = 0
	assignee1: any
	assignee2: any
	assignee3: any
	taskname1:any
	taskname2:any
	taskname3:any
	modifySystemTurnover: boolean = false
	showRequiredErr: boolean = false

	isSubmitted: boolean = false
	validAssignee1: boolean = true
	validAssignee2: boolean = true
	validAssignee3: boolean = true

	validAssigneeEmail1: boolean = true
	validAssigneeEmail2: boolean = true
	validAssigneeEmail3: boolean = true

	public gridOptions: GridOptions;
	public systemPhaseValues:Array<any> = [];
	public selectedSystemPhaseVal: Array<any> = []
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };
	  

	constructor(private systemTurnoverNoticeService: SystemTurnoverNoticeService,
		private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService,
		private workflowService: WorkflowService,
		private userService: UserService,
		private systemService: SystemService,
		private capabilityService: CapabilityService,
		private bsModalService: BsModalService,
		private loadingService: LoadingService) {
			this.gridOptions = <GridOptions>{
				context: {
					componentParent: this
				}
			};
		this.modifySystemTurnover = capabilityService.isCapabilityAssigned("modifySystemTurnover");
		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
		var self = this
		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.stn_translated_data = res['STN']
			this.columnDefs = [
				{ headerName: this.stn_translated_data.SYSTEM, field: 'systemName', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 280 },
				{ headerName: this.stn_translated_data.STN_STATUS, field: 'stnStatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 100 },
				{ headerName: this.stn_translated_data.PTN_STATUS, field: 'ptnStatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 100 },
				{
					headerName: this.stn_translated_data.TARGET_COMPLETION_DATE, field: 'newtargetCompletionDate', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				}
			];

			if (this.modifySystemTurnover) {
				this.columnDefs.push({
					headerName: this.stn_translated_data.EDIT,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 70,
					suppressFilter: true,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						eDiv.innerHTML = '<button id="edit" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="far fa-edit mx-2" aria-hidden="true"></i> </button>';
						var eButton = eDiv.querySelectorAll('.btn-sm')[0];

						eButton.addEventListener('click', function () {
							self.showEdit(params);
						});
						return eDiv;
					},
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.stn_translated_data.START_WORKFLOW,
					//field: 'isStart',
					valueGetter: function(params) {
						if(params.data.isStart) {
							return params.data.isStart;
						} else {
							return params.data.docuSignStatus == null ? '' : params.data.docuSignStatus;
						}
					},
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 125,
					filter: "agTextColumnFilter",
					//suppressFilter: true,
					// cellRenderer: function (params) {
					// 	var eDiv = document.createElement('div');
					// 	if (params.value) {
					// 		eDiv.innerHTML = '<button *ngIf="params.value" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
					// 			'<i class="fas fa-play mx-2" aria-hidden="true"></i>' +
					// 			'</button>';
					// 		var eButton = eDiv.querySelectorAll('.btn-sm')[0];

					// 		eButton.addEventListener('click', function () {
					// 			self.showWizard(params)
					// 		});
					// 	} else {
					// 		eDiv.innerHTML = '';
					// 	}
					// 	return eDiv;
					// },
					cellRendererFramework: SystemTurnoverNoticeDocuSignRenderer,
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.stn_translated_data.FAILURE_REASON,
					valueGetter: function(params) {
						if(params.data.outputErrorMessage != null){
							return params.data.outputErrorMessage;
						} else if(params.data.docusignFailureReason != null){
							return params.data.docusignFailureReason;
						} else{
							return '';
						}
					},
					suppressFilter: true,
					hide: true,
					minWidth: 200
				})
				this.columnDefs.push({
					headerName: this.stn_translated_data.DELETE,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 80,
					suppressFilter: true,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if (params.data.stnStatus == 'Issued' || params.data.stnStatus == 'Approved'
								|| params.data.ptnStatus == 'Issued' || params.data.ptnStatus == 'Approved' || params.data.stnStatus == "Error" || params.data.ptnStatus == "Error") {
								eDiv.innerHTML = '<button id="edit" click="dataClick()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="fas fa-undo mx-2" aria-hidden="true"></i> </button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									self.showDeleteConfirmation(params);
								});

							} else {
								eDiv.innerHTML = ''
							}
						} else {
							eDiv.innerHTML = ''
						}
						return eDiv;
					},
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.stn_translated_data.SENT_PDF,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 70,
					suppressFilter: true,
					hide: true,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if ((params.data.stnStatus == 'Issued' || params.data.stnStatus == 'Approved'
								|| params.data.ptnStatus == 'Issued' || params.data.ptnStatus == 'Approved')) {
								eDiv.innerHTML = '<a class="btn btn-sm edit"><i class="fas fa-file-pdf" aria-hidden="true"></i>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									self.downloadSentFile(params)
								});

							} else {
								eDiv.innerHTML = ''
							}
						} else {
							eDiv.innerHTML = ''
						}
						return eDiv;
					},
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.stn_translated_data.RETURNED_PDF,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 90,
					suppressFilter: true,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if ((params.data.stnStatus == 'Issued' || params.data.stnStatus == 'Approved'
								|| params.data.ptnStatus == 'Issued' || params.data.ptnStatus == 'Approved') && params.data.isUploaded == 'true') {
								eDiv.innerHTML = '<a class="btn btn-sm edit"><i class="fas fa-file-pdf" aria-hidden="true"></i>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									self.downloadFile(params)
								});

							} else {
								eDiv.innerHTML = ''
							}
						} else {
							eDiv.innerHTML = ''
						}
						return eDiv;
					},
					suppressSorting: true
				})
			}
			// this.columnDefs.push({
			// 	headerName: this.stn_translated_data.DOCUSIGN_STATUS,
			// 	field: 'docuSignStatus',
			// 	autoHeight: true,
			// 	cellClass: "cell-wrap-text",
			// 	hide: true,
			// 	cellRendererFramework: SystemTurnoverNoticeDocuSignRenderer,
			// 	minWidth: 90
				
			// })

		});

		this.systemTurnoverNoticeService.setCallback(this);
		this.systemService.setCallback(this)
	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}
			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	clear() {
		this.selectedAssignee = []
		this.ch1 = false
		this.ch2 = false
		this.ch3 = true
		this.ch4 = true
		this.ch5 = true
		this.ch6 = false
		this.ch7 = false
		this.ch8 = false
		this.ch9 = false
		this.ch10 = false
		this.ch11 = false
		this.ch12 = false
		this.ch13 = false
		this.ch14 = false
		this.ch15 = false
		this.ch16 = false
		this.ch17 = false
		this.ch18 = false
		this.ch19 = false
		this.comments = null
		this.selectedItem = null
		this.selectedSTN = null
		this.selectedSystem = null
		this.stnWorkflowTaskList = []
		this.assignee1 = null
		this.assignee2 = null
		this.assignee3 = null
		this.taskname1 = null
		this.taskname2 = null
		this.taskname3 = null
		this.showRequiredErr = false
	}

	systemPhaseSelected(event: any)
	{
		this.selectedSystemPhase = event["text"]
	}

	ngOnInit() {
		this.systemPhaseValues.push({ 'id': '1', 'text': 'Onshore' });
		this.systemPhaseValues.push({ 'id': '2', 'text': 'Offshore' });		

		this.systemTurnoverNoticeService.getAllVendorList().subscribe(
			data => {
				this.vendorList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.vendorList.push({ "id": element.vendorId, "text": element.vendorName })
					});
					if (this.vendorList.length > 0) {
						this.selectedVendor = []
						this.selectedVendor.push(this.vendorList[0])
					} else {
						//do nothing
					}
				} else {
					//do nothing
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'Not Issued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	downloadSentFile(params) {
		// this.loadingService.showLoading(false, false, '', 0)
		this.systemTurnoverNoticeService.downloadSentFile(UserVariable.projectId, params.data.stnId)
			.subscribe(response => {
				console.log(this.selectedVendor)
				// this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "STN_" + params.data.systemName + "_" + this.selectedVendor[0].text + ".pdf");
			},
			error => {
				// this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.stn_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadFile(params) {
		this.loadingService.showLoading(false, false, '', 0)
		this.systemTurnoverNoticeService.downloadFile(UserVariable.projectId, params.data.stnId)
			.subscribe(response => {
				console.log(this.selectedVendor)
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "STN_" + params.data.systemName + "_" + this.selectedVendor[0].text + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.stn_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	public getStyle(status: any) {
		if (status == 'Approved') {
			return 'badge-success'
		} else {
			return 'badge-warning'
		}
	}

	public getStatus(status: any) {
		switch (status) {
			case 'Not Issued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading()
		if (serviceType == WsType.GET_STNS_BY_VENDOR_ID) {
			if (data.payload) {
				data.payload.forEach(element => {
					if (element.targetCompletionDate) {
						element.newtargetCompletionDate = new Date(element.targetCompletionDate).toLocaleDateString()
					}
					element.systemName = element.systemNo + " " + element.systemName

					element.isStart = false
					if (element.ptnStatus == "NotIssued" || element.stnStatus == "NotIssued") {
						element.isStart = true
					}
					if (element.ptnStatus == "NotIssued") {
						element.ptnStatus = "Not Issued"
					}
					if (element.stnStatus == "NotIssued") {
						element.stnStatus = "Not Issued"
					}
					if(element.docuSignStatus != null){
						element.docuSignStatus = element.docuSignStatus.toLowerCase()
					}
				});
				this.rowData = data.payload;
			} else {
				this.rowData = []
			}
		} else if (serviceType == WsType.UPDATE_STN_STATUS) {
			this.loadingService.hideLoading()
			this.gridApi.showLoadingOverlay()
			this.systemTurnoverNoticeService.getAllSTNsByVendorId(this.selectedVendor[0].id)
		} else if (serviceType == WsType.GET_STN_BY_ID) {
			this.selectedSystemPhaseVal = [];
			this.loadingService.hideLoading()
			this.eventType = this.widgetEvent.SHOW_EDIT
			this.selectedSTN = data.payload
			this.selectedSystemPhase = this.selectedSTN.systemPhase;
			this.systemPhaseValues.forEach(element => {
				if(element.text == this.selectedSystemPhase){
					this.selectedSystemPhaseVal = [];
					this.selectedSystemPhaseVal.push({"id":element.id, "text":element.text});
				}
			});
			if (this.selectedSTN.targetCompletionDate) {
				this.targetCompletionDate = new Date(this.selectedSTN.targetCompletionDate)
			} else {
				this.targetCompletionDate = null;
			}
			if (!this.selectedSystem.parentId) {
				this.required = this.selectedSTN.stnRequired
			} else {
				this.required = this.selectedSTN.ptnRequired
			}
		} else if (serviceType == WsType.GET_SYSTEM_BY_ID) {
			this.selectedSystem = data.payload
			this.systemTurnoverNoticeService.getStnById(this.selectedItem.stnId)
		} else if (serviceType == WsType.ADD_STN) {
			this.loadingService.hideLoading()
			this.eventType = this.widgetEvent.SHOW_TABLE
			this.gridApi.showLoadingOverlay()
			this.systemTurnoverNoticeService.getAllSTNsByVendorId(this.selectedVendor[0].id)
		} else if (serviceType == WsType.UPDATE_STN) {
			this.loadingService.hideLoading()
			this.eventType = this.widgetEvent.SHOW_TABLE
			this.gridApi.showLoadingOverlay()
			this.systemTurnoverNoticeService.getAllSTNsByVendorId(this.selectedVendor[0].id)
		} else if (serviceType == WsType.GENERATE_STN) {
			if (this.stnWorkflowTaskList) {
				this.taskCount = 0
				this.addInstanceTaskMap()
			}
		}
	}

	getConfigData() {
		var configData = '<ConfigData>';
		configData += '<AllChecksheetsCompleted>' + this.ch1 + '</AllChecksheetsCompleted>';
		configData += '<AllPunchlistItemsCleared>' + this.ch2 + '</AllPunchlistItemsCleared>';
		configData += '<TurnoverCompleted>' + this.ch3 + '</TurnoverCompleted>';
		configData += '<EnteredToSCDb>' + this.ch4 + '</EnteredToSCDb>';
		configData += '<TCPCompleted>' + this.ch5 + '</TCPCompleted>';
		configData += '<DrawingsIssued>' + this.ch6 + '</DrawingsIssued>';
		configData += '<AllPreservationReqCompleted>' + this.ch7 + '</AllPreservationReqCompleted>';
		configData += '<SiteComInformationCleared>' + this.ch8 + '</SiteComInformationCleared>';
		configData += '<NCRActionsClosed>' + this.ch9 + '</NCRActionsClosed>';
		configData += '<VendorReportsClosed>' + this.ch10 + '</VendorReportsClosed>';
		configData += '<AllCertificationCompleted>' + this.ch11 + '</AllCertificationCompleted>';
		configData += '<RegularityApprovalsObtained>' + this.ch12 + '</RegularityApprovalsObtained>';
		configData += '<SAPCMMSModule>' + this.ch13 + '</SAPCMMSModule>';
		configData += '<SystemFlushed>' + this.ch14 + '</SystemFlushed>';
		configData += '<TempIsolationsRemoved>' + this.ch15 + '</TempIsolationsRemoved>';
		configData += '<AreasCleanSafe>' + this.ch16 + '</AreasCleanSafe>';
		configData += '<PermitsCancelled>' + this.ch17 + '</PermitsCancelled>';
		configData += '<SystemSafe>' + this.ch18 + '</SystemSafe>';
		configData += '<PlanApprovedForRemItems>' + this.ch19 + '</PlanApprovedForRemItems>';
		configData += '<Comments>' + this.comments + '</Comments>';
		configData += '</ConfigData>';
		return configData;
	}

	addInstanceTaskMap() {
		var taskmap = this.stnWorkflowTaskList[this.taskCount]
		taskmap['processInstanceId'] = this.workflowId.value

		var taskAssignee = null
		if (this.taskCount == 0) {
			taskAssignee = this.assignee1;
		} else if (this.taskCount == 1) {
			taskAssignee = this.assignee2;
		} else if (this.taskCount == 2) {
			taskAssignee = this.assignee3;
		}
		if (this.userList.find(user => user.id == taskAssignee)) {
			taskmap['userId'] = taskAssignee
			taskmap['userEmail'] = null
		} else {
			taskmap['userEmail'] = taskAssignee
			taskmap['userId'] = null
		}

		this.taskCount = this.taskCount + 1
		this.workflowService.addWorkflowInstanceTaskRolemap(taskmap).
			subscribe(
			data => {
				if (this.taskCount == this.stnWorkflowTaskList.length) {
					var workflow = {
						"id": this.workflowId.value, "projectId": UserVariable.projectId, "processInstanceId": this.workflowId.value,
						"processName": "STNPTNApproval", "itemId": this.selectedItem.stnId,
						"itemType": "SystemTurnoverNotice", "initiatedUserId": UserVariable.userId, "configData": this.getConfigData()
					}
					this.workflowService.generateWorkflow(workflow).
						subscribe(data => {
							this.backToTable()
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						})
				} else {
					this.addInstanceTaskMap()
				}
			},
			error => {
				this.alertService.error(error.statusDescription);
			})
	}

	textChanged(event: any, i: any, taskname: any) {
		if (event) {
			if (i == 1) {
				this.assignee1 = event.value
				this.taskname1 = taskname
			} else if (i == 2) {
				this.assignee2 = event.value
				this.taskname2 = taskname
			} else if (i == 3) {
				this.assignee3 = event.value
				this.taskname3 = taskname
			}
		} else {
			if (i == 1) {
				this.assignee1 = null
				this.taskname1 = null
			} else if (i == 2) {
				this.assignee2 = null
				this.taskname2 = null
			} else if (i == 3) {
				this.assignee3 = null
				this.taskname3 = null
			}
		}
	}

	isAssigneesAdded() {
		if (this.assignee1 && this.assignee2 && this.assignee3) {
			let result = true;
			if (!this.userList.find(user => user.id == this.assignee1)) {
				if (!this.emailValidate(this.assignee1)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee2)) {
				if (!this.emailValidate(this.assignee2)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee3)) {
				if (!this.emailValidate(this.assignee3)) {
					result = false;
				}
			}
			return result
		} else {
			return false
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading()
		this.alertService.clear()
		if (serviceType == WsType.GET_STNS_BY_VENDOR_ID) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.GET_DEFAULT_WORKFLOW_LIST) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.GET_ALL_ACTIVE_USERS) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.UPDATE_STN_STATUS) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.GET_STN_BY_ID) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.ADD_STN) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.UPDATE_STN) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.CHECK_CHECKSHEETS_COMPLETED) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.CHECK_PUNCHLISTS_COMPLETED) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.GET_OPEN_PUNCHLIST_ITEMS) {
			this.alertService.error(res.statusDescription)
		} else if (serviceType == WsType.GENERATE_STN) {
			this.alertService.error(res.statusDescription)
			this.loading = false
		}
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		if (this.gridApi) {
			this.gridApi.showLoadingOverlay();
		}

		if (this.vendorList.length == 0) {
			this.systemTurnoverNoticeService.getAllVendorList().subscribe(
				data => {
					this.vendorList = []
					if (data.payload) {
						data.payload.forEach(element => {
							this.vendorList.push({ "id": element.vendorId, "text": element.vendorName });
						})
						if (this.vendorList.length > 0) {
							this.systemTurnoverNoticeService.getAllSTNsByVendorId(this.vendorList[0].id)
							this.selectedVendor = []
							this.selectedVendor.push(this.vendorList[0])
						} else {
							this.rowData = []
						}
					}
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
		}
		params.api.sizeColumnsToFit();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	private showDeleteConfirmation(params: any) {
		this.selectedSTN = params.data
		this.deleteModalRef = this.bsModalService.show(this.deleteSTNModal, { class: 'modal-sm' })
	}

	continue() {
		if (this.incompleteModalRef) {
			this.incompleteModalRef.hide()
		}
		this.loadingService.showLoading(false, false, '', 0)
		this.eventType = this.widgetEvent.SHOW_WIZARD

		this.systemService.getOpenPunchlistItems(this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.openPunchlists = []
				if (data.payload) {
					this.openPunchlists = data.payload
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id, "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('SystemTurnoverNotice')
					.subscribe(data => {
						this.stnWorkflowTaskList = data.payload
						this.selectedAssignee = []
						if (data.payload) {
							this.stnWorkflowTaskList.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})


	}

	private showWizard(data: any) {
		this.selectedItem = data.data
		this.loadingService.showLoading(false, false, '', 0)
		this.systemService.isAllChecksheetsComplete('', this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
			.subscribe(data => {
				this.allChecksheetsCompleted = data.payload
				this.ch1 = data.payload
				this.systemService.isAllPunchlistItemsClosedByCategory("A", this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
					.subscribe(data => {
						this.allPunchlistCompleted = data.payload
						this.ch2 = data.payload
						this.loadingService.hideLoading()

						if (!this.allChecksheetsCompleted || !this.allPunchlistCompleted) {
							this.incompleteModalRef = this.bsModalService.show(this.incompleteSTNModal, { class: 'modal-lg' })
						} else {
							this.continue()
						}
					},
					error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})
	}

	cancelWorkflow(): void {
		this.incompleteModalRef.hide()
	}

	private backToTable() {
		this.isSubmitted = false;
		this.clear()
		if (!this.selectedVendor[0]) {
			this.selectedVendor = []
			this.selectedVendor.push(this.vendorList[0])
		}

		this.systemTurnoverNoticeService.getAllSTNsByVendorId(this.selectedVendor[0].id)
		this.eventType = this.widgetEvent.SHOW_TABLE
		this.gridApi.showLoadingOverlay()
	}

	private finishFunction() {

		this.isSubmitted = true;
		if(!this.validateAssigneeData()){
			return;

		}
		else{
			this.loadingService.showLoading(false, false, '', 0)
			this.loading = true;
			var signerslist =  this.getSigngersList();
			
			if (!this.comments) {
				this.comments = ''
			}
			var stnOject = {
				"stnId": this.selectedItem.stnId, "vendorId": this.selectedVendor[0].id, "ch1": this.ch1, "ch2": this.ch2, "ch3": this.ch3, "ch4": this.ch4,
				"ch5": this.ch5, "ch6": this.ch6, "ch7": this.ch7, "ch8": this.ch8, "ch9": this.ch9, "ch10": this.ch10, "ch11": this.ch11, "ch12": this.ch12
				, "ch13": this.ch13, "ch14": this.ch14, "ch15": this.ch15, "ch16": this.ch16, "ch17": this.ch17, "ch18": this.ch18, "ch19": this.ch19, "comments": this.comments,
				"signersList": JSON.stringify(signerslist) , "doctype" : "STN", "systemno":this.selectedItem.systemNo.replace(/"/g, '\\"')
			}
			console.log(JSON.stringify(signerslist))
			this.workflowId = Guid.create()
			this.systemTurnoverNoticeService.generateSTN(stnOject)
	}}

	validateAssigneeData(): boolean{
		this.validAssignee1 = true;
		this.validAssignee2= true
		this.validAssignee3= true

		this.validAssigneeEmail1 = true;
		this.validAssigneeEmail2= true
		this.validAssigneeEmail3= true


		let validation = true;

			if (this.assignee1 == null ||this.assignee1 == ''){
				this.validAssignee1 = false;
				validation = false;
				
			}
			
			if (this.assignee2 == null ||this.assignee2 == ''){
				this.validAssignee2 = false;
				validation = false;
			}
			
			if (this.assignee3 == null ||this.assignee3 == ''){
				this.validAssignee3 = false;
				validation = false;
			}

			if(this.assignee1 !='' && this.assignee1 != null ){
			if(!this.userList.find(user => user.id == this.assignee1)){
				if(!this.emailValidate(this.assignee1)){
					this.validAssigneeEmail1 = false;
					validation = false;

				}
			}
			}
			if(this.assignee2 !='' && this.assignee2 != null ){
			if(!this.userList.find(user => user.id == this.assignee2)){
				if(!this.emailValidate(this.assignee2)){
					this.validAssigneeEmail2 = false;
					validation = false;

				}
			}
			}
			if(this.assignee3 !='' && this.assignee3 != null ){
			if(!this.userList.find(user => user.id == this.assignee3)){
				if(!this.emailValidate(this.assignee3)){
					this.validAssigneeEmail3 = false;
					validation = false;

				}
			}
			}

			return validation;

	}

	//generate signers list
	getSigngersList(){
		var signersIdList = [this.assignee1, this.assignee2, this.assignee3]
		var taskNameList = [this.taskname1, this.taskname2, this.taskname3]
		var signersList: any[] = [];
		var i= 1;
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.stnWorkflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'STN', 'systemno':this.selectedItem.systemNo.replace(/"/g, '\\"') });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				//console.log("assigneeName : "+ assigneeName);
				signersList.push({'signerId': '' , 'signerName': assigneeName , 'signerEmail': assignee, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.stnWorkflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'STN', 'systemno':this.selectedItem.systemNo.replace(/"/g, '\\"') });
				i++;
			}
		});
		return signersList;
 	}

	
	onCellClick(event) {
	}

	public selectedVendorId(value: any): void {
		console.log('[selectedVendorId]' + JSON.stringify(value));
		this.gridApi.showLoadingOverlay();
		this.selectedVendor = []
		this.selectedVendor.push(value)
		this.systemTurnoverNoticeService.getAllSTNsByVendorId(value.id)
	}

	public removedVendor(value: any): void {
		console.log('[removedVendor]' + JSON.stringify(value));
		this.rowData = []
		this.selectedVendor = []
	}

	ngSelectData(value: any) {
		console.log('[ngSelectData]');
	}

	private showEdit(params: any) {
		this.selectedItem = params.data
		this.loadingService.showLoading(false, false, '', 0)
		this.systemService.getSystemBySystemId(params.data.systemId)
	}

	delete(): void {
		this.deleteModalRef.hide();
		this.loadingService.showLoading(false, false, '', 0)
		this.systemTurnoverNoticeService.updateSTNStatus(this.selectedSTN)
	}

	cancel(): void {
		this.deleteModalRef.hide();
	}

	emailValidate(value: any) {
		let isValid = /^[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+(\.[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
		return isValid
	}

	isValidEmailAddress(value: any, i: any) {
		let result = true;
		if (i == 0) {
			if (this.assignee1) {
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
			} 
		} else if (i == 1) {
			if (this.assignee2) {
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
			}
		} else if (i == 2) {
			if (this.assignee3) {
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
			}
		}
		return result
	}

	deleteSTN() {
		this.editModalRef.hide();
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.loadingService.showLoading(false, false, '', 0)
		this.systemTurnoverNoticeService.deleteSystemTurnoverNotice(this.selectedItem.stnId).subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	cancelDeleteSTN() {
		this.editModalRef.hide()
	}

	saveSTN(): void {
		if (this.selectedSTN.stnId) {
			if (this.isInputValid()) {
				this.showRequiredErr = false
				let temp = false;
				if (this.selectedSystem.parentId) {
					temp = this.selectedSTN.ptnRequired
				} else {
					temp = this.selectedSTN.stnRequired
				}
				if (temp && !this.required) {
					this.editModalRef = this.bsModalService.show(this.editSTNModal, { class: 'modal-sm' })
				} else {
					this.loadingService.showLoading(false, false, '', 0)
					if (this.selectedSystem.parentId) {
						if (!this.selectedSTN.ptnStatus) {
							this.selectedSTN.ptnStatus = "NotIssued"
						}
						this.selectedSTN.ptnRequired = this.required
					} else {
						if (!this.selectedSTN.stnStatus) {
							this.selectedSTN.stnStatus = "NotIssued"
						}
						this.selectedSTN.stnRequired = this.required
					}
					this.selectedSTN.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
					this.selectedSTN.systemPhase = this.selectedSystemPhase;
					//console.log(this.selectedSTN.selectedSystemPhase);
					this.systemTurnoverNoticeService.updateSTN(this.selectedSTN)
				}
			} else {
				this.showRequiredErr = true
			}
			// this.gridApi.showLoadingOverlay()
			// this.eventType = this.widgetEvent.SHOW_TABLE
			// this.backToTable()
		} else {
			if (this.isInputValid()) {
				this.showRequiredErr = false
				this.loadingService.showLoading(false, false, '', 0)
				if (this.selectedSystem.parentId) {
					if (!this.selectedSTN.ptnStatus) {
						this.selectedSTN.ptnStatus = "NotIssued"
					}
					this.selectedSTN.ptnRequired = this.required
				} else {
					if (!this.selectedSTN.stnStatus) {
						this.selectedSTN.stnStatus = "NotIssued"
					}
					this.selectedSTN.stnRequired = this.required
				}
				this.selectedSTN.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
				this.selectedSTN.vendorId = this.selectedVendor[0].id
				this.selectedSTN.systemId = this.selectedSystem.systemId
				this.selectedSTN.systemPhase = this.selectedSystemPhase;
				this.selectedSTN.projectId = UserVariable.projectId;
				this.systemTurnoverNoticeService.addSTN(this.selectedSTN)
			} else {
				this.showRequiredErr = true
			}
			// this.gridApi.showLoadingOverlay()			
			// this.backToTable()
		}
	}

	//refresh widget
	public refresh() {
		console.log('[SystemTurnoverNoticeComponent] (refresh) ');
		this.backToTable()
	}

	exportToExcel() {
		var params = {
			fileName: "system_turnover_notice.xlsx",
			sheetName: "system_turnover_notice",
			suppressTextAsCDATA: true
		};
		if (this.gridApi) {
			var xlsContent = this.gridApi.getDataAsExcel(params)
			var temp = XLSX.read(xlsContent, { type: 'binary' });
			var xlsxContent = XLSX.write(temp, { bookType: 'xlsx', type: 'base64' });

			this.download(params, xlsxContent);
		}
	}

	download(params, content) {
		var fileNamePresent = params && params.fileName && params.fileName.length !== 0;
		var fileName = fileNamePresent ? params.fileName : "noWarning.xlsx";
		var blobObject = this.b64toBlob(content, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blobObject, fileName);
		} else {
			var downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blobObject);
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	}

	b64toBlob(b64Data, contentType) {
		var sliceSize = 512;
		var byteCharacters = atob(b64Data);
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	isInputValid() {
		let tempRequired = false;
		let tempStatus = null
		if (this.selectedSystem.parentId) {
			tempRequired = this.selectedSTN.ptnRequired
			tempStatus = this.selectedSTN.ptnStatus
		} else {
			tempRequired = this.selectedSTN.stnRequired
			tempStatus = this.selectedSTN.stnStatus
		}
		if (!tempStatus) {
			if (this.targetCompletionDate && this.required) {
				return true
			} else {
				return false
			}
		} else {
			if (tempRequired && !this.required) {
				if (this.targetCompletionDate) {
					return true
				} else {
					return false
				}
			} else {
				if (this.targetCompletionDate && this.required) {
					return true
				} else {
					return false
				}
			}
		}
	}

	public previous(){
		this.isSubmitted = false;
	}
}
