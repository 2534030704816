import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class CheckSheetService {

	constructor(private http: HttpClient) {
	}

	downloadSentFile(projectId: string, id: string, fileName: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", id).set("mode", "ViewCommOutput").set("type", "CheckSheet").set("fileName", fileName)
			});
	}

	downloadApprovedFile(projectId: string, id: string, fileName: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", id)
					.set("mode", "ViewApprovedOutput").set("type", "CheckSheet").set("fileName", fileName)
			});
	}

}