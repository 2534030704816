import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserVariable } from '../../util/common/user-variable';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) { }

    getNotifications(pageNo: Number,pageLimit:Number): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
      
        const options = { params: new HttpParams().set("token", token).set("limit", String(pageLimit)).set("pageno", String(pageNo)) }
        let url = this.getNotificationsByUserURL();
        return this.http.get(ServiceUrls.GET_NOTIFICATIONS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }
    public getNotificationsByUserURL() {
        let url = ServiceUrls.GET_NOTIFICATIONS;
        return url;
    }

    getUpdateViewedNotification( id: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("id", id) }
        let url = this.getUpdateViewedNotificationByUserURL();
        return this.http.get(ServiceUrls.GET_VIEWED_NOTIFICATION, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    getUpdateViewedAsAllNotifications(): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
       
        const options = { params: new HttpParams().set("token", token) }
        let url = this.getUpdateViewedNotificationByUserURL();
        return this.http.get(ServiceUrls.UPDATE_VIEWED_AS_ALL_NOTIFICATIONS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    public getUpdateViewedNotificationByUserURL() {
        let url = ServiceUrls.GET_VIEWED_NOTIFICATION;
        return url;
    }

}
