import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { AlertService } from '../../../util/alert/alert.service';
import { ChangePassword } from './change-password.service';
import { Password } from './password.model';
import { LocalStorage } from '../../../util/localstorage.service';

@Component({
   selector: 'app-changepassword',
   templateUrl: './changepassword.component.html',
   styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit, WsCallback {
   public passwordChangeModel = new Password(2);

   constructor(private router: Router, private alertService: AlertService, public changePasswordService: ChangePassword) {
      changePasswordService.setCallback(this);
      this.passwordChangeModel.userId = localStorage.getItem(LocalStorage.USER_ID);
      localStorage.removeItem(LocalStorage.LOGGED_USER);
   }

   ngOnInit() {
   }

   public onSubmitButtonClick(): void {
      if (this.passwordChangeModel.newPassword.length < 6) {
         this.alertService.error('Password must contain at least six characters', false);
         return;
      }
      if (!this.passwordChangeModel.newPassword.match('[0-9]+')) {
         this.alertService.error('Password must contain at least one number (0-9)', false);
         return;
      }
      if (!this.passwordChangeModel.newPassword.match('[a-z]+')) {
         this.alertService.error('Password must contain at least one lowercase letter (a-z)', false);
         return;
      }
      if (this.passwordChangeModel.newPassword.match('(.)\\1')) {
         this.alertService.error('Password cannot contain, two consecutive letters', false);
         return;
      }
      if (!this.passwordChangeModel.newPassword.match('[A-Z]')) {
         this.alertService.error('Password must contain at least one uppercase letter (A-Z)', false);
         return;
      }
      if (this.passwordChangeModel.newPassword != this.passwordChangeModel.confirmPassword) {
         this.alertService.error('Mismatching passwords', false);
         return;
      }

      //send the request
      this.changePasswordService.changePassword(JSON.stringify(this.passwordChangeModel));
   }

   public onCancelButtonClick(): void {
      localStorage.removeItem(LocalStorage.LOGGED_USER);
      this.router.navigateByUrl('login');
   }

   onSuccess(data: WsResponse, serviceType: WsType): void {
      if (serviceType == WsType.CHANGE_PASSWORD) {
         localStorage.removeItem(LocalStorage.LOGGED_USER);
         this.router.navigateByUrl('changePasswordStatus');
      }
   }

   onFail(res: WsResponse, serviceType: WsType): void {
      if (serviceType == WsType.CHANGE_PASSWORD) {
         this.alertService.error(res.statusDescription, false);
      }
   }

   cancelChangePassword(){
	   
   }

}
