import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-password-change-status',
  templateUrl: './password-change-status.component.html',
  styleUrls: ['./password-change-status.component.css']
})
export class PasswordChangeStatusComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public loginLinkClicked(): void {
    this.router.navigateByUrl('login');
  }

}