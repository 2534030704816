<div *ngIf="!disable">
	<button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
		<i class={{icon}} aria-hidden="true"></i>
	</button>
</div>
<div *ngIf="disable">
	<a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0 disabled " (click)="invoke(type, data)">
		<i class="text-black-50 {{icon}}" aria-hidden="true"></i>
	</a>
</div>
<ng-template #revokePunchlistModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Revoke confirm</h4>
    </div>
    <div class="modal-body pb-0">
        <h5>Are you sure you want to Revoke the Punchlist?</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary" (click)="revokeConfirm();revokeModalRef.hide()">
            {{ 'PUNCHLIST_CELL_RENDER.OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="revokeModalRef.hide()">
            {{ 'PUNCHLIST_CELL_RENDER.CANCEL' | translate}} </button>
    </div>
</ng-template>


<ng-template #otherPunchlistInSameGroupWarn>
    <div class="modal-header">
        <h4 class='modal-title pull-left warn-text-editor'>{{ 'WARNING' | translate}}</h4>
    </div>
    <div class="modal-body pb-0">
        <h6 *ngIf="!isRevoke">Workflow for this punchlist is started with below punchlist(s).</h6>
        <h6 *ngIf="isRevoke">Workflow for this punchlist is started with below punchlist(s). If you continue all of these punchlist will also be revoked.</h6>
        
        <div class="row my-1">
            <div class="col-sm-12 my-2">
                <ag-grid-angular #agGrid style="width: 100%; height: 250px;" id="punchListDetails" [rowData]="rowData"
                    class="ag-theme-balham" [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true"
                    [enableColResize]="true" [enableRangeSelection]="true" [paginationPageSize]="5"
                    [pagination]="true" [suppressRowClickSelection]="true"
                    [gridOptions]="gridOptions"></ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary" (click)="deleteOrRevokePl();otherPunchlistInSameGroupWarnRef.hide()">
            {{ 'PUNCHLIST_CELL_RENDER.OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="otherPunchlistInSameGroupWarnRef.hide()">
            {{ 'PUNCHLIST_CELL_RENDER.CANCEL' | translate}} </button>
    </div>
</ng-template>