import {Component} from "@angular/core";

import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'mood-cell',
    template: ``
})
export class StatusRenderer implements ICellRendererAngularComp {
    private params: any;
    private mood: string;
    public imgForMood: string;

    agInit(params: any): void {
        this.params = params;
        //this.setMood(params);
    }

    refresh(params: any): boolean {
        this.params = params;
        //this.setMood(params);
        return true;
    }

    private setMood(params) {
        this.mood = params.value;
        /*this.imgForMood = this.mood === 'Happy' ? 'https://www.ag-grid.com/images/smiley.png' : 'https://www.ag-grid.com/images/smiley-sad.png';*/

        if(this.mood === 'Complete')
        {
            this.imgForMood = 'https://www.ag-grid.com/images/smiley.png';
        }
        else if(this.mood === 'CompleteWithPL'){
            this.imgForMood = 'https://www.ag-grid.com/images/smiley-sad.png';
        }
        else if(this.mood === 'Outstanding'){
            this.imgForMood = 'https://www.ag-grid.com/images/smiley-sad.png';
        }
        else if(this.mood === 'NotApp'){
            this.imgForMood = 'https://www.ag-grid.com/images/smiley-sad.png';
        }
    };
}

