import { Component, ViewChild, TemplateRef } from "@angular/core";
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { Constants } from "src/app/util/constants";
import * as FileSaver from 'file-saver';
import { UserVariable } from "src/app/util/common/user-variable";
import { WsCallback } from "src/app/util/ws-callback.interface";
import { GridOptions } from "ag-grid";
import { AlertService } from "src/app/util/alert/alert.service";
import { SharedService } from "src/app/util/shared.service";
import { PunchlistService } from "../../punchlist.service";
import { WsResponse } from "src/app/util/ws-response.model";
import { WsType } from "src/app/util/ws-type";

@Component({
	selector: 'app-punchlist-cell-render-component',
	template: `<div>
					<button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
						<i class={{icon}} aria-hidden="true"></i>
					</button>
			  </div>`,
})

export class PunchlistCellRenderComponent implements WsCallback {
	icon: String;
	type: String;
	bsModalRef: BsModalRef;
	title: string;
	dependencyType: string;
	data: String = "";
	status: string = "";
	docuSignStatus: string = "";
	openPunchlistGroupId: string = "";
	closePunchlistGroupId: string = "";
	isOpenReturnPDFUploaded: string = "";
	isCloseReturnPDFUploaded: string = "";
	fileName: string = "";
	action: string;
	public gridOptions: GridOptions;
	public punchlistId: string = "";
	public deleteModalRef: BsModalRef;
	@ViewChild("deletePunchlistModal") deletePunchlistModal: TemplateRef<any>;


	constructor(private alertService: AlertService, private router: Router, private sharedService: SharedService,
		private bsModalService: BsModalService, private translate: TranslateService,
		private punchlistService: PunchlistService,
		private loadingService: LoadingService) {
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
	}

	agInit(params: any): void {
		let items = params.value.split(':')
		if (items.length >= 2) {
			this.data = items[1];
			if (items.length == 3) {
				this.status = items[2];
			}
		}
		this.docuSignStatus = params.data.docuSignStatus;
		this.openPunchlistGroupId = params.data.openPunchlistGroupId;
		this.closePunchlistGroupId = params.data.closePunchlistGroupId;
		this.isOpenReturnPDFUploaded = params.data.isOpenReturnPDFUploaded;
		this.isCloseReturnPDFUploaded = params.data.isCloseReturnPDFUploaded;
		
		if (params.data.assetName != null && params.data.assetName != "") {
			this.fileName = params.data.assetName
		}
		else if (params.data.subSystemName != null && params.data.subSystemName != "") {
			this.fileName = params.data.subSystemName
		}
		this.setType(items[0], items[1])
		console.log("parameters" + items[0] + " " + items[1])

	}


	public invoke(type: String, punchlistId: any) {
		this.punchlistId = punchlistId
		switch (type) {
			case 'opensentpdf':
				this.downloadOpenSentFile(this.openPunchlistGroupId, this.fileName);
				break;
			case 'openreturnedpdf':
				this.downloadOpenReturnFile(this.openPunchlistGroupId, this.fileName);
				break;
			case 'closesentpdf':
				this.downloadCloseSentFile(this.closePunchlistGroupId, this.fileName);
				break;
			case 'closereturnedpdf':
				this.downloadCloseReturnFile(this.closePunchlistGroupId, this.fileName);
				break;
			default:
				break;
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType) {

	}

	onFail(data: WsResponse, serviceType: WsType) {
	}


	private setType(params, punchlistId) {
		this.type = params;
		this.punchlistId = punchlistId;
		if (params == "opensentpdf"
			&& ((this.status == 'Initial' && this.docuSignStatus == "sent") || this.status == 'Open' || this.status == 'Closed')
			&& (this.openPunchlistGroupId != null && this.openPunchlistGroupId != "")) {
			this.icon = 'fas fa-file-pdf';
		}
		else if (params == "openreturnedpdf" && (this.status == 'Open' || this.status == 'Closed') && this.isOpenReturnPDFUploaded == "true") {
			this.icon = 'fas fa-file-pdf';
		}
		else if (params == "closesentpdf" && ((this.status == 'Open' && this.docuSignStatus == "sent") || this.status == 'Closed')
			&& (this.closePunchlistGroupId != null && this.closePunchlistGroupId != "")) {
			this.icon = 'fas fa-file-pdf';
		}
		else if (params == "closereturnedpdf" && (this.status == 'Closed') && this.isCloseReturnPDFUploaded == "true") {
			this.icon = 'fas fa-file-pdf';
		}
	}

	downloadOpenSentFile(documentId, documentName) {
		this.loadingService.showLoading(false, false, '', 0)
		this.punchlistService.downloadOpenSentFile(UserVariable.projectId, documentId)
		    .subscribe(response => {
				this.loadingService.hideLoading()
		            let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLEF_" + documentName+ "_Blank.pdf");
		        },
		        error => {
		            this.loadingService.hideLoading()
		            this.alertService.clear()
		            this.alertService.error("could not download sent file")
		        });
	}

	downloadOpenReturnFile(documentId, documentName) {
		this.loadingService.showLoading(false, false, '', 0)
		this.punchlistService.downloadOpenReturnFile(UserVariable.projectId, documentId)
		    .subscribe(response => {
		            this.loadingService.hideLoading()
		            let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLEF_" + documentName + "_Signed.pdf");
		        },
		        error => {
		            this.loadingService.hideLoading()
		            this.alertService.clear()
		            this.alertService.error("Could not download approval file")
		        });
	}

	downloadCloseSentFile(documentId, documentName) {
		this.loadingService.showLoading(false, false, '', 0)
		this.punchlistService.downloadCloseSentFile(UserVariable.projectId, documentId)
		    .subscribe(response => {
				this.loadingService.hideLoading()
		            let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLCR_" + documentName + "_Blank.pdf");
		        },
		        error => {
		            this.loadingService.hideLoading()
		            this.alertService.clear()
		            this.alertService.error("could not download sent file")
		        });
	}

	downloadCloseReturnFile(documentId, documentName) {
		this.loadingService.showLoading(false, false, '', 0)
		this.punchlistService.downloadCloseReturnFile(UserVariable.projectId, documentId)
		    .subscribe(response => {
		            this.loadingService.hideLoading()
		            let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLCR_" + documentName + "_Signed.pdf");
		        },
		        error => {
		            this.loadingService.hideLoading()
		            this.alertService.clear()
		            this.alertService.error("Could not download approval file")
		        });
	}

}