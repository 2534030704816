import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SystemHandoverWidgetService } from './system-handover-widget.service';
import { SharedService } from '../../util/shared.service';
import { UserVariable } from '../../util/common/user-variable';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { WsCallback } from '../../util/ws-callback.interface';
import * as FileSaver from 'file-saver';
import { LoadingService } from '../../util/loading/loading.service';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-system-handover-widget',
	templateUrl: './system-handover-widget.component.html',
	styleUrls: ['./system-handover-widget.component.css']
})

export class SystemHandoverWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	public rowData: any[] = [];
	public columnDefs;
	private gridApi;
	private gridColumnApi;
	shs_translated_data: any = null;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };

	constructor(private systemHandoverWidgetService: SystemHandoverWidgetService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService, private loadingService: LoadingService) {
		let browserLang = translate.getBrowserLang();
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.shs_translated_data = res['SHS_REPORT']

			var self = this;

			this.columnDefs = [
				{ headerName: this.shs_translated_data.SYSTEM, filter: "agTextColumnFilter", field: 'system', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300, },
				{ headerName: this.shs_translated_data.STATUS, field: 'status', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 150, },
				{
					headerName: this.shs_translated_data.TARGET_COMPLETION_DATE, field: 'tatgetCompletionDate', filter: 'agDateColumnFilter', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200,
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				},
				{
					headerName: this.shs_translated_data.SENT_PDF, field: 'isSentFileUploaded', autoHeight: true, cellClass: "cell-wrap-text", suppressFilter: true, minWidth: 150, cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if ((params.data.status == 'Issued' || params.data.status == 'Approved') && params.data.isSentFileUploaded) {
								eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
									'<i class="fa fa-file-pdf mx-2" aria-hidden="true"></i>' +
									'</button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									self.downloadSentFile(params)
								});
							}
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				},

				{
					headerName: this.shs_translated_data.RETURNED_PDF, field: 'isReturnedFileUploaded', autoHeight: true, cellClass: "cell-wrap-text", suppressFilter: true, minWidth: 150,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if ((params.data.status == 'Issued' || params.data.status == 'Approved') && params.data.isReturnedFileUploaded) {
								eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
									'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
									'</button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									self.downloadFile(params)
								});
							}
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				}
			];

		});

	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {

	}

	downloadFile(params) {
		this.loadingService.showLoading(false, false, '', 0)
		this.systemHandoverWidgetService.downloadFile(UserVariable.projectId, params.data.documentId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "SHC_" + params.data.tatgetCompletionDate + "_" + params.data.system + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("error")
			});
	}


	downloadSentFile(params) {
		this.loadingService.showLoading(false, false, '', 0)
		this.systemHandoverWidgetService.downloadSentFile(UserVariable.projectId, params.data.documentId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "SHC_" + params.data.tatgetCompletionDate + "_" + params.data.system + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("error")
			});
	}



	loadData(payload: any) {
		if (payload != null) {
			payload.forEach(element => {
				if (element.targetCompletionDate) {
					element.targetCompletionDate = new Date(element.targetCompletionDate).toLocaleDateString()
				}

			});
		}

		this.rowData = [];
		this.gridApi.hideOverlay()
		this.gridApi.showLoadingOverlay()

		for (let i = 0; i < payload.length; i++) {
			this.rowData.push({
				system: payload[i]["systemName"],
				status: payload[i]["shcStatus"],
				tatgetCompletionDate: payload[i]["targetCompletionDate"],
				documentId: payload[i]["shcId"],
				isSentFileUploaded: payload[i]["isSentFileUploaded"],
				isReturnedFileUploaded: payload[i]["isReturnedFileUploaded"]
			});
		}
	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'NotIssued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.gridApi.showLoadingOverlay()
		this.systemHandoverWidgetService.getSHCWidgetDetails()
			.subscribe(
			data => {
				this.loadData(data.payload)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 500);
		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.systemHandoverWidgetService.getSHCWidgetDetails()
			.subscribe(
			data => {
				this.loadData(data.payload)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

	}
}
