import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button *ngIf="show()" (click)="downloadCheckSheetApprovedFile()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
	'</button>'

})
export class CheckSheetApprovedFileIconRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;		
	}

	public show() {
		if (this.params.data.approvedFileName) {
			return true
		} else {
			return false
		}
	}

	public downloadCheckSheetApprovedFile() {		
		this.params.context.componentParent.downloadCheckSheetApprovedFile(this.params)
	}

	refresh(): boolean {
		return false;
	}
}