import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsType } from '../util/ws-type';
import { WsCallback } from '../util/ws-callback.interface';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserVariable } from '../util/common/user-variable';
import { map } from 'rxjs/operators';
import { CommonUtil } from '../util/common/common-utils';

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	private callback: WsCallback;
	public notificationCount: string;

	constructor(private http: HttpClient, private commonUtil: CommonUtil) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	/**
     * Set the default project of the user
     * @param request json request with a format as  {"projectId":"","userId":"","isDefaultProject":"","projectType":"", "userId":""}
     */
	public setDefaultRecentProjects(request: string, wsCallBack: WsCallback){
		return this.http.post(ServiceUrls.SET_DEFAULT_RECENT_PROJECT, request).subscribe(
			data => {				
				let modified = JSON.parse(JSON.stringify(data));				
				// console.log('[setDefaultRecentProjects] (data) ' +  JSON.stringify(data));
				let res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload
				);
				wsCallBack.onSuccess(res, WsType.SET_DEFAULT_RECENT_PROJECT)
			},
			error => {				
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.SET_DEFAULT_RECENT_PROJECT)
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.SET_DEFAULT_RECENT_PROJECT)
				}				
			});
	}

	/**
     * Update default project as non default
     * @param request json request with a format as  {"projectId":"","userId":"", "projectType":""}
     */
	public deleteUserDefaultRecentProject(request: String, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.DELETE_USER_DEFAULT_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}

	/**
     * Update default project as non default
     * @param request json request with a format as  {"projectId":"","userId":"", "projectType":""}
     */
	getNotificationCount(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getNotificationCountByUserURL();
		return this.http.get(ServiceUrls.GET_NOTIFICATION_COUNT, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				return throwError(res)
			})
		);
	}
	public getNotificationCountByUserURL() {
		let url = ServiceUrls.GET_NOTIFICATION_COUNT;
		return url;
	}

	getNotificationCountIfModified(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token)}
		let url = this.getNotificationCountByUserURL();
		return this.http.get(ServiceUrls.GET_NOTIFICATION_COUNT_IF_MODIFIED, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				return throwError(res)
			})
		);
	}
	public getNotificationCountIfModifiedByUserURL() {
		let url = ServiceUrls.GET_NOTIFICATION_COUNT_IF_MODIFIED;
		return url;
	}

	getClientLogo(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token)}
		let url = this.getClientLogoIfModifiedByUserURL();
		return this.http.get(ServiceUrls.GET_CLIENT_LOGO, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				return throwError(res)
			})
		);
	}
	public getClientLogoIfModifiedByUserURL() {
		let url = ServiceUrls.GET_CLIENT_LOGO;
		return url;
	}
	
	getServerVersion(): Observable<WsResponse> {
        let token = UserVariable.getUserToken();     
        const options = { params: new HttpParams().set("token", token) }
        return this.http.get(ServiceUrls.GET_SERVER_VERSION, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
	}

	updateUserPreferences(userPreferences   : String): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        if(!this.commonUtil.validateLogin(token)){
            return;
        }
        let url = this.updatePreferences(token);
        return this.http.post(url, JSON.stringify(new Request(userPreferences))).pipe(
        map(response => {
        var modified = JSON.parse(JSON.stringify(response));
        return new WsResponse(
        modified.status.description,
        modified.status.name,
        modified.status.code,
        modified.payload);
        }),
        catchError(error => {
        var modified = JSON.parse(JSON.stringify(error));
        var res = new WsResponse(
        modified.error.status.description,
        modified.error.status.name,
        modified.error.status.code,
        modified.error.payload);
        return throwError(res)
        })
        );
    }    

    public updatePreferences(token: String) {
        let url = ServiceUrls.UPDATE_USER_PREFERENCES + '?token=' + token;
        return url;
        
        }
}

class Request {
    constructor(private userPreference: String) { }
    }
