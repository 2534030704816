import { Component, TemplateRef, ViewChild } from "@angular/core";
import { WsResponse } from '../../../../util/ws-response.model';
import { WsType } from '../../../../util/ws-type';
import { AlertService } from '../../../../util/alert/alert.service';
import { Router } from '@angular/router';
import { LocalStorage } from '../../../../util/localstorage.service';
import { SharedService } from "../../../../util/shared.service";
import { WsCallback } from "../../../../util/ws-callback.interface";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from "src/app/util/constants";
import { OnshoreTurnoverNoticeService } from '../../../onshore-turnover-notice.service';
//import { ApprovotnComponent } from "./approveotn/approveotn.component";
import { UserVariable } from "../../../../util/common/user-variable";
import { LoadingService } from "../../../../util/loading/loading.service";
import * as FileSaver from 'file-saver';
import { AddeditotnComponent } from "../../addeditotn/addeditotn.component";
//import { EditotnComponent } from "./editotn/editotn.component";

@Component({
	selector: 'child-cell',
    templateUrl: './manage-cell-render.component.html',
    styleUrls: ['./manage-cell-render.component.css']
})
export class ManageCellRenderComponent implements WsCallback {

	icon: string;
    type: string;
    disable: boolean = false;
    approved: boolean = false
    bsModalRef: BsModalRef;
    title: string;
    dependencyType: string;
    data: string = "";
    status: string = "";
    isUploaded: string;
    otnDeleteMsg: string;
    systemName: string;
    vendorName: string;
    otnId: string;
    systemId: string;
	displayMCCWarn: boolean = false;
	mccList = []
	warnModalRef: BsModalRef;
    otn_translated_data: any = null;
    isRevoke: boolean = false;
	@ViewChild('confirmation_warn') confirmationWarn: TemplateRef<any>;
    public revokeModalRef: BsModalRef;
    @ViewChild("revokeOTNModal") revokeOTNModal: TemplateRef<any>;
    public deleteModalRef: BsModalRef;
   @ViewChild("deleteConfirmModal") deleteConfirmModal: TemplateRef<any>;
    constructor(private alertService: AlertService, private router: Router, private sharedService: SharedService,
        private modalService: BsModalService, private translate: TranslateService,
        private loadingService: LoadingService, private bsModalService: BsModalService,
        private onshoreTurnoverNoticeService: OnshoreTurnoverNoticeService) {
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
    }

	agInit(params: any): void {
        this.systemName = params.data.systemName;
        this.vendorName = params.data.vendor;
        this.status = params.data.status;
        this.otnId = params.data.id;
        this.systemId = params.data.systemId;
        let items = params.value.split(':')
        if (items.length >= 2) {
            this.status = items[1];
            if (items.length == 3) {
                this.isUploaded = items[2];
            }
        }
        this.setType(items[0])
    }

    viewDependencies(dflag: boolean) {
        this.dependenciesModalWithComponent(this.title, dflag, true);
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: this.status == "Inactive" ? true : false
        };
    }

	public invoke(type: String, data: any) {
        
        switch (type) {
            case 'sentpdf':
                this.downloadSentFile(this.otnId, this.vendorName);
                break;
            case 'returnedpdf':
                console.log(type);
                this.downloadApprovalFile(this.otnId, this.vendorName);
                break;
            case 'approve':

                //this.bsModalRef = this.modalService.show(ApproveotnComponent, { class: 'modal-xl', backdrop: 'static', keyboard: false, initialState })
                break;
            case 'edit':
				localStorage.setItem("isDelete", "false");
				this.loadingService.showLoading(true, false, 'Checking MCC Status', 0);
				this.onshoreTurnoverNoticeService.getMCCListByOTNId(this.otnId, this);
				break;
            case 'delete':
				localStorage.setItem("isDelete", "false");
				this.loadingService.showLoading(true, false, 'Checking MCC Status', 0);
				this.onshoreTurnoverNoticeService.getMCCListByOTNId(this.otnId, this);
                break;
            default:
                break;
        }
    }

	showWarnMsg() {
		if(this.type == 'edit'){
			if (this.displayMCCWarn ) {
				this.warnModalRef = this.bsModalService.show(this.confirmationWarn);
			} else {
				this.openAddEditOTNModal();
			}
		}
        else if(this.type == 'delete'){
			if (this.displayMCCWarn) {
				this.warnModalRef = this.bsModalService.show(this.confirmationWarn);
			} else {
                this.deleteConfirm(this.otnId);
			}
		}
	}

	warningConfirm() {
		if (this.type == 'edit') {
				this.openAddEditOTNModal()
		} else if (this.type == 'delete') {
            this.deleteConfirm(this.otnId);
		}
	}
    
    private openAddEditOTNModal() {
        const initialState: object = {
            edit: true,
            otnId: this.otnId
        };
        this.bsModalRef = this.modalService.show(AddeditotnComponent, { initialState, class: 'modal-md', backdrop: 'static', keyboard: false })
    }

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_MCC_DATA_BY_OTN_ID) {
			this.loadingService.hideLoading();
			this.mccList = [];
			if (data.payload != null) {
				if (data.payload.length > 0) {
					this.displayMCCWarn = true;
					data.payload.forEach((mcc)=>{
						this.mccList.push({systemName:" MCC " + mcc.systemNo + " " + mcc.systemName,status: mcc.mcstatus})
					})
				} else {
					this.displayMCCWarn = false;
				}
			}
			this.showWarnMsg();
		}
	}

	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.GET_MCC_DATA_BY_OTN_ID) {
			this.loadingService.hideLoading();
			this.displayMCCWarn = false;
			this.mccList = [];
			this.showWarnMsg();
		}
	}

    private setType(params) {
        this.type = params;
        if (params == "approve") {
            if (this.status == "Issued") {
                this.icon = 'far fa-check-circle';
            } else if (this.status == "Approved") {
                this.icon = 'far fa-check-circle';
                this.approved = true
            }
        } else if (params == "edit") {
            this.icon = "far fa-edit";
        }
        else if (params == 'delete') {
            if(this.status == "NotIssued"){
                this.icon = 'far fa-trash-alt';
            } else{
                this.icon = 'far fa-undo';
            }
        } else if (params == "sentpdf" && (this.status == 'Issued' || this.status == 'Approved')) {
            this.icon = 'fas fa-file-pdf';
        }
        else if (params == "returnedpdf" && (this.status == 'Approved' || this.status == 'Issued') && this.isUploaded == "true") {
            this.icon = 'fas fa-file-pdf';
        }

    }

    public deleteConfirm(params: any) {
        console.log("delete called")
        this.otnDeleteMsg = this.translate.instant('ONSHORE_TURNOVER_NOTICE.REVOKE_OTN_MSG');
        if (this.status == 'NotIssued') {
            this.deleteModalRef = this.modalService.show(this.deleteConfirmModal)
        }
        else {
            this.revokeModalRef = this.modalService.show(this.revokeOTNModal)
        }
    }

    public revokeDeleteOTN(action: string) {
        this.loadingService.showLoading(true, null, "", null);
        this.onshoreTurnoverNoticeService.deleteOrRevokeOTN(this.otnId, action);
        if (this.revokeModalRef) {
            this.revokeModalRef.hide()
        }
        if (this.deleteModalRef) {
            this.deleteModalRef.hide()
        }
    }

    public cancelDelteOTN() {
        this.otnDeleteMsg = null;
        if (this.revokeModalRef) {
            this.revokeModalRef.hide()
        }
        if (this.deleteModalRef) {
            this.deleteModalRef.hide()
        }
    }

    downloadSentFile(otnId, vendorName) {
        this.onshoreTurnoverNoticeService.downloadSentFile(UserVariable.projectId, otnId)
            .subscribe(response => {
                // this.loadingService.hideLoading()
                let file = new Blob([response], { type: 'application/pdf' });
                FileSaver.saveAs(file, "OTN_" + vendorName + ".pdf");
            },
                error => {
                    // this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error(this.translate.instant('ONSHORE_TURNOVER_NOTICE.ERR_DOWNLOAD_FILE'))
                });
    }

    downloadApprovalFile(otnId, vendorName) {
        this.loadingService.showLoading(false, false, '', 0)
        this.onshoreTurnoverNoticeService.downloadApprovalFile(UserVariable.projectId, otnId)
            .subscribe(response => {
                this.loadingService.hideLoading()
                let file = new Blob([response], { type: 'application/pdf' });
                FileSaver.saveAs(file, "OTN_" + vendorName + ".pdf");
            },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error(this.translate.instant('ONSHORE_TURNOVER_NOTICE.ERR_DOWNLOAD_FILE'))
                });
    }
}
