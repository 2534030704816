import { Component} from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  template: `<a [routerLink]="['/dashboard']" (click)="viewGrid()">{{plCount}}</a>`
})
export class PunchlistDetailsRenderer implements ICellRendererAngularComp {
  params: any;
  plCount: string;

  agInit(params: any): void {
      this.params = params;
      if(this.params.value != 0){
        this.plCount = this.params.value;
      } else{
        this.plCount = '';
      }
  }

  refresh(params: any): boolean {
		return false;
	}

  public viewGrid(){
    this.params.context.componentParent.viewPunchlistGrid(this.params);
  }

}