<div class="panel panel-default">
    <div class="panel-heading box-shadow panel-header-border-bottom py-1">
        <div class="row px-2">
            <div class="col-sm-12 px-0">
                <div class="float-left pt-1 px-1">
                    <p class="m-0 pl-1">
                        <!-- translated widget name -->
                        {{ "WORKITEM_DETAILS.WIDGET_NAME" | translate }}
                    </p>
                </div>
                <div class="float-right pt-1 px-0">
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="refresh()">
                        <i class="fas fa-sync-alt" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="showFilterOptions = !showFilterOptions">
                        <i class="fas fa-cog" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
        <!-- body content -->
        <div class="row" *ngIf="showFilterOptions">
            <div class="col-sm-12 my-3">
                <div class="row px-3">
                    <div class="form-group col">
                        <label>{{ 'WORK_ITEM.WORK_PACKAGE' | translate }} :</label>
                        <ng-select [active]="selectedWorkPackage" [allowClear]="true" (removed)="removeWorkPackage($event)"
                            (selected)="selectedworkPackId($event)" [items]="workPackList" placeholder="{{ 'WORK_ITEM.ALL' | translate }}"
                            name="WorkPack" id="assetId">
                        </ng-select>
                    </div>
                    <div class="form-group col">
                        <label>{{ 'WORK_ITEM.CRITICAL' | translate }} :</label>
                        <ng-select [active]="selectedCritical" [allowClear]="true" (removed)="removeCritical($event)" (selected)="selectedCriticalId($event)"
                            [items]="criticalList" placeholder="{{ 'WORK_ITEM.ALL' | translate }}" name="criticality"
                            id="criticality">
                        </ng-select>
                    </div>
                    <div class="form-group col">
                        <label>{{ 'WORK_ITEM.VENDOR' | translate }} :</label>
                        <ng-select [active]="selectedVendor" [allowClear]="true" (removed)="removedVendor($event)"
                            (selected)="selectedVendorId($event)" [items]="vendorList" placeholder="{{ 'WORK_ITEM.ALL' | translate }}"
                            name="Vendor" id="vendor">
                        </ng-select>
                    </div>
                </div>
            </div>

        </div>

        <!-- loading panel -->
        <div *ngIf="isLoadingData" class="row">
            <div class="alert alert-warning mx-auto px-3 py-1">
                <h6 class="mb-0">{{ "GENERAL.LOADING_DATA" | translate }}</h6>
            </div>
        </div>
        <!-- no data panel -->
        <div *ngIf="isNoDataToShow" class="row" style="height:150px">
        <div class="row"></div>
            <div class="alert alert-light-nodata mx-auto px-2 py-1 border-0 work-item-details-pie-chart-alert">
                <h6 class="mb-0">{{ "GENERAL.NO_DATA_TO_SHOW" | translate }}</h6>
            </div>
        </div>

        <div *ngIf="!isLoadingData" class="my-2" [chart]="chart" [ngClass]="{'content-hide': isNoDataToShow, 'content-show': !isNoDataToShow}"></div>

    </div>
</div>