import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { TranslateService } from '@ngx-translate/core';
import "ag-grid-enterprise";
import { StatusEditor } from "./status/status-editor.component";
import { StatusRenderer } from "./status/status-renderer.component";
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserVariable } from '../../util/common/user-variable';
import { LoadingService } from '../../util/loading/loading.service';
import { CapabilityService } from '../../util/capability.service';
import { LocalStorage } from '../../util/localstorage.service';

@Component({
  selector: "app-system-completion-status-widget",
  styleUrls: ['./system-completion-status-widget.component.css'],
  templateUrl: './system-completion-status-widget.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SystemCompletionStatusWidgetComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  private token;
  public isMinimized: boolean = false;
  public modifyCap: boolean = false;
  public enableEditStatus: boolean = false;
  public projectName: string;

  //variables for main grid
  public columnDefs;
  public rowData;
  public frameworkComponents;
  public defaultColDef;
  public groupHeaderHeight;
  public headerHeight;

  //variables for summary grid
  public columnDefsSummary;
  public rowDataSummary;
  public groupHeaderHeightSummary;
  public headerHeightSummary;

  //variables for system completion status report
  public systemsIdentifiedAndPrioritized: string;
  public checksheetsDevelopedAndAllocated: string;
  public tcpApproved: string;
  public commissioningSpares: string;
  public firstFillsIdentified: string;
  public startupProcedureDrafted: string;
  public startupProcedureIssued: string;
  public normOperProceduresDrafted: string;
  public operProceduresRevValidated: string;
  public operTrainingProcedures: string;
  public operPreSUCompetent: string;
  public partialTurnover: string;
  public mechanicalCompletionStatus: string;
  public commissioningStatus: string;
  public finalTurnovertToCompany: string;
  public tcpComplete: string;
  public handoverToOperations: string;
  public pssrComplete: string;
  public pssrItemsClosed: string;
  public preSULeakTested: string;
  public safetySystemChecks: string;
  public mgmtApprovalToIntroduceHC: string;
  public startupComplete: string;

  //variables for summary report
  public subSystemsCount: number = 0;
  public onshoreTOCount: number = 0;
  public mcOverallCount: number = 0;
  public mcCompleteCount: number = 0;
  public offshoreTOCount:number = 0;
  public punchACount: number = 0;
  public punchBCount: number = 0;
  public punchCCount: number = 0;
  public hoOperationsCount: number = 0;
  public pssrCount: number = 0;
  public goNoGoCount: number = 0;
  public startupCount: number = 0;

  constructor(private systemService: SystemService, 
    private alertService: AlertService, private translate: TranslateService,
    private loadingService: LoadingService, private capabilityService: CapabilityService) {
    
    //set the project name from local storage
    this.projectName = localStorage.getItem(LocalStorage.PROJECT_NAME);

    //capability check for the edit status
    this.capabilityCheck();

    //define main grid
      this.columnDefs = [
      {
        headerName: "ProjectId",
        field: "projectId",
        hide: true,
        suppressToolPanel: true
      },
      {
        headerName: "SystemId",
        field: "systemId",
        hide: true,
        suppressToolPanel: true
      },
      {headerName: "Group one",
       headerClass: 'sub-group-stick',
       children: [
          {headerName: 'Activity', field: 'systemName', headerClass: 'my-css-class',  width: 100, height:250, pinned: 'left', suppressSizeToFit: true },
          {headerName: 'Sub System #', field: 'systemNumber', headerClass: 'my-css-class',  width: 250, pinned: 'left', suppressSizeToFit: true }
        ]
       },
          {headerName: "Systems Completion Planning",
          headerClass: 'sub-group-1',
          children: [
            {headerName: 'Systems Identified & Prioritized<span class="cat-span">SC</span>', field: 'systemsIdentifiedAndPrioritized',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Checksheets Developed & Allocated Rev 0 <span class="cat-span">SC</span>', field: 'checksheetsDevelopedAndAllocated', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'TCP Approved Rev 0 <span class="cat-span">SC</span>', field: 'tcpApproved', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Commissioning Spares <span class="cat-span">SC</span>', field: 'commissioningSpares', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'First Filled Identified <span class="cat-span">SC</span>', field: 'firstFillsIdentified', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Start-up Procedure(s) Drafted Rev B <span class="cat-span">SUL</span>', field: 'startupProcedureDrafted', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Startup Procedures Issued Rev 0 Validated <span class="cat-span">SUL</span>', field: 'startupProcedureIssued', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            }
            
          ]
        },
        {headerName: "OIMS / CAS",
        headerClass: 'sub-group-2',
          children: [
            {headerName: 'Norm. Oper. Procedures Drafted Rev B <span class="cat-span">OPs</span>', field: 'normOperProceduresDrafted', width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Oper. Procedures Rev 0 - Validated <span class="cat-span">OPs</span>', field: 'operProceduresRevValidated',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Oper. Training on Procedures <span class="cat-span">OPs</span>', field: 'operTrainingProcedures',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Oper. Pre-S/U Competent <span class="cat-span">OPs</span>', field: 'operPreSUCompetent',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            }
          ]
        },
        {headerName: "Onshore MC Status",
          headerClass: 'sub-group-3',
            children: [
              {headerName: 'Onshore System Fabrication Target Date <span class="cat-span">TOA</span>', field: 'onshoreTurnOverNoticeTargetDate', width:50, cellClass: 'col-state date-field' },
              {headerName: 'Partial Turnover <span class="cat-span">TOA</span>', field: 'partialTurnover',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: false//this.enableEditStatus
              }
            ]
          },
        {headerName: "Offshore MC Status",
        headerClass: 'sub-group-4',
          children: [
            {headerName: 'A/B Checksheets - Total <span class="cat-span">TOA</span>', field: 'abcheckSheetsTotal', width:50, cellClass: 'col-state' },
            {headerName: 'A/B Checksheets - Completed <span class="cat-span">TOA</span>', field: 'abcheckSheetsCompleted',  width:50, cellClass: 'col-state' },
            {headerName: 'Offshore Mechanical Completion - Target Date <span class="cat-span">TOA</span>', field: 'mechanicalCompletionTargetDate',  width:50, cellClass: 'col-state date-field' },
            {headerName: 'Mechanical Completion Status <span class="cat-span">TOA</span>', field: 'mechanicalCompletionStatus',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: false//this.enableEditStatus
            },
            {headerName: 'Commissioning Status <span class="cat-span">TOA</span>', field: 'commissioningStatus',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: this.enableEditStatus
            },
            {headerName: 'Offshore Turnover Notice Target Date<span class="cat-span">TOA</span>', field: 'offshoreTurnOverNoticeTargetDate',  width:50, cellClass: 'col-state date-field' },
            {headerName: 'Final Turnover To Company <span class="cat-span">TOA</span>', field: 'finalTurnovertToCompany',  width:50, cellClass: 'col-state',
              cellRenderer: "statusRenderer",
              cellEditor: "statusEditor",
              cellClassRules: {
                'complete': function(params) { return params.value === 'Complete'},
                'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                'outstanding': function(params) { return params.value === 'Outstanding'},
                'na': function(params) { return params.value === 'NotApp'}
              },
              editable: false//this.enableEditStatus
            }
          ]
        },
        {headerName: "Punch - Turnover Status",
          headerClass: 'sub-group-5',
            children: [
              {headerName: "'A' Punchlist Items - Oustanding <span class='cat-span'>TOA</span>", field: 'apunchListOutstanding', width:50, cellClass: 'col-state' },
              {headerName: "'B' Punchlist Items - Oustanding <span class='cat-span'>TOA</span>", field: 'bpunchListOutstanding',  width:50, cellClass: 'col-state' },
              {headerName: "Exceptions - Total 'A' and 'B' <span class='cat-span'>TOA</span>", field: 'abtotalPunchListOutstanding',  width:50, cellClass: 'col-state' },
              {headerName: "'C' Punchlist Items - Oustanding <span class='cat-span'>TOA</span>", field: 'cpunchListOutstanding',  width:50, cellClass: 'col-state' },
              {headerName: 'TCP Complete <span class="cat-span">TOA</span>', field: 'tcpComplete',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus
              },
              {headerName: 'Handover to Operations(SHC) <span class="cat-span">TOA</span>', field: 'handoverToOperations',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: false//this.enableEditStatus
              }
            ]
          },
        {headerName: "Startup",
          headerClass: 'sub-group-6',
            children: [
              {headerName: "PSSR Complete <span class='cat-span'>SUL</span>", field: 'pssrComplete', width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus
              },
              {headerName: "PSSR Items Closed <span class='cat-span'>SUL</span>", field: 'pssrItemsClosed',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus
              },
              {headerName: "Pre S/U - Leak Tested <span class='cat-span'>SUL</span>", field: 'preSULeakTested',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus 
              },
              {headerName: "Safety System Checks <span class='cat-span'>SUL</span>", field: 'safetySystemChecks',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus
              },
              {headerName: 'Mgmt Approval to Introduce HC (Go-No-Go) <span class="cat-span">SUL</span>', field: 'mgmtApprovalToIntroduceHC',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus
              },
              {headerName: 'Start-Up Complete <span class="cat-span">SUL</span>', field: 'startupComplete',  width:50, cellClass: 'col-state',
                cellRenderer: "statusRenderer",
                cellEditor: "statusEditor",
                cellClassRules: {
                  'complete': function(params) { return params.value === 'Complete'},
                  'complete-pl': function(params) { return params.value === 'CompleteWithPL'},
                  'outstanding': function(params) { return params.value === 'Outstanding'},
                  'na': function(params) { return params.value === 'NotApp'}
                },
                editable: this.enableEditStatus
              }
            ]
          },
        {headerName: "space",
        headerClass: 'sub-group-space',
          children: [
            {headerName: 'space1', field: 'space1', width:50, headerClass: 'space-class', cellClass: 'col-state1' },
            {headerName: 'space2', field: 'space2', width:50, headerClass: 'space-class', cellClass: 'col-state1' },
            /*{headerName: 'space3', field: 'space3', width:50, headerClass: 'space-class', cellClass: 'col-state1' },
            {headerName: 'space4', field: 'space4', width:50, headerClass: 'space-class', cellClass: 'col-state1' },
            {headerName: 'space5', field: 'space5', width:50, headerClass: 'space-class', cellClass: 'col-state1' },
            */
          ]
        }
          ];
    
    this.groupHeaderHeight = 250;
    this.headerHeight = 50;
    this.defaultColDef = { 
      resizable: true,
      suppressMenu:true
       };

    this.frameworkComponents = {
      statusRenderer: StatusRenderer,
      statusEditor: StatusEditor
    };

    //define summary grid
    this.columnDefsSummary = [
      {
        headerName:"System Completion Summary",
        headerClass:'my-css-class-column-group-header-summary',
        children: [
        {
          headerName: "Sub Systems",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "subSystemsCount",
          width: 50,
        },
        {
          headerName: "Onshore T/O",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "onshoreTOCount",
          width: 50,
        },
        {
          headerName: "MC Overall",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "mcOverallCount",
          width: 50,
        },
        {
          headerName: "MC Complete",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "mcCompleteCount",
          width: 50,
        },
        {
          headerName: "Offshore T/O",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "offshoreTOCount",
          width: 50,
        },
        {
          headerName: "Punch A",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "punchACount",
          width: 50,
        },{
          headerName: "Punch B",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "punchBCount",
          width: 50,
        },
        {
          headerName: "Punch C",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "punchCCount",
          width: 50,
        },
        {
          headerName: "H/O Operations",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "hoOperationsCount",
          width: 50,
        },
        {
          headerName: "PSSR",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "pssrCount",
          width: 50,
        },
        {
          headerName: "Go-No-Go",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "goNoGoCount",
          width: 50,
        },
        {
          headerName: "Startup",
          headerClass:'my-css-class-column-header-summary',
          cellStyle: {border: '1px solid black'},
          field: "startupCount",
          width: 50,
        }
      ]
    }
    ];

    this.groupHeaderHeightSummary = 50;
    this.headerHeightSummary = 120;

    this.initializeStatusInputData();
    this.systemService.setCallback(this);
  }

  ngOnInit(){
    this.systemService.getSystemsCompletionStatusReportData(UserVariable.projectId)
    .subscribe(
      data => {
        console.log(JSON.stringify(data.payload));
        this.createRowData(data.payload);
      },
      error => {
        this.gridApi.hideOverlay()
        this.alertService.clear()
        this.alertService.error(error.statusDescription)
        this.gridApi.showLoadingOverlay()
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    //resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
  }

  onCellValueChanged(params){
    if(params.data != null && params.oldValue != params.newValue){
      let statusData = {
        systemsIdentifiedAndPrioritized: params.data.systemsIdentifiedAndPrioritized,
        checksheetsDevelopedAndAllocated: params.data.checksheetsDevelopedAndAllocated,
        tcpApproved: params.data.tcpApproved,
        commissioningSpares: params.data.commissioningSpares,
        firstFillsIdentified: params.data.firstFillsIdentified,
        startupProcedureDrafted: params.data.startupProcedureDrafted,
        startupProcedureIssued: params.data.startupProcedureIssued,
        normOperProceduresDrafted: params.data.normOperProceduresDrafted,
        operProceduresRevValidated: params.data.operProceduresRevValidated,
        operTrainingProcedures: params.data.operTrainingProcedures,
        operPreSUCompetent: params.data.operPreSUCompetent,
        //partialTurnover: params.data.partialTurnover,
        //mechanicalCompletionStatus: params.data.mechanicalCompletionStatus,
        commissioningStatus: params.data.commissioningStatus,
        //finalTurnovertToCompany: params.data.finalTurnovertToCompany,
        tcpComplete: params.data.tcpComplete,
        //handoverToOperations: params.data.handoverToOperations,
        pssrComplete: params.data.pssrComplete,
        pssrItemsClosed: params.data.pssrItemsClosed,
        preSULeakTested: params.data.preSULeakTested,
        safetySystemChecks: params.data.safetySystemChecks,
        mgmtApprovalToIntroduceHC: params.data.mgmtApprovalToIntroduceHC,
        startupComplete: params.data.startupComplete
      };
  
      var statusJson = JSON.stringify(statusData);
      let token = UserVariable.getUserToken();
      let data = {
          projectId: params.data.projectId,
          systemId: params.data.systemId,
          systemCompletionStatus: statusJson,
          userToken: token
      };
     
      this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
      this.systemService.updateSystemCompletionStatus(data);
    }
    else{
      this.alertService.info("Please change the system status to save");
    }
  }

  public initializeStatusInputData(){
    this.systemsIdentifiedAndPrioritized = "NotApp";
    this.checksheetsDevelopedAndAllocated = "NotApp";
    this.tcpApproved = "NotApp";
    this.commissioningSpares = "NotApp";
    this.firstFillsIdentified = "NotApp";
    this.startupProcedureDrafted = "NotApp";
    this.startupProcedureIssued = "NotApp";
    this.normOperProceduresDrafted = "NotApp";
    this.operProceduresRevValidated = "NotApp";
    this.operTrainingProcedures = "NotApp";
    this.operPreSUCompetent = "NotApp";
    //this.partialTurnover = "NotApp";
    //this.mechanicalCompletionStatus = "NotApp";
    this.commissioningStatus = "NotApp";
    //this.finalTurnovertToCompany = "NotApp";
    this.tcpComplete = "NotApp";
    //this.handoverToOperations = "NotApp";
    this.pssrComplete = "NotApp";
    this.pssrItemsClosed = "NotApp";
    this.preSULeakTested = "NotApp";
    this.safetySystemChecks = "NotApp";
    this.mgmtApprovalToIntroduceHC = "NotApp";
    this.startupComplete = "NotApp";
  }

  public initializeSummaryData(){
    this.subSystemsCount = 0;
    this.onshoreTOCount = 0;
    this.mcOverallCount = 0;
    this.mcCompleteCount = 0;
    this.offshoreTOCount = 0;
    this.punchACount = 0;
    this.punchBCount = 0;
    this.punchCCount = 0;
    this.hoOperationsCount = 0;
    this.pssrCount = 0;
    this.goNoGoCount = 0;
    this.startupCount = 0;
  }

  public createRowData(payload : any) {
    this.rowData = [];
    this.rowDataSummary = [];
    this.gridApi.hideOverlay()
    this.gridApi.showLoadingOverlay()

    for(let i = 0; i < payload.length; i++)
    {
      console.log(payload[i]["systemCompletionStatus"]);
      if(payload[i]["systemCompletionStatus"] != null)
      {
        let systemCompletionStatusData = JSON.parse(payload[i]["systemCompletionStatus"]);
        this.systemsIdentifiedAndPrioritized = systemCompletionStatusData.systemsIdentifiedAndPrioritized;
        this.checksheetsDevelopedAndAllocated = systemCompletionStatusData.checksheetsDevelopedAndAllocated;
        this.tcpApproved = systemCompletionStatusData.tcpApproved;
        this.commissioningSpares = systemCompletionStatusData.commissioningSpares;
        this.firstFillsIdentified = systemCompletionStatusData.firstFillsIdentified;
        this.startupProcedureDrafted = systemCompletionStatusData.startupProcedureDrafted;
        this.startupProcedureIssued = systemCompletionStatusData.startupProcedureIssued;
        this.normOperProceduresDrafted = systemCompletionStatusData.normOperProceduresDrafted;
        this.operProceduresRevValidated = systemCompletionStatusData.operProceduresRevValidated
        this.operTrainingProcedures = systemCompletionStatusData.operTrainingProcedures;
        this.operPreSUCompetent = systemCompletionStatusData.operPreSUCompetent;
        //this.mechanicalCompletionStatus = systemCompletionStatusData.mechanicalCompletionStatus;
        this.commissioningStatus = systemCompletionStatusData.commissioningStatus;
        this.tcpComplete = systemCompletionStatusData.tcpComplete;
        this.pssrComplete = systemCompletionStatusData.pssrComplete;
        this.pssrItemsClosed = systemCompletionStatusData.pssrItemsClosed;
        this.preSULeakTested = systemCompletionStatusData.preSULeakTested;
        this.safetySystemChecks = systemCompletionStatusData.safetySystemChecks;
        this.mgmtApprovalToIntroduceHC = systemCompletionStatusData.mgmtApprovalToIntroduceHC;
        this.startupComplete = systemCompletionStatusData.startupComplete;
      }
     
      //set status from system data
      this.partialTurnover = payload[i]["partialTurnover"];
      this.finalTurnovertToCompany = payload[i]["finalTurnoverToCompany"];
      this.handoverToOperations = payload[i]["handoverToOperations"];
      this.mechanicalCompletionStatus = payload[i]["mechanicalCompletionStatus"];

      //set values for the summary report
      this.subSystemsCount++;
      if(this.partialTurnover == "Complete"){
        this.onshoreTOCount++;
      }
      if( payload[i]["mechanicalCompletionTargetDate"] != null &&  payload[i]["mechanicalCompletionTargetDate"] != ''){
        this.mcOverallCount++;
        if(this.mechanicalCompletionStatus == "Complete"){
          this.mcCompleteCount++;
        }
      }
      if(this.finalTurnovertToCompany == "Complete"){
        this.offshoreTOCount++;
      }
      this.punchACount = this.punchACount + payload[i]["apunchListOutstanding"];
      this.punchBCount = this.punchBCount + payload[i]["bpunchListOutstanding"];
      this.punchCCount = this.punchCCount + payload[i]["cpunchListOutstanding"];
      if(this.handoverToOperations == "Complete"){
        this.hoOperationsCount++;
      }
      if(this.pssrComplete == "Complete"){
        this.pssrCount++;
      }
      if(this.mgmtApprovalToIntroduceHC == "Complete"){
        this.goNoGoCount++;
      }
      if(this.startupComplete == "Complete"){
        this.startupCount++;
      }

      //add values to the main report
      this.rowData.push({
        projectId: payload[i]["projectId"],
        systemId : payload[i]["systemId"],
        systemName : payload[i]["systemName"],
        systemNumber: payload[i]["systemNumber"],
        onshoreTurnOverNoticeTargetDate: (payload[i]["onshoreTurnOverNoticeTargetDate"] != null) ? new Date(payload[i]["onshoreTurnOverNoticeTargetDate"]).toLocaleDateString() : '',
        abcheckSheetsTotal: payload[i]["abcheckSheetsTotal"],
        abcheckSheetsCompleted: payload[i]["abcheckSheetsCompleted"],
        mechanicalCompletionTargetDate: (payload[i]["mechanicalCompletionTargetDate"] != null) ? new Date(payload[i]["mechanicalCompletionTargetDate"]).toLocaleDateString() : '',
        offshoreTurnOverNoticeTargetDate: (payload[i]["offshoreTurnOverNoticeTargetDate"] != null) ? new Date(payload[i]["offshoreTurnOverNoticeTargetDate"]).toLocaleDateString() : '',
        apunchListOutstanding: payload[i]["apunchListOutstanding"],
        bpunchListOutstanding: payload[i]["bpunchListOutstanding"],
        abtotalPunchListOutstanding: payload[i]["abtotalPunchListOutstanding"],
        cpunchListOutstanding: payload[i]["cpunchListOutstanding"],
        systemsIdentifiedAndPrioritized: this.systemsIdentifiedAndPrioritized,
        checksheetsDevelopedAndAllocated: this.checksheetsDevelopedAndAllocated,
        tcpApproved: this.tcpApproved,
        commissioningSpares: this.commissioningSpares,
        firstFillsIdentified: this.firstFillsIdentified,
        startupProcedureDrafted: this.startupProcedureDrafted,
        startupProcedureIssued: this.startupProcedureIssued,
        normOperProceduresDrafted: this.normOperProceduresDrafted,
        operProceduresRevValidated: this.operProceduresRevValidated,
        operTrainingProcedures: this.operTrainingProcedures,
        operPreSUCompetent: this.operPreSUCompetent,
        partialTurnover: this.partialTurnover,
        mechanicalCompletionStatus: this.mechanicalCompletionStatus,
        commissioningStatus: this.commissioningStatus,
        finalTurnovertToCompany: this.finalTurnovertToCompany,
        tcpComplete: this.tcpComplete,
        handoverToOperations: this.handoverToOperations,
        pssrComplete: this.pssrComplete,
        pssrItemsClosed: this.pssrItemsClosed,
        preSULeakTested: this.preSULeakTested,
        safetySystemChecks: this.safetySystemChecks,
        mgmtApprovalToIntroduceHC: this.mgmtApprovalToIntroduceHC,
        startupComplete: this.startupComplete
      });
      this.initializeStatusInputData();
    }
    //add values to the summary report
    this.rowDataSummary.push({
      subSystemsCount: this.subSystemsCount,
      onshoreTOCount: this.onshoreTOCount,
      mcOverallCount: this.mcOverallCount,
      mcCompleteCount: this.mcCompleteCount,
      offshoreTOCount: this.offshoreTOCount,
      punchACount: this.punchACount,
      punchBCount: this.punchBCount,
      punchCCount: this.punchCCount,
      hoOperationsCount: this.hoOperationsCount,
      pssrCount: this.pssrCount,
      goNoGoCount: this.goNoGoCount,
      startupCount: this.startupCount
    });
  }

  onSuccess(res: WsResponse, serviceType: WsType): void {
    this.loadingService.hideLoading();
    if(res != null && serviceType == WsType.UPDATE_SYSTEM_COMPLETION_STATUS){
      this.alertService.success("Successfully Updated");
      this.initializeSummaryData();
      this.systemService.getSystemsCompletionStatusReportData(UserVariable.projectId)
    .subscribe(
      data => {
        console.log(JSON.stringify(data.payload));
        this.createRowData(data.payload);
      },
      error => {
        this.gridApi.hideOverlay()
        this.alertService.clear()
        this.alertService.error(error.statusDescription)
        this.gridApi.showLoadingOverlay()
      });
    }
  }

  onFail(res: WsResponse, serviceType: WsType): void {
    this.loadingService.hideLoading();
    if(res != null && serviceType == WsType.UPDATE_SYSTEM_COMPLETION_STATUS){
      this.alertService.error(res.statusDescription);
    }
  }

   /**
     * check the capabilities for given type
     */
    public capabilityCheck(){
      let modifyCapability: string = "modifySystemCompletionStatus";
      this.modifyCap = this.capabilityService.isCapabilityAssigned(modifyCapability);
      this.enableEditStatus = this.modifyCap;
  }
}
