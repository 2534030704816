import { Component, OnInit } from '@angular/core';
import { LoggedUser } from '../user/logged-user.model';
import { LocalStorage } from '../util/localstorage.service';
import { UserVariable } from '../util/common/user-variable';
import { WsResponse } from '../util/ws-response.model';
import { WsType } from '../util/ws-type';
import { RecentProjectModel } from './recent-project.model';
import { WsCallback } from '../util/ws-callback.interface';
import { LandingService } from './landing.service';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { AlertService } from '../util/alert/alert.service';
import { RecentProject } from './recent-project.service';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { GridOptions } from "ag-grid";
import { TranslateService } from '@ngx-translate/core';
import { CapabilityService } from '../util/capability.service'
import { LoadingService } from '../util/loading/loading.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css'],

})

export class LandingComponent implements OnInit {

    getRowNodeId;
    private gridColumnApi;
    private loggedInUser: LoggedUser;
    private isClient: string;
    public recentProjectList: Array<RecentProjectModel> = new Array<RecentProjectModel>();
    private gridApi;
    private clickedApi;
    columnDefs;
    userToken: Object;
    private client: string;
    private project: string;
    public landing_translated_data: any = null;
    public gridOptions: GridOptions;
    columnDefs1 = [];
    columnDefs2 = [];
    public projectId: string;
    public defaultProjectId: string;
    rowData: any = [];
    option: string = null
    public projectName = localStorage.getItem(LocalStorage.OLD_PROJECT_NAME)


    constructor(private router: Router, private alertService: AlertService, public recentProjectService: RecentProject,
        private landingService: LandingService, private translate: TranslateService, private activatedRoute: ActivatedRoute,
        private capabilityService: CapabilityService, private loadingService: LoadingService) {

        landingService.setCallback(this);
        //translate.addLangs(["es", "en"]);
        //translate.setDefaultLang("en");
        // let browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        // this.translate.get('LANDING.CLIENT').subscribe((res: string) => {
        // 	this.client = res;
        // 	this.translate.get('LANDING.PROJECT').subscribe((res: string) => {
        // 		this.project = res;
        // 	});
        // });
        let browserLang = translate.getBrowserLang();
        //translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.landing_translated_data = res['LANDING'];
            this.client = this.landing_translated_data.CLIENT;
            this.project = this.landing_translated_data.PROJECT;
        })
        //to set default project with another color
        this.gridOptions = <GridOptions>{

        };

        this.gridOptions.getRowStyle = function (params) {

            if (params.data.id === localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID)) {
                return { background: '#ecf0f1' }
            }

        }
        this.columnDefs1 = [
            {
                headerName: this.client,
                field: "client",
				suppressSorting: true,
				suppressFilter: true
            },
            {
                headerName: this.project,
                field: "projectName",
				suppressSorting: true,
				suppressFilter: true
            }
        ];

        this.columnDefs2 = [
            {
                headerName: this.project,
                field: "projectName",
				suppressSorting: true,
				suppressFilter: true
            }
        ];

        this.activatedRoute.queryParams.subscribe(
            params => {
                this.option = params['option']
                console.log('[ngOnInit] (option) ' + JSON.stringify(this.option));
            }
        )
    }



    ngOnInit() {
        this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
        this.isClient = JSON.parse(localStorage.getItem(LocalStorage.IS_CLIENT));
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        this.gridApi.showLoadingOverlay()
        this.landingService.getAllProjects(this.loggedInUser.userId);
        window.addEventListener("resize", function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }

    onCellClicked(params) {
        this.clickedApi = params.data;
        this.loadingService.showLoading(false, false, '', 0);
        UserVariable.projectId = params.data.id;
        //UserVariable.userId = this.loggedInUser.userId.toString();
        UserVariable.clientId = params.data.clientId;
        localStorage.setItem(LocalStorage.PROJECT_NAME, params.data.projectName);
        localStorage.setItem(LocalStorage.PROJECT_TYPE, params.data.projectType);
        localStorage.setItem(LocalStorage.PROJECT_ID, params.data.id);
        localStorage.setItem(LocalStorage.CLIENT_ID, params.data.clientId);
        this.userToken = UserVariable.getUserToken();
        this.capabilityService.getAllCapabilityList()
            .subscribe(data => {
                localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload));
                //Check if the user has capability to see the dashboard.
                if (this.capabilityService.isCapabilityAssigned("viewDashboard")) {
                    console.log("has viewDashboard Capability");
                    this.router.navigateByUrl('dashboard');
                    this.loadingService.hideLoading()
                } else {
                    console.log("NO viewDashboard Capability ==" + this.landing_translated_data.YOU_DONT_HAVE_PERMISSION);
                    this.alertService.clear();
                    this.alertService.error(this.landing_translated_data.YOU_DONT_HAVE_PERMISSION);
                    this.router.navigateByUrl('landing');
                    this.loadingService.hideLoading()
                }
                //this.router.navigateByUrl('dashboard');
            },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                })

        var request = { projectId: params.data.id, isDefaultProject: 1, projectType: '3', userId: this.loggedInUser.userId };
        this.recentProjectService.addUpdateRecentProject(JSON.stringify(request));
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {

        if (data != null && WsType.LANDING == serviceType) {
            //this.alertService.success(data.statusDescription.toString(), false);
            if (this.isClient == "1") {
                console.log("Client");
                this.columnDefs = this.columnDefs2;

            } else {
                console.log("Not Client");
                this.columnDefs = this.columnDefs1;

            }
            this.loadData(data.payload)
        } else if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
            this.recentProjectList = data.payload;
        }
    }

    onFail(res: WsResponse, serviceType: WsType): void {
        if (res != null && WsType.LANDING == serviceType) {
        } if (serviceType == WsType.GET_ALL_RECENT_PROJECTS) {
            this.alertService.error(res.statusDescription);
        }
    }

    loadData(payload: any) {
        if (payload != null) {
            this.rowData = [];
            this.gridApi.hideOverlay()
            this.gridApi.showLoadingOverlay()
            for (let i = 0; i < payload.length; i++) {
                this.rowData.push({
                    id: payload[i]["id"],
                    projectName: payload[i]["projectName"],
                    client: payload[i]["clientName"] != null ? payload[i]["clientName"] : "",
                    oa: "oa:" + payload[i]["id"] + "," + payload[i]["projectName"],
                    com: payload[i]["projectType"] > 1 ? "acom:" + payload[i]["id"] + "," +
                        payload[i]["projectName"] : "com:" + payload[i]["id"] + "," + payload[i]["projectName"],
                    dashboard: "dashboard:" + payload[i]["id"] + "," + payload[i]["projectName"],
                    procedure: "procedure:" + payload[i]["id"] + "," + payload[i]["projectName"],
                    clientId: payload[i]["clientId"],
                    projectType: payload[i]["projectType"]
                });
            }
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 100);
        }
    }

    private cancelBtnEvent() {
        console.log('[cancelBtnEvent]');
        let oldProjectId = localStorage.getItem(LocalStorage.OLD_PROJECT_ID)
        let oldProjectName = localStorage.getItem(LocalStorage.OLD_PROJECT_NAME)
        let oldClienttId = localStorage.getItem(LocalStorage.OLD_CLIENT_ID)

        localStorage.setItem(LocalStorage.PROJECT_ID, oldProjectId)
        localStorage.setItem(LocalStorage.PROJECT_NAME, oldProjectName)
        localStorage.setItem(LocalStorage.CLIENT_ID, oldClienttId)

        localStorage.removeItem(LocalStorage.OLD_PROJECT_ID);
        localStorage.removeItem(LocalStorage.OLD_PROJECT_NAME);
        localStorage.removeItem(LocalStorage.OLD_CLIENT_ID);

        this.option = null

        this.router.navigateByUrl('dashboard');
    }

}
