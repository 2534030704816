import { LocalStorage } from "./localstorage.service";
//import { UserVariable } from "./common/user-variable";

export class SessionUtil {
    public static PRIMARY_ID: string = "main";
    public static SECONDARY_ID: string = new Date().getTime().toString();
    public static KEY_ID = "id";

    public static setSessionId(token: String) {
        let sessionId = sessionStorage.getItem(this.KEY_ID);
        //let token = UserVariable.getUserToken();

		if (sessionId == null && token == null) {
			sessionStorage.setItem(this.KEY_ID, this.PRIMARY_ID);
		} else if (sessionId == null && token != null) {
			sessionStorage.setItem(this.KEY_ID, this.SECONDARY_ID);
		}
    }

    public static getSessionId(): string | null {
        return sessionStorage.getItem(this.KEY_ID);
    }
}
