import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { LocalStorage } from '../localstorage.service';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable()
export class CommonUtil {
   constructor(private router: Router) { }
   //private timeZone: string = localStorage.getItem(LocalStorage.TIMEZONE)

public static formattedDateUsingTimeZone(date: any): string{
   var chartTimezone = localStorage.getItem(LocalStorage.TIMEZONE);
   return moment.tz(date, chartTimezone.replace (/"/g,'')).format()
}


	// public getFolderContentByFolderIdURL(folderId: string) {
	// 	let url = Constants.FILE_BY_FOLDER_ID_VIEW_URL + folderId + '/files.json'
	// 	return url;
   // }
   
   public validateLogin(token: any, fwd = true): boolean{
		if(token == null){
			if(fwd){
				console.log("navigate to login");
				this.router.navigateByUrl('login');
			}
			return false
		}else{
			let tokenData = JSON.parse(atob(token));
			if(tokenData.userId == ""){
				if(fwd){
					console.log("navigate to login");
					this.router.navigateByUrl('login');
				}
				return false
			}
			return true
		}	
	}

	/**
	 * check userId and projectId in token
	 * @param token 
	 * @param fwd 
	 */
	 public validateToken(token: any, fwd = true): boolean{
		if(!fwd){
			console.log('forward '+ fwd)
		}
		if(token == null){
			//user not logged in
			if(fwd){
				//navigate to login page
				console.log("navigate to login")
				this.router.navigateByUrl('login');
			}
			return false			
		}else{
			let tokenData = JSON.parse(atob(token));
			if(tokenData.userId == ""){
				//user not logged in
				if(fwd){
					//navigate to login page
					console.log("navigate to login");
					this.router.navigateByUrl('login');
				}
				return false
			}else if(tokenData.projectId == ""){ 
				let pathName: string = window.location.pathname.replace('/', '');
				if(pathName == "landing"){
					return true;
				}
				//no selected project
				if(fwd){
					//navigate to landing page
					console.log("navigate to landing");
					this.router.navigateByUrl('landing');
				}
				return false
			}	
		}
		return true;		
	}

	/**
	 * Achini Randeni
	 * HTML Encode
	 * @param inputString 
	 */
	public static formatForHTML(inputString : String) : String {
		var result = "";
		if (inputString != null) {
			for(var i = 0; i < inputString.length; i++){
				if (inputString.charAt(i) == '<') {
					result += "&lt;";
				} else if (inputString.charAt(i) == '>') {
					result += "&gt;";
				} else if (inputString.charAt(i) == '&') {
					result += "&amp;";
				} else if (inputString.charAt(i) == '\"') {
					result += "&quot;";
				} else if (inputString.charAt(i) == '\'') {
					result += "&#039;";
				} else {
					//the char is not a special one
					//add it to the result as is
					result += inputString.charAt(i);
				}
			}
		} else {
			//Empty String.
		}
		return result.toString();
	}

}
