import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../..//util/ws-callback.interface';
import { WsType } from '../../util/ws-type';

@Injectable()
export class SystemTurnoverNoticeService {
	private callback: WsCallback;

	constructor(private http: HttpClient) {
	}

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	getAllVendorList(): Observable<WsResponse> {
		console.log("SystemTurnoverNoticeService.getAllVendorList() == > ServiceURL= " + ServiceUrls.GET_VENDOR_LIST)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.GET_VENDOR_LIST, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getAllSTNsByVendorId(vendorId: string) {
		console.log("SystemTurnoverNoticeService.getAllSTNsByVendorId() == > ServiceURL= " + ServiceUrls.GET_STNS_BY_VENDOR_ID)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("selectedVendorId", vendorId) }

		this.http.get(ServiceUrls.GET_STNS_BY_VENDOR_ID, options).
			subscribe(
			data => {
				console.log(data)
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.GET_STNS_BY_VENDOR_ID);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.GET_STNS_BY_VENDOR_ID);
			});

	}

	updateSTNStatus(stn: any) {
		console.log("SystemTurnoverNoticeService.updateSTNStatus() == > ServiceURL= " + ServiceUrls.UPDATE_STN_STATUS)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		delete stn['newtargetCompletionDate']
		delete stn['isStart']
		stn['userToken'] = token
		this.http.post(ServiceUrls.UPDATE_STN_STATUS, JSON.stringify(stn)).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.UPDATE_STN_STATUS);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.UPDATE_STN_STATUS);
			});

	}

	getStnById(stnId: any) {
		console.log("SystemTurnoverNoticeService.getStnById() == > ServiceURL= " + ServiceUrls.getStnById(stnId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		this.http.get(ServiceUrls.getStnById(stnId), options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.GET_STN_BY_ID);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.GET_STN_BY_ID);
			});
	}

	addSTN(stn: any) {
		console.log("SystemTurnoverNoticeService.addSTN() == > ServiceURL= " + ServiceUrls.ADD_STN)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		stn['userToken'] = token
		this.http.post(ServiceUrls.ADD_STN, JSON.stringify(stn)).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.ADD_STN);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.ADD_STN);
			});
	}

	updateSTN(stn: any) {
		console.log("SystemTurnoverNoticeService.updateSTN() == > ServiceURL= " + ServiceUrls.UPDATE_STN)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		stn['userToken'] = token
		console.log(JSON.stringify(stn))
		this.http.post(ServiceUrls.UPDATE_STN, JSON.stringify(stn)).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.UPDATE_STN);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.UPDATE_STN);
			});
	}

	generateSTN(stn: any) {
		console.log("SystemTurnoverNoticeService.generateWorkflow() == > ServiceURL= " + ServiceUrls.GENERATE_STN)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		stn['userToken'] = token;
		this.http.post(ServiceUrls.GENERATE_STN, JSON.stringify(new commonRequest("STN", JSON.stringify(stn)))).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.GENERATE_STN);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.GENERATE_STN);
			});
	}

	downloadFile(projectId: string, stnId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", stnId)
					.set("mode", "ViewApprovedOutput").set("type", "SystemTurnover")
			});
	}

	downloadSentFile(projectId: string, stnId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", stnId).set("mode", "ViewCommOutput").set("type", "SystemTurnover")
			});
	}

	getSTNSignature(fileName: any) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, { responseType: "blob", params: new HttpParams().set("fileName", fileName).set("mode", "GetImage") });
	}

	deleteSystemTurnoverNotice(stnId: any) {
		console.log("SystemTurnoverNoticeService.deleteSystemTurnoverNotice() == > ServiceURL= " + ServiceUrls.getDeleteSTN(stnId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let stn = { 'userToken': token }
		return this.http.post(ServiceUrls.getDeleteSTN(stnId), JSON.stringify(stn)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}
}

class commonRequest{
	constructor(private certificateType: String, private certificate: String) { }
}