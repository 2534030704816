import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonUtil } from '../common/common-utils';
import { UserVariable } from '../common/user-variable';
import { ServiceUrls } from '../service-urls';
import { SharedService } from '../shared.service';
import { WsCallback } from '../ws-callback.interface';
import { WsResponse } from '../ws-response.model';
import { WsType } from '../ws-type';

@Injectable({
    providedIn: 'root'
})
export class DependenciesModalService {
    private callback: WsCallback;

    constructor(private http: HttpClient, private commonUtil: CommonUtil, private sharedService: SharedService) {
    }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    /**
       * Get dependencies. 
       * @param itemId 
       * @param itemType 
       */
    getDependecies(itemId: string, itemType: string, action: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }

        const options = { params: new HttpParams().set("token", token).set("itemId", itemId).set("itemType", itemType).set("action", action) }
        let url = this.getDependenciesURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    if (error.status != '') {
                        var val = (error as HttpErrorResponse).error;
                        var modified = JSON.parse(JSON.stringify(val));
                        var res = new WsResponse(
                            modified.status.description,
                            modified.status.name,
                            modified.status.code,
                            modified.payload);
                        this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                    } else {
                        //browser related issues
                        if (navigator.onLine == false) {
                            var res = new WsResponse("We lost the connection with the server");
                        } else {
                            var res = new WsResponse("Unknown error happened");
                        }
                        this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                    }
                });
    }

    /**
    * Define URL for get dependencies url.
    */
    public getDependenciesURL(itemId: String, itemType: String) {
        let url: any;
        if (itemType == "PunchList") {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        } else {
            url = ServiceUrls.DEPENDENCY + itemId + '/getDependencies.json';
        }

        return url;
    }

    

    getDependeciesMultipleItems(selectedItems: string, action: string) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token).set("multipleSelectedItems", selectedItems).set("action", action) }
        let url = this.getDependenciesMultipleItemsURL();
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                });
    }

    public getDependenciesMultipleItemsURL() {
        let url: any;
        url = ServiceUrls.DEPENDENCY + 'getdependency/getDependenciesMultipleItems.json';
        return url;
    }

    


	/**
     * Get dependencies. 
     * @param itemId 
     * @param itemType 
     */
     getDependeciesWithId(itemId: String, itemType: String, callback :WsCallback) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        //let url = ServiceUrls.COMPONENTS + itemId + '/getDependeciesWithId.json';
        let url = this.getDependenciesWithIdURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload + "_" + itemId);
                    callback.onSuccess(res, WsType.ITEM_DEPENDENCY_LIST);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    callback.onFail(res, WsType.ITEM_DEPENDENCY_LIST);
                });
    }

    

	/**
    * Define URL for get dependencies url.
    */
    public getDependenciesWithIdURL(itemId: String, itemType: String) {
        let url: any;
        if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/getDependeciesWithId.json';
        }

        return url;
    }
    

    /**
     * Disable item by id. 
     * @param itemId 
     * @param itemType 
     */
     disableItem(itemId: String, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = this.disableItemByIdURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DISABLE_ENABLE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.error.status.description,
                        modified.error.status.name,
                        modified.error.status.code,
                        modified.error.payload);
                    this.callback.onFail(res, WsType.ITEM_DISABLE_ENABLE);
                });
    }

    /**
     * Enable item by id. 
     * @param itemId 
     * @param itemType 
     */
    enableItem(itemId: String, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = this.enableItemByIdURL(itemId, itemType);
        this.http.get(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.ITEM_DISABLE_ENABLE);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onFail(res, WsType.ITEM_DISABLE_ENABLE);
                });
    }

    
    /**
    * Define URL to disable item by item id.
    */
     public disableItemByIdURL(itemId: String, itemType: String) {
        let url: any;
        console.log("disable item type:" + itemType);
        if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/disablePunchlist.json';
        }
        console.log("disable url:" + url);
        // console.log("url " + url)
        return url;
    }

    /**
    * Define URL to enable item by item id.  c2 v
    */
    public enableItemByIdURL(itemId: String, itemType: String) {
        let url: any;
        if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/enablePunchlist.json';
        }

        return url;
    }

    

    /**
     * Delete item by id. 
     * @param itemId 
     * @param itemType 
     */
     deleteItem(itemId: string, itemType: String) {
        let token = UserVariable.getUserToken();
        if (!this.commonUtil.validateLogin(token)) {
            return;
        }
        const options = { params: new HttpParams().set("token", token) }
        let url = this.deleteItemByIdURL(itemId, itemType);
        this.http.delete(url, options).
            subscribe(
                data => {
                    var modified = JSON.parse(JSON.stringify(data));
                    console.log("deleteItem: " + JSON.stringify(data));
                    if (modified.payload != false) {
                        modified.payload = itemId;
                    }
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.name,
                        modified.status.code,
                        modified.payload);
                    this.callback.onSuccess(res, WsType.DELETE_ITEM);
                },
                error => {
                    var modified = JSON.parse(JSON.stringify(error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    this.callback.onFail(res, WsType.DELETE_ITEM);
                });
    }

    /**
    * Define URL to enable item by item id.
    */
    public deleteItemByIdURL(itemId: String, itemType: String) {
        console.log("deleteItemByIdURL = itemType=" + itemType + " itemId=" + itemId);
        let url: any;
        if (itemType == "PunchList") {
            url = ServiceUrls.PUNCHLISTS + itemId + '/destroy.json';
        }
        
        return url;
    }
}
