import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../..//util/ws-callback.interface';
import { WsType } from '../../util/ws-type';

@Injectable()
export class InspectionReleaseNoticeService {
	private callback: WsCallback;

	constructor(private http: HttpClient) {
	}

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	getAllIRNs() {
		console.log("InspectionReleaseNoticeService.getAllIRNs() == > ServiceURL= " + ServiceUrls.GET_IRNS)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		this.http.get(ServiceUrls.GET_IRNS, options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				this.callback.onSuccess(res, WsType.GET_ALL_IRNS);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				this.callback.onFail(res, WsType.GET_ALL_IRNS);
			});

	}

	downloadFile(projectId: string, irnId: string, irnNumber: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, { responseType: "blob", params: new HttpParams().set("projectId", projectId).set("irnId", irnId).set("fileName", irnNumber).set("mode", "DownloadIRN") });
	}

	/**
   * Get IRN data
   *
   * @param callBack
   */
	 getIrnDataByIrnId(irnId, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
		this.http.get(ServiceUrls.getIrnByIrnId(token, irnId)).subscribe(
			data => {
			  var modified = JSON.parse(JSON.stringify(data));
			  var res = new WsResponse(
				  modified.status.code,
				  modified.status.name,
				  modified.status.description,
				  modified.payload);
			  callBack.onSuccess(res, WsType.GET_IRN_BY_IRN_ID);
			},
			error => {
			  var modified = JSON.parse(JSON.stringify(error));
			  var res = new WsResponse(
				  modified.status.code,
				  modified.status.name,
				  modified.status.description,
				  modified.payload);
			  callBack.onSuccess(res, WsType.GET_IRN_BY_IRN_ID);
			});
	  }
}