<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						<!-- translated widget name -->
						{{ "OWI_REPORT.WIDGET_NAME" | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
					<!-- other buttons like config button -->
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<!-- body content -->
		<div class="col-sm-12" *ngIf="eventType == widgetEvent.SHOW_TABLE_SCREEN">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="openWorkItemsPreScreen" class="ag-theme-balham"
			 [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (columnResized)="onColumnResized($event)"
			 [floatingFilter]="true" [enableRangeSelection]="true" [paginationPageSize]="5" [pagination]="true" (gridReady)="onGridReady($event)">
			</ag-grid-angular>
		</div>
		<div class="col-sm-12" *ngIf="eventType == widgetEvent.SHOW_TABLE">
			<ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="openWorkItems" [rowData]="rowData" class="ag-theme-balham"
			 [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (columnResized)="onColumnResized($event)"
			 [floatingFilter]="true" [enableRangeSelection]="true" [paginationPageSize]="5" [pagination]="true" (gridReady)="onGridReady($event)"
			 [gridOptions]="gridOptions" (cellValueChanged)="onCellValueChanged($event)" [masterDetail]="true"
			 [detailCellRendererParams]="detailCellRendererParams" [getRowHeight]="getRowHeight" (rowGroupOpened)="onRowGroupOpened($event)"></ag-grid-angular>
		</div>
		<!-- <div *ngIf="showOWICommentsDetail">
			<div class="row">
				<div class="col-sm-12 my-2">
					<div class="row px-2">
						<div class="col">
							<label class="float-left">
								<p class="my-1 h6">
									{{ 'WORK_ITEM.WI_DETAILS' | translate}}
								</p>
							</label>
							<button type="button" class="close" aria-label="Close" (click)="showOWICommentsDetail = !showOWICommentsDetail">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
					<hr class="my-0" />
				</div>
				<div class="col-sm-12 my-2" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
					<ag-grid-angular style="width: 100%; height: 300px;" id="showOWICommentsDetail" [rowData]="detailRowData" class="ag-theme-balham"
					 [columnDefs]="commentsColumnDefs" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true"
					 (columnResized)="onColumnResized($event)" [floatingFilter]="true" [enableRangeSelection]="true"
					 [paginationPageSize]="10" (cellClicked)="editOnClick($event)" [pagination]="true" (columnResized)="onDetailColumnResized($event)"
					 (gridReady)="onDetailGridReady($event)" [gridOptions]="detailGridOptions">
					</ag-grid-angular>
				</div>
			</div>
		</div> -->

		<div *ngIf="eventType == widgetEvent.SHOW_ADD || eventType == widgetEvent.SHOW_EDIT" style="width: 100%; height: 300px;">
			<div class="row">
				<div class="col-sm-12 my-2">
					<hr />
					<div class="container">
						<div class="form-group">
							<div class="col-sm-6">
								<label>{{this.commentState}} {{ 'OWI_REPORT.COMMENT' | translate }}</label>
								<input #cmt (keyup)="onKey(cmt.value)" [(ngModel)]="this.userComment" type="text" placeholder="Enter your Comment.."
								 class="form-control is-invalid" placement="top">
							</div>
							<div class="col-sm-6">
								<label>{{ 'OWI_REPORT.DATE' | translate }}</label>
								<input [readonly]="true" value={{this.currentDate}} type="text" class="form-control is-invalid" placement="top">
							</div>
						</div>
						<div class="form-group my-2">
							<button type="button" class="btn btn-primary btn-sm mx-1 float-right" [disabled]="!isInputValid()" (click)="saveComment($event)">
								{{ 'OWI_REPORT.SAVE' | translate }}</button>
							<button type="button" class="btn btn-secondary btn-sm mx-2 float-right" (click)="backToTable($event)">
								{{ 'OWI_REPORT.CANCEL' | translate }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- popup templates -->
<!-- modal templates -->