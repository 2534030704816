import { Injectable } from "../../../node_modules/@angular/core";

@Injectable({ providedIn: 'root' })
export class LocalStorage {
	constructor() { }

	public static CAPABILITY_LIST: string = "capability_list";
	public static CLIENT_ID: string = "client_id";
	public static DEFAULT_PROJECT_ID: string = "default_project_id"
	public static DEVICE_ID: string = "device_id";
	public static GOOGLE_API_KEY:string = "g_api_key";
	public static GOOGLE_CLIENT_ID:string = "g_client_id";
	public static IS_CLIENT: string = "is_client";
	public static LOGGED_USER: string = "logged_in_user";
	public static NOTIFICATION_COUNT:string = "notification_count";
	public static OLD_PROJECT_ID: string = "old_project_id"
	public static OLD_PROJECT_NAME: string = "old_project_name"
	public static OLD_CLIENT_ID: string = "old_client_id"
	public static PREFERENCES:string = "preferences";
	public static PROJECT_ID: string = "project_id";
	public static PROJECT_NAME: string = "project_name";
	public static PROJECT_TYPE: string = "project_type";
	public static REQUESTED_PATH: string = "requested_path";
	public static USER_ID: string = "user_id";
	public static USER_TOKEN:string = "userToken";
	public static TIMEZONE:string = "time_zone";
	public static CURRENT_PROJECT_ID:string = "current_project_id"
	public static AUTH0_CONFIGURATIONS:string = "auth0_configs";

	

}