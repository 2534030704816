<div class="panel count-panel count-panel-warning">
	<div class="card-block card-body py-0 px-3">
		<!-- body content -->
		<div class="row">
			<div class="col-sm-4 count-panel-icon p-0 text-center">
				<i class="fas fa-hashtag fa-3x py-3" aria-hidden="true"></i>
			</div>
			<div class="col-sm-8 px-3 py-1 count-panel-text">
				<h6 class="mb-0">
					{{ "PUNCHLIST_ITEMS.PUNCHLIST_ITEMS" | translate }}
				</h6>
				<p class="mb-0">
					<b *ngIf="isLoadingData">{{ "GENERAL.LOADING" | translate }}</b>
					<b *ngIf="!isLoadingData">{{punchlistItems | number: '1.'}}</b>
				</p>
			</div>
		</div>
	</div>
</div>

<!-- popup templates -->
<!-- modal templates -->