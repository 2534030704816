import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { OpenWorkItemsPiechartService } from './work-items-piechart.service';
import { SharedService } from '../../util/shared.service';
import { UserVariable } from '../../util/common/user-variable'
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SystemService } from '../system.service';
import { LoadingService } from '../../util/loading/loading.service';

@Component({
	selector: 'app-work-items-piechart',
	templateUrl: './work-items-piechart.component.html',
	styleUrls: ['./work-items-piechart.component.css']
})
export class OpenWorkItemsPiechartComponent implements OnInit {
	public chart: Chart
	public data: any[] = [];
	public showFilterOptions: boolean = false
	public vendorList: Array<any> = []
	public workPackList: Array<any> = []
	isCritical: any;
	vendorId: any;
	workPackId: any;
	private translatedData: any = null
	public isMinimized: boolean = false
	public isLoadingData: boolean = true
	public isNoDataToShow: boolean = false
	public openWorkItemCount = 0;
	public openWorkItems: number = 0;
	public totalWorkItems: number = 0;
	public shipmentWorkItems: number = 0;
	public handoverWorkItems: number = 0;
	public closedWorkItems: number = 0;
	public hookUpWorkItems: number = 0;
	public selectedWorkPackage: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public selectedVendor: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public selectedCritical: Array<any> = [{ 'id': "All", 'text': "ALL" }]
	public criticalList: Array<any> = [
		{ 'id': "All", 'text': "ALL" },
		{ 'id': "1", 'text': "Critical" },
		{ 'id': "0", 'text': "Non-Critical" }
	]


	constructor(private OpenWorkItemsPiechartService: OpenWorkItemsPiechartService, private sharedService: SharedService, private systemService: SystemService, private translate: TranslateService, private alertService: AlertService) {
		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['WORKITEM_DETAILS']
			this.isCritical = "All";
			this.vendorId = "ALL";
			this.workPackId = "ALL";
			this.getDataFromServer();

			this.OpenWorkItemsPiechartService.getAllWorkPackages(this.isCritical, this.vendorId, "ALL").subscribe(
				data => {
					this.workPackList = []
					this.workPackList.push({ 'id': 'ALL', 'text': 'ALL' })
					if (data.payload != null) {
						data.payload.forEach(element => {
							this.workPackList.push({ 'id': element.id, 'text': element.assetName })
						});
					} else {
						// do nothing
					}					
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)					
				}
			)

			this.OpenWorkItemsPiechartService.getAllVendors().subscribe(
						data => {
							this.vendorList = []
							this.vendorList.push({ 'id': 'ALL', 'text': 'ALL' })
							if (data.payload != null) {
								data.payload.forEach(element => {
									this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName })
								});
							} else {
								// do nothing
							}

						},
						error => {
							console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
							this.alertService.error(error.statusDescription)							
						}
					)
		})

	}

	ngOnInit() {

	}

	selectedCriticalId(params) {

		this.isCritical = params.id
		this.OpenWorkItemsPiechartService.getWorkItemsDetails(this.isCritical, this.vendorId, this.workPackId).subscribe(
			data => {
				this.initChart()
				this.getDataFromServer();
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}
	selectedVendorId(params) {
		console.log("selectedVendorId");
		console.log(params);
		this.vendorId = params.id
		this.OpenWorkItemsPiechartService.getAllVendors().subscribe(
			data => {
				this.initChart()
				this.getDataFromServer();
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)

	}

	selectedworkPackId(params) {
		console.log("assetId");
		console.log(params);
		this.workPackId = params.id;
		// this.OpenWorkItemsPiechartService.getAllWorkPackages(this.isCritical, this.vendorId,this.assetId).subscribe(
		//     data => {
		this.initChart()
		this.getDataFromServer();

		// this.workPackList = []
		// this.workPackList.push({ 'id': 'ALL', 'text': 'ALL' })
		// if (data.payload != null) {
		//     data.payload.forEach(element => {
		//         this.workPackList.push({ 'id': element.assetId, 'text': element.assetName })
		//     });
		// } else {
		//     // do nothing
		// }
		//     console.log("workPackList");
		//     console.log(this.workPackList);
		//     },
		//     error => {
		//         this.alertService.clear()
		//         this.alertService.error(error.statusDescription)
		//     }
		// )
	}


	public getDataFromServer() {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.OpenWorkItemsPiechartService.getWorkItemsDetails(this.isCritical, this.vendorId, this.workPackId).subscribe(
			data => {
				if (data.payload != 0) {
					this.loadData(data.payload);
					this.initChart();
					this.isLoadingData = false
				} else {
					this.isNoDataToShow = true
					this.isLoadingData = false
				}
			},
			error => {
				this.isNoDataToShow = true
				this.isLoadingData = false
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}

	loadData(payload: any) {
		this.openWorkItems = payload[0]["openWorkItems"],
			this.shipmentWorkItems = payload[0]["shipmentWorkItems"],
			this.handoverWorkItems = payload[0]["handoverWorkItems"],
			this.closedWorkItems = payload[0]["closedWorkItems"],
			this.hookUpWorkItems = payload[0]["hookUpWorkItems"]

		if (this.openWorkItems == 0 && this.shipmentWorkItems == 0 && this.handoverWorkItems == 0 && this.closedWorkItems == 0 && this.hookUpWorkItems == 0) {
			this.isNoDataToShow = true
		} else {
			this.isNoDataToShow = false
		}
	}

	private initChart() {
		this.chart = new Chart({

			chart: {
				type: 'pie'
			},
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			loading: {
				labelStyle: {
					color: '#FAFAFA'
				},
				style: {
					backgroundColor: '#BDBDBD'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b>: {point.y}'

					}
				}
			},

			series: [{
				name: 'Work Items',
				data: [{
					color: '#388E3C',
					name: this.translatedData.OPENWORKITEMS,
					y: this.openWorkItems,

				},
				{
					color: '#FBC02D',
					name: this.translatedData.HANDOVER_WORKITEMS,
					y: this.handoverWorkItems,

				},
				{
					color: '#D32F2F',
					name: this.translatedData.SHIPMENT_WORKITEMS,
					y: this.shipmentWorkItems,

				},
				{
					color: '#F57C00',
					name: this.translatedData.HOOKUP_WORKITEMS,
					y: this.hookUpWorkItems,

				},
				{
					color: '#757575',
					name: this.translatedData.CLOSED_WORKITEMS,
					y: this.closedWorkItems,

				},
				]
			}]

		})
	}

	refresh() {
		this.selectedWorkPackage = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.selectedCritical = [{ 'id': "All", 'text': "ALL" }]
		this.isCritical = "All";
		this.vendorId = "ALL";
		this.workPackId = "ALL";
		this.getDataFromServer();
	}

	removeWorkPackage(event) {
		this.selectedWorkPackage = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.workPackId = "ALL";
		this.getDataFromServer();
	}

	removeCritical(event) {
		this.selectedCritical = [{ 'id': "All", 'text': "ALL" }]
		this.isCritical = "All";
		this.getDataFromServer();
	}

	removeVendor(event) {
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.vendorId = "ALL";
		this.getDataFromServer();
	}
}
