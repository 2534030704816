<div class="modal-header">
  <h5 class="modal-title">{{ 'PUNCHLIST_ADD_EDIT.EDIT_PUNCHLIST' | translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
  <!-- <div *ngIf="punchlist.relatedIssuedAndApprovedCheckSheetsCount" class="alert alert-danger" role="alert">
    {{'PUNCHLIST_ADD_EDIT.ISSUED_APPROVED_CHECKSHEET_WARN' | translate}}
  </div>
  <div *ngIf="punchlist.relatedIssuedCheckSheetsCount" class="alert alert-danger" role="alert">
    {{'PUNCHLIST_ADD_EDIT.ISSUED_CHECKSHEET_WARN' | translate}}
  </div>
  <div *ngIf="punchlist.relatedApprovedCheckSheetsCount" class="alert alert-danger" role="alert">
    {{'PUNCHLIST_ADD_EDIT.APPROVED_CHECKSHEET_WARN' | translate}}
  </div> -->
  <div class="row">
    <!--Select category-->
    <div class="col-lg-6 col-12">
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.CATEGORY' | translate }}<div class="badge-required mx-2">
          </div>
        </label>

        <ng-select [allowClear]="true" [items]="category" Name="category" [active]="punchlist.selectedCategory"
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_CATEGORY' | translate }}" id="category" [disabled]="!this.isInitial"
          (selected)="categorySelected($event)" (removed)="removeCategory($event)"> </ng-select>

      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Select an asset-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.ASSET' | translate }}
        </label>

        <ng-select #asset [active]="punchlist.selectedAsset" [allowClear]="true" [items]="assetList"
          (selected)="selectedAssetId($event)" placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_ASSET' | translate }}"
          Name="assetId" id="assetId" [disabled]="this.punchlist.isAssetDisable || !this.isInitial" (removed)="removedAsset($event)"
          ([ngModel])="this.punchlist.isAssetDisable">
        </ng-select>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Select Discipline-->
      <div class="form-group ">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.DISCIPLINE' | translate }}<div class="badge-required mx-2">
          </div>
        </label>

        <ng-select #discipline [active]="punchlist.selectedDiscipline" [allowClear]="true" [items]="disciplineList"
          (selected)="selectedDisciplineId($event)" (removed)="removeDisciplineId($event)" [disabled]="!this.isInitial"
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_DISCIPLINE' | translate }}" Name="discipline" id="assetId">
        </ng-select>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Description of the punchlist-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.DESCRIPTION' | translate }}<div class="badge-required mx-2">
          </div>
        </label>

        <textarea type="text" [disabled]="!this.isInitial" class="form-control" id="addpunchlist_description" Name="addpunchlist_description"
          [(ngModel)]="punchlist.punchlistDescription" maxlength="500"></textarea>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Activity code-->
      <div class="form-group ">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.ACTIVITY_CODE' | translate }}
        </label>

        <ng-select #activity_code [active]="punchlist.selectedActivityCode" [allowClear]="true"
          [items]="activityCodeList" (selected)="selectedActivityCodeId($event)" [disabled]="!this.isInitial"
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_ACTIVITY_CODE' | translate }}" Name="activity_code"
          id="activityCodeId" (removed)="removedActivityCode($event)">
        </ng-select>

      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Object Code-->
      <div class="form-group ">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.OBJECT_CODE' | translate }}
        </label>

        <ng-select #object_code [active]="punchlist.selectedObjectCode" [allowClear]="true" [items]="objectCodeList"
          (selected)="selectedObjectCodeId($event)" [disabled]="!this.isInitial"
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_OBJECT_CODE' | translate }}" Name="object_code" id="objectCodeId"
          (removed)="removedObjectCode($event)">
        </ng-select>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Optional Modifier-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.OPTIONAL_MODIFIER' | translate }}
        </label>
        <ng-select #optional_modifier [active]="punchlist.selectedOptionalModifier" [allowClear]="true"
          [items]="optionalModifierList" (selected)="selectedOptionalModifierId($event)" [disabled]="!this.isInitial"
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_OPTIONAL_MODIFIER' | translate }}" Name="optional_modifier"
          id="optionalModifierId" (removed)="removedOptionalModifier($event)"> </ng-select>

      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Date Raised-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.DATE_RAISED' | translate }}<div class="badge-required mx-2">
          </div>
        </label>

        <input type="text" placeholder="Select date raised" class="form-control" Name="date_raised" [disabled]="!this.isInitial"
          [(ngModel)]="punchlist.dateraised" [bsConfig]="bsConfig" placement="top" bsDatepicker>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Date closed-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.DATE_CLOSED' | translate }}
        </label>

        <input type="text" placeholder="Date closed" class="form-control" Name="date_closed"
          [(ngModel)]="punchlist.closedDate" [bsConfig]="bsConfig" placement="top" bsDatepicker [disabled]=true>

      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Target Completion Date-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.TARGET_COMPLETION_DATE' | translate }}
        </label>

        <input type="text" placeholder="Select target completion date" class="form-control" [disabled]="!this.isInitial"
          Name="target_completion_date" [(ngModel)]="punchlist.targetCompletionDate" [bsConfig]="bsConfig"
          placement="top" bsDatepicker>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Raised By-->
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.RAISED_BY' | translate }}<div class="badge-required mx-2">
          </div>
        </label>

        <input type="text" id="addpunchlist_raisedby" class="form-control" Name="addpunchlist_raisedby" [disabled]="!this.isInitial"
          [(ngModel)]="punchlist.raisedby" maxlength="50">

      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Estimated man hours-->
      <div class="form-group">
        <label>{{ 'PUNCHLIST_ADD_EDIT.ESTIMATED_MAN_HOURS' | translate }}
        </label>

        <input type="text" class="form-control" id="addpunchlist_estimated_man_hours" [disabled]="!this.isInitial"
          Name="addpunchlist_estimated_man_hours" [(ngModel)]="punchlist.estimatedManHours" maxlength="4">

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Select a subsystem-->
      <div class="form-group">
        <label>{{ 'PUNCHLIST_ADD_EDIT.SUB_SYSTEM' | translate }}
        </label>

        <ng-select class="select-more-height" #subsystem [active]="punchlist.selectedSubSystem" [allowClear]="true"
          [items]="subSystemList" placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_SUB_SYSTEM' | translate }}"
          Name="subsystem" id="subsystemId" (selected)="selectedSubSystemId($event)"
          ([ngModel])="this.punchlist.isSystemDisable" [disabled]="this.punchlist.isSystemDisable || !this.isInitial"
          (removed)="removedSubSystem($event)"> </ng-select>
        <small ([ngModel])="this.punchlist.parentName" id="emailHelp" class="form-text text-muted">{{
          'PUNCHLIST_ADD_EDIT.SYSTEM' | translate }} {{ this.punchlist.parentName }}</small>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Materials Required-->
      <div class="form-group ">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.MATERIALS_REQUIRED' | translate }}
        </label>

        <textarea type="text" id="materials_required" class="form-control" Name="materials_required" [disabled]="!this.isInitial"
          [(ngModel)]="punchlist.materialRequired" maxlength="500"></textarea>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-12">
      <!--Item Status-->
      <div class="form-group ">
        <label>{{ 'PUNCHLIST_ADD_EDIT.ITEM_STATUS' | translate }}
        </label>

        <ng-select [active]="[selectedItem_status]" [items]="item_status" Name="itemstatus" [disabled]=true
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_ITEM_STATUS' | translate }}" id="itemstatus"
          (selected)="itemStatusSelected($event)"> </ng-select>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <!--Comments-->
      <div class="form-group ">
        <label>{{ 'PUNCHLIST_ADD_EDIT.COMMENTS' | translate }}
        </label>

        <textarea type="text" id="comments" class="form-control" Name="comments" [(ngModel)]="punchlist.comments"
          maxlength="500"></textarea>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.VENDOR' | translate }}
        </label>
        <ng-select #vendor [active]="punchlist.selectedVendor" [allowClear]="true" [items]="vendorList"
          (selected)="selectedVendorId($event)" (removed)="removeVendorId($event)" [disabled]="!this.isInitial"
          placeholder="{{ 'PUNCHLIST_ADD_EDIT.VENDOR' | translate }}" Name="vendor" id="vendorId">
        </ng-select>
      </div>
    </div>
    <div class="col-lg-6 col-12">
			<div class="form-group">
				<div class="row mt-4">
					<div class="col-6">
						<label class="mt-1">{{ 'PUNCHLIST_ADD_EDIT.FIRST_OIL_REQUIRED' | translate }}
						</label>
					</div>
					<div class="col-2">
						<input #firstOilRequired [checked]="punchlist.firstOilRequired" id="firstOilRequired"
              type="checkbox" class="form-control mt-1" name="firstOilRequired" [disabled]="!this.isInitial"
              style="height: 100%;" (change)="checkFirstOilRequired(firstOilRequired.checked)">
					</div>
				</div>
			</div>
		</div>
  </div>
  <div class="row">

    <div class="col-lg-6">
      <div class="row form-group">
        <label class="col-lg-2">{{ 'PUNCHLIST_ADD_EDIT.PHOTOS' | translate }}
        </label>
        <div class="col">
          <div class="files singlebtn selectimages">
            <input type="file" Name="profile" id="ImageUpload" accept=".png, .jpg, .jpeg, .gif"
              (change)="onFileChanged_Image($event)" multiple [disabled]="!this.isInitial">
          </div>

        </div>
        <!--<span class="fileLbl col-lg-4">{{fileSelTxt}}</span>-->
      </div>
      <div class="container" *ngIf="punchlist.punchlistImages.length > 0">
        <div class="d-flex flex-wrap">
          <div *ngFor="let punchlistImage of punchlist.punchlistImages" class="">
            <div *ngIf="!punchlistImage.isDeleted" class="position-relative p-3" >
              <img *ngIf="!punchlistImage.isNew" src="data:image/png;base64,{{punchlistImage.base64Image}}" alt=""
                height="auto" class="img-fluid plimage" (click)="openImage(punchlistImage)">
              <img *ngIf="punchlistImage.isNew" class="img-fluid plimage" src="{{punchlistImage.base64Image}}" alt="" 
                (click)="openImage(punchlistImage)">
              <span class="d-block position-absolute fixed-top removeIcon">
                <a class="close" (click)="removeImage(punchlistImage.id)">&times;</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.LOCATION' | translate }}
        </label>
        <div #mapContainer id="map" *ngIf="punchlist.isAvailableLocation"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="form-group files selectfiles">
        <label class="">{{ 'PUNCHLIST_ADD_EDIT.UPLOAD_DOCUMENTS' | translate }}</label>
        <input type="file" class="form-control  custom-b  form-control-file" multiple="" Name="profile" [disabled]="!this.isInitial"
          id="NewFile_FileUpload" accept=".pdf" (change)="onFileChanged($event)" required>
      </div>
      <label><small id="emailHelp" class="form-text text-muted">{{ 'NEW_PUNCHLIST_DOCUMENT.USER_FILE.MESSAGE' |
          translate }}</small>
        <small id="emailHelp" class="form-text text-muted">{{ 'NEW_PUNCHLIST_DOCUMENT.USER_FILE.SIZE_LIMIT' |
          translate }}</small></label>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData"
        [columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
        (gridReady)="onGridReady($event)" (cellClicked)='onDelete($event)'>
      </ag-grid-angular>
    </div>
  </div>
</div>


<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" (click)="getIrnfromId()">{{ 'PUNCHLIST_ADD_EDIT.SAVE' |
    translate}}</button>
  <button type="button" id="punchlist_cancel" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{
    'PUNCHLIST_ADD_EDIT.CANCEL' | translate}}</button>
</div>

<ng-template #confirmation_warn>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'PUNCHLIST_ADD_EDIT.IRN_WARN_HEADER' | translate}}</h4>
  </div>
  <div class="modal-body pb-0">
    <h5>{{'PUNCHLIST_ADD_EDIT.IRN_WARN' | translate}}</h5>
  </div>

  <div class="modal-footer">
    <button type="button" id="warn_confirm" class="btn btn-primary" (click)="warnModalRef.hide(); confirmIRNWarn();">
      {{ 'OK' | translate}} </button>
    <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal" (click)="warnModalRef.hide()">
      {{ 'CANCEL' | translate}} </button>
  </div>
</ng-template>