import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {

	@Output() showLoadingPanel: EventEmitter<any> = new EventEmitter()
	@Output() hideLoadingPanel: EventEmitter<any> = new EventEmitter()
	@Output() changeMessage: EventEmitter<any> = new EventEmitter()
	@Output() changePercentage: EventEmitter<any> = new EventEmitter()

	constructor() { }

	/**
	 * show loading
	 * @param showMessage 
	 * @param showPercentage 
	 * @param initialMessage 
	 * @param initialPercentage 
	 */
	public showLoading(showMessage: boolean, showPercentage: boolean, initialMessage: string, initialPercentage: number) {
		this.showLoadingPanel.emit({
			showMessage: showMessage,
			showPercentage: showPercentage,
			initialMessage: initialMessage,
			initialPercentage: initialPercentage
		})
	}

	public showLoadingExecute() {
		return this.showLoadingPanel
	}

	public hideLoading() {
		this.hideLoadingPanel.emit()
	}

	public hideLoadingExecute() {
		return this.hideLoadingPanel
	}

	public changeLoadingMessage(newMessage: string) {
		this.changeMessage.emit({ message: newMessage })
	}

	public changeLoadingMessageExecute() {
		return this.changeMessage
	}

	public changeLoadingPercentage(newPercentage: number) {
		this.changePercentage.emit({ percentage: newPercentage })
	}

	public changeLoadingPercentageExecute() {
		return this.changePercentage
	}
}
