<div class="panel panel-default">
    <div class="panel-heading box-shadow panel-header-border-bottom py-1">
        <div class="row px-2">
            <div class="col-sm-12 px-0">
                <div class="float-left pt-1 px-1">
                    <p class="m-0 pl-1">
                        <!-- translated widget name -->
                        {{ "PUNCHLISTDETAIL_REPORT.WIDGET_NAME" | translate }}
                    </p>
                </div>
                <div class="float-right pt-1 px-0">
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="isMinimized = !isMinimized">
                        <i class="fa " [ngClass]="{'fa-chevron-down': isMinimized, 'fa-chevron-up': !isMinimized}"
                            aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="refresh()">
                        <i class="fas fa-sync-alt" aria-hidden="true"></i>
                    </button>
                    <!-- other buttons like config button -->
                </div>
            </div>
        </div>
    </div>

    <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
        <div class="row my-1">
            <div *ngIf="eventType == widgetEvent.SHOW_TABLE" class="d-flex col justify-content-end w-100">
                <button class="btn btn-primary btn-sm mr-2" (click)="startWorkflowMultiple()">{{
                        'PUNCHLIST_WORKFLOW.PUNCHLIST_START_WORKFLOW' | translate }}</button>
                <button class="btn btn-primary btn-sm" (click)="loadAddPunchlistView()">{{
                        'PUNCHLIST_ADD_EDIT.ADD_PUNCHLIST' | translate }}</button>
            </div>
        </div>
        <!-- body content -->
        <div *ngIf="eventType == widgetEvent.SHOW_TABLE">
        <div class="row my-1">
            <div class="col-sm-12 my-2">
                <ag-grid-angular #agGrid style="width: 100%; height: 450px;" id="punchListDetails" [rowData]="rowData"
                    class="ag-theme-balham" [columnDefs]="columnDefs" [frameworkComponents]="components" [enableSorting]="true" [enableFilter]="true"
                    [enableColResize]="true" [floatingFilter]="true" [enableRangeSelection]="true" [paginationPageSize]="10"
                    [pagination]="true" (columnResized)="onColumnResized($event)" [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [isRowSelectable]="isRowSelectable" (rowSelected)="onRowSelected($event)" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions"></ag-grid-angular>
            </div>
        </div>
        </div>
    </div>

    <div *ngIf="eventType == widgetEvent.SHOW_WIZARD">
        <div class="row px-4">
            <button type="button" class="btn btn-primary btn-sm" (click)="backToTable()">
                <i class="fa fa-arrow-left mx-2" aria-hidden="true"></i>{{ 'PUNCHLIST_WORKFLOW.BACK_TO_TABLE' | translate }}
            </button>
        </div>
        <div class="row px-3">
            <div class="col-sm-12 my-2">
                <aw-wizard class="custom-css">
                        <aw-wizard-step stepTitle="">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="my-2">
                                            <span *ngIf="punchlistStatus" class="badge mr-2" [ngClass]="getStyle(punchlistStatus)">{{punchlistStatus}}</span>
                                        </h6>
                                        <hr />
                                        <div>
                                            <p>{{ 'PUNCHLIST_WORKFLOW.SUMMARY' | translate }}</p>
                                            <table class="table" *ngIf="selectedPunchlists.length>0">
                                                <tr>
                                                    <th>{{ 'PUNCHLIST_WORKFLOW.CATEGORY' | translate }}</th>
                                                    <th>{{ 'PUNCHLIST_WORKFLOW.DESCRIPTION' | translate }}</th>
                                                    <th *ngIf="punchlistStatus === 'Open'">{{ 'PUNCHLIST_WORKFLOW.CLOSURE_COMMENT' | translate }}</th>
                                                    <th>{{ 'PUNCHLIST_WORKFLOW.ASSET' | translate }}</th>
                                                    <th>{{ 'PUNCHLIST_WORKFLOW.SYSTEM_SUBSYSTEM' | translate }}</th>
                                                </tr>
                                                <tr *ngFor="let item of selectedPunchlists">
                                                    <td>{{ item.value.category }}</td>
                                                    <td>{{ item.value.description }}</td>
                                                    <td *ngIf="punchlistStatus === 'Open'">
                                                        <textarea type="text" [(ngModel)]="item.editableText" (blur)="onFieldBlur(item)"></textarea>
                                                    </td>
                                                    <td>{{ item.value.asset }}</td>
                                                    <td>{{ item.value.sub_system }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="my-2 float-right">
                                                <button type="button" class="btn btn-primary btn-sm" (click)="saveComment()" awNextStep>{{ 'PUNCHLIST_WORKFLOW.NEXT' |
                                                    translate }}</button>
                                            </div>
                        </aw-wizard-step>

                    <aw-wizard-step stepTitle="">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="my-2">
                                    <span *ngIf="punchlistStatus" class="badge mr-2" [ngClass]="getStyle(punchlistStatus)">{{punchlistStatus}}</span>
                                </h6>
                                <hr />
                                <form>
                                    <div class="row" *ngFor="let task of workflowTaskListOpen; let i = index">
                                        <div class="col-sm-10 form-group">
                                            <label class="my-1 mx-0"><b>{{getTaskName(task.taskName)}}</b></label>
                                            <div class="form-row">
                                                <div class="col">
                                                    <app-custom-typeahead *ngIf="i==0" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                     [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                     <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee1 && (isSubmitted && i==0) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                    </p>
                                                    <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail1 && (isSubmitted && i==0) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                    </p>

                                                    <app-custom-typeahead *ngIf="i==1" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                     [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                     <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee2 && (isSubmitted && i==1) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                    </p>
                                                    <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail2 && (isSubmitted && i==1) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                    </p>

                                                    <app-custom-typeahead *ngIf="i==2" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                     [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                    <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee3 && (isSubmitted && i==2) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                    </p>
                                                    <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail3 && (isSubmitted && i==2) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                    </p>

                                                    <app-custom-typeahead *ngIf="i==3" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                     [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                    <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee4 && (isSubmitted && i==3) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                    </p>
                                                    <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail4 && (isSubmitted && i==3) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let task of workflowTaskListClose; let i = index">
                                            <div class="col-sm-10 form-group">
                                                <label class="my-1 mx-0"><b>{{getTaskName(task.taskName)}}</b></label>
                                                <div class="form-row">
                                                    <div class="col">
                                                        <app-custom-typeahead *ngIf="i==0" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                         [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                         <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee1 && (isSubmitted && i==0) ">
                                                            {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                        </p>
                                                        <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail1 && (isSubmitted && i==0) ">
                                                            {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                        </p>

                                                        <app-custom-typeahead *ngIf="i==1" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                         [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                          <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee2 && (isSubmitted && i==1) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                        </p>
                                                        <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail2 && (isSubmitted && i==1) ">
                                                            {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                        </p>

                                                        <app-custom-typeahead *ngIf="i==2" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
                                                         [typeahead_list]="userList" [option_field]="'name'" [place_holder]="getTaskName(task.taskName)"></app-custom-typeahead>
                                                          <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee3 && (isSubmitted && i==2) ">
                                                        {{ 'PUNCHLIST_WORKFLOW.EMPTY_USER' | translate }}
                                                        </p>
                                                        <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail3 && (isSubmitted && i==2) ">
                                                            {{ 'PUNCHLIST_WORKFLOW.INVALID_EMAIL_ADDRESS' | translate }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                        <div class="my-2 float-right">
                            <button type="button" class="btn btn-secondary btn-sm mx-2" (click)="previous()" awPreviousStep>{{ 'PUNCHLIST_WORKFLOW.PREVIOUS'
                                | translate }}</button>
                            <button type="button" class="btn btn-primary btn-sm mx-2" (click)="finishFunction()">{{
                                'PUNCHLIST_WORKFLOW.START' | translate }}</button>
                        </div>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
        </div>
    </div>
</div>