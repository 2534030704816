import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Fingerprint2 from 'fingerprintjs2';
import { AlertService } from '../../util/alert/alert.service';
import { LoadingService } from '../../util/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthRequest } from './auth-request.model';
import { Auth } from "../../user/login/auth.service";
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { LocalStorage } from '../../util/localstorage.service';
import { LoggedUser } from '../logged-user.model';
import { AuthService, GoogleLoginProvider } from "angular-6-social-login-v2";
import { SessionValidationService } from './session-validation-request.service';
import { Openid } from './openid.service';
import { ResetUrlService } from './forgotpassword/reset-url.service';
import { UserVariable } from '../../util/common/user-variable';
import { RecentProject } from '../../landing/recent-project.service';
import { CapabilityService } from '../../util/capability.service'
import { Auth0Service } from './../auth0.service';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/util/shared.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, WsCallback {
   	@ViewChild('login_username') userNameInput: ElementRef;
   	@ViewChild('login_password') passwordInput: ElementRef;
     
	public authRequest: AuthRequest;
	public tarns: string;
	private loggedInUser: LoggedUser;
	public login_translated_data: any = null;
   	public showPassword: boolean = false
   	private forwardPath: string;

	constructor(private router: Router,
		private alertService: AlertService,
		private translate: TranslateService,
		private loadingService: LoadingService,
		private auth: Auth,
		private socialAuthService: AuthService,
		private openIdService: Openid,
		private openIdLogin: Openid,
		public recentProjectService: RecentProject,
		public sessionValidationRequest: SessionValidationService,
		public passwordResetURL: ResetUrlService,
		private capabilityService: CapabilityService,
		private route: ActivatedRoute,
		private auth0 : Auth0Service, 
		private sharedService : SharedService) {
		//translate.addLangs(["es", "en"]);
		//translate.setDefaultLang("en");

		auth.setCallback(this);
		recentProjectService.setCallback(this);


		let browserLang = translate.getBrowserLang();
		//translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.login_translated_data = res['LOGIN'];
		})

	}

	ngOnInit() {
		// Initializing the auth request.
		this.authRequest = new AuthRequest();
      	this.authRequest.authtype = "2";
      
      this.route.paramMap.subscribe(params => {
			if (params.get('fwd') != null) {
				this.forwardPath = params.get('fwd');
			}
		});

		// Generating a unique ID for the browser
		var options = { excludeAdBlock: true, excludeJsFonts: true, excludeScreenResolution: true, excludeAvailableScreenResolution: true, excludeUserAgent: true};
		new Fingerprint2(options).get((data) => this.setDeviceId(data));

		//check user session
		if (JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) != null &&
			JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken != null) {
			this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
			this.sessionValidationRequest.validateSession(this);
		}
   }
   
   ngAfterViewInit(): void {
		setTimeout(() => {
			this.authRequest.username = this.userNameInput.nativeElement.value;
			this.authRequest.password = this.passwordInput.nativeElement.value;
		  }, 1000);
	}
	private susbscription: Subscription;
	ngAfterContentInit() {
		this.susbscription = this.sharedService.auth0LoginResponse().subscribe((option: any) => this.auth0Login(option))
    }

	public setDeviceId(deviceId) {
		console.log('LOG' + deviceId);
		this.authRequest.deviceid = deviceId;
		localStorage.setItem(LocalStorage.DEVICE_ID, deviceId);
	}

	public forgotPassword(): void {
		this.alertService.clear()
		if (this.authRequest.username == undefined || this.authRequest.username == '') {
			this.alertService.error('Please enter username');
			return;
		}
		this.passwordResetURL.setCallback(this);
		this.passwordResetURL.getPasswordResetUrl(this.authRequest.username);
	}

	public loginBtnClickEvent(): void {
		// this.alertService.success('Your password incorrect')
		this.loadingService.showLoading(false, false, '', 0)
		if (this.authRequest.username != null && this.authRequest.password != null) {
			//encoding data
			let logRequest = new AuthRequest();
			logRequest.authtype = btoa(this.authRequest.authtype);
			logRequest.deviceid = btoa(this.authRequest.deviceid);
			logRequest.email = btoa(this.authRequest.email);
			logRequest.password = btoa(this.authRequest.password);
			logRequest.username = btoa(this.authRequest.username);

			this.auth.login(JSON.stringify(logRequest));
		} else {
			this.loadingService.hideLoading();
		}
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading();

		if (serviceType == WsType.LOGIN) {
			var userJason = JSON.parse(JSON.stringify(data.payload));

			var user = new LoggedUser(userJason.currentusername,
				userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
			let userPreference = JSON.parse(JSON.parse(JSON.stringify(data.payload.preferences)));

			localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
			localStorage.setItem(LocalStorage.TIMEZONE, userPreference.UserPreference.Portal.General.TimeZone);
			console.log('LOGIN =============LocalStorage.TIMEZONE ' + localStorage.getItem(LocalStorage.TIMEZONE));

			//this will available only for client users. Null for internal users
			UserVariable.clientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
			UserVariable.isClient = data.payload.isclient;
			UserVariable.userId = data.payload.currentuserid;
			UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";
         	//Forward to requested page if it is available
         	console.log('forward path ========== ' + this.forwardPath + "===");
			if ((this.forwardPath != undefined) && (this.forwardPath.trim() != "") && (this.forwardPath.trim() != "dashboard")) {
            	this.router.navigateByUrl(this.forwardPath);
			} else {
				// console.log("userJason.recentproject.isDefaultProject => " + userJason.recentproject.isDefaultProject);
				// console.log("userJason.recentproject.projectType => " + userJason.recentproject.projectType);
				//Otherwise forward to the normal page
				if ((userJason.recentproject != null || userJason.recentproject != undefined)
					&& (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 3)) {
					console.log("has Default Project");
					UserVariable.projectId = userJason.recentproject.projectId;
					localStorage.setItem(LocalStorage.PROJECT_NAME, userJason.recentproject.projectName);
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, userJason.recentproject.projectId);
					localStorage.setItem(LocalStorage.PROJECT_TYPE, userJason.recentproject.projectType);
					localStorage.setItem(LocalStorage.PROJECT_ID, userJason.recentproject.projectId);

					//this will available only for recent projects
					UserVariable.clientId = userJason.recentproject.clientId;
					this.capabilityService.getAllCapabilityList()
						.subscribe(data => {
							localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload))
							//Check if the user has capability to see the dashboard.
							if (this.capabilityService.isCapabilityAssigned("viewDashboard")) {
								this.router.navigateByUrl('dashboard');
							} else {
								this.alertService.clear();
								this.alertService.error(this.login_translated_data.YOU_DONT_HAVE_PERMISSION, true);
								this.router.navigateByUrl('landing');
							}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						})
				} else {
					this.router.navigateByUrl('landing');
				}
			}

		} else if (serviceType == WsType.OPEN_ID_LOGIN) {
			var userJason = JSON.parse(JSON.stringify(data.payload));
			var user = new LoggedUser(userJason.currentusername,
				userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
			let userPreference = JSON.parse(JSON.parse(JSON.stringify(data.payload.preferences)));
			localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
			localStorage.setItem(LocalStorage.TIMEZONE, userPreference.UserPreference.Portal.General.TimeZone);
			//this will available only for client users. Null for internal users
			UserVariable.clientId = data.payload.clientid != null ? JSON.stringify(data.payload.clientid) : "";
			UserVariable.isClient = data.payload.isclient;
			UserVariable.userId = data.payload.currentuserid;
			UserVariable.preferences = data.payload.preferences != null ? JSON.stringify(data.payload.preferences) : "";

			//Forward to requested page if it is available
			if (localStorage.getItem(LocalStorage.REQUESTED_PATH) != null) {
				const page = localStorage.getItem(LocalStorage.REQUESTED_PATH);
				localStorage.removeItem(LocalStorage.REQUESTED_PATH);
				this.router.navigateByUrl(page);
			} else {
				//Otherwise forward to the normal page
				if ((userJason.recentproject != null || userJason.recentproject != undefined)
					&& (userJason.recentproject.isDefaultProject == 1) && (userJason.recentproject.projectType == 3)) {
					UserVariable.projectId = userJason.recentproject.projectId;
					localStorage.setItem(LocalStorage.PROJECT_NAME, userJason.recentproject.projectName);
					localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, userJason.recentproject.projectId);
					localStorage.setItem(LocalStorage.PROJECT_TYPE, userJason.recentproject.projectType);
					localStorage.setItem(LocalStorage.PROJECT_ID, userJason.recentproject.projectId);
					//this will available only for recent projects
					UserVariable.clientId = userJason.recentproject.clientId;
					this.capabilityService.getAllCapabilityList()
						.subscribe(data => {
							localStorage.setItem(LocalStorage.CAPABILITY_LIST, JSON.stringify(data.payload))
							//Check if the user has capability to see the dashboard.
							if (this.capabilityService.isCapabilityAssigned("viewDashboard")) {
								this.router.navigateByUrl('dashboard');
							} else {
								this.alertService.clear();
								this.alertService.error(this.login_translated_data.YOU_DONT_HAVE_PERMISSION, true);
								this.router.navigateByUrl('landing');
							}
						},
						error => {
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						})
				} else {
					this.router.navigateByUrl('landing');
				}
			}
		} else if (serviceType == WsType.SESSION_VALIDATION) {
			this.router.navigateByUrl('landing');
			//Get the default project of the user
			// this.recentProjectService.getDefaultRecentProject(this.loggedInUser.userId.toString(), this);
		} else if (serviceType == WsType.PASSWORD_RESET_URL) {
			this.alertService.info(data.statusDescription, false);
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			//save data in the local storage
			var payload = JSON.parse(data.payload);
			localStorage.setItem(LocalStorage.GOOGLE_API_KEY, payload.key);
			localStorage.setItem(LocalStorage.GOOGLE_CLIENT_ID, payload.clientid);

			this.doGoogleAuthentication();

		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			var payload = JSON.parse(JSON.stringify(data.payload));
			localStorage.setItem(LocalStorage.AUTH0_CONFIGURATIONS, payload);
			var username = this.authRequest.username;
	  		var usernameData = "{ \"username\": \""+username+"\"}";
			this.auth0.authorise(localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS),usernameData);
		} else {

		}
	}


	onFail(res: WsResponse, serviceType: WsType): void {
		this.loadingService.hideLoading();
		this.alertService.clear()
		if (serviceType == WsType.LOGIN) {
			if (res.statusCode == "542") {

				// var userJason = JSON.parse(JSON.stringify(res.payload));
				// var user = new LoggedUser(userJason.currentusername,
				//    userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
				// localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
				// this.router.navigateByUrl('kvc');

				var userJason = JSON.parse(JSON.stringify(res.payload));
				//var user = new LoggedUser(userJason.currentusername,userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
				localStorage.setItem(LocalStorage.USER_ID, userJason.currentuserid);
				this.router.navigateByUrl('kvc');

			} else if (res.statusCode == "541") {
				//expired password
				var userJason = JSON.parse(JSON.stringify(res.payload));
				var user = new LoggedUser(userJason.currentusername,
					userJason.currentuserid, userJason.authtoken, userJason.firstName, userJason.lastName);
				localStorage.setItem(LocalStorage.USER_ID, userJason.currentuserid);					
				localStorage.setItem(LocalStorage.LOGGED_USER, JSON.stringify(user));
				this.alertService.error(res.statusDescription, false);
				setTimeout(() => this.router.navigateByUrl('changepassword'), 1000);
			} else {
				//show the error message            
				this.alertService.error(res.statusDescription, false);
			}
		} else if (serviceType == WsType.OPEN_ID_LOGIN) {

			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.SESSION_VALIDATION) {
			this.alertService.error(res.statusDescription, false);
			setTimeout(() => this.router.navigateByUrl('login'), 1000);
		} else if (serviceType == WsType.PASSWORD_RESET_URL) {
			this.alertService.error(res.statusDescription, false);
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			this.router.navigateByUrl('login');
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			this.router.navigateByUrl('login');
		} else {

		}
	}

	doGoogleAuthentication(): void {
		console.log("LocalStorage.GOOGLE_API_KEY=" + localStorage.getItem(LocalStorage.GOOGLE_API_KEY));
		console.log("LocalStorage.GOOGLE_CLIENT_ID=" + localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID));
		if (localStorage.getItem(LocalStorage.GOOGLE_API_KEY) != null || localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID) != null) {
			console.log("===========DDD="+ GoogleLoginProvider.PROVIDER_ID);
			//console.log("===========userData="+ userData);
			this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
				(userData) => {
					var authRequest: AuthRequest = new AuthRequest();
					authRequest.email = btoa(userData.email);
					authRequest.authtype = btoa("1");
					authRequest.deviceid = btoa(this.authRequest.deviceid);
					console.log("============AAA");
					this.openIdLogin.openIDLogin(JSON.stringify(authRequest), this);
				},
				(reason) => {
					console.log("============BBB");
					this.alertService.error(reason, false);
				}
			);
		} else {
			console.log("============CCC");
			this.openIdService.getGoogleAPIKeys(this);
		}
	}

	/**
	 * Call Auth0 for openId authentication
	 */ 
	 async auth0Authentication(): Promise<void>{
		await this.openIdService.getAuth0Configurations(this);
 
		let username = this.authRequest.username;
		var usernameData = "{ \"username\": \""+username+"\"}";
		this.auth0.authorise(localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS),usernameData);
	}
 
	auth0Login(option: any) {
		var authRequest: AuthRequest = new AuthRequest();
						authRequest.email = btoa(option.userEmail);
						authRequest.authtype = btoa("1");
						let deviceId = localStorage.getItem(LocalStorage.DEVICE_ID);
						authRequest.deviceid = btoa(deviceId);
						this.openIdLogin.openIDLogin(JSON.stringify(authRequest), this);
	}

}
