import { Component, OnInit, ViewChild } from '@angular/core';
import { PrecommissioningSystemSkylineWidgetService } from './precommissioning-system-skyline-widget.service'
import { LoggedUser } from '../../user/logged-user.model';
import { Chart, Highcharts, HIGHCHARTS_MODULES, ChartModule } from 'angular-highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageConst } from '../../util/constants';
import { SharedService } from '../../util/shared.service';
import { MechanicalCompletionService } from '../mechanical-completion.service'
import { PrecommissioningCompletionService } from '../precommissioning-completion.service'
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { GridOptions } from 'ag-grid';
import { CheckSheetService } from '../checksheet.service';
import { UserVariable } from '../../util/common/user-variable';
import { CheckSheetSentFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-sent-file-icon.component'
import { CheckSheetApprovedFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import { LoadingService } from '../../util/loading/loading.service';
// import exporting from '../../../../node_modules/highcharts/modules/exporting.src.js'
import * as FileSaver from 'file-saver';
// exporting(Highcharts);

// export function highchartsModules(){
//     return [ exporting(Highcharts) ];
// }
@Component({
	selector: 'app-precommissioning-system-skyline-widget',
	templateUrl: './precommissioning-system-skyline-widget.component.html',
	styleUrls: ['./precommissioning-system-skyline-widget.component.css']
})

export class PrecommissioningSystemSkylineWidgetComponent implements OnInit {

	public chart: Chart

	public gridOptions: GridOptions;

	public allSystemList: Array<any> = []
	public systemList: Array<any> = []
	public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public exportType: Array<any> = [
		{ 'id': "1", 'text': "Download PNG image" },
		{ 'id': "0", 'text': "Download JPEG image" },
		{ 'id': "0", 'text': "Download PDF document" },
		{ 'id': "0", 'text': "Download SVG vector image" }
	]
	public rowData: any[] = []
	private xAxisList: string[] = []

	@ViewChild('autoShownModal') autoShownModal: ModalDirective;

	private currentMenuItem: string = ''

	private yAxisMax: number = 0

	public self: any
	Highcharts
	private selectedPoint: any = null
	private translatedData: any = null
	public columnDefs: any
	private gridApi
	private gridColumnApi
	public plotLineValue: number = 0;
	public showPCSDetail: boolean = false
	public isLoadingData: boolean = true
	public isNoDataToShow: boolean = false
	public isShowLabel: boolean = false
	public isChart: boolean = false
	public isMinimized: boolean = false
	public isModalShown: boolean = false;
	public showFilterOptions: boolean = false


	constructor(private precommissioningSystemSkylineService: PrecommissioningSystemSkylineWidgetService, private alertService: AlertService,
		private systemService: SystemService, private translate: TranslateService, private checkSheetService: CheckSheetService,
		private precommissioningCompletionService: PrecommissioningCompletionService, private loadingService: LoadingService) {
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 10
		};
		//call to getPreCommissioningSystems() in systemSkylineService
		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['PRECOM_SYSTEM_SKYLINE']
			this.columnDefs = [
				{
					headerName: this.translatedData.CHECKSHEET_CODE,
					field: 'checkSheetCode',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.translatedData.CHECKSHEET_NAME,
					field: 'checkSheetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.translatedData.VENDOR,
					field: 'vendorName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100
				},
				{
					headerName: this.translatedData.SYSTEM_NAME,
					field: 'system',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.translatedData.ASSET,
					field: 'assetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.translatedData.STATUS,
					field: 'status',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 90,
					cellRenderer: this.statusCellRenderer,
					suppressSorting: true
				},
				{
					headerName: this.translatedData.SENT_DOWNLOAD_ALL,
					field: 'initialFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 80,
					suppressFilter: true,
					cellRendererFramework: CheckSheetSentFileIconRenderer,
					suppressSorting: true
				},
				{
					headerName: this.translatedData.APPROVED_DOWNLOAD_ALL,
					field: 'approvedFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100,
					suppressFilter: true,
					cellRendererFramework: CheckSheetApprovedFileIconRenderer,
					suppressSorting: true
				}

			]
			this.initChart()
			this.getPreComSysSkylineDataAndLoadGraph('ALL')
		})
	}

	ngOnInit() {
		this.systemService.getParentSystem().subscribe(
			data => {
				this.systemList = []
				this.systemList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.systemList.push({ 'id': element.systemId, 'text': element.systemNo + ' - ' + element.systemName })
					});
				} else {
					// do nothing
				}
			},
			error => {
				console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)

	}

	/**
	 * select new system. dropdown select event
	 * @param event 
	 */
	selectedSystemId(event) {
		let selectedSystemId = event.id
		this.resetGraph()
		this.getPreComSysSkylineDataAndLoadGraph(selectedSystemId)
	}

	/**
	 * remove system from dropdown. (close button click event)
	 * no empty system always shows 'ALL'
	 * @param event 
	 */
	removedSystem(event) {
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.resetGraph()
		this.getPreComSysSkylineDataAndLoadGraph('ALL')
	}

	private generateXaxis(weekList: any): string[] {
		let returnVal: string[] = []
		if (weekList != null) {
			this.isChart = true
			weekList.forEach(week => {
				returnVal.push(week.weekLabel)
			});
			return returnVal
		}
	}


	private generateDataSeries(payload: any) {
		this.allSystemList = []
		if (payload != null) {
			if (payload.weekList != null) {
				//loop through week list and get week label as x value
				payload.weekList.forEach(week => {
					let xValue: string = week.weekLabel
					let xval: number = this.xAxisList.indexOf(week.weekLabel)
					//loop through system list and generate points for the graph
					week.systemList.forEach(system => {
						let point: any = system
						let yVal: number = 1
						let color: string = this.provideColorToSeries(system.color)
						let tooltip: string = this.generateTooltip(system, xValue)
						let name: string = system.systemNumber
						let data_point: any =
							{
								'x': xval,
								'y': yVal,
								'color': color,
								'name': name,
								'pointToolTip': tooltip,
								'actualColor': system.color,
								'pointData': point
							}
						this.allSystemList.push(data_point)
					});
				});
				this.filterAndLoadSeriesByColor(this.allSystemList)
			}
		}
	}

	private provideColorToSeries(colorStr: string): string {
		let colorValur: string = ''
		switch (colorStr) {
			case 'Green':
				colorValur = '#388E3C'
				break
			case 'Yellow':
				colorValur = '#FBC02D'
				break
			case 'White':
				colorValur = '#EEEEEE'
				break
			case 'Red':
				colorValur = '#D32F2F'
				break
			case 'Orange':
				colorValur = '#F57C00'
				break
			case 'Gray':
				colorValur = '#757575'
				break
		}
		return colorValur
	}

	private generateTooltip(point: any, xValue: string): string {
		let systemNumber: string = point.systemNumber
		let systemName: string = point.systemName
		let fileType: string = ''
		let completionDays: number = point.completionDays
		let openCheckSheetsCount: number = point.openCheckSheetsCount
		let openPunchListCount: number = point.openPunchListCount

		let targetCompletionString: string = ''

		if (completionDays == 0) {
			targetCompletionString = ''
		} else {
			if (completionDays < 0) {
				targetCompletionString = this.translatedData.TOOLTIP_TC + ' ' + completionDays + ' ' + this.translatedData.TOOLTIP_DSA
			} else {
				targetCompletionString = this.translatedData.TOOLTIP_TCI + ' ' + completionDays + ' ' + this.translatedData.TOOLTIP_DS
			}
		}

		if (point.signedFile != null) {
			fileType = this.translatedData.TOOLTIP_PCC_S_CERTIFICATE
		} else {
			fileType = this.translatedData.TOOLTIP_PCC_CERTIFICATE
		}

		let ttString = ''
		ttString += '<b>' + xValue + ' : ' + systemNumber + ' ' + systemName + '</b><br />'
		ttString += fileType + '<br />'
		if (openCheckSheetsCount != 0) {
			ttString += openCheckSheetsCount + ' ' + this.translatedData.TOOLTIP_OCS + ' <br />'
		} else {
			//do nothing
		}
		if (openPunchListCount != 0) {
			ttString += openPunchListCount + ' ' + this.translatedData.TOOLTIP_OPLS + ' <br />'
		} else {
			//do nothing
		}
		if ((point.color == 'Green') || (point.color == 'Yellow') || (point.color == 'Gray')) {
			//do nothing
		} else {
			ttString += targetCompletionString
		}


		return ttString
	}

	private filterAndLoadSeriesByColor(allSystemList: any) {
		let redList: any = this.generateSeriesByColor(allSystemList, 'Red')
		let orangeList: any = this.generateSeriesByColor(allSystemList, 'Orange')
		let whiteList: any = this.generateSeriesByColor(allSystemList, 'White')
		let greenList: any = this.generateSeriesByColor(allSystemList, 'Green')
		let yellowList: any = this.generateSeriesByColor(allSystemList, 'Yellow')
		let greyList: any = this.generateSeriesByColor(allSystemList, 'Gray')

		if (greyList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.OTHER,
				color: '#757575',
				data: greyList.reverse()
			})
		} else {
			//do nothing
		}

		if (redList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.PC_NOT_COMP_BEHIND_TARGET,
				color: '#D32F2F',
				data: redList.reverse()
			})
		} else {
			//do nothing
		}

		if (orangeList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.PC_NOT_COMP_TARGET_IMMINENT,
				color: '#F57C00',
				data: orangeList.reverse()
			})
		} else {
			//do nothing
		}

		if (yellowList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.APPROVED_PC_WITH_A_PL,
				color: '#FBC02D',
				data: yellowList.reverse()
			})
		} else {
			//do nothing
		}

		if (greenList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.APPROVED_PC_NO_A_PL,
				color: '#388E3C',
				data: greenList.reverse()
			})
		} else {
			//do nothing
		}

		if (whiteList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.PC_NOT_COMP_ON_TARGET,
				color: '#EEEEEE',
				data: whiteList.reverse()
			})
		} else {
			//do nothing
		}

		this.isLoadingData = false
	}

	/**
	 * return an array by color
	 * @param systemList 
	 * @param color 
	 */
	private generateSeriesByColor(systemList: any, color: string): any {
		let returnList = systemList.filter(system => system.actualColor === color)
		return returnList
	}

	public initChart() {
		this.chart = new Chart({
			chart: {
				type: 'column',
				zoomType: 'x'
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			xAxis: {
				title: {
					text: this.translatedData.X_AXIS
				},
				categories: this.xAxisList,
				crosshair: true,
				min: 0,
				max: (this.xAxisList.length - 1),
				plotLines: [{
					color: '#757575',
					dashStyle: 'ShortDash',
					value: this.plotLineValue,
					width: 2,
					zIndex: 5,
					label: {
						text: this.translatedData.TODAY,
						verticalAlign: 'top',
						textAlign: 'center',
						y: 40,
						x: -8,
						rotation: 270
					}
				}]
			},
			yAxis: [{ // left y axis	
				gridLineWidth: 1,
				tickWidth: 1,
				minTickInterval: 1,
				title: {
					text: this.translatedData.Y_AXIS
				},
				// max: this.yAxisMax,
				reversedStacks: false
			}],
			plotOptions: {
				column: {
					cursor: 'pointer',
					borderColor: '#9E9E9E',
					borderWidth: 0.5,
					stacking: 'normal',
					dataLabels: {
						enabled: this.isShowLabel,
						rotation: 0,
						style: {
							fontSize: '10',
							textOutline: '0px',
							color: '#212121'
						},
						formatter: function () {
							return this.point.name
						}
					},
					maxPointWidth: 80
				},
				series: {
					stacking: 'normal',
					shadow: false,
					point: {
						events: {
							click: (event) => this.barClickEvent(event),
						}
					},
				}
			},
			tooltip: {
				crosshairs: false,
				shared: false,
				shadow: true,
				backgroundColor: '#FAFAFA',
				xDateFormat: '%Y-%b-%d',
				formatter: function () {
					return this.point.pointToolTip
				}
			},
			legend: {
				borderRadius: 5,
				borderWidth: 0,
				backgroundColor: '#FFFFFF',
				borderColor: '#BDBDBD',
			},
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					},
					legend: {
						borderWidth: 0
					}
				}
			}
		})
	}

	showHideLabel() {
		this.isShowLabel = !this.isShowLabel
		this.initChart()
		this.filterAndLoadSeriesByColor(this.allSystemList)
	}

	public barClickEvent(event: any): boolean {
		console.log('[barClickEvent] (pointData) ' + JSON.stringify(event.point.pointData));
		console.log("Clicked")
		console.log(event);
		this.showPCSDetail = false
		this.selectedPoint = event.point.pointData
		let systemId: string = event.point.pointData.id
		//TO-DO: get data from server eg. used in mc-system-skyline-widget
		this.systemService.getPreComCheckSheets(systemId).subscribe(
			data => {
				console.log('[getCheckSheets] (data) ' + JSON.stringify(data))
				console.log(data)
				this.rowData = data.payload
			},
			error => {
				console.log('[getCheckSheets] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription)
			}
		)

		console.log('[confirmedEvent] (selectedPoint) ' + JSON.stringify(this.selectedPoint));
		this.showPCSDetail = true
		this.showModal()
		return true
	}

	showModal(): void {
		this.isModalShown = true;
	}

	hideModal(): void {
		this.autoShownModal.hide();
	}

	onHidden(): void {
		this.isModalShown = false;
	}

	public confirmedEvent() {
		//this is unwanted because client need to show data in same component
		// so no need to navigate to another screen
		// this.currentMenuItem = localStorage.getItem(LocalStorageConst.SELECTED_MENU_ITEM)
		// // console.log('[confirmedEvent] (currentMenuItem) ' + this.currentMenuItem);
		// if (this.currentMenuItem == 'dashboard') {
		// 	this.sharedService.csmiRequest('com_MC', 'com')
		// } else {
		// }
		console.log('[confirmedEvent] (selectedPoint) ' + JSON.stringify(this.selectedPoint));
		this.showPCSDetail = true
		this.hideModal()
	}

	public refresh() {
		console.log('[PrecommissioningSystemSkylineWidgetComponent] (refresh)');
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.resetGraph()
		this.getPreComSysSkylineDataAndLoadGraph('ALL')
	}

	private getPreComSysSkylineDataAndLoadGraph(systemId: string) {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.precommissioningSystemSkylineService.getPreCommSystemSkylineData(systemId).subscribe(
			data => {
				// console.log('[getPreCommSystemSkylineData] (data) ' + JSON.stringify(data))
				if (data.payload != null) {
					if (data.payload.weekList != null) {
						this.xAxisList = this.generateXaxis(data.payload.weekList)
						this.yAxisMax = data.payload.highestCount
						this.plotLineValue = data.payload.plotLineValue
						// console.log('[getSkylineData] (xAxisList) ' + JSON.stringify(this.xAxisList))
						this.initChart()
						this.generateDataSeries(data.payload)
					} else {
						//no data to show
						this.initChart()
						this.isLoadingData = false
						this.isNoDataToShow = true
					}
				} else {
					//no data to show
					this.initChart()
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			});
	}

	/**
	 * before draw the graph remove current graph
	 * reset graph with the table
	 */
	private resetGraph() {
		this.removeSerie()
		this.showPCSDetail = false
		this.selectedPoint = null
	}

	/**
	 * remove all series from the graph
	 */
	private removeSerie() {
		let num = this.chart.ref.series.length
		// console.log('[removeSerie] num ' + JSON.stringify(num));
		for (let i = 0; i < num; i++) {
			this.chart.removeSerie(0);
		}
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onCellClick(event) {
		// console.log(event)
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);
	}
	/**
	 * status cell renderer
	 * @param param 
	 */
	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'NotIssued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	public getStatus(status: any) {
		switch (status) {
			case 'NotIssued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	downloadCheckSheetSentFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadSentFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.initialFileName);
			}, error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	downloadCheckSheetApprovedFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadApprovedFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.approvedFileName);
			}, error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	showPCCCertificate(selectedPoint: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.precommissioningCompletionService.downloadSentFile(UserVariable.projectId, selectedPoint.file)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PCC_" + selectedPoint.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	showPCCSignedCertificate(selectedPoint: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.precommissioningCompletionService.downloadFile(UserVariable.projectId, selectedPoint.signedFile)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PCC_" + selectedPoint.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}


}
