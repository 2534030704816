<div class="panel panel-default">
    <div class="panel-heading box-shadow panel-header-border-bottom py-1">
        <div class="row px-2">
            <div class="col-sm-12 px-0">
                <div class="float-left pt-1 px-1">
                    <p class="m-0 pl-1">
                        <!-- translated widget name -->
                        {{ 'WORK_ITEM.WIDGET_NAME' | translate }}
                    </p>
                </div>
                <div class="float-right pt-1 px-0">
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="isMinimized = !isMinimized">
                        <i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
                            aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="refresh()">
                        <i class="fas fa-sync-alt" aria-hidden="true"></i>
                    </button>
                    <!-- other buttons like config button -->
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="showFilterOptions = !showFilterOptions">
                        <i class="fas fa-cog" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="showHideLabel()">
                        <i class="fa " [ngClass]="{'fa-eye': !isShowLabel, 'fa-eye-slash': isShowLabel}" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
        <!-- body content -->
        <div class="row" *ngIf="showFilterOptions">
            <div class="col-sm-12 my-2">
                <div class="row px-2">
                    <div class="form-group col-sm-6">
                        <label>{{ 'WORK_ITEM.VENDOR' | translate }} :</label>
                        <ng-select [active]="selectedVendor" [allowClear]="true" (removed)="removedVendor($event)"
                            (selected)="selectedVendorId($event)" [items]="vendorList" placeholder="{{ 'STN_PTN_RUNDOWN.SELECT_SYSTEM' | translate }}"
                            name="Vendor" id="vendor">
                        </ng-select>
                    </div>
                    <div class="form-group col-sm-6">
                        <label>{{ 'WORK_ITEM.CRITICAL' | translate }} :</label>
                        <ng-select [active]="selectedVendor" [allowClear]="true" (removed)="removedVendor($event)"
                            (selected)="selectedCriticalId($event)" [items]="criticalList" placeholder="{{ 'STN_PTN_RUNDOWN.SELECT_SYSTEM' | translate }}"
                            name="Vendor" id="vendor">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <!-- loading panel -->
        <div *ngIf="isLoadingData" class="row">
            <div class="alert alert-warning mx-auto px-3 py-1">
                <h6 class="mb-0">{{ "GENERAL.LOADING_DATA" | translate }}</h6>
            </div>
        </div>
        <!-- no data panel -->
        <div *ngIf="isNoDataToShow" class="row">
            <div class="alert alert-light-nodata mx-auto px-2 py-1 border-0">
                <h6 class="mb-0">{{ "GENERAL.NO_DATA_TO_SHOW" | translate }}</h6>
            </div>
        </div>
        <div class="my-2" [chart]="chart" [ngClass]="{'content-hide': isNoDataToShow, 'content-show': !isNoDataToShow}"></div>
        <div *ngIf="showWIDetail" class="row mb-2">
            <div class="col-sm-12 my-2">
                <div class="row px-2">
                    <div class="col">
                        <label class="float-left">
                            <ng-template #mccsc_template>
                                <span class="badge badge-dark mx-1">
                                    {{ 'WORK_ITEM.TOTAL_WORK_ITEM' | translate }} :
                                    {{selectedPoint.totalWorkItemCount}}
                                </span>
                            </ng-template>
                            <ng-template #mccc_template>
                                <span class="badge badge-dark mx-1">
                                    {{ 'WORK_ITEM.OPEN_WORK_ITEMS' | translate }}
                                </span>
                            </ng-template>
                            <p class="my-1"><b class="mx-1">{{selectedPoint.assetName}} : </b>
                                {{selectedPoint.assetDesc}}</p>
                            <p class="my-1">
                                <b class="mx-1">{{ 'WORK_ITEM.TOTAL_WORK_ITEM' | translate }} : </b>
                                {{selectedPoint.totalWorkItemCount}} <small class="mx-1 h6 text-muted"> | </small>
                                <b class="mx-1">{{ 'WORK_ITEM.OPEN_WORK_ITEMS' | translate }} : </b>
                                {{selectedPoint.openWorkItemCountTooltip}} <small class="mx-1 h6 text-muted"> |
                                </small>
                                <b class="mx-1">{{ 'WORK_ITEM.STATUS' | translate }} : </b> <span class="badge "
                                    [ngClass]="{'badge-warning': selectedPoint.status == 'Open', 'badge-success': selectedPoint.status == 'Closed'}">
                                    {{getStatus(selectedPoint.status)}}</span>
                            </p>
                            <p class="my-1">
                                <span *ngIf="selectedPoint.actualDeliveryDate"><b class="mx-1">{{ 'WORK_ITEM.DELIVERY' | translate }} : </b>
                                {{selectedPoint.actualDeliveryDate | date}} </span> 
                                <span *ngIf="!selectedPoint.actualDeliveryDate"><b class="mx-1">{{ 'WORK_ITEM.TARGET_DELIVERY_DATE' | translate }} : </b>
                                {{selectedPoint.targetDeliveryDate | date}}</span><small class="mx-1 h6 text-muted"> | </small>
                           
                                <span *ngIf="selectedPoint.actualInspectDate"><b class="mx-1">{{ 'WORK_ITEM.LIFTED_DATE' | translate }} : </b>
                                {{selectedPoint.actualInspectDate | date}} </span> 
								<span *ngIf="!selectedPoint.actualInspectDate"><b class="mx-1">{{ 'WORK_ITEM.TARGET_LIFTING_DATE' | translate }} : </b>
                                {{selectedPoint.targetInspectDate | date}} </span><small class="mx-1 h6 text-muted"> | </small>
							
                                <span *ngIf="selectedPoint.actualInstallDate"><b class="mx-1">{{ 'WORK_ITEM.INSTALLED_DATE' | translate }} : </b>
                                {{selectedPoint.actualInstallDate | date}} </span> 
								<span *ngIf="!selectedPoint.actualInstallDate"><b class="mx-1">{{ 'WORK_ITEM.TARGET_INSTALLATION_DATE' | translate }} : </b>
                                {{selectedPoint.targetInstallDate | date}} </span>
							</p>
							<p class="my-1">
								<span *ngIf="(selectedPoint.filesXml)" class="badge badge-btn-success mx-1 cur-pointer pr-2 pl-0 py-0"
                                (click)="downloadFile(selectedPoint.filesXml, 'IRN')">
                                <i class="fa fa-arrow-down p-1 mr-2" aria-hidden="true"></i>
                                {{ 'WORK_ITEM.IRN_DESC' | translate }} </span>
								<span *ngIf="(selectedPoint.otccFileName)" class="badge badge-btn-success mx-1 cur-pointer pr-2 pl-0 py-0"
                                (click)="downloadFile(selectedPoint.otccFileName, 'OTCC')">
                                <i class="fa fa-arrow-down p-1 mr-2" aria-hidden="true"></i>
                                {{ 'WORK_ITEM.OTCC_DESC' | translate }}
                            	</span>
							</p>
                        </label>
                        <button type="button" class="close" aria-label="Close" (click)="resetWidgetDetail()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <hr class="my-1" />
                <!-- <button (click)="export($event)" class="btn btn-primary float-right">{{ 'WORK_ITEM.EXPORT' | translate}}
                    </button> -->
            </div>
            <div class="col-sm-12" *ngIf="eventType == widgetEvent.SHOW_TABLE">
                <ag-grid-angular #agGrid style="width: 100%; height: 400px;" id="workItems" [rowData]="rowData" class="ag-theme-balham"
                    [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true"
                    (columnResized)="onColumnResized($event)" [floatingFilter]="true" [enableRangeSelection]="true"
                    [pagination]="true" [paginationPageSize]="5" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions"
                    (cellValueChanged)="onCellValueChanged($event)" [masterDetail]="true" [detailCellRendererParams]="detailCellRendererParams"
                    [getRowHeight]="getRowHeight" (rowGroupOpened)="onRowGroupOpened($event)"></ag-grid-angular>
            </div>
        </div>

        <div *ngIf="eventType == widgetEvent.SHOW_COMMENT">
            <div class="row">
                <div class="col-sm-12 my-2">
                    <div class="row px-2">
                        <div class="col">
                            <label class="float-left">
                                <p class="my-1 h6">
                                    {{ 'WORK_ITEM.WI_DETAILS' | translate}}
                                </p>
                                <p class="my-1"><b class="mx-2">{{selectedItemForCD.type}}</b>-
                                    {{selectedItemForCD.description}}
                                </p>
                                <p class="my-1">
                                    <b class="mx-2">{{ 'WORK_ITEM.STATUS' | translate }} : </b>
                                    {{selectedItemForCD.status}} <small class="mx-1 h6 text-muted"> | </small>
                                    <b class="mx-2">{{ 'WORK_ITEM.LIFT_CRITICAL' | translate }} : </b>
                                    {{selectedItemForCD.liftCritical}} <small class="mx-1 h6 text-muted"> | </small>
                                    <b class="mx-2">{{ 'WORK_ITEM.PRIORITY' | translate }} : </b>
                                    {{selectedItemForCD.priority}}
                                </p>
                                <p class="my-1">
                                    <b class="mx-2">{{ 'WORK_ITEM.OWNERS' | translate }} : </b>
                                    {{selectedItemForCD.owners}}
                                </p>
                            </label>
                            <button type="button" class="close" aria-label="Close" (click)="eventType = widgetEvent.SHOW_TABLE">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <hr class="my-0" />
                    <!-- <button class="btn btn-primary btn-sm float-right">{{ 'WORK_ITEM.ADD_COMMENT' |translate}}</button> -->
                </div>
            </div>
        </div>

        <div *ngIf="eventType == widgetEvent.SHOW_ADD || eventType == widgetEvent.SHOW_EDIT" style="width: 100%; height: 300px;">
            <!-- *ngIf="showAddComment"-->
            <div class="row">
                <div class="col-sm-12 my-2">
                    <div class="container">
                        <div class="form-group">
                            <div class="col-sm-6 mb-3">
                                <label class="mb-1">{{this.commentState}} {{ 'WORK_ITEM.COMMENT' | translate }}</label>
                                <input #cmt (keyup)="onKey(cmt.value)" [(ngModel)]="this.userComment" type="text"
                                    placeholder="{{ 'WORK_ITEM.ENTER_COMMENT' | translate }}" class="form-control is-invalid"
                                    placement="top">
                            </div>
                            <div class="col-sm-6 mt-2">
                                <label class="mb-1">{{ 'WORK_ITEM.DATE' | translate }}</label>
                                <input [readonly]="true" value={{this.currentDate}} type="text" class="form-control is-invalid"
                                    placement="top">
                            </div>

                        </div>
                        <div class="form-group my-2">
                            <button type="button" class="btn btn-primary btn-sm mx-1 float-right" [disabled]="!isInputValid()"
                                (click)="saveComment($event)">
                                {{ 'MECHANICAL_COMPLETION.SAVE' | translate }}</button>
                            <button type="button" class="btn btn-secondary btn-sm mx-2 float-right" (click)="backToTable($event)">
                                {{ 'MECHANICAL_COMPLETION.CANCEL' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<!-- popup templates -->
<!-- modal templates -->