<div *ngIf="show">
	<div class="container-fluid row mx-auto px-2 my-2">
		<button type="button" class="btn btn-primary btn-sm" (click)="gotoSignatureSection()">
			<i class="fa fa-arrow-down mx-2" aria-hidden="true"></i>{{ 'WORKFLOW_APPROVAL.CLICK_TO_SIGN' | translate }}</button>
		<div class="col-sm-12 text-center">
			<h5 *ngIf="mode=='MCC'">{{ 'WORKFLOW_APPROVAL.WORKFLOW_MCC_TITLE' | translate }}</h5>
			<h5 *ngIf="mode=='STN'">{{ 'WORKFLOW_APPROVAL.WORKFLOW_STN_TITLE' | translate }}</h5>
			<h5 *ngIf="mode=='PCC'">{{ 'WORKFLOW_APPROVAL.WORKFLOW_PCC_TITLE' | translate }}</h5>
			<h5 *ngIf="mode=='CCC'">{{ 'WORKFLOW_APPROVAL.WORKFLOW_CCC_TITLE' | translate }}</h5>
		</div>
		<div class="col-sm-12 text-center">
			<h6 class="text-muted">{{ 'WORKFLOW_APPROVAL.DATE' | translate }}: {{targetCompletionDate}}</h6>
		</div>
	</div>
	<hr class="mx-2 my-2" />
	<div class="container-fluid row p-0 mx-auto">
		<div class="col-sm-2 text-center border-right">
			<div class="form-group form-check">
				<i *ngIf="isSubSystem" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<i *ngIf="!isSubSystem" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label" for="sub-system">Sub-system</label>
			</div>
		</div>
		<div class="col-sm-2 text-center border-right">
			<div class="form-group form-check ">
				<i *ngIf="!isSubSystem" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<i *ngIf="isSubSystem" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label" for="system">System</label>
			</div>
		</div>
		<div class="col-sm-3 border-right">
			<label class=" col"><b>{{ 'WORKFLOW_APPROVAL.SYSTEM' | translate }} : </b></label>
			<label class=" col">{{systemNo}}</label>
		</div>
		<div class="col-sm-5">
			<label class="col"><b>{{ 'WORKFLOW_APPROVAL.SYSTEM_DESCRIPTION' | translate }} : </b></label>
			<label class="col">{{system.systemName}}</label>
		</div>
	</div>
	<hr class="mx-2 my-2" />
	<div class="container-fluid row p-0 mx-auto ">
		<p *ngIf="mode=='MCC'" class="col my-1">{{ 'WORKFLOW_APPROVAL.MCC_WORKFLOW_TITLE' | translate }}</p>
		<p *ngIf="mode=='PCC'" class="col my-1">{{ 'WORKFLOW_APPROVAL.PCC_WORKFLOW_TITLE' | translate }}</p>
		<p *ngIf="mode=='STN'" class="col my-1">{{ 'WORKFLOW_APPROVAL.STN_WORKFLOW_TITLE' | translate }}</p>
		<p *ngIf="mode=='CCC'" class="col my-1">{{ 'WORKFLOW_APPROVAL.CCC_WORKFLOW_TITLE' | translate }}</p>
	</div>
	<hr class="mx-2 my-2" />
	<div class="container-fluid row p-0 mx-auto ">
		<div class="col-sm-12 my-1">
			<h6>{{ 'WORKFLOW_APPROVAL.HOLD_POINT' | translate }} : </h6>
			<p></p>
		</div>
		<div class="col-sm-12 my-1" *ngIf="mode=='MCC'">
			<p class="h6">{{ 'MECHANICAL_COMPLETION.WORKFLOW_TITLE' | translate }}</p>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch4, 'fa-check-square': ch4}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH4' | translate }}
				</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch1, 'fa-check-square': ch1}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH1' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch2, 'fa-check-square': ch2}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH2' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch7, 'fa-check-square': ch7}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH7' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch8, 'fa-check-square': ch8}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH8' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch9, 'fa-check-square': ch9}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH9' | translate }}</label>
			</div>			
			<!-- <div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch7, 'fa-check-square': ch7}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH7' | translate }}
				</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch8, 'fa-check-square': ch8}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH8' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch9, 'fa-check-square': ch9}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'MECHANICAL_COMPLETION.CH9' | translate }}</label>
			</div> -->
		</div>
		<div class="col-sm-12 my-1" *ngIf="mode=='CCC'">
			<p class="h6">{{ 'COMM_COMPLETION.WORKFLOW_TITLE' | translate }}</p>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch1, 'fa-check-square': ch1}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'COMM_COMPLETION.CH1' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch2, 'fa-check-square': ch2}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'COMM_COMPLETION.CH2' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch3, 'fa-check-square': ch3}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'COMM_COMPLETION.CH3' | translate }}
				</label>
			</div>
			<div class="col-sm-12 my-1">
				<div class="form-group form-check " *ngIf="!procedureList.length>0">
					<input class="form-control" type="text" value="{{ 'COMM_COMPLETION.NO_PROCEDURES' | translate }}" readonly>
				</div>
				<table class="table table-striped table-bordered table-sm mx-4" *ngIf="procedureList.length>0">
					<thead class="thead-dark-custom">
						<tr>
							<th></th>
							<th>{{ 'COMM_COMPLETION.PROCEDURE' | translate }}</th>
							<th>{{ 'COMM_COMPLETION.USER' | translate }}</th>
							<th>{{ 'COMM_COMPLETION.DATE' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of procedureList;let i = index;">
							<td class="text-center"><i [ngClass]="{'fa-square': item.Executed=='NO', 'fa-check-square': item.Executed=='YES'}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i></td>
							<td>{{ item.Procedure }}</td>
							<td>{{ getActualContent(item.User) }}</td>
							<td>{{ getActualContent(item.Date) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch4, 'fa-check-square': ch4}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'COMM_COMPLETION.CH4' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch5, 'fa-check-square': ch5}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'COMM_COMPLETION.CH5' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch6, 'fa-check-square': ch6}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'COMM_COMPLETION.CH6' | translate }}</label>
			</div>
		</div>
		<div class="col-sm-12 my-1" *ngIf="mode=='PCC'">
			<p class="h6">{{ 'PRECOMMISSIONING.WORKFLOW_TITLE' | translate }}</p>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch1, 'fa-check-square': ch1}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH1' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch2, 'fa-check-square': ch2}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH2' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch3, 'fa-check-square': ch3}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH3' | translate }}
				</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch4, 'fa-check-square': ch4}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH4' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch5, 'fa-check-square': ch5}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH5' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch6, 'fa-check-square': ch6}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH6' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch7, 'fa-check-square': ch7}" class="fa fa-check-square fa-lg mx-2 my-1 disable-check-box"
				 aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH7' | translate }}
				</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch8, 'fa-check-square': ch8}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH8' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch9, 'fa-check-square': ch9}" class="fa fa-square fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'PRECOMMISSIONING.CH9' | translate }}</label>
			</div>
		</div>
		<div class="col-sm-12 my-1" *ngIf="mode=='STN'">
			<p class="h6">{{ 'STN.WORKFLOW_TITLE' | translate }}</p>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch1, 'fa-check-square': ch1}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH1' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch2, 'fa-check-square': ch2}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH2' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch3, 'fa-check-square': ch3}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH3' | translate }}
				</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch4, 'fa-check-square': ch4}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH4' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch5, 'fa-check-square': ch5}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH5' | translate }}</label>
			</div>
			<!-- <div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch6, 'fa-check-square': ch6}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH6' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch7, 'fa-check-square': ch7}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH7' | translate }}
				</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch8, 'fa-check-square': ch8}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH8' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch9, 'fa-check-square': ch9}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH9' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch10, 'fa-check-square': ch10}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH10' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch11, 'fa-check-square': ch11}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH11' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch12, 'fa-check-square': ch12}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH12' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch13, 'fa-check-square': ch13}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH13' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch14, 'fa-check-square': ch14}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH14' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch15, 'fa-check-square': ch15}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH15' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch16, 'fa-check-square': ch16}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH16' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch17, 'fa-check-square': ch17}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH17' | translate }}</label>
			</div>
			<div class="form-group form-check ">
				<i [ngClass]="{'fa-square': !ch18, 'fa-check-square': ch18}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
				<label class="form-check-label">{{ 'STN.CH18' | translate }}</label>
			</div> -->

		</div>
	</div>
	<div *ngIf="mode!='CCC'">
		<hr class="mx-2 my-2" />
		<div class="container-fluid row p-0 mx-auto ">
			<div class="col-sm-12">
				<p class="h6">{{ 'STN.SUMMARY' | translate }} </p>
			</div>
			<div class="col-sm-12 my-1">
				<div class="form-group form-check " *ngIf="!openPunchlists.length>0">
					<input class="form-control" type="text" value="{{ 'STN.NO_PUNCHLISTS' | translate }}" readonly>
				</div>
				<table class="table table-striped table-bordered table-sm" *ngIf="openPunchlists.length>0">
					<thead class="thead-dark-custom">
						<tr>
							<th>{{ 'STN.CATEGORY' | translate }}</th>
							<th>{{ 'STN.DESCRIPTION' | translate }}</th>
							<th>{{ 'STN.ASSET' | translate }}</th>
							<th>{{ 'STN.SYSTEM_SUBSYSTEM' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of openPunchlists">
							<td>{{ item.category }}</td>
							<td>{{ item.punchlistDescription }}</td>
							<td>{{ item.assetName }}</td>
							<td>{{ item.systemName }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-sm-12 my-1">
				<div class="form-group form-check ">
					<i [ngClass]="{'fa-square': !chRemItems, 'fa-check-square': chRemItems}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
					<label class="form-check-label">{{ 'STN.REM_ITEMS' | translate }}</label>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="mode=='CCC'">
		<hr class="mx-2 my-2" />
		<div class="container-fluid row p-0 mx-auto ">
			<div class="col-sm-12">
				<p class="h6">{{ 'STN.SUMMARY' | translate }} </p>
			</div>
			<div class="col-sm-12 my-1">
				<div class="form-group">
					<input class="form-control" type="text" [value]="getActualContent(comments)" readonly>
				</div>
			</div>
			<div class="col-sm-12 my-1">
				<div class="form-group form-check ">
					<i [ngClass]="{'fa-square': !chRemItems, 'fa-check-square': chRemItems}" class="fa fa-lg mx-2 my-1 disable-check-box" aria-hidden="true"></i>
					<label class="form-check-label">{{ 'STN.REM_ITEMS' | translate }}</label>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="mode=='STN'">
		<hr class="mx-2 my-2" />
		<div class="container-fluid row p-0 mx-auto ">
			<div class="col-sm-12">
				<p class="h6">{{ 'STN.COMMENTS' | translate }} </p>
			</div>
			<div class="col-sm-12 my-1">
				<div class="form-group">
					<input class="form-control" type="text" [value]="comments" readonly>
				</div>
			</div>
		</div>
	</div>
	<hr class="mx-2 my-2" />
	<div class="container-fluid row p-0 mx-auto">
		<div [ngClass]="getColSize()" class="text-center border-right" *ngFor="let task of workflowTasks;let i=index">

			<img width="100%" height="70%" [src]="getSignature(i+1)" *ngIf="IsSignatureAdded(i+1)">

			<div *ngIf="!IsSignatureAdded(i+1)" class="card alert-secondary my-2 p-4 opacity-4">
				<div class="card-body">
					<p class="h3 text-muted">{{ 'WORKFLOW_APPROVAL.NO_SIGNATURE' | translate }}</p>
				</div>
			</div>
			<h6 class="mt-2">{{getTaskName(task.taskName)}}</h6>
			<label class="col">{{task.taskUser}}</label>
		</div>
	</div>
	<hr class="mx-2 my-2" />
</div>
<div class="container p-0 mx-auto mb-4 mt-3" #signatureSection>
	<div [style.display]="getStyle()">
		<div class="col-sm-12 text-center">
			<h6>{{getTaskName(taskModel.formName)}}</h6>
		</div>
		<div class="card col-sm-8 p-4 my-1 mx-auto border border-danger" #container1>
			<!-- <p class="h6 text-center text-muted signature-pad-placeholder m-0">Sign Here</p> -->
			<signature-pad [options]="signaturePadOptions" #SignaturePad1 (onEndEvent)="drawComplete()"></signature-pad>
		</div>
		<div class="col-sm-12 text-center my-2">
			<button class="btn btn-secondary mx-1" (click)="clearSignature()">{{ 'WORKFLOW_APPROVAL.CLEAR' | translate }}</button>
			<button class="btn btn-primary mx-1" type="submit" (click)="submit()" [disabled]="!signature">{{ 'WORKFLOW_APPROVAL.SUBMIT' | translate }}</button>
		</div>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="taskStatus && mode=='STN'">
	<div class="alert alert-primary" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.STN_ALREADY_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="taskStatus && mode=='MCC'">
	<div class="alert alert-primary" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.MCC_ALREADY_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="taskStatus && mode=='PCC'">
	<div class="alert alert-primary" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.PCC_ALREADY_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="taskStatus && mode=='CCC'">
	<div class="alert alert-primary" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.CCC_ALREADY_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="approved && mode=='STN'">
	<div class="alert alert-success" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.STN_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="approved && mode=='MCC'">
	<div class="alert alert-success" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.MCC_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="approved && mode=='PCC'">
	<div class="alert alert-success" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.PCC_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="approved && mode=='CCC'">
	<div class="alert alert-success" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.CCC_APPROVED' | translate }}</p>
	</div>
</div>
<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="error">
	<div class="alert alert-danger" role="alert">
		<p class="h6 text-center">{{ 'WORKFLOW_APPROVAL.INVALID_URL' | translate }}</p>
	</div>
</div>
<alert></alert>