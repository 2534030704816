import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceUrls } from '../../util/service-urls';
import { Injectable } from '@angular/core';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../../util/common/user-variable';

@Injectable({ providedIn: "root" })
export class Auth {
	private callback: WsCallback;
	constructor(private http: HttpClient) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	public login(loginRequest: String) {
		this.http.post(ServiceUrls.LOGIN_URL, loginRequest).subscribe(
			data => {
				// this may be incorrect
				//var val = (data as HttpErrorResponse).message;
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.LOGIN);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onFail(res, WsType.LOGIN);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.LOGIN);
				}
			}
		);
	}

	public keyCodeVerification() {

	}	

}