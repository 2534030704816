import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WorkItemSkylineWidgetService {

    public token: string;
    private callback: WsCallback;

    constructor(private http: HttpClient) {

    }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    getWorkItemSkyline(isCritical: string, vendorId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();     
        const options = { params: new HttpParams().set("token", token).set("vendorId",vendorId).set("type", "ALL").set("isCritical", isCritical) }
        return this.http.get(ServiceUrls.GET_WORKITEM_SKYLINE_DATA, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    getAllVendors(): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token) }
        return this.http.get(ServiceUrls.GET_VENDOR_LIST, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }    

    getWorkitemsList(workPackageId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("workPackageId", workPackageId) }
        return this.http.get(ServiceUrls.GET_WORKITEMSLIST, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }     

    updateWorkItemStatus(workItemId: string, workItemStatus: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("workItemId", workItemId).set("workItemStatus", workItemStatus) }
        return this.http.get(ServiceUrls.UPDATE_WORKITEM_STATUS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }     

    updateWorkItemLiftCritical(workItemId: string, workItemCritical: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("workItemId", workItemId).set("workItemCritical", workItemCritical) }
        return this.http.get(ServiceUrls.UPDATE_WORKITEM_LIFT_CRITICAL, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }      

    addWorkItemComments(workItemId: string, workItemComments: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("workItemId", workItemId).set("workItemComments", workItemComments) }
        return this.http.get(ServiceUrls.ADD_WORKITEM_COMMENTS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }   
    
    updateWorkItemComments(commWorkItemCommentId: string, workItemComment: string, addedDate: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("commWorkItemCommentId", commWorkItemCommentId).set("workItemComment", workItemComment)
        .set("addedDate", addedDate) }
        return this.http.get(ServiceUrls.UPDATE_WORKITEM_COMMENTS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }    
    
    deleteWorkItemComments(commWorkItemCommentId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("commWorkItemCommentId", commWorkItemCommentId) }
        return this.http.get(ServiceUrls.DELETE_WORKITEM_COMMENTS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }    

	downloadFile(projectId: string, documentId: string) {		
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", documentId)
					.set("mode", "ViewApprovedOutput").set("type", "WorkPackage").set("fileName", "InspectionReleaseNotice")
			});
	}  
}