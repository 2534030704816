import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'child-cell',
    template: '<button *ngIf="show()" (click)="delete()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="fas fa-trash-alt mx-2" aria-hidden="true"></i>' +
	'</button>'

})
export class WIDeleteButtonRenderer implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

	public show() {
		if (this.params.data.comment != null ) {
			return true
		} else {
			return false
		}
	}

    public delete() {
        console.log("Delete")

        this.params.context.componentParent.iconClicked = true
        this.params.context.componentParent.delete(this.params)

    }

    refresh(): boolean {
        return false;
    }
}