<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 list-group mt-2">
			<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'dashBoardWidgetList', 'custom-list-item-dark': selectedMenuItem != 'dashBoardWidgetList'}"
			 (click)="sideItemClick('dashBoardWidgetList')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="far fa-tachometer mx-2" aria-hidden="true"></i> Dashboard
				</h6>
			</li>
			<li *ngIf="showCommissioning" class="list-group-item border-0 rounded-0 list-item-bg px-0 py-3 custom-list-item-dark list-cursor" (click)='isCommCollapsed = !isCommCollapsed'>
				<div class="sidebar-list-text " aria-controls="commissioningSubMenu" [attr.aria-expanded]="!isCommCollapsed">
					<h6 class="flaot-left ml-2 mb-0 list-cursor sidebar-list-tex">
						<i class="fas fa-flag-checkered mx-2" aria-hidden="true"></i>Commissioning
						<i class="fa fa-chevron-down float-right mr-3" [ngClass]="{'animate-arrow-0': !isCommCollapsed, 'animate-arrow-180': isCommCollapsed}"
						 aria-hidden="true"></i>
					</h6>
				</div>
			</li>
			<ul class="col-sm-12 px-0 py-0 list-group rounded-0" [ngbCollapse]="!isCommCollapsed" id="commissioningSubMenu">
				<li *ngIf="showOverview" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'overview', 'sub-menu-item-list': selectedMenuItem != 'overview'}"
				(click)="sideItemClick('overview')">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-search mx-2" aria-hidden="true"></i> Overview
					</h6>
				</li>
				<li *ngIf="showMechanicalCompletion" class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_MC')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_MC', 'sub-menu-item-list': selectedMenuItem != 'com_MC'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-wrench mx-2" aria-hidden="true"></i>Mechanical Completion
					</h6>
				</li>
				<li *ngIf="showPreCommissioning" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_PC')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_PC', 'sub-menu-item-list': selectedMenuItem != 'com_PC'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-plug mx-2" aria-hidden="true"></i>Precommissioning
					</h6>
				</li>
				<li *ngIf="showWorkItems" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_WI')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_WI', 'sub-menu-item-list': selectedMenuItem != 'com_WI'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fal fa-dolly-flatbed-alt mx-2" aria-hidden="true"></i>Work Items
					</h6>
				</li>
				<li *ngIf="showFacilityAcceptance" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_FA')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_FA', 'sub-menu-item-list': selectedMenuItem != 'com_FA'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fas fa-industry-alt mx-2" aria-hidden="true"></i>Facility Acceptance
					</h6>
				</li>
				<li *ngIf="showPunchlists" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_PL')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_PL', 'sub-menu-item-list': selectedMenuItem != 'com_PL'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fas fa-list mx-2" aria-hidden="true"></i>Punchlists
					</h6>
				</li>
				<li *ngIf="showIRN" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_IRN')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_IRN', 'sub-menu-item-list': selectedMenuItem != 'com_IRN'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fas fa-search mx-2" aria-hidden="true"></i>Inspection Release Notice
					</h6>
				</li>
				<li *ngIf="showSystemHandover" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_SH')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_SH', 'sub-menu-item-list': selectedMenuItem != 'com_SH'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fas fa-hand-holding mx-2" aria-hidden="true"></i>System Handover
					</h6>
				</li>
				<li *ngIf="showSTNPTN" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_STNPTN')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_STNPTN', 'sub-menu-item-list': selectedMenuItem != 'com_STNPTN'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fal fa-network-wired mx-2" aria-hidden="true"></i>STN/PTN
					</h6>
				</li>
				<li *ngIf="showCommCompletion" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_CC')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_CC', 'sub-menu-item-list': selectedMenuItem != 'com_CC'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fal fa-box-check mx-2" aria-hidden="true"></i>Commissioning Completion
					</h6>
				</li>
				<!-- <li *ngIf="showChecksheets" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_CS')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_CS', 'sub-menu-item-list': selectedMenuItem != 'com_CS'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fal fa-calendar-check mx-2" aria-hidden="true"></i>Checksheets
					</h6>
				</li> -->
				<li *ngIf="showOTN" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_OTN')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_OTN', 'sub-menu-item-list': selectedMenuItem != 'com_OTN'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="far fa-screwdriver mx-2" aria-hidden="true"></i>Onshore Turnover Notice
					</h6>
				</li>
				<li *ngIf="showBulkImport" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" (click)="sideItemClick('com_Import')"
				 [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'com_Import', 'sub-menu-item-list': selectedMenuItem != 'com_Import'}">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fal fa-inbox-in mx-2" aria-hidden="true"></i>Bulk Data Import
					</h6>
				</li>
				<li *ngIf="showAdhocReports" class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'adhoc_reports', 'sub-menu-item-list': selectedMenuItem != 'adhoc_reports'}"
				 (click)="sideItemClick('adhoc_reports')">
					<h6 class="flaot-left mb-0 ml-4">
						<i class="fas fa-file-chart-pie mx-2" aria-hidden="true"></i>Ad Hoc Reports
					</h6>
				</li>
			</ul>
			<li *ngIf="showDocumentation" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'documentation', 'custom-list-item-dark': selectedMenuItem != 'documentation'}"
			 (click)="sideItemClick('documentation')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fas fa-file-alt mx-2" aria-hidden="true"></i>Documentation
				</h6>
			</li>
			<li *ngIf="showCMMS" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg custom-list-item-dark list-cursor">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fas fa-toolbox mx-2" aria-hidden="true"></i>CMMS
				</h6>
			</li>
			<li *ngIf="showProcedures" class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg custom-list-item-dark list-cursor" [ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'procedures', 'custom-list-item-dark': selectedMenuItem != 'procedures'}"
			 (click)="sideItemClick('procedures')">
				<h6 class="flaot-left ml-2 mb-0 sidebar-list-text">
					<i class="fas fa-list-ol mx-2" aria-hidden="true"></i>Procedures
				</h6>
			</li>
		</ul>
	</div>
</div>