<div class="front">
	<p *ngIf="isPresent" class="text-left" (click)="viewPersonStatus()" [innerHTML] = "docuSignStatus"></p>
</div>
<button *ngIf="workflow" (click)="startWorkflow()" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="fa fa-play mx-2" aria-hidden="true"></i>
</button>

<ng-template #docusignTemplate>
    <div class="modal-header">
        <h5 class="modal-title pull-left">{{ system }}</h5>
        <button type="button" id="table_close" class="close pull-right" aria-label="Close" (click)="modelRefCloseEvent()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div  class="modal-body">
        <app-docusign-peson-status  [docusignId] ="docuSignId" [system] ="system" [type]="type"></app-docusign-peson-status>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="modelRefCloseEvent()"> Close</button>
    </div>
</ng-template>
