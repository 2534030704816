<div class="row notification-header py-2">
	<div class="col-sm-8">
		<b>Notification</b>
	</div>
	<div class="col-sm-4" *ngIf="!isHideClearAll">
		<!--a class="notification-show-all-btn">Show All</a-->
        <a class="notification-show-all-btn link-primary" (click)="clearAll()">
            {{ 'NOTIFICATION_LIST.CLEAR_ALL' | translate}}
        </a>
    </div>
</div>
<div *ngFor="let notification of notificationList">
	<div class="card col-sm-12 rounded-0 p-0 border-top-0 border-left-0 border-right-0">
		<div class="card-body row p-0">
			<!--<div class="col-sm-2 ">
						<span class="btn btn-outline-dark btn-circle btn-sm float-left ">{{notification.userFirstName | slice:0:1}}{{notification.userLastName | slice:0:1}}</span>
					</div>-->

			<!-- notification with user name and time and notification text -->
			<!-- <div class="col-sm-12 ">			
						<p class="float-left mb-1">
							<span class="btn btn-outline-dark btn-circle btn-sm float-left ">{{notification.userFirstName | slice:0:1}}{{notification.userLastName | slice:0:1}}</span>
							&nbsp;&nbsp;<b>{{notification.user}}</b> <span [innerHTML]="notification.text | linky"> </span></p>
						<small class="float-left mb-2 text-muted">{{notification.time}}</small>
					</div> -->

			<!-- notification with notification text only -->
			<div class="col-sm-12 pointer-cursor py-2" [ngClass]="{'notification_read': notification.viewed, 'notification_unread': !notification.viewed}"
			 (click)="noticifationClickEvent(notification)">
				<p class="float-left mb-1">
					<span [innerHTML]="notification.notificationContent | linky"> </span>
				</p>
				<p class="float-left mb-1">
					<span class="small" [innerHTML]="notification.firingTimestamp"></span>
				</p>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!isHideSeeMore" class="seemore_back">
    <a  class="btn link-primary  btn-sm btn-seemore" (click)="loadData(false)">
        {{ 'NOTIFICATION_LIST.SEE_MORE' | translate}}
    </a>
</div>