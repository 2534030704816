import { Component, OnInit } from '@angular/core';
import { ControlledDocumentsWidgetService } from './controlled-documents-widget.service';
import { ControlledDocumentsWidgetModel } from './controlled-document-widget.model';
import { LoggedUser } from '../../user/logged-user.model';
import { LocalStorage } from '../../util/localstorage.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import * as FileSaver from 'file-saver';
import { UserVariable } from '../../util/common/user-variable';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-controlled-documents-widget',
	templateUrl: './controlled-documents-widget.component.html',
	styleUrls: ['./controlled-documents-widget.component.css']
})
export class ControlledDocumentsWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	private gridApi;
	private gridColumnApi;
	public rowData = [];
	public controlledDocumentList: Array<ControlledDocumentsWidgetModel> = new Array<ControlledDocumentsWidgetModel>();
	private loggedInUser: LoggedUser;
	public columnDefs;
	cd_translated_data: any = null;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
		
	  };

	constructor(public controlledDocumentService: ControlledDocumentsWidgetService, private alertService: AlertService, private translate: TranslateService,
		private sharedService: SharedService, private loadingService: LoadingService) {

		let browserLang = translate.getBrowserLang();
		var self = this;
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cd_translated_data = res['CONTROLLED_DOCUMENTS']
			this.columnDefs = [
				{
					headerName: this.cd_translated_data.DOCUMENTNAME,
					field: "documentName", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", width: 300, minWidth: 300
				},
				{
					headerName: this.cd_translated_data.DOCUMENTNO,
					field: "documentNo", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200
				},
				{
					headerName: this.cd_translated_data.REVISION,
					field: "revision", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", width: 100, minWidth: 100
				},
				{
					headerName: this.cd_translated_data.REVISIONDATE,
					field: "revisionDate",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200,
					filter: "agTextColumnFilter",
					// comparator: this.dateComparator,
					// filterParams: {
					// 	comparator: function (filterLocalDateAtMidnight, cellValue) {
							
					// 		var dateAsString = cellValue;
					// 		var cellDate = new Date(dateAsString)
					// 		if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					// 			return 0;
					// 		}
					// 		if (cellDate < filterLocalDateAtMidnight) {
					// 			return -1;
					// 		}
					// 		if (cellDate > filterLocalDateAtMidnight) {
					// 			return 1;
					// 		}
					// 	},
					// 	//browserDatePicker: true
					// }
				},
				{
					headerName: this.cd_translated_data.VIEW, field: 'view', autoHeight: true, cellClass: "cell-wrap-text", suppressFilter: true, minWidth: 200, cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						var fileType = '';
						if(params.data){
							if(params.data.documentName){
								fileType = params.data.documentName.split('.').pop(); 
							}
						}
						if (params.value) {
							if(fileType.toLocaleLowerCase() === 'pdf'){
								eDiv.innerHTML = '<button *ngIf="params.value" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
								'</button>';
							} else if(fileType.toLocaleLowerCase() === 'zip'){
								eDiv.innerHTML = '<button *ngIf="params.value" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="fas fa-file-archive mx-2" aria-hidden="true"></i>' +
								'</button>';
							} else if(fileType.toLocaleLowerCase() === 'pptx'){
								eDiv.innerHTML = '<button *ngIf="params.value" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="fas fa-file-powerpoint mx-2" aria-hidden="true"></i>' +
								'</button>';
							} else if(fileType.toLocaleLowerCase() === 'xml'){
								eDiv.innerHTML = '<button *ngIf="params.value" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="fas fa-file-code mx-2" aria-hidden="true"></i>' +
								'</button>';
							} else{
								eDiv.innerHTML = '';
							}
							var eButton = eDiv.querySelectorAll('.btn-sm')[0];

							eButton.addEventListener('click', function () {
								self.downloadFile(params, fileType)
							});
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				}
			];
		});
		controlledDocumentService.getAllDocuments(this.loggedInUser)
			.subscribe(
				data => {
					this.loadData(data.payload)

				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});

	}

	dateComparator(date1, date2) {
		var date1Number = new Date(date1).getTime();
		var date2Number = new Date(date2).getTime();

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {
		this.columnDefs;
		this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()

		this.getDataFromServer(params)
		// setTimeout(() => {
		// 	params.api.sizeColumnsToFit();
		// }, 500);
		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	loadData(payload: any) {
		console.log(payload)
		if (payload != null) {
			this.rowData = [];
			for (let i = 0; i < payload.length; i++) {
				this.rowData.push({
					outputId: payload[i]["id"], // to get pdf
					documentName: payload[i]["name"],
					documentNo: payload[i]["no"],
					revision: payload[i]["revision"],
					revisionDate: payload[i]["revisionDate"],
					view: payload[i]["view"],
				});
			}
		}
	}

	downloadFile(params, fileType) {
		var ftype = 'application/' + fileType;
		this.loadingService.showLoading(false, false, '', 0)
		this.controlledDocumentService.downloadFile(UserVariable.projectId, params.data.outputId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: ftype });
				FileSaver.saveAs(file, "CD_" + params.data.documentName + "_" + "." + fileType);
				//let file = new Blob([response], { type: 'application/pdf' });
				//FileSaver.saveAs(file, "CD_" + params.data.documentName + "_" + ".pdf");
			},
				error => {
					this.loadingService.hideLoading()
					this.alertService.clear()
					this.alertService.error("error")
				});
	}

	onCellClicked(event: any) {

	}


	public getDataFromServer(params: any) {
		this.gridApi.showLoadingOverlay()
		this.controlledDocumentService.getAllDocuments(this.loggedInUser)
			.subscribe(
				data => {
					this.loadData(data.payload)
					setTimeout(() => {
						params.api.sizeColumnsToFit();
					}, 500);
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});

	}

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.controlledDocumentService.getAllDocuments(this.loggedInUser)
			.subscribe(
				data => {
					this.loadData(data.payload)
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});

	}
}

