<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg" #navPanel>
	<app-header [projectName]="projectName" [showHamburger]="true"></app-header>
</nav>
<div class="container-fluid">
	<div class="row">
		<nav class="col-md-2 d-md-block bg-dark box-shadow sidebar animated faster" [ngClass]="{'slideInLeft': isSideBar, 'slideOutLeft': !isSideBar}">
			<div class="sidebar-sticky">
				<app-sidebar></app-sidebar>
			</div>
		</nav>
		<main role="main" class="px-0" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" #main [style.padding-top.px]="paddingtop">
			<div class="col-sm-12 col-md-12 mx-auto pl-4 pt-2">
				<div *ngIf="!isShowFullScreenWidget" class="row d-block mt-2">
					<div *ngFor="let widget of widgetList; let i = index" class="{{ widget.widgetSize }} mt-1 mb-1 float-left">
						<div #widgetBody></div>
					</div>
				</div>

				<!-- <div *ngIf="isShowFullScreenWidget" class="row d-block mt-2">
					<div class="col-sm-12 mt-1 mb-1 float-left">
						<div class="panel panel-default">
							<div class="panel-heading box-shadow">
								<div class="row">
									<div class="col-sm-9">
										<b>{{ fullScreenWidget.widgetName }}</b>
									</div>
									<div class="col-sm-3 float-right">
										<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor" (click)="exitFullScreen(widget)">
											<i class="fa fa-times"></i>
										</button>
										<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor">
											<i class="fa fa-refresh" aria-hidden="true"></i>
										</button>
									</div>
								</div>
							</div>
							<div class="card-block card-body mt-2 pb-1 pt-1" [ngClass]="{'content-hide': fullScreenWidget.isMinimized, 'content-show': !fullScreenWidget.isMinimized}">
								<app-samplepiechart></app-samplepiechart>
							</div>
						</div>
					</div>
				</div> -->
			</div>
		</main>
	</div>

