import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridOptions } from "ag-grid";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WIDGET_EVENT } from '../../util/constants';
import { CustomTypeaheadComponent } from '../../util/custom-typeahead/custom-typeahead.component';
import { SharedService } from '../../util/shared.service';
import { OnshoreTurnoverNoticeService } from '../onshore-turnover-notice.service';
import { TranslateService } from '@ngx-translate/core';
import { ManageCellRenderComponent } from './cell-render/manage-cell-render/manage-cell-render.component'
import { DeleteCellComponent } from './cell-render/delete-cell/delete-cell.component'
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { WorkflowService } from '../workflow.service';
import { CheckSheetService } from '../checksheet.service';
import { CapabilityService } from '../../util/capability.service';
import { Guid } from "guid-typescript";
import * as FileSaver from 'file-saver';
//import { FileIconRenderer } from './cell-renderer/file-icon/approved-file-icon.component'
import { SentComponent } from './cell-render/sent/sent.component'
import { ReturnedComponent } from './cell-render/returned/returned.component';
import { CheckSheetSentFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-sent-file-icon.component'
import { CheckSheetApprovedFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import "ag-grid-enterprise";
import * as XLSX from 'xlsx';
import { LoadingService } from '../../util/loading/loading.service';
import { DatePipe } from '@angular/common';
import { OnshoreTurnoverNoticeDocuSignRenderer } from './cell-render/onshore-turnover-notice-docu-sign/onshore-turnover-notice-docu-sign.component';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { AddeditotnComponent } from './addeditotn/addeditotn.component';
import { CustomDateComponent } from 'src/app/custom-date-component.component';
import * as moment from 'moment';

@Component({
  selector: 'app-onshore-turnover-notice-widget',
  templateUrl: './onshore-turnover-notice-widget.component.html',
  styleUrls: ['./onshore-turnover-notice-widget.component.css']
})
export class OnshoreTurnoverNoticeWidgetComponent implements OnInit {

  public isMinimized: boolean = false
	@ViewChild("deleteOTNModal") deleteOTNModal: TemplateRef<any>;
	@ViewChild("incompleteOTNModal") incompleteOTNModal: TemplateRef<any>;
	@ViewChild("editOTNModal") editOTNModal: TemplateRef<any>;

	generateOut: boolean = false;
	public deleteModalRef: BsModalRef;
	public incompleteModalRef: BsModalRef;
	public editModalRef: BsModalRef;
	public bsModalRef: BsModalRef;
	public gridOptions: GridOptions;
	public detailGridOptions: GridOptions;
	private rowData: any[] = []
	private columnDefs: any
	private gridApi
	private gridColumnApi
	otn_translated_data: any = null;
	eventType: any = WIDGET_EVENT.SHOW_TABLE
	widgetEvent = WIDGET_EVENT
	private selectedItem: any = null
	private bsConfig: Partial<BsDatepickerConfig>
	public targetCompletionDate: Date = null
	public required: boolean = null
	public vendorManaged: boolean = null
	public openPunchlists: any[] = [];
	public closedPunchlists: any[] = [];
	public userList: any[] = []
	public allPunchlistCompleted: boolean = false;
	public isOTNHasIRN: boolean = false;
	public isSystemHasIRN: boolean = false;
	public isAllChecksheetsPunchlistsCompleted: boolean = false;
	assetIrnCheckList: any
	assetOpenPunchlistCheckList: any
	hasAssetWithNoIRN: boolean = false;
	hasAssetWitOpenPunchlists: boolean = false;
	public selectedAssignee: any[] = null

	public WorkflowTaskList: any[] = [];

	public otnId: any = null;
	public workflowId: any = null
	public taskCount: any = 0
	assignee1: any
	assignee2: any
	assignee3: any
	taskname1: any
	taskname2: any
	taskname3: any
	assetsStr: string = "";

	showOTNDetail: boolean = false
	detailRowData: any[] = []
	detailColumnDefs: any
	detailGridApi
	detailGridColumnApi
	iconClicked: boolean = false
	modifyOnshoreTurnoverNotice: boolean = false;
	showRequiredErr: boolean = false

	isSubmitted: boolean = false
	validAssignee1: boolean = true
	validAssignee2: boolean = true
	validAssignee3: boolean = true

	validAssigneeEmail1: boolean = true
	validAssigneeEmail2: boolean = true
	validAssigneeEmail3: boolean = true

	public allIRNCompleteY: any = ""
	public allIRNCompleteN: any = ""
	public allIRNCompleteNA: any = ""
	public finalVisualInspectionCompleteY: any = ""
	public finalVisualInspectionCompleteN: any = ""
	public finalVisualInspectionCompleteNA: any = ""
	public handoverCertificateIssuedY: any = ""
	public handoverCertificateIssuedN: any = ""
	public handoverCertificateIssuedNA: any = ""
	public plIdentifiedCategorizedY: any = ""
	public plIdentifiedCategorizedN: any = ""
	public plIdentifiedCategorizedNA: any = ""
	public APunchlistClosedY: any = ""
	public APunchlistClosedN: any = ""
	public APunchlistClosedNA: any = ""
	allIRNComplete: string = "";
	finalVisualInspectionComplete: string = "";
	handoverCertificateIssued: string = "";
	plIdentifiedCategorized: string = "";
	typeAPunchlistClosed: string = "";
	radioSelected1:string = "";
	radioSelected2:string = "";
	radioSelected3:string = "";
	radioSelected4:string = "";
	radioSelected5:string = "";
	itemsList1: any[] = [{name:'Yes', value:'allIRNCompleteY'}, {name:'No', value:'allIRNCompleteN'}, {name:'N/A', value:'allIRNCompleteNA'}];
	itemsList2: any[] = [{name:'Yes', value:'finalVisualInspectionCompleteY'}, {name:'No', value:'finalVisualInspectionCompleteN'}, {name:'N/A', value:'finalVisualInspectionCompleteNA'}];
	itemsList3: any[] = [{name:'Yes', value:'handoverCertificateIssuedY'}, {name:'No', value:'handoverCertificateIssuedN'}, {name:'N/A', value:'handoverCertificateIssuedNA'}];
	itemsList4: any[] = [{name:'Yes (Punchlist items identified and categorized)', value:'plIdentifiedCategorizedY'}, {name:'No (Punchlist items not yet identified or categorized)', value:'plIdentifiedCategorizedN'}, {name:'N/A (Assets on OTN are free from punches)', value:'plIdentifiedCategorizedNA'}];
	itemsList5: any[] = [{name:'Yes (“A” Punches exist but are closed)', value:'APunchlistClosedY'}, {name:'No (“A” Punches exist but are not closed)', value:'APunchlistClosedN'}, {name:'N/A (No “A” punches were/are identified)', value:'APunchlistClosedNA'}];

	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
		
	  };
	  
	constructor(private translate: TranslateService,
		private sharedService: SharedService,
		private onshoreTurnoverNoticeService: OnshoreTurnoverNoticeService,
		private alertService: AlertService,
		private userService: UserService,
		private systemService: SystemService,
		private workflowService: WorkflowService,
		private checkSheetService: CheckSheetService,
		private loadingService: LoadingService,
		private capabilityService: CapabilityService,
		
		private bsModalService: BsModalService) {

		this.modifyOnshoreTurnoverNotice = capabilityService.isCapabilityAssigned("modifyOnshoreTurnoverNotice");

		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 10
		};

		this.detailGridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 5
		};

		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.otn_translated_data = res['ONSHORE_TURNOVER_NOTICE']
			this.columnDefs = [
				{
					headerName: this.otn_translated_data.ASSETS,
					field: 'assetsText', filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 280,
					cellStyle: { 'white-space': 'normal' },
					comparator: function (assetList1, assetList2) {
					  var assets1 = "";
					  var assets2 = "";
					  if (assetList1 != null) {
						var listSize = assetList1.length
						for (let i = 0; i < listSize; i++) {
						  assets1 += assetList1[i].name;
						  if (i < listSize - 1) {
							assets1 += ", ";
						  }
						}
					  }
					  if (assetList2 != null) {
						var listSize = assetList2.length
						for (let i = 0; i < listSize; i++) {
						  assets2 += assetList2[i].name;
						  if (i < listSize - 1) {
							assets2 += ", ";
						  }
						}
					  }
					  return ('' + assets1).localeCompare(assets2);
					}

				},
				{ headerName: this.otn_translated_data.OTN_STATUS, field: 'otnstatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 90 },
				/* {
					headerName: this.otn_translated_data.TARGET_COMPLETION_DATE, field: 'newtargetCompletionDate', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);

							} else {
								var cellDate = new Date(dateAsString)
							}

							
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				}, */
				{ headerName: this.otn_translated_data.VENDOR, field: 'vendor', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 280 },
			]

				if (this.modifyOnshoreTurnoverNotice) {
					this.columnDefs.push({ headerName: this.otn_translated_data.EDIT, 
						field: 'edit', 
						autoHeight: false, 
						cellClass: "cell-wrap-text", 
						minWidth: 70, 
						suppressFilter: true, 
						cellRendererFramework: ManageCellRenderComponent})
					this.columnDefs.push({
						headerName: this.otn_translated_data.START_WORKFLOW,
						field: 'workflow',
						valueGetter: function(params) {
							if(params.data.isStart) {
								return params.data.isStart;
							} else {
								return params.data.docuSignStatus == null ? '' : params.data.docuSignStatus;
							}	
						},
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 125,
						//suppressFilter: true,
						filter: "agTextColumnFilter",
						cellRendererFramework: OnshoreTurnoverNoticeDocuSignRenderer,
						suppressSorting: true
					})
					this.columnDefs.push({
						headerName: this.otn_translated_data.FAILURE_REASON,
						field: 'errorMessage',
						suppressFilter: true,
						hide: true,
						minWidth: 200
					})
					this.columnDefs.push({
						headerName: this.otn_translated_data.DELETE, 
						field: 'delete', 
						autoHeight: true, 
						cellClass: "cell-wrap-text", 
						minWidth: 120,
				 		suppressFilter: true, 
						cellRendererFramework: ManageCellRenderComponent, 
						suppressSorting: true
					})
					this.columnDefs.push({
						headerName: this.otn_translated_data.SENT_PDF,
						field: "sentpdf",
						minWidth: 75,
						cellStyle: { 'text-align': 'center' },
						cellRendererFramework: ManageCellRenderComponent,
					})
					this.columnDefs.push({
						headerName: this.otn_translated_data.RETURNED_PDF,
						field: "returnedpdf",
						minWidth: 75,
						cellStyle: { 'text-align': 'center' },
						cellRendererFramework: ManageCellRenderComponent,
					})
				}
				//{ headerName: this.otn_translated_data.START_WORKFLOW, field: 'workflow', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 70, suppressFilter: true, cellRendererFramework: OnshoreTurnoverNoticeDocuSignRenderer, suppressSorting: true},	
				
			
		});
	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {
		this.onshoreTurnoverNoticeService.setOTNcomp(this);
    	this.onshoreTurnoverNoticeService.loadTableDataResponse().subscribe((option: any) => this.refresh());
		this.onshoreTurnoverNoticeService.updateUIafterOTNGenerateResponse().subscribe((option: any) => this.updateUIAfterGenerateOutPut());
    	this.onshoreTurnoverNoticeService.updateUIafterApprovalResponse().subscribe((option: any) => this.updateUIAfterApprove(option.otnId, option.uploaded));
	}

	agInit(params: any): void {
		console.log(params);
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onDetailColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.refresh();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	onDetailGridReady(params) {
		this.detailGridApi = params.api;
		this.detailGridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.detailGridApi.showLoadingOverlay()
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
		}

	onRowClick(event) {
		console.log('row clicked ' + this.iconClicked)
		if (!this.iconClicked) {
			this.showOTNDetail = true
			if (this.detailGridApi) {
				this.detailGridApi.showLoadingOverlay()
			}

			if (event.data) {
				this.systemService.getCheckSheets(event.data.systemId).subscribe(
					data => {
						console.log('[getCheckSheets] (data) ' + JSON.stringify(data))
						this.detailRowData = data.payload
					},
					error => {
						console.log('[getCheckSheets] (error) ' + JSON.stringify(error))
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					}
				)
			}
		} else {
			this.iconClicked = false
		}
	}

	public getStyle(status: any) {
		if (status == 'Approved') {
			return 'badge-success'
		} else {
			return 'badge-warning'
		}
	}

	public getStatus(status: any) {
		switch (status) {
			case 'NotIssued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'NotIssued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	isInputValid() {
		if (!this.selectedItem.otnstatus) {
			if (this.targetCompletionDate && this.required) {
				return true
			} else {
				return false
			}
		} else {
			if (this.selectedItem.otnRequired && !this.required) {
				if (this.targetCompletionDate) {
					return true
				} else {
					return false
				}
			} else {
				if (this.targetCompletionDate && this.required) {
					return true
				} else {
					return false
				}
			}
		}
	}

	public showWizard(data: any) {
		this.selectedItem = data.data
		console.log('[showWizard] (data) ' + JSON.stringify(this.selectedItem));
		this.eventType = WIDGET_EVENT.SHOW_WIZARD
	}

	private backToTable() {
		this.isSubmitted = false;
		this.selectedItem = null
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	deleteOTN() {
		this.editModalRef.hide();
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.loadingService.showLoading(false, false, '', 0)
		this.onshoreTurnoverNoticeService.deleteOnshoreTurnoverNotice(this.selectedItem.otnId)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	cancelEditOTN() {
		this.editModalRef.hide()
	}

	

	newOTNButtonClick() {
		this.openAddEditOTNModal();
	  }
	
	  private openAddEditOTNModal() {
		const initialState: object = {
		  edit: false
		};
		this.bsModalRef = this.bsModalService.show(AddeditotnComponent, { initialState, class: 'modal-md', backdrop: 'static', keyboard: false })
	  }

	continue() {
		if (this.incompleteModalRef) {
			this.incompleteModalRef.hide()
		}
		this.loadingService.showLoading(false, false, '', 0)
		this.eventType = this.widgetEvent.SHOW_WIZARD
			//get open punchlist items
			this.onshoreTurnoverNoticeService.getPunchlistItemsByAssetsCategoryAndItemStatus("A", this.assetsStr, "Open").subscribe(
				data1 => {
					this.openPunchlists = []
					if (data1.payload) {
						this.openPunchlists = data1.payload
					}
					if(this.openPunchlists.length > 0){
						this.loadingService.hideLoading()
						this.radioSelected5 = 'APunchlistClosedN'; //set radio button value
					}else{
						this.onshoreTurnoverNoticeService.getPunchlistItemsByAssetsCategoryAndItemStatus("A", this.assetsStr, "Closed").subscribe(
							data2 => {
								this.loadingService.hideLoading()
								this.closedPunchlists = []
								if (data2.payload) {
									this.closedPunchlists = data2.payload
								}
								if(this.closedPunchlists.length > 0){
									this.radioSelected5 = 'APunchlistClosedY'; //set radio button value
								}else{
									this.radioSelected5 = 'APunchlistClosedNA'; //set radio button value
								}
							},error => {
								this.loadingService.hideLoading()
								this.alertService.clear()
								this.alertService.error(error.statusDescription)
							});
					}
				},
				error => {
					this.loadingService.hideLoading()
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
	

		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id, "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('OnshoreTurnoverNotice')
					.subscribe(data => {
						this.WorkflowTaskList = data.payload
						this.selectedAssignee = []
						if (data.payload) {
							this.WorkflowTaskList.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})
	}

	private startWorkflow(params: any) {
		this.resetWorkflowValues()
		this.selectedItem = params.data
		this.assetsStr = ""
		for(var i = 0; i < this.selectedItem.assets.length; i++){
			if(i != this.selectedItem.assets.length){
				this.assetsStr += this.selectedItem.assets[i].id + ",";
			}else{
				this.assetsStr += this.selectedItem.assets[i].id;
			}
		}
		
		this.loadingService.showLoading(false, false, '', 0)

		for(var i = 0; i < this.rowData.length; i++){
			if (this.selectedItem.id == this.rowData[i].id){
				
				this.assetIrnCheckList = this.rowData[i].assetHasIrnList;
				if(this.assetIrnCheckList != undefined && this.assetIrnCheckList != null && this.assetIrnCheckList.length != 0){
					this.hasAssetWithNoIRN = true;
				}
				else{
					this.hasAssetWithNoIRN = false;
				}

				this.assetOpenPunchlistCheckList = this.rowData[i].assetHasOpenPlList;
				if(this.assetOpenPunchlistCheckList != undefined && this.assetOpenPunchlistCheckList != null && this.assetOpenPunchlistCheckList.length != 0){
					this.hasAssetWitOpenPunchlists = true;
				}
				else{
					this.hasAssetWitOpenPunchlists = false;
				}
			}
		}
		this.loadingService.hideLoading()
		this.incompleteModalRef = this.bsModalService.show(this.incompleteOTNModal, { class: 'modal-lg' });
		this.loadingService.hideLoading()
		// this.onshoreTurnoverNoticeService.isAssetsHasIRN(this.assetsStr)
		// 	.subscribe(data => {
		// 		if (data != undefined || data != null) {
		// 			this.assetIrnCheckList = data.payload;
		// 			for (let i = 0; i < this.assetIrnCheckList.length; i++) {
		// 			  if (!this.hasAssetWithNoIRN) {
		// 				// if there is a asset which has no IRN set hasAssetWithNoIRN to true
		// 				this.hasAssetWithNoIRN = !this.assetIrnCheckList[i].hasIRN;
		// 			  }
		// 			}
		// 		  }
				
		// 		this.onshoreTurnoverNoticeService.isAllPunchlistItemsClosedByCategoryAndAsset("A", this.assetsStr)
		// 			.subscribe(data2 => {
						
		// 				if (data2 != undefined || data2 != null) {
		// 					this.assetOpenPunchlistCheckList = data2.payload;
		// 					for (let i = 0; i < this.assetOpenPunchlistCheckList.length; i++) {
		// 					  if (!this.hasAssetWitOpenPunchlists) {
		// 						// if there is a asset which has not closed punchlists set hasAssetWithNotClosedPunchlists to true
		// 						this.hasAssetWitOpenPunchlists = this.assetOpenPunchlistCheckList[i].hasOpenPunchlists;
		// 					  }
		// 					}
		// 				  }
						
		// 				this.loadingService.hideLoading()
		// 				if (this.hasAssetWithNoIRN || this.hasAssetWitOpenPunchlists) {
		// 					this.incompleteModalRef = this.bsModalService.show(this.incompleteOTNModal, { class: 'modal-lg' });
		// 				}
		// 				else {
		// 					this.continue()
		// 				}
		// 			},
		// 			error => {
		// 				this.loadingService.hideLoading()
		// 				this.alertService.clear()
		// 				this.alertService.error(error.statusDescription)
		// 			})
		// 	},
		// 	error => {
		// 		this.loadingService.hideLoading()
		// 		this.alertService.clear()
		// 		this.alertService.error(error.statusDescription)
		// 	})
	}

	cancelWorkflow(): void {
		this.incompleteModalRef.hide()
		this.eventType = this.widgetEvent.SHOW_TABLE
		this.hasAssetWithNoIRN = false;
		this.hasAssetWitOpenPunchlists = false;
	}

	/**
	 * get values according to the selected radio button for items 1, 2 and 3
	 * @param itemSelected 
	 */
	getItemGroupValue(itemSelected: string) : string{
		var displayValue = "";
		switch(itemSelected){
			case 'allIRNCompleteY':
				displayValue = "Y"
				break;
			case 'allIRNCompleteN':
				displayValue = "N"
				break;
			case 'allIRNCompleteNA':
				displayValue = "NA"
				break;
			case 'finalVisualInspectionCompleteY':
				displayValue = "Y"
				break;
			case 'finalVisualInspectionCompleteN':
				displayValue = "N"
				break;
			case 'finalVisualInspectionCompleteNA':
				displayValue = "NA"
				break;
			case 'handoverCertificateIssuedY':
				displayValue = "Y"
				break;
			case 'handoverCertificateIssuedN':
				displayValue = "N"
				break;
			case 'handoverCertificateIssuedNA':
				displayValue = "NA"
				break;
			case 'plIdentifiedCategorizedY':
				displayValue = "Y"
				break;
			case 'plIdentifiedCategorizedN':
				displayValue = "N"
				break;
			case 'plIdentifiedCategorizedNA':
				displayValue = "NA"
				break;
			case 'APunchlistClosedY':
				displayValue = "Y"
				break;
			case 'APunchlistClosedN':
				displayValue = "N"
				break;
			case 'APunchlistClosedNA':
				displayValue = "NA"
				break;
		}
		return displayValue;
	}

	private finishFunction() {

		this.isSubmitted = true;
		if(!this.validateAssigneeData()){
			return;

		}
		else{

			this.loadingService.showLoading(false, false, '', 0)
			var signerslist =  this.getSigngersList();
			
			if(this.radioSelected1 != ""){
				this.allIRNComplete = this.getItemGroupValue(this.radioSelected1)
			}

			if(this.radioSelected2 != ""){
				this.finalVisualInspectionComplete = this.getItemGroupValue(this.radioSelected2)
			}

			if(this.radioSelected3 != ""){
				this.handoverCertificateIssued = this.getItemGroupValue(this.radioSelected3)
			}
			
			if(this.radioSelected4 != ""){
				this.plIdentifiedCategorized = this.getItemGroupValue(this.radioSelected4)
			}

			if(this.radioSelected5 != ""){
				this.typeAPunchlistClosed = this.getItemGroupValue(this.radioSelected5)
			}

			var otnOject = {
				"otnId": this.selectedItem.id, "allIRNComplete": this.allIRNComplete, "finalVisualInspectionComplete": this.finalVisualInspectionComplete, "handoverCertificateIssued": this.handoverCertificateIssued, 
				"plIdentifiedCategorized": this.plIdentifiedCategorized, "APunchlistClosed": this.typeAPunchlistClosed, 
				"signersList": JSON.stringify(signerslist) , "doctype" : "OTN"
			}
			console.log(JSON.stringify(signerslist))
			//this.workflowId = Guid.create()
			this.onshoreTurnoverNoticeService.generateOTN(otnOject).subscribe(
				data => {
					this.loadingService.hideLoading()
					this.backToTable()
				},
				error => {
					this.loadingService.hideLoading()
					this.alertService.error(error.statusDescription);
				})
	}}

	validateAssigneeData(): boolean{
		this.validAssignee1 = true;
		this.validAssignee2= true
		this.validAssignee3= true
		this.validAssigneeEmail1 = true;
		this.validAssigneeEmail2= true
		this.validAssigneeEmail3= true


		let validation = true;

			if (this.assignee1 == null ||this.assignee1 == ''){
				this.validAssignee1 = false;
				validation = false;
				
			}
			
			if (this.assignee2 == null ||this.assignee2 == ''){
				this.validAssignee2 = false;
				validation = false;
			}
			
			if (this.assignee3 == null ||this.assignee3 == ''){
				this.validAssignee3 = false;
				validation = false;
			}

			if(this.assignee1 !='' && this.assignee1 != null ){
			if(!this.userList.find(user => user.id == this.assignee1)){
				if(!this.emailValidate(this.assignee1)){
					this.validAssigneeEmail1 = false;
					validation = false;

				}
			}
			}
			if(this.assignee2 !='' && this.assignee2 != null ){
			if(!this.userList.find(user => user.id == this.assignee2)){
				if(!this.emailValidate(this.assignee2)){
					this.validAssigneeEmail2 = false;
					validation = false;

				}
			}
			}
			if(this.assignee3 !='' && this.assignee3 != null ){
			if(!this.userList.find(user => user.id == this.assignee3)){
				if(!this.emailValidate(this.assignee3)){
					this.validAssigneeEmail3 = false;
					validation = false;

				}
			}
			}		

			return validation;

	}

	//generate signers list
	getSigngersList(){
		var signersIdList = [this.assignee1, this.assignee2, this.assignee3]
		var taskNameList = [this.taskname1, this.taskname2, this.taskname3]
		var signersList: any[] = [];
		var i= 1;
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.WorkflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'OTN' });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				//console.log("assigneeName : "+ assigneeName);
				signersList.push({'signerId': '' , 'signerName': assigneeName , 'signerEmail': assignee, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.WorkflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'OTN' });
				i++;
			}
		});
		return signersList;
	 }
	 
	 emailValidate(value: any) {
		let isValid = /^[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+(\.[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
		return isValid
	}

	isValidEmailAddress(value: any, i: any) {
		let result = true;
		if (i == 0) {
			if (this.assignee1) {
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
			} 
		} else if (i == 1) {
			if (this.assignee2) {
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
			}
		} else if (i == 2) {
			if (this.assignee3) {
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
			}
		}
		return result
	}

	isAssigneesAdded() {
		if (this.assignee1 && this.assignee2 && this.assignee3) {
			let result = true;
			if (!this.userList.find(user => user.id == this.assignee1)) {
				if (!this.emailValidate(this.assignee1)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee2)) {
				if (!this.emailValidate(this.assignee2)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee3)) {
				if (!this.emailValidate(this.assignee3)) {
					result = false;
				}
			}
			return result
		} else {
			return false
		}
	}

	outFocus(event: any, i: any) {
		console.log('[outFocus] (event) ' + JSON.stringify(event));

	}

	textChanged(event: any, i: any , taskname: any) {
		console.log('[textChanged] (event) ' + JSON.stringify(event));
		if (event) {
			if (i == 1) {
				this.assignee1 = event.value
				this.taskname1 = this.getTaskName(taskname);
			} else if (i == 2) {
				this.assignee2 = event.value
				this.taskname2 = this.getTaskName(taskname);
			} else if (i == 3) {
				this.assignee3 = event.value
				this.taskname3 = this.getTaskName(taskname);
			} 
		} else {
			if (i == 1) {
				this.assignee1 = null
				this.taskname1 = null
			} else if (i == 2) {
				this.assignee2 = null
				this.taskname2 = null
			} else if (i == 3) {
				this.assignee3 = null
				this.taskname3 = null
			} 
		}
	}

	getTaskName(taskName: any) {
		var correctTaskName = taskName.replace(/([a-z])([A-Z])/g, '$1 $2').replace('Endose', 'Endorse')
		return correctTaskName
	}

	public delete(params: any) {
		this.selectedItem = params.data
		this.deleteModalRef = this.bsModalService.show(this.deleteOTNModal, { class: 'modal-sm' })
	}

	revokeOTN(): void {
		this.deleteModalRef.hide();
		this.loadingService.showLoading(false, false, '', 0)
		this.onshoreTurnoverNoticeService.revokeOnshoreTurnoverNotice(this.selectedItem.otnId)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	cancel(): void {
		this.deleteModalRef.hide();
	}	

	onSuccess(data: WsResponse, serviceType: WsType) {
		if(serviceType == WsType.DELETE_REVOKE_OTN){
			this.loadingService.hideLoading();
		  if (data.payload.action == "delete") {
			this.alertService.success(this.otn_translated_data.DELETE_SUCCESS_MSG)
			this.onshoreTurnoverNoticeService.loadTableDataRequest();
		  }
		  else {
			this.alertService.success(this.otn_translated_data.REVOKE_SUCCESS_MSG)
			this.onshoreTurnoverNoticeService.loadTableDataRequest();
		  }
		  if(data.payload){
			let itemIndex = this.rowData.findIndex(item => item.otnId == data.payload.id);
			if (this.rowData[itemIndex]) {
			  if(this.rowData[itemIndex].otnStatus == "Issued" || this.rowData[itemIndex].otnStatus == "Error"
				  || this.rowData[itemIndex].otnStatus == "OnQueue" ){
  
				this.rowData[itemIndex].otnStatus = "NotIssued";
				this.rowData[itemIndex].approve= "approve:NotIssued"
				this.rowData[itemIndex].edit= "edit:NotIssued"
				this.rowData[itemIndex].delete= "delete:NotIssued"
				this.rowData[itemIndex].sentpdf= "sentpdf:NotIssued"
				this.rowData[itemIndex].returnedpdf= "returnedpdf:NotIssued:false"
  
			  }else if(this.rowData[itemIndex].otnStatus == "Approved"){
				
				this.rowData[itemIndex].otnStatus = "Issued";
				this.rowData[itemIndex].approve= "approve:Issued"
				this.rowData[itemIndex].edit= "edit:Issued"
				this.rowData[itemIndex].delete= "delete:Issued"
				this.rowData[itemIndex].sentpdf= "sentpdf:Issued"
				this.rowData[itemIndex].returnedpdf= "returnedpdf:Issued:false"
			  }
			  else if(this.rowData[itemIndex].otnStatus == "NotIssued") {
				this.rowData.splice(itemIndex, 1);
			  }
			  this.gridApi.setRowData(this.rowData);
			  this.generateOut = false;
			  if(this.rowData.length > 0 ){
				this.rowData.forEach((row)=>{
				  if(row.otnStatus == "NotIssued"){
					this.generateOut = true;
				  }
				})
			  }else{
				this.generateOut = false;
			  }
			}
		  }
	  }
  
	}
  
	onFail(data: WsResponse, serviceType: WsType) {
	  if(serviceType == WsType.DELETE_REVOKE_OTN){
		this.loadingService.hideLoading();
		if (data.payload.action == "delete") {
		  this.alertService.error(this.otn_translated_data.DELETE_FAIL_MSG);
		}
		else {
		  this.alertService.error(this.otn_translated_data.REVOKE_FAIL_MSG);
		}
	  }
	}

	//refresh widget
	public refresh() {
		console.log('[OnshoreTurnoverNoticeWidgetComponent] (refresh) ');
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.showOTNDetail = false
		this.iconClicked = false
		this.resetWorkflowValues()
		this.hasAssetWithNoIRN = false;
		this.hasAssetWitOpenPunchlists = false;
		this.showRequiredErr = false
		this.gridApi.showLoadingOverlay()
		this.onshoreTurnoverNoticeService.getAllOnshoreTurnoverNotice()
			.subscribe(data => {
				if (data.payload) {
					data.payload.forEach(element => {
						if (element.targetCompletionDate) {
							element.newtargetCompletionDate = new Date(element.targetCompletionDate).toLocaleDateString()
						}
						element.isStart = false
						if (element.otnstatus == "NotIssued") {
							element.isStart = true
						}
						if(element.docuSignStatus != null){
							element.docuSignStatus = element.docuSignStatus.toLowerCase()
						}
					});
					//this.rowData = data.payload;
					var rowData = [];
					
					var payload = data.payload;
					for (let i = 0; i < payload.length; i++) {
						//= payload[i]["targetCompletionDate"] != null ? new Date(payload[i]["targetCompletionDate"]).toLocaleDateString() : null;
						let targetCompletionDate  = payload[i]["targetCompletionDate"] != null ? moment.utc(payload[i]["targetCompletionDate"]).format("M/D/YYYY") : null;
						//get asset list text by the asset list
						let assetsString = "";
						if(payload[i]["assetList"] != null){
							for(let j = 0; j < payload[i]["assetList"].length; j++){
								assetsString += payload[i]["assetList"][j].name
								if(j != payload[i]["assetList"].length - 1){
									assetsString += ', '
								}
							}
						}

						rowData.push({
						  id: payload[i]["id"],
						  assets: payload[i]["assetList"],
						  assetsText: assetsString, 
						  otnstatus: payload[i]["otnstatus"],
						  newtargetCompletionDate: targetCompletionDate,
						  vendor: payload[i]["vendorName"],
						  approve: "approve:" + payload[i]["otnstatus"],
						  edit: "edit:" + payload[i]["otnstatus"],
						  delete: "delete:" + payload[i]["otnstatus"],
						  sentpdf: "sentpdf:" + payload[i]["otnstatus"],
						  returnedpdf: "returnedpdf:" + payload[i]["otnstatus"] + ":" + payload[i]["isUploaded"],
						  docuSignStatus: payload[i]["docuSignStatus"],
						  docuSignId: payload[i]["docuSignId"],
						  assetHasIrnList: payload[i]["assetIdHasIrnList"],
						  assetHasOpenPlList: payload[i]["assetIdHasOpenPlList"],
						  errorMessage: payload[i]["outputErrorMessage"] != null ? payload[i]["outputErrorMessage"] : payload[i]["docusignFailureReason"] != null ?  payload[i]["docusignFailureReason"] : ''
						});
					  }
					  this.rowData = rowData;
					console.log('[getAllOnshoreTurnoverNotice] (data) ' + JSON.stringify(this.rowData))
				} else {
					this.rowData = []
				}
			},
			error => {
				console.log('[getAllOnshoreTurnoverNotice] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription);
			})
	}

	exportToExcel() {
		var params = {
			fileName: "onshore_turnover_notice.xlsx",
			sheetName: "onshore_turnover-_notice",
			suppressTextAsCDATA: true,
			columnKeys: ['assetsText', 'otnstatus', 'newtargetCompletionDate', 'vendor', 'workflow']
		};
		if (this.gridApi) {
			var xlsContent = this.gridApi.getDataAsExcel(params)
			var temp = XLSX.read(xlsContent, { type: 'binary' });
			var xlsxContent = XLSX.write(temp, { bookType: 'xlsx', type: 'base64' });

			this.download(params, xlsxContent);
		}
	}

	download(params, content) {
		var fileNamePresent = params && params.fileName && params.fileName.length !== 0;
		var fileName = fileNamePresent ? params.fileName : "noWarning.xlsx";
		var blobObject = this.b64toBlob(content, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blobObject, fileName);
		} else {
			var downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blobObject);
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	}

	b64toBlob(b64Data, contentType) {
		var sliceSize = 512;
		var byteCharacters = atob(b64Data);
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	/**
	 * Reset workflow related variables
	 */
	resetWorkflowValues(){
		this.selectedAssignee = []
		this.assignee1 = null
		this.assignee2 = null
		this.assignee3 = null
		this.taskname1 = null
		this.taskname2 = null
		this.taskname3 = null
		this.allIRNCompleteY = ""
		this.allIRNCompleteN = ""
		this.allIRNCompleteNA = ""
		this.finalVisualInspectionCompleteY = ""
		this.finalVisualInspectionCompleteN = ""
		this.finalVisualInspectionCompleteNA = ""
		this.handoverCertificateIssuedY = ""
		this.handoverCertificateIssuedN = ""
		this.handoverCertificateIssuedNA = ""
		this.plIdentifiedCategorizedY = ""
		this.plIdentifiedCategorizedN = ""
		this.plIdentifiedCategorizedNA = ""
		this.APunchlistClosedY = ""
		this.APunchlistClosedN = ""
		this.APunchlistClosedNA = ""
		this.allIRNComplete = "";
		this.finalVisualInspectionComplete = "";
		this.handoverCertificateIssued = "";
		this.plIdentifiedCategorized = "";
		this.typeAPunchlistClosed = "";
		this.radioSelected1 = "";
		this.radioSelected2 = "";
		this.radioSelected3 = "";
		this.radioSelected4 = "";
		this.radioSelected5 = "";
		this.selectedItem = null
		this.WorkflowTaskList = []
		this.assetIrnCheckList = []
		this.assetOpenPunchlistCheckList = []
	}

	updateUIAfterGenerateOutPut(){
		this.generateOut = false;
		if(this.rowData.length > 0 ){
		  this.rowData.forEach((row)=>{
			if(row.otnStatus == "NotIssued" ){
			  row.otnStatus = "OnQueue";
			  row.approve= "approve:OnQueue"
			  row.edit= "edit:OnQueue"
			  row.delete= "delete:OnQueue"
			  row.sentpdf= "sentpdf:OnQueue"
			  row.returnedpdf= "returnedpdf:OnQueue:false"
			}
		  })
		}
		this.gridApi.setRowData(this.rowData);
	  }
	
	  updateUIAfterApprove(otnId, uploaded){
		let itemIndex = this.rowData.findIndex(item => item.otnId == otnId);
		if (this.rowData[itemIndex]) {
		  this.rowData[itemIndex].otnStatus = "Approved";
		  this.rowData[itemIndex].approve= "approve:Approved"
		  this.rowData[itemIndex].edit= "edit:Approved"
		  this.rowData[itemIndex].delete= "delete:Approved"
		  this.rowData[itemIndex].sentpdf= "sentpdf:Approved"
		  this.rowData[itemIndex].returnedpdf= "returnedpdf:Approved:"+uploaded
		  this.gridApi.setRowData(this.rowData);
		  this.generateOut = false;
		  if(this.rowData.length > 0 ){
			this.rowData.forEach((row)=>{
			  if(row.otnStatus == "NotIssued"){
				this.generateOut = true;
			  }
			})
		  }
		}
	  }

	  public previous(){
		this.isSubmitted = false;
	}
}
