<div class="modal-header py-2">
    <h5 *ngIf="!edit" class="modal-title">
        {{ 'OTN_ADD_EDIT.ADD_OTN' | translate }}
    </h5>
    <h5 *ngIf="edit" class="modal-title">
        {{ 'OTN_ADD_EDIT.EDIT_OTN' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" id="otnaddedit">
    <form>
        <fieldset [disabled]="disableform">
            <div class="form-group mb-2">
                <div class="mt-2 dropdown-100 mb-2">
                    <div class="form-group">
                        <label for="addotn_assets" class="mb-1">{{ 'OTN_ADD_EDIT.OTN_ASSETS' | translate
                            }}</label>
                        <div class="badge-required mx-2"></div>
                        <tag-input name="addotn_assets" id="addotn_assets" [(ngModel)]='selectedAssetList'
                            [disable]="disableAssets || disableform" [removable]='true'
                            secondaryPlaceholder="{{'OTN_ADD_EDIT.SELECT_OTN_ASSETS' | translate}}"
                            placeholder="{{'OTN_ADD_EDIT.SELECT_OTN_ASSETS' | translate}}" [onlyFromAutocomplete]="true"
                            (onAdd)="selectItemFromSuggestions($event, 'asset')" (onRemove)="removeSelectedAsset($event, 'asset')" [clearOnBlur]="true"
                            [displayBy]="'text'" [identifyBy]="'id'">
                            <tag-input-dropdown [showDropdownIfEmpty]="true" [keepOpen]="false"  [autocompleteItems]="assetList"
                                [displayBy]="'text'" [identifyBy]="'id'" [limitItemsTo]=20 [appendToBody]="false">
                            </tag-input-dropdown>

                        </tag-input>
                    </div>
                </div>
                <div class="mt-4 dropdown-100 ">
                    <div class="form-group">
                        <label class="">{{ 'OTN_ADD_EDIT.OTN_VENDOR' | translate }}
                        </label>
                        <div class="badge-required mx-2"></div>
                        <ng-select #vendor [active]="selectedVendor" [allowClear]="true" [items]="vendorList" [disabled]="disableVendor || disableform"
					        (selected)="selectedVendorId($event)" (removed)="removedVendor($event)"
					        placeholder="{{ 'OTN_ADD_EDIT.SELECT_OTN_VENDOR' | translate }}" name="vendor" id="vendorId">
				        </ng-select>
                    </div>
                </div>
                <div class="mt-4 dropdown-100 ">
                    <div class="form-group">
                        <label class="">{{ 'OTN_ADD_EDIT.OTN_STATUS' | translate }}
                        </label>
                        <div class="badge-required mx-2"></div>
                        <input *ngIf="otnStatus!=null" type="text" class="form-control form-control-sm" id="otnStatus"
                            name="otnStatus" [value]="otnStatus" disabled>
                    </div>
                </div>
                <!-- <div class="mt-4 dropdown-100 mb-2">
                    <div class="form-group">
                        <label class="">{{ 'OTN_ADD_EDIT.OTN_TARGET_COMPLETION_DATE' | translate }}
                        </label>
                        <div class="badge-required mx-2"></div>
                        <input type="text"
                            placeholder="{{ 'OTN_ADD_EDIT.SELECT_OTN_TARGET_COMPLETION_DATE' | translate }}"
                            class="form-control" name="target_completion_date" [(ngModel)]="targetCompletionDate"
                            [bsConfig]="bsConfig" placement="top" bsDatepicker>
                    </div>
                </div> -->
            </div>
        </fieldset>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="!edit && ! disableform" type="submit" id="otn_save" class="btn btn-primary btn-sm float-left"
        (click)="save()" [disabled]="disableSaveButton">{{ 'OTN_ADD_EDIT.SAVE' | translate}}</button>
    <button type="button" id="otn_cancel" class="btn btn-secondary btn-sm float-left" 
        (click)="cancel()">{{ 'OTN_ADD_EDIT.CANCEL'| translate}}</button>
    <button *ngIf="edit && ! disableform" type="submit" id="otn_save" class="btn btn-primary btn-sm float-left"
        (click)="save()" [disabled]="disableSaveButton">{{ 'OTN_ADD_EDIT.UPDATE' | translate}}</button>
</div>