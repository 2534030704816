<alert class="floating-alert"></alert>
<div class="navbar navbar-light">
	<div class="navbar-brand">
		<span *ngIf="showSetDefault" class="slide-bar-span d-inline-block align-top mr-2" (click)="showSlideBar()">
			<i class="fas fa-bars fa-fw slide-bar-i" aria-hidden="true"></i>
		</span>
		<img src="data:image/jpeg;base64,{{base64Data}}" width="auto" height="30" class="d-inline-block align-top ml-1 mr-1" alt="">
		<div class="d-inline-block align-top vertical-line ml-1 mr-1"></div>
		<h5 class="d-inline-block align-top ml-1 mr-1 project-name">{{projectName}}</h5>
	</div>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
	 aria-expanded="false" aria-label="Toggle navigation" (click)="showToggleMenu()">
		<i class="fas fa-ellipsis-v"></i>
	</button>
	<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'toggle-border-top': isToggleMenu}">
		<ul class="navbar-nav mr-auto">
		</ul>
		<div class="form-inline my-2 my-lg-0 float-right">
			<div class="my-2 my-sm-0 mr-1 ml-2">
				<span class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0" (click)="showNotification(); isOpen = !isOpen"
				[popover]="notificationContent" [isOpen]="isOpen" containerClass="notification-custom"
					 placement="bottom" #notificationPO="bs-popover" container="body" [outsideClick]="true" triggers="">
					<!-- animated swing [ngClass]="{'infinite': notificationCount > 0}" -->
					<i class="fal fa-bell fa-lg" aria-hidden="true" ></i>
					<span class="badge badge-danger badge-pill badge-notify animated faster" [ngClass]="{'fadeIn': notificationCount>0, 'hidden': notificationCount==0 }">{{notificationCount}}</span>
				</span>
			</div>
			<button class="btn btn-outline-dark btn-sm btn-circle my-2 my-sm-0 mr-1 ml-2" (click)="userSettingsPO.toggle()">
				<span [popover]="userSettingsContent" containerClass="user-setting-custom" placement="bottom" #userSettingsPO="bs-popover"
				 triggers="" container="body" [outsideClick]="true">{{firstLetter}}</span>
			</button>

		</div>
	</div>
</div>

<ng-template #notificationContent>
	<app-notification (messageEvent)="getNotificationCount()"></app-notification>
</ng-template>

<ng-template #userSettingsContent>
	<div class="card col-sm-12 rounded-0 p-0 border-top-0 border-left-0 border-right-0 mt-2 mb-1">
		<div class="card-body row p-0">
			<div class="float-left mx-3">
				<span class="btn btn-outline-dark btn-circle btn-sm float-left mt-1">{{firstLetter}}</span>
			</div>
			<div class="float-left col">
				<div class="row">
					<div class="col-sm-12 pl-0">
						<p class="float-left mb-0"> {{userFullName}}</p>
					</div>
					<div class="col-sm-12 pl-0" (click)="openModal(userProfilerTemplate, 'lg')">
						<small class="float-left mb-2 text-muted view-profile">View Profile</small>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="changeProject()">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-tasks" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'MENU.CHANGE' | translate }}</p>
		</div>
	</div>
	<div *ngIf="showSetDefault" class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="setasDefault(defaultBtnIcon)">
		<div class="float-left mx-2 text-muted">
			<i class="fas " aria-hidden="true" [ngClass]="{'fa-check-circle': defaultBtnIcon, 'fa-times-circle': !defaultBtnIcon}"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ setDefaultBtnText }}</p>
		</div>
	</div>
	<hr class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="signOutBtnClickEvent()">
		<div class="float-left mx-2 text-muted">
			<i class="fas fa-sign-out" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'MENU.LOGOUT' | translate }}</p>
		</div>
	</div>
	<hr class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'MENU.VERSION' | translate }} - {{appVersion}}</p>
		</div>
	</div>
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted small">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 small">{{ 'MENU.SERVERVERSION' | translate }} - {{serverVersion}}</p>
		</div>
	</div>
</ng-template>
<ng-template #userProfilerTemplate>
	<div class="modal-header">
		<h4 class="modal-title pull-left">{{ 'HEADER.VIEW_PROFILE' | translate}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<app-header-profile></app-header-profile>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" data-dismiss="modal" (click)="save()">{{
					'HEADER.SAVE' | translate}}</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">{{
					'HEADER.CANCEL' | translate}}</button>
	</div>
</ng-template>