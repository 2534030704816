import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//import { Chart } from 'angular-highcharts';
import { Chart, Highcharts } from 'angular-highcharts';

import { ChecksheetRundownWidgetService } from './mc-checksheet-rundown-widget.service';
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { SharedService } from '../../util/shared.service';
import { LocalStorage } from '../../util/localstorage.service';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-mc-checksheet-rundown-widget',
	templateUrl: './mc-checksheet-rundown-widget.component.html',
	styleUrls: ['./mc-checksheet-rundown-widget.component.css']
})
export class McChecksheetRundownWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	chart: Chart
	public systemList: Array<any> = []
	public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public today = new Date()
	public dateInt: number;
	public y_axis_text: string = ''
	public x_axis_text: string = ''

	private translatedData: any = null
	public isLoadingData: boolean = true
	public isNoDataToShow: boolean = false
	public showFilterOptions: boolean = false

	constructor(private checksheetRundownService: ChecksheetRundownWidgetService, private translate: TranslateService,
		private alertService: AlertService, private systemService: SystemService, private sharedService: SharedService) {
		// checksheetRundownService.setCallback(this)
		// systemService.setCallback(this)
		this.dateInt = this.today.setUTCHours(0, 0, 0, 0)

		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['MC_CHECKSHEET_RUNDOWN']
			this.initChart()
			this.getMCChecksheetData('ALL')
		})
	}

	ngOnInit() {
		//get system list
		this.systemService.getAllSystemList().subscribe(
			data => {
				this.systemList = []
				this.systemList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.systemList.push({ 'id': element.id, 'text': element.systemNo + ' - ' + element.systemName })
					});
				} else {
					// do nothing
				}
			},
			error => {
				console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			});

		// Highcharts.setOptions({   // This is for all plots, change Date axis to local timezone
		// 	global: {
		// 		getTimezoneOffset: function (timestamp) {
		// 			var chartTimezone = localStorage.getItem(LocalStorage.TIMEZONE);
		// 			var timezoneOffset = 300;
		// 			if (chartTimezone != null) {
		// 				timezoneOffset = -moment.tz(timestamp, chartTimezone.replace(/"/g, '')).utcOffset();
		// 			}
		// 			return timezoneOffset;
		// 		}
		// 	}
		// });

	}

	public initChart() {
		this.chart = new Chart({
			chart: {
				type: 'line',
				zoomType: 'x',
				resetZoomButton: {
					position: {
						align: 'left',
						verticalAlign: 'top',
						x: 0,
						y: -10
					}
				}
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			xAxis: {
				title: {
					text: this.translatedData.DATE
				},
				type: 'datetime',
				tickWidth: 1,
				labels: {
					enabled: true,
					rotation: -45,
					format: '{value:%Y-%b-%d}'
				},
				plotLines: [{
					color: '#757575', // Color value
					dashStyle: 'ShortDash', // Style of the plot line. Default to solid
					value: this.dateInt, // Value of where the line will appear
					width: 2, // Width of the line   
					label: {
						text: this.translatedData.TODAY,
						align: 'Center',
						y: 40
					}
				}]

			},
			yAxis: [{ // left y axis	
				gridLineWidth: 1,
				tickWidth: 1,
				minTickInterval: 1,
				title: {
					text: this.translatedData.NUM_OF_CHECKSHEETS
				}
			}],
			plotOptions: {
				line: {
					marker: {
						enabled: true
					}
				}
			},
			tooltip: {
				crosshairs: true,
				shared: true,
				xDateFormat: '%Y-%b-%d'
			},
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					}
				}
			}

		});
	}

	public addSeriesToChart(planSeries: any, remainSeries: any) {
		this.chart.addSerie({
			name: this.translatedData.PLAN_A_CHECKSHEETS,
			color: '#1E88E5',
			marker: {
				symbol: 'dot'
			},
			lineWidth: 2,
			data: planSeries,
		})
		this.chart.addSerie({
			name: this.translatedData.REMAINING_A_CHECKSHEETS,
			color: '#E53935',
			marker: {
				symbol: 'dot'
			},
			lineWidth: 2,
			data: remainSeries
		})
		this.isLoadingData = false
	}

	public removedSystem(value: any): void {
		// console.log('[removedSystem] value ' + JSON.stringify(value))
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.removeSerie()
		this.getMCChecksheetData(this.selectedSystem[0].id)
	}

	public selectedSystemId(value: any): void {
		// console.log('[selectedSystemId] value ' + JSON.stringify(value));
		this.selectedSystem.pop()
		this.selectedSystem.push(value)
		this.removeSerie()
		this.getMCChecksheetData(value.id)
	}

	public removeSerie() {
		if (this.chart.ref.series.length != null) {
			let num = this.chart.ref.series.length
			// console.log('[removeSerie] num ' + JSON.stringify(num));
			for (let i = 0; i < num; i++) {
				this.chart.removeSerie(0);
			}
		}
	}

	/**
	 * get mc checksheet data
	 * @param systemId 
	 */
	public getMCChecksheetData(systemId: string) {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.checksheetRundownService.getmcchecksheetData(systemId).subscribe(
			data => {
				// console.log('[ngOnInit] (data) ' + JSON.stringify(data));
				let checksheetPlanData: any = []
				let checksheetRemainData: any = []

				if (data.payload != null) {
					if (data.payload.checksheetRemain.length != 0) {
						data.payload.checksheetRemain.forEach(element => {
							let date = new Date(element.date).getTime()
							checksheetRemainData.push([date, element.checksheetRundown])
						});
					} else {
						// do nothing no remaining items
					}
					if (data.payload.checksheetPlan.length != 0) {
						data.payload.checksheetPlan.forEach(element => {
							let date = new Date(element.date).getTime()
							checksheetPlanData.push([date, element.checksheetRundown])
						});
					} else {
						// do nothing no plan items
					}
					if (checksheetPlanData.length == 0 && checksheetRemainData.length == 0) {
						//do not have  data to show
						this.isLoadingData = false
						this.isNoDataToShow = true
					} else {
						this.removeSerie()
						this.addSeriesToChart(checksheetPlanData, checksheetRemainData)
					}
				} else {
					//do nothing
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			});
	}

	public refresh() {
		console.log('[MCChecksheetRundownWidgetComponent] (refresh)');
		this.initChart()
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.getMCChecksheetData('ALL')
	}

}
