import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    template: `<div *ngFor="let checkSheet of this.checkSheetList">
    <a [routerLink]="['/dashboard']" *ngIf="!show(checkSheet)">&nbsp;</a>
    <a [routerLink]="['/dashboard']" *ngIf="show(checkSheet)" (click)="download(checkSheet)">{{checkSheet.checkSheetCode}} </a>
    </div>`
})
export class CheckSheetRecievedFileDownloadRenderer implements ICellRendererAngularComp {    
    params: any;    
    checkSheetList: any[] = [];

    agInit(params: any): void {
        this.params = params;
        if(this.params.data){
            if(this.params.data.checkSheetList){
                this.checkSheetList = this.params.data.checkSheetList;
            } 
        } 
    }

    show(checkSheetData:any) {
		if(checkSheetData.approvedFileName != null && checkSheetData.approvedFileName != undefined){
			return true;
		}
		else{
			return false;
		}
    }

    refresh(params: any): boolean {
        return false;
    }    

    public download(checkSheet:any){
        this.params.context.componentParent.downloadCheckSheetApprovedFile(checkSheet);
    }
}