export class WsType {
	// class variables
	public static GET_ALL_IRNS = 1;

	public static LOGIN = 2;
	public static SESSION_VALIDATION = 3;
	public static OPEN_ID_LOGIN = 4;
	public static PASSWORD_RESET_URL = 5;
	public static PASSWORD_RESET_VALIDATE = 6;
	public static PASSWORD_RESET = 7;
	public static CHANGE_PASSWORD = 8;
	public static KEY_CODE_VERIFICATION = 9;
	public static GET_STNS_BY_VENDOR_ID = 10;
	public static GET_VENDOR_LIST = 11;
	public static GET_DEFAULT_WORKFLOW_LIST = 12;
	public static GET_ALL_ACTIVE_USERS = 13;
	public static UPDATE_STN_STATUS = 14;
	public static GET_STN_BY_ID = 15;
	public static GET_SYSTEM_BY_ID = 16;
	public static ADD_STN = 17;
	public static UPDATE_STN = 18;
	public static CHECK_PUNCHLISTS_COMPLETED = 19;
	public static CHECK_CHECKSHEETS_COMPLETED = 20;
	public static GET_OPEN_PUNCHLIST_ITEMS = 21;
	public static GET_STN_PTN_RUNDOWN_DATA = 22
	public static GET_SYSTEM_LIST = 23
	public static GENERATE_STN = 24
	public static ADD_WORKFLOW_INSTANCE_TASK_MAP = 25
	public static GENERATE_WORKFLOW = 26
	//new code
	public static ADD_UPDATE_RECENT_PROJECT = 27;
	public static GET_ALL_RECENT_PROJECTS = 28;
	public static GET_DEFAULT_RECENT_PROJECT = 29;
	public static SET_DEFAULT_RECENT_PROJECT = 30;
	public static DELETE_USER_RECENT_PROJECT = 31;
	public static DELETE_USER_DEFAULT_RECENT_PROJECT = 32;
	public static LANDING = 33;
	public static GET_ALL_CONTROLLED_DOCUMENTS = 34;
	public static CONTROLLED_DOCUMENTS = 35;
	public static TAG_COUNTS = 36;
	public static GET_ALL_TAG_COUNTS = 37;
   public static GET_ALL_SHS = 38;
   public static GET_ALL_FAS = 39;
	public static GET_PRECOMMISSIONING_SYSTEMS = 40;
	public static SYSTEM_SKYLINE = 41;
	public static GET_MCCHECKSHEET_RUNDOWN_DATA = 38;
   public static GET_ALL_OWI = 39;
   public static GET_GOOGLE_KEYS = 40;

   public static UPDATE_SYSTEM_COMPLETION_STATUS = 41;
   public static GET_AUTH0_CONFIGURATIONS = 42;

   public static ADD_NEW_PUNCHLIST = 43;
   public static GET_PUNCHLIST_DATA_TO_IRN = 44;
   public static ADD_NEW_PUNCHLIST_DOCUMENT = 45;
   public static GET_ASSETS = 46;
   public static GET_SYSTEMS_BY_PROJECT_ID = 47;
   public static GET_DISCIPLINES = 48;

   public static GENERATE_OTN = 49;
   public static GENERATE_PUNCHLIST = 50;
   public static ADD_NEW_PUNCHLIST_IMAGE = 51;
   public static GET_ALL_VENDORS = 52;
   public static GET_OTN_BY_ASSET_ID = 53;
   public static ADD_NEW_OTN = 54;
   public static GET_MCC_DATA_BY_OTN_ID = 55;
   public static DELETE_REVOKE_OTN = 56;
   public static GET_OTN_BY_OTN_ID = 57;
   public static UPDATE_OTN = 58;

   public static ITEM_DEPENDENCY_LIST = 59;
   public static ITEM_DISABLE_ENABLE = 60;
   public static GET_PUNCHLIST_BY_PUNCHLIST_ID = 61;
   public static GET_PUNCH_CODES = 62;
   public static GET_IRN_BY_IRN_ID = 63;
   public static UPDATE_PUNCHLIST = 64;
   public static REVOKE_PUNCHLIST = 65;
   public static DELETE_ITEM = 66;
   public static UPDATE_PUNCHLIST_CLCOMMENT = 67;
};
