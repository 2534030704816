import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { InspectionReleaseNoticeService } from './inspection-release-notice.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserVariable } from '../../util/common/user-variable';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import { PunchlistDetailsRenderer } from './cell-renderer/punchlist-details.component';
import { PLDocumentDownloadRenderer } from './cell-renderer/punchlist-document.component';
import { GridOptions } from "ag-grid";
import { PunchlistService } from '../punchlist.service';
import { DecodeHtmlString } from '../../util/common/decode-html-string.pipe';
import { CustomDateComponent } from 'src/app/custom-date-component.component';
import * as moment from 'moment';

@Component({
	selector: 'app-inspection-release-notice',
	templateUrl: './inspection-release-notice.component.html',
	styleUrls: ['./inspection-release-notice.component.css']
})
export class InspectionReleaseNoticeComponent implements OnInit {

	public isMinimized: boolean = false
	public isMinimizedPunchlist: boolean = false
	public isHidden: boolean = true
	rowData: any[] = [];
	private gridApi;
	private gridColumnApi;
	columnDefs;
	irn_translated_data: any = null;
	public columnDefsPunchlist;
	public gridApiPunchlist
	public gridColumnApiPunchlist;
	public gridOptions: GridOptions;
	public gridOptionsPunchlist: GridOptions;
	public rowDataPunchlist: any;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };

	constructor(private inspectionReleaseNoticeService: InspectionReleaseNoticeService, private sharedService: SharedService, 
		private punchlistService: PunchlistService, private translate: TranslateService, private alertService: AlertService, private loadingService: LoadingService,
		private decodeHTMLString: DecodeHtmlString) {
		let browserLang = translate.getBrowserLang();
		this.gridOptions = <GridOptions>{
			context: {
			  componentParent: this
			}
		  };
		this.gridOptionsPunchlist = <GridOptions>{
			context: {
			  componentParent: this
			}
		  };
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.irn_translated_data = res['IRN_REPORT']
			this.columnDefs = [
				{ headerName: this.irn_translated_data.IRN_NUMBER, field: 'irnNumber', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, },
				{ headerName: this.irn_translated_data.IRN_DESCRIPTION, field: 'description', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, },
				{ headerName: this.irn_translated_data.ASSET, field: 'assetNames', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, },
				{ headerName: this.irn_translated_data.IRN_STATUS, field: 'irnstatus', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, },
				/* {
					headerName: this.irn_translated_data.IRN_TARGET_COMPLETION_DATE, field: 'targetCompletionDate', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						}
					}
				}, */
				{ headerName: this.irn_translated_data.VENDOR, field: 'vendorName', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, },
				{
					headerName: this.irn_translated_data.DOWNLOAD, field: 'isUploaded', suppressFilter: true, autoHeight: true, minWidth: 100, cellClass: "cell-wrap-text",
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.value) {
							eDiv.innerHTML = '<a class="btn btn-sm edit"><i class="fas fa-file-pdf" aria-hidden="true"></i>';
							var eButton = eDiv.querySelectorAll('.btn-sm')[0];

							eButton.addEventListener('click', function () {
								loadingService.showLoading(false, false, '', 0)
								inspectionReleaseNoticeService.downloadFile(UserVariable.projectId, params.data.irnId, params.data.irnNumber)
									.subscribe(response => {
										loadingService.hideLoading()
										let file = new Blob([response], { type: 'application/pdf' });
										FileSaver.saveAs(file, "IRN_" + params.data.irnNumber + ".pdf");
									},
									error => {
										loadingService.hideLoading()
										alertService.error(this.irn_translated_data.ERR_DOWNLOAD_FILE)
									});
							});
						} else {
							eDiv.innerHTML = '';
						}

						return eDiv;
					}
				},
				{
					headerName: this.irn_translated_data.PUNCHLISTS,
					marryChildren: true,
					children: [
					  {
						headerName: "A",
						field: "punchlistCountA",
						cellRendererFramework: PunchlistDetailsRenderer,
						suppressFilter: true,
						suppressMenu: true,
						maxWidth:50
					  },
					  {
						headerName: "B",
						field: "punchlistCountB",
						cellRendererFramework: PunchlistDetailsRenderer,
						suppressFilter: true,
						suppressMenu: true,
						maxWidth:50
					  },
					  {
						headerName: "C",
						field: "punchlistCountC",
						cellRendererFramework: PunchlistDetailsRenderer,
						suppressFilter: true,
						suppressMenu: true,
						maxWidth:50
					  },
					  {
						headerName: "D",
						field: "punchlistCountD",
						cellRendererFramework: PunchlistDetailsRenderer,
						suppressFilter: true,
						suppressMenu: true,
						maxWidth:50
					  }
					]
				  }
			];
		});

		this.columnDefsPunchlist = [
			{
			  headerName: "Category",
			  field: 'category',
			  autoHeight: true, 
			  cellClass: "cell-wrap-text",  
			  minWidth: 100,
			},
			{
			  headerName: "Asset",
			  field: 'assetName',
			  autoHeight: true, 
			  cellClass: "cell-wrap-text",  
			  minWidth: 100,
			},
			{
			  headerName: "Description",
			  field: 'plDescription',
			  autoHeight: true, 
			  cellClass: "cell-wrap-text",  
			  minWidth: 300,
			},
			/* {
			  headerName: "Completion Date",
			  field: 'completionDate',
			  autoHeight: true, 
			  cellClass: "cell-wrap-text",  
			  minWidth: 150,
			}, */
			{
			  headerName: "System",
			  field: 'subSystemName',
			  autoHeight: true, 
			  cellClass: "cell-wrap-text",  
			  minWidth: 300,
			},
			{
			  headerName: "Documents",
			  field: 'documents',
			  autoHeight: true,
			  cellClass: "cell-wrap-text",
			  cellStyle: {'white-space': 'normal' },
			  minWidth: 200,
			  cellRendererFramework: PLDocumentDownloadRenderer
			}
		];
	
		this.gridOptionsPunchlist = {
			rowData: this.rowDataPunchlist,
			columnDefs: this.columnDefsPunchlist,
			enableColResize: true,
			enableSorting: true,
			rowSelection: "single",
			rowDeselection: true,
			rowMultiSelectWithClick: false,
			animateRows: true,
			rowBuffer: 20,
			context: {
			  componentParent: this
			},
		};

		inspectionReleaseNoticeService.setCallback(this);
	}

	ngOnInit() {

	}

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.GET_ALL_IRNS) {
			//this.rowData = data.payload;
			this.processRowData(data);
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.GET_ALL_IRNS) {
			this.alertService.error(res.statusDescription)
		}
	}

	processRowData(data: any){
		var rowData = [];
			
		var payload = data.payload;
		for (let i = 0; i < payload.length; i++) {
			let targetCompletionDate = payload[i]["targetCompletionDate"] != null ? moment.utc(payload[i]["targetCompletionDate"]).format("M/D/YYYY") : null;
			//get asset list text by the asset list
			let assetsString = "";
			if(payload[i]["assetList"] != null){
			for(let j = 0; j < payload[i]["assetList"].length; j++){
				assetsString += payload[i]["assetList"][j].name
				if(j != payload[i]["assetList"].length - 1){
				assetsString += ', '
				}
			}
		}

		rowData.push({
			irnId: payload[i]["irnId"],
			irnNumber: payload[i]["irnNumber"],
			description: payload[i]["description"],
			vendorId: payload[i]["vendorId"],
			assetId: payload[i]["assetId"],
			systemId: payload[i]["systemId"],
			vendorName: payload[i]["vendorName"],
			assetName: payload[i]["assetName"],
			assets: payload[i]["assets"],
			punchlists: payload[i]["punchlists"],
			assetIds: payload[i]["assetIds"],
			isUploaded: payload[i]["isUploaded"],
			assetNames: payload[i]["assetNames"],
			targetCompletionDate: targetCompletionDate,
			approvedDate: payload[i]["approvedDate"],
			referenceCount: payload[i]["referenceCount"],
			dependencyCount: payload[i]["dependencyCount"],
			punchlistCount: payload[i]["punchlistCount"],
			projectName: payload[i]["projectName"],
			clientName: payload[i]["clientName"],
			punchlistCountA: payload[i]["punchlistCountA"],
			punchlistCountB: payload[i]["punchlistCountB"],
			punchlistCountC: payload[i]["punchlistCountC"],
			punchlistCountD: payload[i]["punchlistCountD"],
			irnstatus: payload[i]["irnstatus"]
			});
		}
		this.rowData = rowData;
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}
	
	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()
		params.api.sizeColumnsToFit();
		this.refresh()
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.isHidden = true;
		this.inspectionReleaseNoticeService.getAllIRNs();
	}

	/**
	 * Set punchlist details to the grid
	 * @param payload 
	 */
	loadDataPunchlist(payload: any) {
		this.rowDataPunchlist = [];
		this.gridApiPunchlist.hideOverlay()
		this.gridApiPunchlist.showLoadingOverlay()
		for (let i = 0; i < payload.length; i++) {
		  let completionDate = ''
		  let dateClosed = ''
		  let dateRaised = ''
		 
		  if (payload[i]["estimatedCompletionDate"]) {
			completionDate = new Date(payload[i]["estimatedCompletionDate"]).toLocaleDateString()
		  }
		  if (payload[i]["dateClosed"]) {
			dateClosed = new Date(payload[i]["dateClosed"]).toLocaleDateString()
		  }
		  if (payload[i]["dateRaised"]) {
			dateRaised = new Date(payload[i]["dateRaised"]).toLocaleDateString()
		  }
		  
			this.rowDataPunchlist.push({
				category: payload[i]["category"] != null ? payload[i]["category"] : '',
				assetName: payload[i]["assetName"] != null ? this.decodeHTMLString.transform(payload[i]["assetName"]) : '',
				plDescription: payload[i]["punchlistDescription"] != null ? this.decodeHTMLString.transform(payload[i]["punchlistDescription"]) : '',
				completionDate: completionDate,
				subSystemName: payload[i]["subSystemName"] != null ? this.decodeHTMLString.transform(payload[i]["subSystemName"]) : '',
				documents: payload[i]["punchlistDocuments"]
			});
		}
	  }
	
	/**
	 * Get punchlist details by IRN Id and punchlist category
	 * @param params 
	 */
	public viewPunchlistGrid(params){
		console.log(params)
		if(params.data != null && params.data != undefined){
		  let irnId = params.data.irnId;
		  let category = params.colDef.headerName;
		  this.rowDataPunchlist = [];
	
		  this.punchlistService.getPunchlistDetailsByIRN(irnId, category).subscribe(
			data => {
			  console.log(JSON.stringify(data.payload));
			  this.loadDataPunchlist(data.payload);
			  this.loadingService.hideLoading();
			},
			error => {
			  this.gridApiPunchlist.hideOverlay()
			  this.alertService.clear()
			  this.alertService.error(error.statusDescription)
			});
			this.isHidden = false;
		}else{
			this.isHidden = true;
		}    
	  }

	  /**
	   * Download punchlist document
	   * @param doc 
	   */
	  downloadDocument(doc:any) {
		let fileExtention = doc.contentType;
		let outputId = doc.id;
		let outputFile = null;
		let punchlistId = doc.punchlistId;
		let projectId = doc.projectId;
		let fileName = doc.fileName;
	  
		this.loadingService.showLoading(false, false, '', 0);
		this.punchlistService.downloadFile(outputId, punchlistId, projectId, fileExtention, fileName)
		.subscribe(response => {
		  console
			  this.loadingService.hideLoading()
			  let file = new Blob([response], { type: 'application/pdf' });
			  //let file = new Blob([response], { type: response.type });
			  FileSaver.saveAs(file, fileName);
			},
			error => {
			  this.loadingService.hideLoading()
			  this.alertService.clear()
			  this.alertService.error("Could not download the file.");
			});
	  }
	  
	async onGridReadyPunchlist(params) {
		this.gridApiPunchlist = params.api;
		this.gridColumnApiPunchlist = params.columnApi;
		params.api.sizeColumnsToFit();
		setTimeout(() => {
		  params.api.sizeColumnsToFit();
		}, 500);
		window.addEventListener("resize", function () {
		  setTimeout(function () {
			params.api.sizeColumnsToFit();
		  });
		});
	  }

	  dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
