import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { StockChart } from 'angular-highcharts';
import * as Highcharts from 'highcharts/highstock';
import { IndividualSeriesOptions, TitleOptions } from 'highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GridOptions } from "ag-grid";
import * as FileSaver from 'file-saver';
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageConst } from '../../util/constants';
import { SharedService } from '../../util/shared.service';
import { MechanicalCompletionService } from '../mechanical-completion.service'
import { LoadingService } from '../../util/loading/loading.service';
import { CheckSheetService } from '../checksheet.service';
import { UserVariable } from '../../util/common/user-variable';
import { CheckSheetSentFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-sent-file-icon.component'
import { CheckSheetApprovedFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import * as exporting from 'highcharts/modules/exporting.src';import { timeout } from 'q';
 exporting(Highcharts);

@Component({
	selector: 'app-mc-system-skyline-widget',
	templateUrl: './mc-system-skyline-widget.component.html',
	styleUrls: ['./mc-system-skyline-widget.component.css']
})
export class McSystemSkylineWidgetComponent implements OnInit {

	public chart: Chart
	public gridOptions: GridOptions;

	public systemList: Array<any> = []
	public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public allSystemList: Array<any> = []
	public allSystemListWithEmptyWeeks: Array<any> = []
	private xAxisList: string[] = []
	private xAxisListWithEmptyWeeks: string[] = []
	private xAxisListWoEmptyWeeks: string[] = []
	private rowData: any[] = []

	@ViewChild('autoShownModal') autoShownModal: ModalDirective;

	private currentMenuItem: string = ''

	private yAxisMax: number = 0
	public plotLineValue: number = 0;
	public plotLineValueWithEmptyWeeks: number = 0; 
	public plotLineValueWoEmptyWeeks: number = 0; 

	public self: any
	private selectedPoint: any = null
	private columnDefs: any
	private gridApi
	private gridColumnApi
	private translatedData: any = null

	public isShowLabel: boolean = false
	public isModalShown: boolean = false;
	public isMinimized: boolean = false
	public isLoadingData: boolean = true
	public isNoDataToShow: boolean = false
	public showMCDetail: boolean = false
	public showFilterOptions: boolean = false
	public openCheckSheetsCount: any;
	public openPunchListCount: any;

	//variables used to support Dashboard - XOM Item changes - Mechanical Completion System Skyline
	highcharts = Highcharts;
	chartOptions: Highcharts.Options = { }; // required
	updateFlag: boolean = true; 
	oneToOneFlag: boolean = true; 
	//values for creating the stock chart
	private maxChartWidth: number = 1200;
	private maxChartWidthTablet: number = 600;
	private maxChartWidthMobile: number = 300;
	private maxRangeWidth: number = 100;
	private maxRangeWidthTablet: number = 50;
	private maxRangeWidthMobile: number = 25;
	private defaultPointWidth: number = 50;
	private defaultPointWidthTablet: number = 20;
	private defaultPointWidthMobile: number = 5;
	private defaultPointLabelLength: number = 6;
	private maxColumnWidthMobile: number = 100;
	private defaultPointRatio: number =  0;
	private maxColumnWidth: number =  0;
	private columnWidth: number = 0;
	private maxColumnCount: number = 12;
	private minValue: number;
	private maxValue: number;
	public isFocusOnTodayAxis: boolean = false;
	public isShowEmptyWeeks: boolean = false;
	private systemIdSelected: any = 'ALL';
	public isCondensedView: boolean = false;
	//to keep the device type
	private isMobile: boolean = false;
	private isTablet: boolean = false;
	private isDesktop: boolean = false;
	private MOBILE_MAX_WIDTH: number = 425;  
	private TABLET_MAX_WIDTH: number = 1024; 
	
	constructor(private systemService: SystemService, private translate: TranslateService,
		private alertService: AlertService, private sharedService: SharedService,
		private mechanicalCompletionService: MechanicalCompletionService,
		private loadingService: LoadingService,
		private checkSheetService: CheckSheetService) {
		this.self = this
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 5
		};


		let browserLang = translate.getBrowserLang()

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['MC_SYSTEM_SKYLINE']
			this.columnDefs = [
				{
					headerName: this.translatedData.CHECKSHEET_CODE,
					field: 'checkSheetCode',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.translatedData.CHECKSHEET_NAME,
					field: 'checkSheetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.translatedData.VENDOR,
					field: 'vendorName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100
				},
				{
					headerName: this.translatedData.SYSTEM_NAME,
					field: 'system',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.translatedData.ASSET,
					field: 'assetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.translatedData.STATUS,
					field: 'status',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100,
					cellRenderer: this.statusCellRenderer,
					suppressSorting: true
				},
				{
					headerName: this.translatedData.SENT_DOWNLOAD_ALL,
					field: 'initialFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100,
					suppressFilter: true,
					cellRendererFramework: CheckSheetSentFileIconRenderer,
					suppressSorting: true
				},
				{
					headerName: this.translatedData.APPROVED_DOWNLOAD_ALL,
					field: 'approvedFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100,
					suppressFilter: true,
					cellRendererFramework: CheckSheetApprovedFileIconRenderer,
					suppressSorting: true
				}
			]
			this.getDeviceType() //get device type(mobile/tablet/desktop)
			this.initStockChart() //initialize the stockchart
			this.getSkylineDataAndLoadGraphStockChart('ALL')
		})
	}

	ngOnInit() {
		//reinitialize the stockchart
		this.reInitializeStockChart()
		this.systemService.getParentSystem().subscribe(
			data => {
				this.systemList = []
				this.systemList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.systemList.push(
							{
								'id': element.systemId,
								'text': element.systemNo + ' - ' + element.systemName
							}
						)
					});
				} else {
					// do nothing
				}
			},
			error => {
				console.log('[getParentSystem] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)
	}

	/**
	 * initialize the highchart
	 * this chart is used to show a condensed view of all the systems
	 */
	public initChart() {
		this.chart = new Chart({
			chart: {
				type: 'column',
				zoomType: 'x'
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			loading: {
				labelStyle: {
					color: '#FAFAFA'
				},
				style: {
					backgroundColor: '#BDBDBD'
				}
			},
			xAxis: {
				title: {
					text: this.translatedData.X_AXIS
				},
				categories: this.xAxisList,
				crosshair: true,
				min: 0,
				max: (this.xAxisList.length - 1),
				plotLines: [{
					color: '#757575',
					dashStyle: 'ShortDash',
					value: this.plotLineValue,
					width: 2,
					label: {
						text: this.translatedData.TODAY,
						verticalAlign: 'top',
						textAlign: 'center',
						y: 40,
						x: -8,
						rotation: 270
					}
				}]
			},
			yAxis: [{ // left y axis	
				gridLineWidth: 1,
				tickWidth: 1,
				minTickInterval: 1,
				title: {
					text: this.translatedData.Y_AXIS
				},
				// max: this.yAxisMax,
				reversedStacks: false
			}],
			plotOptions: {
				column: {
					cursor: 'pointer',
					borderColor: '#9E9E9E',
					borderWidth: 0.5,
					stacking: 'normal',
					dataLabels: {
						enabled: this.isShowLabel,
						allowOverlap: true,
						rotation: -45,
						crop:false,
						overflow:'allow',
						style: {
							fontSize: '10',
							textOutline: '0px',
							color: '#212121'
						},
						formatter: function () {
							return this.point.name
						}
					},
					maxPointWidth: 80
				},
				series: {
					stacking: 'normal',
					cropThreshold: 10000,
					shadow: false,
					point: {
						events: {
							click: (event) => this.barClickEvent(event),
						}
					}
				}
			},
			tooltip: {
				crosshairs: false,
				shared: false,
				shadow: true,
				backgroundColor: '#FAFAFA',
				xDateFormat: '%Y-%b-%d',
				formatter: function () {
					return this.point.pointToolTip
				}
			},
			legend: {
				borderRadius: 5,
				borderWidth: 0,
				backgroundColor: '#FFFFFF',
				borderColor: '#BDBDBD',
			},
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					},
					legend: {
						borderWidth: 0
					}
				}
			}
		})

	}

	/**
	 * initialize the stockchart
	 * this chart is used to show the systems with scrollbar, show/hide empty weeks and 
	 * focus on today's axis options
	 */
	public initStockChart() {
		this.chartOptions = {
			chart: {
				type: 'column',
				zoomType: 'x'
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			loading: {
				labelStyle: {
					color: '#FAFAFA'
				},
				style: {
					backgroundColor: '#BDBDBD'
				}
			},
			xAxis: {
				title: {
					text: this.translatedData.X_AXIS
				},
				categories: this.xAxisList,
				crosshair: true,
				scrollbar:{
					enabled: true
				  },
				min:this.minValue,
				max:this.maxValue,
				plotLines: [{
					color: '#757575',
					dashStyle: 'ShortDash',
					value: this.plotLineValue,
					width: 2,
					zIndex: 4,
					label: {
						text: this.translatedData.TODAY,
						verticalAlign: 'top',
						textAlign: 'center',
						y: 40,
						x: -8,
						rotation: 270
					}
				}]
			},
			yAxis: [{ // left y axis	
				gridLineWidth: 1,
				tickWidth: 1,
				minTickInterval: 1,
				title: {
					text: this.translatedData.Y_AXIS
				},
				 max: this.yAxisMax,
				reversedStacks: false
			}],
			plotOptions: {
				column: {
					cursor: 'pointer',
					borderColor: '#9E9E9E',
					borderWidth: 0.5,
					stacking: 'normal',
					dataLabels: {
						enabled: this.isShowLabel,
						allowOverlap: true,
						rotation: 0,
						crop:false,
						overflow:'allow',
						style: {
							fontSize: '10',
							textOutline: '0px',
							color: '#212121'
						},
						formatter: function () {
							return this.point.name
						}
					},
					maxPointWidth: this.columnWidth
				},
				series: {
					stacking: 'normal',
					shadow: false,
					point: {
						events: {
							click: (event) => this.barClickEvent(event),
						}
					}
				}
			},
			series:[
			],
			tooltip: {
				crosshairs: false,
				shared: false,
				shadow: true,
				backgroundColor: '#FAFAFA',
				xDateFormat: '%Y-%b-%d',
				formatter: function () {
					return this.point.pointToolTip
				}
			},
			legend: {
				borderRadius: 5,
				borderWidth: 0,
				backgroundColor: '#FFFFFF',
				borderColor: '#BDBDBD',
			},
			exporting: {
				sourceWidth: 1200,
				sourceHeight: 400, 
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					},
					legend: {
						borderWidth: 0
					}
				}
			}
		}
	}

	/**
	 * show/hide labels of the charts
	 */
	showHideLabel() {
		//chech whether the condensed view is enabled
		if(this.isCondensedView){
			this.isShowLabel = !this.isShowLabel
			this.initChart()
			this.filterAndLoadSeriesByColor(this.allSystemList)
		}else{
			this.showHideLabelStockChart()
		}
	}

	/**
	 * show/hide data labels of the stockchart
	 * this will increase the column length according to the 
	 * character length of the system name
	 */	
	showHideLabelStockChart() {
		//reset xAxis
		this.resetxAxis()
		if(!this.isShowLabel && !this.isShowEmptyWeeks){
			this.xAxisList = this.xAxisListWoEmptyWeeks;
			this.plotLineValue = this.plotLineValueWoEmptyWeeks;
			this.isShowLabel = !this.isShowLabel;
			this.columnWidth = this.maxColumnWidth;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemList)
		} else if(!this.isShowLabel && this.isShowEmptyWeeks){
			this.xAxisList = this.xAxisListWithEmptyWeeks;
			this.columnWidth = this.maxColumnWidth;
			this.plotLineValue = this.plotLineValueWithEmptyWeeks;
			this.isShowLabel = !this.isShowLabel;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemListWithEmptyWeeks)
		} else if(this.isShowLabel && this.isShowEmptyWeeks){
			this.xAxisList = this.xAxisListWithEmptyWeeks;
			this.columnWidth = this.defaultPointWidth;
			this.plotLineValue = this.plotLineValueWithEmptyWeeks;
			this.isShowLabel = !this.isShowLabel;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemListWithEmptyWeeks)
		} else if(this.isShowLabel && !this.isShowEmptyWeeks){
			this.xAxisList = this.xAxisListWoEmptyWeeks;
			this.columnWidth = this.defaultPointWidth;
			this.plotLineValue = this.plotLineValueWoEmptyWeeks;
			this.isShowLabel = !this.isShowLabel;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemList)
		} else{
			// do nothing
		}
	}

	/**
	 * show/hide empty weeks of the stockchart
	 */
	showHideEmptyWeeks(){
		//reset xAxis
		this.resetxAxis()
		if(!this.isShowEmptyWeeks && !this.isShowLabel){
			this.xAxisList = this.xAxisListWithEmptyWeeks;
			this.columnWidth = this.defaultPointWidth;
			this.plotLineValue = this.plotLineValueWithEmptyWeeks;
			this.isShowEmptyWeeks = !this.isShowEmptyWeeks;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemListWithEmptyWeeks)
		} else if(!this.isShowEmptyWeeks && this.isShowLabel){
			this.xAxisList = this.xAxisListWithEmptyWeeks;
			this.columnWidth = this.maxColumnWidth;
			this.plotLineValue = this.plotLineValueWithEmptyWeeks;
			this.isShowEmptyWeeks = !this.isShowEmptyWeeks;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemListWithEmptyWeeks)
		} else if(this.isShowEmptyWeeks && this.isShowLabel){
			this.xAxisList = this.xAxisListWoEmptyWeeks;
			this.columnWidth = this.maxColumnWidth;
			this.plotLineValue = this.plotLineValueWoEmptyWeeks;
			this.isShowEmptyWeeks = !this.isShowEmptyWeeks;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemList)
		}else{
			this.xAxisList = this.xAxisListWoEmptyWeeks;
			this.columnWidth = this.defaultPointWidth;
			this.plotLineValue = this.plotLineValueWoEmptyWeeks;
			this.isShowEmptyWeeks = !this.isShowEmptyWeeks;
			this.getMinMaxValues();
			this.initStockChart()
			this.filterAndLoadSeriesByColorStockChart(this.allSystemList)
		}
	}

	/**
	 * focus on Today's axis
	 */
	focusOnTodayAxis(){
		this.isFocusOnTodayAxis = true;
		this.getMinMaxValues();
		Highcharts.charts[0].xAxis[0].setExtremes(this.minValue, this.maxValue);
	}

	/**
	 * graph point click event 
	 * @param event 
	 */
	public barClickEvent(event: any): boolean {
		// console.log('[barClickEvent] (pointData) ' + JSON.stringify(event.point.pointData));
		// console.log(event);
		this.showMCDetail = false
		this.selectedPoint = event.point.pointData
		this.openCheckSheetsCount = event.point.openCheckSheetsCount
		this.openPunchListCount = event.point.openPunchListCount
		let systemId: string = event.point.pointData.id
		this.systemService.getCheckSheets(systemId).subscribe(
			data => {
				this.rowData = data.payload
			},
			error => {
				console.log('[getCheckSheets] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription)
			}
		)
		// console.log('[confirmedEvent] (selectedPoint) ' + JSON.stringify(this.selectedPoint));
		this.showMCDetail = true
		return true
	}

	// showModal(): void {
	// 	this.isModalShown = true;
	// }

	// hideModal(): void {
	// 	this.autoShownModal.hide();
	// }

	// onHidden(): void {
	// 	this.isModalShown = false;
	// }

	// /**
	//  * user click yes button on confirmation modal
	//  * directed to the Mechanical Completion Dashboard page
	//  */
	// public confirmedEvent() {
	// 	//this is unwanted because client need to show data in same component
	// 	// so no need to navigate to another screen
	// 	// this.currentMenuItem = localStorage.getItem(LocalStorageConst.SELECTED_MENU_ITEM)
	// 	// // console.log('[confirmedEvent] (currentMenuItem) ' + this.currentMenuItem);
	// 	// if (this.currentMenuItem == 'dashboard') {
	// 	// 	this.sharedService.csmiRequest('com_MC', 'com')
	// 	// } else {
	// 	// }
	// 	console.log('[confirmedEvent] (selectedPoint) ' + JSON.stringify(this.selectedPoint));
	// 	this.showMCDetail = true
	// 	this.hideModal()
	// }

	/**
	 * remove system from dropdown. (close button click event)
	 * no empty system always shows 'ALL'
	 * @param event 
	 */
	removedSystem(event) {
		// console.log('[removedSystem] (event)' + JSON.stringify(event));
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.systemIdSelected = 'ALL';
		this.resetGraph()
		if(this.isCondensedView){
			this.getSkylineDataAndLoadGraph('ALL')
		}else{
			this.isShowEmptyWeeks = false;
			this.getSkylineDataAndLoadGraphStockChart('ALL')
		}
	}

	/**
	 * select new system. dropdown select event
	 * @param event 
	 */
	selectedSystemId(event) {
		// console.log('[selectedSystemId] (event)' + JSON.stringify(event));
		let selectedSystemId = event.id
		this.systemIdSelected = selectedSystemId;
		this.resetGraph()
		if(this.isCondensedView){
			this.getSkylineDataAndLoadGraph(selectedSystemId)
		}else{
			this.getSkylineDataAndLoadGraphStockChart(selectedSystemId)
		}
	}

	/**
	 * generate x-axis values from the data set
	 * @param weekList 
	 */
	private generateXaxis(weekList: any): string[] {
		let returnVal: string[] = []
		weekList.forEach(week => {
			returnVal.push(week.weekLabel)
		});
		return returnVal
	}

	/**
	 * add custom colors to the chart 
	 * @param colorStr 
	 */
	private provideColorToSeries(colorStr: string): string {
		let colorValur: string = ''
		switch (colorStr) {
			case 'Green':
				colorValur = '#388E3C'
				break
			case 'Yellow':
				colorValur = '#FDD835'
				break
			case 'White':
				colorValur = '#EEEEEE'
				break
			case 'Red':
				colorValur = '#D32F2F'
				break
			case 'Orange':
				colorValur = '#F57C00'
				break
			case 'Gray':
				colorValur = '#757575'
				break
		}
		return colorValur
	}

	/**
	 * generate tooltip using point value
	 * @param point 
	 */
	private generateTooltip(point: any, xValue: string): string {
		let systemNumber: string = point.systemNumber
		let systemName: string = point.systemName
		let fileType: string = ''
		let completionDays: number = point.completionDays
		let openCheckSheetsCount: number = point.openCheckSheetsCount
		let openPunchListCount: number = point.openPunchListCount
		let isVendorManaged: boolean = point.vendorManaged //get value of the VendorManaged option
		let targetCompletionString: string = ''

		if (completionDays == 0) {
			targetCompletionString = ''
		} else {
			if (completionDays < 0) {
				targetCompletionString = this.translatedData.TOOLTIP_TC + ' ' + completionDays + ' ' + this.translatedData.TOOLTIP_DSA
			} else {
				targetCompletionString = this.translatedData.TOOLTIP_TCI + ' ' + completionDays + ' ' + this.translatedData.TOOLTIP_DS
			}
		}

		if (point.signedFile != null) {
			fileType = this.translatedData.TOOLTIP_MCC_S_CERTIFICATE
		} else {
			fileType = this.translatedData.TOOLTIP_MCC_CERTIFICATE
		}

		let ttString = ''
		ttString += '<b>' + xValue + ' : ' + systemNumber + ' ' + systemName + '</b><br />'
		//if the system has the vendor managed flag then display it in the system hover area
		if(isVendorManaged){
            ttString += fileType + '    <b>Vendor Managed</b>' + '<br />'
        }else{
            ttString += fileType + '<br />'
        }

		if (openCheckSheetsCount != 0) {
			ttString += openCheckSheetsCount + ' ' + this.translatedData.TOOLTIP_OCS + ' <br />'
		} else {
			//do nothing
		}
		if (openPunchListCount != 0) {
			ttString += openPunchListCount + ' ' + this.translatedData.TOOLTIP_OPLS + ' <br />'
		} else {
			//do nothing
		}
		if ((point.color == 'Green') || (point.color == 'Yellow') || (point.color == 'Gray')) {
			//do nothing
		} else {
			ttString += targetCompletionString
		}
		return ttString
	}

	/**
	 * generate data array using server data for the highchart
	 * @param payload 
	 */
	private generateDataSeries(payload: any) {
		this.allSystemList = []
		//loop through week list and get week label as x value
		payload.weekList.forEach(week => {
			// console.log('[weekLabel]' + JSON.stringify(week.weekLabel));
			let xValue: string = week.weekLabel
			let xval: number = this.xAxisList.indexOf(week.weekLabel)
			//loop through system list and generate points for the graph
			week.systemList.forEach(system => {
				let point: any = system
				let yVal: number = 1
				let color: string = this.provideColorToSeries(system.color)
				let tooltip: string = this.generateTooltip(system, xValue)
				let name: string = system.systemNumber
				let data_point: any =
					{
						'x': xval,
						'y': yVal,
						'color': color,
						'name': name,
						'pointToolTip': tooltip,
						'actualColor': system.color,
						'pointData': point
					}
				this.allSystemList.push(data_point)
			});
		});
		// console.log('[generateDataSeriesByColor] (redList) ' + JSON.stringify(redList));
		this.filterAndLoadSeriesByColor(this.allSystemList)
	}

	/**
	 * generate data array using server data for the stockchart
	 * @param payload 
	 */
	private generateDataSeriesStockChart(payload: any) {
		this.allSystemList = []
		//loop through week list and get week label as x value
		payload.weekList.forEach(week => {
			// console.log('[weekLabel]' + JSON.stringify(week.weekLabel));
			let xValue: string = week.weekLabel
			let xval: number = this.xAxisList.indexOf(week.weekLabel)
			//loop through system list and generate points for the graph
			if(week.systemList != null){
				week.systemList.forEach(system => {
					let point: any = system
					let yVal: number = 1
					let color: string = this.provideColorToSeries(system.color)
					let tooltip: string = this.generateTooltip(system, xValue)
					let name: string = system.systemNumber
					let data_point: any =
						{
							'x': xval,
							'y': yVal,
							'color': color,
							'name': name,
							'pointToolTip': tooltip,
							'actualColor': system.color,
							'pointData': point
						}
					this.allSystemList.push(data_point)
				});
			}
		});
		// console.log('[generateDataSeriesByColor] (redList) ' + JSON.stringify(redList));
		this.filterAndLoadSeriesByColorStockChart(this.allSystemList)
	}

	/**
	 * generate data array using server data with empty weeks
	 * @param payload 
	 */
	private generateDataSeriesWithEmptyWeeks(weekListWithEmptyWeeks: any) {
		this.allSystemListWithEmptyWeeks = []
		//loop through week list and get week label as x value
		weekListWithEmptyWeeks.forEach(week => {
			// console.log('[weekLabel]' + JSON.stringify(week.weekLabel));
			let xValue: string = week.weekLabel
			let xval: number = this.xAxisListWithEmptyWeeks.indexOf(week.weekLabel)
			//loop through system list and generate points for the graph
			if(week.systemList != null){
				week.systemList.forEach(system => {
					let point: any = system
					let yVal: number = 1
					let color: string = this.provideColorToSeries(system.color)
					let tooltip: string = this.generateTooltip(system, xValue)
					let name: string = system.systemNumber
					let data_point: any =
						{
							'x': xval,
							'y': yVal,
							'color': color,
							'name': name,
							'pointToolTip': tooltip,
							'actualColor': system.color,
							'pointData': point
						}
					this.allSystemListWithEmptyWeeks.push(data_point)
				});
			}
		});
		// console.log('[generateDataSeriesByColor] (redList) ' + JSON.stringify(redList));
		//this.filterAndLoadSeriesByColor(this.allSystemListWithEmptyWeeks)
	}

	/**
	 * generate data series by color for the highchart
	 * @param systemList 
	 * @param color 
	 */
	private generateSeriesByColor(systemList: any, color: string): any {
		let returnList = systemList.filter(system => system.actualColor === color)
		return returnList
	}

	/**
	 * generate data series by color for the stockchart
	 * @param systemList 
	 * @param color 
	 */
	private generateSeriesByColorStockChart(systemList: any, color: string): any {
		let filteredList = systemList.filter(system => system.actualColor === color)
		let returnList: any = []
		for(var x = 0; x < this.xAxisList.length; x++){
			let fList = filteredList.filter(fsystem => fsystem.x == x)
			if(fList != undefined && fList.length > 0){
				fList.sort((a: any, b: any) => {
					if (a.pointData.completionDays < b.pointData.completionDays) {
					  return 1;
					} else if (a.pointData.completionDays > b.pointData.completionDays) {
					  return -1;
					} else {
					  return 0;
					}
				  });
				fList.forEach(sortedSystem => {
					returnList.push(sortedSystem)
				});
			}
		}
		return returnList
	}

	/**
	 * generate an arrays by colors for the highchart
	 * add those arrays as series to the chart with name and color
	 * @param allSystemList 
	 */
	private filterAndLoadSeriesByColor(allSystemList: any) {
		let redList: any = this.generateSeriesByColor(allSystemList, 'Red')
		let orangeList: any = this.generateSeriesByColor(allSystemList, 'Orange')
		let whiteList: any = this.generateSeriesByColor(allSystemList, 'White')
		let greenList: any = this.generateSeriesByColor(allSystemList, 'Green')
		let yellowList: any = this.generateSeriesByColor(allSystemList, 'Yellow')
		let greyList: any = this.generateSeriesByColor(allSystemList, 'Gray')

		if (greyList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.OTHER,
				color: '#757575',
				data: greyList.reverse()
			})
		} else {
			//do nothing
		}

		if (redList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.MC_NOT_COMP_BEHIND_TARGET,
				color: '#D32F2F',
				data: redList.reverse()
			})
		} else {
			//do nothing
		}

		if (orangeList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.MC_NOT_COMP_TARGET_IMMINENT,
				color: '#F57C00',
				data: orangeList.reverse()
			})
		} else {
			//do nothing
		}

		if (yellowList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.APPROVED_MC_WITH_A_PL,
				color: '#FBC02D',
				data: yellowList.reverse()
			})
		} else {
			//do nothing
		}

		if (greenList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.APPROVED_MC_NO_A_PL,
				color: '#388E3C',
				data: greenList.reverse()
			})
		} else {
			//do nothing
		}

		if (whiteList.length != 0) {
			this.chart.addSerie({
				name: this.translatedData.MC_NOT_COMP_ON_TARGET,
				color: '#EEEEEE',
				data: whiteList.reverse()
			})
		} else {
			//do nothing
		}

		this.isLoadingData = false
	}

	/**
	 * generate an arrays by colors for the stockchart
	 * add those arrays as series to the chart with name and color
	 * @param allSystemList 
	 */
	private filterAndLoadSeriesByColorStockChart(allSystemList: any) {
		let redList: any = this.generateSeriesByColorStockChart(allSystemList, 'Red')
		let orangeList: any = this.generateSeriesByColorStockChart(allSystemList, 'Orange')
		let whiteList: any = this.generateSeriesByColorStockChart(allSystemList, 'White')
		let greenList: any = this.generateSeriesByColorStockChart(allSystemList, 'Green')
		let yellowList: any = this.generateSeriesByColorStockChart(allSystemList, 'Yellow')
		let greyList: any = this.generateSeriesByColorStockChart(allSystemList, 'Gray')

		this.getMaxColumnWidth(greyList);
		this.getMaxColumnWidth(redList);
		this.getMaxColumnWidth(orangeList);
		this.getMaxColumnWidth(yellowList);
		this.getMaxColumnWidth(greenList);
		this.getMaxColumnWidth(whiteList);

		if (greyList.length != 0) {
			this.chartOptions.series.push({
				name: this.translatedData.OTHER,
				color: '#757575',
				data: greyList,
				pointWidth: this.maxColumnWidth
			})
		} else {
			//do nothing
		}

		if (redList.length != 0) {
			this.chartOptions.series.push({
				name: this.translatedData.MC_NOT_COMP_BEHIND_TARGET,
				color: '#D32F2F',
				data: redList,
				pointWidth: this.maxColumnWidth
			})
		} else {
			//do nothing
		}

		if (orangeList.length != 0) {
			this.chartOptions.series.push({
				name: this.translatedData.MC_NOT_COMP_TARGET_IMMINENT,
				color: '#F57C00',
				data: orangeList,
				pointWidth: this.maxColumnWidth
			})
		} else {
			//do nothing
		}

		if (yellowList.length != 0) {
			this.chartOptions.series.push({
				name: this.translatedData.APPROVED_MC_WITH_A_PL,
				color: '#FBC02D',
				data: yellowList,
				pointWidth: this.maxColumnWidth
			})
		} else {
			//do nothing
		}

		if (greenList.length != 0) {
			this.chartOptions.series.push({
				name: this.translatedData.APPROVED_MC_NO_A_PL,
				color: '#388E3C',
				data: greenList,
				pointWidth: this.maxColumnWidth
			})
		} else {
			//do nothing
		}

		if (whiteList.length != 0) {
			this.chartOptions.series.push({
				name: this.translatedData.MC_NOT_COMP_ON_TARGET,
				color: '#EEEEEE',
				data: whiteList,
				pointWidth: this.maxColumnWidth
			})
		} else {
			//do nothing
		}

		this.isLoadingData = false
	}

	/**
	 * get the maximum column width according to the length of the label
	 * @param columnList
	 */
	private getMaxColumnWidth(columnList){
		//set predefined value for the column width for the mobile device
		if(this.isMobile){
			this.maxColumnWidth = this.maxColumnWidthMobile;
		} else{
			//get the column width ratio according to the point label length
			this.defaultPointRatio = this.defaultPointWidth/this.defaultPointLabelLength;
			var calculatedMaxColumnWidth = 0;
			for (var i = 0; i < columnList.length; i++) {
				calculatedMaxColumnWidth = this.defaultPointLabelLength < columnList[i].name.length ? this.defaultPointRatio * columnList[i].name.length : this.defaultPointWidth;
				if(calculatedMaxColumnWidth > this.maxColumnWidth){
					this.maxColumnWidth = calculatedMaxColumnWidth;
				}
			}
		}
	}

	/**
	 * get minimum and maximum values for the x axis for the stockchart
	 */
	private getMinMaxValues(){
		//data labels, empty weeks and focus on today axis options are enabled 
		if(this.isShowLabel && this.isShowEmptyWeeks && this.isFocusOnTodayAxis){
			//set min, max values for the phone
			if(this.isMobile){
				if(this.columnWidth > this.defaultPointWidthMobile){
					let numOfColumns: number = 1; //set 1 for the mobile devices since we cannot exceed the chart width for long data labels
					this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(this.xAxisList.length - 1) : 
								(
									((this.plotLineValue - 0.5 + (numOfColumns/2)) < this.xAxisList.length - 1) ? 
									(this.plotLineValue - 0.5 + (numOfColumns/2)) :
									(this.xAxisList.length - 1)
								)
					this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
										(
											((this.xAxisList.length - numOfColumns) > 0) ? (this.xAxisList.length - numOfColumns) : 0
										 ) :
										(
											((this.plotLineValue + 0.5 - (numOfColumns/2)) > 0) ?
											(this.plotLineValue + 0.5 - (numOfColumns/2)) : 0
										)
				} else{
					if((this.maxRangeWidthMobile * this.xAxisList.length) > this.maxChartWidthMobile){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			} 
			//set min, max values for the tablet
			else if(this.isTablet){
				if(this.columnWidth > this.defaultPointWidthTablet){
					let numOfColumns: number = Math.round(this.maxChartWidthTablet/(this.columnWidth + this.maxRangeWidthTablet));
					this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(this.xAxisList.length - 1) : 
								(
									((this.plotLineValue - 0.5 + (numOfColumns/2)) < this.xAxisList.length - 1) ? 
									(this.plotLineValue - 0.5 + (numOfColumns/2)) :
									(this.xAxisList.length - 1)
								)
					this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
										(
											((this.xAxisList.length - numOfColumns) > 0) ? (this.xAxisList.length - numOfColumns) : 0
										 ) :
										(
											((this.plotLineValue + 0.5 - (numOfColumns/2)) > 0) ?
											(this.plotLineValue + 0.5 - (numOfColumns/2)) : 0
										)
				} else{
					if((this.maxRangeWidthTablet * this.xAxisList.length) > this.maxChartWidthTablet){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			} 
			//set min, max values for the desktop
			else{
				if(this.columnWidth > this.defaultPointWidth){
					let numOfColumns: number = Math.round(this.maxChartWidth/(this.columnWidth + this.maxRangeWidth));
					this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(this.xAxisList.length - 1) : 
								(
									((this.plotLineValue - 0.5 + (numOfColumns/2)) < this.xAxisList.length - 1) ? 
									(this.plotLineValue - 0.5 + (numOfColumns/2)) :
									(this.xAxisList.length - 1)
								)
					this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
										(
											((this.xAxisList.length - numOfColumns) > 0) ? (this.xAxisList.length - numOfColumns) : 0
										 ) :
										(
											((this.plotLineValue + 0.5 - (numOfColumns/2)) > 0) ?
											(this.plotLineValue + 0.5 - (numOfColumns/2)) : 0
										)
				} else{
					if((this.maxRangeWidth * this.xAxisList.length) > this.maxChartWidth){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			}
		} 
		//data labels and empty weeks options are enabled
		else if(this.isShowLabel && this.isShowEmptyWeeks){
			//set min, max values for the phone
			if(this.isMobile){
				//display one column for the mobile devices since we cannot exceed beyond the chart width for long data labels
				this.minValue = this.xAxisList.length - 1;
				this.maxValue = this.xAxisList.length - 1;
			} 
			//set min, max values for the tablet
			else if(this.isTablet){
				if(this.columnWidth > this.defaultPointWidthTablet){
					let numOfColumns: number = Math.round(this.maxChartWidthTablet/(this.columnWidth + this.maxRangeWidthTablet));
					this.maxValue = this.xAxisList.length - 1;
					this.minValue = ((this.xAxisList.length - numOfColumns) < 0 ) ? 0 : (this.xAxisList.length - numOfColumns);
				} else{
					if((this.maxRangeWidthTablet * this.xAxisList.length) > this.maxChartWidthTablet){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			} 
			//set min, max values for the desktop
			else{
				if(this.columnWidth > this.defaultPointWidth){
					let numOfColumns: number = Math.round(this.maxChartWidth/(this.columnWidth + this.maxRangeWidth));
					this.maxValue = this.xAxisList.length - 1;
					this.minValue = ((this.xAxisList.length - numOfColumns) < 0 ) ? 0 : (this.xAxisList.length - numOfColumns);
				} else{
					if((this.maxRangeWidth * this.xAxisList.length) > this.maxChartWidth){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			}
		} 
		//data labels and focus on today axis options are enabled
		else if(this.isShowLabel && this.isFocusOnTodayAxis){
			//set min, max values for the phone
			if(this.isMobile){
				if(this.columnWidth > this.defaultPointWidthMobile){
					let numOfColumns: number = 1; //set 1 for the mobile devices since we cannot exceed the chart width for long data labels
					this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(this.xAxisList.length -1) : 
								(
									((this.plotLineValue - 0.5 + (numOfColumns/2)) < (this.xAxisList.length - 1)) ? 
									(this.plotLineValue - 0.5 + (numOfColumns/2)) :
									(this.xAxisList.length - 1)
								)
					this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
										(
											((this.xAxisList.length - numOfColumns) > 0) ? (this.xAxisList.length - numOfColumns) : 0
										) :
										(
											((this.plotLineValue + 0.5 - (numOfColumns/2)) > 0) ?
											(this.plotLineValue + 0.5 - (numOfColumns/2)) : 0
										)
				} else{
					if((this.maxRangeWidthMobile * this.xAxisList.length) > this.maxChartWidthMobile){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			} 
			//set min, max values for the tablet
			else if(this.isTablet){
				if(this.columnWidth > this.defaultPointWidthTablet){
					let numOfColumns: number = Math.round(this.maxChartWidthTablet/(this.columnWidth + this.maxRangeWidthTablet));
					this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(this.xAxisList.length -1) : 
								(
									((this.plotLineValue - 0.5 + (numOfColumns/2)) < (this.xAxisList.length - 1)) ? 
									(this.plotLineValue - 0.5 + (numOfColumns/2)) :
									(this.xAxisList.length - 1)
								)
					this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
										(
											((this.xAxisList.length - numOfColumns) > 0) ? (this.xAxisList.length - numOfColumns) : 0
										) :
										(
											((this.plotLineValue + 0.5 - (numOfColumns/2)) > 0) ?
											(this.plotLineValue + 0.5 - (numOfColumns/2)) : 0
										)
				} else{
					if((this.maxRangeWidthTablet * this.xAxisList.length) > this.maxChartWidthTablet){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			} 
			//set min, max values for the desktop
			else{
				if(this.columnWidth > this.defaultPointWidth){
					let numOfColumns: number = Math.round(this.maxChartWidth/(this.columnWidth + this.maxRangeWidth));
					this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(this.xAxisList.length -1) : 
								(
									((this.plotLineValue - 0.5 + (numOfColumns/2)) < (this.xAxisList.length - 1)) ? 
									(this.plotLineValue - 0.5 + (numOfColumns/2)) :
									(this.xAxisList.length - 1)
								)
					this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
										(
											((this.xAxisList.length - numOfColumns) > 0) ? (this.xAxisList.length - numOfColumns) : 0
										) :
										(
											((this.plotLineValue + 0.5 - (numOfColumns/2)) > 0) ?
											(this.plotLineValue + 0.5 - (numOfColumns/2)) : 0
										)
				} else{
					if((this.maxRangeWidth * this.xAxisList.length) > this.maxChartWidth){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			}
		}
		//empty weeks and focus on today axis options are enabled 
		else if(this.isShowEmptyWeeks && this.isFocusOnTodayAxis){
			this.setDefaultColumnWidth(); //set default column width
			this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
					(this.xAxisList.length - 1) : 
					(
						((this.plotLineValue - 0.5 + (this.maxColumnCount/2)) < this.xAxisList.length - 1) ? 
						(this.plotLineValue - 0.5 + (this.maxColumnCount/2)) :
						(this.xAxisList.length - 1)
					)
			this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
			(
				((this.xAxisList.length - this.maxColumnCount) > 0) ?
				(this.xAxisList.length - this.maxColumnCount) : 0
			) :
			(
				((this.plotLineValue + 0.5 - (this.maxColumnCount/2)) > 0) ?
				(this.plotLineValue + 0.5 - (this.maxColumnCount/2)) : 0
			)
		} 
		//data label option is enabled
		else if(this.isShowLabel){
			//set min, max values for the phone
			if(this.isMobile){
				//display one column for the mobile devices since we cannot exceed beyond the chart width for long data labels
				this.minValue = this.xAxisList.length - 1;
				this.maxValue = this.xAxisList.length - 1;
			}
			//set min, max values for the tablet 
			else if(this.isTablet){
				if(this.columnWidth > this.defaultPointWidthTablet){
					let numOfColumns: number = Math.round(this.maxChartWidthTablet/(this.columnWidth + this.maxRangeWidthTablet));
					this.maxValue = this.xAxisList.length - 1;
					this.minValue = ((this.xAxisList.length - numOfColumns) < 0 ) ? 0 : this.xAxisList.length - numOfColumns;
				} else{
					if((this.maxRangeWidthTablet * this.xAxisList.length) > this.maxChartWidthTablet){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			} 
			//set min, max values for the desktop
			else{
				if(this.columnWidth > this.defaultPointWidth){
					let numOfColumns: number = Math.round(this.maxChartWidth/(this.columnWidth + this.maxRangeWidth));
					this.maxValue = this.xAxisList.length - 1;
					this.minValue = ((this.xAxisList.length - numOfColumns) < 0 ) ? 0 : this.xAxisList.length - numOfColumns;
				} else{
					if((this.maxRangeWidth * this.xAxisList.length) > this.maxChartWidth){
						this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
						this.maxValue = this.xAxisList.length - 1;
					} else{
						this.minValue = 0;
						this.maxValue = this.xAxisList.length - 1;
					}
				}
			}
		} 
		//focus on today axis option is enabled
		else if(this.isFocusOnTodayAxis){
			this.setDefaultColumnWidth(); //set default column width
			this.maxValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
							(this.xAxisList.length - 1) : 
							(
								((this.plotLineValue - 0.5 + (this.maxColumnCount/2)) < this.xAxisList.length - 1) ? 
								(this.plotLineValue - 0.5 + (this.maxColumnCount/2)) :
								(this.xAxisList.length - 1)
							)
			this.minValue = ((this.xAxisList.length - this.plotLineValue) == 0.5) ? 
								(
									((this.xAxisList.length - this.maxColumnCount) > 0) ?
									(this.xAxisList.length - this.maxColumnCount) : 0
								) :
								(
									((this.plotLineValue + 0.5 - (this.maxColumnCount/2)) > 0) ?
									(this.plotLineValue + 0.5 - (this.maxColumnCount/2)) : 0
								)
		} 
		//empty weeks option is enabled
		else if(this.isShowEmptyWeeks){
			this.setDefaultColumnWidth(); //set default column width
			this.minValue = ((this.xAxisList.length - this.maxColumnCount) < 0) ? 0 : (this.xAxisList.length - this.maxColumnCount);
			this.maxValue = this.xAxisList.length-1
		} else{
			if(this.isMobile){
			this.columnWidth = ((this.maxChartWidthMobile/this.xAxisList.length)/2) > this.defaultPointWidthMobile ? this.defaultPointWidthMobile : (this.maxChartWidthMobile/this.xAxisList.length)/2;
			} else if(this.isTablet){
				this.columnWidth = ((this.maxChartWidthTablet/this.xAxisList.length)/2) > this.defaultPointWidthTablet ? this.defaultPointWidthTablet : (this.maxChartWidthTablet/this.xAxisList.length)/2;
			} else{
				this.columnWidth = ((this.maxChartWidth/this.xAxisList.length)/2) > this.defaultPointWidth ? this.defaultPointWidth : (this.maxChartWidth/this.xAxisList.length)/2;
			}
			this.minValue = 0;
			this.maxValue = (this.xAxisList.length - 1);
		}
	}

	/**
	 * set default column width according to the device type
	 */
	private setDefaultColumnWidth(){
		if(this.isMobile){
			this.columnWidth = this.defaultPointWidthMobile;
		} else if(this.isTablet){
			this.columnWidth = this.defaultPointWidthTablet;
		} else{
			this.columnWidth = this.defaultPointWidth;
		}
	}

	/**
	 * remove all series from the highchart
	 */
	private removeSerie() {
		let num = this.chart.ref.series.length
		// console.log('[removeSerie] num ' + JSON.stringify(num));
		for (let i = 0; i < num; i++) {
			this.chart.removeSerie(0);
		}
	}

	/**
	 * remove all series from the stockchart
	 */
	private removeSerieStockChart() {
		let num = this.chartOptions.series.length;
		for(let i = 0; i < num; i++){
			this.chartOptions.series.pop()
		}
	}

	/**
	 * AG GRID METHODS
	 * @param params 
	 */
	agInit(params: any): void {
		console.log(params);
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);
	}

	onCellClick(event) {
		// console.log(event)
	}

	/**
	 * status cell renderer
	 * @param param 
	 */
	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'NotIssued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	/**
	 * refactor the status value in the table
	 * @param status 
	 */
	public getStatus(status: any) {
		switch (status) {
			case 'NotIssued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	/**
	 * refresh the graph
	 */
	public refresh() {
		console.log('[McSystemSkylineWidgetComponent] (refresh)');
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.resetGraph()
		this.systemIdSelected = 'ALL';
		//check whether the condensed view is enabled or not
		if(this.isCondensedView){
			this.getSkylineDataAndLoadGraph('ALL')
		}else{
			this.columnWidth = (this.maxChartWidth/this.xAxisList.length)/2;
			this.xAxisList = this.xAxisListWoEmptyWeeks;
			this.plotLineValue = this.plotLineValueWoEmptyWeeks;
			this.isShowLabel = false
			this.isShowEmptyWeeks = false
			this.isFocusOnTodayAxis = false
			this.getSkylineDataAndLoadGraphStockChart('ALL')
		}
	}

	/**
	 * get skyline data and draw the graph
	 * before draw the graph remove current graph using resetGraph()
	 * @param id 
	 */
	private getSkylineDataAndLoadGraph(id: string) {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.mechanicalCompletionService.getSkylineData(id).subscribe(
			data => {
				console.log('[getSkylineData] (data) ' + JSON.stringify(data))
				if (data.payload != null) {
					if (data.payload.weekList != null) {
						this.xAxisList = this.generateXaxis(data.payload.weekList)
						this.yAxisMax = data.payload.highestCount
						this.plotLineValue = data.payload.plotLineValue
						// console.log('[getSkylineData] (xAxisList) ' + JSON.stringify(this.xAxisList))
						this.initChart()
						this.generateDataSeries(data.payload)
					} else {
						//no data to show
						this.initChart()
						this.isLoadingData = false
						this.isNoDataToShow = true
					}
				} else {
					//no data to show
					this.initChart()
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				console.log('[getSkylineData] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			}
		)
	}

	/**
	 * get skyline data and draw the graph
	 * before draw the graph remove current graph using resetGraph()
	 * @param id 
	 */
	private getSkylineDataAndLoadGraphStockChart(id: string) {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.mechanicalCompletionService.getSkylineData(id).subscribe(
			data => {
				console.log('[getSkylineData] (data) ' + JSON.stringify(data))
				if (data.payload != null) {
					if (data.payload.weekList != null) {
						this.xAxisList = this.generateXaxis(data.payload.weekList)
						this.yAxisMax = data.payload.highestCount
						this.plotLineValue = data.payload.plotLineValue
						this.plotLineValueWoEmptyWeeks = this.plotLineValue
						this.xAxisListWoEmptyWeeks = this.xAxisList;

						// console.log('[getSkylineData] (xAxisList) ' + JSON.stringify(this.xAxisList))
						this.getMinMaxValues()
						this.initStockChart()
						this.generateDataSeriesStockChart(data.payload)
					} else {
						//no data to show
						this.initStockChart()
						this.isLoadingData = false
						this.isNoDataToShow = true
					}

					if(data.payload.weekListWithEmptyWeeks != null){
						this.xAxisListWithEmptyWeeks = this.generateXaxis(data.payload.weekListWithEmptyWeeks)
						this.plotLineValueWithEmptyWeeks = data.payload.plotLineValueWithEmptyWeeks
						this.generateDataSeriesWithEmptyWeeks(data.payload.weekListWithEmptyWeeks)
					} else{

					}

				} else {
					//no data to show
					this.initStockChart()
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				console.log('[getSkylineData] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			}
		)
	}

	/**
	 * before draw the graph remove current graph
	 * reset graph with the table
	 */
	private resetGraph() {
		this.showMCDetail = false
		this.selectedPoint = null
		//check whether the condensed view is enabled or not
		if(this.isCondensedView){
			this.removeSerie()
		}else{
			this.removeSerieStockChart()
			this.resetxAxis();
			this.isFocusOnTodayAxis = false
		}
	}

	/**
	 * Reinitialize the stockchart
	 */
	reInitializeStockChart(){
		//reinitialize Highcharts.charts
		if (Highcharts.charts[0] !== undefined) {
			Highcharts.charts[0].destroy();
			Highcharts.charts.splice(0, 1);
			}
	}

	/**
	 * reset xaxis after using setExtremes()
	 */
	private resetxAxis(){
		if (Highcharts.charts[0].xAxis[0] !== undefined) {
			Highcharts.charts[0].xAxis[0].remove()
		}
	}

	/**
	 * show/hide the highchart
	 */
	public showCondensedView(){
		//hide MC detail widget
		this.showMCDetail = false
		this.selectedPoint = null
		this.showFilterOptions = false //remove filter option
		if(this.isCondensedView){
			this.isCondensedView = false
			this.isShowLabel = false
			this.isShowEmptyWeeks = false
			this.isFocusOnTodayAxis = false
			this.reInitializeStockChart() //reinitialize the stockchart
			this.initStockChart() //initialize the highchart
			this.getSkylineDataAndLoadGraphStockChart('ALL')
		}else{
			this.isCondensedView = true
			this.isShowLabel = true
			this.initChart() //initialize the highchart
			this.getSkylineDataAndLoadGraph('ALL')
		}
	}

	/**
	 * Set device type according to the screen width of the device
	 */
	private getDeviceType(){
		var width = window.innerWidth; //get screen width of the device
		if (width <= this.MOBILE_MAX_WIDTH) {
			this.isMobile = true;
			this.maxColumnWidth = this.defaultPointWidthMobile;
			this.columnWidth = this.defaultPointWidthMobile;
		} else if (width > this.MOBILE_MAX_WIDTH && width <= this.TABLET_MAX_WIDTH) {
			this.isTablet = true;
			this.maxColumnWidth = this.defaultPointWidthTablet;
			this.columnWidth = this.defaultPointWidthTablet;
		} else {
			this.isDesktop = true;
			this.maxColumnWidth = this.defaultPointWidth;
			this.columnWidth = this.defaultPointWidth;
		}
	}

	downloadCheckSheetSentFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadSentFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.initialFileName);
			}, error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	downloadCheckSheetApprovedFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadApprovedFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.approvedFileName);
			}, error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	showMCCCertificate(selectedPoint: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.downloadSentFile(UserVariable.projectId, selectedPoint.file)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "MCC_" + selectedPoint.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	showMCCSignedCertificate(selectedPoint: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.downloadFile(UserVariable.projectId, selectedPoint.signedFile)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "MCC_" + selectedPoint.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}
}
