import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ServiceUrls } from '../../util/service-urls';
import { Injectable } from '@angular/core';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';

@Injectable({ providedIn: "root" })
export class Openid {
   constructor(private http:HttpClient){}

   /**
    * Login with Google authentication
    * @param loginRequest login request data
    */
   public openIDLogin(loginRequest: String, callBack: WsCallback) {
       this.http.post(ServiceUrls.OPEN_ID_LOGIN_URL, loginRequest).subscribe(
           data => {
               // this may be incorrect
               //var val = (data as HttpErrorResponse).message;
               var modified = JSON.parse(JSON.stringify(data));
               var res = new WsResponse(
                   modified.status.description,
                   modified.status.code,
                   modified.status.name,
                   modified.payload);
                   callBack.onSuccess(res, WsType.OPEN_ID_LOGIN);
           },
           error => {
               if (error.status == 532 || error.status == 542 || error.status == 401) {
                   var val = (error as HttpErrorResponse).error;
                   var modified = JSON.parse(JSON.stringify(val));
                   var res = new WsResponse(
                       modified.status.description,
                       modified.status.code,
                       modified.status.name,
                       modified.payload);
                       callBack.onFail(res, WsType.OPEN_ID_LOGIN);
               } else {
                   //browser related issues
                   var res = new WsResponse("Unknown error happened");
                   callBack.onFail(res, WsType.OPEN_ID_LOGIN);
               }
           }
       );
   }

   /**
    * Get google API keys
    */
   public getGoogleAPIKeys(callBack: WsCallback) {
       this.http.get(ServiceUrls.OPEN_ID_LOGIN_URL).subscribe(
           data => {
               // this may be incorrect
               //var val = (data as HttpErrorResponse).message;
               var modified = JSON.parse(JSON.stringify(data));
               var res = new WsResponse(
                   modified.status.description,
                   modified.status.code,
                   modified.status.name,
                   modified.payload);
                   callBack.onSuccess(res, WsType.GET_GOOGLE_KEYS);
           },
           error => {
               if (error.status == 532 || error.status == 542 || error.status == 401) {
                   var val = (error as HttpErrorResponse).error;
                   var modified = JSON.parse(JSON.stringify(val));
                   var res = new WsResponse(
                       modified.status.description,
                       modified.status.code,
                       modified.status.name,
                       modified.payload);
                       callBack.onFail(res, WsType.GET_GOOGLE_KEYS);
               } else {
                   //browser related issues
                   var res = new WsResponse("Unknown error happened");
                   callBack.onFail(res, WsType.GET_GOOGLE_KEYS);
               }
           }
       );
   }

    /**
     * Get Auth0 Configurations
     */
     public  getAuth0Configurations(callBack: WsCallback) {
        this.http.get(ServiceUrls.GET_AUTH0_CONFIGURATIONS_URL).subscribe(
            data => {
                var modified = JSON.parse(JSON.stringify(data));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                    callBack.onSuccess(res, WsType.GET_AUTH0_CONFIGURATIONS);
            },
            error => {
                if (error.status == 532 || error.status == 542 || error.status == 401) {
                    var val = (error as HttpErrorResponse).error;
                    var modified = JSON.parse(JSON.stringify(val));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                        callBack.onFail(res, WsType.GET_AUTH0_CONFIGURATIONS);
                } else {
                    var res = new WsResponse("Unknown error happened");
                    callBack.onFail(res, WsType.GET_AUTH0_CONFIGURATIONS);
                }
            }
        );
    }

}