import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button id="add" (click)="add()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0"> <i class="far fa-edit mx-2" aria-hidden="true"></i> </button>'

})
export class WIAddButtonRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;	
	}

	public add() {
		console.log("ADD")

		this.params.context.componentParent.iconClicked = true
		this.params.context.componentParent.add(this.params)
	}

	refresh(): boolean {
		return false;
	}
}