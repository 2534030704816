import { Injectable } from "../../../node_modules/@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class RecentProject {
	private callback: WsCallback;
	private recentProjectList: any;
	constructor(private http: HttpClient) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	public setRecentProjectList(recentProjectList: any) {
		this.recentProjectList = recentProjectList;
	}

	public getRecentProjectList() {
		return this.recentProjectList;
	}

    /**
     * Add or update recent project.
     * @param request json request with a format as  {"projectid":"","userid":"","isdefault":"","projecttype":""}
     */
	public addUpdateRecentProject(request: string) {
		this.http.post(ServiceUrls.ADD_UPDATE_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.ADD_UPDATE_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onFail(res, WsType.ADD_UPDATE_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.ADD_UPDATE_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Get all recent project list
     * @param userId id value of the user
     */
	public getAllRecentProjects(userId: String) {
		this.http.get(ServiceUrls.getAllRecentProjects(userId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.GET_ALL_RECENT_PROJECTS);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onFail(res, WsType.GET_ALL_RECENT_PROJECTS);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.GET_ALL_RECENT_PROJECTS);
				}
			}
		);
	}
    /**
     * Get default project of the user
     * @param userId id of the user
     */
   
   public getDefaultRecentProject(userId: string, wsCallBack: WsCallback) {
		this.http.get(ServiceUrls.getDefaultRecentProject(userId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}
	
    /**
     * Set the default project of the user
     * @param request json request with a format as  {"projectId":"","userId":"","isDefaultProject":"","projectType":"", "userId":""}
     */
	public setDefaultRecentProjects(request: string) {
		this.http.post(ServiceUrls.SET_DEFAULT_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.SET_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onFail(res, WsType.SET_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.SET_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Delete a recently visited project
     * @param request json request with a format as  {"projectId":"","userId":"", "projectType":""}
     */
	public deleteUserRecentProject(request: string) {
		this.http.post(ServiceUrls.DELETE_USER_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.DELETE_USER_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onFail(res, WsType.DELETE_USER_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.DELETE_USER_RECENT_PROJECT);
				}
			}
		);
	}

    /**
     * Update default project as non default
     * @param request json request with a format as  {"projectId":"","userId":"", "projectType":""}
     */
	public deleteUserDefaultRecentProject(request: String) {
		this.http.post(ServiceUrls.DELETE_USER_DEFAULT_RECENT_PROJECT, request).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					this.callback.onFail(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					this.callback.onFail(res, WsType.DELETE_USER_DEFAULT_RECENT_PROJECT);
				}
			}
		);
	}
}
