<div class="btn-group col px-0">
	<input 
	[(ngModel)]="val"
	[typeahead]="typeahead_list" 
	[typeaheadOptionField]="option_field"
	class="form-control" 
	placeholder="{{place_holder}}"
	[typeaheadMinLength]="0"
	(typeaheadOnSelect)="onSelect($event)"
	(ngModelChange)="textChange($event)"
	container="body"
	[typeaheadScrollable]="true"
	(focusout)="onfocusout(val)">
	<span *ngIf="val.length != 0" class="searchclear fa fa-times" (click)="clearInput()"></span>
</div>