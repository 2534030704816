import { Component, OnInit, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
	selector: 'app-custom-typeahead',
	templateUrl: './custom-typeahead.component.html',
	styleUrls: ['./custom-typeahead.component.css'],
	inputs: ['typeahead_list', 'option_field', 'place_holder', 'val', 'initial_value'],
	outputs: ['text_changed', 'out_focus']
})
export class CustomTypeaheadComponent implements OnInit {

	typeahead_list: any
	option_field: string = 'name'
	val: any = ''
	place_holder: string = 'Custom Typeahead Placeholder';
	initial_value: any 

	selectedOption: any = null

	constructor() {
		
	}

	ngOnInit() {
		console.log('[CustomTypeaheadComponent] (initial_value) ' + JSON.stringify(this.initial_value));
		this.setInitialValue(this.initial_value)
	}

	onSelect(event: TypeaheadMatch): void {
		this.selectedOption = event.item.value;
		this.val = event.item.name
		this.text_changed.emit({
			value: event.item.value
		})
		this.out_focus.emit({
			value: event.item.value
		})
	}

	text_changed = new EventEmitter<any>()
	out_focus = new EventEmitter<any>()

	textChange(value: any) {		
		let newVal = this.getValueFromText(value)	
		if(newVal != null) {
			this.val = newVal.name
			this.text_changed.emit(
				{
					value: newVal.value
				}
			)
		} else {
			this.val = value
			this.text_changed.emit(
				{
					value: value
				}
			)
		}
		
	}

	clearInput() {
		this.val = ''
		this.selectedOption = null
		this.text_changed.emit(null)
		this.out_focus.emit(null)
	}

	setInitialValue(initialValue: any) {
		if(initialValue != null) {
			this.selectedOption = initialValue.value
			this.val = initialValue.name
			this.text_changed.emit({
				value: initialValue.value
			})
			this.out_focus.emit({
				value: initialValue.value
			})
		} else {
			//do nothing
		}
	}

	onfocusout(value: any) {
		let newVal = this.getValueFromText(value)
		if(newVal != null) {
			this.val = newVal.name
			this.out_focus.emit(
				{
					value: newVal.value
				}
			)
		} else {
			this.val = value
			this.out_focus.emit(
				{
					value: value
				}
			)
		}		
		
	}

	getValueFromText(value: any) {
		let selectedItem = null
		selectedItem = this.typeahead_list.find(item => item.name === value)
		if(selectedItem == null) {
			return null
		} else {
			return selectedItem
		}
	}
}
