import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class MechanicalCompletionService {

	constructor(private http: HttpClient) {
	}

	getSkylineData(systemId: string): Observable<WsResponse> {
		console.log("MechanicalCompletionService.getSkylineData() == > ServiceURL= " + ServiceUrls.GET_SKYLINE_DATA)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("checkSheetType", "A").set("year", "ALL") }

		return this.http.get(ServiceUrls.GET_SKYLINE_DATA, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getAllMechanicalCompletion(): Observable<WsResponse> {
		console.log("MechanicalCompletionService.getAllMechanicalCompletion() == > ServiceURL= " + ServiceUrls.GET_ALL_MC)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.GET_ALL_MC, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	addMechanicalCompletion(mechanicalCompletion: any): Observable<WsResponse> {
		console.log("MechanicalCompletionService.addMechanicalCompletion() == > ServiceURL= " + ServiceUrls.ADD_MECHANICAL_COMPLETION)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete mechanicalCompletion['isStart']
		mechanicalCompletion['userToken'] = token
		return this.http.post(ServiceUrls.ADD_MECHANICAL_COMPLETION, JSON.stringify(mechanicalCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	updateMechanicalCompletion(mechanicalCompletion: any): Observable<WsResponse> {
		console.log("MechanicalCompletionService.updateMechanicalCompletion() == > ServiceURL= " + ServiceUrls.UPDATE_MECHANICAL_COMPLETION)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete mechanicalCompletion['isStart']
		delete mechanicalCompletion['newtargetCompletionDate']
		mechanicalCompletion['userToken'] = token
		return this.http.post(ServiceUrls.UPDATE_MECHANICAL_COMPLETION, JSON.stringify(mechanicalCompletion)).
			pipe( map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	generateMechanicalCompletion(mechanicalCompletion: any) {
		console.log("MechanicalCompletionService.generateMechanicalCompletion() == > ServiceURL= " + ServiceUrls.GENERATE_MC)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		mechanicalCompletion['userToken'] = token
		//return this.http.post(ServiceUrls.GENERATE_MC, JSON.stringify(mechanicalCompletion)).
		return this.http.post(ServiceUrls.GENERATE_MC, JSON.stringify(new commonRequest("MCC", JSON.stringify(mechanicalCompletion)))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	revokeMechanicalCompletion(mccId: any) {
		console.log("MechanicalCompletionService.revokeMechanicalCompletion() == > ServiceURL= " + ServiceUrls.getRevokeMCC(mccId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let mechanicalCompletion = { 'userToken': token }
		return this.http.post(ServiceUrls.getRevokeMCC(mccId), JSON.stringify(mechanicalCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}
	
	getMCCSignature(fileName: any) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, { responseType: "blob", params: new HttpParams().set("fileName", fileName).set("mode", "GetImage") });
	}	

	downloadFile(projectId: string, mccId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", mccId)
					.set("mode", "ViewApprovedOutput").set("type", "MechanicalCompletion").set("fileName", "MCC")
			});
	}

	downloadSentFile(projectId: string, mccId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", mccId).set("mode", "ViewCommOutput").set("type", "MechanicalCompletion").set("fileName", "MCC")
			});
	}

	public getMccCount(projectId: any): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set('token', token) }

		return this.http.get(ServiceUrls.getMechanicalMccCount(projectId), options)
			.pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	deleteMechanicalCompletion(mccId: any) {
		console.log("MechanicalCompletionService.deleteMechanicalCompletion() == > ServiceURL= " + ServiceUrls.getDeleteMCC(mccId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let mechanicalCompletion = { 'userToken': token }
		return this.http.post(ServiceUrls.getDeleteMCC(mccId), JSON.stringify(mechanicalCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}
}

class commonRequest{
	constructor(private certificateType: String, private certificate: String) { }
}