import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class LandingService {
	public token: string;
	private callback: WsCallback;

	constructor(private http: HttpClient) {

	}

	public setSelectedProjectName(selectedProjectName: string) {
		localStorage.setItem('selectedProjectName', selectedProjectName);
	}
	
	public getSelectedProjectName() {
		return localStorage.getItem('selectedProjectName');
	}

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	getAllProjects(userId : any) {
		console.log("landingService.getAllProjects() == > ServiceURL= " + ServiceUrls.USER_PROJECT_VIEW  + userId + "/projects.json")
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getProjectListByUserURL(userId);
		this.http.get(url, options).
			subscribe(
			data => {
				
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.LANDING);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description != undefined ? modified.status.description: "Error",
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.LANDING);
			}
		
			);
	}

	public getProjectListByUserURL(userId: string) {
		let url = ServiceUrls.USER_PROJECT_VIEW + userId + '/getDetailActiveProjectsByUser.json'
		return url;
	}
}

