import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    template: `<a [routerLink]="['/dashboard']" *ngIf="show()" (click)="download()">{{systemNo}}</a>`
})
export class SHCFileDownloadRenderer implements ICellRendererAngularComp {    
    params: any;  
    systemNo: string;

    agInit(params: any): void {
        this.params = params;
        if(this.params.data){
            if(this.params.data.shc){
                this.systemNo = this.params.data.systemNumber;
            }
        }
    }

    public show() {
		if (this.params.data) {
            if(this.params.data.shc){
                if (this.params.data.shc.shcStatus == 'Approved') {
                    return true
                } else {
                    return false
                }
            }else{
                return false
            }
		} else {
			return false
		}
	}

    refresh(params: any): boolean {
        return false;
    }    

    public download(){
        this.params.context.componentParent.downloadSHCFile(this.params);
    }
}