
import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SystemService } from '../system.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { LoadingService } from 'src/app/util/loading/loading.service';
import * as FileSaver from 'file-saver';
import { CheckSheetService } from '../checksheet.service';
import { MechanicalCompletionService } from '../mechanical-completion.service';
import { SystemTurnoverNoticeService } from '../system-turnover-notice/system-turnover-notice.service';
import { SystemHandoverWidgetService } from '../system-handover-widget/system-handover-widget.service';
import { CheckSheetApprovedFileIconRenderer } from './../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import { CheckSheetSentFileIconRenderer } from './../common-cell-renderer/file-icon/checksheet-sent-file-icon.component';
import { Widget } from '../widget.model';
import { PunchlistDetailsBySystemComponent } from './cell-renderer/punchlist-details-by-system.component';

import { MCCFileDownloadRenderer } from './cell-renderer/mcc.component';
import { SHCFileDownloadRenderer } from './cell-renderer/shc.component';
import { STNPTNFileDownloadRenderer } from './cell-renderer/stn-ptn.component';
import { CheckSheetSentFileDownloadRenderer } from './cell-renderer/checksheet-sent.component';
import { CheckSheetRecievedFileDownloadRenderer } from './cell-renderer/checksheet-recieved.component';
import { GridOptions } from "ag-grid";
import { PunchlistService } from '../punchlist.service';
import { DecodeHtmlString } from '../../util/common/decode-html-string.pipe';
import { PunchListDocumentDownloadRenderer } from './cell-renderer/punchlist-document-cell-renderer.component';
import { IRNRenderer } from './cell-renderer/irn.component';
import { InspectionReleaseNoticeService } from '../inspection-release-notice/inspection-release-notice.service';
import { PunchlistCellRenderComponent } from './cell-renderer/punchlist-cell-render-component.component';
import { TranslateService } from '@ngx-translate/core';
import { OTNRenderer } from './cell-renderer/otn.component';
import { OnshoreTurnoverNoticeService } from '../onshore-turnover-notice.service';

@Component({
  selector: 'app-punchlist-by-system-widget',
  templateUrl: './punchlist-by-system-widget.component.html',
  styleUrls: ['./punchlist-by-system-widget.component.css']
})
export class PunchlistBySystemWidgetComponent implements OnInit {
  public isMinimized: boolean = false
  public isMinimizedPunchlist: boolean = false
  public gridApi
  public gridColumnApi;
  public gridApiPunchlist
  public gridColumnApiPunchlist;
  public gridOptions: GridOptions;
  public gridOptionsPunchlist: GridOptions;

  public columnDefs;
  public columnDefsPunchlist;
  public autoGroupColumnDef;
  public detailCellRendererParams;
  public rowData: any;
  public rowDataPunchlist: any;
  private filteredData: Array<any> = [];
  public isHidden: boolean = true;
  public getDataPath;

  constructor( private systemService: SystemService, 
    private alertService: AlertService,
    private loadingService: LoadingService,
    private checkSheetService: CheckSheetService,
    private mechanicalCompletionService: MechanicalCompletionService,
    private systemTurnoverNoticeService: SystemTurnoverNoticeService,
    private systemHandoverWidgetService: SystemHandoverWidgetService,
    private punchlistService: PunchlistService,
    private decodeHTMLString: DecodeHtmlString,
    private inspectionReleaseNoticeService: InspectionReleaseNoticeService, 
    private onshoreTurnoverNoticeService: OnshoreTurnoverNoticeService, private translate: TranslateService) {
  
      this.gridOptions = <GridOptions>{
        context: {
          componentParent: this
        }
      };
      this.gridOptionsPunchlist = <GridOptions>{
        context: {
          componentParent: this
        }
      };
     
      this.columnDefs = [
        {
          headerName:"",
          children:[
            {
              headerName: "OTN",
              field: "otn",
              cellRendererFramework: OTNRenderer,
              autoHeight: true
            },
            {
              headerName: "IRN",
              field: "irn",
              cellRendererFramework: IRNRenderer,
              autoHeight: true
            },
            {
              headerName: "STN/PTN",
              field: "stnptn",
              autoHeight: true,
              cellRendererFramework: STNPTNFileDownloadRenderer,
              minWidth: 30
            },
            {
              headerName: "MCC",
              field: "mcc",
              cellRendererFramework: MCCFileDownloadRenderer,
              minWidth: 30
            },
            {
              headerName: "SHC",
              field: "shc",
              cellRendererFramework: SHCFileDownloadRenderer,
              minWidth: 30
            }
          ]
        },
        { 
          headerName: "CheckSheet",
          marryChildren: true,
          children:[
        {
          headerName: "Sent",
          field: "initialFileName",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          minWidth: 30,
          suppressFilter: true,
          cellRendererFramework: CheckSheetSentFileDownloadRenderer,
          suppressSorting: true,
          suppressMenu: true
          },
        { 
          headerName: "Received",
          field: "approvedFileName",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          minWidth: 30,
          suppressFilter: true,
          cellRendererFramework: CheckSheetRecievedFileDownloadRenderer,
          suppressSorting: true,
          suppressMenu: true
        }
      ]},
       {
          headerName: "Punchlist",
          marryChildren: true,
          children: [
            {
              headerName: "A",
              field: "punchlistCountA",
              cellRendererFramework: PunchlistDetailsBySystemComponent,
              minWidth: 5,
              suppressMenu: true
            },
            {
              headerName: "B",
              field: "punchlistCountB",
              cellRendererFramework: PunchlistDetailsBySystemComponent,
              minWidth: 5,
              suppressMenu: true
            },
            {
              headerName: "C",
              field: "punchlistCountC",
              cellRendererFramework: PunchlistDetailsBySystemComponent,
              minWidth: 5,
              suppressMenu: true
            }
          ]
        }
      ];

      this.columnDefsPunchlist = [
        {
          headerName: "Category",
          field: 'category',
          autoHeight: true, 
          cellClass: "cell-wrap-text",  
          minWidth: 100,
        },
        {
          headerName: "Asset",
          field: 'assetName',
          autoHeight: true, 
          cellClass: "cell-wrap-text",  
          minWidth: 100,
        },
        {
          headerName: "Description",
          field: 'plDescription',
          autoHeight: true, 
          cellClass: "cell-wrap-text",  
          minWidth: 300,
        },
        {
          headerName: "Completion Date",
          field: 'completionDate',
          autoHeight: true, 
          cellClass: "cell-wrap-text",  
          minWidth: 150,
        },
        {
          headerName: "System",
          field: 'subSystemName',
          autoHeight: true, 
          cellClass: "cell-wrap-text",  
          minWidth: 300,
        },
        {
          headerName: "Documents",
          field: 'documents',
          autoHeight: true,
          cellClass: "cell-wrap-text",
          cellStyle: {'white-space': 'normal' },
          minWidth: 200,
          cellRendererFramework: PunchListDocumentDownloadRenderer
        },
        {
          headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.PUNCHLIST_OPEN_SENT_PDF'),
          field: 'opensentpdf',
          autoHeight: true,
          cellClass: "cell-wrap-text",
          cellStyle: {'white-space': 'normal' },
          minWidth: 100,
          cellRendererFramework: PunchlistCellRenderComponent
        },
        {
          headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.PUNCHLIST_OPEN_RETURN_PDF'),
          field: 'openreturnedpdf',
          autoHeight: true,
          cellClass: "cell-wrap-text",
          cellStyle: {'white-space': 'normal' },
          minWidth: 100,
          cellRendererFramework: PunchlistCellRenderComponent
        },
        {
          headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.PUNCHLIST_CLOSE_SENT_PDF'),
          field: 'closesentpdf',
          autoHeight: true,
          cellClass: "cell-wrap-text",
          cellStyle: {'white-space': 'normal' },
          minWidth: 100,
          cellRendererFramework: PunchlistCellRenderComponent
        },
        {
          headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.PUNCHLIST_CLOSE_RETURN_PDF'),
          field: 'closereturnedpdf',
          autoHeight: true,
          cellClass: "cell-wrap-text",
          cellStyle: {'white-space': 'normal' },
          minWidth: 100,
          cellRendererFramework: PunchlistCellRenderComponent
        },
    ];

    this.getDataPath = function(data) {
      return data.orgHierarchy;
    };

    this.autoGroupColumnDef = {
      //headerName: "System",
      width:780,
      cellRendererParams: { suppressCount: true }
    };

    this.gridOptionsPunchlist = {
      rowData: this.rowDataPunchlist,
      columnDefs: this.columnDefsPunchlist,
      enableColResize: true,
      enableSorting: true,
      rowSelection: "single",
      rowDeselection: true,
      rowMultiSelectWithClick: false,
      animateRows: true,
      rowBuffer: 20,
      context: {
        componentParent: this
      },
  };

  }
  refresh(){
    this.getDataFromServer()
    this.isHidden = true;
  }

  public statusCellRenderer(param: any) {
    switch (param.value) {
      case 'Open':
        return '<span class="badge badge-warning px-2 py-1">Open</span>'
      case 'Closed':
        return '<span class="badge badge-success px-2 py-1">Closed</span>'
      default:
        return ''
    }
  }

  public viewPunchlistGrid(params){
    console.log(params)
    if(params.data != null && params.data != undefined){
      let systemId = params.data.systemId;
      let subSystemId = params.data.subsystemId;
      let assetId =  params.data.assetId;
      let category = params.colDef.headerName;
      this.filteredData = [];
      this.rowDataPunchlist = [];

      this.punchlistService.getPunchlistDetailsBySystem(systemId, subSystemId, assetId, category).subscribe(
        data => {
          console.log(JSON.stringify(data.payload));
          this.loadDataPunchlist(data.payload);
          this.loadingService.hideLoading();
        },
        error => {
          this.gridApiPunchlist.hideOverlay()
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
        });
          this.isHidden = false;
    }else{
      this.isHidden = true;
    }    
  }

  public getDataFromServer() {
    this.gridApi.showLoadingOverlay()
      this.systemService.getAllSystemDetailListSolr(UserVariable.projectId)
      .subscribe(
        data => {
          console.log(JSON.stringify(data.payload));
          this.loadData(data.payload);
        },
        error => {
          this.gridApi.hideOverlay()
          this.alertService.clear()
          this.alertService.error(error.statusDescription)
          this.gridApi.showLoadingOverlay()
        });
  }

  loadData(payload: any) {
    this.rowData = [];
    this.gridApi.hideOverlay()
    this.gridApi.showLoadingOverlay()
    for (let i = 0; i < payload.length; i++) {
      let orgList = [];
      if(payload[i]["system"] != null){
        orgList.push(payload[i]["system"]);
      }
      if(payload[i]["subsystem"] != null){
        orgList.push(payload[i]["system"] + "-" + payload[i]["subsystem"]);
      }
      if(payload[i]["parentAsset"] != null){
        orgList.push(payload[i]["parentAsset"]);
      }
      if(payload[i]["asset"] != null){
        orgList.push(payload[i]["asset"]);
      }

      this.rowData.push({
        orgHierarchy:  orgList,
        systemId: payload[i]["systemId"],
        //system: payload[i]["system"],
        subsystemId: payload[i]["subsystemId"],
        //subsystem: (payload[i]["subsystem"] != null || payload[i]["subsystem"] != undefined) ? (payload[i]["system"] + "-" + payload[i]["subsystem"]) : null,
        systemNumber: (payload[i]["subsystem"] != null || payload[i]["subsystem"] != undefined) ? (payload[i]["system"] + "-" + payload[i]["subsystem"]) : payload[i]["system"],
        //asset: (payload[i]["asset"] != null) ? payload[i]["asset"] : '',
        assetId: payload[i]["assetId"],
        //parentAsset: payload[i]["parentAsset"],
        otn: this.removeDuplicateValues(payload[i]["otnlist"], "otn"),
        irn: this.removeDuplicateValues(payload[i]["irnlist"], "irn"),
        stnptn: payload[i]["systemTurnoverNoticeModelList"],
        mcc: payload[i]["mechanicalCompletionModel"],
        shc: payload[i]["systemHandoverModel"],
        checkSheetList: this.removeDuplicateValues(payload[i]["checkSheets"], "checksheet"),
        punchlistCountA: payload[i]["punchlistCountA"],
        punchlistCountB: payload[i]["punchlistCountB"],
        punchlistCountC: payload[i]["punchlistCountC"]
			});
    }
    console.log("rowData",this.rowData);
    this.gridApi.hideOverlay()
  }

  /**
   * Achini Randeni
   * Remove duplicate IRN Number and Checksheet code
   * @param myArray 
   * @param type 
   */
  public removeDuplicateValues(myArray, type){ 
    var newArray = [];
    if(myArray != null){
      myArray.forEach(element1 => {
        var exists = false;
        newArray.forEach(element2 =>{
          if(type == "checksheet"){
            if(element2.checksheetId == element1.checksheetId){
              exists = true;
            }
          } else if(type == "irn"){
            if(element2.irnId == element1.irnId){
              exists = true;
            }
          } else if(type == "otn"){
            if(element2.id == element1.id){
              exists = true;
            }
          }
        });
        if(type == "checksheet"){
          //only display type A checksheets
          if(exists == false && element1.checksheetId != "" && element1.checkSheetType == 'A') { 
            newArray.push(element1); 
          }
        } else if(type == "irn"){
          if(exists == false && element1.irnId != "") { 
            newArray.push(element1); 
          }
        } else if(type == "otn"){
          if(exists == false && element1.id != "") { 
            newArray.push(element1); 
          }
        }
      });
    }
    return newArray;
  }

  loadDataPunchlist(payload: any) {
    this.rowDataPunchlist = [];
    this.gridApiPunchlist.hideOverlay()
    this.gridApiPunchlist.showLoadingOverlay()
    for (let i = 0; i < payload.length; i++) {
      let completionDate = ''
      let dateClosed = ''
      let dateRaised = ''
     
      if (payload[i]["estimatedCompletionDate"]) {
        completionDate = new Date(payload[i]["estimatedCompletionDate"]).toLocaleDateString()
      }
      if (payload[i]["dateClosed"]) {
        dateClosed = new Date(payload[i]["dateClosed"]).toLocaleDateString()
      }
      if (payload[i]["dateRaised"]) {
        dateRaised = new Date(payload[i]["dateRaised"]).toLocaleDateString()
      }
      
        this.rowDataPunchlist.push({
            category: payload[i]["category"] != null ? payload[i]["category"] : '',
            assetName: payload[i]["assetName"] != null ? this.decodeHTMLString.transform(payload[i]["assetName"]) : '',
            plDescription: payload[i]["punchlistDescription"] != null ? this.decodeHTMLString.transform(payload[i]["punchlistDescription"]) : '',
            completionDate: completionDate,
            subSystemName: payload[i]["subSystemName"] != null ? this.decodeHTMLString.transform(payload[i]["subSystemName"]) : '',
            documents: payload[i]["punchlistDocuments"],
            opensentpdf: "opensentpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
            openreturnedpdf: "openreturnedpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
            closesentpdf: "closesentpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
            closereturnedpdf: "closereturnedpdf:" + payload[i]["id"] + ":" + payload[i]["itemStatus"],
            docuSignStatus: payload[i]["docuSignStatus"],
            openPunchlistGroupId: payload[i]["openPunchlistGroupId"],
            closePunchlistGroupId: payload[i]["closePunchlistGroupId"],
            isOpenReturnPDFUploaded: payload[i]["isOpenReturnPDFUploaded"],
            isCloseReturnPDFUploaded: payload[i]["isCloseReturnPDFUploaded"],
        });
    }
  }

  ngOnInit() {

  }
  
  downloadCheckSheetSentFile(checksheetData:any) {
    let fileName = checksheetData.initialFileName; 
		fileName = fileName.substring(0, fileName.indexOf("."));
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadSentFile(UserVariable.projectId, checksheetData.checkSheetInstanceId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
        let file = new Blob([response], { type: 'application/pdf' });
        console.log(fileName)
				FileSaver.saveAs(file, checksheetData.initialFileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("Could not download the file.");
			});
	}

	downloadCheckSheetApprovedFile(checksheetData:any) {
		let fileName = checksheetData.approvedFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadApprovedFile(UserVariable.projectId, checksheetData.checkSheetInstanceId, fileName)
			.subscribe(response => {
        console
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, checksheetData.approvedFileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("Could not download the file.");
			});
  }
  
  downloadSTNPTNFile(selectedVendor, params) {
    this.loadingService.showLoading(false, false, '', 0)
		this.systemTurnoverNoticeService.downloadFile(UserVariable.projectId, selectedVendor.stnId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "STN_" + params.data.systemNumber + " " + selectedVendor.systemName + "_" + selectedVendor.vendorName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error("Could not download the file.");
			});
  }
  
  downloadMCCFile(params) {
    this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.downloadFile(UserVariable.projectId, params.data.mcc.mcId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "MCC_" + params.data.systemNumber + " " + params.data.mcc.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
        this.alertService.clear()
        this.alertService.error("Could not download the file.");
			});
  }
  
  downloadSHCFile(params) {
    this.loadingService.showLoading(false, false, '', 0)
		this.systemHandoverWidgetService.downloadFile(UserVariable.projectId, params.data.shc.shcId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "SHC_" + params.data.shc.targetCompletionDate + "_" + params.data.shc.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("Could not download the file.")
			});
  }
  
  downloadDocument(doc:any) {
    let fileExtention = doc.contentType;
    let outputId = doc.id;
    let outputFile = null;
    let punchlistId = doc.punchlistId;
    let projectId = doc.projectId;
    let fileName = doc.fileName;
  
    this.loadingService.showLoading(false, false, '', 0);
    this.punchlistService.downloadFile(outputId, punchlistId, projectId, fileExtention, fileName)
    .subscribe(response => {
      console
          this.loadingService.hideLoading()
          let file = new Blob([response], { type: 'application/pdf' });
          //let file = new Blob([response], { type: response.type });
          FileSaver.saveAs(file, fileName);
        },
        error => {
          this.loadingService.hideLoading()
          this.alertService.clear()
          this.alertService.error("Could not download the file.");
        });
  }

  downloadIRNFile(irn:any) {
    this.loadingService.showLoading(false, false, '', 0)
    this.inspectionReleaseNoticeService.downloadFile(UserVariable.projectId, irn.irnId, irn.irnNumber)
    .subscribe(response => {
      this.loadingService.hideLoading()
      let file = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(file, "IRN_" + irn.irnNumber + ".pdf");
    },
    error => {
      this.loadingService.hideLoading()
      this.alertService.clear()
      this.alertService.error("Could not download the file.")
    });
  }

  /**
   * Download OTN returned PDF
   * @param otnId 
   * @param vendorName 
   */
  downloadOTNApprovedFile(otnId: string, vendorName: string) {
    this.loadingService.showLoading(false, false, '', 0)
    this.onshoreTurnoverNoticeService.downloadApprovalFile(UserVariable.projectId, otnId)
    .subscribe(response => {
      this.loadingService.hideLoading()
      let file = new Blob([response], { type: 'application/pdf' });
      FileSaver.saveAs(file, "OTN_" + vendorName + ".pdf");
    },
    error => {
      this.loadingService.hideLoading()
      this.alertService.clear()
      this.alertService.error("Could not download the file.")
    });
  }

async onGridReady(params) {
  this.gridApi = params.api
  this.gridColumnApi = params.columnApi;
  //this.rowData = await this.getDataFromServer();
  params.api.sizeColumnsToFit();
  setTimeout(() => {
    params.api.sizeColumnsToFit();
  }, 500);
  window.addEventListener("resize", function () {
    setTimeout(function () {
      params.api.sizeColumnsToFit();
    });
  });
  this.getDataFromServer()
  setTimeout(function() {
    var rowCount = 0;
    params.api.forEachNode(function(node) {
      node.setExpanded(rowCount++ === 1);
    });
  }, 500);
        
}

async onGridReadyPunchlist(params) {
  this.gridApiPunchlist = params.api;
  this.gridColumnApiPunchlist = params.columnApi;
  params.api.sizeColumnsToFit();
  setTimeout(() => {
    params.api.sizeColumnsToFit();
  }, 500);
  window.addEventListener("resize", function () {
    setTimeout(function () {
      params.api.sizeColumnsToFit();
    });
  });
}
}