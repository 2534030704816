import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommCompletionService } from '../../../comm-completion.service'
import { GridOptions } from "ag-grid";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { DocusignPesonStatusComponent } from "../../../docusign-peson-status/docusign-peson-status.component"


@Component({
  selector: 'app-mechanical-completion-docu-sign',
  templateUrl: './mechanical-completion-docu-sign.component.html',
  styleUrls: ['./mechanical-completion-docu-sign.component.css']
})
export class MechanicalCompletionDocuSignRenderer implements ICellRendererAngularComp {

	@ViewChild('docusignTemplate') docusignTemplate: TemplateRef<any>
	type: string;
	
	
	refresh(): boolean {
		return false;
	}

	params: any;
	docuSignId: any;
	system: any;
	status: any;
	docuSignStatus: any = "";
	modalRef: BsModalRef;
	// isLoading: boolean = true;
	isPresent: boolean = false;
	public gridOptions: GridOptions;
	columnDefs: any[];
	public rowData = [];
	gridParams: any;
	workflow: boolean = false;


	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}
	
	constructor(private modalService: BsModalService){}

	agInit(params: any): void{
		this.gridParams = params;
		//if params.value == true or false it is workflow coloumn
		//if params.value == true it shows workflow start button
		if(params.value == true){
			this.workflow = true
		}else{
			//show docusign status
			// console.log("===docusing renderer if(params != null)=" + params);
			this.docuSignStatus = this.statusRender(params.data.docuSignStatus)	
			this.isPresent = true;
			this.docuSignId = params.data.docuSignId;
			this.system = params.data.systemName;
		}
		
	}

	public startWorkflow() {
		this.gridParams.context.componentParent.iconClicked = true
		this.gridParams.context.componentParent.startWorkflow(this.gridParams)
	}

	public statusRender(status: any) {
		switch (status) {
			case 'waiting':
				return '<span class="badge badge-warning px-2 py-1">Waiting</span>'
			case 'completed':
				return '<span class="badge badge-success px-2 py-1">Completed</span>'
			case 'sent':
				return '<span class="badge badge-warning px-2 py-1">Sent</span>'
			case 'declined':
				return '<span class="badge badge-danger px-2 py-1">Declined</span>'
			case 'delegated':
				return '<span class="badge badge-danger px-2 py-1">Delegated</span>'
			case 'error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			case 'failed':
				return '<span class="badge badge-danger px-2 py-1">Failed</span>'
			default:
				return ''
		}
	}

	viewPersonStatus(){
		// this.getPersonStatus();
		this.type = 'MECHANICAL_COMPLETION'
		event.stopPropagation();
		this.modalRef = this.modalService.show(this.docusignTemplate, this.modalConfig);
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
	}

	
}
