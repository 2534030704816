import { Component } from "@angular/core";

@Component({
    selector: 'app-addhoc-reports-cell-render-component',
    template: '<button (click)=viewReport() class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="{{icon}}" aria-hidden="true"></i>' +
	'</button>'
})

export class AdhocReportsCellRenderComponent {
    public params: any;
    public icon: any;

	agInit(params: any): void {
		this.params = params;
        switch (params.column.colId) {
            case "pdf":
                this.icon = "fas fa-file-pdf";
                break;
            case "xlsx":
                this.icon = "fas fa-file-spreadsheet";
                break;
            case "csv":
                this.icon = "fas fa-file-csv";
                break;
            case "html":
                this.icon = "fas fa-file-code";
                break;
        
            default:
                break;
        }
        console.log(params);
	}
    
    refresh(): boolean {
		return false;
	}

    public viewReport() {
		this.params.context.componentParent.viewReport(this.params.data.id,
            this.params.data.reportName, this.params.column.colId);
	}
}