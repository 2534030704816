import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import { AlertService } from 'src/app/util/alert/alert.service';
import { SharedService } from 'src/app/util/shared.service';
import { OtnRundownWidgetService } from './otn-rundown-widget.service';

@Component({
    selector: 'app-otn-rundown-widget',
    templateUrl: './otn-rundown-widget.component.html',
    styleUrls: ['./otn-rundown-widget.component.css']
})
export class OtnRundownWidgetComponent implements OnInit {

    chart: Chart
    public isMinimized: boolean = false
    public vendorList: Array<any> = []
    public assetList: Array<any> = []
    public selectedVendor: Array<any> = [{ 'id': "ALL", 'text': 'ALL' }]
    public selectedAsset: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
    public today = new Date()
    public dateInt: any;
    vendorId: any
    assetId: any
    private translatedData: any = null
    public isLoadingData: boolean = true
    public isNoDataToShow: boolean = false
    public showFilterOptions: boolean = false
    constructor(private otnRundownService: OtnRundownWidgetService, private translate: TranslateService,
        private alertService: AlertService, private sharedService: SharedService) {
        this.dateInt = this.today.setUTCHours(0, 0, 0, 0)

        let browserLang = translate.getBrowserLang()
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.translatedData = res['OTN_RUNDOWN']
            this.initChart()
            this.getOTNRundownData('ALL', "ALL")
        })
    }

    ngOnInit() {
		this.otnRundownService.getOTNAssetVendorList().subscribe(
			data => {
				this.vendorList = []
				this.vendorList.push({ 'id': "ALL", 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.vendorList.push({ "id": element.vendorId, "text": element.vendorName });
					})
				} else {
					//do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
			});
		this.otnRundownService.getOTNAssetList().subscribe(
			data => {
				this.assetList = []
				this.assetList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.assetList.push({ 'id': element.tagId, 'text': element.tagNo })
					});
				} else {
					// do nothing
				}
			},
			error => {
				this.alertService.error(error.statusDescription)
			});
    }


    public initChart() {
        this.chart = new Chart({
            chart: {
                type: 'line',
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 0,
                        y: -10
                    }
                }
            },
            title: {
                text: null
            },
            credits: {

                enabled: false
            },
            xAxis: {
                title: {
                    text: this.translatedData.DATE
                },
                type: 'datetime',
                tickWidth: 1,
                labels: {
                    enabled: true,
                    rotation: -45,
                    format: '{value:%Y-%b-%d}'
                },
                plotLines: [{
                    color: '#757575', // Color value
                    dashStyle: 'ShortDash', // Style of the plot line. Default to solid
                    value: this.dateInt, // Value of where the line will appear
                    width: 2, // Width of the line   
                    label: {
                        text: this.translatedData.TODAY,
                        align: 'center',
                        y: 40,
                    }
                }]
            },
            yAxis: [{ // left y axis	
                gridLineWidth: 1,
                tickWidth: 1,
                minTickInterval: 1,
                title: {
                    text: this.translatedData.NUM_OF_ASSETS
                }
            }],
            plotOptions: {
                line: {
                    marker: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                crosshairs: true,
                shared: true,
                xDateFormat: '%Y-%b-%d',
                useHTML: true
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: this.translatedData.WIDGET_NAME
                    }
                }
            }

        });
    }

    getOTNRundownData(assetId: any, vendorId: any) {
        this.isLoadingData = true;
        this.isNoDataToShow = false;
        this.otnRundownService.getOTNRundownData(assetId, vendorId).subscribe(
            data => {
				let plannedOTNData: any = [];
				let remainingOTNData: any = [];

				if (data.payload != null) {
					if (data.payload.otnremain.length != 0) {
						data.payload.otnremain.forEach(element => {                 
							let formatDate = new Date(element.date).getTime()
							remainingOTNData.push([formatDate, element.otnRundown])
						});
					} else {
						// do nothing no remaining items
					}
					if (data.payload.otnplan.length != 0) {
						data.payload.otnplan.forEach(element => {
							let formatDate = new Date(element.date).getTime()
							plannedOTNData.push([formatDate, element.otnRundown])
						});
					} else {
						// do nothing no plan items
					}
					if (plannedOTNData.length == 0 && remainingOTNData.length == 0) {
						//do data to show
						this.isLoadingData = false
						this.isNoDataToShow = true
					} else {
						//remove series from the chart before adding a series
						this.removeSerie()
						this.addSeriesToChart(plannedOTNData, remainingOTNData)
					}
				} else {
					//do not have data to show
					this.isLoadingData = false
					this.isNoDataToShow = true
				}

            },
            error => {
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
            }
        );

    }

	public addSeriesToChart(planSeries: any, remainSeries: any) {
		this.chart.addSerie({
			name: this.translatedData.PLAN_ASSETS,
			color: '#1E88E5',
			marker: {
				symbol: 'dot'
			},
			lineWidth: 2,
			data: planSeries,
		})
		this.chart.addSerie({
			name: this.translatedData.REMAINING_ASSETS,
			color: '#E53935',
			marker: {
				symbol: 'dot'
			},
			lineWidth: 2,
			data: remainSeries
		})
		this.isLoadingData = false
	}

	public removeSerie() {
		let num = this.chart.ref.series.length;
		for (let i = 0; i < num; i++) {
			this.chart.removeSerie(0);
		}
	}
    
	public removedVendor(value: any): void {
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.removeSerie();
		let assetId = 'ALL'
		if (this.selectedAsset.length != 0) {
			assetId = this.selectedAsset[0].id
		}
		this.getOTNRundownData(assetId, this.selectedVendor[0].id)
	}

	public selectedVendorId(value: any): void {
		this.removeSerie()
		this.selectedVendor = []
		this.selectedVendor.push(value)
		if (value.id == 'ALL') {
			this.getOTNRundownData(this.selectedAsset[0].id, this.selectedVendor[0].id)
		} else {
			this.getOTNRundownData(this.selectedAsset[0].id, value.id)
		}

	}

	public removedAsset(value: any): void {
		this.selectedAsset = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.removeSerie()
		let vendorId = 'ALL'
		if (this.selectedVendor.length != 0) {
			vendorId = this.selectedVendor[0].id
		}
		this.getOTNRundownData(this.selectedAsset[0].id, vendorId)
	}

	public selectedAssetId(value: any): void {
		this.removeSerie()
		this.selectedAsset = []
		this.selectedAsset.push(value)
		let vendorId = 'ALL'
		if (this.selectedVendor.length != 0) {
			vendorId = this.selectedVendor[0].id
		}
		this.getOTNRundownData(this.selectedAsset[0].id, vendorId)
	}

	public refresh() {
		this.initChart()
		this.selectedAsset = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.getOTNRundownData('ALL', 'ALL')
	}
}
