import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { PunchlistService } from '../punchlist.service';

@Component({
	selector: 'app-punchlist-items-per-category',
	templateUrl: './punchlist-items-per-category.component.html',
	styleUrls: ['./punchlist-items-per-category.component.css']
})
export class PunchlistItemsPerCategoryComponent implements OnInit {
	public isLoadingData: boolean = false
	public isMinimized: boolean = false;
	public isHeader: boolean = false;
	public punchlistItemsPerCategory: number = 0;
	public punchlistType: any;
	public list: Array<any> = []
	itemName: any;
	public showFilterOptions: boolean = false;
	public selectedItem: Array<any> = []
	punchlistItems_translated_data: any = null;

	constructor(private punchlistService: PunchlistService, private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService) {
		let browserLang = translate.getBrowserLang();
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.punchlistItems_translated_data = res['PUNCHLIST_ITEMS_PER_CATEGORY']
			this.list = [
				{ "id": "OpenAPLCount", 'text': this.punchlistItems_translated_data.OPENAPLCOUNT },
				{ "id": "OpenBPLCount", 'text': this.punchlistItems_translated_data.OPENBPLCOUNT },
				{ "id": "OpenCPLCount", 'text': this.punchlistItems_translated_data.OPENCPLCOUNT },
				{ "id": "OpenDPLCount", 'text': this.punchlistItems_translated_data.OPENDPLCOUNT }]

		});
	}

	ngOnInit() {
		this.punchlistType = 'A'
		this.loadData()
	}

	loadData() {
		this.isLoadingData = true
		this.punchlistService.getPunchListCount(this.punchlistType).subscribe(
			data => {
				this.isLoadingData = false
				this.punchlistItemsPerCategory = data.payload;
			},
			error => {
				this.isLoadingData = false
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	public selectedItemName(params) {
		this.showFilterOptions = false
		this.itemName = params.id;		

		if (this.itemName == "OpenAPLCount") {
			this.punchlistType = "A";
		}
		if (this.itemName == "OpenBPLCount") {
			this.punchlistType = "B";
		}
		if (this.itemName == "OpenCPLCount") {
			this.punchlistType = "C";
		}
		if (this.itemName == "OpenDPLCount") {
			this.punchlistType = "D";
		} else {
			//do nothing
		}
		this.loadData()
	}

	public changeItemType(typeName: string) {
		this.showFilterOptions = false
		this.itemName = typeName;		

		if (this.itemName == "OpenAPLCount") {
			this.punchlistType = "A";
		}
		if (this.itemName == "OpenBPLCount") {
			this.punchlistType = "B";
		}
		if (this.itemName == "OpenCPLCount") {
			this.punchlistType = "C";
		}
		if (this.itemName == "OpenDPLCount") {
			this.punchlistType = "D";
		} else {
			//do nothing
		}
		this.loadData()
	}

	removedItem(event) {
		this.punchlistType = "A";
		this.loadData()
	}


}
