<div>
	<div class="loading-panel center">
		<div class="center">
			<tr>
				<img src="../../../assets/images/mainimage.png" alt="" width="auto" height="auto">
			</tr>
			<tr class="center">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</tr>
		</div>
	</div>
</div>