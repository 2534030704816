import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { UserVariable } from '../util/common/user-variable';

import { InspectionReleaseNoticeComponent } from './inspection-release-notice/inspection-release-notice.component';
import { Sample2chartComponent } from './sample2chart/sample2chart.component';
import { SamplebarchartComponent } from './samplebarchart/samplebarchart.component';
import { SamplepiechartComponent } from './samplepiechart/samplepiechart.component';
import { Widget } from './widget.model';
import { MechanicalCompletionWidgetComponent } from './mechanical-completion-widget/mechanical-completion-widget.component';
import { OpenWorkItemsComponent } from './open-work-items/open-work-items.component';
import { PunchlistPiechartComponent } from './punchlist-open-punchlist/punchlist-open-punchlist.component';
import { SystemTurnoverNoticeComponent } from './system-turnover-notice/system-turnover-notice.component';
import { PunchlistDetailWidgetComponent } from './punchlist-detail-widget/punchlist-detail-widget.component';
import { StnptnRundownWidgetComponent } from './stnptn-rundown-widget/stnptn-rundown-widget.component';
import { McChecksheetRundownWidgetComponent } from './mc-checksheet-rundown-widget/mc-checksheet-rundown-widget.component';
import { ControlledDocumentsWidgetComponent } from './controlled-documents-widget/controlled-documents-widget.component';
import { SystemHandoverWidgetComponent } from './system-handover-widget/system-handover-widget.component';
import { MccBehindScheduledWidgetComponent } from './mcc-behind-scheduled-widget/mcc-behind-scheduled-widget.component';
import { McSystemSkylineWidgetComponent } from './mc-system-skyline-widget/mc-system-skyline-widget.component';
import { MechanicalCompletionDetailWidgetComponent } from './mechanical-completion-detail-widget/mechanical-completion-detail-widget.component';
import { FacilityAcceptanceComponent } from './facility-acceptance/facility-acceptance.component';
import { PrecommissioningSystemSkylineWidgetComponent } from './precommissioning-system-skyline-widget/precommissioning-system-skyline-widget.component';
import { PrecommissioningDetailWidgetComponent } from './precommissioning-detail-widget/precommissioning-detail-widget.component';
import { WorkItemSkylineWidgetComponent } from './work-item-skyline-widget/work-item-skyline-widget.component';
import { PrecommissioningChecksheetRundownWidgetComponent } from './precommissioning-checksheet-rundown-widget/precommissioning-checksheet-rundown-widget.component';
import { CommCompletionWidgetComponent } from './comm-completion-widget/comm-completion-widget.component';
import { ProceduresInStoreCountWidgetComponent } from './procedures-in-store-count-widget/procedures-in-store-count-widget.component';
import { ProceduresInTheStoreWidgetComponent } from './procedures-in-the-store-widget/procedures-in-the-store-widget.component';
import { ProceduresInstancesWidgetComponent } from './procedures-instances-widget/procedures-instances-widget.component';
import { ProceduresExecutedByTimeWidgetComponent } from './procedures-executed-by-time-widget/procedures-executed-by-time-widget.component';
import { PunchlistItemComponent } from './punchlist-item/punchlist-item.component';
import { PunchlistItemsPerCategoryComponent } from './punchlist-items-per-category/punchlist-items-per-category.component';
import { ProceduresExecutedCountWidgetComponent } from './procedures-executed-count-widget/procedures-executed-count-widget.component';
import { MyOpenWorkItemCountComponent } from './my-open-work-item-count/my-open-work-item-count.component';
import { ChecksheetsWidgetComponent } from './checksheets-widget/checksheets-widget.component';
import { DocumentationTagCountComponent } from './documentation-tag-count/documentation-tag-count.component';
import { OpenWorkItemsPiechartComponent } from './work-items-piechart/work-items-piechart.component';
import { DocumentationUniqueStatementCountComponent } from './documentation-unique-statement-count/documentation-unique-statement-count.component';
import { DocumentationTotalRevisionsComponent } from './documentation-total-revisions/documentation-total-revisions.component';
import { DocumentationPIdComponent } from './documentation-p-id/documentation-p-id.component';
import { PccBehindScheduledWidgetComponent } from './pcc-behind-scheduled-widget/pcc-behind-scheduled-widget.component';
import { PunchlistBySystemWidgetComponent } from './punchlist-by-system-widget/punchlist-by-system-widget.component';
import { SystemCompletionStatusWidgetComponent } from './system-completion-status-widget/system-completion-status-widget.component';
import { TopicMapsInStoreCountWidgetComponent } from './topicmaps-in-store-count-widget/topicmaps-in-store-count-widget.component';
import { TopicMapsInTheStoreWidgetComponent } from './topicmaps-in-the-store-widget/topicmaps-in-the-store-widget.component';
import { OnshoreTurnoverNoticeWidgetComponent } from './onshore-turnover-notice-widget/onshore-turnover-notice-widget.component';
import { BulkDataImportWidgetComponent } from './bulk-data-import-widget/bulk-data-import-widget.component';
import { AdhocReportsWidgetComponent } from './adhoc-reports-widget/adhoc-reports-widget.component';
import { OtnRundownWidgetComponent } from './otn-rundown-widget/otn-rundown-widget.component';
import { IrnRundownWidgetComponent } from './irn-rundown-widget/irn-rundown-widget.component';

@Injectable({
	providedIn: 'root'
})
export class WidgetService {

	private dashBoardWidgetList = [
		{
			widgetId: 'stn-01',
			widgetName: 'STN/PTN Run Down',
			component: 'stnptn-rundown-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'stn-02',
			widgetName: 'System Turnover Notice',
			component: 'stn',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]
	private irnWidgetList = [
		{
			widgetId: 'irn-01',
			widgetName: 'IRN Run Down',
			component: 'irn-rundown-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: '004',
			widgetName: 'IRN Report',
			component: 'irn',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private mcWidgetList = [
		{
			widgetId: 'mc-01',
			widgetName: 'MCC Behind Scheduled',
			component: 'mcc-behind-scheduled-widget',
			widgetSize: 'col-lg-4',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'primary'
		},
		{
			widgetId: 'mc-02',
			widgetName: 'Mechanical Completion Checksheet Run Down',
			component: 'mc-checksheet-rundown-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},   
		{
			widgetId: 'mc-03',
			widgetName: 'Checksheets Widget',
			component: 'checksheets-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'mc-04',
			widgetName: 'Mechanical Completion System Skyline',
			component: 'mc-system-skyline',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'mc-05',
			widgetName: 'Mechanical Completion',
			component: 'mechanical-completion-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}		
	]
	private precommWidgetList = [
		{
			widgetId: 'pc-04',
			widgetName: 'PCC Behind Scheduled',
			component: 'pcc-behind-scheduled-widget',
			widgetSize: 'col-lg-4',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: false,
			bodyType: 'primary'
		},
		{
			widgetId: 'pc-01',
			widgetName: 'Precommissioning System Skyline',
			component: 'precommissioning-system-skyline-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'pc-02',
			widgetName: 'Precommissioning',
			component: 'precommissioning-detail-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'pc-03',
			widgetName: 'Precommissioning CheckSheet Rundown Report',
			component: 'precommissioning-checksheet-rundown-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
		
	]
	private punchWidgetList = [
		{
			widgetId: 'p-01',
			widgetName: 'Open Punchlists',
			component: 'punchlist-piechart',
			widgetSize: 'col-lg-9',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}, {
			widgetId: 'p-03',
			widgetName: 'Punchlist Item',
			component: 'punchlist-item',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'p-02',
			widgetName: 'Punchlist Items Per Category',
			component: 'punchlist-items-per-category',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'p-04',
			widgetName: 'Punchlist Details',
			component: 'punchlist-detail-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]
	private stnptnWidgetList = [
		{
			widgetId: 'stn-01',
			widgetName: 'STN/PTN Run Down',
			component: 'stnptn-rundown-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'stn-02',
			widgetName: 'System Turnover Notice',
			component: 'stn',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private defaultList = [

	]

	private documentationWidgetList = [
		{
			widgetId: '001',
			widgetName: 'Tag Count',
			component: 'documentation-tag-count',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: '002',
			widgetName: 'Unique Statement',
			component: 'documentation-unique-statement-count',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: '003',
			widgetName: 'Total Revisions',
			component: 'documentation-total-revisions',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: '004',
			widgetName: 'P&ID',
			component: 'documentation-p-id',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: '005',
			widgetName: 'Controlled Documents',
			component: 'controlled-documents-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private checksheetWidgetList = [
	]

	private bulkimportWidgetList = [
		{
			widgetId: 'bi-01',
			widgetName: 'Bulk Import Widget',
			component: 'bulk-data-import-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private systemHandoverWidgetList = [
		{
			widgetId: '001',
			widgetName: 'SHC Details',
			component: 'system-handover-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private facilityAcceptanceWidgetList = [
		{
			widgetId: 'fa-001',
			widgetName: 'Facility Acceptance',
			component: 'facility-acceptance',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private workItemWidgetList = [
		{
			widgetId: 'wi-04',
			widgetName: 'My Open Work Item Count',
			component: 'my-open-work-item-count',
			widgetSize: 'col-lg-6',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'wi-01',
			widgetName: 'Work Item Skyline',
			component: 'work-item-skyline-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'wi-02',
			widgetName: 'My Open Work Items',
			component: 'open-work-items-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'wi-03',
			widgetName: 'Open Work Items Piechart',
			component: 'open-work-items-piechart',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
		
	]

	private ccWidgetList = [
		{
			widgetId: 'cc-01',
			widgetName: 'Commissioning Completion',
			component: 'comm-completion-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private proceduresWidgetList = [
		{
			widgetId: 'p-01',
			widgetName: 'Executed Procedures',
			component: 'procedures-executed-count-widget',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'p-02',
			widgetName: 'In Store Count',
			component: 'topicmaps-in-store-count-widget',
			widgetSize: 'col-lg-3',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'p-03',
			widgetName: 'Executed by Time',
			component: 'procedures-executed-by-time-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'p-04',
			widgetName: 'Instances',
			component: 'procedures-instances-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'p-05',
			widgetName: 'In The Store',
			component: 'topicmaps-in-the-store-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]
	private overviewWidgetList = [
		{
			widgetId: 'p-01',
			widgetName: 'Punchlist By System Widget',
			component: 'punchlist-by-system-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'cc-02',
			widgetName: 'System Completion Status',
			component: 'system-completion-status-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private otnWidgetList = [
		{
			widgetId: 'otn-01',
			widgetName: 'OTN Run Down',
			component: 'otn-rundown-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		},
		{
			widgetId: 'otn-02',
			widgetName: 'Onshore Turnover Notice',
			component: 'onshore-turnover-notice-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	private adhocReportsWidgetList = [
		{
			widgetId: 'ahr-01',
			widgetName: 'Ad Hoc Reports',
			component: 'adhoc-reports-widget',
			widgetSize: 'col-lg-12',
			isMinimized: false,
			canRefresh: true,
			hasConfig: false,
			hasHeader: true,
			bodyType: 'normal'
		}
	]

	constructor(private http: HttpClient) { }

	public loadComponentByComponentName(componentName: string): any {
		switch (componentName) {
			case 'sample-chart-line':
				return Sample2chartComponent
			case 'sample-chart-bar':
				return SamplebarchartComponent
			case 'punchlist-piechart':
				return PunchlistPiechartComponent
			case 'irn':
				return InspectionReleaseNoticeComponent
			case 'mechanical-completion-widget':
				return MechanicalCompletionWidgetComponent
			case 'my-open-work-item-count':
				return MyOpenWorkItemCountComponent
			case 'open-work-items-piechart':
				return OpenWorkItemsPiechartComponent
			case 'stn':
				return SystemTurnoverNoticeComponent
			case 'punchlist-detail-widget':
				return PunchlistDetailWidgetComponent
			case 'punchlist-item':
				return PunchlistItemComponent
			case 'punchlist-items-per-category':
				return PunchlistItemsPerCategoryComponent
			case 'stnptn-rundown-widget':
				return StnptnRundownWidgetComponent
			case 'mc-checksheet-rundown-widget':
				return McChecksheetRundownWidgetComponent
			case 'controlled-documents-widget':
				return ControlledDocumentsWidgetComponent
			case 'system-handover-widget':
				return SystemHandoverWidgetComponent
			case 'open-work-items-widget':
				return OpenWorkItemsComponent
			case 'mcc-behind-scheduled-widget':
				return MccBehindScheduledWidgetComponent
			case 'mc-system-skyline':
				return McSystemSkylineWidgetComponent
			case 'mechanical-completion-detail-widget':
				return MechanicalCompletionDetailWidgetComponent
			case 'facility-acceptance':
				return FacilityAcceptanceComponent
			case 'precommissioning-system-skyline-widget':
				return PrecommissioningSystemSkylineWidgetComponent
			case 'precommissioning-detail-widget':
				return PrecommissioningDetailWidgetComponent
			case 'work-item-skyline-widget':
				return WorkItemSkylineWidgetComponent
			case 'precommissioning-checksheet-rundown-widget':
				return PrecommissioningChecksheetRundownWidgetComponent
			case 'comm-completion-widget':
				return CommCompletionWidgetComponent
			case 'procedures-in-store-count-widget':
				return ProceduresInStoreCountWidgetComponent
			case 'topicmaps-in-store-count-widget':
				return TopicMapsInStoreCountWidgetComponent
			case 'topicmaps-in-the-store-widget':
				return TopicMapsInTheStoreWidgetComponent
			case 'procedures-in-the-store-widget':
				return ProceduresInTheStoreWidgetComponent
			case 'procedures-instances-widget':
				return ProceduresInstancesWidgetComponent
			case 'procedures-executed-by-time-widget':
				return ProceduresExecutedByTimeWidgetComponent
			case 'procedures-executed-count-widget':
				return ProceduresExecutedCountWidgetComponent
			case 'checksheets-widget':
				return ChecksheetsWidgetComponent
			case 'bulk-data-import-widget':
				return BulkDataImportWidgetComponent
			case 'documentation-tag-count':
				return DocumentationTagCountComponent
			case 'documentation-unique-statement-count':
				return DocumentationUniqueStatementCountComponent
			case 'documentation-total-revisions':
				return DocumentationTotalRevisionsComponent
			case 'documentation-p-id':
				return DocumentationPIdComponent
			case 'pcc-behind-scheduled-widget':
				return PccBehindScheduledWidgetComponent
			case 'punchlist-by-system-widget':
				return PunchlistBySystemWidgetComponent
			case 'system-completion-status-widget':
				return SystemCompletionStatusWidgetComponent
			case 'onshore-turnover-notice-widget':
				return OnshoreTurnoverNoticeWidgetComponent
			case 'adhoc-reports-widget':
				return AdhocReportsWidgetComponent
			case 'otn-rundown-widget':
				return OtnRundownWidgetComponent
			case 'irn-rundown-widget':
				return IrnRundownWidgetComponent
		}
	}

	public loadWidgetListByNumber(selectedItem: string): any {
		switch (selectedItem) {
			case 'dashboard':
				return this.dashBoardWidgetList
			case 'com_MC':
				return this.mcWidgetList
			case 'com_PC':
				return this.precommWidgetList
			case 'com_PL':
				return this.punchWidgetList
			case 'com_IRN':
				return this.irnWidgetList
			case 'com_SH':
				return this.systemHandoverWidgetList
			case 'com_STNPTN':
				return this.stnptnWidgetList
			case 'com_CS':
				return this.checksheetWidgetList
			case 'com_Import':
				return this.bulkimportWidgetList
			case 'documentation':
				return this.documentationWidgetList
			case 'overview':
				return this.overviewWidgetList
			case 'com_FA':
				return this.facilityAcceptanceWidgetList
			case 'com_WI':
				return this.workItemWidgetList
			case 'com_CC':
				return this.ccWidgetList
			case 'procedures':
				return this.proceduresWidgetList
			case 'com_OTN':
				return this.otnWidgetList
			case 'adhoc_reports':
				return this.adhocReportsWidgetList
			default:
				return this.defaultList
		}
	}

	public getProjectDashboard(widgetListName: any): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set('token', token).set('widgetListName', widgetListName) }

		return this.http.get(ServiceUrls.getProjectDashboardDetails(widgetListName), options)
			.pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res)
				})
			);
	}
}
