import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';


@Injectable({
  providedIn: 'root'
})
export class BulkDataImportWidgetService {

  public token: string;

  constructor(private http: HttpClient) {

  }

  /**
   * Import excel and update commissioning data
   * @param fileUploadData 
   * @returns 
   */
  public uploadCOMMData(fileUploadData: FormData): Observable<WsResponse> {
    return this.http.post(ServiceUrls.IMPORT_BULK_COMM_DATA, fileUploadData).pipe(
      map(response => {
        var modified = JSON.parse(JSON.stringify(response));
        return new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
      }),
      catchError(error => {
        var modified = JSON.parse(JSON.stringify(error.error));
        var res = new WsResponse(
          modified.status.description,
          modified.status.code,
          modified.status.name,
          modified.payload);
        return throwError(res)
      })
    );
  }
}
