import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, Highcharts } from 'angular-highcharts';

import { StnptnRundownWidgetService } from './stnptn-rundown-widget.service';
import { SystemTurnoverNoticeService } from '../system-turnover-notice/system-turnover-notice.service';
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { SharedService } from '../../util/shared.service';
import * as moment from 'moment-timezone';
import { CommonUtil } from '../../util/common/common-utils'
import { LocalStorage } from '../../util/localstorage.service';

@Component({
	selector: 'app-stnptn-rundown-widget',
	templateUrl: './stnptn-rundown-widget.component.html',
	styleUrls: ['./stnptn-rundown-widget.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class StnptnRundownWidgetComponent implements OnInit {

	chart: Chart
	public isMinimized: boolean = false
	public vendorList: Array<any> = []
	public systemList: Array<any> = []
	public selectedVendor: Array<any> = [{ 'id': "ALL", 'text': 'ALL' }]
	public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public today = new Date()
	public dateInt: any;
	vendorId: any
	systemId: any
	private translatedData: any = null
	public isLoadingData: boolean = true
	public isNoDataToShow: boolean = false
	public showFilterOptions: boolean = false

	constructor(private stnpntRundownService: StnptnRundownWidgetService, private translate: TranslateService,
		private systemTurnoverNoticeService: SystemTurnoverNoticeService, private alertService: AlertService,
		private systemService: SystemService, private sharedService: SharedService) {
		this.dateInt = this.today.setUTCHours(0, 0, 0, 0)

		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['STN_PTN_RUNDOWN']
			this.initChart()
			this.getSTNPTNData('ALL', "ALL")
		})

	}

	ngOnInit() {
		this.vendorId = "ALL"
		this.systemId = "ALL"
		this.systemTurnoverNoticeService.getAllVendorList().subscribe(
			data => {
				this.vendorList = []
				this.vendorList.push({ 'id': "ALL", 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.vendorList.push({ "id": element.vendorId, "text": element.vendorName });
					})
				} else {
					//do nothing
				}
			},
			error => {
				console.log('[getAllVendorList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			});
		this.systemService.getAllSystemList().subscribe(
			data => {
				this.systemList = []
				this.systemList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.systemList.push({ 'id': element.id, 'text': element.systemNo + ' - ' + element.systemName })
					});
				} else {
					// do nothing
				}
			},
			error => {
				console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			});

        //  Highcharts.setOptions({   // This is for all plots, change Date axis to local timezone
        //     global: {
        //         getTimezoneOffset: function (timestamp) {
        //             var chartTimezone = localStorage.getItem(LocalStorage.TIMEZONE);
        //             var timezoneOffset = 300;
        //             if (chartTimezone != null) {
        //                 timezoneOffset = -moment.tz(timestamp, chartTimezone.replace (/"/g,'')).utcOffset();
        //             }
        //             return timezoneOffset;
        //         }
        //     }
        // });
    }

	public initChart() {
		this.chart = new Chart({
			chart: {
				type: 'line',
				zoomType: 'x',
				resetZoomButton: {
					position: {
						align: 'left',
						verticalAlign: 'top',
						x: 0,
						y: -10
					}
				}
			},
			title: {
				text: null
			},
			credits: {

				enabled: false
			},
			xAxis: {
				title: {
					text: this.translatedData.DATE
				},
				type: 'datetime',
				tickWidth: 1,
				labels: {
					enabled: true,
					rotation: -45,
					format: '{value:%Y-%b-%d}'
				},
				plotLines: [{
					color: '#757575', // Color value
					dashStyle: 'ShortDash', // Style of the plot line. Default to solid
					value: this.dateInt, // Value of where the line will appear
					width: 2, // Width of the line   
					label: {
						text: this.translatedData.TODAY,
						align: 'center',
						y: 40,
					}
				}]
			},
			yAxis: [{ // left y axis	
				gridLineWidth: 1,
				tickWidth: 1,
				minTickInterval: 1,
				title: {
					text: this.translatedData.NUM_OF_STN_PTN
				}
			}],
			plotOptions: {
				line: {
					marker: {
						enabled: true
					}
				}
			},
			tooltip: {
				crosshairs: true,
				shared: true,
				xDateFormat: '%Y-%b-%d',
				useHTML: true
			},
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					}
				}
			}

		});
	}

	public addSeriesToChart(planSeries: any, remainSeries: any) {
		this.chart.addSerie({
			name: this.translatedData.PLAN_STN_PTN,
			color: '#1E88E5',
			marker: {
				symbol: 'dot'
			},
			lineWidth: 2,
			data: planSeries,
		})
		this.chart.addSerie({
			name: this.translatedData.REMAINING_STN_PTN,
			color: '#E53935',
			marker: {
				symbol: 'dot'
			},
			lineWidth: 2,
			data: remainSeries
		})
		this.isLoadingData = false
	}

	public removedVendor(value: any): void {
		// console.log('[removedVendor] value ' + JSON.stringify(value));
		// console.log('[removedVendor] selectedSystem ' + JSON.stringify(this.selectedSystem[0].id));
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.removeSerie()
		this.getSTNPTNData(this.selectedSystem[0].id, this.selectedVendor[0].id)
	}

	public selectedVendorId(value: any): void {
		// console.log('[selectedVendorId] value ' + JSON.stringify(value));
		this.removeSerie()
		this.selectedVendor = []
		this.selectedVendor.push(value)
		if (value.id == 'ALL') {
			this.getSTNPTNData(this.selectedSystem[0].id, this.selectedVendor[0].id)
		} else {
			this.getSTNPTNData(this.selectedSystem[0].id, value.id)
		}

	}

	public removedSystem(value: any): void {
		// console.log('[removedSystem] value ' + JSON.stringify(value))
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.removeSerie()
		let vendorId = 'ALL'
		if (this.selectedVendor.length != 0) {
			vendorId = this.selectedVendor[0].id
		}
		this.getSTNPTNData(this.selectedSystem[0].id, vendorId)
	}

	public selectedSystemId(value: any): void {
		// console.log('[selectedSystemId] value ' + JSON.stringify(value));
		this.selectedSystem.pop()
		this.selectedSystem.push(value)
		this.removeSerie()
		let vendorId = 'ALL'
		if (this.selectedVendor.length != 0) {
			vendorId = this.selectedVendor[0].id
		}
		this.getSTNPTNData(value.id, vendorId)
	}

	public removeSerie() {
		let num = this.chart.ref.series.length
		// console.log('[removeSerie] num ' + JSON.stringify(num));
		for (let i = 0; i < num; i++) {
			this.chart.removeSerie(0);
		}
	}

	public pointClickEvent(event: any): boolean {
		console.log('[pointClickEvent] (event.point) ' + JSON.stringify(event.point));
		return true
	}

	getSTNPTNData(systemId: any, vendorId: any) {

		this.isLoadingData = true
		this.isNoDataToShow = false
		this.stnpntRundownService.getSTNPTNData(systemId, vendorId).subscribe(
			data => {
				let stnPlanData: any = []
				let stnRemainData: any = []

				if (data.payload != null) {
					if (data.payload.stnremain.length != 0) {
						data.payload.stnremain.forEach(element => {
							//let date = CommonUtil.formattedDateUsingTimeZone(element.date);                    
							let formatDate = new Date(element.date).getTime()
							stnRemainData.push([formatDate, element.stnRundown])
						});
					} else {
						// do nothing no remaining items
					}
					if (data.payload.stnplan.length != 0) {
						data.payload.stnplan.forEach(element => {
							//let date = CommonUtil.formattedDateUsingTimeZone(element.date);
							let formatDate = new Date(element.date).getTime()
							stnPlanData.push([formatDate, element.stnRundown])
						});
					} else {
						// do nothing no plan items
					}
					if (stnPlanData.length == 0 && stnRemainData.length == 0) {
						//do data to show
						this.isLoadingData = false
						this.isNoDataToShow = true
					} else {
						//remove series from the chart before adding a series
						this.removeSerie()
						this.addSeriesToChart(stnPlanData, stnRemainData)
					}
				} else {
					//do not have data to show
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			});
	}

	public refresh() {
		console.log('[StnptnRundownWidgetComponent] (refresh)');
		this.initChart()
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.getSTNPTNData('ALL', 'ALL')
	}
}
