import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'

@Injectable({
	providedIn: 'root'
})
export class PrecommissioningCompletionService {

	constructor(private http: HttpClient) {

	}

	getPreCommissioningDetails(): Observable<WsResponse> {
		console.log("PrecommissioningCompletionService.getPreCommissioningDetails() == > ServiceURL= " + ServiceUrls.GET_PRECOMMISSIONING_DETAILS)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		return this.http.get(ServiceUrls.GET_PRECOMMISSIONING_DETAILS, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	addPrecommissioningCompletion(precommissioningCompletion: any): Observable<WsResponse> {
		console.log("PrecommissioningCompletionService.addPrecommissioningCompletion() == > ServiceURL= " + ServiceUrls.ADD_PRECOMMISSIONING_COMPLETION)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete precommissioningCompletion['isStart']
		precommissioningCompletion['userToken'] = token
		return this.http.post(ServiceUrls.ADD_PRECOMMISSIONING_COMPLETION, JSON.stringify(precommissioningCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	updatePrecommissioningCompletion(precommissioningCompletion: any): Observable<WsResponse> {
		console.log("PrecommissioningCompletionService.updatePrecommissioningCompletion() == > ServiceURL= " + ServiceUrls.UPDATE_PRECOMMISSIONING_COMPLETION)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete precommissioningCompletion['isStart']
		delete precommissioningCompletion['newtargetCompletionDate']
		precommissioningCompletion['userToken'] = token
		return this.http.post(ServiceUrls.UPDATE_PRECOMMISSIONING_COMPLETION, JSON.stringify(precommissioningCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	generatePrecommissioningCompletion(precommissioningCompletion: any) {
		console.log("PrecommissioningCompletionService.generatePrecommissioningCompletion() == > ServiceURL= " + ServiceUrls.GENERATE_PCC)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		precommissioningCompletion['userToken'] = token
		return this.http.post(ServiceUrls.GENERATE_PCC, JSON.stringify(new commonRequest("PCC", JSON.stringify(precommissioningCompletion)))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	revokePrecommissioningCompletion(pccId: any) {
		console.log("PrecommissioningCompletionService.revokePrecommissioningCompletion() == > ServiceURL= " + ServiceUrls.getRevokePCC(pccId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let precommissioningCompletion = { 'userToken': token }
		return this.http.post(ServiceUrls.getRevokePCC(pccId), JSON.stringify(precommissioningCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}



	getPCCSignature(fileName: any) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, { responseType: "blob", params: new HttpParams().set("fileName", fileName).set("mode", "GetImage") });
	}

	downloadFile(projectId: string, documentId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", documentId)
					.set("mode", "ViewApprovedOutput").set("type", "StaticCompletion").set("fileName", "PCC")
			});
	}

	downloadSentFile(projectId: string, documentId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", documentId).set("mode", "ViewCommOutput").set("type", "StaticCompletion").set("fileName", "PCC")
			});
	}

	public getPccCount(projectId: any): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set('token', token) }

		return this.http.get(ServiceUrls.getPrecommissioningPccCount(projectId), options)
			.pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	deletePrecommissioningCompletion(pccId: any) {
		console.log("PrecommissioningCompletionService.deletePrecommissioningCompletion() == > ServiceURL= " + ServiceUrls.getDeletePCC(pccId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let precommissioningCompletion = { 'userToken': token }
		return this.http.post(ServiceUrls.getDeletePCC(pccId), JSON.stringify(precommissioningCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}
}

class commonRequest{
	constructor(private certificateType: String, private certificate: String) { }
}