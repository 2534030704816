import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridOptions } from "ag-grid";
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';
import { WIDGET_EVENT } from '../../util/constants';
import { CustomTypeaheadComponent } from '../../util/custom-typeahead/custom-typeahead.component';
import { SharedService } from '../../util/shared.service';
import { MechanicalCompletionService } from '../mechanical-completion.service';
import { TranslateService } from '@ngx-translate/core';
import { EditButtonRenderer } from './cell-renderer/edit-button/edit-button.component'
import { DeleteButtonRenderer } from './cell-renderer/delete-button/delete-button.component'
import { UserVariable } from '../../util/common/user-variable';
import { AlertService } from '../../util/alert/alert.service';
import { StartWorkflowRenderer } from './cell-renderer/start-workflow/start-workflow.component'
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { WorkflowService } from '../workflow.service';
import { CheckSheetService } from '../checksheet.service';
import { CapabilityService } from '../../util/capability.service';
import { Guid } from "guid-typescript";
import * as FileSaver from 'file-saver';
import { FileIconRenderer } from './cell-renderer/file-icon/approved-file-icon.component'
import { SentFileIconRenderer } from './cell-renderer/file-icon/sent-file-icon.component'
import { CheckSheetSentFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-sent-file-icon.component'
import { CheckSheetApprovedFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import "ag-grid-enterprise";
import * as XLSX from 'xlsx';
import { LoadingService } from '../../util/loading/loading.service';
import { DatePipe } from '@angular/common';
import { MechanicalCompletionDocuSignRenderer } from './cell-renderer/mechanical-completion-docu-sign/mechanical-completion-docu-sign.component';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-mechanical-completion-widget',
	templateUrl: './mechanical-completion-widget.component.html',
	styleUrls: ['./mechanical-completion-widget.component.css']
})
export class MechanicalCompletionWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	@ViewChild("deleteMCCModal") deleteMCCModal: TemplateRef<any>;
	@ViewChild("incompleteMCCModal") incompleteMCCModal: TemplateRef<any>;
	@ViewChild("editMCCModal") editMCCModal: TemplateRef<any>;
	public deleteModalRef: BsModalRef;
	public incompleteModalRef: BsModalRef;
	public editModalRef: BsModalRef;
	public gridOptions: GridOptions;
	public detailGridOptions: GridOptions;
	private rowData: any[] = []
	private columnDefs: any
	private gridApi
	private gridColumnApi
	mc_translated_data: any = null;
	eventType: any = WIDGET_EVENT.SHOW_TABLE
	widgetEvent = WIDGET_EVENT
	private selectedItem: any = null
	private bsConfig: Partial<BsDatepickerConfig>
	public targetCompletionDate: Date = null
	public required: boolean = null
	public vendorManaged: boolean = null
	public openPunchlists: any[] = [];
	public userList: any[] = []
	public allPunchlistCompleted: boolean = false;
	public allChecksheetsCompleted: boolean = false;
	public isSystemHasIRN: boolean = false;
	public isAllChecksheetsPunchlistsCompleted: boolean = false;
	public selectedAssignee: any[] = null
	public workflowTaskList: any[] = [];
	public ch1: any = false
	public ch2: any = false
	public ch3: any = false
	public ch4: any = false
	public ch5: any = false
	public ch6: any = false
	public ch7: any = false
	public ch8: any = false
	public ch9: any = false
	public ch10: any = false
	public workflowId: any = null
	public taskCount: any = 0
	assignee1: any
	assignee2: any
	assignee3: any
	assignee4: any
	taskname1: any
	taskname2: any
	taskname3: any
	taskname4: any
	showMCDetail: boolean = false
	detailRowData: any[] = []
	detailColumnDefs: any
	detailGridApi
	detailGridColumnApi
	iconClicked: boolean = false
	modifyMechanicalCompletion: boolean = false;
	showRequiredErr: boolean = false
	isSubmitted: boolean = false
	validAssignee1: boolean = true
	validAssignee2: boolean = true
	validAssignee3: boolean = true
	validAssignee4: boolean = true
	validAssigneeEmail1: boolean = true
	validAssigneeEmail2: boolean = true
	validAssigneeEmail3: boolean = true
	validAssigneeEmail4: boolean = true
	public assetsListWithoutApprovedOTNBySystemId: any[] = [];
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };

	constructor(private translate: TranslateService,
		private sharedService: SharedService,
		private mechanicalCompletionService: MechanicalCompletionService,
		private alertService: AlertService,
		private userService: UserService,
		private systemService: SystemService,
		private workflowService: WorkflowService,
		private checkSheetService: CheckSheetService,
		private loadingService: LoadingService,
		private capabilityService: CapabilityService,
		private bsModalService: BsModalService) {

		this.modifyMechanicalCompletion = capabilityService.isCapabilityAssigned("modifyMechanicalCompletion");

		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 10
		};

		this.detailGridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 5
		};

		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.mc_translated_data = res['MECHANICAL_COMPLETION']
			this.columnDefs = [
				{ headerName: this.mc_translated_data.SYSTEM, field: 'systemName', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 280 },
				{ headerName: this.mc_translated_data.MC_STATUS, field: 'mcstatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 90 },
				{
					headerName: this.mc_translated_data.TARGET_COMPLETION_DATE, field: 'newtargetCompletionDate', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

							dateAsString = dateAsString.replace(/-/g,'/');

							var dateParts = dateAsString.split('/');

							var cellDate = new Date(
							Number(dateParts[2]),
							Number(dateParts[1]) - 1,
							Number(dateParts[0])
							);


							}
							else{

							var cellDate = new Date(dateAsString)

							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				}
			];
			if (this.modifyMechanicalCompletion) {
				this.columnDefs.push({
					headerName: this.mc_translated_data.EDIT,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 70,
					suppressFilter: true,
					cellRendererFramework: EditButtonRenderer,
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.mc_translated_data.START_WORKFLOW,
					//field: 'isStart',
					valueGetter: function(params) {
						if(params.data.isStart) {
							return params.data.isStart;
						} else {
							return params.data.docuSignStatus == null ? '' : params.data.docuSignStatus;
						}
					},
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 125,
					//suppressFilter: true,
					filter: "agTextColumnFilter",
					cellRendererFramework: MechanicalCompletionDocuSignRenderer,
					// StartWorkflowRenderer,
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.mc_translated_data.FAILURE_REASON,
					valueGetter: function(params) {
						if(params.data.outputErrorMessage != null){
							return params.data.outputErrorMessage;
						} else if(params.data.docusignFailureReason != null){
							return params.data.docusignFailureReason;
						} else{
							return '';
						}
					},
					suppressFilter: true,
					hide: true,
					minWidth: 200
				})
				this.columnDefs.push({
					headerName: this.mc_translated_data.DELETE,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 80,
					suppressFilter: true,
					cellRendererFramework: DeleteButtonRenderer,
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.mc_translated_data.SENT_PDF,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 70,
					suppressFilter: true,
					hide: true,
					cellRendererFramework: SentFileIconRenderer,
					suppressSorting: true
				})
				this.columnDefs.push({
					headerName: this.mc_translated_data.RETURNED_PDF,
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 90,
					suppressFilter: true,
					cellRendererFramework: FileIconRenderer,
					suppressSorting: true
				})
			}
			// this.columnDefs.push({
			// 	headerName: this.mc_translated_data.DOCUSIGN_STATUS,
			// 	field: 'docuSignStatus',
			// 	autoHeight: true,
			// 	cellClass: "cell-wrap-text",
			// 	suppressRowClickSelection: true,
			// 	hide: true,
			// 	cellRendererFramework: MechanicalCompletionDocuSignRenderer,
			// 	minWidth: 90
				
			// })
			this.detailColumnDefs = [
				{
					headerName: this.mc_translated_data.CHECKSHEET_CODE,
					field: 'checkSheetCode',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.mc_translated_data.CHECKSHEET_NAME,
					field: 'checkSheetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.mc_translated_data.VENDOR,
					field: 'vendorName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100
				},
				{
					headerName: this.mc_translated_data.SYSTEM_NAME,
					field: 'system',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.mc_translated_data.ASSET,
					field: 'assetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.mc_translated_data.STATUS,
					field: 'status',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 90,
					cellRenderer: this.statusCellRenderer,
					suppressMenu: true,
					suppressSorting: true
				},
				{
					headerName: this.mc_translated_data.SENT_DOWNLOAD_ALL,
					field: 'initialFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					suppressFilter: true,
					minWidth: 70,
					cellRendererFramework: CheckSheetSentFileIconRenderer,
					suppressMenu: true,
					suppressSorting: true
				},
				{
					headerName: this.mc_translated_data.APPROVED_DOWNLOAD_ALL,
					field: 'approvedFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					suppressFilter: true,
					minWidth: 100,
					cellRendererFramework: CheckSheetApprovedFileIconRenderer,
					suppressMenu: true,
					suppressSorting: true
				}

			]
		});
	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {
	}

	agInit(params: any): void {
		console.log(params);
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onDetailColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.refresh()
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	onDetailGridReady(params) {
		this.detailGridApi = params.api;
		this.detailGridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.detailGridApi.showLoadingOverlay()
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	onRowClick(event) {
		console.log('row clicked ' + this.iconClicked)
		if (!this.iconClicked) {
			this.showMCDetail = true
			if (this.detailGridApi) {
				this.detailGridApi.showLoadingOverlay()
			}

			if (event.data) {
				this.systemService.getCheckSheets(event.data.systemId).subscribe(
					data => {
						console.log('[getCheckSheets] (data) ' + JSON.stringify(data))
						this.detailRowData = data.payload
					},
					error => {
						console.log('[getCheckSheets] (error) ' + JSON.stringify(error))
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					}
				)
			}
		} else {
			this.iconClicked = false
		}
	}

	public getStyle(status: any) {
		if (status == 'Approved') {
			return 'badge-success'
		} else {
			return 'badge-warning'
		}
	}

	public getStatus(status: any) {
		switch (status) {
			case 'Not Issued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'Not Issued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	isInputValid() {
		if (!this.selectedItem.mcstatus) {
			if (this.targetCompletionDate && this.required) {
				return true
			} else {
				return false
			}
		} else {
			if (this.selectedItem.mcRequired && !this.required) {
				if (this.targetCompletionDate) {
					return true
				} else {
					return false
				}
			} else {
				if (this.targetCompletionDate && this.required) {
					return true
				} else {
					return false
				}
			}
		}
	}

	public showWizard(data: any) {
		this.selectedItem = data.data
		console.log('[showWizard] (data) ' + JSON.stringify(this.selectedItem));
		this.eventType = WIDGET_EVENT.SHOW_WIZARD
	}

	private backToTable() {
		this.isSubmitted = false;
		this.selectedItem = null
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	private finishFunction() {
		this.isSubmitted = true;
		if(!this.validateAssigneeData()){
			return;

		}
		else{
			this.loadingService.showLoading(false, false, '', 0)
			var signerslist =  this.getSigngersList();
			var mcObject = {
				"mcId": this.selectedItem.mcId, "ch1": this.ch1, "ch2": this.ch2, "ch3": this.ch3, "ch4": this.ch4,
				"ch5": this.ch5, "ch6": this.ch6, "ch7": this.ch7, "ch8": this.ch8, "ch9": this.ch9, "ch10": this.ch10, 
				"signersList": JSON.stringify(signerslist) , "doctype" : "MCC", "systemno":this.selectedItem.systemNo.replace(/"/g, '\\"')
			}
			this.workflowId = Guid.create()
			console.log(JSON.stringify(mcObject))
			this.mechanicalCompletionService.generateMechanicalCompletion(mcObject).
				subscribe(
				data => {
					if (this.workflowTaskList) {
						this.taskCount = 0
						this.addInstanceTaskMap()
					}
				},
				error => {
					this.loadingService.hideLoading()
					this.alertService.error(error.statusDescription);
				})
	}
}

	validateAssigneeData(): boolean{
		this.validAssignee1 = true;
		this.validAssignee2= true
		this.validAssignee3= true
		this.validAssignee4 = true
		this.validAssigneeEmail1 = true;
		this.validAssigneeEmail2= true
		this.validAssigneeEmail3= true
		this.validAssigneeEmail4 = true

		let validation = true;

			if (this.assignee1 == null ||this.assignee1 == ''){
				this.validAssignee1 = false;
				validation = false;
				
			}
			
			if (this.assignee2 == null ||this.assignee2 == ''){
				this.validAssignee2 = false;
				validation = false;
			}
			
			if (this.assignee3 == null ||this.assignee3 == ''){
				this.validAssignee3 = false;
				validation = false;
			}
			
			if (this.assignee4 == null ||this.assignee4 == ''){
				this.validAssignee4 = false;
				validation = false;
			}
			if(this.assignee1 !='' && this.assignee1 != null ){
			if(!this.userList.find(user => user.id == this.assignee1)){
				if(!this.emailValidate(this.assignee1)){
					this.validAssigneeEmail1 = false;
					validation = false;

				}
			}
			}
			if(this.assignee2 !='' && this.assignee2 != null ){
			if(!this.userList.find(user => user.id == this.assignee2)){
				if(!this.emailValidate(this.assignee2)){
					this.validAssigneeEmail2 = false;
					validation = false;

				}
			}
			}
			if(this.assignee3 !='' && this.assignee3 != null ){
			if(!this.userList.find(user => user.id == this.assignee3)){
				if(!this.emailValidate(this.assignee3)){
					this.validAssigneeEmail3 = false;
					validation = false;

				}
			}
			}
			
			if(this.assignee4 !='' && this.assignee4 != null ){
			if(!this.userList.find(user => user.id == this.assignee4)){
				if(!this.emailValidate(this.assignee4)){
					this.validAssigneeEmail4 = false;
					validation = false;

				}
			}
			}
			
			return validation;

	}

	//generate signers list
	getSigngersList(){
		var signersIdList = [this.assignee1, this.assignee2, this.assignee3, this.assignee4]
		var taskNameList = [this.taskname1, this.taskname2, this.taskname3, this.taskname4];
		var signersList: any[] = [];
		var i= 1;
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 'signLocation' : signLocation , 'datelocation': dateLocation, 
				'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'MCC','systemno':this.selectedItem.systemNo.replace(/"/g, '\\"') });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				signersList.push({'signerId': "" , 'signerName': assigneeName , 'signerEmail': assignee, 'signLocation' : signLocation , 'datelocation': dateLocation, 
				'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'MCC', 'systemno':this.selectedItem.systemNo.replace(/"/g, '\\"') });
				i++;
			}
		});
		return signersList;
 	}

	addInstanceTaskMap() {
		this.loadingService.showLoading(false, false, '', 0)
		var taskmap = this.workflowTaskList[this.taskCount]
		taskmap['processInstanceId'] = this.workflowId.value

		var taskAssignee = null
		if (this.taskCount == 0) {
			taskAssignee = this.assignee1;
		} else if (this.taskCount == 1) {
			taskAssignee = this.assignee2;
		} else if (this.taskCount == 2) {
			taskAssignee = this.assignee3;
		} else if (this.taskCount == 3) {
			taskAssignee = this.assignee4;
		}
		if (this.userList.find(user => user.id == taskAssignee)) {
			taskmap['userId'] = taskAssignee
			taskmap['userEmail'] = null
		} else {
			taskmap['userEmail'] = taskAssignee
			taskmap['userId'] = null
		}

		this.taskCount = this.taskCount + 1
		this.workflowService.addWorkflowInstanceTaskRolemap(taskmap).
			subscribe(
			data => {
				if (this.taskCount == this.workflowTaskList.length) {
					var workflow = {
						"id": this.workflowId.value, "projectId": UserVariable.projectId, "processInstanceId": this.workflowId.value,
						"processName": "MCCApproval", "itemId": this.selectedItem.mcId,
						"itemType": "MechanicalCompletion", "initiatedUserId": UserVariable.userId, "configData": this.getConfigData()
					}
					this.workflowService.generateWorkflow(workflow)
						.subscribe(data => {
							this.loadingService.hideLoading()
							this.backToTable()
						},
						error => {
							this.loadingService.hideLoading()
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						})
				} else {
					this.addInstanceTaskMap()
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.error(error.statusDescription);
			})
	}

	getConfigData() {
		var configData = '<ConfigData>';
		configData += '<AllDocInclude>' + this.ch6 + '</AllDocInclude>';
		configData += '<AllMcCheckSheet>' + this.ch1 + '</AllMcCheckSheet>';
		configData += '<AllAPunchList>' + this.ch2 + '</AllAPunchList>';
		configData += '<AllRemPunchList>' + this.ch7 + '</AllRemPunchList>';
		configData += '<AllComponentsAssembled>' + this.ch3 + '</AllComponentsAssembled>';
		configData += '<AcceptanceCompleted>' + this.ch4 + '</AcceptanceCompleted>';
		configData += '<ReleaseNoticeIssued>' + this.ch5 + '</ReleaseNoticeIssued>';
		configData += '<CareProcessApproved>' + this.ch8 + '</CareProcessApproved>';
		configData += '<TCPDocCompleted>' + this.ch9 + '</TCPDocCompleted>';
		configData += '<PlanApprovedForRemItems>' + this.ch10 + '</PlanApprovedForRemItems>';
		configData += '</ConfigData>';
		return configData;
	}

	private edit(data: any) {
		this.selectedItem = data.data
		this.eventType = WIDGET_EVENT.SHOW_EDIT
		if (this.selectedItem.targetCompletionDate) {
			this.targetCompletionDate = new Date(this.selectedItem.targetCompletionDate)
		} else {
			this.targetCompletionDate = null;
		}
		console.log(this.selectedItem.mcRequired)
		this.required = this.selectedItem.mcRequired
		this.vendorManaged = this.selectedItem.mcVendorManaged
	}

	private resetValues() {

	}

	deleteMCC() {
		this.editModalRef.hide();
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.deleteMechanicalCompletion(this.selectedItem.mcId)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	cancelEditMCC() {
		this.editModalRef.hide()
	}

	saveMC(): void {		
		if (this.selectedItem.mcId) {
			if (this.isInputValid()) {
				this.showRequiredErr = false
				if (this.selectedItem.mcRequired && !this.required) {
					this.editModalRef = this.bsModalService.show(this.editMCCModal, { class: 'modal-sm' })
				} else {
					this.loadingService.showLoading(false, false, '', 0)
					if (!this.selectedItem.mcstatus) {
						this.selectedItem.mcstatus = "NotIssued"
					}
					if (this.selectedItem.mcstatus == "Not Issued") {
						this.selectedItem.mcstatus = "NotIssued"
					}
					this.selectedItem.mcRequired = this.required
					this.selectedItem.mcVendorManaged = this.vendorManaged
					this.selectedItem.strCompletionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
					this.mechanicalCompletionService.updateMechanicalCompletion(this.selectedItem)
						.subscribe(data => {
							this.loadingService.hideLoading()
							this.backToTable();
						}, error => {
							this.loadingService.hideLoading()
							this.alertService.error(error.statusDescription);
						})
				}
			} else {
				this.showRequiredErr = true
			}

		} else {
			if (this.isInputValid()) {
				this.loadingService.showLoading(false, false, '', 0)
				this.showRequiredErr = false
				this.selectedItem.mcstatus = "NotIssued"
				this.selectedItem.mcRequired = this.required
				this.selectedItem.mcVendorManaged = this.vendorManaged
				this.selectedItem.strCompletionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
				this.selectedItem.projectId = UserVariable.projectId;
				this.mechanicalCompletionService.addMechanicalCompletion(this.selectedItem)
					.subscribe(data => {
						this.loadingService.hideLoading()
						this.backToTable();
					}, error => {
						this.loadingService.hideLoading()
						this.alertService.error(error.statusDescription);
					})
			} else {
				this.showRequiredErr = true
			}
		}
	}

	continue() {
		this.loadingService.showLoading(false, false, '', 0)
		this.eventType = this.widgetEvent.SHOW_WIZARD
		if (this.incompleteModalRef) {
			this.incompleteModalRef.hide()
		}
		this.systemService.getOpenPunchlistItems(this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
			.subscribe(
			data => {
				this.openPunchlists = []
				this.loadingService.hideLoading()
				if (data.payload) {
					this.openPunchlists = data.payload
				}
			}, error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id , "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('MechanicalCompletion')
					.subscribe(data => {
						this.workflowTaskList = data.payload
						this.selectedAssignee = []
						if (data.payload) {
							this.workflowTaskList.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					}, error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			}, error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})

	}

	startWorkflow(params: any) {
		this.selectedItem = params.data
		this.loadingService.showLoading(false, false, '', 0)
		//Check MCC has IRN logic here- Check assets of the system has an IRN
		this.systemService.isSystemHasIRN( this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
		.subscribe(data => {
			this.isSystemHasIRN = data.payload;
			//If system has IRN value set to ch4 
			this.ch4 = data.payload;
			this.systemService.isAllChecksheetsComplete("A", this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
				.subscribe(data => {
					this.allChecksheetsCompleted = data.payload
					this.ch1 = data.payload
					this.systemService.getAssetsListWithoutApprovedOTNBySystemId (this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId).
					subscribe(data => {
						this.assetsListWithoutApprovedOTNBySystemId = []

						if (data.payload) {
							this.assetsListWithoutApprovedOTNBySystemId = data.payload
						}
							
							this.systemService.isAllPunchlistItemsClosedByCategory("A", this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
								.subscribe(data => {
									this.allPunchlistCompleted = data.payload
									this.ch2 = data.payload
									this.loadingService.hideLoading()
									if (this.isSystemHasIRN) {
										if (!this.allChecksheetsCompleted || !this.allPunchlistCompleted) {
											this.isAllChecksheetsPunchlistsCompleted = false;
											this.incompleteModalRef = this.bsModalService.show(this.incompleteMCCModal, { class: 'modal-lg' })
										} else {
											this.continue()
										}
									} else {
										if (!this.allChecksheetsCompleted || !this.allPunchlistCompleted) {
											this.isAllChecksheetsPunchlistsCompleted = false;
										} else {
											this.isAllChecksheetsPunchlistsCompleted = true;
										}
										this.incompleteModalRef = this.bsModalService.show(this.incompleteMCCModal, { class: 'modal-lg' })
									}
									
								}, error => {
									this.loadingService.hideLoading()
									this.alertService.clear()
									this.alertService.error(error.statusDescription)
								})
					}, error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
				}, error => {
					this.loadingService.hideLoading()
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				})
		}, error => {
			this.loadingService.hideLoading()
			this.alertService.clear()
			this.alertService.error(error.statusDescription)
		})

	}

	emailValidate(value: any) {
		let isValid = /^[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+(\.[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
		return isValid
	}

	isValidEmailAddress(value: any, i: any) {
		let result = true;
		if (i == 0) {
			if (this.assignee1) {
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
			}
		} else if (i == 1) {
			if (this.assignee2) {
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
			}
		} else if (i == 2) {
			if (this.assignee3) {
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
			}
		} else if (i == 3) {
			if (this.assignee4) {
				if (!this.userList.find(user => user.id == this.assignee4)) {
					if (!this.emailValidate(this.assignee4)) {
						result = false;
					}
				}
			}
		}
		return result
	}

	isAssigneesAdded() {
		if (this.assignee1 && this.assignee2 && this.assignee3 && this.assignee4) {
			let result = true;
			if (!this.userList.find(user => user.id == this.assignee1)) {
				if (!this.emailValidate(this.assignee1)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee2)) {
				if (!this.emailValidate(this.assignee2)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee3)) {
				if (!this.emailValidate(this.assignee3)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee4)) {
				if (!this.emailValidate(this.assignee4)) {
					result = false;
				}
			}
			return result
		} else {
			return false
		}
	}

	outFocus(event: any, i: any) {
		console.log('[outFocus] (event) ' + JSON.stringify(event));

	}

	textChanged(event: any, i: any , taskname: any) {
		console.log('[textChanged] (event) ' + JSON.stringify(event));
		if (event) {
			if (i == 1) {
				this.assignee1 = event.value
				this.taskname1 = this.getTaskName(taskname);
			} else if (i == 2) {
				this.assignee2 = event.value
				this.taskname2 = this.getTaskName(taskname);
			} else if (i == 3) {
				this.assignee3 = event.value
				this.taskname3 = this.getTaskName(taskname);
			} else if (i == 4) {
				this.assignee4 = event.value
				this.taskname4 = this.getTaskName(taskname);
			}
		} else {
			if (i == 1) {
				this.assignee1 = null
				this.taskname1 = null
			} else if (i == 2) {
				this.assignee2 = null
				this.taskname2 = null
			} else if (i == 3) {
				this.assignee3 = null
				this.taskname3 = null
			} else if (i == 4) {
				this.assignee4 = null
				this.taskname4 = null
			}
		}
	}

	getTaskName(taskName: any) {
		var correctTaskName = taskName.replace(/([a-z])([A-Z])/g, '$1 $2').replace('Endose', 'Endorse')
		return correctTaskName
	}

	public delete(params: any) {
		this.selectedItem = params.data
		this.deleteModalRef = this.bsModalService.show(this.deleteMCCModal, { class: 'modal-sm' })
	}

	revokeMCC(): void {
		this.deleteModalRef.hide();
		this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.revokeMechanicalCompletion(this.selectedItem.mcId)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	cancel(): void {
		this.deleteModalRef.hide();
	}

	cancelWorkflow(): void {
		this.incompleteModalRef.hide()
		this.eventType = this.widgetEvent.SHOW_TABLE
	}

	downloadFile(params: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.downloadFile(UserVariable.projectId, params.data.mcId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "MCC_" + params.data.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.mc_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadSentFile(params) {
		// this.loadingService.showLoading(false, false, '', 0)
		this.mechanicalCompletionService.downloadSentFile(UserVariable.projectId, params.data.mcId)
			.subscribe(response => {
				// this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "MCC_" + params.data.systemName + "_" + ".pdf");
			},
			error => {
				// this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.mc_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadCheckSheetSentFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadSentFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.initialFileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.mc_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadCheckSheetApprovedFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadApprovedFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.approvedFileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.mc_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	//refresh widget
	public refresh() {
		console.log('[MechanicalCompletionWidgetComponent] (refresh) ');
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.showMCDetail = false
		this.iconClicked = false
		this.selectedAssignee = []
		this.assignee1 = null
		this.assignee2 = null
		this.assignee3 = null
		this.assignee4 = null
		this.taskname1 = null
		this.taskname2 = null
		this.taskname3 = null
		this.taskname4 = null
		this.ch1 = false //
		this.ch2 = false //
		this.ch3 = false
		this.ch4 = false //
		this.ch5 = false
		this.ch6 = false
		this.ch7 = true //
		this.ch8 = true //
		this.ch9 = true //
		this.ch10 = false
		this.selectedItem = null
		this.workflowTaskList = []
		this.showRequiredErr = false
		this.gridApi.showLoadingOverlay()
		this.mechanicalCompletionService.getAllMechanicalCompletion()
			.subscribe(data => {
				if (data.payload) {
					data.payload.forEach(element => {
						if (element.targetCompletionDate) {
							element.newtargetCompletionDate = new Date(element.targetCompletionDate).toLocaleDateString()
						}
						element.systemName = element.systemNo + " " + element.systemName
						element.isStart = false
						if (element.mcstatus == "NotIssued") {
							element.isStart = true
							element.mcstatus = "Not Issued"
						}
						if(element.docuSignStatus != null){
							element.docuSignStatus = element.docuSignStatus.toLowerCase()
						}
					});
					this.rowData = data.payload;
					console.log('[getAllMechanicalCompletion] (data) ' + JSON.stringify(this.rowData))
				} else {
					this.rowData = []
				}
			},
			error => {
				console.log('[getAllMechanicalCompletion] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription);
			})
	}

	exportToExcel() {
		var params = {
			fileName: "mechanical_completion.xlsx",
			sheetName: "mechanical_completion",
			suppressTextAsCDATA: true
		};
		if (this.gridApi) {
			var xlsContent = this.gridApi.getDataAsExcel(params)
			var temp = XLSX.read(xlsContent, { type: 'binary' });
			var xlsxContent = XLSX.write(temp, { bookType: 'xlsx', type: 'base64' });

			this.download(params, xlsxContent);
		}
	}

	download(params, content) {
		var fileNamePresent = params && params.fileName && params.fileName.length !== 0;
		var fileName = fileNamePresent ? params.fileName : "noWarning.xlsx";
		var blobObject = this.b64toBlob(content, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blobObject, fileName);
		} else {
			var downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blobObject);
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	}

	b64toBlob(b64Data, contentType) {
		var sliceSize = 512;
		var byteCharacters = atob(b64Data);
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	public previous(){
		this.isSubmitted = false;
	}
}
