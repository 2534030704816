import { Component, OnInit } from '@angular/core';
import { ProceduresInstancesWidgetService } from './procedures-instances-widget.service';
import { LoggedUser } from '../../user/logged-user.model';
import { LocalStorage } from '../../util/localstorage.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import * as FileSaver from 'file-saver';
import { UserVariable } from '../../util/common/user-variable';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../util/shared.service';
import { LoadingService } from '../../util/loading/loading.service';
import * as moment from 'moment-timezone';
import { FacilitiesCellRender } from '../topicmaps-in-the-store-widget/cell-render/facilitiescellrender.component';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-procedures-instances-widget',
	templateUrl: './procedures-instances-widget.component.html',
	styleUrls: ['./procedures-instances-widget.component.css']
})
export class ProceduresInstancesWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	private gridApi;
	private gridColumnApi;
	public rowData = [];
	private loggedInUser: LoggedUser;
	public columnDefs;
	cd_translated_data: any = null;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };
	  
	constructor(
		private proceduresInstancesWidgetService: ProceduresInstancesWidgetService,
		private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService,
		private loadingService: LoadingService) {

		let browserLang = translate.getBrowserLang();
		var self = this;
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cd_translated_data = res['PROCEDURES_INSTANCES']
			this.columnDefs = [
				{
					headerName: this.cd_translated_data.FACILITIES,
					field: "facilities",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160,
					//cellRendererFramework: FacilitiesCellRender,
					filter: 'agTextColumnFilter',
					/* keyCreator: function (params){
						var facilities = params.value;
						var filterkeys = [];
						for (var key in facilities) {
							filterkeys.push(facilities[key]);
						}
						return filterkeys;

					} */
				},
				{
					headerName: this.cd_translated_data.PROCEDURE,
					field: "procedure" , filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300
				},
				{
					headerName: this.cd_translated_data.DOCUMENT,
					field: "topicmap" , filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300
				},
				// {
				//     headerName: this.cd_translated_data.COMPLETED_DATE,
				//     field: "completedDate",
				//     autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200,
				//     filter: "agDateColumnFilter",
				//     filterParams: {
				//         comparator: function (filterLocalDateAtMidnight, cellValue) {
				//             var dateAsString = cellValue;
				//             var cellDate = new Date(dateAsString)
				//             if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
				//                 return 0;
				//             }
				//             if (cellDate < filterLocalDateAtMidnight) {
				//                 return -1;
				//             }
				//             if (cellDate > filterLocalDateAtMidnight) {
				//                 return 1;
				//             }
				//         }
				//     }
				// },
				{
					headerName: this.cd_translated_data.USER,
					field: "user",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160
				},
				{
					headerName: this.cd_translated_data.INITIATED_TIME,
					field: "initiatedTime",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						//browserDatePicker: true,
						cellRenderer: data => {
							return data.value != undefined ? moment(data.value).format("dd-mm-yyyy") : null;
						},
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
								var dateTimeComponents = dateAsString.split(" ");
								if (dateTimeComponents != undefined && dateTimeComponents.length > 0) {
									dateAsString = dateTimeComponents[0];
								}

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[0]) - 1,
								Number(dateParts[1])
								);


							} else {
								var cellDateTime = new Date(dateAsString)
								var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						}
					}
				},
				{
					headerName: this.cd_translated_data.COMPLETED_TIME,
					field: "completedTime",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						//browserDatePicker: true,
						cellRenderer: data => {
							return data.value != undefined ? moment(data.value).format("dd-mm-yyyy") : null;
						},
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
								var dateTimeComponents = dateAsString.split(" ");
								if (dateTimeComponents != undefined && dateTimeComponents.length > 0) {
									dateAsString = dateTimeComponents[0];
								}

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
									Number(dateParts[2]),
									Number(dateParts[0]) - 1,
									Number(dateParts[1])
									);


							} else {
								var cellDateTime = new Date(dateAsString)
								var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						}
					}
				},
				{
					headerName: this.cd_translated_data.FILE,
					field: 'file',
					autoHeight: true, cellClass: "cell-wrap-text",
					suppressFilter: true, minWidth: 60,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.value) {
							eDiv.innerHTML = '<button *ngIf="params.value" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
								'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
								'</button>';
							var eButton = eDiv.querySelectorAll('.btn-sm')[0];

							eButton.addEventListener('click', function () {
								self.downloadFile(params)
							});
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				}
			];
		});

		proceduresInstancesWidgetService.getInstances()
			.subscribe(
			data => {
				this.loadData(data.payload)

			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	ngOnInit() {
		this.columnDefs;
	}

	dateComparator(date1, date2) {
		var date1AsString = "";
		var date2AsString = "";
		var date1TimeComponents = date1.split(" ");
		var date2TimeComponents = date2.split(" ");

		date1AsString = date1TimeComponents[0];
		date2AsString = date2TimeComponents[0];

		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1AsString == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1AsString.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[0]) - 1,
					Number(dateParts1[1])
					);
			}
			if(date2AsString == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2AsString.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[0]) - 1,
					Number(dateParts2[1])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1AsString).getTime();
			var date2Number = new Date(date2AsString).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()
		params.api.sizeColumnsToFit();
		this.refresh()
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 500);
		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	loadData(payload: any) {
		if (payload != null) {
			payload.forEach(element => {
				if (element.addedDate) {
					element.addedDate = new Date(element.addedDate).toLocaleDateString()
				}
				if (element.initiatedTime != null) {

                    element.initiatedTime  = moment(element.initiatedTime ).format('MM-DD-YYYY HH:mm:ss');                                    

                    //element.initiatedTime = date.tz(localStorage.getItem(LocalStorage.TIMEZONE)).format('L LTS')

                }
				else{
					
					element.initiatedTime = " ";
				}

                if (element.completedTime != null) {

                    element.completedTime = moment(element.completedTime).format('MM-DD-YYYY HH:mm:ss');                       

                    //element.completedTime = date.tz(localStorage.getItem(LocalStorage.TIMEZONE)).format('L LTS')

                }
				else{

					element.completedTime = " ";
				}
			});

			this.rowData = [];
			for (let i = 0; i < payload.length; i++) {
				var facility:any = {};
				if (payload[i]["facilityList"].length != 0 ) {
					var facilities = payload[i]["facilityList"];
					for (var key in facilities) {
					facility = {
						'facilityId': key,
						'facilityName': facilities[key]
					}
				}
				} else {
					facility = {
						'facilityId': "",
						'facilityName': ""
					}

				}
				
				this.rowData.push({
					facilities:[facility.facilityName],
					procedure: payload[i]["name"],
					topicmap: payload[i]["topicmapName"],
					completedDate: payload[i]["addedDate"],
					user: payload[i]["initiatedUser"],
					file: payload[i]["fileId"],
					initiatedTime: payload[i]["initiatedTime"],
					completedTime: payload[i]["completedTime"]
				});
			}
		}
	}

	downloadFile(params) {
		this.loadingService.showLoading(false, false, '', 0)
		this.proceduresInstancesWidgetService.downloadFile(UserVariable.projectId, params.data.file)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PI_" + params.data.procedure + "_" + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error("error")
			});
	}

	onCellClicked(event: any) { }


	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.proceduresInstancesWidgetService.getInstances()
			.subscribe(
			data => {
				this.loadData(data.payload)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

	}

}
