import { environment } from '../../environments/environment';

export class ServiceUrls {
	private static serviceName = "symbiosisservice/";
	private static restName = "api/"
	private static serviceVersion = "v1/";

	private static servicePrefix = environment.server_URL + ServiceUrls.serviceName + ServiceUrls.restName + ServiceUrls.serviceVersion;

	//Project URLs
	public static ADD_STN = ServiceUrls.servicePrefix + "stns.json";
	public static CHANGE_PASSWORD = ServiceUrls.servicePrefix + "auth/changepw/changePassword.json";
	public static GET_DEFAULT_WORKFLOW_LIST = ServiceUrls.servicePrefix + "workflow/activeworkflow/getTasksPerWorkflowType.json";
	public static GET_IRNS = ServiceUrls.servicePrefix + "irns.json";
	public static GET_SHS = ServiceUrls.servicePrefix + "shs.json";
	public static GET_OWI = ServiceUrls.servicePrefix + "owi.json";
	public static GET_FAS = ServiceUrls.servicePrefix + "fas.json";
	public static DOWNLOAD_FILE = environment.server_URL + ServiceUrls.serviceName + ServiceUrls.serviceVersion + "download";
	public static GET_STNPTN_DATA = ServiceUrls.servicePrefix + "stns/stnwidget/getReportSTNRundown.json";
	public static GET_ALL_WORK_PACKAGES = ServiceUrls.servicePrefix + "workpackage.json";
	public static GET_STNS_BY_VENDOR_ID = ServiceUrls.servicePrefix + "stns.json";
	public static GET_SYSTEM_LIST = ServiceUrls.servicePrefix + "systems/systemList/getAllSystemsList.json";
	public static GET_PARENT_SYSTEM_LIST = ServiceUrls.servicePrefix + "systems/parentSystemList/getALLParentSystem.json";
	public static GET_VENDOR_LIST = ServiceUrls.servicePrefix + "vendors.json";
	public static KEY_CODE_VERIFY_URL = ServiceUrls.servicePrefix + "kcv.json";
	public static GET_AUTH0_CONFIGURATIONS_URL = ServiceUrls.servicePrefix + "openid/1/getAuth0Configurations.json";
	public static LOGIN_URL = ServiceUrls.servicePrefix + "auth.json";
	public static OPEN_ID_LOGIN_URL = ServiceUrls.servicePrefix + "openid.json";
	public static RESET_PASSWORD = ServiceUrls.servicePrefix + "auth/resetpw/resetPassword.json";
	public static SESSION_VALIDATION = ServiceUrls.servicePrefix + "auth/checks/isAlreadySignIn.json";
	public static UPDATE_STN = ServiceUrls.servicePrefix + "stns/updatestn/updateSTNData.json";
	public static UPDATE_STN_STATUS = ServiceUrls.servicePrefix + "stns/updatestn/updateSTNStatus.json";
	public static GET_PUNCHLIST_DETAILS = ServiceUrls.servicePrefix + "punchlist/getDetails/getPunchlistDetailsBySystem.json";
	public static GET_PUNCHLIST_DETAILS_BY_IRN = ServiceUrls.servicePrefix + "punchlist/getDetails/getPunchlistDetailsByIRN.json";

	public static getDeleteSTN(stnId: string) {
		return ServiceUrls.servicePrefix + "stns/" + stnId + "/deleteSystemTurnoverNotice.json";
	}
	public static getMCSystemBreakdownlist(projectId: string) {
		return ServiceUrls.servicePrefix + "systems/"+ projectId + "/getMCSystemBreakdown.json";
	}

	public static getMCSystemBreakdownlistSolr(projectId: string) {
		return ServiceUrls.servicePrefix + "systems/"+ projectId + "/getMCSystemBreakdownSolr.json";
	}

	public static checkChecksheetsCompleted(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + "/isAllChecksheetsComplete.json";
	}
	public static checkPunchlistsCompleted(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + "/isAllPunchlistItemsClosedByCategory.json";
	}

	public static isSystemHasIRN(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + "/isSystemHasIRN.json";
	}

	public static getAssetsListWithoutApprovedOTNBySystemId(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + "/getAssetsListWithoutApprovedOTNBySystemId.json";
	}
	
	public static getAllActiveUsers(projectId: string) {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getAllActiveUsers.json";
	}
	public static GET_OPEN_PUNCHLIST_ITEMS = ServiceUrls.servicePrefix + "workflowapproval/system/getOpenPunchlistItems.json";

	public static getPasswordResetUrl(userName: String, authType: number): string {
		return ServiceUrls.servicePrefix + "auth/reseturl/sendPasswordResetURL.json?uname=" + userName + "&authType=" + authType;
	}
	public static getStnById(stnId: string) {
		return ServiceUrls.servicePrefix + "stns/" + stnId + ".json";
	}
	public static getSystemById(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + ".json";
	}
	public static getValidatePasswordReset(key: String, id: String, authType: number) {
		return ServiceUrls.servicePrefix + "auth/resetpage/loadPasswordResetPage.json?key=" + key + "&id=" + id + "&authType=" + authType;
	}
	//public static GENERATE_STN = ServiceUrls.servicePrefix + "stns/generate/generateSTN.json";
	public static GENERATE_STN = ServiceUrls.servicePrefix + "docusign/1/addDocuSignEntry.json";
	public static ADD_WORKFLOW_INSTANCE_TASK_MAP = ServiceUrls.servicePrefix + "workflow/activeworkflow/addWorkflowInstanceTaskRolemap.json";
	public static GENERATE_WORKFLOW = ServiceUrls.servicePrefix + "workflow/generate/generateWorkflow.json";

	//new code
	public static ADD_UPDATE_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/addrecent/addUpdateRecentProject.json";

	public static getAllRecentProjects(userId: String): string {
		return ServiceUrls.servicePrefix + "recentprojects/getallp/getAllRecentProjects.json?userId=" + userId;
	}

	public static getDefaultRecentProject(userId: string): string {
		return ServiceUrls.servicePrefix + "recentprojects/getdefault/getDefaultRecentProject.json?userId=" + userId;
	}

	public static SET_DEFAULT_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/setdefault/setDefaultRecentProject.json";

	public static DELETE_USER_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/delrecent/deleteUserRecentProject.json";

	public static DELETE_USER_DEFAULT_RECENT_PROJECT = ServiceUrls.servicePrefix + "recentprojects/deldefault/deleteUserDefaultRecentProject.json";

	public static USER_PROJECT_VIEW = ServiceUrls.servicePrefix + "users/";

	public static USER_DOCUMENT_VIEW = ServiceUrls.servicePrefix + "controlleddocuments.json";

	public static USER_SHC_VIEW = ServiceUrls.servicePrefix + "shs.json";

	public static GET_CLIENT_LOGO = ServiceUrls.servicePrefix + "clients.json";

	public static GET_NOTIFICATION_COUNT = ServiceUrls.servicePrefix + "notification/notificationCount/getNotificationCountByUser.json";

	public static GET_VIEWED_NOTIFICATION = ServiceUrls.servicePrefix + "notification/viewdNotification/updateViewedNotification.json";

	public static GET_NOTIFICATION_COUNT_IF_MODIFIED = ServiceUrls.servicePrefix + "notification/notificationCountIfModified/getNotificationsIfModified.json";

	public static GET_NOTIFICATIONS = ServiceUrls.servicePrefix + "notification/notificationList/getNotificationsByUser.json";

	public static UPDATE_VIEWED_AS_ALL_NOTIFICATIONS = ServiceUrls.servicePrefix + "notification/viewdNotification/updateViewedAsAllNotifications.json";

	public static GET_SERVER_VERSION = ServiceUrls.servicePrefix + "serverversion.json";

	public static GET_PUNCHLISTS = ServiceUrls.servicePrefix + "punchlist.json";

	public static USER_OWI_VIEW = ServiceUrls.servicePrefix + "workitem/openWorkItems/getOpenWorkItems.json";

	public static USER_TAG_VIEW = ServiceUrls.servicePrefix + "tags.json";

	public static USER_SYSTEM_SKYLINE_VIEW = ServiceUrls.servicePrefix + "precommissioning/skyline/getPrecommissioningSkyline.json"

	public static GET_WORKITEM_SKYLINE_DATA = ServiceUrls.servicePrefix + "workitem/skyline/getSkylineData.json"

	public static GET_CHECKSHEETS_DETAILS = ServiceUrls.servicePrefix + "checksheets/checksheetsDetails/getChecksheetsSkyline.json"

	public static IMPORT_BULK_COMM_DATA  = ServiceUrls.servicePrefix + "folderprop/importBulkCommData/importBulkCommData.json";

	public static GET_PRECOMMISSIONING_DETAILS = ServiceUrls.servicePrefix + "precommissioning.json"

	public static GET_WORKITEMSLIST = ServiceUrls.servicePrefix + "workitem/workitemslist/getWorkItemsList.json"

	public static GET_WORKITEMDETAILS = ServiceUrls.servicePrefix + "workitem/workitemdetails/getWorkItemsWeeklyDetailsReport.json"

	public static UPDATE_WORKITEM_STATUS = ServiceUrls.servicePrefix + "workitem/workitemslist/updateWorkItemStatus.json"

	public static UPDATE_WORKITEM_LIFT_CRITICAL = ServiceUrls.servicePrefix + "workitem/workitemslist/updateWorkItemLiftCritical.json"

	public static ADD_WORKITEM_COMMENTS = ServiceUrls.servicePrefix + "workitem/workitemscomment/addWorkItemComment.json"

	public static DELETE_WORKITEM_COMMENTS = ServiceUrls.servicePrefix + "workitem/workitemscomment/deleteWorkItemComment.json"

	public static UPDATE_WORKITEM_COMMENTS = ServiceUrls.servicePrefix + "workitem/workitemscomment/updateWorkItemComment.json"

	public static GET_WORKFLOW_TASK_ENTRY = ServiceUrls.servicePrefix + "workflowapproval/activeworkflow/getWorkFlowTaskEntryByActivity.json"
	public static GET_WORKFLOW_TASK = ServiceUrls.servicePrefix + "workflowapproval/task/getTask.json";

	public static GET_CLIENTID_BY_PROJECT_ID = ServiceUrls.servicePrefix + "workflowapproval/project/getClientId.json";

	public static GET_WORKFLOW_PROCESS_ENTRY = ServiceUrls.servicePrefix + "workflowapproval/activeworkflow/getWorkFlowProcessEntryByProcessInstanceId.json"
	public static GET_WORKFLOW_USER_ID = ServiceUrls.servicePrefix + "workflowapproval/activeworkflow/getWorkFlowUserId.json"
	public static GET_STN = ServiceUrls.servicePrefix + "workflowapproval/stn/getStn.json";

	public static GET_SYSTEM = ServiceUrls.servicePrefix + "workflowapproval/system/getSystem.json";

	public static GET_WORKFLOW_INSTANCE_TASKS = ServiceUrls.servicePrefix + "workflowapproval/workflow/getWorkFlowInstanceTaskRoleMap.json";

	public static GET_STN_WORKFLOW_APPROVE = ServiceUrls.servicePrefix + "workflowapproval/stn/approveSTNWorkflow.json";

	public static GET_STN_APPROVAL_DATA = ServiceUrls.servicePrefix + "workflowapproval/stn/getSTNApprovalData.json";

	public static GET_SKYLINE_DATA = ServiceUrls.servicePrefix + "mechanicalcompletion/skyline/getSkylineData.json";
	public static GET_MCCHECKSHEET_DATA = ServiceUrls.servicePrefix + "mechanicalcompletion/mcrundown/getReportCheckSheet.json";
	public static getCheckSheets(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + "/getCheckSheets.json";
	}
	public static GET_ALL_MC = ServiceUrls.servicePrefix + "mechanicalcompletion.json";
	public static ADD_MECHANICAL_COMPLETION = ServiceUrls.servicePrefix + "mechanicalcompletion.json";
	public static UPDATE_MECHANICAL_COMPLETION = ServiceUrls.servicePrefix + "mechanicalcompletion/updatemechanicalcompletion/updateMCData.json";
	//public static GENERATE_MC = ServiceUrls.servicePrefix + "mechanicalcompletion/generate/generateMechanicalCompletion.json"
	public static GENERATE_MC = ServiceUrls.servicePrefix + "docusign/1/addDocuSignEntry.json";
	public static getRevokeMCC(mccId: string) {
		return ServiceUrls.servicePrefix + "mechanicalcompletion/" + mccId + "/revokeMechanicalCompletion.json";
	}
	public static getDeleteMCC(mccId: string) {
		return ServiceUrls.servicePrefix + "mechanicalcompletion/" + mccId + "/deleteMechanicalCompletion.json";
	}
	public static GET_MCC = ServiceUrls.servicePrefix + "workflowapproval/mechanicalcompletion/getMCC.json";

	public static GET_MCC_APPROVAL_DATA = ServiceUrls.servicePrefix + "workflowapproval/mechanicalcompletion/getMCCApprovalData.json";

	public static GET_MCC_WORKFLOW_APPROVAL = ServiceUrls.servicePrefix + "workflowapproval/mechanicalcompletion/approveMCCWorkflow.json";

	public static GET_CAPABILITY_LIST = ServiceUrls.servicePrefix + "capabilities.json";
	public static ADD_PRECOMMISSIONING_COMPLETION = ServiceUrls.servicePrefix + "precommissioning.json";
	public static UPDATE_PRECOMMISSIONING_COMPLETION = ServiceUrls.servicePrefix + "precommissioning/updateprecommissioningcompletion/updatePCData.json";
	//public static GENERATE_PCC = ServiceUrls.servicePrefix + "precommissioning/generate/generatePrecommissioningCompletion.json"
	public static GENERATE_PCC = ServiceUrls.servicePrefix + "docusign/1/addDocuSignEntry.json"
	public static getRevokePCC(pccId: string) {
		return ServiceUrls.servicePrefix + "precommissioning/" + pccId + "/revokePrecommissioningCompletion.json";
	}
	public static getDeletePCC(pccId: string) {
		return ServiceUrls.servicePrefix + "precommissioning/" + pccId + "/deletePrecommissioningCompletion.json";
	}
	public static GET_PCC = ServiceUrls.servicePrefix + "workflowapproval/precommissioning/getPCC.json";

	public static GET_PCC_APPROVAL_DATA = ServiceUrls.servicePrefix + "workflowapproval/precommissioning/getPCCApprovalData.json";

	public static GET_PCC_WORKFLOW_APPROVE = ServiceUrls.servicePrefix + "workflowapproval/precommissioning/approvePCCWorkflow.json";

	public static GET_ALL_CC = ServiceUrls.servicePrefix + "commcompletion.json";
	public static getProcedureInStoreCount(projectId: string) {
		return ServiceUrls.servicePrefix + "procedures/" + projectId + "/getProceduresInStoreCount.json"
	}
	public static getTopicMapInStoreCount(projectId: string) {
		return ServiceUrls.servicePrefix + "documentStore/" + projectId + "/getTopicMapsInStoreCount.json"
	}
	public static GET_PRECOMMISSIONING_CHECKSHEET_DATA = ServiceUrls.servicePrefix + "precommissioning/checksheetrundown/getReportCheckSheet.json";
	public static ADD_COMM_COMPLETION = ServiceUrls.servicePrefix + "commcompletion.json";
	public static getUpdateCommCompletion(ccId: string) {
		return ServiceUrls.servicePrefix + "commcompletion/" + ccId + ".json";
	}
	public static getTopicMaps(projectId: string) {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getTopicmaps.json";
	}
	public static getProcedures(topicmapId: string) {
		return ServiceUrls.servicePrefix + "topicmaps/" + topicmapId + "/getProcedures.json";
	}
	public static getCommCompletionById(ccId: string) {
		return ServiceUrls.servicePrefix + "commcompletion/" + ccId + ".json"
	}
	public static GET_PROCEDURE_INSTANCES = ServiceUrls.servicePrefix + "procedures/projectid/getInstances.json";
	public static getStoreLoadByProjectId(projectId: string) {
		return ServiceUrls.servicePrefix + "procedures/" + projectId + "/getStoreLoad.json"
	}
	public static getDocumentStoreLoadByProjectId(projectId: string) {
		return ServiceUrls.servicePrefix + "documentStore/" + projectId + "/getStoreLoad.json"
	}
	public static getProcedureListStatus(ccId: string) {
		return ServiceUrls.servicePrefix + "commcompletion/" + ccId + "/getProcedureListStatus.json"
	}
	public static generateCommCompletion(ccId: string) {
		//return ServiceUrls.servicePrefix + "commcompletion/" + ccId + "/generateCommCompletion.json"
		return ServiceUrls.servicePrefix + "docusign/" + ccId + "/addDocuSignEntry.json"
	}
	public static getRevokeCCC(cccId: string) {
		return ServiceUrls.servicePrefix + "commcompletion/" + cccId + "/revokeCommCompletion.json";
	}
	public static getDeleteCCC(cccId: string) {
		return ServiceUrls.servicePrefix + "commcompletion/" + cccId + "/deleteCommCompletion.json";
	}
	public static GET_CCC = ServiceUrls.servicePrefix + "workflowapproval/commcompletion/getCCC.json";

	public static GET_CCC_APPROVAL_DATA = ServiceUrls.servicePrefix + "workflowapproval/commcompletion/getCCCApprovalData.json";

	public static CCC_WORKFLOW_APPROVE = ServiceUrls.servicePrefix + "workflowapproval/commcompletion/approveCCCWorkflow.json";

	public static GET_EXECUTED_PROCEDURES = ServiceUrls.servicePrefix + "procedures/projectid/getExecutedProcedures.json";
	public static getProcedureExecutedCount(projectId: string) {
		return ServiceUrls.servicePrefix + "procedures/" + projectId + "/getCountExecutedProcedures.json"
	}
	public static getDocumentationTagCount(projectId: string) {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getTagCount.json"
	}
	public static getDocumentationUniqueStatementCount(projectId: string) {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getUniqueStatementCount.json"
	}
	public static getDocumentationTotalRevisions(projectId: string) {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getTotalRevisions.json"
	}
	public static getDocumentationPandID(projectId: string) {
		return ServiceUrls.servicePrefix + "projects/" + projectId + "/getPandID.json"
	}
	public static GET_OPEN_PUNCHLIST_COUNT = ServiceUrls.servicePrefix + "punchlist/openpunchlists/getOpenPunchlistCount.json"
	public static getOpenPunchlistItems(systemId: string) {
		return ServiceUrls.servicePrefix + "systems/" + systemId + "/getOpenPunchlistItems.json";
	}
	public static getPunchListCount(category: string) {
		return ServiceUrls.servicePrefix + "punchlist/" + category + "/getPunchlistCount.json"
	}
	public static getMechanicalMccCount(projectId: string) {
		return ServiceUrls.servicePrefix + "mechanicalcompletion/" + projectId + "/getMccCount.json"
	}
	public static getPrecommissioningPccCount(projectId: string) {
		return ServiceUrls.servicePrefix + "precommissioning/" + projectId + "/getPccCount.json"
	}
	public static getProjectDashboardDetails(widgetListName: string) {
		return ServiceUrls.servicePrefix + "projects/" + widgetListName + "/getProjectDashboard.json"
	}

	public static getPersonDocuSignStatusUrl(docuSignId: string) {
		return ServiceUrls.servicePrefix + "docusign/" + docuSignId + "/viewPersonDocuSignStatus.json";
	}

	public static getSystemsCompletionStatusUrl(projectId: string) {
		return ServiceUrls.servicePrefix + "systems/"+ projectId + "/getSystemsCompletionStatusReportData.json";
	}

	public static UPDATE_SYSTEM_COMPLETION_STATUS = ServiceUrls.servicePrefix + "systems/updateSystemCompletionStatus/saveSystemCompletionStatusInputReportData.json";
	
	public static UPDATE_USER_PREFERENCES = ServiceUrls.servicePrefix + "userpreferences/updateUserPreferences/updateUserPreferences.json";

	public static GET_ALL_OTN = ServiceUrls.servicePrefix + "onshoreturnovernotice.json";

	public static getAllOTN(token: string): string {
		return ServiceUrls.servicePrefix + "onshoreturnovernotice.json?token=" + token;
	}

	public static UPDATE_ONSHORE_TURNOVER_NOTICE = ServiceUrls.servicePrefix + "onshoreTurnoverNotice/updateOnshoreTurnoverNotice/updateOTNData.json";

	public static ADD_ONSHORE_TURNOVER_NOTICE = ServiceUrls.servicePrefix + "onshoreTurnoverNotice.json";

	public static addNewOTN(token: string): string {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice.json?token=" + token;
	}

	public static getAllVendors(token: string) {
        return ServiceUrls.servicePrefix + "vendors.json?token=" + token;
    }

	public static getRevokeOTN(otnId: string) {
		return ServiceUrls.servicePrefix + "onshoreTurnoverNotice/" + otnId + "/revokeOnshoreTurnoverNotice.json";
	}

	public static getDeleteOTN(otnId: string) {
		return ServiceUrls.servicePrefix + "onshoreTurnoverNotice/" + otnId + "/deleteOnshoreTurnoverNotice.json";
	}

	public static getOTNByAssetId(token: String, assetId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/getOTN/getOTNListByAssetId.json?token=" + token + "&assetId=" + assetId;
    }

	//add/edit punchlist
	public static ADD_NEW_PUNCHLIST_DOCUMENT = ServiceUrls.servicePrefix + "folderprop/addNewPunchlistDocument/addNewPunchlistDocument.json";
	public static getDisciplines(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getAllActiveDisciplineForProject.json";
	}
	public static GET_ALL_SYSTEM_LIST = ServiceUrls.servicePrefix + "systems/geysys/getSystemsByProjectId";
	public static GET_ASSET_LIST = ServiceUrls.servicePrefix + "tags/getTagListByProjectIdUsedInCommissioning/getTagListByProjectIdUsedInCommissioning.json";
    public static GET_ASSET_LIST_WITH_OTN_TARGET_COMPLETION_DATE = ServiceUrls.servicePrefix + "tags/getTagListByProjectIdUsedInCommissioning/getTagsWithOTNTargetCompletionDate.json";
	public static TAGS_SEARCH_VIEW = ServiceUrls.servicePrefix + "tags/tagSearch/getTagListBySearch.json";
	public static addNewPunchlist(token: string): string {
        return ServiceUrls.servicePrefix + "punchlist/addPunchlist/addPunchlist.json?token=" + token;
	}
	public static getPunchlistByPunchlistId(token: String, punchlistId: String) {
        return ServiceUrls.servicePrefix + "punchlist/getPunchlist/getPunchlistByPunchlistId.json?token=" + token + "&id=" + punchlistId;
    }
	public static getPunchCodes(projectId: String): string {
        return ServiceUrls.servicePrefix + "projects/" + projectId + "/getPunchcodeForProject.json";
    }
	public static updatePunchlist(token: string): string {
        return ServiceUrls.servicePrefix + "punchlist/updatePunchlist/updatePunchlist.json?token=" + token;
    }
	public static updatePunchlistWithClousreComment(token: string): string {
        return ServiceUrls.servicePrefix + "punchlist/updatePunchlist/updatePunchlistWithClousreComment.json?token=" + token;
    }
	public static CLOSURE_COMMENT_UPDATE = ServiceUrls.servicePrefix + "punchlist/updatePunchlist/updatePunchlistWithClousreComment.json";
	public static DELETE_PL_CLOSURE_COMMENTS = ServiceUrls.servicePrefix + "punchlist/updatePunchlist/deleteClosureComments.json"
	public static getPunchlistDetailsByPunchlistIds(token: string) {
		return ServiceUrls.servicePrefix + "punchlist/getPunchlistDetailsByPunchlistIds/getPunchlistDetailsByPunchlistIds.json?token=" + token;
	}

	public static isAssetsHasIRN(token: string) {
        return ServiceUrls.servicePrefix + "tags/checkAssetHasIRN/isAssetHasIRN.json?token=" + token;
    }

	public static getIrnByIrnId(token: String, irnId: String) {
        return ServiceUrls.servicePrefix + "irns/getIRN/getIRNByIRNId.json?token=" + token + "&id=" + irnId;
    }

	public static isAllPunchlistItemsClosedByCategoryAndAsset(token: string) {
        return ServiceUrls.servicePrefix + "tags/checkAssetsPunchlists/isAllPunchlistItemsClosedByCategoryAndAsset.json?token=" + token;
    }

	public static GENERATE_OTN = ServiceUrls.servicePrefix + "docusign/1/addDocuSignEntry.json";
	public static GENERATE_PUNCHLIST = ServiceUrls.servicePrefix + "docusign/1/addDocuSignEntry.json";
	public static ADD_NEW_PUNCHLIST_IMAGE = ServiceUrls.servicePrefix + "folderprop/addNewPunchlistImage/addNewPunchlistImage.json";

	public static getMCCListByOTNId(token: String, otnId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + "/getMCCListByOTNId.json?token=" + token;
    }

	public static deleteOrRevokeOTN(token: String, otnId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + "/deleteOrRevokeOTN.json?token=" + token;
    }

	public static getOTNByOTNId(token: String, otnId: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + "/getOTNByOTNId.json?token=" + token;
    }

	public static updateOTN(token: string, otnId: String): string {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/" + otnId + ".json?token=" + token;
    }

	public static getPunchlistItemsByAssetsCategoryAndItemStatus(token: string) {
		return ServiceUrls.servicePrefix + "punchlist/getPunchlistItemsByAssetsCategoryAndItemStatus/getPunchlistItemsByAssetsCategoryAndItemStatus.json?token=" + token;
	}

    public static DEPENDENCY = ServiceUrls.servicePrefix + "dependency/";
    public static PUNCHLISTS = ServiceUrls.servicePrefix + "punchlist/";

	public static revokePunchlist(token: String, punchlistId: String) {
        return ServiceUrls.servicePrefix + "punchlist/" + punchlistId + "/revokePunchlist.json?token=" + token;
    }
    public static checkAssetVendorCombination(token: String) {
        return ServiceUrls.servicePrefix + "onshoreturnovernotice/validate/checkAssetVendorCombination.json?token=" + token;
    }
	public static getPunchlistsInSameGroup(token: String, punchlistId: String) {
        return ServiceUrls.servicePrefix + "punchlist/" + punchlistId + "/getPunchlistsInSameGroup.json?token=" + token;
    }

	public static getAllReportDesignsByModuleAndProjectId(token: string): string {
		return ServiceUrls.servicePrefix + "adhocreports/getReports/getAllReportDesignsByModuleAndProjectId.json?token=" + token;
	}
	
	public static viewReport(token: string, reportId: string): string {
		return ServiceUrls.servicePrefix + "adhocreports/" + reportId + "/viewReport.json?token=" + token;
	}
	
	public static GET_OTN_RUNDOWN_DATA = ServiceUrls.servicePrefix + "onshoreturnovernotice/otnwidget/getReportOTNRundown.json";
	public static GET_OTN_ASSETS = ServiceUrls.servicePrefix + "onshoreturnovernotice/otnwidget/getAllOTNAssets.json";
	public static GET_OTN_VENDORS = ServiceUrls.servicePrefix + "onshoreturnovernotice/otnwidget/getAllOTNVendors.json";
	public static GET_OTN_ASSET_VENDORS = ServiceUrls.servicePrefix + "vendors/otmnassetvendors/getOTNAssetVendors.json";

	public static GET_IRN_RUNDOWN_DATA = ServiceUrls.servicePrefix + "irns/irnwidget/getReportIRNRundown.json";
	public static GET_IRN_ASSETS = ServiceUrls.servicePrefix + "irns/irnwidget/getAllIRNAssets.json";
	public static GET_IRN_VENDORS = ServiceUrls.servicePrefix + "irns/irnwidget/getAllIRNVendors.json";
	public static GET_IRN_ASSET_VENDORS = ServiceUrls.servicePrefix + "vendors/irnassetvendors/getIRNAssetVendors.json";
}