import { Component, OnInit, ViewChildren, QueryList, EventEmitter, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Punchlist, PunchlistDocument } from 'src/app/widgets/punchlist.model'
import { PunchlistService } from 'src/app/widgets/punchlist.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocalStorage } from 'src/app/util/localstorage.service';
import { GridOptions } from "ag-grid";
import { FileUploadService } from '../../../file-upload.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommonUtil } from 'src/app/util/common/common-utils';
import { SharedService } from "../../../../util/shared.service";
import { Observable } from 'rxjs';
import { InspectionReleaseNoticeService } from 'src/app/widgets/inspection-release-notice/inspection-release-notice.service';

@Component({
  selector: 'app-editpunchlist',
  templateUrl: './editpunchlist.component.html',
  styleUrls: ['./editpunchlist.component.css']
})
export class EditpunchlistComponent implements OnInit, WsCallback {
  public punchlist: Punchlist = new Punchlist();
  public projectId: string = null;
  public userId: string = null;
  public token: string;

  public category: Array<any> = [];
  public item_status: Array<any> = [];
  public selectedCategory: any = "";
  public selectedItem_status: any = "";


  public assetList: Array<any> = [];
  public disciplineList: Array<any> = [];
  public vendorList: Array<any> = [];
  public subSystemList: Array<any> = [];
  public selectedAsset: Array<any> = null;

  public activityCodeList: Array<any> = [];
  public objectCodeList: Array<any> = [];
  public optionalModifierList: Array<any> = [];

  public selectedAssetList = [];
  public initialValueList = [];

  public punchlistDisable: boolean = true;
  public assetDisable: boolean = true;
  public disciplineDisable: boolean = true;
  public subSystemDisable: boolean = true;
  public vendorDisable: boolean = true;
  public isInitial: boolean = true;

  public emptyFieldError: any;
  public statusError: any;
  public emptyAssetOrSystem: any;
  public emptyAsset: any;
  public emptyCloseDate: any;
  public invalidDate: any;
  public maxTotalSizeExceed: any;

  public gridOptions: GridOptions;
  public columnDefs: any;
  public rowData: any = [];
  private gridApi: any;
  private gridColumnApi: any;
  //public modules: Module[] = AllModules;

  private uploadingFile: File;
  private fileExtension: string;
  public uploadFileName: string;
  public uploadFileNameWithExt: string;
  public punchlistUploadDocuments: any = [];

  filesToUpload: Array<File> = [];
  irnAssetsList: any = [];
  punchlistIRNNumber: string;

  public bsConfig: Partial<BsDatepickerConfig>

  onClose = new EventEmitter();

  private uploadingFile_Image: File;
  private fileExtension_Image: string;
  public uploadFileName_Image: string;
  public uploadFileNameWithExt_Image: string;
  public punchlistUploadImages: any = [];
  public fileSelTxt: string = "";
  warnModalRef: BsModalRef;
  private MAX_FILE_SIZE : any = 26214400; //25MB

  @ViewChild('confirmation_warn') confirmationWarn: TemplateRef<any>;

  constructor(
    private punchlistService: PunchlistService,
    private alertService: AlertService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    public bsModalRef: BsModalRef,
    private commonUtil: CommonUtil,
    private sharedService: SharedService,
    private irnService: InspectionReleaseNoticeService,
  ) {

    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.translate.get('PUNCHLIST_ADD_EDIT.FIELD_EMPTY').subscribe((res: string) => {
      this.emptyFieldError = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.STATUS_ERROR').subscribe((res: string) => {
      this.statusError = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.CLOSE_DATE_EMPTY').subscribe((res: string) => {
      this.emptyCloseDate = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.ASSET_OR_SYSTEM_EMPTY').subscribe((res: string) => {
      this.emptyAssetOrSystem = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.ASSET_EMPTY').subscribe((res: string) => {
      this.emptyAsset = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.INVALID_DATE').subscribe((res: string) => {
      this.invalidDate = res;
    });
    this.translate.get('NEW_PUNCHLIST_IMAGE.NO_FILES').subscribe((res: string) => {
      this.fileSelTxt = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.MAX_ATTACHMENT_SIZE_').subscribe((res: string) => {
      this.maxTotalSizeExceed = res;
    });

    //set token
    this.token = UserVariable.getUserToken();

    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', customTodayClass: 'custom-today-class' });

    this.prepareAgGrid();
  }

  loadingMap: boolean = false;
  @ViewChild('mapContainer') gmap: ElementRef;
  map: google.maps.Map;
  lat = 0.000000;
  lng = 0.000000;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8
  };

  marker = new google.maps.Marker();

  //initialize google map
  mapInitializer() {
    this.lat = this.punchlist.locationLat;
    this.lng = this.punchlist.locationLong;
    console.log("lattitude:", this.lat);
    console.log("longtitude:", this.lng);
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.SATELLITE
    };
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);
    this.marker = new google.maps.Marker({
      position: this.coordinates,
      map: this.map,
    });
    this.marker.setMap(this.map);
  }

  ngOnInit() {
    this.category = [
      { 'id': '1', 'text': 'A' },
      { 'id': '2', 'text': 'B' },
      { 'id': '3', 'text': 'C' },
      { 'id': '4', 'text': 'D' }
    ];

    this.item_status = [
      { 'id': '1', 'text': 'Open' },
      { 'id': '2', 'text': 'Closed' },
      { 'id': '3', 'text': 'Initial' }
    ];

    //set project id and user id
    this.projectId = UserVariable.projectId;
    this.userId = UserVariable.userId;

    //get required details for add punchlist
    this.punchlistService.getAssetList(this);
    this.punchlistService.getSubSystemList(this);
    this.punchlistService.getDisciplineList(this.projectId, this);
    this.punchlistService.getVendorList(this.projectId, this);
    this.punchlistService.getPunchCodeList(this.projectId, this);
    
  }

  /**
   * set payload data to the punchlist model
   * @param payload 
   */
  displayData(payload: any) {
    console.log(payload);
    if (payload != null) {
      this.punchlist.punchlistId = payload.id;
      this.punchlist.punchlistDescription = payload.punchlistDescription;
      this.punchlist.closedDate = payload.dateClosed != null ? new Date(payload.dateClosed) : null;
      this.punchlist.dateraised = payload.dateRaised != null ? new Date(payload.dateRaised) : null;
      this.punchlist.targetCompletionDate = payload.estimatedCompletionDate != null ? new Date(payload.estimatedCompletionDate) : null;
      this.punchlist.raisedby = payload.raisedBy;
      this.punchlist.estimatedManHours = payload.estimatedManHours;
      this.punchlist.materialRequired = payload.materialsRequired;
      this.punchlist.comments = payload.comments;
      this.punchlist.subsystemId = payload.subSystemId;
      if (this.punchlist.subsystemId != null && this.punchlist.subsystemId != "") {
        this.punchlist.isAssetDisable = true;
      }
      this.punchlist.subSystemName = payload.subSystemName;
      this.punchlist.selectedSubSystem = this.subSystemList.filter(x => x.id == this.punchlist.subsystemId);
      this.punchlist.parentId = payload.systemId;
      this.punchlist.parentName = payload.systemName;
      this.punchlist.punchlistDocuments = payload.punchlistDocuments;
      this.punchlist.punchlistImages = payload.punchlistImages;
      this.punchlist.assetId = payload.assetId;
      this.punchlist.vendorId = payload.vendorId;
      if (this.punchlist.assetId != null && this.punchlist.assetId != "null" && this.punchlist.assetId != "") {
        this.punchlist.isSystemDisable = true;
      }
      this.punchlist.assetName = payload.assetName;
      this.punchlist.selectedAsset = this.assetList.filter(x => x.id == this.punchlist.assetId);
      this.punchlist.selectedCategory = this.category.filter(x => x.text == payload.category);
      this.punchlist.category = payload.category;
      this.punchlist.disciplineId = payload.disciplineId;
      this.punchlist.disciplineName = payload.disciplineName;
      this.punchlist.selectedDiscipline = this.disciplineList.filter(x => x.id == this.punchlist.disciplineId);
      this.punchlist.selectedItemStatus = this.item_status.filter(x => x.text == payload.itemStatus);
      this.punchlist.itemStatus = payload.itemStatus;
      this.selectedItem_status = this.punchlist.selectedItemStatus[0] != undefined ? this.punchlist.selectedItemStatus[0].text : '';
      this.punchlist.status = payload.status;
      this.punchlist.activityCodeId = payload.activityCodeId;
      this.punchlist.selectedActivityCode = this.activityCodeList.filter(x => x.id == this.punchlist.activityCodeId);
      this.punchlist.objectCodeId = payload.objectCodeId;
      this.punchlist.selectedObjectCode = this.objectCodeList.filter(x => x.id == this.punchlist.objectCodeId);
      this.punchlist.optionalModifierId = payload.optionalModifierId;
      this.punchlist.selectedOptionalModifier = this.optionalModifierList.filter(x => x.id == this.punchlist.optionalModifierId);
      this.punchlist.selectedVendor = this.vendorList.filter(x => x.id == this.punchlist.vendorId);
      this.punchlist.photoName = payload.photoName;
      this.punchlist.imgUrl = payload.base64Image;
      this.punchlist.firstOilRequired = payload.firstOilRequired;
      if (this.punchlist.photoName != null && this.punchlist.imgUrl) {
        this.punchlist.isAvailablePhoto = true;
      } else {
        this.punchlist.isAvailablePhoto = false;
      }
      this.punchlist.locationLat = payload.locationLat;
      this.punchlist.locationLong = payload.locationLong;
      if (this.punchlist.locationLat != 0 && this.punchlist.locationLong != 0) {
        this.punchlist.isAvailableLocation = true;
        this.mapInitializer();
      }
      else {
        this.punchlist.isAvailableLocation = false;
      }
      this.createRowData(this.punchlist.punchlistDocuments);
      this.punchlist.irnId = payload.irnId;
      //get the checksheet related data for the edit punchlist and display relavant messages
      if (payload.relatedIssuedCheckSheetsCount != null && payload.relatedIssuedCheckSheetsCount != 0 && payload.relatedApprovedCheckSheetsCount != null && payload.relatedApprovedCheckSheetsCount != 0) {
        this.punchlist.relatedIssuedAndApprovedCheckSheetsCount = true;
      } else if (payload.relatedIssuedCheckSheetsCount != null && payload.relatedIssuedCheckSheetsCount != 0) {
        this.punchlist.relatedIssuedCheckSheetsCount = true;
      } else if (payload.relatedApprovedCheckSheetsCount != null && payload.relatedApprovedCheckSheetsCount != 0) {
        this.punchlist.relatedApprovedCheckSheetsCount = true;
      }

      if(payload.itemStatus != "Initial" || payload.docuSignStatus != null) {
        this.isInitial = false;
      } else {
        //do nothing
      }

    } else {
    }
    console.log(this.punchlist.selectedItemStatus);
    //hide loading
    this.loadingService.hideLoading();
  }

  public selectedAssetId(value: any): void {
    console.log("Selected Asset Value: " + JSON.stringify(value));

    if (this.punchlist.assetId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.assetName = value.text;
      this.punchlist.assetId = value.id;
      this.punchlist.selectedAsset = this.assetList.filter(x => x.id == this.punchlist.assetId);
      this.punchlist.isSystemDisable = true;
      this.punchlist.parentId = null;
      this.punchlist.parentName = '';
    } else {
      //nothing to do
    }
  }

  selectedAssetDisplay(assetId) {
    console.log("selectedAssetDisplay() - assetId: " + assetId);

    // Set selected asset
    this.punchlist.selectedAsset = this.getAssetNameById(assetId);
    console.log("assetId " + JSON.stringify(this.punchlist.selectedAsset));

  }

  getAssetNameById(id) {
    return this.assetList.filter(x => (x.id === id));
  }


  categorySelected(event: any) {
    this.punchlist.category = event.text;
    this.selectedCategory = event["text"]
  }

  public selectedDisciplineId(value: any): void {
    console.log("Selected Discipline Value: " + JSON.stringify(value));

    if (this.punchlist.disciplineId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.disciplineName = value.text;
      this.punchlist.disciplineId = value.id;
      this.punchlist.selectedDiscipline = this.disciplineList.filter(x => x.id == this.punchlist.disciplineId);
    } else {
      //nothing to do
    }
  }

  //Remove the selected category
  public removeCategory(event: any) {
    this.punchlist.category = ""
    this.selectedCategory = ""
  }

  public removeDisciplineId(value: any): void {
    console.log("removeDisciplineId");

    this.punchlist.disciplineName = null;
    this.punchlist.disciplineId = null;
    this.punchlist.selectedDiscipline = null;
  }

  selectedtDisciplineDisplay(disciplineId) {
    console.log("selectedDisciplineDisplay() - disciplineId: " + disciplineId);

    // Set selected discipline
    this.punchlist.selectedDiscipline = this.getDisciplineNameById(disciplineId);
    console.log("disciplineId " + JSON.stringify(this.punchlist.selectedDiscipline));

  }

  getDisciplineNameById(id) {
    return this.disciplineList.filter(x => (x.id === id));
  }

  public selectedSubSystemId(value: any): void {
    console.log("Selected SubSystem Value: " + JSON.stringify(value));
    if (this.punchlist.subsystemId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.subSystemName = value.text;
      this.punchlist.subsystemId = value.id;
      this.punchlist.selectedSubSystem = this.subSystemList.filter(x => x.id == this.punchlist.subsystemId);
      if (this.punchlist.selectedSubSystem[0].parentNo != null && this.punchlist.selectedSubSystem[0].parentName != null && this.punchlist.selectedSubSystem[0].parentId != null) {
        this.punchlist.parentName = this.punchlist.selectedSubSystem[0].parentNo + ' ' + this.punchlist.selectedSubSystem[0].parentName;
        this.punchlist.parentId = this.punchlist.selectedSubSystem[0].parentId;
      }
      else {
        this.punchlist.parentName = '';
        this.punchlist.parentId = null;
      }
      this.punchlist.isAssetDisable = true;
      this.selectedAssetList = [];
    } else {
      //nothing to do
    }
  }

  selectedSubSystemDisplay(subsystemId) {
    console.log("selectedSubSystemDisplay() - subsystemId: " + subsystemId);

    // Set selected subsystem
    this.punchlist.selectedSubSystem = this.getSubSystemNameById(subsystemId);
    console.log("subsystemId " + JSON.stringify(this.punchlist.selectedSubSystem));

  }

  public selectedActivityCodeId(value: any): void {
    console.log("Selected Activity_Code Value: " + JSON.stringify(value));
    if (this.punchlist.activityCodeId != value.id) {
      console.log("Inside if")
      this.punchlistDisable = true;
      this.punchlist.activityCodeName = value.text;
      this.punchlist.activityCodeId = value.id;
      this.punchlist.selectedActivityCode = this.activityCodeList.filter(x => x.id == this.punchlist.activityCodeId);
    } else {
      //nothing to do
    }
  }

  selectedActivityCodeDisplay(activityCodeId) {
    console.log("selectedActivityCodeDisplay() - activityCodeId: " + activityCodeId);

    // Set selected activity code
    this.punchlist.selectedActivityCode = this.getActivityCodeById(activityCodeId);
    console.log("activityCodeId " + JSON.stringify(this.punchlist.selectedActivityCode));

  }

  getActivityCodeById(id) {
    return this.activityCodeList.filter(x => (x.id === id));
  }

  public selectedObjectCodeId(value: any): void {
    console.log("Selected Object_Code Value: " + JSON.stringify(value));
    if (this.punchlist.objectCodeId != value.id) {
      console.log("Inside if")
      this.punchlistDisable = true;
      this.punchlist.objectCodeName = value.text;
      this.punchlist.objectCodeId = value.id;
      this.punchlist.selectedObjectCode = this.objectCodeList.filter(x => x.id == this.punchlist.objectCodeId);
    } else {
      //nothing to do
    }
  }

  selectedObjectCodeDisplay(objectCodeId) {
    console.log("selectedObjectCodeDisplay() - objectCodeId: " + objectCodeId);

    // Set selected activity code
    this.punchlist.selectedObjectCode = this.getObjectCodeById(objectCodeId);
    console.log("objectCodeId " + JSON.stringify(this.punchlist.selectedObjectCode));

  }

  getObjectCodeById(id) {
    return this.objectCodeList.filter(x => (x.id === id));
  }

  public selectedOptionalModifierId(value: any): void {
    console.log("Selected Optional_Modifier Value: " + JSON.stringify(value));
    if (this.punchlist.optionalModifierId != value.id) {
      console.log("Inside if")
      this.punchlistDisable = true;
      this.punchlist.optionalModifierName = value.text;
      this.punchlist.optionalModifierId = value.id;
      this.punchlist.selectedOptionalModifier = this.optionalModifierList.filter(x => x.id == this.punchlist.optionalModifierId);
    } else {
      //nothing to do
    }
  }

  selectedOptionalModofoerDisplay(optionalModifierId) {
    console.log("selectedOptionalModifierDisplay() - optionalModofoerId: " + optionalModifierId);

    // Set selected activity code
    this.punchlist.selectedOptionalModifier = this.getOptionalModifierById(optionalModifierId);
    console.log("optionalModifierId " + JSON.stringify(this.punchlist.selectedOptionalModifier));

  }

  getOptionalModifierById(id) {
    return this.optionalModifierList.filter(x => (x.id === id));
  }

  getSubSystemNameById(id) {
    return this.subSystemList.filter(x => (x.id === id));
  }

  public itemStatusSelected(event: any) {
    this.punchlist.itemStatus = event.text;
    this.punchlist.selectedItemStatus = event.text;
    this.selectedItem_status = event["text"]
  }

  public removedAsset(value: any): void {
    console.log("removeSelectedAsset");

    // Update asset details
    this.punchlist.assetName = null;
    this.punchlist.assetId = null;
    this.punchlist.selectedAsset = null;
    this.punchlist.isSystemDisable = false;

    console.log("Removed Asset Value: " + value);
  }

  public removedSubSystem(value: any): void {
    console.log("removeSelectedSubSystem");

    // Update asset details
    this.punchlist.subSystemName = null;
    this.punchlist.subsystemId = null;
    this.punchlist.selectedSubSystem = null;
    this.punchlist.isAssetDisable = false;
    this.punchlist.parentId = null;
    this.punchlist.parentName = '';

    console.log("Removed Asset Value: " + value);
  }

  public removedActivityCode(value: any): void {
    console.log("removedActivityCode");

    // Update activity code details
    this.punchlist.activityCodeName = null;
    this.punchlist.activityCodeId = null;
    this.punchlist.selectedActivityCode = null;

    console.log("Removed Activity Code Value: " + value);
  }

  public removedObjectCode(value: any): void {
    console.log("removedObjectCode");

    // Update object code details
    this.punchlist.objectCodeId = null;
    this.punchlist.objectCodeName = null;
    this.punchlist.selectedObjectCode = null;

    console.log("Removed Object Code Value: " + value);
  }

  public removedOptionalModifier(value: any): void {
    console.log("removeSeleremovedOptionalModifierctedAsset");

    // Update optional modifier details
    this.punchlist.optionalModifierId = null;
    this.punchlist.optionalModifierName = null;
    this.punchlist.selectedOptionalModifier = null;

    console.log("Removed Optional Modifier Value: " + value);
  }

  IsValidDate(value: Date): boolean {
    if (value != undefined) {
      var date = new Date(value);
      if (date.toString() == 'Invalid Date') {
        return false;
      }
      else {
        return true;
      }
    } else {
      return true;
    }
  }

  public selectedVendorId(value: any): void {
    console.log("Selected Vendor Value: " + JSON.stringify(value));

    if (this.punchlist.vendorId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.vendorName = value.text;
      this.punchlist.vendorId = value.id;
      this.punchlist.selectedVendor = this.vendorList.filter(x => x.id == this.punchlist.vendorId);
    } else {
      //nothing to do
    }
  }

  public removeVendorId(value: any): void {
    console.log("removeVendorId");

    this.punchlist.vendorName = null;
    this.punchlist.vendorId = null;
    this.punchlist.selectedVendor = null;
  }

  showIRNWarn() {
    this.warnModalRef = this.modalService.show(this.confirmationWarn);
  }
  confirmIRNWarn() {
    this.punchlist.irnId = null;
    let data = {
      id: this.punchlist.punchlistId,
      punchlistDescription: this.punchlist.punchlistDescription,
      category: this.punchlist.category,
      dateRaised: this.punchlist.dateraised != undefined ? this.punchlist.dateraised : null,
      dateClosed: this.punchlist.closedDate != undefined ? this.punchlist.closedDate : null,
      estimatedCompletionDate: this.punchlist.targetCompletionDate != undefined ? this.punchlist.targetCompletionDate : null,
      raisedBy: this.punchlist.raisedby,
      estimatedManHours: this.punchlist.estimatedManHours,
      materialsRequired: this.punchlist.materialRequired,
      itemStatus: this.punchlist.itemStatus,
      comments: this.punchlist.comments,
      assetId: this.punchlist.assetId,
      disciplineId: this.punchlist.disciplineId,
      subSystemId: this.punchlist.subsystemId,
      systemId: this.punchlist.parentId != null ? this.punchlist.parentId : null,
      status: this.punchlist.status,
      projectId: this.projectId,
      activityCodeId: this.punchlist.activityCodeId,
      objectCodeId: this.punchlist.objectCodeId,
      optionalModifierId: this.punchlist.optionalModifierId,
      punchlistDocuments: this.punchlist.punchlistDocuments,
      punchlistImages: this.punchlist.punchlistImages,
      irnId: this.punchlist.irnId,
      vendorId: this.punchlist.vendorId,
      firstOilRequired: this.punchlist.firstOilRequired
    };

    var json = JSON.stringify(data);
    console.log(json);
    this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
    this.punchlistService.updatePunchlist(this.token, json, this);
  }
  cancelIRNWarn() {

  }
  IsAssetInIRN(): boolean {
    if (this.punchlist.subsystemId) {
      return false
    } else {
      if (this.punchlist.assetId) {
        let itemIndex = this.irnAssetsList.findIndex(item => item.assetId == this.punchlist.assetId);
        if (this.irnAssetsList[itemIndex]) {
          console.log(this.irnAssetsList[itemIndex])
          return true;
        } else {
          return false;
        }
      }
    }
  }
  getIrnfromId() {
    if (this.punchlist.irnId) {
      this.loadingService.showLoading(true, false, 'Loading IRN data', 0);
      this.irnService.getIrnDataByIrnId(this.punchlist.irnId, this)
    } else {
      this.Update();
    }
  }

  /**
   * update existing punchlist
   */
  Update() {
    //validate punchlist category for the undefined, null and empty
    if (this.punchlist.category == undefined || this.punchlist.category == null || this.punchlist.category == "") {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if (this.punchlist.disciplineId == undefined || this.punchlist.disciplineId == null) {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if ((this.punchlist.punchlistDescription == undefined || this.punchlist.punchlistDescription.trim() == "") &&
      (this.punchlist.activityCodeId == null || this.punchlist.activityCodeId == "") &&
      (this.punchlist.objectCodeId == null || this.punchlist.objectCodeId == "") &&
      (this.punchlist.optionalModifierId == null || this.punchlist.optionalModifierId == "")) {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if (this.punchlist.dateraised == undefined || this.punchlist.dateraised == null) {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }
    //Validate raisedby field for the undefined, null and empty
    else if (this.punchlist.raisedby == undefined || this.punchlist.raisedby == null || this.punchlist.raisedby == "") {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if ((this.punchlist.assetId == undefined || this.punchlist.assetId == null) && (this.punchlist.subsystemId == undefined || this.punchlist.subsystemId == null)) {
      this.alertService.clear();
      this.alertService.error(this.emptyAssetOrSystem);
    } else if (!this.IsValidDate(this.punchlist.dateraised) || !this.IsValidDate(this.punchlist.closedDate) || !this.IsValidDate(this.punchlist.targetCompletionDate)) {
      this.alertService.clear();
      this.alertService.error(this.invalidDate);
    } else if (this.punchlist.closedDate != null && this.punchlist.closedDate != undefined && this.punchlist.itemStatus != "Closed") {
      this.alertService.clear();
      this.alertService.error(this.statusError);
    } else if (this.punchlist.itemStatus == "Closed" && (this.punchlist.closedDate == null || this.punchlist.closedDate == undefined)) {
      this.alertService.clear();
      this.alertService.error(this.emptyCloseDate);
    } else if (this.IsMaxAttachmentSizeExceeded()) {
      this.alertService.clear();
      this.alertService.error(this.maxTotalSizeExceed);
    } else if (this.punchlist.irnId && !this.IsAssetInIRN()) {
      this.showIRNWarn();
    }
    else {
      let data = {
        id: this.punchlist.punchlistId,
        punchlistDescription: this.punchlist.punchlistDescription,
        category: this.punchlist.category,
        dateRaised: this.punchlist.dateraised != undefined ? this.punchlist.dateraised : null,
        dateClosed: this.punchlist.closedDate != undefined ? this.punchlist.closedDate : null,
        estimatedCompletionDate: this.punchlist.targetCompletionDate != undefined ? this.punchlist.targetCompletionDate : null,
        raisedBy: this.punchlist.raisedby,
        estimatedManHours: this.punchlist.estimatedManHours,
        materialsRequired: this.punchlist.materialRequired,
        itemStatus: this.punchlist.itemStatus,
        comments: this.punchlist.comments,
        assetId: this.punchlist.assetId,
        disciplineId: this.punchlist.disciplineId,
        subSystemId: this.punchlist.subsystemId,
        systemId: this.punchlist.parentId != null ? this.punchlist.parentId : null,
        status: this.punchlist.status,
        projectId: this.projectId,
        activityCodeId: this.punchlist.activityCodeId,
        objectCodeId: this.punchlist.objectCodeId,
        optionalModifierId: this.punchlist.optionalModifierId,
        punchlistDocuments: this.punchlist.punchlistDocuments,
        punchlistImages: this.punchlist.punchlistImages,
        irnId: this.punchlist.irnId,
        vendorId: this.punchlist.vendorId,
        firstOilRequired: this.punchlist.firstOilRequired
      };

      var json = JSON.stringify(data);
      console.log(json);
      this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
      this.punchlistService.updatePunchlist(this.token, json, this);
    }
  }

  cancel() {
    this.onClose.emit(true);
    this.bsModalRef.hide()
  }

  /**
   * Get file from local storage
   * @param event 
   */
  onFileChanged(event) {
    console.log("onfile change= ")
    let i: number = 0;
    for (i = 0; i < event.target.files.length; i++) {
      this.uploadingFile = <File>event.target.files[i];
      if (this.uploadingFile != undefined) {
        let parts = this.uploadingFile.name.split('.')
        if (parts.length > 1) {
          this.fileExtension = parts.pop()
          this.uploadFileName = parts.join('.')
          var documentId = this.createUUID();

          if (this.validateForm()) {
            //set for inserting to the database
            var punchlistDoc = {
              id: documentId,
              punchlistId: this.punchlist.punchlistId,
              fileName: this.uploadingFile.name,
              contentType: this.fileExtension,
              projectId: this.projectId,
              filePath: '',
              isAdded: true,
              isDeleted: false,
              addedUserId: this.userId,
              fileSize:this.uploadingFile.size
            };
            this.punchlist.punchlistDocuments.push(punchlistDoc);

            //set for uploading to the local file system
            var punchlistUploadDoc =
            {
              docId: documentId,
              docName: this.uploadFileName,
              docType: this.fileExtension,
              file: this.uploadingFile,
              isAdded: true,
              isDeleted: false
            };
            this.punchlistUploadDocuments.push(punchlistUploadDoc);
          }
        } else {
          this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        this.uploadFileName = '';
      }
    }

    //refresh ag-grid 
    this.createRowData(this.punchlist.punchlistDocuments.filter(x => !x.isDeleted));
  }

  /**
   * Upload documents into local storage folder
   */
  onUpload() {
    var loadingMessage;
    this.translate.get('NEW_PUNCHLIST_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    let token = UserVariable.getUserToken();
    this.loadingService.showLoading(true, null, loadingMessage, null);

    this.punchlistUploadDocuments.forEach(element => {
      if (element.isAdded && !element.isDeleted) {
        this.uploadFileNameWithExt = element.docName + "." + element.docType;
        const uploadData = new FormData();
        console.log("element.docId = ", element.docId);
        console.log("this.punchlist.id = ", this.punchlist.punchlistId);
        uploadData.append("docId", element.docId);
        uploadData.append("punchlistId", this.punchlist.punchlistId);
        uploadData.append("projectId", this.projectId);
        uploadData.append("filePath", '');
        uploadData.append("fileName", this.uploadFileNameWithExt);
        uploadData.append("contentType", element.docType);
        uploadData.append("token", token);
        uploadData.append("file", element.file, element.docName);
        uploadData.append("addedUserId", '');
        uploadData.append("isAdded", element.isAdded);
        uploadData.append("isDeleted", element.isDeleted);

        this.fileUploadService.uploadFile(uploadData, this);
      }
    });
  }

  /**
   * ag-grid punchlist documents
   */
  private prepareAgGrid() {
    this.columnDefs = [
      {
        headerName: 'Document Id',
        field: 'docId',
        hide: true
      },
      {
        headerName: 'Document Name',
        field: 'docName',
        width: 500,
        cellClass: "cell-wrap-text",
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: 'Content Type',
        field: 'docType',
        width: 400,
        cellClass: "cell-wrap-text",
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: 'Delete',
        field: 'docDelete',
        width: 200,
        cellStyle: { 'text-align': 'center', "white-space": "normal" },
        cellClass: "cell-wrap-text",
        cellRenderer: function (params) {
          return ('<button type="button" [disabled]="false" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"(click)="deleteButtonClick($event)"><i class="far fa-trash-alt" aria-hidden="true"></i></button>');
        }
      }
    ];

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        //resizable: true,
        // sortable: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false
    };

  }

  /**
   * Initializing data for the punchlist documents ag-grid
   * @param documents 
   */
  private createRowData(documents: Array<PunchlistDocument>) {
    var rowData: any[] = [];
    documents.forEach(punchlistDoc => {
      rowData.push({
        docId: punchlistDoc.id,
        docName: punchlistDoc.fileName,
        docType: punchlistDoc.contentType
      });
    })

    this.rowData = rowData;
    this.prepareAgGrid();
  }

  /**
   * Delete punchlist document
   * @param event 
   */
  public onDelete(event) {
    if (event.colDef.field == "docDelete" && this.isInitial == true) {
      var selectedRowData = this.gridApi.getSelectedRows();
      this.gridApi.updateRowData({ remove: selectedRowData });
      var docId = selectedRowData[0].docId;
      this.punchlist.punchlistDocuments.forEach(doc => {
        if (doc.id == docId) {
          doc.isDeleted = true;
        }
      });
      this.punchlistUploadDocuments.forEach(doc => {
        if (doc.docId == docId) {
          doc.isDeleted = true;
        }
      });
      this.createRowData(this.punchlist.punchlistDocuments.filter(x => !x.isDeleted));
    }
  }

  onGridReady(params: any) {
    console.log("ongrid ready")
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay()
  }

  /**
   * Generate unique identifier
   */
  public createUUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  validateForm(): boolean {
    this.uploadFileName = this.uploadFileName.trim()
    if (this.uploadingFile.size > this.MAX_FILE_SIZE) {
      this.translate.get('NEW_PUNCHLIST_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    if (this.uploadingFile.type != 'application/pdf') {
      this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    if (this.uploadingFile.name.length > 100) {
      this.translate.get('NEW_PUNCHLIST_DOCUMENT.FILE_NAME_SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    return true;
  }

  /**
   * Get file from local storage
   * @param event 
   */
  onFileChanged_Image(event) {
    console.log("onfile change image= ")

    let fileCount = event.target.files.length;
    let i: number = 0;
    for (i = 0; i < fileCount; i++) {
      this.uploadingFile_Image = <File>event.target.files[i];
      if (this.uploadingFile_Image != undefined) {
        let parts = this.uploadingFile_Image.name.split('.')
        if (parts.length > 1) {
          this.fileExtension_Image = parts.pop()
          this.uploadFileName_Image = parts.join('.')
          let imageId = this.createUUID();

          if (this.validateForm_Image()) {
            //set for inserting to the database
            let punchlistImage = {
              id: imageId,
              punchlistId: this.punchlist.punchlistId,
              fileName: this.uploadingFile_Image.name,
              contentType: this.fileExtension_Image,
              projectId: this.projectId,
              filePath: '',
              isAdded: true,
              isDeleted: false,
              addedUserId: this.userId,
              isNew: true,
              base64Image: '',
              fileSize:this.uploadingFile_Image.size
            };

            //show selected image preview
            let reader = new FileReader();
            reader.onload = (e: any) => {
              punchlistImage.base64Image = e.target.result;
              this.punchlist.punchlistImages.push(punchlistImage);
            }
            reader.readAsDataURL(this.uploadingFile_Image);


            //set for uploading to the local file system
            let punchlistUploadImage =
            {
              fileId: imageId,
              fileName: this.uploadFileName_Image,
              fileType: this.fileExtension_Image,
              file: this.uploadingFile_Image,
              isAdded: true,
              isDeleted: false
            };
            this.punchlistUploadImages.push(punchlistUploadImage);
          }
        } else {
          this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        this.uploadFileName_Image = '';
      }
    }

    //update label text
    if (fileCount == 0) {
      this.translate.get('NEW_PUNCHLIST_IMAGE.NO_FILES').subscribe((res: string) => {
        this.fileSelTxt = res;
      });
    } else if (fileCount == 1) {
      this.fileSelTxt = this.uploadingFile_Image.name;
    } else {
      this.translate.get('NEW_PUNCHLIST_IMAGE.FILES_SELECTED').subscribe((res: string) => {
        this.fileSelTxt = fileCount + res;
      });
    }
  }

  validateForm_Image(): boolean {
    this.uploadFileName_Image = this.uploadFileName_Image.trim()
    if (this.uploadingFile_Image.type == 'image/png' || this.uploadingFile_Image.type == 'image/jpg' || this.uploadingFile_Image.type == 'image/jpeg' || this.uploadingFile_Image.type == 'image/gif') {
      //correct type
    } else {
      this.translate.get('NEW_PUNCHLIST_IMAGE.INVALID_FILE_TYPE').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    if (this.uploadingFile_Image.name.length > 100) {
      this.translate.get('NEW_PUNCHLIST_IMAGE.FILE_NAME_SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    return true;
  }

  onUpload_Images() {
    var loadingMessage;
    this.translate.get('NEW_PUNCHLIST_IMAGE.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    let token = UserVariable.getUserToken();
    this.loadingService.showLoading(true, null, loadingMessage, null);

    this.punchlistUploadImages.forEach(element => {
      if (element.isAdded && !element.isDeleted) {
        this.uploadFileNameWithExt_Image = element.fileName + "." + element.fileType;
        const uploadData = new FormData();
        console.log("element.fileId = ", element.fileId);
        console.log("this.punchlist.id = ", this.punchlist.punchlistId);
        uploadData.append("fileId", element.fileId);
        uploadData.append("punchlistId", this.punchlist.punchlistId);
        uploadData.append("projectId", this.projectId);
        uploadData.append("filePath", '');
        uploadData.append("fileName", this.uploadFileNameWithExt_Image);
        uploadData.append("contentType", element.fileType);
        uploadData.append("token", token);
        uploadData.append("file", element.file, element.fileName);
        uploadData.append("addedUserId", '');
        uploadData.append("isAdded", element.isAdded);
        uploadData.append("isDeleted", element.isDeleted);

        this.fileUploadService.uploadFile_Images(uploadData, this);
      }
    });
  }

  removeImage(imageId) {
    if (this.isInitial) {
      this.punchlist.punchlistImages.forEach(image => {
        if (image.id == imageId) {
          image.isDeleted = true;
        }
      });
      this.punchlistUploadImages.forEach(image => {
        if (image.fileId == imageId) {
          image.isDeleted = true;
        }
      });
    } else {
      //not in initial status
    }
  }

  openImage(data) {
    var image = new Image();
    if (data) {
      if (data.isNew == true) {
        //not uploaded images
        image.src = data.base64Image;
      } else {
        //uploaded images
        image.src = "data:image/jpg;base64," + data.base64Image;
      }
        var w = window.open("");        
        w.document.write(image.outerHTML);
        w.document.title = data.fileName;
        w.document.close();
    } else {
      //empty
    }
  }

  //check total attachment size exceed 25MB
  IsMaxAttachmentSizeExceeded() {
    var isExceeded = false;
    var totalSize = 0;

    //get document sizes
    this.punchlist.punchlistDocuments.forEach(element => {
      if (!element.isDeleted) {
        totalSize += element.fileSize;
      } else {
        //deleted
      }
    });
    
    //get image sizes
    this.punchlist.punchlistImages.forEach(element => {
      if (!element.isDeleted) {
        totalSize += element.fileSize;
      } else {
        //deleted
      }
    });

    if (totalSize > this.MAX_FILE_SIZE) {
      isExceeded = true;
    } else {
      //false
    }

    return isExceeded;
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.UPDATE_PUNCHLIST) {
      //Upload documents
      this.onUpload();
      //Upload images
      this.onUpload_Images();
      this.loadingService.hideLoading();
      this.alertService.success("Successfully updated the punchlist");
      //close modal
      this.cancel();
      //update punchlist grid
      this.punchlistService.loadPLTableDataRequest();
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_DOCUMENT) {

    }
    else if (serviceType == WsType.GET_ASSETS) {
      this.assetList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.assetList.push({ 'id': element.tagId, 'text': element.tagNo });
        });
        this.assetDisable = false;
      }
      let plId = this.sharedService.getFileId();
      if (plId != null) {
        this.punchlistService.getPunchlistDetails(plId, this);
      }
    }
    else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      this.subSystemList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.subSystemList.push({ 'id': element.id, 'text': element.systemNo + ' ' + element.systemName, 'parentId': element.parentId, 'parentName': element.parentSystemName, 'parentNo': element.parentSystemNo });
        });
        this.subSystemDisable = false;
      }
    }
    else if (serviceType == WsType.GET_DISCIPLINES) {
      this.disciplineList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.disciplineList.push({ 'id': element.disciplineId, 'text': element.disciplineName });
        });
        this.disciplineDisable = false;
      }
    }
    else if (serviceType == WsType.GET_PUNCH_CODES) {
      this.activityCodeList = [];
      this.optionalModifierList = [];
      this.objectCodeList = [];
      if (data.payload != null) {
        let activityPunchCode = data.payload.filter(x => x.punchType == 'Action Code');
        activityPunchCode.forEach(element => {
          this.activityCodeList.push({ 'id': element.id, 'text': element.punchcode + ' - ' + element.description });
        });
        let optionalPunchCode = data.payload.filter(x => x.punchType == 'Optional Modifier');
        optionalPunchCode.forEach(element => {
          this.optionalModifierList.push({ 'id': element.id, 'text': element.punchcode + ' - ' + element.description });
        });
        let objectPunchCode = data.payload.filter(x => x.punchType == 'Object Code');
        objectPunchCode.forEach(element => {
          this.objectCodeList.push({ 'id': element.id, 'text': element.punchcode + ' - ' + element.description });
        });
      }
    }
    else if (serviceType == WsType.GET_VENDOR_LIST) {
      this.vendorList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName });
        });
        this.vendorDisable = false;
      }
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_IMAGE) {

    }
    else if (serviceType == WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID) {
      if (data.payload != null) {
        this.displayData(data.payload);
      }
    } else if (serviceType == WsType.GET_IRN_BY_IRN_ID) {
      this.loadingService.hideLoading();
      if (data.payload != null) {
        this.punchlist.irnId = data.payload.irnId
        this.punchlistIRNNumber = data.payload.irnNumber
        this.irnAssetsList = [];
        if (data.payload.assets.length > 0) {
          this.irnAssetsList = data.payload.assets;
        }
      }
      this.Update();
    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    this.loadingService.hideLoading();
    if (serviceType == WsType.ADD_NEW_PUNCHLIST) {
      this.alertService.error(data.statusDescription);
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_DOCUMENT) {
      this.alertService.error(data.statusDescription);
    }
    else if (serviceType == WsType.GET_ASSETS) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_DISCIPLINES) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_VENDOR_LIST) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_IMAGE) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription);
    }
    else if (serviceType == WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_PUNCH_CODES) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_IRN_BY_IRN_ID) {
      this.loadingService.hideLoading();
      this.Update();
    }
  }

  checkFirstOilRequired(event: any) {
    if (event == true) {
      this.punchlist.firstOilRequired = true;
    }
    else {
      this.punchlist.firstOilRequired = false;
    }
  }
}


