import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../util/loading/loading.service';
import { ResetPasswordValidation } from './reset-password-validation.service';
import { WsCallback } from '../../../util/ws-callback.interface';
import { WsResponse } from '../../../util/ws-response.model';
import { WsType } from '../../../util/ws-type';
import { ResetPassword } from './reset-password.service';
import { AlertService } from '../../../util/alert/alert.service';
import { LocalStorage } from '../../../util/localstorage.service';

@Component({
	selector: 'app-forgotpassword',
	templateUrl: './forgotpassword.component.html',
	styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit, WsCallback {

   newPassword: String = "";
   confirmPassword: String = "";

   private action: any;
   private key: any;
   private id: any;

	constructor(private router: Router,  private activatedRoute: ActivatedRoute,
		private loadingService: LoadingService, private resetPasswordValidation: ResetPasswordValidation,
		private resetPasswordService: ResetPassword, private alertService: AlertService) { }

	ngOnInit() {
		//Reading url parameters
		this.activatedRoute.paramMap.subscribe(params => {
			this.action = this.activatedRoute.snapshot.queryParams.action;
			this.key = this.activatedRoute.snapshot.queryParams.key;
			this.id = this.activatedRoute.snapshot.queryParams.id;
		});

      //reading parameters
		console.log(this.activatedRoute.snapshot.queryParamMap);
		this.resetPasswordValidation.setCallback(this);
   }

   ngAfterViewInit() {
	//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
	//Add 'implements AfterViewInit' to the class.

	console.log(this.action + '---' + this.key + '--' + this.id);

	if (this.action == 'validate' && this.key != null && this.id != null) {
		//show the loading
		this.loadingService.showLoading(true, false, 'Password reset request is being validated', 0);
		//send the data to the server
		this.resetPasswordValidation.getPasswordResetUrl(this.key, this.id);
	} else {
		console.log('Some parameter are not available');
	}
}
   
   public resetPassword(): void {
	   if(this.newPassword.trim() != "" && this.confirmPassword.trim() != ""){
		this.resetPasswordService.setCallback(this);
		this.resetPasswordService.getResetPassword(this.newPassword,
		this.confirmPassword,  this.activatedRoute.snapshot.queryParamMap.get('key'));
	   }else{
		this.alertService.clear();
		this.alertService.error("The new password and the confirmation password cannot be empty.");
	   }
	}

	onSuccess(data: WsResponse, serviceType: WsType): void {      
		if (serviceType == WsType.PASSWORD_RESET_VALIDATE) {
			this.loadingService.hideLoading();
		} else if (serviceType == WsType.PASSWORD_RESET) {
         //this.router.navigateByUrl('login');
         this.loadingService.hideLoading();
			localStorage.removeItem(LocalStorage.LOGGED_USER);
			this.router.navigateByUrl('changePasswordStatus');
		}
	}

	onFail(res: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.PASSWORD_RESET_VALIDATE) {
			this.loadingService.hideLoading();
			this.router.navigateByUrl('login');
		} else if (serviceType == WsType.PASSWORD_RESET) {
			this.alertService.error(res.statusDescription);
		}
	}

	public cancelForgotPassword(): void {
		this.router.navigateByUrl('/login')
	}

}
