import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid';
import { AlertService } from '../../util/alert/alert.service';
import { CommonUtil } from '../../util/common/common-utils';
import { UserVariable } from '../../util/common/user-variable';
import { LoadingService } from '../../util/loading/loading.service';
import { BulkDataImportWidgetService } from './bulk-data-import-widget.service';

@Component({
  selector: 'app-bulk-data-import-widget',
  templateUrl: './bulk-data-import-widget.component.html',
  styleUrls: ['./bulk-data-import-widget.component.css']
})
export class BulkDataImportWidgetComponent implements OnInit {

  public hasErrorList : boolean = false;
  public showCount : boolean = false;
  public count: number = 0;
  public countMessage : string = "";

  errorDataList : any; 
  bi_translated_data: any;
  columnDefs: any;
  rowData = [];
	private gridApi;
  private gridOptions: GridOptions;
  private gridColumnApi;
  private fileName: string = null;

  constructor(private loadingService: LoadingService, private bulkImportService: BulkDataImportWidgetService, 
              private translate: TranslateService, private commonUtil: CommonUtil, private alertService: AlertService) {
    this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
   }

  ngOnInit() {
    let browserLang = this.translate.getBrowserLang();
		this.translate.getTranslation(browserLang).subscribe((res: string) => {
			this.bi_translated_data = res['BULK_IMPORT']
      this.columnDefs = [
        {
          headerName: this.bi_translated_data.MODULE,
          field: "module",
          filter: "agTextColumnFilter"        
        },
        {
          headerName: this.bi_translated_data.SYSTEM,
          field: "system",
          filter: "agTextColumnFilter"
        },
        {
          headerName: this.bi_translated_data.VENDOR,
          field: "vendor",
          filter: "agTextColumnFilter"
        },
        {
          headerName: this.bi_translated_data.ASSET,
          field: "asset",
          filter: "agTextColumnFilter"
        },
        {
          headerName: this.bi_translated_data.CS_CODE,
          field: "cscode",
          filter: "agTextColumnFilter"
        },
        {
          headerName: this.bi_translated_data.STATUS,
          field: "status",
          filter: "agTextColumnFilter"
        }
      ]
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    window.addEventListener("resize", function () {
        setTimeout(function () {
            params.api.sizeColumnsToFit();
        });
    });
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      let loadingMessage;
      this.translate.get('BULK_IMPORT.IMPORTING_MESSAGE').subscribe((res: string) => {
        loadingMessage = res;
      });

      // check this
      let token = UserVariable.getUserToken();
      if (!this.commonUtil.validateToken(token)) {
        return;
      }

      this.loadingService.showLoading(true, null, loadingMessage, null);   
      const uploadData = new FormData();

      uploadData.append("token", token);
      uploadData.append("file", file);
      uploadData.append("fileName", this.fileName);
      this.bulkImportService.uploadCOMMData(uploadData).subscribe(
				data => {
          console.log('[uploadCOMMData] (success) ' + JSON.stringify(data.payload));
          this.errorDataList = JSON.parse(JSON.stringify(data.payload));
          let countData = this.errorDataList[this.errorDataList.length -1];
          this.count = Number(countData["Status"]);
          if(this.count >=0 ) {
            this.showCount = true;
            if(this.count > 0) {
              this.countMessage = this.count + " records processed successfully";
            }
            else {
              this.countMessage = "No records processed";
            }
          }
          this.hasErrorList = true;
          if(this.errorDataList.length > 1) {
            this.loadData(this.errorDataList);
          }
          else {
            this.rowData = [];
          }
          this.loadingService.hideLoading();
				},
				error => {
          this.loadingService.hideLoading();
					console.log('[uploadCOMMData] (error) ' + JSON.stringify(error.statusDescription));
					this.alertService.error(error.statusDescription)
				}
      );
    }
  }

  loadData(dataList: any) {
    if (dataList.length > 0) {
        this.rowData = [];
        for (let i = 0; i < dataList.length - 1; i++) {
            this.rowData.push({
                module: dataList[i]["Module"],
                system: dataList[i]["System"] != null ?  dataList[i]["System"] : "",
                vendor: dataList[i]["Vendor"] != null ?  dataList[i]["Vendor"] : "",
                asset: dataList[i]["Asset"] != null ?  dataList[i]["Asset"] : "",
                cscode: dataList[i]["CSCode"] != null ?  dataList[i]["CSCode"] : "",
                status: dataList[i]["Status"]
            });
        }
        setTimeout(() => {
            this.gridApi.sizeColumnsToFit();
        }, 100);
    }
  }
}
