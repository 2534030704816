import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class PrecommissioningSystemSkylineWidgetService {

    public token: string;
    private callback: WsCallback;

    constructor(private http: HttpClient) {

    }

    public setCallback(callBack: WsCallback) {
        this.callback = callBack;
    }

    getPreCommissioningSystems(): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("systemId", "ALL").set("checkSheetType", "B").set("year", "2015-12-27 17:30:00").set("type", "Static") }
        let url = this.getSystemsListByUserURL();
        return this.http.get(url, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    getPreCommSystemSkylineData(systemId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("checkSheetType", "B").set("year", "ALL").set("type", "Static") }
        let url = this.getSystemsListByUserURL();
        return this.http.get(url, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    public getSystemsListByUserURL() {
        let url = ServiceUrls.USER_SYSTEM_SKYLINE_VIEW;
        return url;
    }
}
