<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						<!-- translated widget name -->
						{{ "PRECOMMISSIONING.WIDGET_NAME" | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
					<!-- other buttons like config button -->
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<!-- body content -->
		<div *ngIf="eventType == widgetEvent.SHOW_TABLE">
			<div class="row my-1">
				<div class="col-sm-9 ">
				</div>
				<div class="col-sm-3">
					<button class="btn btn-primary btn-sm float-right" (click)="exportToExcel()">{{
						'PRECOMMISSIONING.EXPORT_TO_EXCEL' | translate }}</button>
				</div>
			</div>
			<div class="my-2">
				<ag-grid-angular #agGrid style="width: 100%; height: 450px;" id="cmmsDataBuildDetails" [rowData]="rowData" class="ag-theme-balham"
				 [columnDefs]="columnDefs" [frameworkComponents]="components" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" 
				 [floatingFilter]="true" [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true"
				 (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)" (cellClicked)="onCellClick($event)"
				 [gridOptions]="gridOptions" [suppressMenuHide]="true" (rowClicked)="onRowClick($event)">
				</ag-grid-angular>
			</div>
		</div>
		<div *ngIf="showPCDetail" class="row">
			<div class="col-sm-12 my-2">
				<div class="row px-2">
					<div class="col">
						<label>
							{{ 'PRECOMMISSIONING.PC_DETAILS' | translate}}
						</label>
						<button type="button" class="close" aria-label="Close" (click)="showPCDetail = !showPCDetail">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
				<hr class="my-0" />
			</div>
			<div class="col-sm-12 my-2">
				<ag-grid-angular #agGrid style="width: 100%; height: 350px;" id="precommissioningDetail" [rowData]="detailRowData"
				 class="ag-theme-balham" [columnDefs]="detailColumnDefs" [enableSorting]="true" [enableFilter]="true"
				 [enableColResize]="true" [floatingFilter]="true" [enableRangeSelection]="true"
				 [pagination]="true" (columnResized)="onDetailColumnResized($event)" (gridReady)="onDetailGridReady($event)"
				 [gridOptions]="detailGridOptions">
				</ag-grid-angular>
			</div>
		</div>
		<div *ngIf="eventType == widgetEvent.SHOW_WIZARD">
			<div class="row">
				<button type="button" class="btn btn-primary btn-sm" (click)="backToTable()">
					<i class="fa fa-arrow-left mx-2" aria-hidden="true"></i>{{ 'PRECOMMISSIONING.BACK_TO_TABLE' | translate }}
				</button>
			</div>
			<div class="row">
				<div class="col-sm-12 my-2">
					<aw-wizard class="custom-css">
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.pcstatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.pcstatus)}}</span>
										{{selectedItem.systemName}}
									</h6>
									<hr />
									<div>
										<p>{{ 'PRECOMMISSIONING.WORKFLOW_TITLE' | translate }}
										</p>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="ch1">
												<label class="form-check-label" for="exampleCheck1">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH1' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck2" [(ngModel)]="ch2">
												<label class="form-check-label" for="exampleCheck2">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH2' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck3" [(ngModel)]="ch3">
												<label class="form-check-label" for="exampleCheck3">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH3' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck4" [(ngModel)]="ch4">
												<label class="form-check-label" for="exampleCheck4">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH4' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck5" [(ngModel)]="ch5">
												<label class="form-check-label" for="exampleCheck5">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH5' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck6" [(ngModel)]="ch6">
												<label class="form-check-label" for="exampleCheck6">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH6' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck7" [(ngModel)]="ch7">
												<label class="form-check-label" for="exampleCheck7">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH7' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck8" [(ngModel)]="ch8">
												<label class="form-check-label" for="exampleCheck8">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH8' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck9" [(ngModel)]="ch9">
												<label class="form-check-label" for="exampleCheck9">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.CH9' | translate }}</p>
												</label>
											</li>
										</ul>
										<p>{{ 'PRECOMMISSIONING.SUMMARY' | translate }}</p>
										<table class="table" *ngIf="openPunchlists.length>0">
											<tr>
												<th>{{ 'PRECOMMISSIONING.CATEGORY' | translate }}</th>
												<th>{{ 'PRECOMMISSIONING.DESCRIPTION' | translate }}</th>
												<th>{{ 'PRECOMMISSIONING.ASSET' | translate }}</th>
												<th>{{ 'PRECOMMISSIONING.SYSTEM_SUBSYSTEM' | translate }}</th>
											</tr>
											<tr *ngFor="let item of openPunchlists">
												<td>{{ item.category }}</td>
												<td>{{ item.punchlistDescription }}</td>
												<td>{{ item.assetName }}</td>
												<td>{{ item.systemName }}</td>
											</tr>
										</table>
										<p *ngIf="!openPunchlists.length>0" class="m-0 text-muted">{{ 'PRECOMMISSIONING.NO_PUNCHLISTS' | translate }}</p>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck10" [(ngModel)]="ch10">
												<label class="form-check-label" for="exampleCheck10">
													<p class="m-0 text-muted">{{ 'PRECOMMISSIONING.REM_ITEMS' | translate }}</p>
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-primary btn-sm" [awGoToStep]="{ stepOffset: 1 }" (click)="changeScroll()">{{
									'PRECOMMISSIONING.NEXT' | translate }}</button>
							</div>
						</aw-wizard-step>
						<aw-wizard-step stepTitle="">
							<div class="card" #wizard2section id="wizard2section">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.pcstatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.pcstatus)}}</span>
										{{selectedItem.systemName}}
									</h6>
									<hr />
									<form>
										<div class="row" *ngFor="let task of workflowTaskList; let i = index">
											<div class="col-sm-10 form-group">
												<label class="my-1 mx-0"><b>{{task.taskName}}</b></label>
												<div class="form-row">
													<div class="col">
														<app-custom-typeahead *ngIf="i==0" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee1 && (isSubmitted && i==0) ">
															{{'PRECOMMISSIONING.EMPTY_USER' | translate }}
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail1 && (isSubmitted && i==0) ">
															{{'PRECOMMISSIONING.INVALID_EMAIL' | translate }}
														</p>
														<app-custom-typeahead *ngIf="i==1" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee2 && (isSubmitted && i==1) ">
															{{'PRECOMMISSIONING.EMPTY_USER' | translate }}
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail2 && (isSubmitted && i==1) ">
															{{'PRECOMMISSIONING.INVALID_EMAIL' | translate }}
														</p>

													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-secondary btn-sm mx-2" (click)="previous()" awPreviousStep>{{ 'PRECOMMISSIONING.PREVIOUS' |
									translate }}</button>
								<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()" >{{
									'PRECOMMISSIONING.START' | translate }}</button>
							</div>
						</aw-wizard-step>
						<!-- <aw-wizard-step stepTitle="">
								<div class="card">
									<div class="card-body">
										This is some text within a card body. step 3
									</div>
								</div>
								<div class="my-2 float-right">
									<button type="button" class="btn btn-secondary btn-sm mx-2" awPreviousStep>Previous</button>
									<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">Finish</button>
								</div>
							</aw-wizard-step> -->
					</aw-wizard>
				</div>
			</div>
		</div>

		<div *ngIf="eventType == widgetEvent.SHOW_EDIT">
			<div class="row">
				<div class="col-sm-12 my-2">
					<h6 class="my-2">
						<span *ngIf="selectedItem.pcstatus" [ngClass]="getStyle(selectedItem.pcstatus)" class="badge mr-2">{{getStatus(selectedItem.pcstatus)}}</span>{{selectedItem.systemName}}
					</h6>
					<hr />
					<div class="container">
						<div class="form-group">
							<div class="col-sm-6">
								<label>{{ 'PRECOMMISSIONING.TARGET_COMPLETION_DATE' | translate }}</label>
								<input [(ngModel)]="targetCompletionDate" type="text" placeholder="Select target completion date" class="form-control is-invalid"
								 [bsConfig]="bsConfig" placement="top" bsDatepicker>
							</div>
						</div>
						<div class="form-group form-check">
							<div class="col-sm-6">
								<label class="container_checkbox">
									<input [(ngModel)]="required" type="checkbox" checked="checked" id="required">
									<span class="checkmark rounded"></span>
								</label>
								<!-- <input [(ngModel)]="required" type="checkbox" class="form-check-input" id="required">-->
								<label class="ml-2">{{ 'PRECOMMISSIONING.REQUIRED' | translate }}</label>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-6">
								<p class="alert alert-danger mb-0 mt-1 py-1 px-4" role="alert" *ngIf="showRequiredErr && !isInputValid()">
									{{ 'PRECOMMISSIONING.REQUIRED_FIELDS' | translate }}
								</p>
							</div>
						</div>
						<div class="form-group my-2">
							<button type="button" class="btn btn-primary btn-sm mx-1 float-right" (click)="savePC()">{{
								'PRECOMMISSIONING.SAVE' | translate }}</button>
							<button type="button" class="btn btn-secondary btn-sm mx-2 float-right" (click)="backToTable()">{{
								'PRECOMMISSIONING.CANCEL' | translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- popup templates -->
<!-- modal templates -->
<ng-template #deletePCCModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'PRECOMMISSIONING.REVOKE_PCC_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'PRECOMMISSIONING.REVOKE_PCC_MSG' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="revokePCC()">{{ 'PRECOMMISSIONING.YES' | translate }}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancel()">{{ 'PRECOMMISSIONING.NO' | translate }}</button>
		</div>
	</div>
</ng-template>
<ng-template #incompletePCCModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'PRECOMMISSIONING.INCOMPLETE_PCC_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="incompleteModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'PRECOMMISSIONING.INCOMPLETE_PCC_MSG' | translate }} </p>
		<p> {{ 'PRECOMMISSIONING.ASK_TO_CONTINUE' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="continue()">{{ 'PRECOMMISSIONING.CONTINUE' | translate
				}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancelWorkflow()">{{ 'PRECOMMISSIONING.CANCEL' |
				translate }}</button>
		</div>
	</div>
</ng-template>
<ng-template #editPCCModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'PRECOMMISSIONING.DELETE_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'PRECOMMISSIONING.DELETE_MSG' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="deletePCC()">{{ 'PRECOMMISSIONING.YES' | translate }}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancelDeletePCC()">{{ 'PRECOMMISSIONING.CANCEL' |
				translate }}</button>
		</div>
	</div>
</ng-template>