<div class="panel panel-default">
    <div class="panel-heading box-shadow panel-header-border-bottom py-1">
        <div class="row px-2">
            <div class="col-sm-7 pt-1 px-1">
                <p class="m-0 pl-1">
                    {{ 'MC_SYSTEM_SKYLINE.WIDGET_NAME' | translate }}
                </p>
            </div>
            <div class="col-sm-5 float-right pt-1 px-0">
                <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                    (click)="isMinimized = !isMinimized" title="minimize the chart">
                    <i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
                        aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                    (click)="refresh()" title="refresh the chart">
                    <i class="fas fa-sync-alt" aria-hidden="true"></i>
                </button>
                <!-- other buttons like config button -->
                <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                    (click)="showFilterOptions = !showFilterOptions" title="filter with system">
                    <i class="fas fa-cog" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                    (click)="showHideLabel()" title="show/hide data label">
                    <i class="fa " [ngClass]="{'fa-eye': !isShowLabel, 'fa-eye-slash': isShowLabel}" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                (click)="showCondensedView()" title="show/hide condensed view">
                <i class="fa " [ngClass]="{'fas fa-tag': !isCondensedView, 'fas fa-tags': isCondensedView}" aria-hidden="true"></i>
            </button>
                <button *ngIf="!isCondensedView" type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                    (click)="showHideEmptyWeeks()" title="show/hide empty weeks">
                    <i class="fas fa-calendar-plus " [ngClass]="{'fa-calendar-plus': !isShowEmptyWeeks}" aria-hidden="true"></i>
                    <i class="fas fa-calendar-minus " [ngClass]="{'fa-calendar-minus': isShowEmptyWeeks}" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isCondensedView" type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                (click)="focusOnTodayAxis()" title="focus on Today">
                <i class="fas fa-text-width" aria-hidden="true"></i>
            </button>
            </div>
        </div>
    </div>
    <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
        <!-- body content -->
        <div class="row" *ngIf="showFilterOptions">
            <div class="col-sm-12 my-2">
                <div class="row px-2">
                    <div class="form-group col">
                        <label>{{ 'MC_SYSTEM_SKYLINE.SYSTEM' | translate }} :</label>
                        <ng-select [disabled]="isLoadingData" [active]="selectedSystem" [allowClear]="true" (removed)="removedSystem($event)"
                            (selected)="selectedSystemId($event)" [items]="systemList" placeholder="{{ 'MC_SYSTEM_SKYLINE.SELECT_SYSTEM' | translate }}"
                            name="system" id="system">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>

        <!-- loading panel -->
        <div *ngIf="isLoadingData" class="row">
            <div class="alert alert-warning mx-auto px-3 py-1">
                <h6 class="mb-0">{{ "GENERAL.LOADING_DATA" | translate }}</h6>
            </div>
        </div>
        <!-- no data panel -->
        <div *ngIf="isNoDataToShow" class="row">
            <div class="alert alert-light-nodata mx-auto px-2 py-1 border-0">
                <h6 class="mb-0">{{ "GENERAL.NO_DATA_TO_SHOW" | translate }}</h6>
            </div>
        </div>

        <highcharts-chart *ngIf="!isCondensedView" [Highcharts] = "highcharts" [options] = "chartOptions" [(update)]="updateFlag"
        [oneToOne]="oneToOneFlag" [ngClass]="{'content-hide': isNoDataToShow, 'content-show': !isNoDataToShow}">
</highcharts-chart>

<div *ngIf="isCondensedView" class="my-2" [chart]="chart" [ngClass]="{'content-hide': isNoDataToShow, 'content-show': !isNoDataToShow}"></div>

        <div *ngIf="showMCDetail" class="row">
            <div class="col-sm-12 my-2">
                <div class="row px-2">
                    <div class="col">
                        <label class="float-left">
                            <span class="badge " [ngClass]="{'badge-success': selectedPoint.status == 'Approved', 'badge-warning': selectedPoint.status == 'Issued', ' badge-danger': selectedPoint.status == 'NotIssued'}">
                                {{getStatus(selectedPoint.status)}}</span>
                            <!--<div *ngIf="selectedPoint.signedFile != null; then mccsc_template else mccc_template"></div>-->
                            <span *ngIf="(selectedPoint.file != null) && (selectedPoint.status != 'NotIssued')" class="badge badge-btn-success mx-1 cur-pointer pr-2 pl-0 py-0"
                                (click)="showMCCCertificate(selectedPoint)">
                                <i class="fa fa-arrow-down p-1 mr-2" aria-hidden="true"></i>
                                {{ 'MC_SYSTEM_SKYLINE.TOOLTIP_MCC_CERTIFICATE' | translate }}
                            </span>
                            <span *ngIf="selectedPoint.signedFile != null" class="badge badge-btn-success mx-1 cur-pointer pr-2 pl-0 py-0"
                                (click)="showMCCSignedCertificate(selectedPoint)">
                                <i class="fa fa-arrow-down p-1 mr-2" aria-hidden="true"></i>
                                {{ 'MC_SYSTEM_SKYLINE.TOOLTIP_MCC_S_CERTIFICATE' | translate }}
                            </span>
                            <div>
                                {{selectedPoint.systemNumber}} : {{selectedPoint.systemName}}
                                <span *ngIf="selectedPoint.vendorManaged" class="badge " [ngClass]="{'badge-success': selectedPoint.vendorManaged }">
                                    Vendor Managed</span>
                            </div>
                            <div>
                                <span *ngIf="(selectedPoint.openCheckSheetsCount != 0)">
                                    {{selectedPoint.openCheckSheetsCount}} : {{
                                    'MC_SYSTEM_SKYLINE.OPEN_CHECKSHEETS_COUNT' | translate }}
                                </span>
                                <span *ngIf="(selectedPoint.openPunchListCount != 0)">
                                    {{selectedPoint.openPunchListCount}} : {{ 'MC_SYSTEM_SKYLINE.OPEN_PUNCHLIST_ITEMS'
                                    | translate }}
                                </span>
                                <span *ngIf="(selectedPoint.completionDays != 0)">
                                    {{ 'MC_SYSTEM_SKYLINE.TARGET_COMPLETION_IN' | translate }}
                                    {{selectedPoint.completionDays}} {{ 'MC_SYSTEM_SKYLINE.DAYS' |
                                    translate }}
                                </span>
                            </div>
                        </label>
                        <button type="button" class="close" aria-label="Close" (click)="showMCDetail = !showMCDetail">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <hr class="my-1" />
            </div>
            <div class="col-sm-12 my-2">
                <ag-grid-angular #agGrid style="width: 100%; height: 300px;" id="cmmsDataBuildDetails" [rowData]="rowData"
                    class="ag-theme-balham" [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true"
                    [enableColResize]="true" [enableColResize]="true" [floatingFilter]="true" [enableRangeSelection]="true"
                    [paginationPageSize]="5" [pagination]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)"
                    (cellClicked)="onCellClick($event)" [gridOptions]="gridOptions">
                </ag-grid-angular>
            </div>
        </div>
    </div>
</div>



<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade"
    tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p class="h6 my-2">Do you want more information?</p>
                <button type="button" class="btn btn-default mx-1 my-1" (click)="hideModal()">No</button>
                <button type="button" class="btn btn-primary mx-1 my-1" (click)="confirmedEvent()">Yes</button>
            </div>
        </div>
    </div>
</div>