import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorage } from './localstorage.service';
import { Constants } from './constants';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/retry';
import { AlertService } from './alert/alert.service';

@Injectable()
export class BasicRequest implements HttpInterceptor {
    private token: string = "";
    private deviceId: string = "";

    constructor(private router: Router, private injector: Injector, private route: ActivatedRoute) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const notificationService = this.injector.get(AlertService);
        if (localStorage.getItem(LocalStorage.LOGGED_USER) != null) {
            this.token = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken || '';
        }
        if (localStorage.getItem(LocalStorage.DEVICE_ID) != null) {
            this.deviceId = localStorage.getItem(LocalStorage.DEVICE_ID) || '';
//            console.log('basic request - ' + this.deviceId);
        }

        request = request.clone({
            setHeaders: {
                Authorization: this.token,
                'User-App': btoa(Constants.APP_TYPE + '_' + Math.random()),
                'Accept-Language': 'en-US',
                'Device-Id': btoa(this.deviceId)
            }
        });
        return next.handle(request).pipe(
            tap(event => {
                //ignore this
            }, error => {
                //for errors
                if (error.status == 440) {
                    //session timeout
                    this.router.navigateByUrl("/login");
                }
                /*if (error instanceof HttpErrorResponse) {
                    if (error.error.actionErrors != undefined) {
                        notificationService.error(error.statusText + ' - ' + error.error.actionErrors);
                    } else {
                        notificationService.error(error.statusText);
                    }
                }*/
            })
        );
    }
}