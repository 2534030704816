import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from "ag-grid";
import { UserVariable } from '../../util/common/user-variable';
import { PrecommissioningStartWorkflowRenderer } from './cell-renderer/start-workflow/start-workflow.component';
import { PrecommissioningEditButtonRenderer } from './cell-renderer/edit-button/edit-button.component'
import { PrecommissioningDeleteButtonRenderer } from './cell-renderer/delete-button/delete-button.component'
import { PrecommissioningSentFileIconRenderer } from './cell-renderer/file-icon/sent-file-icon.component';
import { PrecommissioningFileIconRenderer } from './cell-renderer/file-icon/approved-file-icon.component';
import { PrecommissioningCompletionService } from '../precommissioning-completion.service'
import { CheckSheetSentFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-sent-file-icon.component'
import { CheckSheetApprovedFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import { CheckSheetService } from '../checksheet.service';
import { AlertService } from '../../util/alert/alert.service';
import { WIDGET_EVENT } from '../../util/constants';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { WorkflowService } from '../workflow.service';
import { CapabilityService } from '../../util/capability.service';
import { SharedService } from '../../util/shared.service';
import { Guid } from "guid-typescript";
import { BsModalService, BsDatepickerConfig } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';
import * as FileSaver from 'file-saver';
import "ag-grid-enterprise";
import * as XLSX from 'xlsx';
import { LoadingService } from '../../util/loading/loading.service';
import { DatePipe } from '@angular/common';
import { PrecommissioningDocuSignRenderer } from './cell-renderer/precommissioning-docu-sign/precommissioning-docu-sign.component';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-precommissioning-detail-widget',
	templateUrl: './precommissioning-detail-widget.component.html',
	styleUrls: ['./precommissioning-detail-widget.component.css']
})
export class PrecommissioningDetailWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	@ViewChild("deletePCCModal") deletePCCModal: TemplateRef<any>;
	@ViewChild("incompletePCCModal") incompletePCCModal: TemplateRef<any>;
	@ViewChild("editPCCModal") editPCCModal: TemplateRef<any>;
	@ViewChild('wizard2section') wizard2section: ElementRef
	public deleteModalRef: BsModalRef;
	public incompleteModalRef: BsModalRef;
	public editModalRef: BsModalRef;
	pcd_translated_data: any = null;
	private gridApi;
	private gridColumnApi;
	public rowData = [];
	// public controlledDocumentList: Array<ControlledDocumentsWidgetModel> = new Array<ControlledDocumentsWidgetModel>();
	public columnDefs;
	public gridOptions: GridOptions;
	private selectedItem: any = null
	public targetCompletionDate: Date = null
	public required: boolean = null
	public openPunchlists: any[] = [];
	public userList: any[] = []
	public allPunchlistCompleted: boolean = false;
	public allChecksheetsCompleted: boolean = false;
	public selectedAssignee: any[] = null
	public workflowTaskList: any[] = [];
	public ch1: any = false
	public ch2: any = false
	public ch3: any = false
	public ch4: any = false
	public ch5: any = false
	public ch6: any = false
	public ch7: any = false
	public ch8: any = false
	public ch9: any = false
	public ch10: any = false
	public workflowId: any = null
	public taskCount: any = 0
	assignee1: any
	assignee2: any
	taskname1:any
	taskname2:any
	showMCDetail: boolean = false
	showPCDetail: boolean = false
	detailRowData: any[] = []
	detailColumnDefs: any
	detailGridApi
	detailGridColumnApi
	iconClicked: boolean = false
	eventType: any = WIDGET_EVENT.SHOW_TABLE
	widgetEvent = WIDGET_EVENT
	modifyPrecommissioningCompletion: boolean = false
	public bsConfig: Partial<BsDatepickerConfig>
	showRequiredErr: boolean = false

	isSubmitted: boolean = false
	validAssignee1: boolean = true
	validAssignee2: boolean = true
	validAssignee3: boolean = true

	validAssigneeEmail1: boolean = true
	validAssigneeEmail2: boolean = true
	validAssigneeEmail3: boolean = true
	public detailGridOptions: GridOptions;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };

	constructor(private translate: TranslateService,
		private precommissioningservice: PrecommissioningCompletionService,
		private userService: UserService,
		private systemService: SystemService,
		private workflowService: WorkflowService,
		private sharedService: SharedService,
		private alertService: AlertService,
		private loadingService: LoadingService,
		private capabilityService: CapabilityService,
		private bsModalService: BsModalService,
		private checkSheetService: CheckSheetService) {

		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		this.detailGridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			paginationPageSize: 5
		};
		this.modifyPrecommissioningCompletion = capabilityService.isCapabilityAssigned("modifyStaticCompletion");
		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.pcd_translated_data = res['PRECOMMISSIONING']
            var self = this;
			this.columnDefs = [
				{ headerName: this.pcd_translated_data.SYSTEM, field: 'systemName', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 280 },
				{ headerName: this.pcd_translated_data.STATUS, field: 'pcstatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 90 },
				{
					headerName: this.pcd_translated_data.TARGET_COMPLETION_DATE, field: 'newtargetCompletionDate', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				}
			]
			if (this.modifyPrecommissioningCompletion) {
				this.columnDefs.push(
					{
						headerName: this.pcd_translated_data.EDIT,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 70,
						suppressFilter: true,
						cellRendererFramework: PrecommissioningEditButtonRenderer,
						suppressMenu: true,
						suppressSorting: true
					},
					{
						headerName: this.pcd_translated_data.START_WORKFLOW,
						//field: 'isStart',
						valueGetter: function(params) {
							if(params.data.isStart) {
								return params.data.isStart;
							} else {
								return params.data.docuSignStatus == null ? '' : params.data.docuSignStatus;
							}
						},
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 125,
						//suppressFilter: true,
						filter: "agTextColumnFilter",
						suppressCellFlash: true,
						cellRendererFramework: PrecommissioningDocuSignRenderer,
						//PrecommissioningStartWorkflowRenderer,
						suppressSorting: true
					},
					{
						headerName: this.pcd_translated_data.FAILURE_REASON,
						valueGetter: function(params) {
							if(params.data.outputErrorMessage != null){
								return params.data.outputErrorMessage;
							} else if(params.data.docusignFailureReason != null){
								return params.data.docusignFailureReason;
							} else{
								return '';
							}
						},
						suppressFilter: true,
						hide: true,
						minWidth: 200
					},
					{
						headerName: this.pcd_translated_data.DELETE,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 80,
						suppressFilter: true,
						cellRendererFramework: PrecommissioningDeleteButtonRenderer,
						suppressSorting: true
					},					
					{
						headerName: this.pcd_translated_data.SENT_PDF,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 70,
						suppressFilter: true,
						hide: true,
						cellRendererFramework: PrecommissioningSentFileIconRenderer,
						suppressSorting: true
					},
					{
						headerName: this.pcd_translated_data.RETURNED_PDF,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 90,
						suppressFilter: true,
						cellRendererFramework: PrecommissioningFileIconRenderer,
						suppressSorting: true
					}
					)
			}
			// this.columnDefs.push({ 
			// 	headerName: this.pcd_translated_data.DOCUSIGN_STATUS, 
			// 	field: 'docuSignStatus', 
			// 	autoHeight: true, 
			// 	cellClass: "cell-wrap-text",  
			// 	hide: true,
			// 	suppressCellFlash: true,
			// 	cellRendererFramework: PrecommissioningDocuSignRenderer,
			// 	minWidth: 90 })

			this.detailColumnDefs = [
				{
					headerName: this.pcd_translated_data.CHECKSHEET_CODE,
					field: 'checkSheetCode',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.pcd_translated_data.CHECKSHEET_NAME,
					field: 'checkSheetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.pcd_translated_data.VENDOR,
					field: 'vendorName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100
				},
				{
					headerName: this.pcd_translated_data.SYSTEM_NAME,
					field: 'system',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 250
				},
				{
					headerName: this.pcd_translated_data.ASSET,
					field: 'assetName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.pcd_translated_data.STATUS,
					field: 'status',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 90,
					cellRenderer: this.statusCellRenderer,
					suppressMenu: true,
					suppressSorting: true
				},
				{
					headerName: this.pcd_translated_data.SENT_DOWNLOAD_ALL,
					field: 'initialFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					suppressFilter: true,
					minWidth: 70,
					cellRendererFramework: CheckSheetSentFileIconRenderer,
					suppressMenu: true,
					suppressSorting: true
				},
				{
					headerName: this.pcd_translated_data.APPROVED_DOWNLOAD_ALL,
					field: 'approvedFileName',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					suppressFilter: true,
					minWidth: 100,
					cellRendererFramework: CheckSheetApprovedFileIconRenderer,
					suppressMenu: true,
					suppressSorting: true
				}
			]
		});
	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()
		params.api.sizeColumnsToFit();
		this.refresh()
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 500);
		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	ngOnInit() {

	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'Not Issued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onDetailColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onDetailGridReady(params) {
		this.detailGridApi = params.api;
		this.detailGridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.detailGridApi.showLoadingOverlay()
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	onCellClick(event) {
		//  console.log("==precom cell click= "+ JSON.stringify(event))
	}

	private edit(data: any) {
		this.selectedItem = data.data
		this.eventType = WIDGET_EVENT.SHOW_EDIT
		if (this.selectedItem.targetCompletionDate) {
			this.targetCompletionDate = new Date(this.selectedItem.targetCompletionDate)
		} else {
			this.targetCompletionDate = null;
		}
		console.log(this.selectedItem.pcRequired)
		this.required = this.selectedItem.pcRequired
	}

	deletePCC() {
		this.editModalRef.hide();
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.loadingService.showLoading(false, false, '', 0)
		this.precommissioningservice.deletePrecommissioningCompletion(this.selectedItem.pcId).subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	cancelDeletePCC() {
		this.editModalRef.hide()
	}

	savePC(): void {
		if (this.selectedItem.pcId) {
			if (this.isInputValid()) {
				this.showRequiredErr = false
				if (this.selectedItem.pcRequired && !this.required) {
					this.editModalRef = this.bsModalService.show(this.editPCCModal, { class: 'modal-sm' })
				} else {
					this.loadingService.showLoading(false, false, '', 0)
					if (!this.selectedItem.pcstatus) {
						this.selectedItem.pcstatus = "NotIssued"
					}
					if (this.selectedItem.pcstatus == "Not Issued") {
						this.selectedItem.pcstatus = "NotIssued"
					}
					this.selectedItem.pcRequired = this.required
					this.selectedItem.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
					console.log(JSON.stringify(this.selectedItem))
					this.precommissioningservice.updatePrecommissioningCompletion(this.selectedItem)
						.subscribe(data => {
							this.loadingService.hideLoading()
							this.backToTable();
						},
						error => {
							this.loadingService.hideLoading()
							this.alertService.error(error.statusDescription);
						})
				}
			} else {
				this.showRequiredErr = true
			}

		} else {
			if (this.isInputValid()) {
				this.loadingService.showLoading(false, false, '', 0)
				this.showRequiredErr = false
				this.selectedItem.pcstatus = "NotIssued"
				this.selectedItem.pcRequired = this.required
				this.selectedItem.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
				this.selectedItem.projectId = UserVariable.projectId;
				this.precommissioningservice.addPrecommissioningCompletion(this.selectedItem)
					.subscribe(data => {
						this.loadingService.hideLoading()
						this.backToTable();
					},
					error => {
						this.loadingService.hideLoading()
						this.alertService.error(error.statusDescription);
					})
			} else {
				this.showRequiredErr = true
			}
		}
	}

	public refresh() {
		console.log('[PrecommissioningWidgetComponent] (refresh) ');
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.showMCDetail = false
		this.iconClicked = false
		this.selectedAssignee = []
		this.assignee1 = null
		this.assignee2 = null
		this.ch1 = false
		this.ch2 = false
		this.ch3 = false
		this.ch4 = false
		this.ch5 = false
		this.ch6 = false
		this.ch7 = false
		this.ch8 = false
		this.ch9 = false
		this.ch10 = false
		this.selectedItem = null
		this.workflowTaskList = []
		this.showRequiredErr = false
		this.gridApi.showLoadingOverlay()
		this.precommissioningservice.getPreCommissioningDetails()
			.subscribe(data => {
				if (data.payload) {
					console.log(data.payload)
					data.payload.forEach(element => {
						if (element.targetCompletionDate) {
							element.newtargetCompletionDate = new Date(element.targetCompletionDate).toLocaleDateString()
						}
						element.systemName = element.systemNo + " " + element.systemName
						element.isStart = false
						if (element.pcstatus == "NotIssued") {
							element.isStart = true
							element.pcstatus = "Not Issued"
						}
						if(element.docuSignStatus != null){
							element.docuSignStatus = element.docuSignStatus.toLowerCase()
						}
					});
					this.rowData = data.payload;
				} else {
					this.rowData = []
				}
			},
			error => {
				this.alertService.error(error.statusDescription);
			})
	}

	public getStyle(status: any) {
		if (status == 'Approved') {
			return 'badge-success'
		} else {
			return 'badge-warning'
		}
	}

	public getStatus(status: any) {
		switch (status) {
			case 'Not Issued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	isInputValid() {
		if (!this.selectedItem.pcstatus) {
			if (this.targetCompletionDate && this.required) {
				return true
			} else {
				return false
			}
		} else {
			if (this.selectedItem.pcRequired && !this.required) {
				if (this.targetCompletionDate) {
					return true
				} else {
					return false
				}
			} else {
				if (this.targetCompletionDate && this.required) {
					return true
				} else {
					return false
				}
			}
		}
	}

	public showWizard(data: any) {
		this.selectedItem = data.data
		console.log('[showWizard] (data) ' + JSON.stringify(this.selectedItem));
		this.eventType = WIDGET_EVENT.SHOW_WIZARD
	}

	private backToTable() {
		this.isSubmitted = false;
		this.selectedItem = null
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	getConfigData() {
		var configData = '<ConfigData>';
		configData += '<AllTestCompleted>' + this.ch1 + '</AllTestCompleted>';
		configData += '<PreshipmentCompleted>' + this.ch2 + '</PreshipmentCompleted>';
		configData += '<SiteCommisisoningCompleted>' + this.ch3 + '</SiteCommisisoningCompleted>';
		configData += '<AllDocCompleted>' + this.ch4 + '</AllDocCompleted>';
		configData += '<AllPreCommissioningCheckSheetCompleted>' + this.ch5 + '</AllPreCommissioningCheckSheetCompleted>';
		configData += '<AllStaticCommissioningCheckSheetCompleted>' + this.ch6 + '</AllStaticCommissioningCheckSheetCompleted>';
		configData += '<EnteredToSCDb>' + this.ch7 + '</EnteredToSCDb>';
		configData += '<CareProcessApproved>' + this.ch8 + '</CareProcessApproved>';
		configData += '<TCPDocCompleted>' + this.ch9 + '</TCPDocCompleted>';
		configData += '<PlanApprovedForRemItems>' + this.ch10 + '</PlanApprovedForRemItems>';
		configData += '</ConfigData>';
		return configData;
	}

	continue() {
		this.eventType = this.widgetEvent.SHOW_WIZARD
		if (this.incompleteModalRef) {
			this.incompleteModalRef.hide()
		}
		this.loadingService.showLoading(false, false, '', 0)

		this.systemService.getOpenPunchlistItems(this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.openPunchlists = []
				if (data.payload) {
					this.openPunchlists = data.payload
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id, "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('PrecommissioningCompletion')
					.subscribe(data => {
						this.workflowTaskList = data.payload
						this.selectedAssignee = []
						if (data.payload) {
							this.workflowTaskList.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})

	}

	startWorkflow(params: any) {
		this.selectedItem = params.data

		this.loadingService.showLoading(false, false, '', 0)

		this.systemService.isAllChecksheetsComplete("B", this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
			.subscribe(data => {
				this.allChecksheetsCompleted = data.payload
				this.systemService.isAllPunchlistItemsClosedByCategory("A", this.selectedItem.systemId, UserVariable.projectId, UserVariable.userId, UserVariable.clientId)
					.subscribe(data => {
						this.allPunchlistCompleted = data.payload
						this.loadingService.hideLoading()

						if (!this.allChecksheetsCompleted || !this.allPunchlistCompleted) {
							this.incompleteModalRef = this.bsModalService.show(this.incompletePCCModal, { class: 'modal-lg' })
						} else {
							this.continue()
						}
					}, error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			}, error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})

	}

	emailValidate(value: any) {
		let isValid = /^[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+(\.[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
		return isValid
	}

	isValidEmailAddress(value: any, i: any) {
		let result = true;
		if (i == 0) {
			if (this.assignee1) {
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
			}
		} else if (i == 1) {
			if (this.assignee2) {
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
			}
		}
		return result
	}

	isAssigneesAdded() {
		if (this.assignee1 && this.assignee2) {
			let result = true;
			if (!this.userList.find(user => user.id == this.assignee1)) {
				if (!this.emailValidate(this.assignee1)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee2)) {
				if (!this.emailValidate(this.assignee2)) {
					result = false;
				}
			}
			return result
		} else {
			return false
		}
	}

	textChanged(event: any, i: any, taskname: any) {
		if (event) {
			if (i == 1) {
				this.assignee1 = event.value
				this.taskname1 = taskname
			} else if (i == 2) {
				this.assignee2 = event.value
				this.taskname2 = taskname
			}
		} else {
			if (i == 1) {
				this.assignee1 = null
				this.taskname1 = null
			} else if (i == 2) {
				this.assignee2 = null
				this.taskname2 = null
			}
		}
	}

	addInstanceTaskMap() {
		var taskmap = this.workflowTaskList[this.taskCount]
		taskmap['processInstanceId'] = this.workflowId.value

		var taskAssignee = null
		if (this.taskCount == 0) {
			taskAssignee = this.assignee1;
		} else if (this.taskCount == 1) {
			taskAssignee = this.assignee2;
		}
		if (this.userList.find(user => user.id == taskAssignee)) {
			taskmap['userId'] = taskAssignee
			taskmap['userEmail'] = null
		} else {
			taskmap['userEmail'] = taskAssignee
			taskmap['userId'] = null
		}

		this.taskCount = this.taskCount + 1
		
		this.workflowService.addWorkflowInstanceTaskRolemap(taskmap).subscribe(
			data => {
				if (this.taskCount == this.workflowTaskList.length) {
					var workflow = {
						"id": this.workflowId.value, "projectId": UserVariable.projectId, "processInstanceId": this.workflowId.value,
						"processName": "PCCApproval", "itemId": this.selectedItem.pcId,
						"itemType": "PrecommissioningCompletion", "initiatedUserId": UserVariable.userId, "configData": this.getConfigData()
					}
					this.workflowService.generateWorkflow(workflow)
						.subscribe(data => {
							this.loadingService.hideLoading()
							this.backToTable()
						},
						error => {
							this.loadingService.hideLoading()
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						})
				} else {
					this.addInstanceTaskMap()
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.error(error.statusDescription);
			})
	}

	private finishFunction() {

		this.isSubmitted = true;
		if(!this.validateAssigneeData()){
			return;

		}
		else{

			var signerslist =  this.getSigngersList();
			var pcObject = {
				"pcId": this.selectedItem.pcId, "ch1": this.ch1, "ch2": this.ch2, "ch3": this.ch3, "ch4": this.ch4,
				"ch5": this.ch5, "ch6": this.ch6, "ch7": this.ch7, "ch8": this.ch8, "ch9": this.ch9, "ch10": this.ch10,
				"signersList": JSON.stringify(signerslist) , "doctype" : "PCC"
			}
			this.workflowId = Guid.create()
			console.log(JSON.stringify(pcObject))
			this.loadingService.showLoading(false, false, '', 0)
			this.precommissioningservice.generatePrecommissioningCompletion(pcObject).subscribe(
				data => {
					if (this.workflowTaskList) {
						this.taskCount = 0
						this.addInstanceTaskMap()
					}
				},
				error => {
					this.loadingService.hideLoading()
					this.alertService.error(error.statusDescription);
				})
	}}


	validateAssigneeData(): boolean{
		this.validAssignee1 = true;
		this.validAssignee2= true
		this.validAssignee3= true
		this.validAssigneeEmail1 = true;
		this.validAssigneeEmail2= true
		this.validAssigneeEmail3= true


		let validation = true;

			if (this.assignee1 == null ||this.assignee1 == ''){
				this.validAssignee1 = false;
				validation = false;
				
			}
			
			if (this.assignee2 == null ||this.assignee2 == ''){
				this.validAssignee2 = false;
				validation = false;
			}

			if(this.assignee1 !='' && this.assignee1 != null ){
			if(!this.userList.find(user => user.id == this.assignee1)){
				if(!this.emailValidate(this.assignee1)){
					this.validAssigneeEmail1 = false;
					validation = false;

				}
			}
			}
			if(this.assignee2 !='' && this.assignee2 != null ){
			if(!this.userList.find(user => user.id == this.assignee2)){
				if(!this.emailValidate(this.assignee2)){
					this.validAssigneeEmail2 = false;
					validation = false;

				}
			}
			}		

			return validation;

	}
	//generate signers list
	getSigngersList(){
		var signersIdList = [this.assignee1, this.assignee2];
		var taskNameList = [this.taskname1, this.taskname2];
		var signersList: any[] = [];
		var i= 1;
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'PCC' });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				signersList.push({'signerId': "" , 'signerName': assigneeName , 'signerEmail': assignee, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'PCC' });
				i++;
			}
		});
		return signersList;
 	}

	public delete(params: any) {
		this.selectedItem = params.data
		this.deleteModalRef = this.bsModalService.show(this.deletePCCModal, { class: 'modal-sm' })
	}

	cancel(): void {
		this.deleteModalRef.hide();
	}

	cancelWorkflow(): void {
		this.incompleteModalRef.hide()
	}

	revokePCC(): void {
		this.deleteModalRef.hide();
		this.loadingService.showLoading(false, false, '', 0)
		this.precommissioningservice.revokePrecommissioningCompletion(this.selectedItem.pcId).subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	downloadFile(params: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.precommissioningservice.downloadFile(UserVariable.projectId, params.data.pcId).subscribe(
			response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PCC_" + params.data.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.pcd_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadSentFile(params) {
		// this.loadingService.showLoading(false, false, '', 0)
		this.precommissioningservice.downloadSentFile(UserVariable.projectId, params.data.pcId).subscribe(
			response => {
				// this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "PCC_" + params.data.systemName + "_" + ".pdf");
			},
			error => {
				// this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.pcd_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadCheckSheetSentFile(params) {		
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadSentFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.initialFileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.pcd_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadCheckSheetApprovedFile(params) {
		let fileName = params.data.initialFileName
		fileName = fileName.substring(0, fileName.indexOf("."))
		this.loadingService.showLoading(false, false, '', 0)
		this.checkSheetService.downloadApprovedFile(UserVariable.projectId, params.data.checkSheetId, fileName)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, params.data.approvedFileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.pcd_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	exportToExcel() {
		var params = {
			fileName: "precommissioning_completion.xlsx",
			sheetName: "precommissioning_completion",
			suppressTextAsCDATA: true
		};
		if (this.gridApi) {
			var xlsContent = this.gridApi.getDataAsExcel(params)
			var temp = XLSX.read(xlsContent, { type: 'binary' });
			var xlsxContent = XLSX.write(temp, { bookType: 'xlsx', type: 'base64' });

			this.download(params, xlsxContent);
		}
	}

	download(params, content) {
		var fileNamePresent = params && params.fileName && params.fileName.length !== 0;
		var fileName = fileNamePresent ? params.fileName : "noWarning.xlsx";
		var blobObject = this.b64toBlob(content, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blobObject, fileName);
		} else {
			var downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blobObject);
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	}

	b64toBlob(b64Data, contentType) {
		var sliceSize = 512;
		var byteCharacters = atob(b64Data);
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	/**
	 * change scroll
	 */
	changeScroll() {
		console.log('[changeScroll]');
		this.sharedService.changeScrollRequest(this.wizard2section)
		// this.wizard2section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
	}

	onRowClick(event) {
		console.log('row clicked ' + this.iconClicked)
		if (!this.iconClicked) {
			this.showPCDetail = true
			if (this.detailGridApi) {
				this.detailGridApi.showLoadingOverlay()
			}

			if (event.data) {
				this.systemService.getPreComCheckSheets(event.data.systemId).subscribe(
					data => {
						console.log('[getCheckSheets] (data) ' + JSON.stringify(data))
						this.detailRowData = data.payload
					},
					error => {
						console.log('[getCheckSheets] (error) ' + JSON.stringify(error))
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					}
				)
			}
		} else {
			this.iconClicked = false
		}
	}

	public previous(){
		this.isSubmitted = false;
	}
}
