import { Component, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GridOptions } from "ag-grid";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { Constants } from "src/app/util/constants";
import { DependenciesModalComponent } from "src/app/util/dependenciesmodal/dependenciesmodal.component";
import { SharedService } from "src/app/util/shared.service";
import { LoadingService } from 'src/app/util/loading/loading.service';
import { PunchlistService } from "../../punchlist.service";
import * as FileSaver from 'file-saver';
import { UserVariable } from "src/app/util/common/user-variable";
import { AlertService } from '../../../util/alert/alert.service';
import { DecodeHtmlString } from "src/app/util/common/decode-html-string.pipe";

@Component({
    selector: 'app-punchlists-cell-render-component',
    templateUrl: './punchlists-cell-render-component.component.html',
    styles: [`.warn-text-editor{
        font-weight: bold !important;
        color:red !important;
      }`]
})

export class PunchlistsCellRenderComponent {
    icon: String;
    type: String;
    disable: boolean = false;
    bsModalRef: BsModalRef;
    title: string;
    dependencyType: string;
    data: String = "";
    status: string = "";
	fileName: string = "";
    action: string;
    docuSignStatus: string = "";
	openPunchlistGroupId: string = "";
	closePunchlistGroupId: string = "";
	isOpenReturnPDFUploaded: string = "";
	isCloseReturnPDFUploaded: string = "";
    parentComp: any;
    public gridOptions: GridOptions;
    public punchlistId: string = "";
    public revokeModalRef: BsModalRef;
    isRevoke: boolean = false;
    @ViewChild("revokePunchlistModal") revokePunchlistModal: TemplateRef<any>;

    //other punchlist in same group popup
    @ViewChild("otherPunchlistInSameGroupWarn") otherPunchlistInSameGroupWarn: TemplateRef<any>;
    public otherPunchlistInSameGroupWarnRef: BsModalRef;
	public rowData: any[] = [];
	public columnDefs;

    constructor(private sharedService: SharedService,
        private bsModalService: BsModalService, private translate: TranslateService,
        private loadingService: LoadingService, private punchlistService: PunchlistService,
        private alertService: AlertService, private modalService: BsModalService,
		private decodeHTMLString: DecodeHtmlString,) {
            
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.columnDefs = [
            { headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.ASSET'), field: 'asset', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 75, enableRowGroup: true },
            { headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.DESCRIPTION'), field: 'description', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, filter: "agTextColumnFilter" },
            { headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.SUB_SYSTEM'), field: 'sub_system', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200, filter: "agTextColumnFilter" },
            { headerName: this.translate.instant('PUNCHLISTDETAIL_REPORT.RAISED_BY'), field: 'raised_by', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 120, enableRowGroup: true }
        ]
    }

    agInit(params: any): void {
        let items = params.value.split(':')
        if (items.length >= 2) {
            this.data = items[1];
            if (items.length == 3) {
                this.status = items[2];
            }
        }

        this.docuSignStatus = params.data.docuSignStatus;
		this.openPunchlistGroupId = params.data.openPunchlistGroupId;
		this.closePunchlistGroupId = params.data.closePunchlistGroupId;
		this.isOpenReturnPDFUploaded = params.data.isOpenReturnPDFUploaded;
		this.isCloseReturnPDFUploaded = params.data.isCloseReturnPDFUploaded;
        this.parentComp = params.context.componentParent
		
		if (params.data.asset != null && params.data.asset != "") {
			this.fileName = params.data.asset
		}
		else if (params.data.sub_system != null && params.data.sub_system != "") {
			this.fileName = params.data.sub_system
		}

        this.setType(items[0], items[1])

        this.sharedService.disableCellRendererExecute().subscribe(option => this.setDisable(option));

    }


    public invoke(type: String, punchlistId: any) {
        this.punchlistId = punchlistId
        switch (type) {
            case 'dependencies':
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
                localStorage.setItem("isDelete", "false");
                this.viewDependencies(false);
                break;
            case 'status':
                localStorage.setItem("isDelete", "false");
                this.viewDependencies(false);
                break;
            case 'edit':
                localStorage.setItem("isDelete", "false");
                this.viewDependencies(false);
                break;
            case 'delete':
                this.getPunchlistsInSameGroup(this.punchlistId);
                break;
            case 'opensentpdf':
                this.downloadOpenSentFile(this.openPunchlistGroupId, this.fileName);
                break;
            case 'openreturnedpdf':
                this.downloadOpenReturnFile(this.openPunchlistGroupId, this.fileName);
                break;
            case 'closesentpdf':
                this.downloadCloseSentFile(this.closePunchlistGroupId, this.fileName);
                break;
            case 'closereturnedpdf':
                this.downloadCloseReturnFile(this.closePunchlistGroupId, this.fileName);
                break;
            default:
                break;
        }
    }

    private setType(params, punchlistId) {
        this.type = params;
        this.punchlistId = punchlistId;
        if (this.type == 'dependencies') {
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.VIEW;
            this.translate.get('PUNCHLIST_CELL_RENDER.DEPENDENCIES_LIST').subscribe((res: string) => {
                this.title = res;
            });
            this.icon = 'far fa-link';
        } else if (params == 'status') {
            if (this.status == "Inactive") {
                this.icon = 'far fa-times-circle';
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
                this.translate.get('PUNCHLIST_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
                    this.title = res;
                });
            } else {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
                this.translate.get('PUNCHLIST_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
                    this.title = res;
                });
                this.icon = 'far fa-check-circle';
            }
        } else if (params == 'disable_dependencies') {
            this.disable = true;
            this.icon = 'far fa-link';
        } else if (this.type == 'delete') {
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DELETE;
            this.translate.get('PUNCHLIST_CELL_RENDER.DELETE_CONFLICTS').subscribe((res: string) => {
                this.title = res;
            });
            this.isRevokeItem();
            if(this.isRevoke){
                this.icon = 'far fa-undo';
            } else{
                this.icon = 'far fa-trash-alt';
            }
        } else if (this.type == 'edit') {
            this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.EDIT;
            this.translate.get('PUNCHLIST_CELL_RENDER.EDIT_CONFIRM').subscribe((res: string) => {
                this.title = res;
            });
            this.icon = 'far fa-edit';
        } else if (params == "opensentpdf"
            && ((this.status == 'Initial' && this.docuSignStatus == "sent") || this.status == 'Open' || this.status == 'Closed')
            && (this.openPunchlistGroupId != null && this.openPunchlistGroupId != "")) {
            this.icon = 'fas fa-file-pdf';
        } else if (params == "openreturnedpdf" && (this.status == 'Open' || this.status == 'Closed') && this.isOpenReturnPDFUploaded == "true") {
            this.icon = 'fas fa-file-pdf';
        } else if (params == "closesentpdf" && ((this.status == 'Open' && this.docuSignStatus == "sent") || this.status == 'Closed')
            && (this.closePunchlistGroupId != null && this.closePunchlistGroupId != "")) {
            this.icon = 'fas fa-file-pdf';
        } else if (params == "closereturnedpdf" && (this.status == 'Closed') && this.isCloseReturnPDFUploaded == "true") {
            this.icon = 'fas fa-file-pdf';
        }
    }

    viewDependencies(dflag: boolean) {
        this.dependenciesModalWithComponent(this.title, dflag, true);
    }

    dependenciesModalWithComponent(header: String, dflag: boolean, cflag: boolean) {
        const initialState: object = {
            headerTitle: header,
            delFlag: dflag,
            cancelFlag: cflag,
            checkboxVal: this.status == "Inactive" ? true : false
        };
        this.sharedService.setFileId(this.data);
        this.sharedService.setFileType(Constants.DEPENDENCY_ITEM_TYPE.PUNCHLIST);
        this.sharedService.setAction("getDependencies");
        this.sharedService.setDependencyMessageType(this.dependencyType);
        this.sharedService.setParent(this);
        this.bsModalRef = this.bsModalService.show(DependenciesModalComponent, { initialState, class: 'modal-lg' });
    }

    private setDisable(option: any) {
        let items = this.data.split(",")
        if (option != null && option.id == items[0] && this.type == 'status') {
            this.disable = option.data;
            this.icon = this.disable ? 'far fa-times-circle' : 'far fa-check-circle';
            this.status = this.disable ? 'Inactive' : 'Active';
            if (this.status == "Inactive") {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE;
                this.translate.get('SYSTEM_CELL_RENDER.ENABLE_CONFIRM').subscribe((res: string) => {
                    this.title = res;
                });
            } else {
                this.dependencyType = Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE;
                this.translate.get('SYSTEM_CELL_RENDER.DISABLE_CONFIRM').subscribe((res: string) => {
                    this.title = res;
                });
            }
        }
    }

    downloadOpenSentFile(documentId, documentName) {
        this.loadingService.showLoading(false, false, '', 0)
        this.punchlistService.downloadOpenSentFile(UserVariable.projectId, documentId)
            .subscribe(response => {
				this.loadingService.hideLoading()
                    let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLEF_" + documentName+ "_Blank.pdf");
                },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error("could not download sent file")
                });
    }

    downloadOpenReturnFile(documentId, documentName) {
        this.loadingService.showLoading(false, false, '', 0)
        this.punchlistService.downloadOpenReturnFile(UserVariable.projectId, documentId)
            .subscribe(response => {
                    this.loadingService.hideLoading()
                    let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLEF_" + documentName + "_Signed.pdf")
                },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error("Could not download approval file")
                });
    }

	downloadCloseSentFile(documentId, documentName) {
        this.loadingService.showLoading(false, false, '', 0)
        this.punchlistService.downloadCloseSentFile(UserVariable.projectId, documentId)
            .subscribe(response => {
				this.loadingService.hideLoading()
                    let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLCR_" + documentName + "_Blank.pdf");
                },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error("could not download sent file")
                });
    }

    downloadCloseReturnFile(documentId, documentName) {
        this.loadingService.showLoading(false, false, '', 0)
        this.punchlistService.downloadCloseReturnFile(UserVariable.projectId, documentId)
            .subscribe(response => {
                    this.loadingService.hideLoading()
                    let file = new Blob([response], { type: 'application/pdf' });
                    FileSaver.saveAs(file, "PLCR_" + documentName + "_Signed.pdf");
                },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error("Could not download approval file")
                });
    }

    revokePunchlist() {
        this.revokeModalRef = this.modalService.show(this.revokePunchlistModal);
    }
    revokeConfirm() {
        this.punchlistService.revokePunchlist(this.punchlistId, this.parentComp);
    }

    getPunchlistsInSameGroup(punchlistId) {
        this.loadingService.showLoading(false, false, '', 0)
        this.punchlistService.getPunchlistsInSameGroup(punchlistId)
            .subscribe(response => {
                    this.loadingService.hideLoading();

                    if (response.payload == null || response.payload.length == 0
                        || (response.payload.length == 1 && response.payload[0]["id"] == this.punchlistId)) {
                        this.deleteOrRevokePl();
                    }
                    else {
                        this.rowData = [];
                        var payload = response.payload;
                        for (let i = 0; i < payload.length; i++) {
                            if (payload[i]["id"] != this.punchlistId) {
                                this.rowData.push({
                                    id: payload[i]["id"],
                                    asset: this.decodeHTMLString.transform(payload[i]["assetName"]),
                                    description: this.decodeHTMLString.transform(payload[i]["punchlistDescription"]),
                                    sub_system: this.decodeHTMLString.transform(payload[i]["subSystemName"]),
                                    raised_by: payload[i]["raisedBy"]
                                    
                                });
                            }
                        }
                    
                        //identify whether the punchlist is delete or revoke and set the warning accordingly
                        if (this.docuSignStatus != null && this.docuSignStatus != "" && this.docuSignStatus != "Completed") 
                        {
                            this.isRevoke = true;
                        }else{
                            this.isRevoke = false;
                        }
                        
                        this.otherPunchlistInSameGroupWarnRef = this.modalService.show(this.otherPunchlistInSameGroupWarn, {class: 'modal-lg'});
                    }
                },
                error => {
                    this.loadingService.hideLoading()
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
    }

	deleteOrRevokePl() {
		if (this.docuSignStatus != null && this.docuSignStatus != "" && this.docuSignStatus != "Completed") 
		{
			this.revokePunchlist();
		}
		else{
			localStorage.setItem("isDelete", "true");
			this.viewDependencies(true);
		}
    }
    
    isRevokeItem(){
        if (this.docuSignStatus != null && this.docuSignStatus != "" && this.docuSignStatus != "Completed") 
        {
            this.isRevoke = true;
        }else{
            this.isRevoke = false;
        }
    }
}