<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						<!-- translated widget name -->
						{{ 'ONSHORE_TURNOVER_NOTICE.WIDGET_NAME' | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
					
					<!-- other buttons like config button -->
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<!-- body content -->
		<div *ngIf="eventType == widgetEvent.SHOW_TABLE">
			<div class="row my-1">
				<div class="col-sm-9 ">
				</div>
				<div class="col-sm-3">
				<!--<div class="float-right pt-1 px-0">-->
					<button class="btn btn-primary btn-sm float-right" (click)="exportToExcel()">{{
						'ONSHORE_TURNOVER_NOTICE.EXPORT_TO_EXCEL' | translate }}</button>
					<button type="submit" id="add_otn" class="btn btn-primary btn-sm float-right pl-2 pr-2 pt-1 pb-1 ml-1 mr-1 mb-1" (click)="newOTNButtonClick()"><i class="fas fa-plus"></i></button>
				</div>
				
			</div>
			
			<div class="my-2">
				<ag-grid-angular #agGrid style="width: 100%; height: 450px;" id="onshoreTurnoverNotice" [rowData]="rowData" class="ag-theme-balham"
				 [columnDefs]="columnDefs" [enableSorting]="true" [frameworkComponents]="components" [enableFilter]="true" [enableColResize]="true" [enableColResize]="true"
				 [floatingFilter]="true" [enableRangeSelection]="true" [pagination]="true" (columnResized)="onColumnResized($event)"
				 (gridReady)="onGridReady($event)" (rowClicked)="onRowClick($event)" [gridOptions]="gridOptions" [suppressMenuHide]="true">
				</ag-grid-angular>
			</div>
		</div>
	</div>
	<div *ngIf="eventType == widgetEvent.SHOW_WIZARD">
			<div class="row px-4">
				<button type="button" class="btn btn-primary btn-sm" (click)="backToTable()">
					<i class="fa fa-arrow-left mx-2" aria-hidden="true"></i>{{ 'OTN.BACK_TO_TABLE' | translate }}
				</button>
			</div>
			<div class="row m-0">
				<div class="col-sm-12 my-2">
					<aw-wizard class="custom-css">
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
											<span *ngIf="selectedItem.otnstatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.otnstatus)}}</span>
									</h6>
									<hr />
									<div>
										<p>{{ 'OTN.WORKFLOW_TITLE' | translate }}
										</p>
										<label>1. All Inspection Release Notices are complete</label>
											<ul>
												<li class="list-group-item border-0 mx-2 p-1"  *ngFor="let item of itemsList1">
												  <input type="radio" class="form-check-input" [(ngModel)]="radioSelected1" name="list_name1" value="{{item.value}}"/> 
												  <label class="form-check-label">
														<p class="m-0 text-muted">{{item.name}}</p>
													</label>
												</li>
										  </ul>
										<label>2. Turnover (final) walkdown is complete</label>
											<ul>
											<li class="list-group-item border-0 mx-2 p-1"  *ngFor="let item of itemsList2">
												<input type="radio" class="form-check-input" [(ngModel)]="radioSelected2" name="list_name2" value="{{item.value}}"/> 
												<label class="form-check-label">
													<p class="m-0 text-muted">{{item.name}}</p>
												</label>
											</li>
										  </ul>
										<label>3. Handover Certificate has been issued</label>
											<ul>
												<li class="list-group-item border-0 mx-2 p-1"  *ngFor="let item of itemsList3">
													<input type="radio" class="form-check-input" [(ngModel)]="radioSelected3" name="list_name3" value="{{item.value}}"/> 
													<label class="form-check-label">
														<p class="m-0 text-muted">{{item.name}}</p>
													</label>
												</li>
											  </ul>
										<label>4. Punchlists items have been identified and categorized</label>
											<ul>
												<li class="list-group-item border-0 mx-2 p-1"  *ngFor="let item of itemsList4">
													<input type="radio" class="form-check-input" [(ngModel)]="radioSelected4" name="list_name4" value="{{item.value}}"/> 
													<label class="form-check-label">
														<p class="m-0 text-muted">{{item.name}}</p>
													</label>
												</li>
											  </ul>
										<label>5. All “A” punchlist items have been cleared</label>
											<ul>
												<!--<li class="list-group-item border-0 mx-2 p-1"  *ngFor="let item of itemsList5">
													<input type="radio" class="form-check-input" [(ngModel)]="radioSelected5" name="list_name5" value="{{item.value}}"/> 
													<label class="form-check-label">
														<p class="m-0 text-muted">{{item.name}}</p>
													</label>
												</li>-->
												<li class="list-group-item border-0 mx-2 p-1">
														<input type="radio" class="form-check-input" [(ngModel)]="radioSelected5" name="list_name5" value="APunchlistClosedY"/> 
														<label class="form-check-label">
															<p class="m-0 text-muted">Yes (“A” Punches exist but are closed)</p>
														</label>
													</li>
													<li class="list-group-item border-0 mx-2 p-1">
															<input type="radio" class="form-check-input" [(ngModel)]="radioSelected5" name="list_name5" value="APunchlistClosedN"/> 
															<label class="form-check-label">
																<p class="m-0 text-muted">No (“A” Punches exist but are <b><u>not</u></b> closed)</p>
															</label>
														</li>
												<li class="list-group-item border-0 mx-2 p-1">
														<input type="radio" class="form-check-input" [(ngModel)]="radioSelected5" name="list_name5" value="APunchlistClosedNA"/> 
														<label class="form-check-label">
															<p class="m-0 text-muted">N/A (No “A” punches were/are identified)</p>
														</label>
													</li>
											  </ul>
										<p>{{ 'OTN.SUMMARY' | translate }}</p>
										<table class="table" *ngIf="openPunchlists.length>0">
											<tr>
												<th>{{ 'OTN.CATEGORY' | translate }}</th>
												<th>{{ 'OTN.ASSET' | translate }}</th>
												<th>{{ 'OTN.SYSTEM_SUBSYSTEM' | translate }}</th>
												<th>{{ 'OTN.PL_DESCRIPTION' | translate }}</th>
											</tr>
											<tr *ngFor="let item of openPunchlists">
												<td>{{ item.category }}</td>
												<td>{{ item.assetName }}</td>
												<td>{{ item.systemName }}</td>
												<td>{{ item.punchlistDescription }}</td>
											</tr>
										</table>
										<p *ngIf="!openPunchlists.length>0" class="m-0 text-muted">{{ 'OTN.NO_PUNCHLISTS' | translate }}</p>
									</div>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-primary btn-sm" awNextStep>{{ 'OTN.NEXT' |
									translate }}</button>
							</div>
						</aw-wizard-step>
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.otnstatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.otnstatus)}}</span>
									</h6>
									<hr />
									<form>
										<div class="row" *ngFor="let task of WorkflowTaskList; let i = index">
											<div class="col-sm-10 form-group">
												<label class="my-1 mx-0"><b>{{task.taskName}}</b></label>
												<div class="form-row">
													<div class="col">
														<app-custom-typeahead *ngIf="i==0" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee1 && (isSubmitted && i==0) ">
															Empty user
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail1 && (isSubmitted && i==0) ">
															Invalid email address
														</p>
														<app-custom-typeahead *ngIf="i==1" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee2 && (isSubmitted && i==1) ">
															Empty user
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail2 && (isSubmitted && i==1) ">
															Invalid email address
														</p>
														<app-custom-typeahead *ngIf="i==2" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee3 && (isSubmitted && i==2) ">
															Empty user
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail3 && (isSubmitted && i==2) ">
															Invalid email address
														</p>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-secondary btn-sm mx-2" (click)="previous()" awPreviousStep>{{
									'OTN.PREVIOUS' | translate }}</button>
								<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">{{
									'OTN.START' | translate }}</button>
							</div>
						</aw-wizard-step>
						<!-- <aw-wizard-step stepTitle="">
										<div class="card">
											<div class="card-body">
												This is some text within a card body. step 3
											</div>
										</div>
										<div class="my-2 float-right">
											<button type="button" class="btn btn-secondary btn-sm mx-2" awPreviousStep>Previous</button>
											<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">Finish</button>
										</div>
									</aw-wizard-step> -->
					</aw-wizard>
				</div>
	</div>
</div>
<ng-template #incompleteOTNModal>
		<div class="modal-header">
			<h6 class="modal-title pull-left">{{ 'OTN.INCOMPLETE_OTN_TITLE' | translate }}</h6>
			<button type="button" class="close pull-right" aria-label="Close" (click)="incompleteModalRef.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body ">
			<p *ngIf="hasAssetWithNoIRN"> {{ 'OTN.INCOMPLETE_IRN_OTN_MSG' | translate }} </p>
			<div>
				<ul>
				  <div *ngFor="let tag of assetIrnCheckList">
					<!-- <li *ngIf="!tag.hasIRN"> -->
					  <li>{{tag.tagNo}}</li> 
					<!-- </li> -->
				  </div>
				</ul>
			  </div>
			<p *ngIf="hasAssetWitOpenPunchlists"> {{ 'OTN.INCOMPLETE_PL_OTN_MSG' | translate }} </p>
			<div>
				<ul>
				  <div *ngFor="let tag of assetOpenPunchlistCheckList">
					<!-- <li *ngIf="tag.hasOpenPunchlists"> -->
					 <li>{{tag.tagNo}}</li> 
					<!-- </li> -->
				  </div>
				</ul>
			  </div>
			<p> {{ 'OTN.ASK_TO_CONTINUE' | translate }} </p>
			<div class="float-right">
				<button type="button" class="btn btn-default mx-1" (click)="continue()">{{ 'OTN.CONTINUE' | translate
					}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="cancelWorkflow()">{{ 'OTN.CANCEL' | translate }}</button>
			</div>
		</div>
	</ng-template>
		

		

