import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { OpenWorkItemsService } from '../my-open-workitems.service';


import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-my-open-work-item-count',
    templateUrl: './my-open-work-item-count.component.html',
    styleUrls: ['./my-open-work-item-count.component.css']
})
export class MyOpenWorkItemCountComponent implements OnInit {

    public isMinimized: boolean = false;
    public isHeader: boolean = false;
    public myOpenWorkItems: number;
    public myOpenWorkItemCount = 0;
    public isLoadingData: boolean = false

    constructor(private openWorkItemsService: OpenWorkItemsService, private translate: TranslateService,
        private alertService: AlertService,
        private sharedService: SharedService) {

    }

    ngOnInit() {
        this.isLoadingData = true
        this.openWorkItemsService.getOpenWorkItems().subscribe(
            data => {
                if (data.payload != null) {
                    this.isLoadingData = false
                    for (let i = 0; i < data.payload.length; i++) {
                        this.myOpenWorkItems = data.payload[i];
                        this.myOpenWorkItemCount++;
                    }
                } else {
                    //do nothing
                }
            },
            error => {
                this.isLoadingData = false
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            });
    }
}
