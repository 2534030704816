import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Notification } from './notification.model';
import { NotificationService } from './notification.service';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../util/shared.service';
import { timer } from 'rxjs';
import { LocalStorage } from '../../util/localstorage.service';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

    public notificationList: Array<Notification> = new Array<Notification>()
    notificationList_translated_data: any = null;
    private pageNo = 0
    private pageLimit = 20
    public isHideSeeMore = true
    public isHideClearAll = true

    @Output() messageEvent = new EventEmitter<any>();


    constructor(private sharedService: SharedService,private notificationService: NotificationService, private translate: TranslateService,
        private alertService: AlertService,
    ) {
        let browserLang = translate.getBrowserLang();
        translate.getTranslation(browserLang).subscribe((res: string) => {
            this.notificationList_translated_data = res['NOTIFICATION_LIST']
        });
        this.loadData(false);
    }

    ngOnInit() {
    }


    loadData(isReset:Boolean) {

        if  (isReset) {
            this.pageNo = 0;
            this.notificationList = [];
        }

        let pageValue = this.pageNo * this.pageLimit
        let pageLimit = this.pageLimit

        this.notificationService.getNotifications(pageValue,pageLimit).subscribe(
            data => {
                //this.notificationList = [];
                let lastFive = "";
                if (data.payload != null) {
                    this.pageNo++;
                    this.isHideSeeMore = false

                    if (data.payload.length < 1) {
                        this.pageNo = -1;
                        this.isHideSeeMore = true
                        return
                    }

                    for (let i = 0; i < data.payload.length; i++) {
                        let filetypecontent = data.payload[i]["notificationContent"];
                        if (filetypecontent != null) {
                            lastFive = filetypecontent.substr(filetypecontent.length - 3);
                        }

                        if ( data.payload[i]["viewed"]  == 0) {
                            this.isHideClearAll = false
                        }

                        this.notificationList.push({
                            id: data.payload[i]["id"],
                            appType: data.payload[i]["appType"],
                            userId: data.payload[i]["userId"],
                            notificationContent: data.payload[i]["notificationContent"],
                            notificationType: data.payload[i]["notificationType"],
                            itemId: data.payload[i]["itemId"],
                            notificationScope: data.payload[i]["notificationScope"],
                            notificationPriority: data.payload[i]["notificationPriority"],
                            addedTimestamp: data.payload[i]["addedTimestamp"],
                            firingTimestamp: formatDate(data.payload[i]["firingTimestamp"], 'yyy-MM-dd hh:mm:ss', 'en-US'),
                            displayed: data.payload[i]["displayed"],
                            viewed: data.payload[i]["viewed"]
                        });
                    }

                } else {
                    //do nothing
                    this.pageNo = -1;
                    this.isHideSeeMore = true
                }
            },
            error => {
                this.pageNo = -1;
                this.isHideSeeMore = true
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            })
    }

    clearAll() {
        console.log("clear all tap")
        this.isHideSeeMore = true

        this.notificationService.getUpdateViewedAsAllNotifications().subscribe(
            data => {
                if (data.payload != null) {
                    //   console.log(data.payload);
                    this.loadData(true)
                    this.messageEvent.emit()
                    this.isHideClearAll = true
                } else {
                    //do nothing
                }

            },
            error => {
                this.messageEvent.emit()
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            })
       
        
    }


    public noticifationClickEvent(notification: Notification) {
        console.log('[noticifationClickEvent] (notification) ' + JSON.stringify(notification));
        this.notificationService.getUpdateViewedNotification(notification.id).subscribe(
            data => {
                if (data.payload != null) {
                    this.sharedService.tnRequest()
                    //console.log(data.payload);

                } else {
                    //do nothing
                }

            },
            error => {
                this.sharedService.tnRequest()
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            })
        // 
    }

}
