import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button *ngIf="show()" (click)="downloadSentFile()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
	'</button>'

})
export class PrecommissioningSentFileIconRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public show() {
		if (this.params.data) {
			if (this.params.data.pcstatus == 'Issued' || this.params.data.pcstatus == 'Approved') {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}

	public downloadSentFile() {
		this.params.context.componentParent.iconClicked = true
		this.params.context.componentParent.downloadSentFile(this.params)
	}

	refresh(): boolean {
		return false;
	}
}