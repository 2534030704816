import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { PunchlistService } from '../punchlist.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
	selector: 'app-punchlist-item',
	templateUrl: './punchlist-item.component.html',
	styleUrls: ['./punchlist-item.component.css']
})
export class PunchlistItemComponent implements OnInit {
	public isLoadingData: boolean = false
	public isMinimized: boolean = false;
	public isHeader: boolean = false;
	public punchlistItems: number = 0;

	constructor(private punchlistService: PunchlistService, private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService) { }

	ngOnInit() {
		this.isLoadingData = true
		this.punchlistService.getPunchlists().subscribe(
			data => {
				this.isLoadingData = false
				if (data.payload) {
					this.punchlistItems = data.payload.length
				} else {
					//do nothing
				}
			},
			error => {
				this.isLoadingData = false
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

}
