<div class="panel count-panel count-panel-warning">
	<div class="card-block card-body py-0 px-3">
		<!-- body content -->
		<div class="row">
			<div class="col-sm-4 count-panel-icon p-0 text-center">
				<i class="fas fa-hashtag fa-3x py-3" aria-hidden="true"></i>
			</div>
			<div class="col-sm-8 px-3 py-1 count-panel-text">
				<div class="btn-group float-right" dropdown container="body">
					<button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 aria-controls="dropdown-basic"><i class="fas fa-cog" aria-hidden="true"></i><span class="caret"></span>
					</button>
					<ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="min-width: 0.5rem;">
						<li role="menuitem"><a class="dropdown-item cursor-pointer" (click)="changeItemType('OpenAPLCount')">A</a></li>
						<li role="menuitem"><a class="dropdown-item cursor-pointer" (click)="changeItemType('OpenBPLCount')">B</a></li>
						<li role="menuitem"><a class="dropdown-item cursor-pointer" (click)="changeItemType('OpenCPLCount')">C</a></li>
						<li role="menuitem"><a class="dropdown-item cursor-pointer" (click)="changeItemType('OpenDPLCount')">D</a></li>
					</ul>
				</div>
				<h6 class="mb-0">
					{{punchlistType}} {{ 'PUNCHLIST_ITEMS_PER_CATEGORY.PUNCHLIST_ITEMS' | translate }}
				</h6>
				<p class="mb-0">
					<b *ngIf="isLoadingData">{{ "GENERAL.LOADING" | translate }}</b>
					<b *ngIf="!isLoadingData">{{punchlistItemsPerCategory | number: '1.'}}</b>
				</p>
			</div>
		</div>
	</div>
</div>
<!-- popup templates -->
<!-- modal templates -->