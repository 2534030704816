<div class="panel panel-default">
    <div class="panel-heading box-shadow panel-header-border-bottom py-1">
            <div class="row px-2">
                <div class="col-sm-12 px-0">
                    <div class="float-left pt-1 px-1">
                        <p class="m-0 pl-1">
                            <!-- translated widget name -->
                            {{ "SYSTEMCOMPLETIONSTATUS_REPORT.WIDGET_NAME" | translate }}
                        </p>
                    </div>
                    <div class="float-right pt-1 px-0">
                        <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
                    </div>
                </div>
            </div>
        </div>
  <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}" >
    <div>
    <div class="row">
      <div class="column body">{{projectName}}</div>
    </div>
     <div class="row">
      <div class="column body">EPC2/3 SURF</div>
    </div>
    <div class="row">
    <div class="column"></div>
    </div>
    <div class="row custom-grid1-wrapper">
    <ag-grid-angular 
        style="width: 100%; height: 960px;" 
        class="ag-theme-balham custom-grid1"
        id="myGrid"
        [rowData]="rowData" 
        [columnDefs]="columnDefs"
        headerHeight="300"
        [enableColResize] = true
        [defaultColDef]="defaultColDef"
        [frameworkComponents]="frameworkComponents"
        (cellValueChanged)="onCellValueChanged($event)"
        >
   </ag-grid-angular>
</div>
   <div class="d-flex">
        <ul class="list-inline mx-auto justify-content-center">
            <li class="list-inline-item"><i class="fas fa-circle green-state mr-2"></i>Complete</li>
            <li class="list-inline-item"><i class="fas fa-circle yellow-state mr-2"></i>Complete With P/L</li>
            <li class="list-inline-item"><i class="fas fa-circle red-state mr-2"></i>Outstanding</li>
            <li class="list-inline-item"><i class="fas fa-circle nutral-state mr-2"></i>N/A</li>
        </ul>
    </div>
    <div class="d-flex">
    <ag-grid-angular
        #agGrid
        style="width: 100%; height: 220px;"
        id="myGridSummary"
        class="ag-theme-balham"
        [enableColResize] = true
        [columnDefs]="columnDefsSummary"
        [defaultColDef]="defaultColDef"
        [rowData]="rowDataSummary"
        [groupHeaderHeight]="groupHeaderHeightSummary"
        [headerHeight]="headerHeightSummary"
        (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
    </div>
    </div>
    </div>
    <div></div>
    </div>