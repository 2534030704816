<alert class="floating-alert"></alert>
<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header [showHamburger]="false" [projectName]="projectName" [showSetDefault]="false"></app-header>
</nav>

<div class="main-panel pb-2" role="main">
	<div class="container-fluid mt-3 mx-auto">
		<div class="row">
			<div class="col-sm-12 py-2">
				<label class="h5">{{'LANDING.MESSAGE' | translate }}</label>
				<button *ngIf="option == 'change_project'" type="button" class="btn btn-primary btn-sm float-right" (click)="cancelBtnEvent()">Cancel</button>
			</div>
			<div class="col-sm-12" >
				<ag-grid-angular #agGrid style="height: 500px;width: 100%" class="ag-theme-balham" [enableSorting]="true"
				 [enableFilter]="true" [rowData]="rowData" [columnDefs]="columnDefs" rowSelection="multiple" [rowDeselection]="true"
				 [rowMultiSelectWithClick]="false" (cellClicked)="onCellClicked($event)" [enableColResize]="true" [getRowNodeId]="getRowNodeId"
				 (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="15">
				</ag-grid-angular>
			</div>
		</div>
		<!-- <h4 class="ml-4 mr-2 mt-3">{{ 'ALL_PROJECTS.PROJECTS' | translate }}</h4> -->
	</div>
</div>