import { Component, OnInit, ViewChild, Input } from '@angular/core';
import "ag-grid-enterprise";
import { WorkItemSkylineWidgetService } from './work-item-skyline-widget.service'
import { LoggedUser } from '../../user/logged-user.model';
import { Chart } from 'angular-highcharts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SystemService } from '../system.service';
import { AlertService } from '../../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageConst, WIDGET_EVENT } from '../../util/constants';
import { SharedService } from '../../util/shared.service';
import { MechanicalCompletionService } from '../mechanical-completion.service'
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { GridOptions } from 'ag-grid';
import { LoadingService } from '../../util/loading/loading.service'
import { UserVariable } from '../../util/common/user-variable';
import { WorkItemSkylineWidgetCellRenderComponent } from './cell-renderer/work-item-skyline-widget-cell-render.component';
import { CheckSheetApprovedFileIconRenderer } from '../common-cell-renderer/file-icon/checksheet-approved-file-icon.component'
import { DateFormatter, BsDatepickerConfig } from 'ngx-bootstrap';
import { WIEditButtonRenderer } from './cell-renderer/edit-button/edit-button.component';
import { WIAddButtonRenderer } from './cell-renderer/add-button/add-button.component';
import { WIDeleteButtonRenderer } from './cell-renderer/delete-button/delete-button.component';
import { CapabilityService } from '../../util/capability.service';
import { formatDate, DatePipe } from '@angular/common';
import "ag-grid-enterprise";
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-work-item-skyline-widget',
	templateUrl: './work-item-skyline-widget.component.html',
	styleUrls: ['./work-item-skyline-widget.component.css']
})
export class WorkItemSkylineWidgetComponent implements OnInit {


	private loggedInUser: LoggedUser;
	public chart: Chart
	public vendorList: Array<any> = []
	public selectedVendor: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public selectedCritical: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public y_axis_text: string = ''
	public x_axis_text: string = ''
	@ViewChild('autoShownModal') autoShownModal: ModalDirective;
	isModalShown: boolean = false;
	private currentMenuItem: string = ''
	private xAxisList: string[] = []
	private yAxisMax: number = 0
	public self: any
	public criticalList: Array<any> = [
		{ 'id': "All", 'text': "ALL" },
		{ 'id': "Critical", 'text': "Critical" },
		{ 'id': "NonCritical", 'text': "Non-Critical" }
	]
	isCritical: any;
	vendorId: any;
	message = "";
	public isShowLabel: boolean = false
	public isMinimized: boolean = false;
	public isLoadingData: boolean = true;
	public isNoDataToShow: boolean = false;
	public showWIDetail: boolean = false;
	private selectedPoint: any = null;
	private translatedData: any = null;
	public gridOptions: GridOptions;
	public detailGridOptions: GridOptions;
	public masterGridOptions: GridOptions;
	private columnDefs: any;
	private detailCellRendererParams;
	public commentsColumnDefs: any;
	public editCommentsColumnDefs: any;
	public rowData: any[];
	private detailRowData: any[] = [];
	gridApi: any;
	public statusDropDown: Array<any> = [
		{ 'id': "Open" },
		{ 'id': "Closed" }
	]
	gridColumnApi: any;
	public plotLineValue: number = 0;
	public targetDeliveryDate
	public installaionDate
	public assetName
	public asselWorkItems
	public opentDesc
	public totaWorkItems
	public status
	public deliveredDate: Date
	public liftedDate: Date
	public installedDate
	public showFilterOptions: boolean = false;
	public addedDate: Date;
	detailGridApi: any;
	detailGridColumnApi: any;
	modifyWorkItemComment: boolean = false;
	private bsConfig: Partial<BsDatepickerConfig>
	iconClicked: boolean = false;
	eventType: any = WIDGET_EVENT.SHOW_TABLE;
	widgetEvent = WIDGET_EVENT
	private selectedItem: any = null
	public required: boolean = null
	comment: string;
	public userComment: string = "";
	private workItemId: string;
	public commentInput: string;
	@Input('showCommentInput') showCommentInput: boolean;
	showAddComment: boolean = false;
	public checkedAddClicked: boolean = false;
	public checkedEditClicked: boolean = false;
	workPackageId: any;
	public commentState: string;
	timer: number = null;
	public isChart: boolean = false
	commentId: string;
	allWorkItemList: Array<any> = []
	public selectedItemForCD: any = null
	private backEvent: any = null
	detailRowHeight: number;
	getRowHeight: (params: any) => number;
	public currentDate: string

	constructor(private WorkItemSkylineService: WorkItemSkylineWidgetService, private alertService: AlertService, private translate: TranslateService,
		private capabilityService: CapabilityService, private loadingService: LoadingService) {

		this.self = this
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			},
			autoHeight: true,
			// rowHeight: 130
			// rowTemplate: 
			rowData: this.rowData,

			// enable master detail
			masterDetail: true,

			// specify params for default detail cell renderer
			detailCellRendererParams: {
				// provide detail grid options
				detailGridOptions: this.detailGridOptions,
			}
			// rowHeight: 130
			// rowTemplate: 
		};

		this.detailGridOptions = <GridOptions>{
			columnDefs: this.columnDefs,
			context: {
				componentParent: this
			},
			paginationPageSize: 5,
			autoHeight: true,
		};

		this.modifyWorkItemComment = capabilityService.isCapabilityAssigned("modifyWorkItemComment");
		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['WORK_ITEM']
			this.columnDefs = [
				{
					headerName: this.translatedData.TYPE,
					field: 'type',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 120,
					cellRenderer: "agGroupCellRenderer",
					filter: "agTextColumnFilter"
				},
				{
					headerName: this.translatedData.DESCRIPTION,
					field: 'description',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 300,
					filter: "agTextColumnFilter",
				},
				{
					headerName: this.translatedData.STATUS,
					field: 'status',
					autoHeight: true,
					cellClass: "dropdown-toggle",
					minWidth: 100,
					// cellRendererFramework: WorkItemSkylineWidgetCellRenderComponent
					editable: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: ["Open", "Closed"]
					},
					singleClickEdit: true
				},
				{
					headerName: this.translatedData.LIFT_CRITICAL,
					field: 'liftCritical',
					autoHeight: true,
					cellClass: "dropdown-toggle",
					minWidth: 99,
					// cellRendererFramework: WorkItemSkylineWidgetCellRenderComponent,
					editable: true,
					cellEditor: "agRichSelectCellEditor",
					cellEditorParams: {
						values: ["Yes", "No"],
					},
					singleClickEdit: true
				},
				{
					headerName: this.translatedData.PRIORITY,
					field: 'priority',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150
				},
				{
					headerName: this.translatedData.OWNERS,
					field: 'owners',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 150,
				},
				{
					headerName: this.translatedData.COMMENTS,
					field: 'comments',
					autoHeight: true,
					cellClass: "cell-wrap-text",
					minWidth: 100,
					suppressFilter: true,
					cellRendererFramework: WIAddButtonRenderer
				}
			]
			this.initChart()
			this.getSkylineDataAndLoadGraph('ALL', 'All')
		})

		//columnDef for display comments details
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['WORK_ITEM']
			// this.detailRowHeight = 250;
			this.detailCellRendererParams = {
				detailGridOptions: {
					context: {
						componentParent: this
					},
					floatingFilter: "true",
					columnDefs: [
						{
							headerName: this.translatedData.COMMENT,
							field: 'comment',
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 600,
							filter: "agTextColumnFilter"
						},
						{
							headerName: this.translatedData.DATE,
							field: 'addedDate',
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 150,
							filter: 'agDateColumnFilter',
							filterParams: {
								comparator: function (filterLocalDateAtMidnight, cellValue) {
									var dateAsString = cellValue;
									var cellDate = new Date(dateAsString)
									if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
										return 0;
									}
									if (cellDate < filterLocalDateAtMidnight) {
										return -1;
									}
									if (cellDate > filterLocalDateAtMidnight) {
										return 1;
									}
								}
							},
						},
						{
							headerName: this.translatedData.USER,
							field: 'userName',
							autoHeight: true,
							cellClass: "cell-wrap-box",
							minWidth: 200,
							filter: "agTextColumnFilter"
						},
						{
							headerName: this.translatedData.EDIT,
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 80,
							suppressFilter: true,
							cellRendererFramework: WIEditButtonRenderer
						},
						{
							headerName: this.translatedData.DELETE,
							autoHeight: true,
							cellClass: "cell-wrap-text",
							minWidth: 80,
							suppressFilter: true,
							cellRendererFramework: WIDeleteButtonRenderer
						}

					],
					// enableColResize: true,
					defaultColDef: { editable: true },

					onGridReady: function (params) {
						params.api.setGridAutoHeight(true);
						setTimeout(() => {
							params.api.sizeColumnsToFit();
						}, 500);
						window.addEventListener("resize", function () {
							setTimeout(function () {
								params.api.sizeColumnsToFit();
							});
						});
					}
				},
				getDetailRowData: function (params) {
					let detailRowData = [];
					if (params.data.comments != null) {
						for (let i = 0; i < params.data.comments.length; i++) {
							detailRowData.push({
								comment: params.data.comments[i]["comment"],
								addedDate: new Date(params.data.comments[i]["addedDate"]).toLocaleDateString(),
								userName: params.data.comments[i]["userName"],
								workItemId: params.data["workItemId"],
								commWorkItemCommentId: params.data.comments[i]["id"]
							});
							params.successCallback(detailRowData);
						}
					} else {
						params.successCallback(params.data.comments);
					}
				}
			};
			this.getRowHeight = function (params) {
				if (params.node && params.node.detail) {
					var offset = 400;
					var commentsLength = 0
					if (params.data.comments) {
						offset = 350;
						commentsLength = params.data.comments.length
						console.log(commentsLength)
					}
					var allDetailRowHeight = commentsLength * 28;
					return allDetailRowHeight + offset;
				} else {
					return 60;
				}
			};

		})

		WorkItemSkylineService.getAllVendors().subscribe(
			data => {
				this.vendorList = []
				this.vendorList.push({ 'id': 'ALL', 'text': 'ALL' })
				if (data.payload != null) {
					data.payload.forEach(element => {
						this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName })
					});
				} else {
					// do nothing
				}
			},
			error => {
				console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)
	}

	ngOnInit() {
		this.isCritical = "All";
		this.vendorId = "ALL";
		let date = new Date();
		this.currentDate = formatDate(date, 'yyy-MM-dd', 'en-US');
	}

	selectedVendorId(params) {
		this.vendorId = params.id
		let vendorId = params.id
		let isCritical = this.isCritical
		this.resetGraph()
		this.getSkylineDataAndLoadGraph(vendorId, isCritical)
	}

	selectedCriticalId(params) {
		this.isCritical = params.id
		let isCritical = params.id
		let vendorId = this.vendorId
		this.resetGraph()
		this.getSkylineDataAndLoadGraph(vendorId, isCritical)
	}

	removedVendor(event) {
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		let criticality = this.isCritical
		this.resetGraph()
		this.getSkylineDataAndLoadGraph('ALL', criticality)
	}

	removedCritical(event) {
		this.selectedCritical = [{ 'id': 'ALL', 'text': 'ALL' }]
		let vendor = this.vendorId
		this.resetGraph()
		this.getSkylineDataAndLoadGraph(vendor, 'All')
	}

	private generateXaxis(dateList: any): string[] {
		let returnVal: string[] = []
		if (dateList != null) {
			this.isChart = true
			dateList.forEach(date => {
				returnVal.push(date.dateLabel)
			});
			return returnVal
		}
		else {
			this.message = "No Data to Display"
		}
	}


	private generateDataSeries(payload: any) {
		this.allWorkItemList = []
		if (payload != null) {
			if (payload.dateList != null) {
				//loop through week list and get week label as x value
				payload.dateList.forEach(date => {
					let xValue: string = date.dateLabel
					let xval: number = this.xAxisList.indexOf(date.dateLabel)
					//loop through system list and generate points for the graph
					date.workItemList.forEach(item => {
						let point: any = item
						let yVal: number = 1
						let color: string = this.provideColorToSeries(item.color)
						let tooltip: string = this.generateTooltip(item, xValue)
						//name is defined as assetName
						let name: string = item.assetName
						let data_point: any =
							{
								'x': xval,
								'y': yVal,
								'color': color,
								'name': name,
								'pointToolTip': tooltip,
								'actualColor': item.color,
								'pointData': point
							}
						this.allWorkItemList.push(data_point)
					});
				});
				this.initChart()
				this.filterAndLoadSeriesByColor(this.allWorkItemList)
			}
		}
	}

	private provideColorToSeries(colorStr: string): string {
		let colorValur: string = ''
		switch (colorStr) {
			case 'Green':
				colorValur = '#388E3C'
				break
			case 'Yellow':
				colorValur = '#FBC02D'
				break
			case 'White':
				colorValur = '#EEEEEE'
				break
			case 'Red':
				colorValur = '#D32F2F'
				break
			case 'Orange':
				colorValur = '#F57C00'
				break
			case 'Gray':
				colorValur = '#757575'
				break
			case 'Blue':
				colorValur = '#0288D1'
				break
		}
		return colorValur
	}

	private generateTooltip(point: any, xValue: string): string {
		//set workPackageId as workItemNumber
		let workItemNumber: string = point.workPackageId
		let totalWorkItems: number = point.totalWorkItemCount
		let openWorkItems: number = point.openWorkItemCountTooltip
		//set assetName as workItemname
		let workItemName: string = point.assetName
		let status: string = point.status
		let completionDays: number = point.completionDays
		let liftCriticalItemCount: number = point.liftCriticalItemCount
		let openPunchListCount: number = point.openPunchListCount
		// let deliveryDate: Date = point.actualDeliveryDate
		let targetCompletionString: string = ''
		let deliveryDate = new Date(point.actualDeliveryDate).toLocaleDateString()

		let ttString = ''
		ttString += '<b>' + xValue + ' : ' + workItemName + '</b><br />'

		ttString += ' Status - ' + status + ' <br />'
		ttString += ' Total Work Item(s) - ' + totalWorkItems + ' <br />'
		ttString += ' Lift Critical Count(s) - ' + liftCriticalItemCount + ' <br />'
		ttString += ' Open Work Item(s) - ' + openWorkItems + ' <br />'
		if (point.actualDeliveryDate) {
			ttString += ' Delivered Date - ' + deliveryDate + ' <br />'
		} else {
			ttString += ' Target Delivery Date - ' + new Date(point.targetDeliveryDate).toLocaleDateString() + ' <br />'
		}
		// ttString += targetCompletionString

		return ttString
	}

	private filterAndLoadSeriesByColor(allSystemList: any) {
		let redList: any = this.generateSeriesByColor(allSystemList, 'Red')
		let blueList: any = this.generateSeriesByColor(allSystemList, 'Blue')
		let orangeList: any = this.generateSeriesByColor(allSystemList, 'Orange')
		let whiteList: any = this.generateSeriesByColor(allSystemList, 'White')
		let greenList: any = this.generateSeriesByColor(allSystemList, 'Green')
		let yellowList: any = this.generateSeriesByColor(allSystemList, 'Yellow')
		let greyList: any = this.generateSeriesByColor(allSystemList, 'Gray')

		this.chart.addSerie({
			name: 'Other',
			color: '#757575',
			data: greyList.reverse()
		})
		this.chart.addSerie({
			name: 'Installed',
			color: '#0288D1',//red color code
			data: blueList.reverse()
		})
		this.chart.addSerie({
			name: 'Lifted',
			color: '#388E3C',
			data: greenList.reverse()
		})
		this.chart.addSerie({
			name: 'Delivered',
			color: '#F57C00',
			data: orangeList.reverse()
		})
		this.chart.addSerie({
			name: 'Not Delivered',
			color: '#D32F2F',
			data: redList.reverse()
		})
		this.chart.addSerie({
			name: 'Open Workitems',
			color: '#EEEEEE',
			data: whiteList.reverse()
		})
				
		this.isLoadingData = false

	}

	private generateSeriesByColor(vendorList: any, color: string): any {
		let returnList = vendorList.filter(vendor => vendor.actualColor === color)
		return returnList
	}

	public initChart() {
		this.chart = new Chart({
			chart: {
				type: 'column',
				zoomType: 'x'
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			loading: {
				labelStyle: {
					color: '#FAFAFA'
				},
				style: {
					backgroundColor: '#BDBDBD'
				}
			},
			xAxis: {
				title: {
					text: this.translatedData.X_AXIS
				},
				categories: this.xAxisList,
				crosshair: true,
				min: 0,
				max: (this.xAxisList.length - 1),

				plotLines: [{
					color: '#757575',
					dashStyle: 'ShortDash',
					value: this.plotLineValue,
					width: 2,
					label: {
						text: this.translatedData.TODAY,
						verticalAlign: 'top',
						textAlign: 'center',
						x: -8,
						rotation: 270
					}
				}]
			},
			yAxis: [{ // left y axis	
				gridLineWidth: 1,
				tickWidth: 1,
				minTickInterval: 1,
				title: {
					text: this.translatedData.Y_AXIS
				},
				max: this.yAxisMax,
				reversedStacks: false
			}],
			plotOptions: {
				column: {
					cursor: 'pointer',
					borderColor: '#9E9E9E',
					borderWidth: 0.5,
					stacking: 'normal',
					dataLabels: {
						enabled: this.isShowLabel,
						rotation: 0,
						style: {
							fontSize: '10',
							textOutline: '0px',
							color: '#212121'
						},
						formatter: function () {
							return this.point.name
						}
					},
					maxPointWidth: 80
				},
				series: {
					stacking: 'normal',
					shadow: false,
					point: {
						events: {
							click: (event) => this.barClickEvent(event),
						}
					},
				}
			},
			tooltip: {
				crosshairs: false,
				shared: false,
				shadow: true,
				backgroundColor: '#FAFAFA',
				xDateFormat: '%Y-%b-%d',
				formatter: function () {
					return this.point.pointToolTip
				}
			},
			legend: {
				borderRadius: 5,
				borderWidth: 0,
				backgroundColor: '#FFFFFF',
				borderColor: '#BDBDBD',
			},
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					},
					legend: {
						borderWidth: 0
					}
				}
			}
		})
	}

	public barClickEvent(event: any): boolean {
		this.showWIDetail = false;
		this.eventType = ''
		this.selectedPoint = event.point.pointData
		this.deliveredDate = new Date(this.selectedPoint.actualDeliveryDate)
		this.targetDeliveryDate = new Date(this.selectedPoint.targetDeliveryDate)
		this.workPackageId = event.point.pointData.workPackageId;
		this.WorkItemSkylineService.getWorkitemsList(this.workPackageId).subscribe(
			data => {
				this.rowData = data.payload
				this.sort()

			},
			error => {
				this.alertService.error(error.statusDescription)
			}
		)
		this.showWIDetail = true
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		return true
	}


	showModal(): void {
		this.isModalShown = true;
	}

	public refresh() {
		// this.loadingService.showLoading(false, false, '', 0)
		this.selectedVendor = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.resetGraph()
		this.getSkylineDataAndLoadGraph('ALL', 'All')
		this.iconClicked = false
		// this.showWICommentsDetail = false;
	}

	private resetGraph() {
		this.removeSerie()
		this.showWIDetail = false
		this.selectedPoint = null
	}

	private getSkylineDataAndLoadGraph(vendorId: string, criticalityId: string) {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.WorkItemSkylineService.getWorkItemSkyline(criticalityId, vendorId).subscribe(
			data => {
				console.log("skyline")
				console.log(data)
				if (data.payload != null) {
					if (data.payload.dateList != null) {
						this.xAxisList = this.generateXaxis(data.payload.dateList)
						this.yAxisMax = data.payload.highestCount
						this.plotLineValue = data.payload.plotLineValue
						// console.log('[getSkylineData] (xAxisList) ' + JSON.stringify(this.xAxisList))
						this.initChart()
						this.generateDataSeries(data.payload)
					} else {
						//no data to show
						this.initChart()
						this.isLoadingData = false
						this.isNoDataToShow = true
					}
				} else {
					//no data to show
					this.initChart()
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				this.alertService.clear()
				console.log('[getSkylineData] (error) ' + JSON.stringify(error))
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			}
		)
	}

	private removeSerie() {
		let num = this.chart.ref.series.length
		// console.log('[removeSerie] num ' + JSON.stringify(num));
		for (let i = 0; i < num; i++) {
			this.chart.removeSerie(0);
		}
	}

	public commentCellRenderer(param: any) {
		let statusDropDown: Array<any> = [
			{ 'id': "Open" },
			{ 'id': "Closed" }
		]
		switch (param.value) {
			case 'Open':
				return
			case 'Closed':
				return
			default:
				return
		}
	}

	public getStatus(status: any) {
		var name: string;
		switch (status) {
			case 'Open':
				return 'Open';
			case 'Closed':
				return 'Closed';
		}
	}

	public confirmedEvent() {
		//this is unwanted because client need to show data in same component
		// so no need to navigate to another screen
		// this.currentMenuItem = localStorage.getItem(LocalStorageConst.SELECTED_MENU_ITEM)
		// // console.log('[confirmedEvent] (currentMenuItem) ' + this.currentMenuItem);
		// if (this.currentMenuItem == 'dashboard') {
		// 	this.sharedService.csmiRequest('com_MC', 'com')
		// } else {
		// }
		console.log('[confirmedEvent] (selectedPoint) ' + JSON.stringify(this.selectedPoint));
		this.showWIDetail = true
		// this.hideModal()
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 500);
		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	onRowGroupOpened(params) {
		if (params.node.rowIndex === params.api.paginationProxy.bottomRowIndex) {
			this.gridApi.paginationGoToNextPage();
		}
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	// onDetailColumnResized(event) {
	//     if (event.finished) {
	//         this.detailGridApi.resetRowHeights();
	//     }
	// }

	onCellValueChanged(params) {
		let colId = params.column.getId()
		let workItemId = params.data.workItemId
		let newValue = params.newValue
		// console.log(params)
		console.log('[onCellValueChanged] (colId/newValue) ' + colId + '/' + newValue);
		switch (colId) {
			case 'status':
				this.changeStatusId(workItemId, newValue)
				break;
			case 'liftCritical':
				this.changeLiftCritical(workItemId, newValue)
				break;
		}
	}

	public changeStatusId(workItemId, value) {
		this.WorkItemSkylineService.updateWorkItemStatus(workItemId, value).subscribe(
			data => {
				console.log('[changeStatusId]' + JSON.stringify(data))
				this.sort()
				this.gridApi.setRowData(this.rowData)
			},
			error => {
				console.log('[changeStatusId] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)
	}

	public changeLiftCritical(workItemId, value) {
		this.WorkItemSkylineService.updateWorkItemLiftCritical(workItemId, value).subscribe(
			data => {
				console.log('[changeLiftCritical]' + JSON.stringify(data))
			},
			error => {
				console.log('[changeLiftCritical] (error) ' + JSON.stringify(error.statusDescription));
				this.alertService.error(error.statusDescription)
			}
		)
	}

	public export(event) {
		var params = {
			fileName: "work_item",
			sheetName: "work_item"
		};
		if (this.gridApi) {
			this.gridApi.exportDataAsExcel(params)
		}
	}

	// onDetailGridReady(params) {
	//     this.detailGridApi = params.api;
	//     this.detailGridColumnApi = params.columnApi;
	//     params.api.sizeColumnsToFit();
	//     this.detailGridApi.showLoadingOverlay()
	//     setTimeout(function () {
	//         params.api.sizeColumnsToFit();
	//         params.api.resetRowHeights();
	//     }, 500);
	//     this.WorkItemSkylineService.getWorkitemsList(this.workPackageId).subscribe(
	//         data => {
	//             this.rowData = data.payload
	//         },
	//         error => {
	//             this.alertService.error(error.statusDescription)
	//         }
	//     )
	//     //resize the table for window resize
	//     window.addEventListener('resize', function () {
	//         setTimeout(function () {
	//             params.api.sizeColumnsToFit();
	//             params.api.resetRowHeights();
	//         });
	//     })
	//     this.detailGridApi.hideOverlay()
	// }

	public editOnClick(event) {

	}

	private edit(data: any) {
		this.commentId = data.data.commWorkItemCommentId
		this.commentState = "Edit";
		this.checkedEditClicked = true;
		this.userComment = data.data.comment
		this.showAddComment = true;
		this.workItemId = data.data.workItemId
		this.selectedItem = data.data
		this.eventType = WIDGET_EVENT.SHOW_EDIT
		this.backEvent = WIDGET_EVENT.SHOW_COMMENT
	}

	private add(data: any) {
		this.commentState = "Add";
		this.checkedAddClicked = true;
		this.showAddComment = true;
		this.userComment = ''
		this.eventType = WIDGET_EVENT.SHOW_ADD
		this.backEvent = WIDGET_EVENT.SHOW_TABLE
		this.workItemId = data.data.workItemId

	}
	private delete(data: any) {
		if (data.data != null) {
			data.api.showLoadingOverlay()
			this.WorkItemSkylineService.deleteWorkItemComments(data.data.commWorkItemCommentId).subscribe(
				data => {
					this.WorkItemSkylineService.getWorkitemsList(this.workPackageId).subscribe(
						data => {
							this.rowData = data.payload
							this.sort()
						},
						error => {
							this.alertService.error(error.statusDescription)
						}
					)
				},
				error => {
					this.alertService.error(error.statusDescription)
				}
			);
			this.eventType = WIDGET_EVENT.SHOW_TABLE
		}
	}

	public resetWidgetDetail() {
		this.showWIDetail = false
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	isInputValid() {
		if (this.userComment != null) {
			return true
		} else {
			return false
		}
	}

	saveComment(data): void {
		let userId = UserVariable.userId;
		let date = new Date();
		let currentDate = formatDate(date, 'yyy-MM-dd hh:mm:ss', 'en-US');
		// this.eventType = WIDGET_EVENT.SHOW_EDIT
		this.comment = currentDate + "#splitcomment#" + userId + "#splitcomment#" + this.userComment;
		if (this.checkedAddClicked) {
			this.WorkItemSkylineService.addWorkItemComments(this.workItemId, this.comment).subscribe(
				data => {
					this.WorkItemSkylineService.getWorkitemsList(this.workPackageId).subscribe(
						data => {
							console.log(data.payload)
							this.rowData = data.payload
							this.sort()
						},
						error => {
							this.alertService.error(error.statusDescription)
						}
					)
					if (data.payload != null) {

					} else {
						//no data to show
					}
				},
				error => {
					console.log('[getSkylineData] (error) ' + JSON.stringify(error))
					this.alertService.error(error.statusDescription)
					this.isLoadingData = false
				}
			)
			this.showWIDetail = true

		} else {
			this.comment = undefined + "#splititemcomments#" + currentDate + "#splitcomment#" + userId + "#splitcomment#" + this.userComment;
			this.WorkItemSkylineService.updateWorkItemComments(this.commentId, this.userComment, currentDate).subscribe(
				data => {
					this.WorkItemSkylineService.getWorkitemsList(this.workPackageId).subscribe(
						data => {
							this.rowData = data.payload
							this.sort()
						},
						error => {
							this.alertService.error(error.statusDescription)
						}
					)
				},
				error => {
					console.log('[getSkylineData] (error) ' + JSON.stringify(error))
					this.alertService.error(error.statusDescription)
					this.isLoadingData = false
				}
			)
			this.showWIDetail = true
		}
		this.userComment = "";
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.showAddComment = false;
		this.checkedAddClicked = false;
	}

	onKey(value: string) {
		this.userComment = value;
	}

	backToTable(event) {
		this.checkedAddClicked = false;
		this.showAddComment = false;
		this.checkedEditClicked = false;
		// this.eventType = this.backEvent
		this.eventType = WIDGET_EVENT.SHOW_TABLE
	}

	showHideLabel() {
		this.isShowLabel = !this.isShowLabel
		this.initChart()
		this.filterAndLoadSeriesByColor(this.allWorkItemList)
	}

	downloadFile(documentId: any, type: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.WorkItemSkylineService.downloadFile(UserVariable.projectId, documentId)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				if (type == "IRN") {
					FileSaver.saveAs(file, this.translatedData.IRN + ".pdf");
				} else if (type == "OTCC") {
					FileSaver.saveAs(file, this.translatedData.OTCC_DESC + ".pdf");
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.translatedData.ERR_DOWNLOAD_FILE)
			});
	}

	sort() {
		this.rowData.sort(function (a, b) {
			if (a.status > b.status) return -1;
			if (a.status < b.status) return 1;

			if (a.description.toLowerCase() > b.description.toLowerCase()) return 1;
			if (a.description.toLowerCase() < b.description.toLowerCase()) return -1;
		});
	}
}


