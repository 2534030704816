import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class WorkflowApprovalService {

	constructor(private http: HttpClient) {
	}

	getWorkFlowTaskEntryByActivity(taskId: any, status: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getWorkFlowTaskEntryByActivity() == > ServiceURL= " + ServiceUrls.GET_WORKFLOW_TASK_ENTRY)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("taskId", taskId).set("status", status) }

		return this.http.get(ServiceUrls.GET_WORKFLOW_TASK_ENTRY, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getTaskById(taskId: string): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getTaskById() == > ServiceURL= " + ServiceUrls.GET_WORKFLOW_TASK)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("taskId", taskId) }

		return this.http.get(ServiceUrls.GET_WORKFLOW_TASK, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getWorkFlowProcessEntryByProcessInstanceId(processInstanceId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getWorkFlowProcessEntryByProcessInstanceId() == > ServiceURL= " + ServiceUrls.GET_WORKFLOW_PROCESS_ENTRY)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("processInstanceId", processInstanceId) }

		return this.http.get(ServiceUrls.GET_WORKFLOW_PROCESS_ENTRY, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getClientId(projectId: string): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getClientId() == > ServiceURL= " + ServiceUrls.GET_CLIENTID_BY_PROJECT_ID)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("projectId", projectId) }

		return this.http.get(ServiceUrls.GET_CLIENTID_BY_PROJECT_ID, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getWorkFlowUserId(): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getWorkFlowUserId() == > ServiceURL= " + ServiceUrls.GET_WORKFLOW_USER_ID)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams() }

		return this.http.get(ServiceUrls.GET_WORKFLOW_USER_ID, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	getWorkFlowInstanceTaskRoleMap(workflowId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getWorkFlowInstanceTaskRoleMap() == > ServiceURL= " + ServiceUrls.GET_WORKFLOW_INSTANCE_TASKS)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("workflowId", workflowId) }

		return this.http.get(ServiceUrls.GET_WORKFLOW_INSTANCE_TASKS, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getMCC(mccId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getMCC() == > ServiceURL= " + ServiceUrls.GET_MCC)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("mccId", mccId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_MCC, options)
			.pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getSystem(systemId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getSystem() == > ServiceURL= " + ServiceUrls.GET_SYSTEM)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("systemId", systemId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_SYSTEM, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getOpenPunchlistItems(systemId: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getOpenPunchlistItems() == > ServiceURL= " + ServiceUrls.GET_OPEN_PUNCHLIST_ITEMS)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("systemId", systemId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_OPEN_PUNCHLIST_ITEMS, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getApprovalData(mccId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getApprovalData() == > ServiceURL= " + ServiceUrls.GET_MCC_APPROVAL_DATA)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("mccId", mccId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_MCC_APPROVAL_DATA, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	approveMCCWorkflow(mccId: string, taskId: string, signature: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.approveMCCWorkflow() == > ServiceURL= " + ServiceUrls.GET_MCC_WORKFLOW_APPROVAL)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		var stnOject = {
			"id": mccId, "taskId": taskId, "signature": signature
		}
		console.log(JSON.stringify(stnOject))
		return this.http.post(ServiceUrls.GET_MCC_WORKFLOW_APPROVAL, JSON.stringify(stnOject)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getStn(stnId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getStn() == > ServiceURL= " + ServiceUrls.GET_STN)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("id", stnId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_STN, options)
			.pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getSTNApprovalData(stnId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getSTNApprovalData() == > ServiceURL= " + ServiceUrls.GET_STN_APPROVAL_DATA)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("id", stnId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_STN_APPROVAL_DATA, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	approveSTNWorkflow(stnId: string, taskId: string, signature: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.approveSTNWorkflow() == > ServiceURL= " + ServiceUrls.GET_STN_WORKFLOW_APPROVE)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		var stnOject = {
			"id": stnId, "taskId": taskId, "signature": signature
		}
		console.log(JSON.stringify(stnOject))
		return this.http.post(ServiceUrls.GET_STN_WORKFLOW_APPROVE, JSON.stringify(stnOject)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getPCC(pccId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getPCC() == > ServiceURL= " + ServiceUrls.GET_PCC)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("id", pccId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_PCC, options)
			.pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getPCCApprovalData(pccId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getPCCApprovalData() == > ServiceURL= " + ServiceUrls.GET_PCC_APPROVAL_DATA)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("id", pccId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_PCC_APPROVAL_DATA, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	approvePCCWorkflow(pccId: string, taskId: string, signature: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.approvePCCWorkflow() == > ServiceURL= " + ServiceUrls.GET_PCC_WORKFLOW_APPROVE)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		var pccObject = {
			"id": pccId, "taskId": taskId, "signature": signature
		}
		console.log(JSON.stringify(pccObject))
		return this.http.post(ServiceUrls.GET_PCC_WORKFLOW_APPROVE, JSON.stringify(pccObject)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getCCC(cccId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getCCC() == > ServiceURL= " + ServiceUrls.GET_CCC)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("id", cccId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_CCC, options)
			.pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getCCCApprovalData(cccId: any, projectId: any, userId: any, clientId: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.getApprovalData() == > ServiceURL= " + ServiceUrls.GET_CCC_APPROVAL_DATA)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("id", cccId).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.GET_CCC_APPROVAL_DATA, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	approveCCCWorkflow(cccId: string, taskId: string, signature: any): Observable<WsResponse> {
		console.log("WorkflowApprovalService.approveCCCWorkflow() == > ServiceURL= " + ServiceUrls.CCC_WORKFLOW_APPROVE)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		var stnOject = {
			"id": cccId,"taskId": taskId, "signature": signature
		}
		console.log(JSON.stringify(stnOject))
		return this.http.post(ServiceUrls.CCC_WORKFLOW_APPROVE, JSON.stringify(stnOject)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

}