import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    template: `<div *ngFor="let otn of this.otnList"><a [routerLink]="['/dashboard']" *ngIf="show(otn)" (click)="!isDisabled(otn) && download(otn)">{{otn.vendorName}} </a></div>`
})
export class OTNRenderer implements ICellRendererAngularComp {    
    params: any; 
    otnList: any[] = [];
 
    agInit(params: any): void {
        this.params = params;
        if(this.params.data){
            if(this.params.data.otn && this.params.data.otn.length > 0){
                this.otnList = this.params.data.otn;
            }else{
            }
        }
    }
 
    /**
     * Show OTN Vendor if exists
     * @param otn 
     */
    public show(otn: any): boolean {
        if(otn){
            if (otn.vendorName != null || otn.vendorName != undefined) {
                return true
            } else {
                return false
            }
        }else{
            return false
        }
    }
 
    /**
     * Disable download if OTN document is not exist
     * @param otn 
     */
    public isDisabled(otn: any): boolean{
        if(otn){
            if(otn.isUploaded != null){
                return false
            } else{
                return true
            }
        }else{
            return true
        }
    }

    refresh(params: any): boolean {
        return false;
    }    
 
    /**
     * Download OTN document
     * @param otn 
     */
    public download(otn: any){
        this.params.context.componentParent.downloadOTNApprovedFile(otn.id, otn.vendorName);
    }
}
