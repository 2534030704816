import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    template: `<div *ngFor="let stnorptn of this.stnptnList"><a [routerLink]="['/dashboard']" *ngIf="show(stnorptn)" (click)="download(stnorptn)">{{stnorptn.vendorName}} </a></div>`
})
export class STNPTNFileDownloadRenderer implements ICellRendererAngularComp {    
    params: any;    
    stnptnList: any[] = [];

    agInit(params: any): void {
        this.params = params;
        if(this.params.data){
            if(this.params.data.stnptn){
                this.stnptnList = this.params.data.stnptn;
            } 
        } 
    }

    public show(stnorptn:any) {
        if (stnorptn.stnStatus == 'Approved' || stnorptn.ptnStatus == 'Approved') {
            return true
        } else {
            return false
        }
    }
    
    refresh(params: any): boolean {
        return false;
    }    

    public download(selectedVendor:any){
        this.params.context.componentParent.downloadSTNPTNFile(selectedVendor, this.params);
    }
}