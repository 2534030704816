import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';

@Injectable()
export class SystemService {
	private callback: WsCallback;

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	constructor(private http: HttpClient) {
	}
	//get all system dataset with thir subsystems and assetns belong to them.
	getAllSystemDetailList(projectId: string){
		console.log("SystemService.getAllSystemDetailList() == > ServiceURL= " + ServiceUrls.getMCSystemBreakdownlist(projectId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token)}

		return this.http.get(ServiceUrls.getMCSystemBreakdownlist(projectId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}
	
	//get all system dataset with thir subsystems and assetns belong to them.
	getAllSystemDetailListSolr(projectId: string){
		console.log("SystemService.getAllSystemDetailList() == > ServiceURL= " + ServiceUrls.getMCSystemBreakdownlist(projectId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token)}

		return this.http.get(ServiceUrls.getMCSystemBreakdownlistSolr(projectId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	isAllPunchlistItemsClosedByCategory(category: string, systemId: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
		console.log("SystemService.isAllPunchlistItemsClosedByCategory() == > ServiceURL= " + ServiceUrls.checkPunchlistsCompleted(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("Category", category).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.checkPunchlistsCompleted(systemId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	isAllChecksheetsComplete(checkSheetType: string, systemId: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
		console.log("SystemService.isAllChecksheetsComplete() == > ServiceURL= " + ServiceUrls.checkChecksheetsCompleted(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("checkSheetType", checkSheetType).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.checkChecksheetsCompleted(systemId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getSystemBySystemId(systemId: string) {
		console.log("SystemService.getSystemBySystemId() == > ServiceURL= " + ServiceUrls.getSystemById(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		this.http.get(ServiceUrls.getSystemById(systemId), options).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				this.callback.onSuccess(res, WsType.GET_SYSTEM_BY_ID);
			},
			error => {
				console.log(error)
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.code,
					modified.status.name,
					modified.status.description,
					modified.payload);
				this.callback.onFail(res, WsType.GET_SYSTEM_BY_ID);
			});
	}

	getOpenPunchlistItems(systemId: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
		console.log("SystemService.getOpenPunchlistItems() == > ServiceURL= " + ServiceUrls.getOpenPunchlistItems(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.getOpenPunchlistItems(systemId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getAllSystemList(): Observable<WsResponse> {
		console.log("SystemService.getOpenPunchlistItems() == > ServiceURL= " + ServiceUrls.GET_SYSTEM_LIST)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.GET_SYSTEM_LIST, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);

	}

	getCheckSheets(systemId: string): Observable<WsResponse> {
		console.log("SystemService.getCheckSheets() == > ServiceURL= " + ServiceUrls.getCheckSheets(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("checkSheetType", "A") }

		return this.http.get(ServiceUrls.getCheckSheets(systemId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getPreComCheckSheets(systemId: string): Observable<WsResponse> {
		console.log("SystemService.getCheckSheets() == > ServiceURL= " + ServiceUrls.getCheckSheets(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("checkSheetType", "B").set("type", "Static") }

		return this.http.get(ServiceUrls.getCheckSheets(systemId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getParentSystem(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		return this.http.get(ServiceUrls.GET_PARENT_SYSTEM_LIST, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getSystemsCompletionStatusReportData(projectId: string){
		console.log("SystemService.getSystemCompletionStatusData() == > ServiceURL= " + ServiceUrls.getSystemsCompletionStatusUrl(projectId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token)}

		return this.http.get(ServiceUrls.getSystemsCompletionStatusUrl(projectId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				console.log(modified);
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				console.log(modified);
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	updateSystemCompletionStatus(data: any){
		console.log("SystemsService.updateSystemCompletionStatus() == > ServiceURL= " + ServiceUrls.UPDATE_SYSTEM_COMPLETION_STATUS);
		this.http.post(ServiceUrls.UPDATE_SYSTEM_COMPLETION_STATUS, JSON.stringify(data)).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.UPDATE_SYSTEM_COMPLETION_STATUS);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.UPDATE_SYSTEM_COMPLETION_STATUS);
			});
	}

	//Check System has IRN - check the assets of system and get IRNs of assets
	isSystemHasIRN(systemId: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
		console.log("SystemService.isSystemHasIRN() == > ServiceURL= " + ServiceUrls.isSystemHasIRN(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.isSystemHasIRN(systemId), options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res);
					})
				);
		}

		//Returns assets list which don't have atleast one OTN  by system Id
		getAssetsListWithoutApprovedOTNBySystemId(systemId: string, projectId: string, userId: string, clientId: string): Observable<WsResponse> {
		console.log("SystemService.getAssetsListWithoutApprovedOTNBySystemId() == > ServiceURL= " + ServiceUrls.getAssetsListWithoutApprovedOTNBySystemId(systemId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("projectId", projectId).set("userId", userId).set("clientId", clientId) }

		return this.http.get(ServiceUrls.getAssetsListWithoutApprovedOTNBySystemId(systemId), options).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
				}),
				catchError(error => {
					var modified = JSON.parse(JSON.stringify(error.error));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					return throwError(res);
					})
				);
		}
}