import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class OpenWorkItemsPiechartService {
    public token: string;
    public workPackId: string;
    public vendorId: string;
    private callback: WsCallback;

    constructor(private http: HttpClient) {

    }


    getAllWorkPackages(isCritical: string, vendorId: string, workPackId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("vendorId",vendorId).set("critical", isCritical).set("workPackId", workPackId)  }
        return this.http.get(ServiceUrls.GET_ALL_WORK_PACKAGES, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    } 

    
    getAllVendors(): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token) }
        return this.http.get(ServiceUrls.GET_VENDOR_LIST, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    } 

    getWorkItemsDetails(isCritical: string, vendorId: string,workPackId: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set("vendorId",vendorId).set("isCritical", isCritical).set("workPackId", workPackId) }
        let url = this.getOWIListByUserURL();
        console.log("getWorkItemsDetails-vendorId");
        console.log(vendorId);
        return this.http.get(ServiceUrls.GET_WORKITEMDETAILS, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.code,
                    modified.status.name,
                    modified.status.description,
                    modified.payload);
                return throwError(res)
            })

        );
 
    }
    public getOWIListByUserURL() {
        let url = ServiceUrls.GET_WORKITEMDETAILS;
        return url;
    }
}
