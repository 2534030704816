import { Component, OnInit } from '@angular/core';
import { ProceduresInTheStoreWidgetService } from './procedures-in-the-store-widget.service';
import { ProceduresInTheStoreWidgetModel } from './procedures-in-the-store-widget.model';
import { LoggedUser } from '../../user/logged-user.model';
import { LocalStorage } from '../../util/localstorage.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import * as FileSaver from 'file-saver';
import { UserVariable } from '../../util/common/user-variable';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../util/shared.service';
import { GridOptions } from 'ag-grid';

@Component({
	selector: 'app-procedures-in-the-store-widget',
	templateUrl: './procedures-in-the-store-widget.component.html',
	styleUrls: ['./procedures-in-the-store-widget.component.css']
})
export class ProceduresInTheStoreWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	private gridApi;
	private gridColumnApi;
	public rowData = [];
	public procedureStoreLoadList: Array<ProceduresInTheStoreWidgetModel> = new Array<ProceduresInTheStoreWidgetModel>();
	//private loggedInUser: LoggedUser;
	public columnDefs;
	cd_translated_data: any = null;
	private projectId: any
	self: this;
	public gridOptions: GridOptions;
	public detailGridOptions: GridOptions;
	public getRowHeight: (params: any) => number;
	public detailCellRendererParams;
	folderPathData: any;

	constructor(
		private proceduresInTheStoreWidgetService: ProceduresInTheStoreWidgetService,
		private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService) {

		this.gridOptions = {

			getRowHeight: function (params) {
				if (params.node && params.node.detail) {
					var offset = 80;
					var allDetailRowHeight = params.data.innerData.length * 28;
					return allDetailRowHeight + offset;
				} else {
					return 60;
				}
			}
		}

		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cd_translated_data = res['PROCEDURES_IN_THE_STORE']
			this.columnDefs = [
				{
					headerName: this.cd_translated_data.PROCEDURE,
					field: "procedure", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: this.cd_translated_data.FACILITY,
					field: "facility",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160,
				},
				{
					headerName: this.cd_translated_data.VERSION,
					field: "version",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 90
				},
				{
					headerName: this.cd_translated_data.NOTES,
					field: "notes", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200
				},
				{
					headerName: this.cd_translated_data.AVAILABILITY,
					field: "availability",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100
				},
				{
					headerName: this.cd_translated_data.PUBLISHED_DATE,
					field: "publishedDate",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 130,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							var cellDateTime = new Date(dateAsString)
							var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						}
					}

				},
				{
					headerName: this.cd_translated_data.LOCATION,
					field: "location", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300
				}
			];
		});

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cd_translated_data = res['PROCEDURES_IN_THE_STORE']
			this.detailCellRendererParams = {
				detailGridOptions: {
					context: {
						componentParent: this
					},
					columnDefs: [
						{
							headerName: this.cd_translated_data.PROCEDURE,
							field: "procedure", filter: "agTextColumnFilter",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150
						},
						{
							headerName: this.cd_translated_data.FACILITY,
							field: "facility",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160
						},
						{
							headerName: this.cd_translated_data.VERSION,
							field: "version",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 70
						},
						{
							headerName: this.cd_translated_data.NOTES,
							field: "notes", filter: "agTextColumnFilter",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200
						},
						{
							headerName: this.cd_translated_data.AVAILABILITY,
							field: "availability",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 50
						},
						{
							headerName: this.cd_translated_data.PUBLISHED_DATE,
							field: "publishedDate",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100,
							filter: "agDateColumnFilter",
							comparator: this.dateComparator,
							filterParams: {
								comparator: function (filterLocalDateAtMidnight, cellValue) {
									var dateAsString = cellValue;
									var cellDateTime = new Date(dateAsString)
									var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
									if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
										return 0;
									}
									if (cellDate < filterLocalDateAtMidnight) {
										return -1;
									}
									if (cellDate > filterLocalDateAtMidnight) {
										return 1;
									}
								}
							}

						},
						{
							headerName: this.cd_translated_data.LOCATION,
							field: "location", filter: "agTextColumnFilter",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300
						}
					],
					defaultColDef: { editable: true },
					onGridReady: function (params) {
						params.api.setGridAutoHeight(true);
					}
				},
				getDetailRowData: function (params) {
					let detailRowData = [];
					if (params.data.innerData.length != 0) {
						for (let i = 0; i < params.data.innerData.length; i++) {
							detailRowData.push({
								facility: params.data.innerData[i]["facilityName"],
								procedure: params.data.innerData[i]["name"],
								version: params.data.innerData[i]["storeVersion"],
								notes: params.data.innerData[i]["content"],
								availability: params.data.innerData[i]["storeAvailability"],
								publishedDate: params.data.innerData[i]["lastUpdateTime"],
								location: params.data.innerData[i]["folderPath"],
							});
							params.successCallback(detailRowData);
						}
					} else {
						params.successCallback(params.data.innerData);
					}
				}
			};
			this.getRowHeight = function (params) {
				if (params.node && params.node.detail) {
					var offset = 80;
					var allDetailRowHeight = params.data.innerData.length * 28;
					return allDetailRowHeight + offset;
				} else {
					return 60;
				}
			};
		})

	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	dateComparator(date1, date2) {
		var date1Number = new Date(date1).getTime();
		var date2Number = new Date(date2).getTime();

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {
		this.columnDefs;
		//this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
		this.projectId = localStorage.getItem(LocalStorage.PROJECT_ID)
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()
		this.proceduresInTheStoreWidgetService.getStoreLoad(this.projectId)
			.subscribe(
			data => {
				this.loadData(data.payload)
				setTimeout(() => {
					params.api.sizeColumnsToFit();
				}, 500);
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	loadData(payload: any) {

		this.rowData = [];
		this.gridApi.hideOverlay()
		this.gridApi.showLoadingOverlay()

		// console.log(payload)
		if (payload != null) {
			for (let key in payload) {
				for (let j = 0; j < payload[key].length; j++) {
					if (payload[key][j]["storeAvailability"] == "1") {
						this.rowData.push({
							facility: payload[key][j]["facilityName"],
							procedure: payload[key][j]["name"],
							version: payload[key][j]["storeVersion"],
							notes: payload[key][j]["content"],
							availability: payload[key][j]["storeAvailability"],
							publishedDate: payload[key][j]["lastUpdateTime"],
							location: payload[key][j]["folderPath"],
							innerData: payload[key]
						});
					}
				}
			}
		}
	}

	onCellClicked(event: any) { }

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.proceduresInTheStoreWidgetService.getStoreLoad(this.projectId)
			.subscribe(
			data => {
				this.loadData(data.payload)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

	}

	onRowGroupOpened(params) {
		if (params.node.rowIndex === params.api.paginationProxy.bottomRowIndex) {
			this.gridApi.paginationGoToNextPage();
		}
	}

}
