<div class="my-login-page" data-gr-c-s-loaded="true">
  <section class="h-100">
     <div class="container h-100">
        <div class="justify-content-md-center h-100">
           <div class="card-wrapper">
              <div class="mt-3 mb-3">
                 <img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
              </div>
              <div class="card fat">
                 <div class="card-body">
                    <h4 class="card-title">Change Password</h4>
                    <form method="POST">
                       <div class="form-group">
                          <ul class="list-group list-group-flush">
                             <li class="list-group-item pr-1 pl-1">Your password must contain at least <strong>six characters</strong></li>
                             <li class="list-group-item pr-1 pl-1">Your password must contain at least <strong>one number (0-9)</strong></li>
                             <li class="list-group-item pr-1 pl-1">Your password must contain at least <strong>one lower case letter (a-z)</strong></li>
                             <li class="list-group-item pr-1 pl-1">Your password <strong>cannot contain</strong> two consecutive letters</li>
                             <li class="list-group-item pr-1 pl-1">Your password must contain at lease <strong>one upper case letter (A-Z)</strong></li>
                          </ul>
                       </div>
                       <div class="form-group">
                          <label for="oldPassword">Old Password</label>
                          <input id="oldPassword" type="password" class="form-control" name="oldPassword" value="" required="" autofocus="" [(ngModel)] = "passwordChangeModel.oldPassword">
                       </div>
                       <div class="form-group">
                          <label for="newPassword">New Password</label>
                          <input id="newPassword" type="password" class="form-control" name="newPassword" value="" required="" autofocus="" [(ngModel)] = "passwordChangeModel.newPassword">
                       </div>
                       <div class="form-group">
                          <label for="confirmPassword">Confirm Password</label>
                          <input id="confirmPassword" type="password" class="form-control" name="confirmPassword" value="" required="" autofocus="" [(ngModel)] = "passwordChangeModel.confirmPassword">
                       </div>
                       <div class="form-group">
                          <alert></alert>
                       </div>
                       <div class="form-group mt-4 mb-3">
                          <button type="submit" class="btn btn-primary btn-block mb-3"s (click)="onSubmitButtonClick()">
                             Submit
                          </button >
                          <button class="btn btn-secondary btn-block mt-3" (click)="cancelChangePassword()">
                             Cancel
                          </button>
                       </div>
                    </form>
                 </div>
              </div>
              <div class="footer">
                 2018 © AXIS. All Rights Reserved
              </div>
           </div>
        </div>
     </div>
  </section>
</div>