import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button *ngIf="show()" (click)="delete()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="fas fa-undo mx-2" aria-hidden="true"></i>' +
	'</button>'

})
export class DeleteButtonRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public show() {
		if (this.params.data) {
			if (this.params.data.mcstatus == 'Issued' || this.params.data.mcstatus == 'Approved' ||
				this.params.data.mcstatus == "Error") {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}

	public delete() {
		this.params.context.componentParent.iconClicked = true
		this.params.context.componentParent.delete(this.params)
	}

	refresh(): boolean {
		return false;
	}
}