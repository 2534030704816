import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
	selector: 'app-sample2chart',
	templateUrl: './sample2chart.component.html',
	styleUrls: ['./sample2chart.component.css']
})
export class Sample2chartComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	chart = new Chart({
		chart: {
			type: 'line'
		},
		title: {
			text: 'Linechart'
		},
		credits: {
			enabled: false
		},
		series: [
			{
				name: 'Line 1',
				data: [1, 2, 3]
			}
		]
	});

	// add point to chart serie
	add() {
		this.chart.addPoint(Math.floor(Math.random() * 10));
	}

}
