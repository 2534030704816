import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WsResponse } from '../../util/ws-response.model';
import { FacilityAcceptanceService } from './facility-acceptance.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserVariable } from '../../util/common/user-variable';
import { SharedService } from '../../util/shared.service';
import * as FileSaver from 'file-saver';
import { DownloadAllIconRenderer } from './header/download-all.component'
import { GridOptions } from "ag-grid";
import { LoadingService } from '../../util/loading/loading.service';

@Component({
	selector: 'app-facility-acceptance',
	templateUrl: './facility-acceptance.component.html',
	styleUrls: ['./facility-acceptance.component.css']
})
export class FacilityAcceptanceComponent implements OnInit {

	public isMinimized: boolean = false
	rowData: any[] = [];
	private gridApi;
	private gridColumnApi;
	columnDefs;
	fa_translated_data: any = null;
	public frameworkComponents;
	public gridOptions: GridOptions;

	constructor(private facilityAcceptanceService: FacilityAcceptanceService, private sharedService: SharedService, private translate: TranslateService, private alertService: AlertService, private loadingService: LoadingService) {
		let browserLang = translate.getBrowserLang();
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.fa_translated_data = res['FA_REPORT']
			this.columnDefs = [
				{ headerName: this.fa_translated_data.VENDOR, field: 'vendorName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, },
				{ headerName: this.fa_translated_data.SYSTEM, field: 'systemName', filter: "agTextColumnFilter", autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, },
				{ headerName: this.fa_translated_data.CERTIFICATE_STATUS, field: 'faCertificateStatus', autoHeight: true, cellRenderer: this.statusCellRenderer, cellClass: "cell-wrap-text", minWidth: 140, },
				{ headerName: this.fa_translated_data.NOTICE_STATUS, field: 'faNoticeStatus', autoHeight: true, cellRenderer: this.statusCellRenderer, cellClass: "cell-wrap-text", minWidth: 130, },
				{
					headerName: this.fa_translated_data.SENT_CERTIFICATE,
					headerComponentFramework: DownloadAllIconRenderer,
					headerComponentParams: { type: 'CertificateSent', content: this.fa_translated_data.DOWNLOAD_ALL },
					field: 'faSentCertificate', autoHeight: true, minWidth: 200, cellClass: "cell-wrap-text", suppressFilter: true,
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if ((params.data.faCertificateStatus == "Approved" || params.data.faCertificateStatus == "Issued") && params.data.faCertificateFileName) {
								eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
									'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
									'</button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									console.log(params.data.faCertificateDocId)
									loadingService.showLoading(false, false, '', 0)
									facilityAcceptanceService.downloadFile(UserVariable.projectId, params.data.faCertificateDocId, params.data.faCertificateFileName, "FACertificate", "ViewCommOutput")
										.subscribe(response => {
											loadingService.hideLoading()
											let file = new Blob([response], { type: 'application/pdf' });
											FileSaver.saveAs(file, params.data.faCertificateFileName + ".pdf");
										},
										error => {
											loadingService.hideLoading()
											alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
										});
								});
							} else {
								eDiv.innerHTML = '';
							}
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				},
				{
					headerName: this.fa_translated_data.APPROVED_CERTIFICATE, field: 'faApprovedCertificate', autoHeight: true, minWidth: 230, cellClass: "cell-wrap-text", suppressFilter: true,
					headerComponentFramework: DownloadAllIconRenderer,
					headerComponentParams: { type: 'CertificateApproved', content: this.fa_translated_data.DOWNLOAD_ALL },
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if (params.data.faCertificateStatus == "Approved" && params.data.faCertificateAppFileName) {
								eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
									'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
									'</button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									loadingService.showLoading(false, false, '', 0)
									facilityAcceptanceService.downloadFile(UserVariable.projectId, params.data.faCertificateAppDocId, params.data.faCertificateAppFileName, "FACertificate", "ViewApprovedOutput")
										.subscribe(response => {
											loadingService.hideLoading()
											let file = new Blob([response], { type: 'application/pdf' });
											FileSaver.saveAs(file, params.data.faCertificateAppFileName + ".pdf");
										},
										error => {
											loadingService.hideLoading()
											alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
										});
								});
							} else {
								eDiv.innerHTML = '';
							}
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				},
				{
					headerName: this.fa_translated_data.SENT_NOTICE, field: 'faSentNotice', autoHeight: true, minWidth: 190, cellClass: "cell-wrap-text", suppressFilter: true,
					headerComponentFramework: DownloadAllIconRenderer,
					headerComponentParams: { type: 'NoticeSent', content: this.fa_translated_data.DOWNLOAD_ALL },
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if ((params.data.faNoticeStatus == "Approved" || params.data.faNoticeStatus == "Issued") && params.data.faNoticeFileName) {
								eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
									'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
									'</button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									loadingService.showLoading(false, false, '', 0)
									facilityAcceptanceService.downloadFile(UserVariable.projectId, params.data.faNoticeDocId, params.data.faNoticeFileName, "FANotice", "ViewCommOutput")
										.subscribe(response => {
											loadingService.hideLoading()
											let file = new Blob([response], { type: 'application/pdf' });
											FileSaver.saveAs(file, params.data.faNoticeFileName + ".pdf");
										},
										error => {
											loadingService.hideLoading()
											alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
										});
								});
							} else {
								eDiv.innerHTML = '';
							}
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				},
				{
					headerName: this.fa_translated_data.APPROVED_NOTICE, field: 'faApprovedNotice', autoHeight: true, minWidth: 210, cellClass: "cell-wrap-text", suppressFilter: true,
					headerComponentFramework: DownloadAllIconRenderer,
					headerComponentParams: { type: 'NoticeApproved', content: this.fa_translated_data.DOWNLOAD_ALL },
					cellRenderer: function (params) {
						var eDiv = document.createElement('div');
						if (params.data) {
							if (params.data.faNoticeStatus == "Approved" && params.data.faNoticeAppFileName) {
								eDiv.innerHTML = '<button class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
									'<i class="fas fa-file-pdf mx-2" aria-hidden="true"></i>' +
									'</button>';
								var eButton = eDiv.querySelectorAll('.btn-sm')[0];

								eButton.addEventListener('click', function () {
									loadingService.showLoading(false, false, '', 0)
									facilityAcceptanceService.downloadFile(UserVariable.projectId, params.data.faNoticeAppDocId, params.data.faNoticeAppFileName, "FANotice", "ViewApprovedOutput")
										.subscribe(response => {
											loadingService.hideLoading()
											let file = new Blob([response], { type: 'application/pdf' });
											FileSaver.saveAs(file, params.data.faNoticeAppFileName + ".pdf");
										},
										error => {
											loadingService.hideLoading()
											alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
										});
								});
							} else {
								eDiv.innerHTML = '';
							}
						} else {
							eDiv.innerHTML = '';
						}
						return eDiv;
					}
				}
			];
			// this.frameworkComponents = { customHeaderComponent: CertificateSentFileIconRenderer };
		});
	}

	ngOnInit() {
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case "Approved":
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case "Error":
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				if (param.value) {
					return '<span class="badge badge-warning px-2 py-1">' + param.value + '</span>'
				} else {
					return '<span class="badge badge-warning px-2 py-1"></span>'
				}
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		params.api.sizeColumnsToFit();
		this.getAllFAs();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);
	}

	getAllFAs() {
		this.gridApi.showLoadingOverlay()
		this.facilityAcceptanceService.getAllFAs()
			.subscribe(data => {
				// console.log('[getAllFAs] (data.payload) ' + JSON.stringify(data.payload));
				let sortDataByVendor: any = this.sortByVendor(data.payload)
				console.log("FApayload");
				console.log(sortDataByVendor);
				this.rowData = sortDataByVendor;
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	public refresh() {
		this.getAllFAs()
	}

	/**
	 * sort data by vendor alphabetically
	 * @param array 
	 */
	private sortByVendor(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.vendorName.toLowerCase() > b.vendorName.toLowerCase()) {
					return 1;
				} else if (a.vendorName.toLowerCase() < b.vendorName.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	downloadAll(type: any) {
		let mode = null;
		let fileName = null;
		if (type == 'CertificateSent') {
			mode = "DownloadAllInitialFACFiles"
			fileName = "initialFACertificates.zip"
		} else if (type == 'CertificateApproved') {
			mode = "DownloadAllApprovedFACFiles"
			fileName = "approvedFACertificates.zip"
		} else if (type == 'NoticeSent') {
			mode = "DownloadAllInitialFANFiles"
			fileName = "initialFANotices.zip"
		} else if (type == 'NoticeApproved') {
			mode = "DownloadAllApprovedFANFiles"
			fileName = "approvedFANotices.zip"
		}
		this.loadingService.showLoading(false, false, '', 0)
		this.facilityAcceptanceService.DownloadAllZip(UserVariable.projectId, mode)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/zip' });
				FileSaver.saveAs(file, fileName);
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.error(this.fa_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

}
