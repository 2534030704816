<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						<!-- translated widget name -->
						{{ "COMM_COMPLETION.WIDGET_NAME" | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
					<!-- other buttons like config button -->
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<!-- body content -->
		<div *ngIf="eventType == widgetEvent.SHOW_TABLE">
			<div class="row my-1">
				<div class="col-sm-9 ">
				</div>
				<div class="col-sm-3">
					<button class="btn btn-primary btn-sm float-right" (click)="exportToExcel()">{{
						'COMM_COMPLETION.EXPORT_TO_EXCEL' | translate }}</button>
				</div>
			</div>
			<div class="my-2">
				<ag-grid-angular #agGrid style="width: 100%; height: 450px;" id="cmmsDataBuildDetails" [rowData]="rowData" class="ag-theme-balham"
				 [columnDefs]="columnDefs" [frameworkComponents]="components" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" [enableColResize]="true"
				 [floatingFilter]="true" [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true"
				 (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)" (cellClicked)="onCellClick($event)"
				 [gridOptions]="gridOptions" [suppressMenuHide]="true">
				</ag-grid-angular>
			</div>
		</div>
		<div *ngIf="eventType == widgetEvent.SHOW_WIZARD">
			<div class="row">
				<button type="button" class="btn btn-primary btn-sm" (click)="backToTable()">
					<i class="fa fa-arrow-left mx-2" aria-hidden="true"></i>{{ 'COMM_COMPLETION.BACK_TO_TABLE' | translate }}
				</button>
			</div>
			<div class="row">
				<div class="col-sm-12 my-2">
					<aw-wizard class="custom-css">
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.ccstatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.ccstatus)}}</span>
										{{selectedItem.systemName}}
									</h6>
									<hr />
									<div>
										<p>{{ 'COMM_COMPLETION.WORKFLOW_TITLE' | translate }}
										</p>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="ch1">
												<label class="form-check-label" for="exampleCheck1">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.CH1' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck2" [(ngModel)]="ch2">
												<label class="form-check-label" for="exampleCheck2">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.CH2' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck3" [(ngModel)]="ch3" disabled>
												<label class="form-check-label" for="exampleCheck3">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.CH3' | translate }}</p>
												</label>
											</li>
										</ul>
										<div class="list-group-item border-0 mx-2">
											<table class="table table-bordered table-sm col-sm-12" *ngIf="procedureList.length>0">
												<thead class="thead-light">
													<tr class="custom-table-row">
														<th class="custom-col-1 text-center"><input *ngIf="procedureList.length != sysCompleteProcCount" type="checkbox"
															 (change)="selectAll($event)">
															<!--   -->
														</th>
														<th class="custom-col-5">{{ 'COMM_COMPLETION.PROCEDURE' | translate }}</th>
														<th class="custom-col-3">{{ 'COMM_COMPLETION.USER' | translate }}</th>
														<th class="custom-col-3">{{ 'COMM_COMPLETION.DATE' | translate }}</th>
													</tr>
												</thead>

												<tr *ngFor="let item of procedureList;let i = index;" class="custom-table-row">
													<td class="custom-col-3 text-center"><input type="checkbox" (change)="markItem($event, i)" [(ngModel)]="procedureChecked[i]"
														 [disabled]="item.executed && item.type=='SystemProcedure'"></td>
													<td class="custom-col-3">{{ item.procedureName }}</td>
													<td class="custom-col-3">{{ item.completedUser }}</td>
													<td class="custom-col-3">{{ item.newCompletedDate }}</td>
												</tr>
											</table>
										</div>
										<p *ngIf="!procedureList.length>0" class="m-0 text-muted">{{ 'COMM_COMPLETION.NO_PROCEDURES' | translate }}</p>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck4" [(ngModel)]="ch4">
												<label class="form-check-label" for="exampleCheck4">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.CH4' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck5" [(ngModel)]="ch5">
												<label class="form-check-label" for="exampleCheck5">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.CH5' | translate }}</p>
												</label>
											</li>
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck6" [(ngModel)]="ch6">
												<label class="form-check-label" for="exampleCheck6">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.CH6' | translate }}</p>
												</label>
											</li>
										</ul>
										<p>{{ 'COMM_COMPLETION.SUMMARY' | translate }}</p>
										<textarea id="comments" name="comments" class="form-control mb-2" [(ngModel)]="comments"></textarea>
										<ul class="list-group border-0">
											<li class="list-group-item border-0 mx-2">
												<input type="checkbox" class="form-check-input" id="exampleCheck10" [(ngModel)]="ch7">
												<label class="form-check-label" for="exampleCheck10">
													<p class="m-0 text-muted">{{ 'COMM_COMPLETION.REM_ITEMS' | translate }}</p>
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-primary btn-sm" awNextStep>{{ 'COMM_COMPLETION.NEXT' | translate }}</button>
							</div>
						</aw-wizard-step>
						<aw-wizard-step stepTitle="">
							<div class="card">
								<div class="card-body">
									<h6 class="my-2">
										<span *ngIf="selectedItem.ccstatus" class="badge badge-warning mr-2">{{getStatus(selectedItem.ccstatus)}}</span>
										{{selectedItem.systemName}}
									</h6>
									<hr />
									<form>
										<div class="row" *ngFor="let task of workflowTaskList; let i = index">
											<div class="col-sm-10 form-group">
												<label class="my-1 mx-0"><b>{{task.taskName}}</b></label>
												<div class="form-row">
													<div class="col">
														<app-custom-typeahead *ngIf="i==0" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1,task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														 <p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee1 && (isSubmitted && i==0) ">
															{{ 'COMM_COMPLETION.EMPTY_USER' | translate }}
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail1 && (isSubmitted && i==0) ">
															{{'COMM_COMPLETION.INVALID_EMAIL' | translate }}
														</p>

														<app-custom-typeahead *ngIf="i==1" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee2 && (isSubmitted && i==1) ">
															{{ 'COMM_COMPLETION.EMPTY_USER' | translate }}
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail2 && (isSubmitted && i==1) ">
															{{'COMM_COMPLETION.INVALID_EMAIL' | translate }}
														</p>

														<app-custom-typeahead *ngIf="i==2" [initial_value]="selectedAssignee[i]" (text_changed)="textChanged($event, i+1, task.taskName)"
														 [typeahead_list]="userList" [option_field]="'name'" [place_holder]="task.taskName"></app-custom-typeahead>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssignee3 && (isSubmitted && i==2) ">
															{{ 'COMM_COMPLETION.EMPTY_USER' | translate }}
														</p>
														<p class="alert alert-danger mb-0 mt-1 py-1 px-2 float-right" role="alert" *ngIf="!validAssigneeEmail3 && (isSubmitted && i==2) ">
															{{'COMM_COMPLETION.INVALID_EMAIL' | translate }}
														</p>

													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="my-2 float-right">
								<button type="button" class="btn btn-secondary btn-sm mx-2" (click)="previous()" awPreviousStep>{{ 'COMM_COMPLETION.PREVIOUS' |
									translate }}</button>
								<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">{{
									'COMM_COMPLETION.START' | translate }}</button>
							</div>
						</aw-wizard-step>
						<!-- <aw-wizard-step stepTitle="">
								<div class="card">
									<div class="card-body">
										This is some text within a card body. step 3
									</div>
								</div>
								<div class="my-2 float-right">
									<button type="button" class="btn btn-secondary btn-sm mx-2" awPreviousStep>Previous</button>
									<button type="button" class="btn btn-primary btn-sm mx-1" (click)="finishFunction()">Finish</button>
								</div>
							</aw-wizard-step> -->
					</aw-wizard>
				</div>
			</div>
		</div>

		<div *ngIf="eventType == widgetEvent.SHOW_EDIT">
			<div class="row">
				<div class="col-sm-12 my-2">
					<h6 class="my-2">
						<span *ngIf="selectedItem.ccstatus" [ngClass]="getStyle(selectedItem.ccstatus)" class="badge mr-2">{{getStatus(selectedItem.ccstatus)}}</span>{{selectedItem.systemName}}
					</h6>
					<hr />
					<div class="container">
						<div class="form-group">
							<div class="col-sm-6">
								<label>{{ 'COMM_COMPLETION.TARGET_COMPLETION_DATE' | translate }}</label>
								<input [(ngModel)]="targetCompletionDate" type="text" placeholder="Select target completion date" class="form-control is-invalid"
								 [bsConfig]="bsConfig" bsDatepicker>
							</div>
						</div>
						<div class="form-group form-check">
							<div class="col-sm-6">
								<label class="container_checkbox">
									<input [(ngModel)]="required" type="checkbox" checked="checked" id="required">
									<span class="checkmark rounded"></span>
								</label>
								<!-- <input [(ngModel)]="required" type="checkbox" class="form-check-input" id="required">-->
								<label class="ml-2">{{ 'COMM_COMPLETION.REQUIRED' | translate }}</label>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-12">
								<label>{{ 'COMM_COMPLETION.TOPICMAP' | translate }}</label>
								<ng-select [active]="selectedTopicmap" [allowClear]="true" (removed)="removeTopicmap($event)" (selected)="selectTopicmap($event)"
								[disabled]="disableformData" [items]="topicmapList" placeholder="{{ 'COMM_COMPLETION.SELECT_TOPICMAP' | translate }}" name="topicmap" id="topicmap"
								 class="is-invalid two-line">
								</ng-select>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-12">
								<label>{{ 'COMM_COMPLETION.SYSTEM_PROCEDURES' | translate }}</label>
								<ng-select [multiple]="true" [active]="selectedSysProcedure" [allowClear]="true" (removed)="removeSysProcedure($event)"
								 (selected)="selectSysProcedure($event)" [disabled]="disableformData" [items]="sysProcedureList" placeholder="{{ 'COMM_COMPLETION.SELECT_PROCEDURE' | translate }}"
								 name="sysProcedure" id="sysProcedure">
								</ng-select>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-12">
								<label>{{ 'COMM_COMPLETION.MANUAL_PROCEDURES' | translate }} <span class="small mb-0">[ {{
										'COMM_COMPLETION.PLACEHOLDER' | translate }} ]</span></label>
								<tag-input [(ngModel)]='manualProcedures'  [disable]="disableformData" placeholder="{{ 'COMM_COMPLETION.PLACEHOLDER' | translate }}"
								 secondaryPlaceholder="{{ 'COMM_COMPLETION.PLACEHOLDER' | translate }}">
								</tag-input>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-6">
								<p class="alert alert-danger mb-0 mt-1 py-1 px-4" role="alert" *ngIf="showRequiredErr && !isInputValid()">
									{{ 'COMM_COMPLETION.REQUIRED_FIELDS' | translate }}
								</p>
							</div>
						</div>
						<div class="form-group my-2">
							<button type="button" class="btn btn-primary btn-sm mx-1 float-right" (click)="saveCC()">{{
								'COMM_COMPLETION.SAVE' | translate }}</button>
							<button type="button" class="btn btn-secondary btn-sm mx-2 float-right" (click)="backToTable()">{{
								'COMM_COMPLETION.CANCEL' | translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- popup templates -->
<!-- modal templates -->
<ng-template #deleteCCCModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'COMM_COMPLETION.REVOKE_CCC_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'COMM_COMPLETION.REVOKE_CCC_MSG' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="revokeCCC()">{{ 'COMM_COMPLETION.YES' | translate }}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancel()">{{ 'COMM_COMPLETION.NO' | translate }}</button>
		</div>
	</div>
</ng-template>
<ng-template #editCCCModal>
	<div class="modal-header">
		<h6 class="modal-title pull-left">{{ 'COMM_COMPLETION.DELETE_TITLE' | translate }}</h6>
		<button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body ">
		<p> {{ 'COMM_COMPLETION.DELETE_MSG' | translate }} </p>
		<div class="float-right">
			<button type="button" class="btn btn-default mx-1" (click)="deleteCCC()">{{ 'COMM_COMPLETION.YES' | translate }}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="cancelDeleteCCC()">{{ 'COMM_COMPLETION.CANCEL' |
				translate }}</button>
		</div>
	</div>
</ng-template>