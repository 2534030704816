import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsCallback } from '../../util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { WsResponse } from '../../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class ProceduresInstancesWidgetService {
    public token: string;

    constructor(private http: HttpClient) { }

    public getInstances(): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("token", token).set('status', "3").set('documentName', "") }
        let url = this.getInstancesByURL();
        return this.http.get(url, options).pipe(
            map(response => {
                var modified = JSON.parse(JSON.stringify(response));
                return new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
            }),
            catchError(error => {
                var modified = JSON.parse(JSON.stringify(error.error));
                var res = new WsResponse(
                    modified.status.description,
                    modified.status.code,
                    modified.status.name,
                    modified.payload);
                return throwError(res)
            })
        );
    }

    public getInstancesByURL() {
        let url = ServiceUrls.GET_PROCEDURE_INSTANCES;
        return url;
    }

    downloadFile(projectId: string, outputId: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
                responseType:
                    "blob", params: new HttpParams().set("projectId", projectId).set("documentId", outputId).set("mode", "ViewOutput")
            });
    }
}
