import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { TranslateService } from '@ngx-translate/core';
import { WsResponse } from './util/ws-response.model';
import { WsType } from './util/ws-type';
import { LocalStorage } from './util/localstorage.service';
import { Router } from '@angular/router';
import { SessionValidationService } from './user/login/session-validation-request.service';
import { LocalStorageConst } from './util/constants';
import { AppRoutingModule } from './app-routing.module';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'app';

	// sessionValidationRequest: any;

	constructor(private translate: TranslateService, private router: Router, public sessionValidationRequest: SessionValidationService) {
		setTheme('bs4')
		this.changeTheme('Yellow')
		translate.addLangs(["es", "en"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

		//set selected menu item to localstorage
		localStorage.setItem(LocalStorageConst.SELECTED_MENU_ITEM, 'dashboard')
		var pathName: string = window.location.pathname.replace('/', '');
		//Check session requirement for the requested resource.
		if (!AppComponent.canProceedWithoutSession()) {
			if (pathName != '' && pathName != 'splash' && pathName != 'login' && pathName != 'auth0') {
				//To handle browser reloading
				if (!pathName.includes('fwd')) {
					this.router.navigate(['splash', { 'fwd': pathName }]);
				}  else {
					//fwd is already contain.
					var modified = pathName.substring(pathName.indexOf('fwd=') + 4)
					this.router.navigate(['splash', { 'fwd': modified }]);
				}
			} else if (pathName == 'auth0') {
				console.log('AppComponent A41= ' + pathName);
				//login page will be loaded through the splash screen
				//this.router.navigateByUrl(pathName);
			} else {
				//login page will be loaded through the splash screen
				this.router.navigateByUrl('splash');
			}
		} else {			
			// this.router.navigateByUrl(pathName);
			const search = window.location.search;
			//this.router.navigate([pathName + search], { queryParamsHandling: 'preserve' });
			this.router.navigateByUrl(pathName + search);

		}
	}

	/**
	 * Check whether the requested path can be forwarded without session,
	 * some components need a valid login session some components don't need a valid session.
	 */
	public static canProceedWithoutSession(): boolean {
		var pathName = window.location.pathname.replace('/', '');
		var canProceed: boolean = false;
		var data = AppRoutingModule.getRoutingPaths() as Array<any>;
		data.forEach(item => {
			if (item.path == pathName && item.data.allowed == true) {
				canProceed = true;
				return;
			}
		});

		return canProceed;
	}

	public changeTheme(theme: string): void {
		window['switchStyle'](theme)
		localStorage.setItem('myapp-theme', theme)
	}
	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.SESSION_VALIDATION) {
			//no need to implement this since the user action is valid.
		}
	}
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.SESSION_VALIDATION) {
			this.router.navigateByUrl('login');
		}
	}
}
