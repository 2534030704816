export class Constants {

    public static DOCUMENTTYPE_PANDID_GUID = "fcd6642b-57a7-45f1-8c78-74142ba9b93b";
    //public static GOOGLE_AUTH_CLIENT_ID = "692921478250-hodf54f3amjr8160u43l8l7kr9qtnlas.apps.googleusercontent.com";
    //public static GOOGLE_AUTH_SECRET_KEY = "AsTzI2gPsmAksfhHFqeGrwJ6";
    //public static GOOGLE_AUTH_SCOPE = 'https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/plus.profile.emails.read';
    public static APP_TYPE = 'DashBoard';

    public static DEPENDENCY_MESSAGE_TYPE = {
        EDIT: 'Edit',
        ENABLE: 'Enable',
        DELETE: 'Delete',
        DISABLE: 'Disable',
        VIEW: 'View'
    }

    public static DEPENDENCY_ITEM_TYPE = {
        CLIENT: 'Client',
        PROJECT: 'Project',
        USER: 'User',

        COMPONENT: 'Component',
        DOCUMENT: 'Document',
        FACILITY: 'Facility',
        SYSTEM: 'System',
        STATEMENT: 'Statement',
        TAG: 'Tag',

        CONTENTITEM: 'ContentItem',
        COURSE: 'Course',
        FOLDER: 'Folder',
        IMAGE: 'Image',
        PROCEDURE: 'Procedure',
        TOPIC: 'Topic',
        TOPICMAP: 'TopicMap',
        DOCUMENTTYPE: 'DocumentType',
        DOCUMENTTYPEATTRIBUTE: 'DocumentTypeAttribute',
        THREEDMODEL: 'ThreeDModel',
        PUNCHLIST: 'PunchList',
        IRN: 'IRN'
    }

}

export enum WIDGET_EVENT {
    SHOW_TABLE = 'show_table',
    SHOW_WIZARD = 'show_wizard',
    SHOW_EDIT = 'show_edit',
    SHOW_COMMENT = 'show_comment',
    SHOW_ADD = 'show_add',
    SHOW_TABLE_SCREEN = 'show_table_screen',
}

/**
 * local storage constants
 */
export class LocalStorageConst {
    public static LOGGED_USER: string = "logged_in_user";
	public static DEVICE_ID: string = "device_id";
	public static USER_ID: string = "user_id";
	public static IS_CLIENT: string = "is_client";
	public static PROJECT_NAME: string = "project_name";
	public static PROJECT_TYPE: string = "project_type";
	public static PROJECT_ID: string = "project_id";
	public static DEFAULT_PROJECT_ID: string = "default_project_id"
	public static CLIENT_ID: string = "client_id";
	public static REQUESTED_PATH: string = "requested_path";
    public static SELECTED_MENU_ITEM: string = 'selected_menu_item'

    public static OLD_PROJECT_ID: string = "old_project_id"
	public static OLD_PROJECT_NAME: string = "old_project_name"
	public static OLD_CLIENT_ID: string = "old_client_id"
}