<div *ngIf="showLoading">
	<div class="loading-panel loading-panel-bg"></div>
	<div class="loading-panel center">
		<div class="loader center" >
		</div>
		<label *ngIf="showPercentage" class="loading-percentage m-0">
			<h5 class="m-0">{{percentageValue}}%</h5>
		</label>
	</div>
	<div class="loading-panel center">
		<label *ngIf="showLoadingMessage" class="loading-message m-0">
			<var class="m-0">{{loadingMessage}}</var>
		</label>
	</div>
</div>