import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';

import { LoginComponent } from './user/login/login.component';
import { ChangepasswordComponent } from './user/login/changepassword/changepassword.component';
import { ForgotpasswordComponent } from './user/login/forgotpassword/forgotpassword.component'
import { KeycodeverificationComponent } from './user/login/keycodeverification/keycodeverification.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Sample2chartComponent } from './widgets/sample2chart/sample2chart.component';
import { InspectionReleaseNoticeComponent } from './widgets/inspection-release-notice/inspection-release-notice.component';
import { PasswordChangeStatusComponent } from './user/login/changepassword/password-change-status/password-change-status.component';
import { LandingComponent } from './landing/landing.component';

import { WorkflowApprovalComponent } from './workflow-approval/workflow-approval.component'
import { SplashComponent } from './splash/splash.component';
import { PageNotFoundComponent } from './util/page-not-found/page-not-found.component';
import { Auth0Component } from './user/login/auth0/auth0.component';


const routes: Routes = [
	{ path: '', redirectTo: '/splash', pathMatch: 'full', data: { allowed: false } },
	{ path: 'login', component: LoginComponent, data: { allowed: false } },
	{ path: 'auth0', component: Auth0Component, data: { allowed: false } },
	{ path: 'changepassword', component: ChangepasswordComponent, data: { allowed: false } },
	{ path: 'forgotpassword', component: ForgotpasswordComponent, data: { allowed: true },  },
	{ path: 'kvc', component: KeycodeverificationComponent, data: { allowed: false } },
	{ path: 'dashboard', component: DashboardComponent, data: { allowed: false } },
	{ path: 'chart2', component: Sample2chartComponent, data: { allowed: false } },
	{ path: 'IRN', component: InspectionReleaseNoticeComponent, data: { allowed: false } },
	{ path: 'forgotpassword/resetpage/loadPasswordResetPage.json', component: ForgotpasswordComponent, data: { allowed: true } },
	{ path: 'changePasswordStatus', component: PasswordChangeStatusComponent, data: { allowed: false } },
	{ path: 'landing', component: LandingComponent, data: { allowed: false } },
	{ path: 'workflowapproval', component: WorkflowApprovalComponent, data: { allowed: true } },
	{ path: 'splash', component: SplashComponent, data: {allowed: false} },
	{ path: '**', component: PageNotFoundComponent, data: {allowed: true} }
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
	/**
	 * Get the routing list
	 */
	public static getRoutingPaths(): Route[] {
		return routes;
	}
}
