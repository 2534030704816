import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { CommonUtil } from '../util/common/common-utils';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';

@Injectable({
  providedIn: 'root'
})
export class OnshoreTurnoverNoticeService {
	otnComp:WsCallback;
	private callback: WsCallback;

	private rowData: any = [];
	loadTableData = new EventEmitter<any>();
	updateUIafterOTNGenerate  = new EventEmitter<any>();
	updateUIafterApproval = new EventEmitter<any>();
  
  constructor(
	  private http: HttpClient,
      private commonUtil: CommonUtil
  ) { }

	public setCallback(callBack: WsCallback) {
		this.callback = callBack;
	}

	public setRowData(rowData: any) {
		this.rowData = rowData;
	  }
	  public setOTNcomp(otnComp:WsCallback){
		this.otnComp = otnComp
	  }
	
	  public loadTableDataRequest(){
		this.loadTableData.emit()
	  }
	  public loadTableDataResponse(){
		return this.loadTableData;
	  }
	  public  updateUIafterOTNGenerateRequest(){
		  this.updateUIafterOTNGenerate.emit()
	  }
	  public  updateUIafterOTNGenerateResponse(){
		  return this.updateUIafterOTNGenerate;
	  }
	  public updateUIafterApprovalRequest(otnId, uploaded){
		  this.updateUIafterApproval.emit({otnId, uploaded})
	  }
	  public updateUIafterApprovalResponse(){
		  return this.updateUIafterApproval;
	  }


	  getOTNDataByOTNId(otnId, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
	
		this.http.get(ServiceUrls.getOTNByOTNId(token, otnId)).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.code,
			  modified.status.name,
			  modified.status.description,
			  modified.payload);
			  console.log(JSON.stringify(res));
			callBack.onSuccess(res, WsType.GET_OTN_BY_OTN_ID);
		  },
		  error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
			  modified.status.code,
			  modified.status.name,
			  modified.status.description,
			  modified.payload);
			callBack.onSuccess(res, WsType.GET_OTN_BY_OTN_ID);
		  });
	  }

	getAllOnshoreTurnoverNotice(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.getAllOTN(token), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	/**
   * Get OTN List By Asset ID
   *
   * @param assetId
   * @param callBack
   */
	 getOTNListByAssetId(assetId: string, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		this.http.get(ServiceUrls.getOTNByAssetId(token, assetId)).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.code,
			  modified.status.name,
			  modified.status.description,
			  modified.payload);
			callBack.onSuccess(res, WsType.GET_OTN_BY_ASSET_ID);
		  },
		  error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
			  modified.status.code,
			  modified.status.name,
			  modified.status.description,
			  modified.payload);
			callBack.onSuccess(res, WsType.GET_OTN_BY_ASSET_ID);
		  });
	  }


	public addNewOTN(data: string, wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		this.http.post(ServiceUrls.addNewOTN(token), data).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.description,
			  modified.status.code,
			  modified.status.name,
			  modified.payload);
			  
			wsCallBack.onSuccess(res, WsType.ADD_NEW_OTN);
		  },
		  error => {
			if (error.status != '') {
			  var val = (error as HttpErrorResponse).error;
			  var modified = JSON.parse(JSON.stringify(val));
			  var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			  wsCallBack.onFail(res, WsType.ADD_NEW_OTN);
			} else {
			  //browser related issues
			  var res = new WsResponse("Unknown error happened");
			  wsCallBack.onFail(res, WsType.ADD_NEW_OTN);
			}
		  }
		);
	  }

	/**
   * Get all assets used in commissioning
   * @param wsCallBack  callback class
   */
	 getAssetList(wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_ASSET_LIST, options).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.description,
			  modified.status.code,
			  modified.status.name,
			  modified.payload);
			wsCallBack.onSuccess(res, WsType.GET_ASSETS);
		  },
		  error => {
			if (error.status != '') {
			  var val = (error as HttpErrorResponse).error;
			  var modified = JSON.parse(JSON.stringify(val));
			  var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			  wsCallBack.onFail(res, WsType.GET_ASSETS);
			} else {
			  //browser related issues
			  var res = new WsResponse("Unknown error happened");
			  wsCallBack.onFail(res, WsType.GET_ASSETS);
			}
		  }
		);
	  }
	  
	  /**
	   * Get all assets used in commissioning with otn target completion date
	   * @param wsCallBack  callback class
	   */
	   getAssetListWithOTNTargetCompletionDate(wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_ASSET_LIST_WITH_OTN_TARGET_COMPLETION_DATE, options).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.description,
			  modified.status.code,
			  modified.status.name,
			  modified.payload);
			wsCallBack.onSuccess(res, WsType.GET_ASSETS);
		  },
		  error => {
			if (error.status != '') {
			  var val = (error as HttpErrorResponse).error;
			  var modified = JSON.parse(JSON.stringify(val));
			  var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			  wsCallBack.onFail(res, WsType.GET_ASSETS);
			} else {
			  //browser related issues
			  var res = new WsResponse("Unknown error happened");
			  wsCallBack.onFail(res, WsType.GET_ASSETS);
			}
		  }
		);
	  }
	
	  /**
	   * Get all active vendors
	   * @param wsCallBack  callback class
	   */
	  public getAllVendors(wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		this.http.get(ServiceUrls.getAllVendors(token)).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.description,
			  modified.status.code,
			  modified.status.name,
			  modified.payload);
			wsCallBack.onSuccess(res, WsType.GET_ALL_VENDORS);
		  },
		  error => {
			if (error.status != '') {
			  var val = (error as HttpErrorResponse).error;
			  var modified = JSON.parse(JSON.stringify(val));
			  var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			  wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
			} else {
			  //browser related issues
			  var res = new WsResponse("Unknown error happened");
			  wsCallBack.onFail(res, WsType.GET_ALL_VENDORS);
			}
		  }
		);
	  }

	updateOnshoreturnoverNotice(onshoreTurnoverNotice: any): Observable<WsResponse> {
		console.log("OnshoreTurnoverNoticeService.updateOnshoreturnoverNotice() == > ServiceURL= " + ServiceUrls.UPDATE_ONSHORE_TURNOVER_NOTICE)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete onshoreTurnoverNotice['isStart']
		delete onshoreTurnoverNotice['newtargetCompletionDate']
		onshoreTurnoverNotice['userToken'] = token
		return this.http.post(ServiceUrls.UPDATE_ONSHORE_TURNOVER_NOTICE, JSON.stringify(onshoreTurnoverNotice)).
			pipe( map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	/*generateOnshoreTurnoverNotice(onshoreTurnoverNotice: any) {
		console.log("OnshoreTurnoverNoticeService.generateOnshoreTurnoverNotice() == > ServiceURL= " + ServiceUrls.GENERATE_OTN)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		onshoreTurnoverNotice['userToken'] = token
		return this.http.post(ServiceUrls.GENERATE_OTN, JSON.stringify(new commonRequest("OTN", JSON.stringify(onshoreTurnoverNotice)))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}*/

	revokeOnshoreTurnoverNotice(otnId: any) {
		console.log("OnshoreTurnoverNoticeService.revokeOnshoreTurnoverNotice() == > ServiceURL= " + ServiceUrls.getRevokeOTN(otnId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let onshoreTurnoverNotice = { 'userToken': token }
		return this.http.post(ServiceUrls.getRevokeOTN(otnId), JSON.stringify(onshoreTurnoverNotice)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}
	
	updateOTN(data: string, otnId: string, wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		console.log("349");
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		let url = ServiceUrls.updateOTN(token, otnId);
		this.http.put(url,data).subscribe(
			data => {
			  var modified = JSON.parse(JSON.stringify(data));
			  console.log("357");
			  var res = new WsResponse(
				  modified.status.description,
				  modified.status.code,
				  modified.status.name,
				  modified.payload);
				  console.log(res);
			  wsCallBack.onSuccess(res, WsType.UPDATE_OTN);
			},
			error => {
			  if (error.status != '') {
				var val = (error as HttpErrorResponse).error;
				var modified = JSON.parse(JSON.stringify(val));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onFail(res, WsType.UPDATE_OTN);
			  } else {
				var res = new WsResponse("Unknown error happened");
				wsCallBack.onFail(res, WsType.UPDATE_OTN);
			  }
			}
		);
	  }
	

	downloadFile(projectId: string, otnId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", otnId)
					.set("mode", "ViewApprovedOutput").set("type", "OnshoreTurnoverNotice").set("fileName", "OTN")
			});
	}

	downloadSentFile(projectId: string, otnId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
			  responseType: "blob", params: new HttpParams().set("projectId", projectId)
				  .set("documentId", otnId).set("mode", "ViewCommOutput").set("type", "OnshoreTurnoverNotice").set("fileName", "OTN")
			});
	  }
	
	  
	downloadApprovalFile(projectId: string, otnId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
			  responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", otnId)
				  .set("mode", "ViewApprovedOutput").set("type", "OnshoreTurnoverNotice").set("fileName", "OTN")
			});
	  }

	

	deleteOnshoreTurnoverNotice(otnId: any) {
		console.log("OnshoreTurnoverNoticeService.deleteOnshoreTurnoverNotice() == > ServiceURL= " + ServiceUrls.getDeleteOTN(otnId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let onshoreTurnoverNotice = { 'userToken': token }
		return this.http.post(ServiceUrls.getDeleteOTN(otnId), JSON.stringify(onshoreTurnoverNotice)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	isAssetsHasIRN(assetIds: string): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("assetList", assetIds) }
		return this.http.get(ServiceUrls.isAssetsHasIRN(token), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
    }

    isAllPunchlistItemsClosedByCategoryAndAsset(category: string, assetIds: string): Observable<WsResponse>{
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("category", category).set("assetList", assetIds) }
		return this.http.get(ServiceUrls.isAllPunchlistItemsClosedByCategoryAndAsset(token), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
    }

    getPunchlistItemsByAssetsCategoryAndItemStatus(category: string, assetIds: string, status: string){
		let token = UserVariable.getUserToken();
		//const options = { params: new HttpParams().set("category", category).set("assetList", assetIds).set("status", status) }
		return this.http.post(ServiceUrls.getPunchlistItemsByAssetsCategoryAndItemStatus(token), JSON.stringify(new Request(category,assetIds,status))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
    }
	
	/**
	 * Generate OTN PDF
	 * @param otn 
	 */
    generateOTN(otn: any) {
		console.log("OnshoreTurnoverNoticeService.generateOTN() == > ServiceURL= " + ServiceUrls.GENERATE_OTN)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		otn['userToken'] = token;
		return this.http.post(ServiceUrls.GENERATE_OTN, JSON.stringify(new commonRequest("OTN", JSON.stringify(otn)))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
		/*this.http.post(ServiceUrls.GENERATE_OTN, JSON.stringify(new commonRequest("OTN", JSON.stringify(otn)))).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onSuccess(res, WsType.GENERATE_OTN);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				this.callback.onFail(res, WsType.GENERATE_OTN);
			});*/
    }

	 /**
   * Get MCC List By OTN ID
   *
   * @param otnId
   * @param callBack
   */
	  getMCCListByOTNId(otnId: string, callBack: WsCallback) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		this.http.get(ServiceUrls.getMCCListByOTNId(token, otnId)).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.code,
			  modified.status.name,
			  modified.status.description,
			  modified.payload);
			callBack.onSuccess(res, WsType.GET_MCC_DATA_BY_OTN_ID);
		  },
		  error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
			  modified.status.code,
			  modified.status.name,
			  modified.status.description,
			  modified.payload);
			callBack.onSuccess(res, WsType.GET_MCC_DATA_BY_OTN_ID);
		  });
	  }

	  deleteOrRevokeOTN(otnId: string, action: string ) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		  return;
		}
		let data = {
		  id : otnId,
		}
		let url  = ServiceUrls.deleteOrRevokeOTN(token, otnId)
		return this.http.post(url, JSON.stringify(data)).
		subscribe(
			data => {
			  var modified = JSON.parse(JSON.stringify(data));
			  var res = new WsResponse(
				  modified.status.description,
				  modified.status.name,
				  modified.status.code,
				  {
					id: otnId,
					action: action
				  });
			  this.otnComp.onSuccess(res, WsType.DELETE_REVOKE_OTN);
			},
			error => {
			  var modified = JSON.parse(JSON.stringify(error));
			  var res = new WsResponse(
				  modified.error.status.description,
				  modified.error.status.name,
				  modified.error.status.code,
				  {
					id: otnId,
					action: action
				  });
			  this.otnComp.onFail(res, WsType.DELETE_REVOKE_OTN);
			}
		);
	  }
	  
	    
	public checkAssetVendorCombination(data) {
		let token = UserVariable.getUserToken();
		if (!this.commonUtil.validateToken(token)) {
		return;
		}
		return this.http.post(ServiceUrls.checkAssetVendorCombination(token), data).
		pipe(
			map(response => {
			var modified = JSON.parse(JSON.stringify(response));
			return new WsResponse(
				modified.status.description,
				modified.status.name,
				modified.status.code,
				modified.payload);
			}),
			catchError(error => {
			var modified = JSON.parse(JSON.stringify(error));
			var res = new WsResponse(
				modified.error.status.description,
				modified.error.status.name,
				modified.error.status.code,
				modified.error.payload);
			return throwError(res)
			})
		);
	}
}

class commonRequest{
	constructor(private certificateType: String, private certificate: String) { }
}

class Request{
	constructor(private category: String, private assetIds: String, private status: String) { }
}
