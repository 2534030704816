<div class="my-login-page">
	<section class="h-100">
		<div class="container h-100">
			<div class="row h-100">
				<div class="card-wrapper">
					<div class="mt-3 mb-3">
						<img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
					</div>
					<div class="card fat">
						<div class="card-body">
							<h4 class="card-title">{{ 'LOGIN.TITLE' | translate }}</h4>
							<form method="POST" ngNativeValidate>
								<div class="form-group">
									<label for="email">{{ 'LOGIN.NAME' | translate }}</label>
									<input  #login_username type="text" class="form-control" name="userName" value="" required="" autofocus=""
									 [(ngModel)]="authRequest.username">
								</div>
								<div class="form-group">
									<label for="password">{{ 'LOGIN.PASSWORD' | translate }}
										<a class="link-primary float-right" (click)="forgotPassword()">
											{{ 'LOGIN.FORGOT_PASSWORD' | translate }}
										</a>
									</label>
									<div style="position:relative">
										<div class="input-with-icon">
											<input #login_password [type]="showPassword ? 'text' : 'password'" class="form-control" name="password"
											 required="" data-eye="" style="padding-right: 60px;" [(ngModel)]="authRequest.password">
											<div class="btn btn-default icon" (click)="showPassword = !showPassword">
												<i class="fa" aria-hidden="true" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
											</div>
										</div>
										<!-- <input id="login_password" type="password" class="form-control" name="password" required="" data-eye="" style="padding-right: 60px;"
										 [(ngModel)]="authRequest.password"> -->
										<input type="hidden" id="passeye-0">
									</div>
								</div>
								<div class="form-group">
									<alert></alert>
								</div>
								<div class="form-group mt-4 mb-3">
									<button id="login_submit" type="submit" class="btn btn-primary btn-block" (click)="loginBtnClickEvent()">
										{{ 'LOGIN.TITLE' | translate }}
									</button>
								</div>
								<div class="form-group mt-4 mb-4">
									<hr />
								</div>
								<!-- <div class="form-group mt-3 text-center"> -->
									<!-- <img class="btn btn-block btn-sm p-0" src="../../../assets/images/btn_google_signin.png" alt="" width="auto" height="auto"/> -->
									<!-- <button id="login_openlogin" type="submit" class="btn btn-light btn-block custom-google-btn shadow-sm" (click)="doGoogleAuthentication()">
										<img src="../../../assets/images/google_logo.svg" width="8%" height="8%" class="mr-4" />{{
										'LOGIN.GOOGLE_SIGN_IN' | translate }}
									</button>
								</div> -->
								<div class="form-group mt-3 text-center">
									<button id="login_openlogin" class="btn btn-light btn-block custom-google-btn shadow-sm" (click)="auth0Authentication()">
										<img src="../../../assets/images/auth0-icon.svg" width="8%" height="8%" class="mr-4" />{{'LOGIN.AUTH0_SIGN_IN' | translate }}
									</button>
								</div>
							</form>
						</div>
					</div>
					<div class="footer">
						{{ 'ALL_RIGHTS' | translate}}
					</div>
				</div>
			</div>
		</div>
	</section>
</div>