import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from "ag-grid";
import { UserVariable } from '../../util/common/user-variable';
import { CommCompletionStartWorkflowRenderer } from './cell-renderer/start-workflow/start-workflow.component';
import { CommCompletionEditButtonRenderer } from './cell-renderer/edit-button/edit-button.component'
import { CommCompletionDeleteButtonRenderer } from './cell-renderer/delete-button/delete-button.component'
import { CommCompletionSentFileIconRenderer } from './cell-renderer/file-icon/sent-file-icon.component';
import { CommCompletionFileIconRenderer } from './cell-renderer/file-icon/approved-file-icon.component';
import { CommCompletionService } from '../comm-completion.service'
import { AlertService } from '../../util/alert/alert.service';
import { WIDGET_EVENT } from '../../util/constants';
import { SystemService } from '../system.service';
import { ProjectService } from '../project.service';
import { TopicmapService } from '../topicmap.service';
import { UserService } from '../user.service';
import { CapabilityService } from '../../util/capability.service';
import { WorkflowService } from '../workflow.service';
import { SharedService } from '../../util/shared.service';
import { Guid } from "guid-typescript";
import { BsModalService, BsDatepickerConfig } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';
import * as FileSaver from 'file-saver';
import { LoadingService } from '../../util/loading/loading.service';
import "ag-grid-enterprise";
import * as XLSX from 'xlsx';
import { DecodeHtmlString } from '../../util/common/decode-html-string.pipe';
import * as moment from 'moment-timezone';
import { LocalStorage } from '../../util/localstorage.service';
import { DatePipe } from '@angular/common';
import { CommCompletionDocuSignRenderer } from './cell-renderer/comm-completion-docu-sign/comm-completion-docu-sign.component';
import { CommonUtil } from '../../util/common/common-utils'
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-comm-completion-widget',
	templateUrl: './comm-completion-widget.component.html',
	styleUrls: ['./comm-completion-widget.component.css']
})
export class CommCompletionWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	@ViewChild("deleteCCCModal") deleteCCCModal: TemplateRef<any>;
	@ViewChild("editCCCModal") editCCCModal: TemplateRef<any>;
	public deleteModalRef: BsModalRef;
	public editModalRef: BsModalRef;
	cc_translated_data: any = null;
	private gridApi;
	private gridColumnApi;
	public rowData = [];
	public columnDefs;
	public gridOptions: GridOptions;
	private selectedItem: any = null
	private selectedCC: any = null
	public targetCompletionDate: Date = null
	public required: boolean = null
	public userList: any[] = []
	public procedureList: any[] = [];
	public selectedAssignee: any[] = null
	public workflowTaskList: any[] = [];
	public ch1: any = false
	public ch2: any = false
	public ch3: any = false
	public ch4: any = false
	public ch5: any = false
	public ch6: any = false
	public ch7: any = false
	public comments: any = null
	public workflowId: any = null
	public taskCount: any = 0
	assignee1: any
	assignee2: any
	assignee3: any
	taskname1:any
	taskname2:any
	taskname3:any
	eventType: any = WIDGET_EVENT.SHOW_TABLE
	widgetEvent = WIDGET_EVENT
	modifyCommCompletion: boolean = false
	public topicmapList: Array<any> = []
	public selectedTopicmap: Array<any> = []
	public sysProcedureList: Array<any> = []
	public selectedSysProcedure: Array<any> = []
	public manualProcedures: Array<any> = null
	public procedureChecked: Array<any> = []
	procXML: any = null;
	sysCompleteProcCount: any = 0
	public checkAll: any = false
	public bsConfig: Partial<BsDatepickerConfig>
	showRequiredErr: boolean = false
	isSubmitted: boolean = false
	validAssignee1: boolean = true
	validAssignee2: boolean = true
	validAssignee3: boolean = true

	validAssigneeEmail1: boolean = true
	validAssigneeEmail2: boolean = true
	validAssigneeEmail3: boolean = true

	disableformData: boolean = false;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
		
	  };

	constructor(private translate: TranslateService,
		private commCompletionService: CommCompletionService,
		private userService: UserService,
		private systemService: SystemService,
		private workflowService: WorkflowService,
		private sharedService: SharedService,
		private alertService: AlertService,
		private capabilityService: CapabilityService,
		private projectService: ProjectService,
		private topicmapService: TopicmapService,
		private loadingService: LoadingService,
		private decodeHTMLString: DecodeHtmlString,
		private bsModalService: BsModalService) {

		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
		this.modifyCommCompletion = capabilityService.isCapabilityAssigned("modifyCommCompletion");
		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cc_translated_data = res['COMM_COMPLETION']

			this.columnDefs = [
				{ headerName: this.cc_translated_data.SYSTEM, field: 'systemName', autoHeight: true, filter: "agTextColumnFilter", cellClass: "cell-wrap-text", minWidth: 280 },
				{ headerName: this.cc_translated_data.STATUS, field: 'ccstatus', autoHeight: true, cellClass: "cell-wrap-text", cellRenderer: this.statusCellRenderer, minWidth: 90 },
				{
					headerName: this.cc_translated_data.TARGET_COMPLETION_DATE, field: 'newtargetCompletionDate', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 175,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = cellValue;
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[1]) - 1,
								Number(dateParts[0])
								);


							} else {
								var cellDate = new Date(dateAsString)
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						},
						//browserDatePicker: true
					}
				}
			]

			if (this.modifyCommCompletion) {
				this.columnDefs.push(
					{
						headerName: this.cc_translated_data.EDIT,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 70,
						suppressFilter: true,
						cellRendererFramework: CommCompletionEditButtonRenderer,
						suppressSorting: true
					},
					{
						headerName: this.cc_translated_data.START_WORKFLOW,
						//field: 'isStart',
						valueGetter: function(params) {
							if(params.data.isStart) {
								return params.data.isStart;
							} else {
								return params.data.docuSignStatus == null ? '' : params.data.docuSignStatus;
							}
						},
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 125,
						//suppressFilter: true,
						filter: "agTextColumnFilter",
						cellRendererFramework: CommCompletionDocuSignRenderer,
						//CommCompletionStartWorkflowRenderer,
						suppressSorting: true
					},
					{
						headerName: this.cc_translated_data.FAILURE_REASON,
						valueGetter: function(params) {
							if(params.data.outputErrorMessage != null){
								return params.data.outputErrorMessage;
							} else if(params.data.docusignFailureReason != null){
								return params.data.docusignFailureReason;
							} else{
								return '';
							}
						},
						suppressFilter: true,
						hide: true,
						minWidth: 200
					},
					{
						headerName: this.cc_translated_data.DELETE,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 80,
						suppressFilter: true,
						cellRendererFramework: CommCompletionDeleteButtonRenderer,
						suppressSorting: true
					},
					{
						headerName: this.cc_translated_data.SENT_PDF,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 70,
						suppressFilter: true,
						hide: true,
						cellRendererFramework: CommCompletionSentFileIconRenderer,
						suppressSorting: true
					},
					{
						headerName: this.cc_translated_data.RETURNED_PDF,
						autoHeight: true,
						cellClass: "cell-wrap-text",
						minWidth: 90,
						suppressFilter: true,
						cellRendererFramework: CommCompletionFileIconRenderer,
						suppressSorting: true
					})
			}
			// this.columnDefs.push({
			// 	headerName: this.cc_translated_data.DOCUSIGN_STATUS,
			// 	field: 'docuSignStatus',
			// 	autoHeight: true,
			// 	cellClass: "cell-wrap-text",
			// 	hide: true,
			// 	cellRendererFramework: CommCompletionDocuSignRenderer,
			// 	minWidth: 90
				
			// })
		});
	}

	dateComparator(date1, date2) {
		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1 == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[1]) - 1,
					Number(dateParts1[0])
					);
			}
			if(date2 == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[1]) - 1,
					Number(dateParts2[0])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1).getTime();
			var date2Number = new Date(date2).getTime();
	}

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()
		params.api.sizeColumnsToFit();
		this.refresh()
		setTimeout(function () {
			params.api.sizeColumnsToFit();
			params.api.resetRowHeights();
		}, 500);

		//resize the table for window resize
		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
				params.api.resetRowHeights();
			});
		})
	}

	ngOnInit() {

	}

	public statusCellRenderer(param: any) {
		switch (param.value) {
			case 'Not Issued':
				return '<span class="badge badge-warning px-2 py-1">Not Issued</span>'
			case 'Approved':
				return '<span class="badge badge-success px-2 py-1">Approved</span>'
			case 'OnQueue':
				return '<span class="badge badge-warning px-2 py-1">OnQueue</span>'
			case 'Issued':
				return '<span class="badge badge-warning px-2 py-1">Issued</span>'
			case 'Error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			default:
				return ''
		}
	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	onCellClick(event) {
		// console.log(event)
	}

	public selectTopicmap(topicmap: any): void {
		this.selectedTopicmap = []
		this.selectedTopicmap.push(topicmap)
		this.selectedCC.systemProcedures.length = 0
		this.getProcedures(topicmap.id)
	}

	public selectSysProcedure(procedure: any): void {
		this.selectedSysProcedure.push(procedure)
	}

	public removeSysProcedure(procedure: any): void {
		this.selectedSysProcedure = this.selectedSysProcedure.filter(proc => proc.id != procedure.id)
	}

	getProcedures(topicmapId: any) {
		this.sysProcedureList = []
		this.selectedSysProcedure = []
		this.topicmapService.getProcedures(topicmapId)
			.subscribe(data => {
				this.sysProcedureList = []
				if (data.payload) {
					this.selectedSysProcedure = []
					data.payload.forEach(element => {
						this.sysProcedureList.push({ "id": element.id, "text": element.name });
						if (this.selectedCC.systemProcedures) {
							if (this.selectedCC.systemProcedures.length > 0) {
								if (this.selectedCC.systemProcedures.filter(item => item == element.id).length > 0) {
									this.selectedSysProcedure.push({ "id": element.id, "text": element.name })
								}
							}
						}
					})
				}
			},
			error => {
				this.alertService.error(error.statusDescription);
			})
	}

	public removeTopicmap(topicmap: any): void {
		this.selectedTopicmap = []
		this.selectedSysProcedure = []
		this.sysProcedureList = []
		this.selectedCC.systemProcedures.length = 0
	}

	private edit(data: any) {
		this.clear()
		this.selectedItem = data.data
		this.selectedCC = data.data
		this.selectedTopicmap = []
		this.selectedSysProcedure = []
		this.manualProcedures = []
		this.eventType = WIDGET_EVENT.SHOW_EDIT
		if (this.selectedItem.ccid) {
			this.commCompletionService.getCommCompletionById(this.selectedItem.ccid)
				.subscribe(data => {
					if (data.payload) {
						this.selectedCC = data.payload
						this.setCCData(this.selectedCC)
					}
				},
				error => {
					this.alertService.error(error.statusDescription);
				})
		} else {
			this.setCCData(this.selectedItem)
		}
	}

	setCCData(selectedCC: any) {
		this.projectService.getTopicmaps(UserVariable.projectId)
			.subscribe(data => {
				this.topicmapList = []
				if (data.payload) {
					data.payload.forEach(element => {
						let topicmapText = element.topicMapName
						if (element.folderPath) {
							topicmapText += " - " + element.folderPath
						}
						this.topicmapList.push({ "id": element.id, "text": topicmapText });
						if (selectedCC.topicmapId == element.id) {
							this.selectedTopicmap = []
							this.selectedTopicmap.push({ "id": element.id, "text": topicmapText })
						}
					})
					if (selectedCC.topicmapId) {
						this.getProcedures(selectedCC.topicmapId)
					}
				}
			},
			error => {
				this.alertService.error(error.statusDescription);
			})

		if (selectedCC.targetCompletionDate) {
			this.targetCompletionDate = new Date(selectedCC.targetCompletionDate)
		} else {
			this.targetCompletionDate = null;
		}

		this.required = selectedCC.ccRequired

		this.manualProcedures = []
		if (selectedCC.manualProcedures.length > 0) {
			selectedCC.manualProcedures.forEach(procedure => {
				let manualProc = this.decodeHTMLString.transform(procedure)
				this.manualProcedures.push({ 'display': manualProc, 'value': manualProc })
			})
		}

		if(this.selectedCC.ccstatus == "Issued" || this.selectedCC.ccstatus == "Approved"){
			this.disableformData = true;

		}
		else{
			this.disableformData = false;
		}
	}

	cancel(): void {
		this.deleteModalRef.hide();
	}

	cancelDeleteCCC() {
		this.editModalRef.hide()
	}

	deleteCCC() {
		this.editModalRef.hide();
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.loadingService.showLoading(false, false, '', 0)
		this.commCompletionService.deleteCommCompletion(this.selectedItem.ccid)
			.subscribe(
			data => {
				this.loadingService.hideLoading()
				this.refresh()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	saveCC(): void {
		if (this.selectedItem.ccid) {
			if (this.isInputValid()) {
				this.showRequiredErr = false
				if (this.selectedCC.ccRequired && !this.required) {
					this.editModalRef = this.bsModalService.show(this.editCCCModal, { class: 'modal-sm' })
				} else {
					this.loadingService.showLoading(false, false, '', 0)
					if (!this.selectedItem.ccstatus) {
						this.selectedItem.ccstatus = "NotIssued"
					}
					let status = this.selectedItem.ccstatus;
					if (this.selectedItem.ccstatus == 'Not Issued'){
						status = 'NotIssued';

					}
					this.selectedItem.ccRequired = this.required
					this.selectedItem.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
					this.selectedItem.projectId = UserVariable.projectId;
					let sysProcedures: Array<String> = []
					this.selectedSysProcedure.forEach(procedure => {
						sysProcedures.push(procedure.id)
					})
					let manualProcedureList: Array<String> = []
					this.manualProcedures.forEach(procedure => {
						manualProcedureList.push(procedure.value)
					})
					console.log(JSON.stringify(sysProcedures))
					var cc = {
						'ccid': this.selectedItem.ccid, 'ccstatus': status, 'systemId': this.selectedItem.systemId, 'ccRequired': this.required, 'completionDate': new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd'),
						'issuedDate': this.selectedItem.issuedDate, 'approvedDate': this.selectedItem.approvedDate, 'projectId': UserVariable.projectId,
						'topicmapId': this.selectedTopicmap[0].id, 'systemProcedures': sysProcedures, 'manualProcedures': manualProcedureList
					}
					console.log(JSON.stringify(cc))
					this.commCompletionService.updateCommCompletion(cc)
						.subscribe(data => {
							this.refresh();
							this.loadingService.hideLoading()
						},
						error => {
							this.loadingService.hideLoading()
							this.alertService.clear()
							this.alertService.error(error.statusDescription);
						})
				}
			} else {
				this.showRequiredErr = true
			}

		} else {
			if (this.isInputValid()) {
				this.loadingService.showLoading(false, false, '', 0)
				this.showRequiredErr = false
				this.selectedItem.ccstatus = "NotIssued"
				this.selectedItem.ccRequired = this.required
				this.selectedItem.completionDate = new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd')
				this.selectedItem.projectId = UserVariable.projectId;
				let sysProcedures: Array<String> = []
				this.selectedSysProcedure.forEach(procedure => {
					sysProcedures.push(procedure.id)
				})
				let manualProcedureList: Array<String> = []
				this.manualProcedures.forEach(procedure => {
					manualProcedureList.push(procedure.value)
				})
				console.log(JSON.stringify(sysProcedures))
				var ccObject = {
					'ccstatus': 'NotIssued', 'systemId': this.selectedItem.systemId, 'ccRequired': this.required, 'completionDate': new DatePipe("en-US").transform(new Date(this.targetCompletionDate), 'yyyy-MM-dd'),
					'projectId': UserVariable.projectId, 'topicmapId': this.selectedTopicmap[0].id, 'systemProcedures': sysProcedures, 'manualProcedures': manualProcedureList
				}
				console.log(JSON.stringify(ccObject))
				this.commCompletionService.addCommCompletion(ccObject)
					.subscribe(data => {
						this.refresh();
						this.loadingService.hideLoading()
					},
					error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription);
					})
			} else {
				this.showRequiredErr = true
			}
		}
	}

	clear() {
		this.eventType = WIDGET_EVENT.SHOW_TABLE
		this.selectedAssignee = []
		this.assignee1 = null
		this.assignee2 = null
		this.assignee3 = null
		this.taskname1 = null
		this.taskname2 = null
		this.taskname3 = null
		this.ch1 = false
		this.ch2 = false
		this.ch3 = false
		this.ch4 = false
		this.ch5 = false
		this.ch6 = false
		this.ch7 = false
		this.comments = null
		this.selectedItem = null
		this.selectedCC = null
		this.workflowTaskList = []
		this.procedureChecked = []
		this.procedureList = []
		this.procXML = null
		this.checkAll = false
		this.showRequiredErr = false
	}

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.clear()
		this.commCompletionService.getAllCommCompletion()
			.subscribe(data => {
				if (data.payload) {
					console.log(data.payload)
					data.payload.forEach(element => {
						if (element.targetCompletionDate) {
							element.newtargetCompletionDate = new Date(element.targetCompletionDate).toLocaleDateString()
						}
						element.systemName = element.systemNo + " " + element.systemName
						element.isStart = false
						if (element.ccstatus == "NotIssued") {
							element.isStart = true
							element.ccstatus = "Not Issued"
						}
						if(element.docuSignStatus != null){
							element.docuSignStatus = element.docuSignStatus.toLowerCase()
						}
					});
					this.rowData = data.payload;
				} else {
					this.rowData = []
				}
			},
			error => {
				this.alertService.error(error.statusDescription);
			})
	}

	public getStyle(status: any) {
		if (status == 'Approved') {
			return 'badge-success'
		} else {
			return 'badge-warning'
		}
	}

	public getStatus(status: any) {
		switch (status) {
			case 'Not Issued':
				return 'Not Issued'
			case 'Approved':
				return 'Approved'
			case 'OnQueue':
				return 'OnQueue'
			case 'Issued':
				return 'Issued'
			case 'Error':
				return 'Error'
			default:
				return ''
		}
	}

	isInputValid() {
		if (!this.selectedCC.ccstatus) {
			if (this.targetCompletionDate && this.required && this.selectedTopicmap.length > 0) {
				return true
			} else {
				return false
			}
		} else {
			if (this.selectedCC.ccRequired && !this.required) {
				if (this.targetCompletionDate && this.selectedTopicmap.length > 0) {
					return true
				} else {
					return false
				}
			} else {
				if (this.targetCompletionDate && this.required && this.selectedTopicmap.length > 0) {
					return true
				} else {
					return false
				}
			}
		}
	}

	public showWizard(data: any) {
		this.selectedItem = data.data
		console.log('[showWizard] (data) ' + JSON.stringify(this.selectedItem));
		this.eventType = WIDGET_EVENT.SHOW_WIZARD
	}

	private backToTable() {
		this.isSubmitted = false;
		this.refresh()
	}

	getConfigData(procList) {
		var configData = '<ConfigData>';
		configData += '<AllTestCompleted>' + this.ch1 + '</AllTestCompleted>';
		configData += '<AllCheckSheetCompleted>' + this.ch2 + '</AllCheckSheetCompleted>';
		configData += '<CommissioningCompleted>' + this.ch3 + '</CommissioningCompleted>';
		configData += '<ItemList>' + this.comments + '</ItemList>';
		configData += '<DocIncluded>' + this.ch4 + '</DocIncluded>';
		configData += '<EnteredToSCDb>' + this.ch5 + '</EnteredToSCDb>';
		configData += '<TCPDocCompleted>' + this.ch6 + '</TCPDocCompleted>';
		configData += '<PlanApprovedForRemItems>' + this.ch7 + '</PlanApprovedForRemItems>';
		configData += '<CCCProcedures>' + procList + '</CCCProcedures>';
		configData += '</ConfigData>';
		return configData;
	}

	startWorkflow(params: any) {
		this.selectedItem = params.data
		this.loadingService.showLoading(false, false, '', 0)

		this.commCompletionService.getProcedureListStatus(this.selectedItem.ccid)
			.subscribe(data => {
				console.log(JSON.stringify(data))
				if (data.payload) {
					this.procedureList = data.payload
					this.procedureChecked = []
					if (this.procedureList.length > 0) {
						let manualProcCount = this.procedureList.filter(item => item.type == 'ManualProcedure').length
						let sysProcCount = this.procedureList.filter(item => item.type == 'SystemProcedure').length
						this.sysCompleteProcCount = this.procedureList.filter(item => item.type == 'SystemProcedure' && item.executed).length
						if (manualProcCount == 0 && (sysProcCount == this.sysCompleteProcCount)) {
							this.ch3 = true
						}
					}
					this.procedureList.forEach(item => {
						if (item.completedDate) {
							let date = moment(item.completedDate);
							date.tz(localStorage.getItem(LocalStorage.TIMEZONE))
							let convertedDate = date._d;
							item.newCompletedDate = new Date(convertedDate).toLocaleDateString()
						} else {
							item.newCompletedDate = ""
						}
						item.procedureName = this.decodeHTMLString.transform(item.procedureName)
						this.procedureChecked.push(item.executed)
					})
				}
				this.eventType = this.widgetEvent.SHOW_WIZARD
				this.loadingService.hideLoading()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})

		this.userService.getAllActiveUsersForProject(UserVariable.projectId)
			.subscribe(data => {
				this.userList = []
				if (data.payload) {
					data.payload.forEach(element => {
						this.userList.push({ "id": element.id, "name": element.firstName + " " + element.lastName, "value": element.id, "email": element.emailAddress })
					})
				}
				this.workflowService.getActiveWorkFlowsPerProjectByItemType('CommissioningCompletion')
					.subscribe(data => {
						this.workflowTaskList = data.payload
						this.selectedAssignee = []
						if (data.payload) {
							this.workflowTaskList.forEach(element => {
								this.userList.forEach(userElement => {
									if (userElement.id == element.userId) {
										this.selectedAssignee.push(userElement)
									}
								})
							})
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					})
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			})
	}

	markItem(e, i) {
		if (e.target.checked) {
			this.procedureChecked[i] = true
		} else {
			this.procedureChecked[i] = false
		}
		if (this.procedureChecked.filter(item => item == true).length == this.procedureChecked.length) {
			this.ch3 = true
		} else {
			this.ch3 = false
		}
	}

	selectAll(event) {
		if (this.procedureChecked.length > 0) {
			let index = 0;
			this.procedureChecked.forEach(item => {
				if (event.target.checked) {
					this.procedureChecked[index] = true
				} else {
					if (!this.procedureList[index].executed) {
						this.procedureChecked[index] = false
					}
				}
				index = index + 1
			})
		}
		if (this.procedureChecked.filter(item => item == true).length == this.procedureChecked.length) {
			this.ch3 = true
		} else {
			this.ch3 = false
		}
	}

	emailValidate(value: any) {
		let isValid = /^[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+(\.[_a-zA-Z0-9!#$%&'’*+/=?^`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
		return isValid
	}

	isValidEmailAddress(value: any, i: any) {
		let result = true;
		if (i == 0) {
			if (this.assignee1) {
				if (!this.userList.find(user => user.id == this.assignee1)) {
					if (!this.emailValidate(this.assignee1)) {
						result = false;
					}
				}
			}
		} else if (i == 1) {
			if (this.assignee2) {
				if (!this.userList.find(user => user.id == this.assignee2)) {
					if (!this.emailValidate(this.assignee2)) {
						result = false;
					}
				}
			}
		} else if (i == 2) {
			if (this.assignee3) {
				if (!this.userList.find(user => user.id == this.assignee3)) {
					if (!this.emailValidate(this.assignee3)) {
						result = false;
					}
				}
			}
		}
		return result
	}

	isAssigneesAdded() {
		if (this.assignee1 && this.assignee2 && this.assignee3) {
			let result = true;
			if (!this.userList.find(user => user.id == this.assignee1)) {
				if (!this.emailValidate(this.assignee1)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee2)) {
				if (!this.emailValidate(this.assignee2)) {
					result = false;
				}
			}
			if (!this.userList.find(user => user.id == this.assignee3)) {
				if (!this.emailValidate(this.assignee3)) {
					result = false;
				}
			}
			return result
		} else {
			return false
		}
	}

	textChanged(event: any, i: any, taskname: any) {
		if (event) {
			if (i == 1) {
				this.assignee1 = event.value
				this.taskname1 = taskname
			} else if (i == 2) {
				this.assignee2 = event.value
				this.taskname2 = taskname
			} else if (i == 3) {
				this.assignee3 = event.value
				this.taskname3 = taskname
			}
		} else {
			if (i == 1) {
				this.assignee1 = null
				this.taskname1 = taskname
			} else if (i == 2) {
				this.assignee2 = null
				this.taskname2 = taskname
			} else if (i == 3) {
				this.assignee3 = null
				this.taskname3 = taskname
			}
		}
	}

	addInstanceTaskMap() {
		var taskmap = this.workflowTaskList[this.taskCount]
		taskmap['processInstanceId'] = this.workflowId.value

		var taskAssignee = null
		if (this.taskCount == 0) {
			taskAssignee = this.assignee1;
		} else if (this.taskCount == 1) {
			taskAssignee = this.assignee2;
		} else if (this.taskCount == 2) {
			taskAssignee = this.assignee3;
		}
		if (this.userList.find(user => user.id == taskAssignee)) {
			taskmap['userId'] = taskAssignee
			taskmap['userEmail'] = null
		} else {
			taskmap['userEmail'] = taskAssignee
			taskmap['userId'] = null
		}

		this.taskCount = this.taskCount + 1
		this.workflowService.addWorkflowInstanceTaskRolemap(taskmap).
			subscribe(
			data => {
				if (this.taskCount == this.workflowTaskList.length) {
					var workflow = {
						"id": this.workflowId.value, "projectId": UserVariable.projectId, "processInstanceId": this.workflowId.value,
						"processName": "CCCApproval", "itemId": this.selectedItem.ccid,
						"itemType": "CommCompletion", "initiatedUserId": UserVariable.userId, "configData": this.getConfigData(this.procXML)
					}
					this.workflowService.generateWorkflow(workflow)
						.subscribe(data => {
							this.refresh()
							this.loadingService.hideLoading()
						},
						error => {
							this.loadingService.hideLoading()
							this.alertService.clear()
							this.alertService.error(error.statusDescription)
						})
				} else {
					this.addInstanceTaskMap()
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription);
			})
	}

	getProcXML() {
		let procedureXML = "";
		let index = 0;
		this.procedureList.forEach(item => {
			let completed = this.procedureChecked[index] == true ? 'YES' : 'NO'
			let completedUser = "";
			if (item.completedUser) {
				completedUser = item.completedUser
			}
			let completedDate = "";
			if (item.newCompletedDate) {
				let date = moment(item.completedDate);
				date.tz(localStorage.getItem(LocalStorage.TIMEZONE))
				let convertedDate = date._d;
				completedDate = new DatePipe("en-US").transform(new Date(convertedDate), 'yyyy-MM-dd');
			}
			procedureXML = procedureXML + '<ProcedureItem>'
			procedureXML = procedureXML + '<Procedure>' + CommonUtil.formatForHTML(item.procedureName) + '</Procedure>'
			procedureXML = procedureXML + '<Executed>' + completed + '</Executed>'
			procedureXML = procedureXML + '<User>' + completedUser + '</User>'
			procedureXML = procedureXML + '<Date>' + completedDate + '</Date>'
			procedureXML = procedureXML + '<Type>' + item.type + '</Type>'
			procedureXML = procedureXML + '</ProcedureItem>'
			index = index + 1
		})
		return procedureXML
	}

	private finishFunction() {

		this.isSubmitted = true;
		if(!this.validateAssigneeData()){
			return;

		}
		else{

		this.loadingService.showLoading(false, false, '', 0)
		if (!this.comments) {
			this.comments = ''
		}
		var signerslist =  this.getSigngersList();
		this.procXML = this.getProcXML()
		console.log(JSON.stringify(signerslist))
		var ccObject = {
			"ccid": this.selectedItem.ccid, "ch1": this.ch1, "ch2": this.ch2, "ch3": this.ch3, "ch4": this.ch4,
			"ch5": this.ch5, "ch6": this.ch6, "ch7": this.ch7, "procList": this.procXML, "itemList": this.comments ,
			"signersList": JSON.stringify(signerslist) , "doctype" : "CCC"
		}
		this.workflowId = Guid.create()
		console.log(JSON.stringify(ccObject))
		this.commCompletionService.generateCommCompletion(ccObject).
			subscribe(
			data => {
				if (this.workflowTaskList) {
					this.taskCount = 0
					this.addInstanceTaskMap()
				}
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.error(error.statusDescription);
			})
	}}

	validateAssigneeData(): boolean{
		this.validAssignee1 = true;
		this.validAssignee2= true
		this.validAssignee3= true
		this.validAssigneeEmail1 = true;
		this.validAssigneeEmail2= true
		this.validAssigneeEmail3= true


		let validation = true;

			if (this.assignee1 == null ||this.assignee1 == ''){
				this.validAssignee1 = false;
				validation = false;
				
			}
			
			if (this.assignee2 == null ||this.assignee2 == ''){
				this.validAssignee2 = false;
				validation = false;
			}
			
			if (this.assignee3 == null ||this.assignee3 == ''){
				this.validAssignee3 = false;
				validation = false;
			}

			if(this.assignee1 !='' && this.assignee1 != null ){
			if(!this.userList.find(user => user.id == this.assignee1)){
				if(!this.emailValidate(this.assignee1)){
					this.validAssigneeEmail1 = false;
					validation = false;

				}
			}
			}
			if(this.assignee2 !='' && this.assignee2 != null ){
			if(!this.userList.find(user => user.id == this.assignee2)){
				if(!this.emailValidate(this.assignee2)){
					this.validAssigneeEmail2 = false;
					validation = false;

				}
			}
			}
			if(this.assignee3 !='' && this.assignee3 != null ){
			if(!this.userList.find(user => user.id == this.assignee3)){
				if(!this.emailValidate(this.assignee3)){
					this.validAssigneeEmail3 = false;
					validation = false;

				}
			}
			}		

			return validation;

	}

	//generate signers list
	getSigngersList(){
		var signersIdList = [this.assignee1, this.assignee2, this.assignee3];
		var taskNameList = [this.taskname1, this.taskname2, this.taskname3];
		var signersList: any[] = [];
		var i= 1;
		signersIdList.forEach(assignee => {			
			var signLocation = "USER#"+ i.toString() + "#SIGN";
			var dateLocation = "USER#"+ i.toString() + "#DATE";
			var nameLocation = "USER#"+ i.toString() + "#NAME";
			if(this.userList.find(user=> user.id == assignee)){
				var signer  =  this.userList.find(user=> user.id == assignee);
				signersList.push({'signerId': signer.id , 'signerName': signer.name , 'signerEmail': signer.email, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'CCC' });
				i++;
			} else {
				var array = assignee.split('@');
				var assigneeName = array[0];
				signersList.push({'signerId': "" , 'signerName': assigneeName , 'signerEmail': assignee, 'signLocation' : signLocation , 'datelocation': dateLocation, 'namelocation': nameLocation, 'taskname' : taskNameList[i-1], 'wfTaskRoleMapId': this.workflowTaskList[i-1].id, 'wfOrder': i, 'doctype':'CCC' });
				i++;
			}
		});
		return signersList;
 	}

	public delete(params: any) {
		this.selectedItem = params.data
		this.deleteModalRef = this.bsModalService.show(this.deleteCCCModal, { class: 'modal-sm' })
	}

	revokeCCC(): void {
		this.deleteModalRef.hide();
		this.loadingService.showLoading(false, false, '', 0)
		this.commCompletionService.revokeCommCompletion(this.selectedItem.ccid)
			.subscribe(
			data => {
				this.refresh()
				this.loadingService.hideLoading()
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}

	downloadFile(params: any) {
		this.loadingService.showLoading(false, false, '', 0)
		this.commCompletionService.downloadFile(UserVariable.projectId, params.data.ccid)
			.subscribe(response => {
				this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "CCC_" + params.data.systemName + ".pdf");
			},
			error => {
				this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.cc_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	downloadSentFile(params) {
		// this.loadingService.showLoading(false, false, '', 0)
		this.commCompletionService.downloadSentFile(UserVariable.projectId, params.data.ccid)
			.subscribe(response => {
				// this.loadingService.hideLoading()
				let file = new Blob([response], { type: 'application/pdf' });
				FileSaver.saveAs(file, "CCC_" + params.data.systemName + "_" + ".pdf");
			},
			error => {
				// this.loadingService.hideLoading()
				this.alertService.clear()
				this.alertService.error(this.cc_translated_data.ERR_DOWNLOAD_FILE)
			});
	}

	exportToExcel() {
		var params = {
			fileName: "comm_completion.xlsx",
			sheetName: "comm_completion",
			suppressTextAsCDATA: true
		};
		if (this.gridApi) {
			var xlsContent = this.gridApi.getDataAsExcel(params)
			var temp = XLSX.read(xlsContent, { type: 'binary' });
			var xlsxContent = XLSX.write(temp, { bookType: 'xlsx', type: 'base64' });

			this.download(params, xlsxContent);
		}
	}

	download(params, content) {
		var fileNamePresent = params && params.fileName && params.fileName.length !== 0;
		var fileName = fileNamePresent ? params.fileName : "noWarning.xlsx";
		var blobObject = this.b64toBlob(content, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blobObject, fileName);
		} else {
			var downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blobObject);
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	}

	b64toBlob(b64Data, contentType) {
		var sliceSize = 512;
		var byteCharacters = atob(b64Data);
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	public previous(){
		this.isSubmitted = false;
	}
}