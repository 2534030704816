import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';
import { WsCallback } from '../..//util/ws-callback.interface';
import { WsType } from '../../util/ws-type';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'

@Injectable()
export class SystemHandoverWidgetService {

	constructor(private http: HttpClient) {
	}

	getSHCWidgetDetails(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		let url = this.getSHCListByUserURL();
		return this.http.get(url, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	public getSHCListByUserURL() {
		let url = ServiceUrls.USER_SHC_VIEW;
		return url;
	}

	downloadFile(projectId: string, documentId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", documentId).set("mode", "ViewApprovedOutput").set("type", "SystemHandover")
			});
	}

	downloadSentFile(projectId: string, documentId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", documentId).set("mode", "ViewCommOutput").set("type", "SystemHandover")
			});
	}
}