import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class CommCompletionService {

	constructor(private http: HttpClient) {
	}

	getAllCommCompletion(): Observable<WsResponse> {
		console.log("CommCompletionService.getAllCommCompletion() == > ServiceURL= " + ServiceUrls.GET_ALL_CC)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.GET_ALL_CC, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getCommCompletionById(ccId: any): Observable<WsResponse> {
		console.log("CommCompletionService.getCommCompletionById() == > ServiceURL= " + ServiceUrls.getCommCompletionById(ccId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.getCommCompletionById(ccId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	addCommCompletion(commCompletion: any): Observable<WsResponse> {
		console.log("CommCompletionService.addCommCompletion() == > ServiceURL= " + ServiceUrls.ADD_COMM_COMPLETION)
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete commCompletion['isStart']
		commCompletion['userToken'] = token
		return this.http.post(ServiceUrls.ADD_COMM_COMPLETION, JSON.stringify(commCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	updateCommCompletion(commCompletion: any): Observable<WsResponse> {
		console.log("CommCompletionService.updateCommCompletion() == > ServiceURL= " + ServiceUrls.getUpdateCommCompletion(commCompletion.ccid))
		let token = UserVariable.getUserToken();
		// const headers = new Headers({ 'Content-Type': 'application/json' });
		delete commCompletion['isStart']
		delete commCompletion['newtargetCompletionDate']
		commCompletion['userToken'] = token
		return this.http.put(ServiceUrls.getUpdateCommCompletion(commCompletion.ccid), JSON.stringify(commCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getProcedureListStatus(ccId: any): Observable<WsResponse> {
		console.log("CommCompletionService.getProcedureListStatus() == > ServiceURL= " + ServiceUrls.getProcedureListStatus(ccId))
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.getProcedureListStatus(ccId), options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	generateCommCompletion(commCompletion: any) {
		console.log("CommCompletionService.generateCommCompletion() == > ServiceURL= " + ServiceUrls.generateCommCompletion(commCompletion.ccid))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		commCompletion['userToken'] = token
		//return this.http.post(ServiceUrls.generateCommCompletion(commCompletion.ccid), JSON.stringify(commCompletion)).
		return this.http.post(ServiceUrls.generateCommCompletion(commCompletion.ccid), JSON.stringify(new commonRequest("CCC", JSON.stringify(commCompletion)))).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	revokeCommCompletion(cccId: any) {
		console.log("CommCompletionService.revokeCommCompletion() == > ServiceURL= " + ServiceUrls.getRevokeCCC(cccId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let commCompletion = { 'userToken': token }
		return this.http.post(ServiceUrls.getRevokeCCC(cccId), JSON.stringify(commCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	downloadSentFile(projectId: string, cccId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", cccId).set("mode", "ViewCommOutput").set("type", "CommCompletion").set("fileName", "CCC")
			});
	}

	downloadFile(projectId: string, cccId: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", cccId)
					.set("mode", "ViewApprovedOutput").set("type", "CommCompletion").set("fileName", "CCC")
			});
	}	

	getCCCSignature(fileName: any) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, { responseType: "blob", params: new HttpParams().set("fileName", fileName).set("mode", "GetImage") });
	}

	deleteCommCompletion(cccId: any) {
		console.log("CommCompletionService.deleteCommCompletion() == > ServiceURL= " + ServiceUrls.getDeleteCCC(cccId))
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let commCompletion = { 'userToken': token }
		return this.http.post(ServiceUrls.getDeleteCCC(cccId), JSON.stringify(commCompletion)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	getPersonDocuSignStatus(docuSignId: any): Observable<WsResponse> {
		
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		let url = ServiceUrls.getPersonDocuSignStatusUrl(docuSignId);
		console.log("getPersonDocuSignStatus == > ServiceURL= " + url)

		return this.http.get(url, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

}

class commonRequest{
	constructor(private certificateType: String, private certificate: String) { }
}