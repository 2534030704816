import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap';
import { PunchlistService } from 'src/app/widgets/punchlist.service';
import { AlertService } from '../alert/alert.service';
import { Constants } from '../constants';
import { LoadingService } from '../loading/loading.service';
import { SharedService } from '../shared.service';
import { WsResponse } from '../ws-response.model';
import { WsType } from '../ws-type';
import { DependenciesModalService } from './dependencies-modal.service';

@Component({
    selector: 'app-dependenciesmodal',
    templateUrl: './dependenciesmodal.component.html',
    styleUrls: ['./dependenciesmodal.component.css']
})
export class DependenciesModalComponent implements OnInit {

    private itemType: string;
    private itemId: string;
    private action: string;
    private dependencyMessageType: string;
    private status: String;
    public displayModel: boolean = false;
    public displayDeleteModel: boolean = false;
    public noDependecies: boolean = false;

    
    private systemDependency: any;
    private topicDependency: any;
    private procDependency: any;
    private iceTopicContentDependency: any;
    private iceTopicDependency: any;
    private iceCourseDependency: any;
    private assetDependency: any;
    private tagDependency: any;
    private assetParentDependency: any;
    private assetAttrDependency: any;
    private mccDependency: any;
    private sccDependency: any;
    private shcDependency: any;
    private cccDependency: any;
    private stnDependency: any;
    private punchListDependency: any;
    private topicMapDependency: any;
    private imageDependency: any;
    private folderDependency: any;
    private contentDependency: any;
    private documentDependency: any;
    private projectDependency: any;
    private componentDependency: any;
    private statementDependency: any;
    private checksheetDependency: any;
    private userDependency: any;
    private iceTopicCountDependency: any;
    private iceTopicContentCountDependency: any;
    private iceLearningCheckDependency: any;
    private iceCourseOutput: any;
    private topicDependencies: any;
    private topicCountDependencies: any;
    private tagAttributeDependency: any;
    private hasUserDependency: boolean;
    private irnDependency: any;
    private documentTypeDependency: any;

    public title: String;
    public headerTitle: String;
    public delFlag: boolean;
    public cancelFlag: boolean;
    public checkboxVal: boolean;
    public deleteConfirm: boolean;
    public deleteItem: string;

    public deletePunchlist: string;
    public disablePunchlist: string;
    public enablePunchlist: string;
    public deleted: string;
    public deleteItems: string;
    public userClickCheckbox: boolean = false;
    private multipleSelectedItems: string;
    private loadingMessage: string;

    constructor(public bsModalRef: BsModalRef, private sharedService: SharedService,
        private dependenciesmodalService: DependenciesModalService, private alertService: AlertService,
        private translate: TranslateService, private loadingService: LoadingService, private punchlistService: PunchlistService) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.translate.get('DEPENDENCIES_MODAL.DELETE_PUNCHLIST').subscribe((res: string) => {
            this.deletePunchlist = res;
            this.translate.get('DEPENDENCIES_MODAL.DISABLE_PUNCHLIST').subscribe((res: string) => {
                this.disablePunchlist = res;
                this.translate.get('DEPENDENCIES_MODAL.ENABLE_PUNCHLIST').subscribe((res: string) => {
                    this.enablePunchlist = res;
                    this.translate.get('GENERAL.LOADING').subscribe((res: string) => {
                        this.loadingMessage = res;
                    });
                });
            });
        });


        this.itemType = this.sharedService.getFileType();
        this.itemId = this.sharedService.getFileId();
        this.action = this.sharedService.getAction();
        this.dependencyMessageType = this.sharedService.getDependencyMessageType();
        this.multipleSelectedItems = this.sharedService.getMultipleSelectedItems();
        this.sharedService.setMultipleSelectedItems(null);
        dependenciesmodalService.setCallback(this);

    }

    ngOnInit() {
        this.loadingService.showLoading(true,false,"Dependency Loading",100);
        if (this.multipleSelectedItems != null) {
            this.dependenciesmodalService.getDependeciesMultipleItems(this.multipleSelectedItems, this.action);
        } else {
            this.dependenciesmodalService.getDependecies(this.itemId, this.itemType, this.action);

        }
        localStorage.setItem("Confirm", "cancel")
        console.log("this.itemId " + JSON.stringify(this.itemId))
        console.log("this.itemType " + this.itemType)
        if (this.checkboxVal) {
            this.sharedService.setStatus("Inactive");
            this.status = "Inactive";
            console.log("set status= inactive");
        } else {
            this.sharedService.setStatus("Active");
            this.status = "Active";
            console.log("set status= active");

        }
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {
        console.log("dependenciesmodal.component/onSuccess" + serviceType)
        if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.loadingService.hideLoading();
            let response = JSON.parse(data.payload);
            
            if (response != null) {
                if (response.HasDependencies == 1) {
                    this.noDependecies = false;
                } else {
                    this.noDependecies = true;
                }
                //Uncomment the dependency type that is needed to be shown

                // if (response.DocumentType != undefined) {
                //     this.documentTypeDependency = response.DocumentType;
                //     if(this.documentTypeDependency.length != undefined && this.documentTypeDependency.length > 0){
                //         this.noDependecies = false;
                //     }
                // }
                // if (response.SystemDependency != undefined && response.SystemDependency.Systems != undefined) {
                //     this.systemDependency = response.SystemDependency.Systems;
                //     if (this.systemDependency.System.length != undefined) {
                //         for (var dependency of this.systemDependency.System) {
                //             var html = dependency.SystemName;
                //             var div = document.createElement("div");
                //             div.innerHTML = html;
                //             dependency.SystemName = div.textContent || div.innerText || "";
                //         }
                //     } else {
                //         var html = this.systemDependency.System.SystemName;
                //         var div = document.createElement("div");
                //         div.innerHTML = html;
                //         this.systemDependency.System.SystemName = div.textContent || div.innerText || "";
                //     }
                // }
                // if (response.TopicDependency != undefined && response.TopicDependency.Topics != undefined) {
                //     if (response.TopicDependency.Topics.Topic !== undefined) {
                //         if (response.TopicDependency.Topics.Topic.length == undefined) {
                //             let Topic = [];
                //             Topic.push(response.TopicDependency.Topics.Topic)
                //             response.TopicDependency.Topics.Topic = Topic
                //         }
                //     } else {
                //     }
                //     this.topicDependency = response.TopicDependency.Topics;
                // }
                // if (response.ProcedureDependency != undefined && response.ProcedureDependency.Procedures != undefined) {
                //     this.procDependency = response.ProcedureDependency.Procedures;
                // }

                // if (response.ICECourseDependency != undefined && response.ICECourseDependency.ICECourses != undefined) {
                //     this.iceCourseDependency = response.ICECourseDependency.ICECourses;
                //     if (this.iceCourseDependency.ICECourse.length != undefined) {
                //         for (var dependency of this.iceCourseDependency.ICECourse) {
                //             var html = dependency.iceCourseName;
                //             var div = document.createElement("div");
                //             div.innerHTML = html;
                //             dependency.iceCourseName = div.textContent || div.innerText || "";
                //         }
                //     } else {
                //         var html = this.iceCourseDependency.ICECourse.iceCourseName;
                //         var div = document.createElement("div");
                //         div.innerHTML = html;
                //         this.iceCourseDependency.ICECourse.iceCourseName = div.textContent || div.innerText || "";
                //     }
                // }

                // if (response.ICETopicDependency != undefined && response.ICETopicDependency.ICETopics != undefined) {
                //     this.iceTopicDependency = response.ICETopicDependency.ICETopics;
                //     if (this.iceTopicDependency.ICETopic.length != undefined) {
                //         for (var dependency of this.iceTopicDependency.ICETopic) {
                //             var html = dependency.iceTopicName;
                //             var div = document.createElement("div");
                //             div.innerHTML = html;
                //             dependency.iceTopicName = div.textContent || div.innerText || "";
                //         }
                //     } else {
                //         var html = this.iceTopicDependency.ICETopic.iceTopicName;
                //         var div = document.createElement("div");
                //         div.innerHTML = html;
                //         this.iceTopicDependency.ICETopic.iceTopicName = div.textContent || div.innerText || "";
                //     }
                // }
                // if (response.ICETopicContentDependency != undefined && response.ICETopicContentDependency.ICETopicContents != undefined) {
                //     this.iceTopicContentDependency = response.ICETopicContentDependency.ICETopicContents;
                //     if (this.iceTopicContentDependency.ICETopicContent.length != undefined) {
                //         for (var dependency of this.iceTopicContentDependency.ICETopicContent) {
                //             var html = dependency.descriptionHTML;
                //             var div = document.createElement("div");
                //             div.innerHTML = html;
                //             dependency.descriptionHTML = div.textContent || div.innerText || "";
                //         }
                //     } else {
                //         var html = this.iceTopicContentDependency.ICETopicContent.descriptionHTML;
                //         var div = document.createElement("div");
                //         div.innerHTML = html;
                //         this.iceTopicContentDependency.ICETopicContent.descriptionHTML = div.textContent || div.innerText || "";
                //     }
                // }
                // if (response.AssetDependency != undefined && response.AssetDependency.Assets != undefined) {
                //     this.assetDependency = response.AssetDependency.Assets;
                // }
                // if (response.AssetParentDependency != undefined && response.AssetParentDependency.AssetParents != undefined) {
                //     this.assetParentDependency = response.AssetParentDependency.AssetParents;
                // }
                // if (response.AssetAttrDependency != undefined && response.AssetAttrDependency.AssetAttributes != undefined) {
                //     this.assetAttrDependency = response.AssetAttrDependency.AssetAttributes;
                // }
                // if (response.TagDependency != undefined && response.TagDependency.Tags != undefined) {
                //     this.tagDependency = response.TagDependency.Tags;
                // }
                // if (response.MCCDependency != undefined && response.MCCDependency.MCCs != undefined) {
                //     this.mccDependency = response.MCCDependency.MCCs;
                // }
                // if (response.SCCDependency != undefined && response.SCCDependency.SCCs != undefined) {
                //     this.sccDependency = response.SCCDependency.SCCs;
                // }
                // if (response.SHCDependency != undefined && response.SHCDependency.SHCs != undefined) {
                //     this.shcDependency = response.SHCDependency.SHCs;
                // }
                // if (response.CCCDependency != undefined && response.CCCDependency.CCCs != undefined) {
                //     this.cccDependency = response.CCCDependency.CCCs;
                // }
                // if (response.STNDependency != undefined && response.STNDependency.STNs != undefined) {
                //     this.stnDependency = response.STNDependency.STNs;
                // }
                // if (response.PunchlistDependency != undefined && response.PunchlistDependency.Punchlists != undefined) {
                //     this.punchListDependency = response.PunchlistDependency.Punchlists;
                // }
                // if (response.TopicMapDependency != undefined && response.TopicMapDependency.TopicMaps != undefined) {
                //     this.topicMapDependency = response.TopicMapDependency.TopicMaps;
                // }
                // if (response.ImageDependency != undefined && response.ImageDependency.Images != undefined) {
                //     this.imageDependency = response.ImageDependency.Images;
                // }
                // if (response.FolderDependency != undefined && response.FolderDependency.Folders != undefined) {
                //     this.folderDependency = response.FolderDependency.Folders;
                // }
                // if (response.ContentDependency != undefined && response.ContentDependency.ContentItems != undefined) {
                //     this.contentDependency = response.ContentDependency.ContentItems;
                // }
                // if (response.DocumentDependency != undefined && response.DocumentDependency.Documents != undefined) {
                //     this.documentDependency = response.DocumentDependency.Documents;
                // }
                // if (response.ProjectDependency != undefined && response.ProjectDependency.Projects != undefined) {
                //     this.projectDependency = response.ProjectDependency.Projects;
                // }
                // if (response.ComponentDependency != undefined && response.ComponentDependency.Components != undefined) {
                //     this.componentDependency = response.ComponentDependency.Components;
                // }
                // if (response.StatementDependency != undefined && response.StatementDependency.Statements != undefined) {
                //     this.statementDependency = response.StatementDependency.Statements;
                // }
                if (response.CheckSheetDependency != undefined && response.CheckSheetDependency.CheckSheets != undefined) {
                    this.checksheetDependency = response.CheckSheetDependency.CheckSheets;
                }
                // if (response.UserDependency != undefined && response.UserDependency.Users != undefined) {
                //     if (response.UserDependency !== "") {
                //         this.hasUserDependency = false;
                //     } else {
                //         this.hasUserDependency = true;
                //     } 
                //     this.userDependency = response.UserDependency.Users;
                // }
                // if (response.LearningCheck != undefined) {
                //     this.iceLearningCheckDependency = response.LearningCheck;
                //     this.noDependecies = false;
                // }
                // if(response.TagAttributeDependency != undefined && response.TagAttributeDependency.TagAttribute != undefined){
                //     this.tagAttributeDependency = response.TagAttributeDependency.TagAttribute;
                // }
                // if (response.count != undefined) {
                //     this.iceTopicCountDependency = response.count;
                //     this.topicDependencies = response.HasDependencies;
                //     if (response.count != 0 || response.topicContentCount != 0){
                //         this.delFlag = true;
                //         this.checkboxVal = false;
                //     }
                // }
                // if (response.topcContentCount != undefined) {
                //     this.iceTopicContentCountDependency = response.topcContentCount;
                //     this.topicCountDependencies = response.HasDependencies;
                // }

                //set IRN dependency
                if (response.IRNDependency != undefined && response.IRNDependency.IRNs != undefined) {
                    this.irnDependency = response.IRNDependency.IRNs;
                    this.noDependecies = false;
                }

                if (response.HasDependencies != 1 && localStorage.getItem("isDelete") === "true") {
                    if(this.multipleSelectedItems != null){
                        this.headerTitle = this.translate.instant('MULTIPLE_ACTION.DELETE')
                    } else{
                        this.headerTitle = this.translate.instant('DEPENDENCIES_MODAL.DELETE_ITEM')
                    }
                }
            } else {
                this.noDependecies = true;
            }
            this.displayModel = true;
        }
        else if (data != null && serviceType == WsType.DELETE_ITEM) {
            this.sharedService.setDependencyMessageType(null);
            this.loadingService.hideLoading();
            if (this.itemType == "PunchList") {
                console.log("DELETE_ITEM - comp ");
                this.punchlistService.deletePunchlistTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deletePunchlist);
            }
        }
        else if (serviceType == WsType.ITEM_DISABLE_ENABLE) {
            this.loadingService.hideLoading();
            this.sharedService.setDependencyMessageType(null);
            if (this.itemType == "PunchList") {
                this.status = this.status == "Inactive" ? "Active" : "Inactive";
                this.punchlistService.disableEnablePunchlistTableData(this.itemId, this.status);
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enablePunchlist)
                }
                else {
                    this.alertService.success(this.disablePunchlist)
                }
            }
        }
    }

    onFail(data: WsResponse, serviceType: WsType): void {
        console.log("dependenciesmodal.component/onFail" + serviceType)

        this.loadingService.hideLoading();
        if (data != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.alertService.clear();
            this.alertService.error(data.statusDescription, true);
        }
        else if (data != null && serviceType == WsType.DELETE_ITEM){
            this.alertService.clear();
            this.alertService.error(data.statusDescription, true);
        }

    }

    

    confirm(title: String, checkboxValue: boolean, delFlag: boolean) {
        localStorage.setItem("Confirm", "ok");
        console.log("method confirm=" + title + "=");
        console.log("confirm this.itemType" + this.itemType);
        console.log("confirm dependencyMessageType" + this.dependencyMessageType);
        this.title = title;
        this.status = this.sharedService.getStatus();

        if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE) {
            console.log("method confirm DISABLE_CONFIRM");
            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
            //show loading
            this.loadingService.showLoading(true, false, this.loadingMessage, 0);
        } else if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE) {
            console.log("method confirm ENABLE_CONFIRM" + title);
            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
            //show loading
            this.loadingService.showLoading(true, false, this.loadingMessage, 0);
        } else if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.DELETE) {
            console.log("method confirm DELETE_CONFLICTS" + title);
            if (delFlag && !this.noDependecies) {
                if(this.multipleSelectedItems != null){
                    // if (checkboxValue && this.status == 'Active') {
                    //     this.filesDataService.disableAll(this.multipleSelectedItems, this);
                    // } else if (!checkboxValue && this.status == 'Inactive') {
                    //     this.filesDataService.enableAll(this.multipleSelectedItems, this);
                    // }
                }
                else{
                    if (checkboxValue && this.status == 'Active') {
                        this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                    } else if (!checkboxValue && this.status == 'Inactive') {
                        this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                    }
                }
            } else {
                console.log("delete");
                if(this.multipleSelectedItems != null){
                    // this.dependenciesmodalService.deleteAll(this.multipleSelectedItems, this);
                    // this.loadingService.showLoading(true, false, this.translate.instant("DELETING"), 0);
                } else{
                    this.loadingService.showLoading(true, false, this.translate.instant("DELETING"), 0);
                    this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                }
            }
        } else if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.EDIT) {
            if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.PUNCHLIST) {
                //show loading
                this.loadingService.showLoading(true, false, this.loadingMessage, 0);
                this.sharedService.editPunchlistRequest();
            }
        }

    }

    
    onCheckboxClick(event: any){
        console.log("user clicks")
        this.userClickCheckbox = true;
    }

}
