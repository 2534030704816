
        <div #container class="mood" tabindex="0" (keydown)="onKeyDown($event)">
        <div #container class="moodComplete" (click)="onClick('Complete')">
         <label  
        [ngClass]="{'selected' : complete, 'default' : !complete}">Complete</label>
        </div>
        <div #container class="moodCompletePL" (click)="onClick('CompleteWithPL')">
        <label
        [ngClass]="{'selected' : completePL, 'default' : !completePL}">Complete With  P/L</label>
        </div>  
        <div #container class="moodOutstanding" (click)="onClick('Outstanding')">
        <label
        [ngClass]="{'selected' : outstanding, 'default' : !outstanding}">Outstanding</label>
        </div>    
        <div #container class="moodNA" (click)="onClick('NotApp')">
        <label
        [ngClass]="{'selected' : notApplicable, 'default' : !notApplicable}">N/A</label>
        </div>    
        </div>
    