export class Punchlist{
    constructor(
        //punchlist details
        public punchlistId: string = null,
        //asset details
        public selectedAsset?: any,
        public assetId: string = null,
        public assetName: string = "",
        public isSystemDisable: boolean = false,
        public isAssetDisable: boolean = false,


        //discipline details
        public selectedDiscipline?: any,
        public disciplineId: string = null,
        public disciplineName: string = "",

        //subsystem details
        public selectedSubSystem?: any,
        public subsystemId: string = null,
        public subSystemName: string = "",
        public parentId: string = "",
        public parentName: string = "",

        //category details
        public selectedCategory?: any,
        public category: string = "",

        //description
        public punchlistDescription?: string,

        //date raised
        public dateraised?: Date,

        //raised by details
        public raisedby?: string,

        //closed date
        public closedDate?: Date,

        public targetCompletionDate?: Date,

        public estimatedManHours?: string,

        public materialRequired?: string,
        
        //Item Status
        public selectedItemStatus?: any, 
        public itemStatus: string = "",

        public comments?: string,

        //Activity Code details
        public selectedActivityCode?: any,
        public activityCodeId: string = null,
        public activityCodeName: string = "",

        //Object Code details
        public selectedObjectCode?: any,
        public objectCodeId: string = null,
        public objectCodeName: string = "",

        //Optional Modifier details
        public selectedOptionalModifier?: any,
        public optionalModifierId: string = null,
        public optionalModifierName: string = "",

        public status: string = null,

        //photo and location
        public photoName: string = null,
        public imgUrl: string = null,
        public locationLat: any = null,
        public locationLong: any = null,
        public isAvailablePhoto: boolean = true,
        public isAvailableLocation: boolean = true,
        
        //irn
        public irnId: string = null,

        public relatedIssuedCheckSheetsCount: boolean = false,
        public relatedApprovedCheckSheetsCount: boolean = false,
        public relatedIssuedAndApprovedCheckSheetsCount: boolean = false,

        //vendor details
        public selectedVendor?: any,
        public vendorId: string = null,
        public vendorName: string = "",

        public punchlistDocuments: Array<PunchlistDocument> = [],
        public punchlistImages: Array<PunchlistImage> = [],

        public firstOilRequired: boolean = false
    ){}
}

export class PunchlistDocument{
    constructor(
        public id: string,
        public punchlistId: string,
        public projectId: string,
        public addedUserId: string,
        public filePath: string,
        //public file: File,
        public fileName: string,
        public contentType: string,
        public isAdded: boolean,
        public isDeleted: boolean,
        public fileSize?: number
    ){}
}

export class PunchlistImage{
    constructor(
        public id: string,
        public punchlistId: string,
        public projectId: string,
        public addedUserId: string,
        public filePath: string,
        public fileName: string,
        public contentType: string,
        public isAdded: boolean,
        public isDeleted: boolean,
        public isNew: boolean,
        public base64Image: string,
        public fileSize?: number
    ){}
}
