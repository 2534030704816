import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommCompletionService } from '../../comm-completion.service'
import { ICellRendererAngularComp } from "ag-grid-angular";
import { DocusignPesonStatusComponent } from "../../docusign-peson-status/docusign-peson-status.component"


@Component({
	selector: 'app-system-turnover-notice-docu-sign',
	templateUrl: './system-turnover-notice-docu-sign.component.html',
	styleUrls: ['./system-turnover-notice-docu-sign.component.css']
})
export class SystemTurnoverNoticeDocuSignRenderer implements ICellRendererAngularComp {

	@ViewChild('docusignTemplate') docusignTemplate: TemplateRef<any>
	type: string;
	
	
	refresh(): boolean {
		return false;
	}

	params: any;
	docuSignId: any;
	system: any;
	status: any;
	docuSignStatus: any = "";
	modalRef: BsModalRef;
	isPresent: boolean = false;
	stn_translated_data: any = null;
	gridParams: any;
	workflow: boolean = false;

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}

	constructor(private modalService: BsModalService){}

	ngOnInit() {
	}

	agInit(params: any): void{
		// console.log("===docusing renderer JSON.stringify(params.data)=" + JSON.stringify(params.data));
		// console.log("===docusing renderer JSON.stringify(params.value)=" + JSON.stringify(params.value));
		// console.log("===docusing renderer JSON.stringify(params)=" + JSON.stringify(params));
		this.gridParams = params;
		if(params.value == true){
			this.workflow = true
		}
		else{
			// console.log("===docusing renderer if(params != null)=" + params);
			this.docuSignStatus = this.statusRender(params.data.docuSignStatus)		
			this.isPresent = true;
			this.docuSignId = params.data.docuSignId
			this.system = params.data.systemName
			this.status = params.data.stnStatus
		}
		
	}

	public startWorkflow() {
		// this.gridParams.context.componentParent.iconClicked = true
		this.gridParams.context.componentParent.showWizard(this.gridParams)
	}

	public statusRender(status: any) {
		switch (status) {
			case 'waiting':
				return '<span class="badge badge-warning px-2 py-1">Waiting</span>'
			case 'completed':
				return '<span class="badge badge-success px-2 py-1">Completed</span>'
			case 'sent':
				return '<span class="badge badge-warning px-2 py-1">Sent</span>'
			case 'declined':
				return '<span class="badge badge-danger px-2 py-1">Declined</span>'
			case 'delegated':
				return '<span class="badge badge-danger px-2 py-1">Delegated</span>'
			case 'error':
				return '<span class="badge badge-danger px-2 py-1">Error</span>'
			case 'failed':
				return '<span class="badge badge-danger px-2 py-1">Failed</span>'
			default:
				return ''
		}
	}

	viewPersonStatus(template: TemplateRef<any>){
		this.type = 'STN'
		event.stopPropagation();
		this.modalRef = this.modalService.show(this.docusignTemplate, this.modalConfig);	
	}

	public modelRefCloseEvent() {
		this.modalRef.hide()
	}

}
