<div class="modal-header">
	<h5 class="modal-title">{{ 'PUNCHLIST_ADD_EDIT.ADD_PUNCHLIST' | translate }}</h5>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
		<span aria-hidden="true">×</span>
	</button>
</div>

<div class="modal-body">
	<div class="row">
		<div class="col-lg-6">
			<!--Select category-->
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.CATEGORY' | translate }}<div class="badge-required mx-2">
					</div>
				</label>
				<ng-select [allowClear]="true" [items]="category" Name="category"
					placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_CATEGORY' | translate }}" id="category"
					(selected)="categorySelected($event)" (removed)="removeCategory($event)"> </ng-select>
			</div>
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.ASSET' | translate }}
				</label>
				<tag-input name="add_assets" id="add_assets" [(ngModel)]='selectedAssetList'
							[disable]="this.punchlist.isAssetDisable" [removable]='true' (onRemove)="removeSelectedItem()"
                            secondaryPlaceholder="{{'OTN_ADD_EDIT.SELECT_OTN_ASSETS' | translate}}"
                            placeholder="{{'OTN_ADD_EDIT.SELECT_OTN_ASSETS' | translate}}" [onlyFromAutocomplete]="true"
                            (onAdd)="selectItemFromSuggestions()" [clearOnBlur]="true"
                            [displayBy]="'text'" [identifyBy]="'id'">
                            <tag-input-dropdown [showDropdownIfEmpty]="true" [keepOpen]="false" [autocompleteItems]="assetList"
                                [displayBy]="'text'" [identifyBy]="'id'" [limitItemsTo]=20 [appendToBody]="false">
                            </tag-input-dropdown>
                </tag-input>
				<!--<tag-input name="add_assets" id="add_assets" [(ngModel)]='selectedAssetList'
					[disable]="this.punchlist.isAssetDisable" [removable]='true'
					secondaryPlaceholder="{{'PUNCHLIST_ADD_EDIT.SELECT_ASSET' | translate}}"
					placeholder="{{'PUNCHLIST_ADD_EDIT.SELECT_ASSET' | translate}}" [onlyFromAutocomplete]="true"
					(onAdd)="selectItemFromSuggestions()" (onRemove)="removeSelectedItem()" [clearOnBlur]="true">
					<tag-input-dropdown [showDropdownIfEmpty]="true"
						[autocompleteObservable]="requestAutocompleteItems" [limitItemsTo]=20 [appendToBody]="false">
					</tag-input-dropdown>
				</tag-input>-->
			</div>
		</div>
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.DISCIPLINE' | translate }}<div class="badge-required mx-2">
					</div>
				</label>
				<ng-select #discipline [active]="punchlist.selectedDiscipline" [allowClear]="true"
					[items]="disciplineList" (selected)="selectedDisciplineId($event)"
					(removed)="removeDisciplineId($event)"
					placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_DISCIPLINE' | translate }}" Name="discipline"
					id="assetId"> </ng-select>
			</div>
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.DESCRIPTION' | translate }}<div class="badge-required mx-2">
					</div>
				</label>
				<textarea type="text" id="addpunchlist_description" class="form-control" Name="addpunchlist_description"
					[(ngModel)]="punchlist.punchlistDescription" maxlength="500"></textarea>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.DATE_RAISED' | translate }}<div class="badge-required mx-2">
					</div></label>
				<input type="text" placeholder="Select date raised" class="form-control" Name="date_raised"
					[(ngModel)]="punchlist.dateraised" [bsConfig]="bsConfig" placement="top" bsDatepicker>
			</div>
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.DATE_CLOSED' | translate }}
				</label>
				<input type="text" placeholder="Date closed" class="form-control" Name="date_closed"
					[(ngModel)]="punchlist.closedDate" [bsConfig]="bsConfig" placement="top" bsDatepicker [disabled]=true>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.TARGET_COMPLETION_DATE' | translate }}
				</label>
				<input type="text" placeholder="Select target completion date" class="form-control"
					Name="target_completion_date" [(ngModel)]="punchlist.targetCompletionDate" [bsConfig]="bsConfig"
					placement="top" bsDatepicker>
			</div>
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.RAISED_BY' | translate }}<div class="badge-required mx-2">
					</div>
				</label>

				<input type="text" class="form-control" id="addpunchlist_raisedby" Name="addpunchlist_raisedby"
					[(ngModel)]="punchlist.raisedby" maxlength="50">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.ESTIMATED_MAN_HOURS' | translate }}
				</label>

				<input type="text" class="form-control" id="addpunchlist_estimated_man_hours"
					Name="addpunchlist_estimated_man_hours" [(ngModel)]="punchlist.estimatedManHours" maxlength="4">
			</div>
		</div>
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.SUB_SYSTEM' | translate }}
				</label>

				<ng-select class="select-more-height" #subsystem [active]="punchlist.selectedSubSystem"
					[allowClear]="true" [items]="subSystemList"
					placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_SUB_SYSTEM' | translate }}" Name="subsystem"
					id="subsystemId" (selected)="selectedSubSystemId($event)"
					([ngModel])="this.punchlist.isSystemDisable"
					[disabled]="this.punchlist.isSystemDisable || subSystemDisable"
					(removed)="removedSubSystem($event)"> </ng-select>
				<small ([ngModel])="this.punchlist.parentName" id="emailHelp" class="form-text text-muted">{{
					'PUNCHLIST_ADD_EDIT.SYSTEM' | translate }} {{ this.punchlist.parentName }}</small>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.MATERIALS_REQUIRED' | translate }}
				</label>
				<textarea type="text" id="materials_required" class="form-control" Name="materials_required"
					[(ngModel)]="punchlist.materialRequired" maxlength="500"></textarea>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.ITEM_STATUS' | translate }}
				</label>
				<ng-select [active]="[selectedItem_status]" [items]="item_status" Name="itemstatus" [disabled]=true
					placeholder="{{ 'PUNCHLIST_ADD_EDIT.SELECT_ITEM_STATUS' | translate }}" id="itemstatus"
					(selected)="itemStatusSelected($event)"> </ng-select>
			</div>
		</div>
	</div>

	<div class="row">

		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.COMMENTS' | translate }}
				</label>
				<textarea type="text" class="form-control" id="comments" Name="comments"
					[(ngModel)]="punchlist.comments" maxlength="500"></textarea>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.VENDOR' | translate }}
				</label>
				<ng-select #vendor [active]="punchlist.selectedVendor" [allowClear]="true" [items]="vendorList"
					(selected)="selectedVendorId($event)" (removed)="removeVendorId($event)"
					placeholder="{{ 'PUNCHLIST_ADD_EDIT.VENDOR' | translate }}" Name="vendor" id="vendorId">
				</ng-select>
			</div>
		</div>
	</div>
	<div class="row my-4">
		<div class="col-lg-6 col-12">
			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label class="mt-1">{{ 'PUNCHLIST_ADD_EDIT.FIRST_OIL_REQUIRED' | translate }}
						</label>
					</div>
					<div class="col-2">
						<input #firstOilRequired id="firstOilRequired" type="checkbox" class="form-control"
						name="firstOilRequired" style="height: 100%;" (change)="checkFirstOilRequired(firstOilRequired.checked)">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">

		<div class="col-lg-6">
			<div class="row form-group">
				<label class="col-lg-2">{{ 'PUNCHLIST_ADD_EDIT.PHOTOS' | translate }}
				</label>
				<div class="col">
				<div class="files singlebtn selectimages">
					<input type="file"
					Name="profile" id="ImageUpload" accept=".png, .jpg, .jpeg, .gif" (change)="onFileChanged_Image($event)" multiple
					>
				</div>
				
			</div>
			<!-- <span class="fileLbl col-lg-4">{{fileSelTxt}}</span> -->
			</div>
			<div class="container" *ngIf="punchlist.punchlistImages.length > 0">
				<div class="d-flex flex-wrap">
				  <div *ngFor="let punchlistImage of punchlist.punchlistImages" class="">
					<div *ngIf="!punchlistImage.isDeleted" class="position-relative p-3" >
					  <img class="img-fluid plimage" src="{{punchlistImage.base64Image}}" alt="" 
						(click)="openImage(punchlistImage)">
					  <span class="d-block position-absolute fixed-top removeIcon">
						<a class="close" (click)="removeImage(punchlistImage.id)">&times;</a>
					  </span>
					</div>
				  </div>
				</div>
			  </div>
		</div>
		<div class="col-lg-6">
			<div class="form-group">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.LOCATION' | translate }}
				</label>
				<div #mapContainer id="map"></div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-12">
			<div class="form-group files selectfiles">
				<label class="">{{ 'PUNCHLIST_ADD_EDIT.UPLOAD_DOCUMENTS' | translate }}</label>
				<input type="file" class="form-control  custom-b  form-control-file" multiple="" Name="profile"
					id="NewFile_FileUpload" accept=".pdf" (change)="onFileChanged($event)" required>
			</div>
			<label><small id="emailHelp" class="form-text text-muted">{{ 'NEW_PUNCHLIST_DOCUMENT.USER_FILE.MESSAGE' |
					translate }}</small>
				<small id="emailHelp" class="form-text text-muted">{{ 'NEW_PUNCHLIST_DOCUMENT.USER_FILE.SIZE_LIMIT' |
					translate }}</small></label>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-12">
			<ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData"
				[columnDefs]="columnDefs" [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10"
				(gridReady)="onGridReady($event)" (cellClicked)='onDelete($event)'>
			</ag-grid-angular>
		</div>
	</div>
</div>


<div class="modal-footer">
	<div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
		<button type="button" class="btn btn-primary btn-sm" (click)="saveClick()">{{ 'PUNCHLIST_ADD_EDIT.SAVE' | translate}}</button>
		<div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
		  <button class="btn btn-primary btn-sm dropdown-toggle-split" ngbDropdownToggle></button>
		  <div class="dropdown-menu" ngbDropdownMenu>
			<button ngbDropdownItem (click)="saveClick()">{{ 'PUNCHLIST_ADD_EDIT.SAVE' | translate}}</button>
			<button ngbDropdownItem (click)="saveAndNewClick()">{{ 'PUNCHLIST_ADD_EDIT.SAVE_AND_NEW' | translate}}</button>
			<button ngbDropdownItem (click)="saveAndWorkflowClick()">{{ 'PUNCHLIST_ADD_EDIT.SAVE_AND_WF' | translate }}</button>
		  </div>
		</div>
	</div>
	<button type="button" id="punchlist_cancel" class="btn btn-secondary btn-sm float-left" (click)="cancel()">{{
		'PUNCHLIST_ADD_EDIT.CANCEL' | translate}}</button>
</div>