import { Component, OnInit } from '@angular/core';
import { SharedService } from '../util/shared.service';
import { LoadingService } from '../util/loading/loading.service';
import { WidgetService } from '../widgets/widget.service';
import { LocalStorageConst } from '../util/constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertService } from '../util/alert/alert.service';
import { CapabilityService } from '../util/capability.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

	public selectedMenuItem: string = 'dashboard'
	isCommCollapsed: boolean = false
	isDocuCollapsed: boolean = false;
	isTopicMapCollapsed: boolean = false;
	showCommissioning: boolean = false;
	showOverview: boolean = false;
	showMechanicalCompletion: boolean = false;
	showPreCommissioning: boolean = false;
	showWorkItems: boolean = false;
	showFacilityAcceptance: boolean = false;
	showPunchlists: boolean = false;
	showIRN: boolean = false;
	showSystemHandover: boolean = false;
	showSTNPTN: boolean = false;
	showCommCompletion: boolean = false;
	showChecksheets: boolean = false;
	showBulkImport: boolean = false;
	showDocumentation: boolean = false;
	showCMMS: boolean= false;
	showProcedures: boolean = false;
	showOTN: boolean = false;
	showAdhocReports: boolean = false;

	constructor(private sharedService: SharedService, private loadingService: LoadingService, private alertService: AlertService,
		private widgetService: WidgetService, private deviceService: DeviceDetectorService, private capabilityService: CapabilityService) { }

	ngOnInit() {
		this.capabilityCheck();
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.csmiExecute().subscribe(data => this.changeSelectedMenuItem(data))
	}

	public hideSlideBar() {
		this.sharedService.csbvRequest()
	}

	public sideItemClick(menuItem: string) {
		if (this.selectedMenuItem != menuItem) {
			this.selectedMenuItem = menuItem
			localStorage.setItem(LocalStorageConst.SELECTED_MENU_ITEM, menuItem)
			this.loadingService.showLoading(false, false, '', 0)

			//check device and hide side bar 
			const isDesktopDevice = this.deviceService.isDesktop();
			if (!isDesktopDevice) {
				this.hideSlideBar()
			} else {
				//do nothing
			}
			if (menuItem == 'dashBoardWidgetList') {
				this.widgetService.getProjectDashboard(menuItem).subscribe(
					data => {
						if (data.payload != null) {
							let widgetList = JSON.parse(data.payload.widgetConfig);
							this.sharedService.loadWidgetListRequest(widgetList)
							this.loadingService.hideLoading()
						} else {
							//do nothing
						}
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					}
				)
			} else {
				let widgetList: any = this.widgetService.loadWidgetListByNumber(menuItem)
				setTimeout(() => {
					this.sharedService.loadWidgetListRequest(widgetList)
					this.loadingService.hideLoading()
				}, 1000);
			}

		}
	}


	private changeSelectedMenuItem(data: any) {
		let menuItem = data.menuItem
		let group = data.group

		switch (group) {
			case 'com':
				this.isCommCollapsed = true
				break
			case 'dom':
				this.isDocuCollapsed = true
				break;
            case 'procedures': 
                this.isTopicMapCollapsed = true 
				break;
			default:
				break;
		}

		this.sideItemClick(menuItem)
	}

	/**
	 * Achini Randeni
	 * Check capabilities to show/hide sidebar items
	 */
	capabilityCheck(){
		let viewCommissioningCapability: string = "viewCommissioning";
		this.showCommissioning = this.capabilityService.isCapabilityAssigned(viewCommissioningCapability);
		let viewOverviewCapability: string = "viewOverview";
		this.showOverview = this.capabilityService.isCapabilityAssigned(viewOverviewCapability);
		let viewMechanicalCompletionCapability: string = "viewMechanicalCompletion";
		this.showMechanicalCompletion = this.capabilityService.isCapabilityAssigned(viewMechanicalCompletionCapability);
		let viewPrecommissioningCapability: string = "viewPrecommissioning";
		this.showPreCommissioning = this.capabilityService.isCapabilityAssigned(viewPrecommissioningCapability);
		let viewWorkItemsCapablity: string = "viewWorkItems";
		this.showWorkItems = this.capabilityService.isCapabilityAssigned(viewWorkItemsCapablity); 
		let viewFacilityAcceptanceCapablity: string = "viewFacilityAcceptance";
		this.showFacilityAcceptance = this.capabilityService.isCapabilityAssigned(viewFacilityAcceptanceCapablity);
		let viewPunchlistCapablity: string = "viewPunchlist";
		this.showPunchlists = this.capabilityService.isCapabilityAssigned(viewPunchlistCapablity); 
		let viewIRNCapablity: string = "viewIRN";
		this.showIRN = this.capabilityService.isCapabilityAssigned(viewIRNCapablity); 
		let viewSystemHandoverCapablity: string = "viewSystemHandover";
		this.showSystemHandover = this.capabilityService.isCapabilityAssigned(viewSystemHandoverCapablity); 
		let viewSystemTurnoverCapablity: string = "viewSystemTurnover";
		this.showSTNPTN = this.capabilityService.isCapabilityAssigned(viewSystemTurnoverCapablity); 
		let viewCommCompletionCapability: string = "viewCommCompletion";
		this.showCommCompletion = this.capabilityService.isCapabilityAssigned(viewCommCompletionCapability);
		let viewCheckSheetsCapability: string = "viewChecksheets";
		this.showChecksheets = this.capabilityService.isCapabilityAssigned(viewCheckSheetsCapability);
		let viewBulkImportCapability: string = "viewBulkImport";
		this.showBulkImport = this.capabilityService.isCapabilityAssigned(viewBulkImportCapability);
		let viewDocumentationCapability: string = "viewDocumentation";
		this.showDocumentation = this.capabilityService.isCapabilityAssigned(viewDocumentationCapability);
		let viewCMMSCapability: string = "viewCMMS";
		this.showCMMS = this.capabilityService.isCapabilityAssigned(viewCMMSCapability);
		let viewProceduresCapability: string = "viewProcedures";
		this.showProcedures = this.capabilityService.isCapabilityAssigned(viewProceduresCapability);
		let queryOnshoreTurnoverNoticeCapability: string = "queryOnshoreTurnoverNotice";
		this.showOTN = this.capabilityService.isCapabilityAssigned(queryOnshoreTurnoverNoticeCapability);
		let viewOTN: string = "viewOTN";
		this.showOTN = this.capabilityService.isCapabilityAssigned(viewOTN);
		let viewAdhocReports: string = "viewAdhocReports";
		this.showAdhocReports = this.capabilityService.isCapabilityAssigned(viewAdhocReports);
	}
}
