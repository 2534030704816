import { Component, OnInit } from '@angular/core';

import { LocalStorage } from '../../util/localstorage.service';
import { MechanicalCompletionService } from '../mechanical-completion.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../util/shared.service';
import { LoggedUser } from '../../user/logged-user.model';

import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import * as FileSaver from 'file-saver';
import { UserVariable } from '../../util/common/user-variable';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-mcc-behind-scheduled-widget',
	templateUrl: './mcc-behind-scheduled-widget.component.html',
	styleUrls: ['./mcc-behind-scheduled-widget.component.css']
})
export class MccBehindScheduledWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	public isHeader: boolean = false
	public mccCount: number
	private projectId: any
	isLoadingData: boolean = false

	constructor(
		private mechanicalCompletionService: MechanicalCompletionService,
		private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService) { }

	ngOnInit() {
		this.projectId = localStorage.getItem(LocalStorage.PROJECT_ID)
		this.isLoadingData = true
		this.mechanicalCompletionService.getMccCount(this.projectId).subscribe(
			data => {
				this.isLoadingData = false
				if (data.payload != null) {
					this.mccCount = data.payload;
				} else {
					this.mccCount = 0
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});
	}


}
