import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { convertToObject } from 'typescript';

@Injectable()
export class PunchlistService {
	updateUIafterDelete = new EventEmitter<any>();
	updateUIafterEnableDisable = new EventEmitter<any>();
	private rowData: any = null;
	loadPLTableData = new EventEmitter<any>();

	constructor(private http: HttpClient) {
	}

	public loadPLTableDataRequest(){
		this.loadPLTableData.emit()
	}
	public loadPLTableDataResponse(){
		return this.loadPLTableData;
	}

	getPunchlists(): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.GET_PUNCHLISTS, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	getOpenPunchListCount(systemId: string, showSubSystems: string): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("systemId", systemId).set("showSubSystems", "" + showSubSystems) }

		return this.http.get(ServiceUrls.GET_OPEN_PUNCHLIST_COUNT, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	getPunchListCount(category: string): Observable<WsResponse> {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }

		return this.http.get(ServiceUrls.getPunchListCount(category), options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	//get punchlist details for punchlist count.
	getPunchlistDetailsBySystem(systemId: string, subSystemId: string, assetId: string, category: string){
		console.log("SystemService.getPunchlistDetailsBySystem() == > ServiceURL= " + JSON.stringify(new Request(systemId, subSystemId, assetId, category)))
		let token = UserVariable.getUserToken();
		let url = ServiceUrls.GET_PUNCHLIST_DETAILS + '?token=' + token;
		return this.http.post(url, JSON.stringify(new Request(systemId, subSystemId, assetId, category))).
			pipe(
				map(response => {
					var modified = JSON.parse(JSON.stringify(response));
					return new WsResponse(
						modified.status.description,
						modified.status.name,
						modified.status.code,
						modified.payload);
				}),
			catchError(error => {
					var modified = JSON.parse(JSON.stringify(error));
					var res = new WsResponse(
						modified.error.status.description,
						modified.error.status.name,
						modified.error.status.code,
						modified.error.payload);
					return throwError(res)
				})
			);
	}

	/**
	 * Get punchlist details for IRN punchlist count
	 * @param irnId 
	 * @param category 
	 */
	getPunchlistDetailsByIRN(irnId: string, category: string){
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("irnId", irnId).set("category", category) }

		return this.http.get(ServiceUrls.GET_PUNCHLIST_DETAILS_BY_IRN, options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	downloadImage(projectId: string, id: string, fileName: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE,
			{
				responseType: "blob", params: new HttpParams().set("projectId", projectId)
					.set("documentId", id).set("fileName", fileName).set("mode", "GetPunchlistPhoto")
			});
	}

	downloadOpenSentFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId)
                .set("documentId", id).set("mode", "ViewCommOutput").set("type", "PunchlistOpen").set("fileName", "PL")
            });
    }

    downloadOpenReturnFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", id)
                .set("mode", "ViewApprovedOutput").set("type", "PunchlistOpen").set("fileName", "PL")
            });
    }
  
    downloadCloseSentFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId)
                .set("documentId", id).set("mode", "ViewCommOutput").set("type", "PunchlistClose").set("fileName", "PL")
            });
    }

    downloadCloseReturnFile(projectId: string, id: string) {
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
            responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", id)
                .set("mode", "ViewApprovedOutput").set("type", "PunchlistClose").set("fileName", "PL")
            });
    }

	public downloadFile(outputId: string, punchlistId: string, projectId: string, contentType: string, fileName: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
		   responseType: "blob", params: new HttpParams().
			  set("documentId", outputId).set("punchlistId", punchlistId).set("projectId", projectId).set("type", contentType).set("mode", "ViewPunchlistDoc").set("fileName", fileName)
		});
	 }

	public setRowData(rowData: any) {
        this.rowData = rowData;
    }

    public getRowData() {
        return this.rowData;
    }

	/**
	 * get discipline list for project
	 * @param projectId 
	 * @param wsCallBack 
	 */
	getDisciplineList(projectId: string, wsCallBack: WsCallback) {
		console.log("============Discipline LIST==========");
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }  
		this.http.get(ServiceUrls.getDisciplines(projectId), options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_DISCIPLINES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_DISCIPLINES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_DISCIPLINES);
				}
			}
		);
	}

	/**
	 * Get all the systems in the project
	 * @param wsCallBack 
	 */
	getSubSystemList(wsCallBack: WsCallback){
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_ALL_SYSTEM_LIST, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_SYSTEMS_BY_PROJECT_ID);
				}
			}
		);
	}

	/**
   * Get assets List for search
   *
   * @param tagNo
   */
	getAssetListSearch(tagNo: String, assetIds) {
		let token = UserVariable.getUserToken();
		let url = this.getTagSearchURL(token);

		//append '*' to get tag list which contains tagNo from solr
		tagNo = '*' + tagNo + '*';

		return this.http.post<any>(url, JSON.stringify(new AssetRequest(tagNo, null, "", null, null, null, null, true)))
			.pipe(map(payload => JSON.parse(payload).payload.map(item => {
				if (item.status == "Active") {
					return new Object({
						value: item.tagId,
						display: item.tagNo
					})
				} else {
					let include = assetIds.some((f) => {
						return f.value == item.tagId;
					});
					if (include) {
						return new Object({
							value: item.tagId,
							display: item.tagNo
						})
					} else {
						return new Object({
							display: ""
						})
					}

				}
			}
			)))

	}

	public getTagSearchURL(token: String) {
		let url = ServiceUrls.TAGS_SEARCH_VIEW + '?token=' + token;
		return url;
	}

	/**
	 * get all vendors of the project
	 * @param projectId 
	 * @param wsCallBack 
	 * @returns 
	 */
	getVendorList(projectId: string, wsCallBack: WsCallback) {
		console.log("============Vendor LIST==========");
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_VENDOR_LIST, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_VENDOR_LIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_VENDOR_LIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_VENDOR_LIST);
				}
			}
		);
	}

	/**
	 * add new punchlist
	 * @param token 
	 * @param data 
	 * @param wsCallBack 
	 */
	public addNewPunchlist(token: string, data: string, wsCallBack: WsCallback) {
		this.http.post(ServiceUrls.addNewPunchlist(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.ADD_NEW_PUNCHLIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.ADD_NEW_PUNCHLIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.ADD_NEW_PUNCHLIST);
				}
			}
		);
	}

	/**
	 * Generate Punchlist PDF
	 * @param punchlist 
	 */
	generatePunchlistPDF(punchlist: any, wsCallBack: WsCallback) {
		console.log("PunchlistService.generatePunchlistPDF() == > ServiceURL= " + ServiceUrls.GENERATE_PUNCHLIST)
		let token = UserVariable.getUserToken();
		const headers = new Headers({ 'Content-Type': 'application/json' });
		punchlist['userToken'] = token;
		this.http.post(ServiceUrls.GENERATE_PUNCHLIST, JSON.stringify(new commonRequest("PunchlistGroup", JSON.stringify(punchlist)))).
			subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					wsCallBack.onSuccess(res, WsType.GENERATE_PUNCHLIST);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
					wsCallBack.onFail(res, WsType.GENERATE_PUNCHLIST);
			});
    }

	/**
   * Get all assets used in commissioning
   * @param wsCallBack  callback class
   */
	 getAssetList(wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		this.http.get(ServiceUrls.GET_ASSET_LIST, options).subscribe(
		  data => {
			var modified = JSON.parse(JSON.stringify(data));
			var res = new WsResponse(
			  modified.status.description,
			  modified.status.code,
			  modified.status.name,
			  modified.payload);
			wsCallBack.onSuccess(res, WsType.GET_ASSETS);
		  },
		  error => {
			if (error.status != '') {
			  var val = (error as HttpErrorResponse).error;
			  var modified = JSON.parse(JSON.stringify(val));
			  var res = new WsResponse(
				modified.status.description,
				modified.status.code,
				modified.status.name,
				modified.payload);
			  wsCallBack.onFail(res, WsType.GET_ASSETS);
			} else {
			  //browser related issues
			  var res = new WsResponse("Unknown error happened");
			  wsCallBack.onFail(res, WsType.GET_ASSETS);
			}
		  }
		);
	  }

	  public getPunchlistDetails(punchlistId: string, wsCallBack: WsCallback){
		let token = UserVariable.getUserToken();
		this.http.get(ServiceUrls.getPunchlistByPunchlistId(token,punchlistId)).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_PUNCHLIST_BY_PUNCHLIST_ID);
				}
			}
		);
	}

	public getPunchlistDetailsByPunchlistIds(punchlistIds: string){
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("punchlistIds", punchlistIds) }
		return this.http.get(ServiceUrls.getPunchlistDetailsByPunchlistIds(token), options).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	getPunchCodeList(projectId: string, wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }  
		this.http.get(ServiceUrls.getPunchCodes(projectId), options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.GET_PUNCH_CODES);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.GET_PUNCH_CODES);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.GET_PUNCH_CODES);
				}
			}
		);
	}

	public updatePunchlist(token: string, data: string, wsCallBack: WsCallback){
		this.http.post(ServiceUrls.updatePunchlist(token), data).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_PUNCHLIST);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST);
				}
			}
		);
	}

	public updatePunchlistWithClousreComment(itemList: any, wsCallBack: WsCallback){
		let token = UserVariable.getUserToken();	
		const payload = { itemList };
		let url = ServiceUrls.CLOSURE_COMMENT_UPDATE;
		const options = { params: new HttpParams().set("token", token).set("itemList", itemList) }
		this.http.get(url, options).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
				}
			}
		);
	}
	updatePunchlistWithClousreCommentWithId(plClosureCommentsWithIds: string, wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		let url = this.updatePunchlistWithClousreCommentWithIdURL(token);
		
		this.http.post(url, JSON.stringify(new NewRequest(plClosureCommentsWithIds))).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
				}
			}
		);
	}
	updatePunchlistWithClousreCommentWithIdURL(token: String) {
	  let url = ServiceUrls.CLOSURE_COMMENT_UPDATE + '?token=' + token;
	  return url;
	}
	deletePunchlistWithClousreCommentWithIdURL(token: String) {
		let url = ServiceUrls.DELETE_PL_CLOSURE_COMMENTS + '?token=' + token;
		return url;
	}
	deletePLClosureComments(plIdsWithClosureComments: string, wsCallBack: WsCallback) {
        let token = UserVariable.getUserToken();
		let url = this.deletePunchlistWithClousreCommentWithIdURL(token);
		
		this.http.post(url, JSON.stringify(new NewDeleteRequest(plIdsWithClosureComments))).subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				wsCallBack.onSuccess(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
			},
			error => {
				if (error.status != '') {
					var val = (error as HttpErrorResponse).error;
					var modified = JSON.parse(JSON.stringify(val));
					var res = new WsResponse(
						modified.status.description,
						modified.status.code,
						modified.status.name,
						modified.payload);
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
				} else {
					//browser related issues
					var res = new WsResponse("Unknown error happened");
					wsCallBack.onFail(res, WsType.UPDATE_PUNCHLIST_CLCOMMENT);
				}
			}
		);
    } 
	revokePunchlist(punchlistId: string, wsCallBack: WsCallback) {
		let token = UserVariable.getUserToken();
		let data = {
		  id : punchlistId
		}
		let url  = ServiceUrls.revokePunchlist(token, punchlistId)
		return this.http.post(url, JSON.stringify(data)).
		subscribe(
			data => {
				var modified = JSON.parse(JSON.stringify(data));
				var res = new WsResponse(
					modified.status.description,
					modified.status.name,
					modified.status.code,
					{
						id : punchlistId
					});
				wsCallBack.onSuccess(res, WsType.REVOKE_PUNCHLIST);
			},
			error => {
				var modified = JSON.parse(JSON.stringify(error));
				var res = new WsResponse(
					modified.error.status.description,
					modified.error.status.name,
					modified.error.status.code,
					{
						id : punchlistId
					});
			  	wsCallBack.onFail(res, WsType.REVOKE_PUNCHLIST);
			}
		);
	}
 


	getPunchlistsInSameGroup(punchlistId: string): Observable<WsResponse> {
		let token = UserVariable.getUserToken();

		let url  = ServiceUrls.getPunchlistsInSameGroup(token, punchlistId)
		return this.http.get(url).pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
		);
	}

	public deletePunchlistTableData(punchlistId: any) {
		this.updateUIafterDelete.emit(punchlistId);
	}
	
	public deletePunchlistTableDataResponse() {
		return this.updateUIafterDelete;
	}

	public disableEnablePunchlistTableData(punchlistId: any, status: String){
		this.updateUIafterEnableDisable.emit({punchlistId, status});
	}
	public disableEnablePunchlistTableDataResponse() {
		return this.updateUIafterEnableDisable;
	}
}

class AssetRequest {
	constructor(private tagNo: String, private cadNo: String, private description: String, private systemName: String, private documentName: String, private facilityName: String, private hasText: String, private usedInCommissioning: Boolean) { }
}

class Request {
	constructor(private systemId: String, private subSystemId: String, private assetId: String, private category: String) { }
}

class commonRequest{
    constructor(private certificateType: String, private certificate: String) { }
}

class NewRequest {
	constructor(private plClosureCommentsWithIds: String) { }
}

class NewDeleteRequest {
	constructor(private plIdsWithClosureComments: String) { }
}