<div class="panel count-panel count-panel-warning">
    <div class="card-block card-body py-0 px-3">
        <!-- body content -->
        <div class="row">
            <div class="col-sm-2 count-panel-icon p-0 text-center">
                <i class="fas fa-hashtag fa-3x py-3" aria-hidden="true"></i>
            </div>
            <div class="col-sm-10 px-3 py-1 count-panel-text">
                <h6 class="mb-0">
                    {{ "MY_OPEN_WORK_ITEM_COUNT.MY_OPEN_WORK_ITEM_COUNT" | translate }}
                </h6>
                <p class="mb-0">
                    <b *ngIf="isLoadingData">{{ "GENERAL.LOADING" | translate }}</b>
                    <b *ngIf="!isLoadingData">{{myOpenWorkItemCount | number: '1.'}}</b>
                </p>
            </div>
        </div>
    </div>
</div>

<!-- popup templates -->
<!-- modal templates -->