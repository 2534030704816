<div>
  <div class="d-flex align-items-center py-2">
    <div class="col-sm-10 float-left" *ngIf="showCount" [ngClass]="(count==0)?'error-msg':'success-msg'">{{countMessage}}</div>
    <div class="col-sm">
      <input type="file" class="file-input" accept=".xlsx" 
        (change)="onFileSelected($event)" (click)="$event.target.value=null" #fileUpload>
      <button class="btn btn-primary btn-sm float-right" (click)="hasErrorList=false;showCount=false;fileUpload.click()">{{
        'BULK_IMPORT.DATA_IMPORT' | translate }}</button>
    </div>
  </div>
  <div class="my-2" *ngIf="hasErrorList">
    <ag-grid-angular #agGrid style="width: 100%; height: 450px;" [rowData]="rowData" class="ag-theme-balham"
      [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true"
      [floatingFilter]="true" [enableRangeSelection]="true" [paginationPageSize]="10" [pagination]="true"
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions">
    </ag-grid-angular>
  </div>
</div>

