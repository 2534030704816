import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mechanical-completion-detail-widget',
  templateUrl: './mechanical-completion-detail-widget.component.html',
  styleUrls: ['./mechanical-completion-detail-widget.component.css']
})
export class MechanicalCompletionDetailWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
