<div class="panel panel-default">
	<div class="panel-heading box-shadow panel-header-border-bottom py-1">
		<div class="row px-2">
			<div class="col-sm-12 px-0">
				<div class="float-left pt-1 px-1">
					<p class="m-0 pl-1">
						{{ 'STN_PTN_RUNDOWN.WIDGET_NAME' | translate }}
					</p>
				</div>
				<div class="float-right pt-1 px-0">
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="isMinimized = !isMinimized">
						<i class="fas fa-chevron-up " [ngClass]="{'animate-arrow-0': !isMinimized, 'animate-arrow-180': isMinimized}"
						 aria-hidden="true"></i>
					</button>
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="refresh()">
						<i class="fas fa-sync-alt" aria-hidden="true"></i>
					</button>
					<!-- <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
							 (click)="configSettingPO.toggle()">
								<i class="fa fa-cog" aria-hidden="true" [popover]="configContent" containerClass="user-setting-custom" placement="bottom"
								 #configSettingPO="bs-popover" triggers="" [outsideClick]="true" container="body"></i>
							</button> -->
					<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
					 (click)="showFilterOptions = !showFilterOptions">
						<i class="fas fa-cog" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
		<div class="row my-1" *ngIf="showFilterOptions">
			<div class="col-sm-12 my-2">
				<div class="row">
					<div class="form-group col-sm-6">
						<label>{{ 'STN_PTN_RUNDOWN.SYSTEM' | translate }} :</label>						
						<ng-select [disabled]="isLoadingData" [active]="selectedSystem" [allowClear]="true" (removed)="removedSystem($event)" (selected)="selectedSystemId($event)"
						 [items]="systemList" placeholder="{{ 'STN_PTN_RUNDOWN.SELECT_SYSTEM' | translate }}" name="system" id="system">
						</ng-select>
					</div>
					<div class="form-group col-sm-6">
						<label>{{ 'STN_PTN_RUNDOWN.VENDOR' | translate }} :</label>						
						<ng-select [disabled]="isLoadingData" [active]="selectedVendor" [allowClear]="true" (removed)="removedVendor($event)" (selected)="selectedVendorId($event)"
						 [items]="vendorList" placeholder="{{ 'STN_PTN_RUNDOWN.SELECT_VENDOR' | translate }}" name="vendor" id="vendor">
						</ng-select>
					</div>
				</div>
			</div>
		</div>
		<!-- loading panel -->
		<div *ngIf="isLoadingData" class="row">
			<div class="alert alert-warning mx-auto px-3 py-1">
				<h6 class="mb-0">{{ "GENERAL.LOADING_DATA" | translate }}</h6>
			</div>
		</div>
		<!-- no data panel -->
		<div *ngIf="isNoDataToShow" class="row">
			<div class="alert alert-light-nodata mx-auto px-2 py-1 border-0">
				<h6 class="mb-0">{{ "GENERAL.NO_DATA_TO_SHOW" | translate }}</h6>
			</div>
		</div>
		<div class="my-2" [chart]="chart" [ngClass]="{'content-hide': isNoDataToShow, 'content-show': !isNoDataToShow}"></div>
	</div>
</div>

<!-- popup templates -->
<ng-template #configContent>
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="col-sm-2 text-muted">
			<i class="fa fa-tasks" aria-hidden="true"></i>
		</div>
		<div class="col-sm-10">
			<p class="m-0 ">Option 1</p>
		</div>
	</div>
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="changeNotificationNumber()">
		<div class="col-sm-2 text-muted">
			<i class="fa fa-bell-o" aria-hidden="true"></i>
		</div>
		<div class="col-sm-10">
			<p class="m-0 ">Option 2</p>
		</div>
	</div>
	<hr class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="signOutBtnClickEvent()">
		<div class="col-sm-2 text-muted">
			<i class="fa fa-sign-out" aria-hidden="true"></i>
		</div>
		<div class="col-sm-10">
			<p class="m-0 ">Option type - 1</p>
		</div>
	</div>
</ng-template>
<!-- modal templates -->