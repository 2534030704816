import { LocalStorage } from "../localstorage.service";
import { SessionUtil } from "../session-util";
import { UserToken } from "./user-token.interface";

export class UserVariable {

	//Constants
	private static USER_ID: number = 1;
	private static CLIENT_ID: number = 2;
	private static PROJECT_ID: number = 3;

	private static USER_ID_KEY: string = 'userId';
	private static CLIENT_ID_KEY: string = 'clientId';
	private static PROJECT_ID_KEY: string = 'projectId';
	private static USER_TOKEN:string = "userToken"

	constructor(
		public userId: string,
		public projectId?: string,
		public clientId?: string,
		public userClientId?: string,
		public isClient?: string,
		public preferences?: string
	) {

	}

	/*public static get userClientId(): string {
		return localStorage.getItem('userClientId');
	}

	public static set userClientId(value: string) {
		localStorage.setItem('userClientId', value);
	}*/

	public static get isClient(): string {
		return localStorage.getItem(LocalStorage.IS_CLIENT);
	}

	public static set isClient(value: string) {
		localStorage.setItem(LocalStorage.IS_CLIENT, value);
	}

	public static get preferences(): string {
		return localStorage.getItem(LocalStorage.PREFERENCES);
	}

	public static set preferences(value: string) {
		localStorage.setItem(LocalStorage.PREFERENCES, value);
	}

	public static get userId(): string {
		//return localStorage.getItem('userId');
		var tokenString = atob(this.getUserToken());
		var decoded = JSON.parse(tokenString);
		return decoded.userId;
	}

	public static set userId(value: string) {
		//localStorage.setItem('userId', value);
		UserVariable.setUserToken(UserVariable.USER_ID, value);
	}

	public static get projectId(): string {
		var tokenString = atob(this.getUserToken());
		var decoded = JSON.parse(tokenString);
		return decoded.projectId;
	}
	public static set projectId(value: string) {
		//localStorage.setItem('projectId', value);
		UserVariable.setUserToken(UserVariable.PROJECT_ID, value);
	}

	public static get clientId(): string {
		var tokenString = atob(this.getUserToken());
		var decoded = JSON.parse(tokenString);
		return decoded.clientId;
	}
	public static set clientId(value: string) {
		//localStorage.setItem('clientId', value);
		UserVariable.setUserToken(UserVariable.CLIENT_ID, value);
	}

	/**
	 * Get user token. If user token if null get the default token (id = 'main')
	 */
	public static getUserToken(): string | null {
		let userToken: string = null;
		var localStorageItems = null;
		try {
			localStorageItems = JSON.parse(localStorage.getItem(UserVariable.USER_TOKEN)) as Array<UserToken>;
		} catch (error) {
			//format of the local storage is invalid, so clean it.
			localStorage.removeItem(UserVariable.USER_TOKEN);
		}
		//console.log('local storage list is : ' + JSON.stringify(localStorageItems));
		if (localStorageItems != null) {
			let sessionId = sessionStorage.getItem(SessionUtil.KEY_ID);
			let index = localStorageItems.map(function (x: any) { return x.id; }).indexOf(sessionId);
			//console.log('index : ' + index);
			if (index != -1) {
				userToken = localStorageItems[index].token;
				//console.log(userToken);
			} else {
				userToken = localStorageItems[0].token;
				//console.log('given token id is not valid, get the default : ' + userToken);
			}
		} else {
			//console.log('local storage is empty');
		}

		return userToken;
	}

	private static getUserOwnTokenIndex(): any {
		let index = -1;
		var localStorageItems = JSON.parse(localStorage.getItem(UserVariable.USER_TOKEN)) as Array<UserToken>;
		if (localStorageItems != null) {
			index = localStorageItems.map(function (x: any) { return x.id; }).indexOf(SessionUtil.getSessionId());
		}

		return index;
	}

	private static setUserToken(type: number, value: string) {
		let userToken: any = this.getUserToken();//decoded value which can be user key or default key
		let allTokens: any = localStorage.getItem(UserVariable.USER_TOKEN);//plain text which can be null
		let tokenIndex = this.getUserOwnTokenIndex();

		if (userToken == null) {
			userToken = {
				[UserVariable.USER_ID_KEY]: null,
				[UserVariable.PROJECT_ID_KEY]: null,
				[UserVariable.CLIENT_ID_KEY]: null
			};
		} else {
			userToken= JSON.parse(atob(this.getUserToken()));
		}
		console.log(userToken);
		//update values
		switch (type) {
			case UserVariable.CLIENT_ID:
				userToken.clientId = value;
				break;
			case UserVariable.PROJECT_ID:
				userToken.projectId = value;
				break;
			case UserVariable.USER_ID:
				userToken.userId = value;
				break;
		}
		console.log(userToken);
		//calculate the index
		if (tokenIndex == -1) {
			if (allTokens == null) {
				allTokens = [];
				tokenIndex = 0;
			} else {
				allTokens = JSON.parse(allTokens) as Array<UserToken>;
				tokenIndex = allTokens.length;
			}
		} else {
			allTokens = JSON.parse(allTokens) as Array<UserToken>;
		}
		//tokenIndex = 0;
		console.log(tokenIndex);
		//now we have the modified user token
		allTokens[tokenIndex] = {id :SessionUtil.getSessionId(), token:btoa(JSON.stringify(userToken))};
		//save to the local storage
		localStorage.setItem(UserVariable.USER_TOKEN, JSON.stringify(allTokens));
	}
}