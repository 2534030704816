import { Component, OnInit, ViewChildren, QueryList, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Punchlist, PunchlistDocument } from 'src/app/widgets/punchlist.model'
import { PunchlistService } from 'src/app/widgets/punchlist.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocalStorage } from 'src/app/util/localstorage.service';
import { GridOptions } from "ag-grid";
import { FileUploadService } from '../../file-upload.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { WsCallback } from 'src/app/util/ws-callback.interface';
import { WsResponse } from 'src/app/util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommonUtil } from 'src/app/util/common/common-utils';
import { SharedService } from "../../../util/shared.service";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-addpunchlist',
  templateUrl: './addpunchlist.component.html',
  styleUrls: ['./addpunchlist.component.css']
})
export class AddpunchlistComponent implements OnInit, WsCallback {
  public punchlist: Punchlist = new Punchlist();
  public projectId: string = null;
  public userId: string = null;
  public token: string;

  public category: Array<any> = [];
  public item_status: Array<any> = [];
  public selectedCategory: any = "";
  public selectedItem_status: any = "";


  public assetList: Array<any> = [];
  public disciplineList: Array<any> = [];
  public vendorList: Array<any> = [];
  public subSystemList: Array<any> = [];
  public selectedAsset: Array<any> = null;

  public selectedAssetList = [];
  public initialValueList = [];

  public punchlistDisable: boolean = true;
  public assetDisable: boolean = true;
  public disciplineDisable: boolean = true;
  public subSystemDisable: boolean = true;
  public vendorDisable: boolean = true;

  public emptyFieldError: any;
  public statusError: any;
  public emptyAssetOrSystem: any;
  public emptyAsset: any;
  public emptyCloseDate: any;
  public invalidDate: any;
  public maxTotalSizeExceed: any;

  public gridOptions: GridOptions;
  public columnDefs: any;
  public rowData: any = [];
  private gridApi: any;
  private gridColumnApi: any;
  //public modules: Module[] = AllModules;

  private uploadingFile: File;
  private fileExtension: string;
  public uploadFileName: string;
  public uploadFileNameWithExt: string;
  public punchlistUploadDocuments: any = [];

  filesToUpload: Array<File> = [];

  public bsConfig: Partial<BsDatepickerConfig>

  onClose = new EventEmitter();
  public saveTxt: string;
  public saveAndNewTxt: string;
  public saveAndWFTxt: string;
  public saveDropDownBtnVal: string = "";

  private uploadingFile_Image: File;
  private fileExtension_Image: string;
  public uploadFileName_Image: string;
  public uploadFileNameWithExt_Image: string;
  public punchlistUploadImages: any = [];
  public fileSelTxt: string = "";
  private MAX_FILE_SIZE : any = 26214400; //25MB

  constructor(
    private punchlistService: PunchlistService,
    private alertService: AlertService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    public bsModalRef: BsModalRef,
    private commonUtil: CommonUtil,
    private sharedService: SharedService
  ) {

    translate.addLangs(["es", "en"]);
    translate.setDefaultLang("en");
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.translate.get('PUNCHLIST_ADD_EDIT.FIELD_EMPTY').subscribe((res: string) => {
      this.emptyFieldError = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.STATUS_ERROR').subscribe((res: string) => {
      this.statusError = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.CLOSE_DATE_EMPTY').subscribe((res: string) => {
      this.emptyCloseDate = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.ASSET_OR_SYSTEM_EMPTY').subscribe((res: string) => {
      this.emptyAssetOrSystem = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.ASSET_EMPTY').subscribe((res: string) => {
      this.emptyAsset = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.INVALID_DATE').subscribe((res: string) => {
      this.invalidDate = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.SAVE').subscribe((res: string) => {
      this.saveTxt  = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.SAVE_AND_NEW').subscribe((res: string) => {
      this.saveAndNewTxt = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.SAVE_AND_WF').subscribe((res: string) => {
      this.saveAndWFTxt = res;
    });
    this.translate.get('NEW_PUNCHLIST_IMAGE.NO_FILES').subscribe((res: string) => {
      this.fileSelTxt = res;
    });
    this.translate.get('PUNCHLIST_ADD_EDIT.MAX_ATTACHMENT_SIZE_').subscribe((res: string) => {
      this.maxTotalSizeExceed = res;
    });

    //set token
    this.token = UserVariable.getUserToken();

    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', customTodayClass: 'custom-today-class' });

    this.prepareAgGrid();
  }

  loadingMap: boolean = false;
  @ViewChild('mapContainer') gmap: ElementRef;
  map: google.maps.Map;
  lat = 0.000000;
  lng = 0.000000;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8
  };

  marker = new google.maps.Marker();

  //initialize google map
  mapInitializer() {
    console.log("lattitude:", this.lat);
    console.log("longtitude:", this.lng);
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.SATELLITE
    };
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);
    this.marker = new google.maps.Marker({
      position: this.coordinates,
      map: this.map,
    });
    this.marker.setMap(this.map);
  }

  ngOnInit() {
    this.category = [
      { 'id': '1', 'text': 'A' },
      { 'id': '2', 'text': 'B' },
      { 'id': '3', 'text': 'C' },
      { 'id': '4', 'text': 'D' }
    ];

    this.item_status = [
      { 'id': '1', 'text': 'Open' },
      { 'id': '2', 'text': 'Closed' },
      { 'id': '3', 'text': 'Initial' }
    ];

    //set project id and user id
    this.projectId = UserVariable.projectId;
    this.userId = localStorage.getItem(LocalStorage.USER_ID);

    //set default item status
    this.punchlist.selectedItemStatus = this.item_status.filter(x => x.text == 'Initial');
    this.punchlist.itemStatus = 'Initial';
    this.selectedItem_status = this.punchlist.selectedItemStatus[0].text;

    //get required details for add punchlist
    this.punchlistService.getAssetList(this);
    this.punchlistService.getSubSystemList(this);
    this.punchlistService.getDisciplineList(this.projectId, this);
    this.punchlistService.getVendorList(this.projectId, this);

    //set date raised to current date
    this.punchlist.dateraised = new Date();
    //set raised by
    let loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
    if (loggedInUser != null) {
      this.punchlist.raisedby = loggedInUser.firstName + " " + loggedInUser.lastName;
    } else {
      //do nothing
    }
    //set button text
    this.saveDropDownBtnVal = this.saveTxt;

    //get user's browser location
    this.getPunchlistLocation();

  }

  //get location
  getPunchlistLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lng = position.coords.longitude;
        this.lat = position.coords.latitude;
        //call google map initializer
        this.mapInitializer();
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  public selectedAssetId(value: any): void {
    console.log("Selected Asset Value: " + JSON.stringify(value));

    if (this.punchlist.assetId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.assetName = value.text;
      this.punchlist.assetId = value.id;
      this.punchlist.selectedAsset = this.assetList.filter(x => x.id == this.punchlist.assetId);
      this.punchlist.isSystemDisable = true;
      this.punchlist.parentId = null;
      this.punchlist.parentName = '';
    } else {
      //nothing to do
    }
  }

  selectedAssetDisplay(assetId) {
    console.log("selectedAssetDisplay() - assetId: " + assetId);

    // Set selected asset
    this.punchlist.selectedAsset = this.getAssetNameById(assetId);
    console.log("assetId " + JSON.stringify(this.punchlist.selectedAsset));

  }

  getAssetNameById(id) {
    return this.assetList.filter(x => (x.id === id));
  }


  categorySelected(event: any) {
    this.punchlist.category = event.text;
    this.selectedCategory = event["text"]
  }

  public selectedDisciplineId(value: any): void {
    console.log("Selected Discipline Value: " + JSON.stringify(value));

    if (this.punchlist.disciplineId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.disciplineName = value.text;
      this.punchlist.disciplineId = value.id;
      this.punchlist.selectedDiscipline = this.disciplineList.filter(x => x.id == this.punchlist.disciplineId);
    } else {
      //nothing to do
    }
  }

  //Remove the selected category
  public removeCategory(event: any) {
    this.punchlist.category = ""
    this.selectedCategory = ""
  }

  public removeDisciplineId(value: any): void {
    console.log("removeDisciplineId");

    this.punchlist.disciplineName = null;
    this.punchlist.disciplineId = null;
    this.punchlist.selectedDiscipline = null;
  }

  selectedtDisciplineDisplay(disciplineId) {
    console.log("selectedDisciplineDisplay() - disciplineId: " + disciplineId);

    // Set selected discipline
    this.punchlist.selectedDiscipline = this.getDisciplineNameById(disciplineId);
    console.log("disciplineId " + JSON.stringify(this.punchlist.selectedDiscipline));

  }

  getDisciplineNameById(id) {
    return this.disciplineList.filter(x => (x.id === id));
  }

  public selectedSubSystemId(value: any): void {
    console.log("Selected SubSystem Value: " + JSON.stringify(value));
    if (this.punchlist.subsystemId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.subSystemName = value.text;
      this.punchlist.subsystemId = value.id;
      this.punchlist.selectedSubSystem = this.subSystemList.filter(x => x.id == this.punchlist.subsystemId);
      if (this.punchlist.selectedSubSystem[0].parentNo != null && this.punchlist.selectedSubSystem[0].parentName != null && this.punchlist.selectedSubSystem[0].parentId != null) {
        this.punchlist.parentName = this.punchlist.selectedSubSystem[0].parentNo + ' ' + this.punchlist.selectedSubSystem[0].parentName;
        this.punchlist.parentId = this.punchlist.selectedSubSystem[0].parentId;
      }
      else {
        this.punchlist.parentName = '';
        this.punchlist.parentId = null;
      }
      this.punchlist.isAssetDisable = true;
      this.selectedAssetList = [];
    } else {
      //nothing to do
    }
  }

  selectedSubSystemDisplay(subsystemId) {
    console.log("selectedSubSystemDisplay() - subsystemId: " + subsystemId);

    // Set selected subsystem
    this.punchlist.selectedSubSystem = this.getSubSystemNameById(subsystemId);
    console.log("subsystemId " + JSON.stringify(this.punchlist.selectedSubSystem));

  }

  getSubSystemNameById(id) {
    return this.subSystemList.filter(x => (x.id === id));
  }

  public itemStatusSelected(event: any) {
    this.punchlist.itemStatus = event.text;
    this.punchlist.selectedItemStatus = event.text;
    this.selectedItem_status = event["text"]
  }

  public removedAsset(value: any): void {
    console.log("removeSelectedAsset");

    // Update asset details
    this.punchlist.assetName = null;
    this.punchlist.assetId = null;
    this.punchlist.selectedAsset = null;
    this.punchlist.isSystemDisable = false;

    console.log("Removed Asset Value: " + value);
  }

  public removedSubSystem(value: any): void {
    console.log("removeSelectedSubSystem");

    // Update asset details
    this.punchlist.subSystemName = null;
    this.punchlist.subsystemId = null;
    this.punchlist.selectedSubSystem = null;
    this.punchlist.isAssetDisable = false;
    this.punchlist.parentId = null;
    this.punchlist.parentName = '';

    console.log("Removed Asset Value: " + value);
  }

  IsValidDate(value: Date): boolean {
    if (value != undefined) {
      var date = new Date(value);
      if (date.toString() == 'Invalid Date') {
        return false;
      }
      else {
        return true;
      }
    } else {
      return true;
    }
  }

  public selectedVendorId(value: any): void {
    console.log("Selected Vendor Value: " + JSON.stringify(value));

    if (this.punchlist.vendorId != value.id) {
      this.punchlistDisable = true;
      this.punchlist.vendorName = value.text;
      this.punchlist.vendorId = value.id;
      this.punchlist.selectedVendor = this.vendorList.filter(x => x.id == this.punchlist.vendorId);
    } else {
      //nothing to do
    }
  }

  public removeVendorId(value: any): void {
    console.log("removeVendorId");

    this.punchlist.vendorName = null;
    this.punchlist.vendorId = null;
    this.punchlist.selectedVendor = null;
  }

  /**
   * add new punchlist
   */
  save() {
    //validate punchlist category for the undefined, null and empty
    if (this.punchlist.category == undefined || this.punchlist.category == null || this.punchlist.category == "") {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if (this.punchlist.disciplineId == undefined || this.punchlist.disciplineId == null) {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if (this.punchlist.punchlistDescription == undefined || this.punchlist.punchlistDescription.trim() == "") {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if (this.punchlist.dateraised == undefined || this.punchlist.dateraised == null) {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    }
    //Validate raisedby field for the undefined, null and empty
    else if (this.punchlist.raisedby == undefined || this.punchlist.raisedby == null || this.punchlist.raisedby == "") {
      this.alertService.clear();
      this.alertService.error(this.emptyFieldError);
    } else if ((this.selectedAssetList.length == 0) && (this.punchlist.subsystemId == undefined || this.punchlist.subsystemId == null)) {
      this.alertService.clear();
      this.alertService.error(this.emptyAssetOrSystem);
    } else if (!this.IsValidDate(this.punchlist.dateraised) || !this.IsValidDate(this.punchlist.closedDate) || !this.IsValidDate(this.punchlist.targetCompletionDate)) {
      this.alertService.clear();
      this.alertService.error(this.invalidDate);
    } else if (this.punchlist.closedDate != null && this.punchlist.closedDate != undefined && this.punchlist.itemStatus != "Closed") {
      this.alertService.clear();
      this.alertService.error(this.statusError);
    } else if (this.punchlist.itemStatus == "Closed" && (this.punchlist.closedDate == null || this.punchlist.closedDate == undefined)) {
      this.alertService.clear();
      this.alertService.error(this.emptyCloseDate);
    } else {

      //get selected asset id list
      let seletedAssetsIdList = [];
      this.selectedAssetList.forEach((item) => {
        seletedAssetsIdList.push(item.id);
      })


      let data = {
        id: null,
        punchlistDescription: this.punchlist.punchlistDescription,
        category: this.punchlist.category,
        dateRaised: this.punchlist.dateraised != undefined ? this.punchlist.dateraised : null,
        dateClosed: this.punchlist.closedDate != undefined ? this.punchlist.closedDate : null,
        estimatedCompletionDate: this.punchlist.targetCompletionDate != undefined ? this.punchlist.targetCompletionDate : null,
        raisedBy: this.punchlist.raisedby,
        estimatedManHours: this.punchlist.estimatedManHours,
        materialsRequired: this.punchlist.materialRequired,
        itemStatus: this.punchlist.itemStatus,
        comments: this.punchlist.comments,
        assetId: this.punchlist.assetId,
        disciplineId: this.punchlist.disciplineId,
        subSystemId: this.punchlist.subsystemId,
        systemId: this.punchlist.parentId != null ? this.punchlist.parentId : null,
        status: 'Active',
        projectId: this.projectId,
        punchlistDocuments: this.punchlist.punchlistDocuments,
        punchlistImages:this.punchlist.punchlistImages,
        vendorId: this.punchlist.vendorId,
        assetIds: seletedAssetsIdList.toString(),
        locationLat: this.lat,
        locationLong: this.lng,
        firstOilRequired: this.punchlist.firstOilRequired
      };

      var json = JSON.stringify(data);
      console.log(json);
      this.loadingService.showLoading(true, false, this.translate.instant('SAVING'), 0);
      this.punchlistService.addNewPunchlist(this.token, json, this);
    }
  }

  /**
   * save button clicked
   */
  saveClick() {
    //set button text
    this.saveDropDownBtnVal = this.saveTxt;

    if (this.IsMaxAttachmentSizeExceeded()) {
      this.alertService.clear();
      this.alertService.error(this.maxTotalSizeExceed);
    } else {
      //call save
      this.save()
    }
  }
  
  /**
   * save and new button clicked
   */
  saveAndNewClick() {
    //set button text
    this.saveDropDownBtnVal = this.saveAndNewTxt;
    if (this.IsMaxAttachmentSizeExceeded()) {
      this.alertService.clear();
      this.alertService.error(this.maxTotalSizeExceed);
    } else {
      //call save
      this.save()
    }
  }

  /**
   * save and workflow button clicked
   */
  saveAndWorkflowClick() {
    //set button text
    this.saveDropDownBtnVal = this.saveAndWFTxt;
    if (this.IsMaxAttachmentSizeExceededForSaveAndWorkflow()) {
      this.alertService.clear();
      this.alertService.error(this.maxTotalSizeExceed);
    } else {
      //call save
      this.save()
    }
  }


  cancel() {
    this.onClose.emit(true);
    this.bsModalRef.hide()
  }

  /**
   * Get file from local storage
   * @param event 
   */
  onFileChanged(event) {
    console.log("onfile change= ")
    let i: number = 0;
    for (i = 0; i < event.target.files.length; i++) {
      this.uploadingFile = <File>event.target.files[i];
      if (this.uploadingFile != undefined) {
        let parts = this.uploadingFile.name.split('.')
        if (parts.length > 1) {
          this.fileExtension = parts.pop()
          this.uploadFileName = parts.join('.')
          var documentId = this.createUUID();

          if (this.validateForm()) {
            //set for inserting to the database
            var punchlistDoc = {
              id: documentId,
              punchlistId: null,
              fileName: this.uploadingFile.name,
              contentType: this.fileExtension,
              projectId: this.projectId,
              filePath: '',
              isAdded: true,
              isDeleted: false,
              addedUserId: '',
              fileSize:this.uploadingFile.size
            };
            this.punchlist.punchlistDocuments.push(punchlistDoc);

            //set for uploading to the local file system
            var punchlistUploadDoc =
            {
              docId: documentId,
              docName: this.uploadFileName,
              docType: this.fileExtension,
              file: this.uploadingFile,
              isAdded: true,
              isDeleted: false
            };
            this.punchlistUploadDocuments.push(punchlistUploadDoc);
          }
        } else {
          this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        this.uploadFileName = '';
      }
    }

    //refresh ag-grid 
    this.createRowData(this.punchlist.punchlistDocuments.filter(x => !x.isDeleted));
  }

  /**
   * Upload documents into local storage folder
   * @param punchlistId 
   */
  onUpload(punchlistId: string) {
    var loadingMessage;
    this.translate.get('NEW_PUNCHLIST_DOCUMENT.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    let token = UserVariable.getUserToken();
    this.loadingService.showLoading(true, null, loadingMessage, null);

    this.punchlistUploadDocuments.forEach(element => {
      if (element.isAdded && !element.isDeleted) {
        this.uploadFileNameWithExt = element.docName + "." + element.docType;
        const uploadData = new FormData();
        console.log("element.docId = ", element.docId);
        console.log("this.punchlist.id = ", punchlistId);
        uploadData.append("docId", element.docId);
        uploadData.append("punchlistId", punchlistId);
        uploadData.append("projectId", this.projectId);
        uploadData.append("filePath", '');
        uploadData.append("fileName", this.uploadFileNameWithExt);
        uploadData.append("contentType", element.docType);
        uploadData.append("token", token);
        uploadData.append("file", element.file, element.docName);
        uploadData.append("addedUserId", '');
        uploadData.append("isAdded", element.isAdded);
        uploadData.append("isDeleted", element.isDeleted);

        this.fileUploadService.uploadFile(uploadData, this);
      }
    });
  }

  /**
   * ag-grid punchlist documents
   */
  private prepareAgGrid() {
    this.columnDefs = [
      {
        headerName: 'Document Id',
        field: 'docId',
        hide: true
      },
      {
        headerName: 'Document Name',
        field: 'docName',
        width: 500,
        cellClass: "cell-wrap-text",
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: 'Content Type',
        field: 'docType',
        width: 400,
        cellClass: "cell-wrap-text",
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: 'Delete',
        field: 'docDelete',
        width: 200,
        cellStyle: { 'text-align': 'center', "white-space": "normal" },
        cellClass: "cell-wrap-text",
        cellRenderer: function (params) {
          return ('<button type="button" [disabled]="false" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"(click)="deleteButtonClick($event)"><i class="far fa-trash-alt" aria-hidden="true"></i></button>');
        }
      }
    ];

    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: {
        //resizable: true,
        // sortable: true
      },
      rowSelection: "single",
      rowMultiSelectWithClick: false
    };

  }

  // methods for assets selection
  public requestAutocompleteItems = (text: string): Observable<any> => {
    return this.punchlistService.getAssetListSearch(text, this.initialValueList);
  }

  selectItemFromSuggestions() {
    if (this.selectedAssetList.length > 0) {
      this.punchlist.isSystemDisable = true;
    } else {
      this.punchlist.isSystemDisable = false;
    }
  }

  removeSelectedItem() {
    if (this.selectedAssetList.length > 0) {
      this.punchlist.isSystemDisable = true;
    } else {
      this.punchlist.isSystemDisable = false;
    }
  }

  /**
   * Initializing data for the punchlist documents ag-grid
   * @param documents 
   */
  private createRowData(documents: Array<PunchlistDocument>) {
    var rowData: any[] = [];
    documents.forEach(punchlistDoc => {
      rowData.push({
        docId: punchlistDoc.id,
        docName: punchlistDoc.fileName,
        docType: punchlistDoc.contentType
      });
    })

    this.rowData = rowData;
    this.prepareAgGrid();
  }

  /**
   * Delete punchlist document
   * @param event 
   */
  public onDelete(event) {
    if (event.colDef.field == "docDelete") {
      var selectedRowData = this.gridApi.getSelectedRows();
      this.gridApi.updateRowData({ remove: selectedRowData });
      var docId = selectedRowData[0].docId;
      this.punchlist.punchlistDocuments.forEach(doc => {
        if (doc.id == docId) {
          doc.isDeleted = true;
        }
      });
      this.punchlistUploadDocuments.forEach(doc => {
        if (doc.docId == docId) {
          doc.isDeleted = true;
        }
      });
      this.createRowData(this.punchlist.punchlistDocuments.filter(x => !x.isDeleted));
    }
  }

  onGridReady(params: any) {
    console.log("ongrid ready")
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay()
  }

  /**
   * Generate unique identifier
   */
  public createUUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  validateForm(): boolean {
    this.uploadFileName = this.uploadFileName.trim()
    if (this.uploadingFile.size > this.MAX_FILE_SIZE) {
      this.translate.get('NEW_PUNCHLIST_DOCUMENT.SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    if (this.uploadingFile.type != 'application/pdf') {
      this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_TYPE').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    if (this.uploadingFile.name.length > 100) {
      this.translate.get('NEW_PUNCHLIST_DOCUMENT.FILE_NAME_SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }

    return true;
  }

  /**
   * Get file from local storage
   * @param event 
   */
   onFileChanged_Image(event) {
    console.log("onfile change image= ")

   let fileCount = event.target.files.length;
    let i: number = 0;
    for (i = 0; i < fileCount; i++) {
      this.uploadingFile_Image = <File>event.target.files[i];
      if (this.uploadingFile_Image != undefined) {
        let parts = this.uploadingFile_Image.name.split('.')
        if (parts.length > 1) {
          this.fileExtension_Image = parts.pop()
          this.uploadFileName_Image = parts.join('.')
          let imageId = this.createUUID();

          if (this.validateForm_Image()) {
            //set for inserting to the database
            let punchlistImage = {
              id: imageId,
              punchlistId: null,
              fileName: this.uploadingFile_Image.name,
              contentType: this.fileExtension_Image,
              projectId: this.projectId,
              filePath: '',
              isAdded: true,
              isDeleted: false,
              addedUserId: '',
              isNew:false,
              base64Image:'',
              fileSize:this.uploadingFile_Image.size
            };

            //show selected image preview
            let reader = new FileReader();
            reader.onload = (e: any) => {
              punchlistImage.base64Image = e.target.result;
              this.punchlist.punchlistImages.push(punchlistImage);
            }
            reader.readAsDataURL(this.uploadingFile_Image);

            //set for uploading to the local file system
            let punchlistUploadImage =
            {
              fileId: imageId,
              fileName: this.uploadFileName_Image,
              fileType: this.fileExtension_Image,
              file: this.uploadingFile_Image,
              isAdded: true,
              isDeleted: false
            };
            this.punchlistUploadImages.push(punchlistUploadImage);
          }
        } else {
          this.translate.get('NEW_PUNCHLIST_DOCUMENT.INVALID_FILE_NAME').subscribe((res: string) => {
            this.alertService.error(res, false);
          });
        }
      } else {
        this.uploadFileName_Image = '';
      }
    }

    //update label text
    if (fileCount == 0) {
      this.translate.get('NEW_PUNCHLIST_IMAGE.NO_FILES').subscribe((res: string) => {
        this.fileSelTxt = res;
      });
    } else if (fileCount == 1) {
      this.fileSelTxt = this.uploadingFile_Image.name;
    } else {
      this.translate.get('NEW_PUNCHLIST_IMAGE.FILES_SELECTED').subscribe((res: string) => {
        this.fileSelTxt = fileCount + res;
      });
    }
  }

  validateForm_Image(): boolean {
    this.uploadFileName_Image = this.uploadFileName_Image.trim()
    if (this.uploadingFile_Image.type == 'image/png' || this.uploadingFile_Image.type == 'image/jpg' || this.uploadingFile_Image.type == 'image/jpeg' || this.uploadingFile_Image.type == 'image/gif') {
      //correct type
    } else {
      this.translate.get('NEW_PUNCHLIST_IMAGE.INVALID_FILE_TYPE').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }
    if (this.uploadingFile_Image.name.length > 100) {
      this.translate.get('NEW_PUNCHLIST_IMAGE.FILE_NAME_SIZE_LIMIT_EXCEEDED').subscribe((res: string) => {
        this.alertService.error(res, false);
      });
      return false;
    }
    return true;
  }

  onUpload_Images(punchlistId: string) {
    var loadingMessage;
    this.translate.get('NEW_PUNCHLIST_IMAGE.LOADING_MESSAGE').subscribe((res: string) => {
      loadingMessage = res;
    });
    let token = UserVariable.getUserToken();
    this.loadingService.showLoading(true, null, loadingMessage, null);

    this.punchlistUploadImages.forEach(element => {
      if (element.isAdded && !element.isDeleted) {
        this.uploadFileNameWithExt_Image = element.fileName + "." + element.fileType;
        const uploadData = new FormData();
        console.log("element.fileId = ", element.fileId);
        console.log("this.punchlist.id = ", punchlistId);
        uploadData.append("fileId", element.fileId);
        uploadData.append("punchlistId", punchlistId);
        uploadData.append("projectId", this.projectId);
        uploadData.append("filePath", '');
        uploadData.append("fileName", this.uploadFileNameWithExt_Image);
        uploadData.append("contentType", element.fileType);
        uploadData.append("token", token);
        uploadData.append("file", element.file, element.fileName);
        uploadData.append("addedUserId", '');
        uploadData.append("isAdded", element.isAdded);
        uploadData.append("isDeleted", element.isDeleted);

        this.fileUploadService.uploadFile_Images(uploadData, this);
      }
    });
  }

  removeImage(imageId) {
    this.punchlist.punchlistImages.forEach(image => {
      if (image.id == imageId) {
        image.isDeleted = true;
      }
    });
    this.punchlistUploadImages.forEach(image => {
      if (image.fileId == imageId) {
        image.isDeleted = true;
      }
    });
  }

  openImage(data) {
    var image = new Image();
    if (data) {
        //set source
        image.src = data.base64Image;

        var w = window.open("");
        w.document.write(image.outerHTML);
        w.document.title = data.fileName;
        w.document.close();
    } else {
      //empty
    }
  }

  //check total attachment size exceed 25MB
  IsMaxAttachmentSizeExceeded() {
    var isExceeded = false;
    var totalSize = 0;

    //get document sizes
    this.punchlist.punchlistDocuments.forEach(element => {
      if (!element.isDeleted) {
        totalSize += element.fileSize;
      } else {
        //deleted
      }
    });
    
    //get image sizes
    this.punchlist.punchlistImages.forEach(element => {
      if (!element.isDeleted) {
        totalSize += element.fileSize;
      } else {
        //deleted
      }
    });

    if (totalSize > this.MAX_FILE_SIZE) {
      isExceeded = true;
    } else {
      //false
    }

    return isExceeded;
  }

   //check total attachment size exceed 25MB
   IsMaxAttachmentSizeExceededForSaveAndWorkflow() {
    var isExceeded = false;
    var totalSize = 0;

    //get document sizes
    this.punchlist.punchlistDocuments.forEach(element => {
      if (!element.isDeleted) {
        totalSize += element.fileSize;
      } else {
        //deleted
      }
    });
    
    //get image sizes
    this.punchlist.punchlistImages.forEach(element => {
      if (!element.isDeleted) {
        totalSize += element.fileSize;
      } else {
        //deleted
      }
    });

    //get the total file size for all the assets
    if(this.selectedAssetList.length > 1){
      totalSize *= this.selectedAssetList.length
    }

    if (totalSize > this.MAX_FILE_SIZE) {
      isExceeded = true;
    } else {
      //false
    }

    return isExceeded;
  }

  onSuccess(data: WsResponse, serviceType: WsType) {
    if (serviceType == WsType.ADD_NEW_PUNCHLIST) {
      //Upload documents
      this.onUpload(data.payload);
      //Upload images
      this.onUpload_Images(data.payload);
      this.loadingService.hideLoading();
      this.alertService.success("Successfully added the punchlist");

      //update punchlist grid
      this.punchlistService.loadPLTableDataRequest();

      if (this.saveDropDownBtnVal == this.saveTxt) {
        //close modal
        this.cancel();
      }
      else if (this.saveDropDownBtnVal == this.saveAndNewTxt) {
        //don't close the modal 
      } else {
        //close the modal and open workflow
        this.cancel();
        this.sharedService.setAddedPunchlists( data.payload); //set the generated punchlists to access from the workflow
      }
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_DOCUMENT) {

    }
    else if (serviceType == WsType.GET_ASSETS) {
      this.loadingService.hideLoading()
      this.assetList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.assetList.push({ 'id': element.tagId, 'text': element.tagNo });
        });
        this.assetDisable = false;
      }
    }
    else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      this.subSystemList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.subSystemList.push({ 'id': element.id, 'text': element.systemNo + ' ' + element.systemName, 'parentId': element.parentId, 'parentName': element.parentSystemName, 'parentNo': element.parentSystemNo });
        });
        this.subSystemDisable = false;
      }
    }
    else if (serviceType == WsType.GET_DISCIPLINES) {
      this.disciplineList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.disciplineList.push({ 'id': element.disciplineId, 'text': element.disciplineName });
        });
        this.disciplineDisable = false;
      }
    }
    else if (serviceType == WsType.GET_VENDOR_LIST) {
      this.vendorList = [];
      if (data.payload != null) {
        data.payload.forEach(element => {
          this.vendorList.push({ 'id': element.vendorId, 'text': element.vendorName });
        });
        this.vendorDisable = false;
      }
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_IMAGE) {

    }
  }

  onFail(data: WsResponse, serviceType: WsType) {
    this.loadingService.hideLoading();
    if (serviceType == WsType.ADD_NEW_PUNCHLIST) {
      this.alertService.error(data.statusDescription);
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_DOCUMENT) {
      this.alertService.error(data.statusDescription);
    }
    else if (serviceType == WsType.GET_ASSETS) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_SYSTEMS_BY_PROJECT_ID) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_DISCIPLINES) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.GET_VENDOR_LIST) {
      this.alertService.clear()
      this.alertService.error(data.statusDescription)
    }
    else if (serviceType == WsType.ADD_NEW_PUNCHLIST_IMAGE) {
      this.alertService.error(data.statusDescription);
    }
  }

  checkFirstOilRequired(event: any) {
    if (event == true) {
      this.punchlist.firstOilRequired = true;
    }
    else {
      this.punchlist.firstOilRequired = false;
    }
  }
}

