<div *ngIf="!disable">
    <button id="landing-table-buttons" class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0"
        (click)="invoke(type, data)" [disabled]="approved ? 'disabled': null">
        <i class="{{icon}}" aria-hidden="true"></i>
    </button>
</div>
<div *ngIf="disable">
    <a class="btn btn-sm btn-outline-dark py-0 my-1 px-1 border-0" (click)="invoke(type, data)">
        <i class="text-black-50 {{icon}}" aria-hidden="true"></i>
    </a>
</div>

<ng-template #revokeOTNModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Revoke confirm</h4>
    </div>
    <div class="modal-body pb-0">
        <h5>{{otnDeleteMsg}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary" (click)="revokeDeleteOTN('revoke')">
            {{ 'OTN_ADD_EDIT.OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="cancelDelteOTN()">
            {{ 'OTN_ADD_EDIT.CANCEL' | translate}} </button>
    </div>
</ng-template>

<ng-template #confirmation_warn>
    <div class="modal-header">
        <h4 *ngIf="type == 'delete' && status == 'NotIssued'" class="modal-title pull-left">{{'OTN_CELL_RENDER.DELETE_CONFIRM' | translate}}</h4>
        <h4 *ngIf="type == 'delete' && status != 'NotIssued'" class="modal-title pull-left">{{'OTN_CELL_RENDER.REVOKE_CONFIRM' | translate}}</h4>
        <h4 *ngIf="type == 'edit'" class="modal-title pull-left">{{'OTN_CELL_RENDER.EDIT_WARN_CONFIRM' | translate}}
        </h4>
    </div>
    <div *ngIf="displayMCCWarn" class="modal-body pb-0">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>This OTN is referenced by</h5>
        <div *ngIf="mccList.length != undefined">
            <ul>
                <div *ngFor="let mcc of mccList">
                    <li>
                        {{mcc.status}} {{mcc.systemName}}
                    </li>
                </div>
            </ul>
        </div>
    </div>
    <div *ngIf="displayPunchlistWarn && type == 'delete'" class="modal-body">
        <h5 class='warn-text-editor'>{{ 'WARNING' | translate}}</h5>
        <h5>{{'OTN_CELL_RENDER.PUNCHLIST_WARN' | translate}}</h5>
    </div>
    <div class="modal-footer">
        <button type="button" id="warn_confirm" class="btn btn-primary"
            (click)="warnModalRef.hide(); warningConfirm();">
            {{ 'OTN_ADD_EDIT.OK' | translate}} </button>
        <button type="button" id="warn_cancel" class="btn btn-secondary" data-dismiss="modal"
            (click)="warnModalRef.hide()">
            {{ 'OTN_ADD_EDIT.CANCEL' | translate}} </button>
    </div>
</ng-template>

<ng-template #deleteConfirmModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Confirmation </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <h5>Are you sure you want to delete this?</h5>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="revokeDeleteOTN('delete')">
            Yes </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="cancelDelteOTN()">
            {{ 'OTN_ADD_EDIT.CANCEL' | translate}} </button>
    </div>
</ng-template>