import { Component, OnInit, ViewChild } from '@angular/core';
import { ChecksheetsWidgetService } from './checksheets-widget.service'
import { AlertService } from '../../util/alert/alert.service';
import { SystemService } from '../system.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-checksheets-widget',
	templateUrl: './checksheets-widget.component.html',
	styleUrls: ['./checksheets-widget.component.css']
})
export class ChecksheetsWidgetComponent implements OnInit {

	public isMinimized: boolean = false;
	public isLoadingData: boolean = false;
	public isNoDataToShow: boolean = false;
	public showCSDetail: boolean = false;
	private selectedPoint: any = null
	public systemList: Array<any> = []
	public selectedSystem: Array<any> = [{ 'id': 'ALL', 'text': 'ALL' }]
	public y_axis_text: string = ''
	public x_axis_text: string = ''
	isModalShown: boolean = false;
	public chart: Chart
	iconClicked: boolean = false;
	private xAxisList: string[] = []
	private yAxisMax: number = 0
	public plotLineValue: number = 0;
	private translatedData: any = null;
	public isShowLabel: boolean = true;
	public systemId: string = 'ALL';
	public subSystemCheckbox: boolean = null;
	showSubSystems: string = "false";
	public showFilterOptions: boolean = false;
	public vendorList: string[] = []
	public checksheetsData: any = []
	public green: string = "#388E3C";
	public yellow: string = "#FBC02D";
	public red: string = "#D32F2F";
	@ViewChild('autoShownModal') autoShownModal: ModalDirective;
	message: string;
	vendorId: string;
	public isSubSystem: boolean = false;

	constructor(private ChecksheetService: ChecksheetsWidgetService, private alertService: AlertService, private systemService: SystemService, private translate: TranslateService) {
		let browserLang = translate.getBrowserLang()
		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translatedData = res['CHECKSHEETS']
			this.systemService.getAllSystemList().subscribe(
				data => {

					this.systemList = []
					this.systemList.push({ 'id': 'ALL', 'text': 'ALL' })
					if (data.payload != null) {
						data.payload.forEach(element => {
							this.systemList.push({ 'id': element.id, 'text': element.systemNo + ' - ' + element.systemName })
						});
					} else {
						// do nothing
					}
				},
				error => {
					console.log('[getAllSystemList] (error) ' + JSON.stringify(error.statusDescription));
					this.alertService.error(error.statusDescription)
				}
			)
			this.systemId = "ALL";
			this.checksheetsData = []
			this.getChecksheets()
		})

	}

	getChecksheets() {
		this.initChart()
		this.ChecksheetService.getChecksheetsSkyline(this.systemId, this.isSubSystem.toString()).subscribe(
			data => {
				this.removeSerie();
				console.log(JSON.stringify(data.payload))
				if (data.payload != null) {
					if (data.payload.length != 0) {
						let issuedList: any = [];
						let notIssuedList: any = [];
						let approvedList: any = [];
						this.vendorList.length = 0
						console.log("getChecksheets(): vendorList " + this.vendorList)
						data.payload.forEach(element => {
							this.vendorList.push(element.vendorLabel)
							let valueList: any = [];
							let issuedCount;
							let notIssuedCount;
							let approvedCount;
							if (element.issuedCountA == 0) {
								issuedCount = null
							} else {
								issuedCount = element.issuedCountA
							}
							if (element.notIssuedCountA == 0) {
								notIssuedCount = null
							} else {
								notIssuedCount = element.notIssuedCountA
							}
							if (element.approvedCountA == 0) {
								approvedCount = null
							} else {
								approvedCount = element.approvedCountA
							}
							valueList.push([issuedCount, notIssuedCount, approvedCount])
							issuedList.push(issuedCount)
							notIssuedList.push(notIssuedCount)
							approvedList.push(approvedCount)
							this.checksheetsData = valueList;
						});
						console.log("getChecksheets(): vendorList " + this.vendorList)
						console.log(issuedList)
						this.addSeriesToChart(issuedList, "Issued", this.yellow);
						this.addSeriesToChart(notIssuedList, "Not Issued", this.red);
						this.addSeriesToChart(approvedList, "Approved", this.green);


					} else {
						// do nothing no remaining items
					}
					if (this.checksheetsData.length == 0) {
						//do not have data to show
						this.isLoadingData = false
						this.isNoDataToShow = true
					} else {
						// this.removeSerie()
						//this.addSeriesToChart(checksheetsDataNew)
					}
				} else {
					//do not have data to show
					this.isLoadingData = false
					this.isNoDataToShow = true
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			}
		)
	}

	ngOnInit() {

	}

	public addSeriesToChart(executedChecksheetsSet: any, seriesName?, seriesColor?) {

		this.chart.addSerie(
			{
				name: seriesName, //"checksheets-checksheets",
				// this.translatedData.NUM_OF_PROCEDURES,
				color: seriesColor, //'#1E88E5',
				marker: {
					symbol: 'dot'
				},
				lineWidth: 2,
				stack: true,
				data: executedChecksheetsSet
			})
		this.isLoadingData = false
	}

	public removeSerie() {
		let num = this.chart.ref.series.length
		// console.log('[removeSerie] num ' + JSON.stringify(num));
		for (let i = 0; i < num; i++) {
			this.chart.removeSerie(0);
		}
	}

	public refresh() {
		this.systemId = 'ALL'
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.isSubSystem = false;
		this.resetGraph()
		this.getSkylineDataAndLoadGraph('ALL')
		this.iconClicked = false
	}

	private resetGraph() {
		this.removeSerie()
		this.showCSDetail = false
		this.selectedPoint = null
		this.vendorList.length = 0
	}

	private getSkylineDataAndLoadGraph(systemId: string) {
		this.isLoadingData = true
		this.isNoDataToShow = false
		this.checksheetsData = []
		this.systemId = systemId
		this.getChecksheets()
	}

	public initChart() {
		this.chart = new Chart({
			chart: {
				type: 'column'
			},
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			xAxis: {
				type: 'string',
				crosshair: true,
				categories: this.vendorList
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Count'
				},
			},
			tooltip: {
				headerFormat: '<span style="font-size:10px">{point.key}</span><table>'

			},
			plotOptions: {
				column: {
					// stacking: 'normal',
					dataLabels: {
						enabled: true,
						formatter: function () {
							if (this.y > 0)
								return this.y;
						}
					},
					pointPadding: 0.1,
					maxPointWidth: 50,
					borderWidth: 0,
					minPointLength: 3
				},

			},
			exporting: {
				chartOptions: {
					title: {
						text: this.translatedData.WIDGET_NAME
					},
					legend: {
						borderWidth: 0
					}
				}
			}

		})
	}

	selectedSystemId(event) {
		this.systemId = event.id;
		this.resetGraph()
		this.getSkylineDataAndLoadGraph(this.systemId)
		this.iconClicked = false
	}

	removedSystem(event) {
		this.selectedSystem = [{ 'id': 'ALL', 'text': 'ALL' }]
		this.systemId = 'ALL'
		this.resetGraph()
		this.getSkylineDataAndLoadGraph('ALL')
	}

	showModal(): void {
		this.isModalShown = true;
	}


	checkboxFunction(event) {
		this.resetGraph()
		if (this.subSystemCheckbox = true) {
			this.showSubSystems = "true";
			this.getSkylineDataAndLoadGraph(this.systemId)
		} else {
			this.showSubSystems = "false";
			this.getSkylineDataAndLoadGraph(this.systemId)
		}
	}
}
