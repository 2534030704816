import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserVariable } from 'src/app/util/common/user-variable';
import { ServiceUrls } from 'src/app/util/service-urls';
import { WsResponse } from 'src/app/util/ws-response.model';

@Injectable({
    providedIn: 'root'
})
export class AdhocReportsWidgetService {

    constructor(
        private http: HttpClient
    ) { }

    getAllReportDesignsByModuleAndProjectId(module: string): Observable<WsResponse> {
        let token = UserVariable.getUserToken();
        const options = { params: new HttpParams().set("module", module) }

        return this.http.get(ServiceUrls.getAllReportDesignsByModuleAndProjectId(token), options).
            pipe(
                map(response => {
                    var modified = JSON.parse(JSON.stringify(response));
                    return new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                }),
                catchError(error => {
                    var modified = JSON.parse(JSON.stringify(error.error));
                    var res = new WsResponse(
                        modified.status.description,
                        modified.status.code,
                        modified.status.name,
                        modified.payload);
                    return throwError(res)
                })
            );
    }

    public downloadPDF(reportId: string, reportType: string) {
        let token = UserVariable.getUserToken();
        let userId = UserVariable.userId;
        let projectId = UserVariable.projectId;
        let clientId = UserVariable.clientId;
        
        return this.http.get(ServiceUrls.DOWNLOAD_FILE,
            {
                responseType: "blob", params: new HttpParams().set("projectId", projectId).set("documentId", reportId)
                    .set("mode", "ViewAdHocReport").set("type", reportType).set("userId", userId).set("clientId", clientId)
            });
	  }
}
