import { Component, OnInit } from '@angular/core';
import { TopicMapsInTheStoreWidgetService } from './topicmaps-in-the-store-widget.service';
import { ProceduresInTheStoreWidgetModel } from './topicmaps-in-the-store-widget.model';
import { LoggedUser } from '../../user/logged-user.model';
import { LocalStorage } from '../../util/localstorage.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import * as FileSaver from 'file-saver';
import { UserVariable } from '../../util/common/user-variable';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../util/shared.service';
import { GridOptions } from 'ag-grid';
import { FacilitiesCellRender } from './cell-render/facilitiescellrender.component';
import * as moment from 'moment';
import { CustomDateComponent } from 'src/app/custom-date-component.component';

@Component({
	selector: 'app-topicmaps-in-the-store-widget',
	templateUrl: './topicmaps-in-the-store-widget.component.html',
	styleUrls: ['./topicmaps-in-the-store-widget.component.css']
})
export class TopicMapsInTheStoreWidgetComponent implements OnInit {

	public isMinimized: boolean = false
	private gridApi;
	private gridColumnApi;
	public rowData = [];
	public procedureStoreLoadList: Array<ProceduresInTheStoreWidgetModel> = new Array<ProceduresInTheStoreWidgetModel>();
	//private loggedInUser: LoggedUser;
	public columnDefs;
	cd_translated_data: any = null;
	private projectId: any
	self: this;
	public gridOptions: GridOptions;
	public detailGridOptions: GridOptions;
	public getRowHeight: (params: any) => number;
	public detailCellRendererParams;
	folderPathData: any;
	public components: {
		[p: string]: any;
	  } = {
		agDateInput: CustomDateComponent,
	  };

	constructor(
		private topicMapsInTheStoreWidgetService: TopicMapsInTheStoreWidgetService,
		private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService) {

		this.gridOptions = {

			// getRowHeight: function (params) {
			// 	if (params.node && params.node.detail) {
			// 		var offset = 80;
			// 		var allDetailRowHeight = params.data.innerData.length * 28;
			// 		return allDetailRowHeight + offset;
			// 	} else {
			// 		return 60;
			// 	}
			// }
		}

		let browserLang = translate.getBrowserLang();

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cd_translated_data = res['TOPICMAPS_IN_THE_STORE']
			this.columnDefs = [
				{
					headerName: this.cd_translated_data.DOCUMENT,
					field: "document", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150, cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: this.cd_translated_data.FACILITIES,
					field: "facilities",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160,
					cellRendererFramework: FacilitiesCellRender,
					filter: 'agSetColumnFilter',
					keyCreator: function (params){
						var facilities = params.value;
						var filterkeys = [];
						for (var key in facilities) {
							filterkeys.push(facilities[key]);
						}
						return filterkeys;

					}
				},
				{
					headerName: this.cd_translated_data.VERSION,
					field: "version",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 90
				},
				{
					headerName: this.cd_translated_data.NOTES,
					field: "notes", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200
				},
				{
					headerName: this.cd_translated_data.AVAILABILITY,
					field: "availability",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100
				},
				{
					headerName: this.cd_translated_data.PUBLISHED_DATE,
					field: "publishedDate",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150,
					filter: "agDateColumnFilter",
					comparator: this.dateComparator,
					filterParams: {
						//browserDatePicker: true,
						cellRenderer: data => {
							return data.value != undefined ? moment(data.value).format("dd-mm-yyyy") : null;
						},
						comparator: function (filterLocalDateAtMidnight, cellValue) {
							var dateAsString = "";
							var dateTimeComponents = cellValue.split(" ");
							if (dateTimeComponents != undefined && dateTimeComponents.length > 0) {
								dateAsString = dateTimeComponents[0];
							}
							if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){

								dateAsString = dateAsString.replace(/-/g,'/');

								var dateParts = dateAsString.split('/');

								var cellDate = new Date(
								Number(dateParts[2]),
								Number(dateParts[0])-1,
								Number(dateParts[1])
								);


							} else {
								var cellDateTime = new Date(dateAsString)
								var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
							}
							if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
								return 0;
							}
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							}
							if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
						}
					}

				},
				{
					headerName: this.cd_translated_data.LOCATION,
					field: "location", filter: "agTextColumnFilter",
					autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300
				}
			];
		});

		translate.getTranslation(browserLang).subscribe((res: string) => {
			this.cd_translated_data = res['TOPICMAPS_IN_THE_STORE']
			this.detailCellRendererParams = {
				detailGridOptions: {
					context: {
						componentParent: this
					},
					columnDefs: [
						{
							headerName: this.cd_translated_data.DOCUMENT,
							field: "document", filter: "agTextColumnFilter",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 150
						},
						{
							headerName: this.cd_translated_data.FACILITIES,
							field: "facilities",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 160,
							cellRendererFramework: FacilitiesCellRender
						},
						{
							headerName: this.cd_translated_data.VERSION,
							field: "version",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 70
						},
						{
							headerName: this.cd_translated_data.NOTES,
							field: "notes", filter: "agTextColumnFilter",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 200
						},
						{
							headerName: this.cd_translated_data.AVAILABILITY,
							field: "availability",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 50
						},
						{
							headerName: this.cd_translated_data.PUBLISHED_DATE,
							field: "publishedDate",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100,
							filter: "agDateColumnFilter",
							comparator: this.dateComparator,
							filterParams: {
								browserDatePicker: true,
								cellRenderer: data => {
									return data.value != undefined ? moment(data.value).format("dd-mm-yyyy") : null;
								},
								comparator: function (filterLocalDateAtMidnight, cellValue) {
									var dateAsString = "";
									var dateTimeComponents = cellValue.split(" ");
									if (dateTimeComponents != undefined && dateTimeComponents.length > 0) {
										dateAsString = dateTimeComponents[0];
									}
									var cellDateTime = new Date(dateAsString)
									var cellDate = new Date(cellDateTime.getFullYear(), cellDateTime.getMonth(), cellDateTime.getDate())
									if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
										return 0;
									}
									if (cellDate < filterLocalDateAtMidnight) {
										return -1;
									}
									if (cellDate > filterLocalDateAtMidnight) {
										return 1;
									}
								}
							}

						},
						{
							headerName: this.cd_translated_data.LOCATION,
							field: "location", filter: "agTextColumnFilter",
							autoHeight: true, cellClass: "cell-wrap-text", minWidth: 300
						}
					],
					defaultColDef: { editable: true },
					onGridReady: function (params) {
						//params.api.setGridAutoHeight(true);
         				params.api.resetRowHeights();
					},
					enableColResize: true,
				},
				getDetailRowData: function (params) {
					let detailRowData = [];
					if (params.data.innerData.length != 0) {
						for (let i = 0; i < params.data.innerData.length; i++) {
							detailRowData.push({
								facilities: params.data.innerData[i]["facilityList"],
								document: params.data.innerData[i]["documentTitle"],
								version: params.data.innerData[i]["versionNo"],
								notes: params.data.innerData[i]["notes"],
								availability: params.data.innerData[i]["availability"],
								publishedDate: params.data.innerData[i]["publishedTime"],
								location: params.data.innerData[i]["location"],
							});
							params.successCallback(detailRowData);
						}
					} else {
						params.successCallback(params.data.innerData);
					}
				}
			};	
			// this.getRowHeight = function (params) {
			// 	if (params.node && params.node.detail) {
			// 		var offset = 80;
			// 		var allDetailRowHeight = params.data.innerData.length * 28;
			// 		return allDetailRowHeight + offset;
			// 	} else {
			// 		return 60;
			// 	}
			// };
		})

	}

	onColumnResized(event) {
		if (event.finished) {
			this.gridApi.resetRowHeights();
		}
	}

	dateComparator(date1, date2) {
		var date1AsString = "";
		var date2AsString = "";
		var date1TimeComponents = date1.split(" ");
		var date2TimeComponents = date2.split(" ");

		date1AsString = date1TimeComponents[0];
		date2AsString = date2TimeComponents[0];

		if(navigator.userAgent.toLowerCase().indexOf("firefox") > -1){
			if(date1AsString == undefined){
				var cellDate1 = new Date(0,0,0);
			}else {
				var dateAsString1 = date1AsString.replace(/-/g,'/');
				var dateParts1 = dateAsString1.split('/');
				var cellDate1 = new Date(
					Number(dateParts1[2]),
					Number(dateParts1[0]) - 1,
					Number(dateParts1[1])
					);
			}
			if(date2AsString == undefined){
				var cellDate2 = new Date(0,0,0);
			}else {
				var dateAsString2 = date2AsString.replace(/-/g,'/');
				var dateParts2 = dateAsString2.split('/');
				var cellDate2 = new Date(
					Number(dateParts2[2]),
					Number(dateParts2[0]) - 1,
					Number(dateParts2[1])
					);
			}

			var date1Number = cellDate1.getTime();
			var date2Number = cellDate2.getTime();
	} else {
			var date1Number = new Date(date1AsString).getTime();
			var date2Number = new Date(date2AsString).getTime();
	}

		// var date1Comp = date1.split(" ")
		// var date2Comp = date2.split(" ")

		// var date1date = new Date(date1Comp[0]);
		// var date2date = new Date(date2Comp[0]);

		// var date1String = date1date.getFullYear() + "-" + date1date.getMonth() + "-" + date1date.getDate() + " " + date1Comp[1];
		// var date2String = date2date.getFullYear() + "-" + date2date.getMonth() + "-" + date2date.getDate() + " " + date2Comp[1];

		// var date1Number = new Date(date1String).getTime();
		// var date2Number = new Date(date2String).getTime();

		if (!date1Number && !date2Number) {
			return 0;
		}
		if (!date1Number) {
			return -1;
		}
		if (!date2Number) {
			return 1;
		}
		return date1Number - date2Number;
	}

	ngOnInit() {
		this.columnDefs;
		//this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) as LoggedUser;
		this.projectId = localStorage.getItem(LocalStorage.PROJECT_ID)
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay()
		this.topicMapsInTheStoreWidgetService.getStoreLoad(this.projectId)
			.subscribe(
			data => {
				this.loadData(data.payload)
				setTimeout(() => {
					params.api.sizeColumnsToFit();
				}, 500);
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

		window.addEventListener("resize", function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	}

	loadData(payload: any) {

		this.rowData = [];
		this.gridApi.hideOverlay()
		this.gridApi.showLoadingOverlay()

		console.log(payload)
		if (payload != null) {
			for (let key in payload) {
				for (let j = 0; j < payload[key].length; j++) {
					if (payload[key][j]["availability"] == "1") {
						this.rowData.push({
							facilities: payload[key][j]["facilityList"],
							document: payload[key][j]["documentTitle"],
							version: payload[key][j]["versionNo"],
							notes: payload[key][j]["notes"],
							availability: payload[key][j]["availability"],
							publishedDate: payload[key][j]["publishedTime"],
							location: payload[key][j]["location"],
							innerData: payload[key]
						});
					}
				}
			}
		}
	}

	onCellClicked(event: any) { }

	public refresh() {
		this.gridApi.showLoadingOverlay()
		this.topicMapsInTheStoreWidgetService.getStoreLoad(this.projectId)
			.subscribe(
			data => {
				this.loadData(data.payload)
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
			});

	}

	onRowGroupOpened(params) {
		if (params.node.rowIndex === params.api.paginationProxy.bottomRowIndex) {
			this.gridApi.paginationGoToNextPage();
		}
	}

}
