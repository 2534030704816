import { Component, OnInit, TemplateRef, } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from '../header.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { LocalStorage } from '../../util/localstorage.service';

@Component({
  selector: 'app-header-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	bsModalRef: any;
	DashBData: any[];
	ActionsData: any[];
	DashBPos: any[];
	public searchParams: any = []
	user = {};
	placeholderString = 'Select timezone';
	timezone: string
	timezoneString: any;
	lang: any;
    public fontList: {}[] = [[10, "Small"], [12, "Normal"], [16, "Large"], [24, "Huge"]];
    font: any;
    fontForm: FormGroup;
    widgets: any;
    marked: any;
    widgetList: {}[];
    form: FormGroup;
    widgetName: any;
    status: any;
    

  constructor(private headerService: HeaderService, private modalService: BsModalService, public translate: TranslateService, private fb: FormBuilder, 
    private formBuilder: FormBuilder) { 

    // const controls = this.widgetList2.map(c => new FormControl(false));
    // controls[0].setValue(true); // Set the first checkbox to true (checked)

        
    this.form = this.formBuilder.group({
        widgetList: []
      });
  }

    ngOnInit() {
        let preferences = localStorage.getItem('preferences');
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Portal;
        let editorJson = (JSON.parse(json)).UserPreference.Editor;			
        let timeZone = innerJson.General.TimeZone
        let language = innerJson.General.Language
        let fontSize = editorJson.FontSize
        this.widgets = editorJson.Widgets
        this.widgetList = Object.entries(this.widgets).sort();
 
        this.timezoneString = timeZone
        this.lang = language
        this.font = fontSize

        this.fontForm = this.fb.group({
            fontControl: [this.font]
          });
        this.DashBData = [];
        for (let i = 0; i < innerJson.Dashboard.DashBPage.length; i++) {
            this.DashBData.push({
                DashBPos: innerJson.Dashboard.DashBPage[i]["DashBPos"],
                pageNo: innerJson.Dashboard.DashBPage[i]["pageNo"],
            });
        }
        this.ActionsData = [];
        for (let i = 0; i < innerJson.Actions.Action.length; i++) {
        this.ActionsData.push({
            name: innerJson.Actions.Action[i]["name"],
            table: innerJson.Actions.Action[i]["Table"],
        });
        }
        this.DashBPos = []
    }

	changeTimezone(timezone) {

        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Portal;
        innerJson.General.TimeZone = timezone
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Editor);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+userPreferenceObjStr+",\"Portal\":"+innerJsonObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);
    }

	changeLanguage(language) {

        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Portal;
        innerJson.General.Language = language
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Editor);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+userPreferenceObjStr+",\"Portal\":"+innerJsonObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);
    }

    changeFontSize(fontSize) {

        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Editor;
        innerJson.FontSize = +fontSize
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Portal);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+innerJsonObjStr+",\"Portal\":"+userPreferenceObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);

    }
    
    toggleVisibility(e){
        
        this.widgetName = e['0'];
        this.status = e['1'];
        let preferences = localStorage.getItem('preferences')
        var json = JSON.parse(preferences);
        var innerJson = (JSON.parse(json)).UserPreference.Editor;
        if ((e['1'] != null) || (e['1'] != undefined)){
            if (e['1'] == 0){
                innerJson.Widgets[this.widgetName] = 1
            } else {
                innerJson.Widgets[this.widgetName] = 0
            }
        
        const innerJsonObjStr = JSON.stringify(innerJson);
        const userPreferenceObjStr = JSON.stringify((JSON.parse(json)).UserPreference.Portal);
        const changeFontObj = "{\"UserPreference\":{\"Editor\":"+innerJsonObjStr+",\"Portal\":"+userPreferenceObjStr+"}}";
        const jsonObjStr = JSON.stringify(changeFontObj)
        localStorage.setItem(LocalStorage.PREFERENCES, jsonObjStr);
        }
    }     
}
