import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';

@Injectable()
export class WorkflowService {

	constructor(private http: HttpClient) {
	}

	getActiveWorkFlowsPerProjectByItemType(itemType: string): Observable<WsResponse> {
		console.log("WorkflowService.getActiveWorkFlowsPerProjectByItemType() == > ServiceURL= " + ServiceUrls.GET_DEFAULT_WORKFLOW_LIST)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token).set("workflowType", itemType), }

		return this.http.get(ServiceUrls.GET_DEFAULT_WORKFLOW_LIST, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	addWorkflowInstanceTaskRolemap(taskmap: any): Observable<WsResponse> {
		console.log("WorkflowService.addWorkflowInstanceTaskRolemap() == > ServiceURL= " + ServiceUrls.ADD_WORKFLOW_INSTANCE_TASK_MAP)

		return this.http.post(ServiceUrls.ADD_WORKFLOW_INSTANCE_TASK_MAP, JSON.stringify(taskmap)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	generateWorkflow(workflow: any): Observable<WsResponse> {
		console.log("WorkflowService.generateWorkflow() == > ServiceURL= " + ServiceUrls.GENERATE_WORKFLOW)

		return this.http.post(ServiceUrls.GENERATE_WORKFLOW, JSON.stringify(workflow)).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}	
	

}