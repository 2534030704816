<div class="my-login-page" data-gr-c-s-loaded="true">
  <section class="h-100">
     <div class="container h-100">
        <div class="justify-content-md-center h-100">
           <div class="card-wrapper">
              <div class="mt-5 mb-3">
                 <img src="../../../assets/images/mainimage.png" alt="" width="100%" height="auto">
              </div>
              <div class="card fat">
                 <div class="card-body">
                    <h4 class="card-title">{{ "KEY_CODE_VERIFICATION.TOPIC" | translate }}</h4>
                    <form method="POST" ngNativeValidate>
                       <div class="form-group">
                          <label>
                            <strong>{{ "KEY_CODE_VERIFICATION.TEXT_B" | translate }}</strong>
                            . {{ "KEY_CODE_VERIFICATION.TEXT_C" | translate }}</label>
                       </div>
                       <div class="form-group">
                          <label for="email">{{ "KEY_CODE_VERIFICATION.KEY_CODE" | translate }}</label>
                          <input id="keyCode" type="text" class="form-control" name="keyCode" value="" required="" autofocus="" [(ngModel)] = "keyCode">
                       </div>
                       <div class="form-group">
                           <alert></alert>
                       </div>
                       <div class="form-group mt-4 mb-3">
                          <button type="submit" class="btn btn-primary btn-block mb-3" (click)="verifyKeyCode()">
                              {{ "KEY_CODE_VERIFICATION.SUBMIT" | translate }}
                          </button>
                          <button  class="btn btn-secondary btn-block mt-3" (click)="cancelBtnClickEvent()">
                              {{ "KEY_CODE_VERIFICATION.CANCEL" | translate }}
                          </button>
                       </div>
                    </form>
                 </div>
              </div>
              <div class="footer">
                  {{ "ALL_RIGHTS" | translate }}
              </div>
           </div>
        </div>
     </div>
  </section>
</div>
