import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '../util/localstorage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionValidationService } from '../user/login/session-validation-request.service';
import { WsCallback } from '../util/ws-callback.interface';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { Openid } from '../user/login/openid.service';
import { RecentProject } from '../landing/recent-project.service';
import { LoggedUser } from '../user/logged-user.model';
import { UserVariable } from '../util/common/user-variable';
import { RouteUtil } from '../util/route-util';
@Component({
	selector: 'app-splash',
	templateUrl: './splash.component.html',
	styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit, WsCallback {
	private loggedInUser: LoggedUser;
	private TIMEOUT: number = 1000;

	constructor(private translate: TranslateService, private router: Router, public sessionValidationRequest: SessionValidationService, private openIdService: Openid,
		public recentProjectService: RecentProject, private route: ActivatedRoute) {

		//Load initial data
		if (localStorage.getItem(LocalStorage.GOOGLE_API_KEY) == null || localStorage.getItem(LocalStorage.GOOGLE_CLIENT_ID) == null ||
		  localStorage.getItem(LocalStorage.AUTH0_CONFIGURATIONS)) {
			openIdService.getGoogleAPIKeys(this);
			openIdService.getAuth0Configurations(this);
		} else {
			//check the login validity
			this.doLogin();
		}
	}

	private doLogin() {
		if (JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)) != null &&
			JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER)).authtoken != null) {
			//validate
			this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
			this.sessionValidationRequest.validateSession(this);
			// this.recentProjectService.getDefaultRecentProject(this.loggedInUser.userId.toString(), this)
		} else {
			RouteUtil.forward(this.router, 'login', true, this.route);
		}
	}

	ngOnInit() {}

	onSuccess(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.SESSION_VALIDATION) {
			this.route.paramMap.subscribe(params => {
				if (params.get('fwd') != null) {
					setTimeout(() => this.router.navigateByUrl(params.get('fwd')), this.TIMEOUT);
				} else {
					this.recentProjectService.getDefaultRecentProject(this.loggedInUser.userId.toString(), this);
				}
			});
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			//save data in the local storage
			var payload = JSON.parse(data.payload);
			localStorage.setItem(LocalStorage.GOOGLE_API_KEY, payload.key);
			localStorage.setItem(LocalStorage.GOOGLE_CLIENT_ID, payload.clientid);
			//Reload the page
			//window.location.reload();
			this.doLogin();
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			if (data.payload != null) {
				//There is a default project
				// localStorage.removeItem(LocalStorage.PROJECT_NAME);
				localStorage.setItem(LocalStorage.PROJECT_NAME, data.payload.projectName);
				UserVariable.projectId = data.payload.projectId;
				RouteUtil.forward(this.router, 'dashboard', true, this.route);
			} else {
				//There is no default project
				//setTimeout(() => this.router.navigateByUrl('landing'), this.TIMEOUT);
				RouteUtil.forward(this.router, 'landing', true, this.route);
			}
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			//save data in the local storage
			var payload = JSON.parse(data.payload);
			localStorage.setItem(LocalStorage.AUTH0_CONFIGURATIONS, JSON.stringify(payload));
			//Reload the page
			this.doLogin();
		}
	}
	onFail(data: WsResponse, serviceType: WsType) {
		if (serviceType == WsType.SESSION_VALIDATION) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		} else if (serviceType == WsType.GET_GOOGLE_KEYS) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		} else if (serviceType == WsType.SESSION_VALIDATION) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		} else if (serviceType == WsType.GET_DEFAULT_RECENT_PROJECT) {
			localStorage.removeItem(LocalStorage.PROJECT_NAME);
		} else if (serviceType == WsType.GET_AUTH0_CONFIGURATIONS) {
			RouteUtil.forward(this.router, 'login', true, this.route);
		}
	}
}
