import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { WorkflowService } from '../widgets/workflow.service'
import { ProjectService } from '../widgets/project.service'
import { AlertService } from '../util/alert/alert.service'
import { TranslateService } from '@ngx-translate/core'
import { SystemTurnoverNoticeService } from '../widgets/system-turnover-notice/system-turnover-notice.service'
import { MechanicalCompletionService } from '../widgets/mechanical-completion.service'
import { PrecommissioningCompletionService } from '../widgets/precommissioning-completion.service'
import { CommCompletionService } from '../widgets/comm-completion.service'
import { SystemService } from '../widgets/system.service'
import { NgxXml2jsonService } from 'ngx-xml2json'
import { LocalStorage } from '../util/localstorage.service';
import { LoggedUser } from '../user/logged-user.model';
import { AuthRequest } from '../user/login/auth-request.model';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingService } from '../util/loading/loading.service';
import { WorkflowApprovalService } from './workflow-approval.service';

@Component({
	selector: 'app-workflow-approval',
	templateUrl: './workflow-approval.component.html',
	styleUrls: ['./workflow-approval.component.css']
})
export class WorkflowApprovalComponent implements OnInit {

	@ViewChild('SignaturePad1') signaturePad1: SignaturePad
	@ViewChild('container1') public container1: ElementRef
	@ViewChild('signatureSection') signatureSection: ElementRef

	public authRequest: AuthRequest;
	private loggedInUser: LoggedUser;

	signaturePadOptions: Object = {}
	stnId: any = null
	mccId: any = null
	pccId: any = null
	cccId: any = null
	mode: any = null
	taskId: any = null
	taskStatus: any = null
	taskModel: any = null
	workflowModel: any = null
	clientId: any = null
	workflowUserId: any = null
	systemTurnoverNotice: any = null
	mechanicalCompletion: any = null
	precommissioningCompletion: any = null
	commCompletion: any = null
	targetCompletionDate: any = null
	system: any = {}
	show: boolean = false
	isSubSystem: boolean = false
	configData: any = null
	ch1: boolean = false
	ch2: boolean = false
	ch3: boolean = false
	ch4: boolean = false
	ch5: boolean = false
	ch6: boolean = false
	ch7: boolean = false
	ch8: boolean = false
	ch9: boolean = false
	ch10: boolean = false
	ch11: boolean = false
	ch12: boolean = false
	ch13: boolean = false
	ch14: boolean = false
	ch15: boolean = false
	ch16: boolean = false
	ch17: boolean = false
	ch18: boolean = false
	chRemItems: boolean = false
	comments: any = null
	openPunchlists: any[] = []
	workflowTasks: any[] = []
	size: number = 0
	signature: any = null
	approved: boolean = false
	approvalData: any[] = []
	signature1: any = null
	signature2: any = null
	signature3: any = null
	error: boolean = false
	procedureList: any[] = []
	systemNo: any = null

	constructor(private activatedRoute: ActivatedRoute,
		private workflowService: WorkflowService,
		private alertService: AlertService,
		private translate: TranslateService,
		private projectService: ProjectService,
		private systemTurnoverNoticeService: SystemTurnoverNoticeService,
		private mechanicalCompletionService: MechanicalCompletionService,
		private precommissioningCompletionService: PrecommissioningCompletionService,
		private commCompletionService: CommCompletionService,
		private systemService: SystemService,
		private ngxXml2jsonService: NgxXml2jsonService,
		private sanitizer: DomSanitizer,
		private loadingService: LoadingService,
		private workflowApprovalService: WorkflowApprovalService,
		private router: Router) {

		this.stnId = this.activatedRoute.snapshot.queryParams["stnId"];
		this.mccId = this.activatedRoute.snapshot.queryParams["mccId"];
		this.pccId = this.activatedRoute.snapshot.queryParams["pccId"];
		this.cccId = this.activatedRoute.snapshot.queryParams["cccId"];
		let encodedTask = atob(this.activatedRoute.snapshot.queryParams["taskId"]);
		this.taskId = encodedTask.substring(encodedTask.lastIndexOf("_") + 1)
		let key = encodedTask.substring(0, encodedTask.lastIndexOf("_"))
		if (key != "nbN3}sD7nt" || !Number(this.taskId)) {
			this.error = true
		}

		if (this.stnId) {
			this.mode = 'STN'
			// this.router.navigate(['login', { 'fwd': 'workflowapproval?stnId=' + this.stnId + "&taskId=" + this.taskId }])
		} else if (this.mccId) {
			this.mode = 'MCC'
		} else if (this.pccId) {
			this.mode = 'PCC'
		} else if (this.cccId) {
			this.mode = 'CCC'
		}

		this.loadingService.showLoading(false, false, '', 0)
		console.log('mode is ' + this.mode)
		if (this.mode && !this.error) {
			if (this.taskId) {
				this.workflowApprovalService.getWorkFlowTaskEntryByActivity(this.taskId, "Complete")
					.subscribe(
					data => {
						console.log("data found 11 " + JSON.stringify(data))
						if (data.payload) {
							if (data.payload.taskStatus) {
								this.taskStatus = data.payload.taskStatus
							}
						}
						console.log('task status ' + this.taskStatus)

						if (!this.taskStatus) {
							console.log('not completed')
							this.workflowApprovalService.getTaskById(this.taskId).subscribe(
								data => {
									console.log("data found 22 " + JSON.stringify(data))
									this.taskModel = data.payload
									console.log(this.taskModel.processInstanceId)
									if (this.taskModel.processInstanceId) {
										this.workflowApprovalService.getWorkFlowProcessEntryByProcessInstanceId(this.taskModel.processInstanceId)
											.subscribe(
											data => {
												console.log("data found 444" + JSON.stringify(data))
												if (data.payload.projectId) {
													this.workflowModel = data.payload
													this.workflowApprovalService.getClientId(this.workflowModel.projectId).subscribe(
														data => {
															console.log("data found 555" + JSON.stringify(data))
															this.clientId = data.payload
															this.workflowApprovalService.getWorkFlowUserId().subscribe(
																data => {
																	console.log("data found 666" + JSON.stringify(data))
																	this.workflowUserId = data.payload
																	this.workflowApprovalService.getWorkFlowInstanceTaskRoleMap(this.workflowModel.id)
																		.subscribe(
																		data => {
																			console.log("data found 10000*****" + JSON.stringify(data))
																			this.workflowTasks = []
																			if (data.payload) {
																				this.workflowTasks = data.payload
																				this.size = this.workflowTasks.length
																			}
																			console.log('approval data')
																			if (this.stnId) {
																				this.workflowApprovalService.getStn(this.stnId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																					.subscribe(
																					data => {
																						console.log("data found 7777" + JSON.stringify(data))
																						if (data.payload) {
																							if (data.payload.stnStatus == "Issued" || data.payload.ptnStatus == "Issued") {
																								this.systemTurnoverNotice = data.payload
																								this.targetCompletionDate = new Date(this.systemTurnoverNotice.issuedDate).toLocaleDateString();
																								this.workflowApprovalService.getSystem(this.systemTurnoverNotice.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																									.subscribe(
																									data => {
																										console.log("data found 8888" + JSON.stringify(data))
																										this.system = data.payload
																										if (this.system.parentId) {
																											this.isSubSystem = true
																											this.systemNo = this.system.parentSystemNo + '-' + this.system.systemNo
																										} else {
																											this.systemNo = this.system.systemNo
																										}
																										this.workflowApprovalService.getOpenPunchlistItems(this.systemTurnoverNotice.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																											.subscribe(
																											data => {
																												console.log("data found 9999" + JSON.stringify(data))
																												this.openPunchlists = []
																												if (data.payload) {
																													this.openPunchlists = data.payload
																												}
																												// this.openPunchlists.push({ category: 'aaa', punchlistDescription: 'ccc', systemName: 'ddd', assetName: 'ddd' })
																												this.workflowApprovalService.getSTNApprovalData(this.stnId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																													.subscribe(
																													data => {
																														console.log("data found 1111" + JSON.stringify(data))
																														this.approvalData = []
																														if (data.payload) {
																															this.approvalData = data.payload
																															this.approvalData.forEach(item => {
																																if (item.fieldValue) {
																																	this.systemTurnoverNoticeService.getSTNSignature(item.fieldValue)
																																		.subscribe(
																																		data => {
																																			if (item.fieldName == 'Signature1') {
																																				this.createImageFromBlob(data, 1)
																																			} else if (item.fieldName == 'Signature2') {
																																				this.createImageFromBlob(data, 2)
																																			} else if (item.fieldName == 'Signature3') {
																																				this.createImageFromBlob(data, 3)
																																			}
																																		},
																																		error => {
																																			this.loadingService.hideLoading()
																																			this.alertService.clear()
																																			this.alertService.error(error.statusDescription)
																																		});
																																}
																															})
																															this.loadingService.hideLoading()
																															this.setConfigData()
																														} else {
																															this.setConfigData()
																															this.loadingService.hideLoading()
																														}
																													},
																													error => {
																														this.loadingService.hideLoading()
																														this.alertService.clear()
																														this.alertService.error(error.statusDescription)
																													});

																											},
																											error => {
																												this.loadingService.hideLoading()
																												this.alertService.clear()
																												this.alertService.error(error.statusDescription)
																											});
																									},
																									error => {
																										this.loadingService.hideLoading()
																										this.loadingService.hideLoading()
																										this.alertService.clear()
																										this.alertService.error(error.statusDescription)
																									});
																							} else {
																								this.loadingService.hideLoading()
																								this.error = true
																							}
																						} else {
																							this.loadingService.hideLoading()
																							this.error = true
																						}
																					},
																					error => {
																						this.loadingService.hideLoading()
																						this.alertService.clear()
																						console.log('err ==== ++++ ' + JSON.stringify(error))
																						this.alertService.error(error.statusDescription)
																					});
																			} else if (this.mccId) {
																				this.workflowApprovalService.getMCC(this.mccId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																					.subscribe(
																					data => {
																						console.log("data found mccc 7777" + JSON.stringify(data))
																						if (data.payload) {
																							if (data.payload.mcstatus == "Issued") {
																								this.mechanicalCompletion = data.payload
																								this.targetCompletionDate = new Date(this.mechanicalCompletion.issuedDate).toLocaleDateString();

																								this.workflowApprovalService.getSystem(this.mechanicalCompletion.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																									.subscribe(
																									data => {
																										console.log("data found 8888" + JSON.stringify(data))
																										this.system = data.payload
																										if (this.system.parentId) {
																											this.isSubSystem = true
																											this.systemNo = this.system.parentSystemNo + '-' + this.system.systemNo
																										} else {
																											this.systemNo = this.system.systemNo
																										}
																										this.workflowApprovalService.getOpenPunchlistItems(this.mechanicalCompletion.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																											.subscribe(
																											data => {
																												console.log("data found 9999" + JSON.stringify(data))
																												this.openPunchlists = []
																												if (data.payload) {
																													this.openPunchlists = data.payload
																												}
																												this.workflowApprovalService.getApprovalData(this.mccId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																													.subscribe(
																													data => {
																														console.log("data found 1111" + JSON.stringify(data))
																														this.approvalData = []
																														if (data.payload) {
																															this.approvalData = data.payload
																															this.approvalData.forEach(item => {
																																if (item.fieldValue) {
																																	this.mechanicalCompletionService.getMCCSignature(item.fieldValue)
																																		.subscribe(
																																		data => {
																																			if (item.fieldName == 'Signature1') {
																																				this.createImageFromBlob(data, 1)
																																			} else if (item.fieldName == 'Signature2') {
																																				this.createImageFromBlob(data, 2)
																																			} else if (item.fieldName == 'Signature3') {
																																				this.createImageFromBlob(data, 3)
																																			} else if (item.fieldName == 'Signature4') {
																																				this.createImageFromBlob(data, 4)
																																			}
																																		},
																																		error => {
																																			this.loadingService.hideLoading()
																																			this.alertService.clear()
																																			this.alertService.error(error.statusDescription)
																																		});
																																}
																															})
																															this.loadingService.hideLoading()
																															this.setConfigData()
																														} else {
																															this.setConfigData()
																															this.loadingService.hideLoading()
																														}
																													},
																													error => {
																														this.loadingService.hideLoading()
																														this.alertService.clear()
																														this.alertService.error(error.statusDescription)
																													});
																											},
																											error => {
																												this.loadingService.hideLoading()
																												this.alertService.clear()
																												this.alertService.error(error.statusDescription)
																											});
																									},
																									error => {
																										this.loadingService.hideLoading()
																										this.alertService.clear()
																										this.alertService.error(error.statusDescription)
																									});
																							} else {
																								this.loadingService.hideLoading()
																								this.error = true
																								console.log('error 1')
																							}
																						} else {
																							this.loadingService.hideLoading()
																							this.error = true
																						}
																					},
																					error => {
																						this.loadingService.hideLoading()
																						this.alertService.clear()
																						this.alertService.error(error.statusDescription)
																					});
																			} else if (this.pccId) {
																				this.workflowApprovalService.getPCC(this.pccId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																					.subscribe(
																					data => {
																						console.log("data found pccc 7777" + JSON.stringify(data))
																						if (data.payload) {
																							if (data.payload.pcstatus == "Issued") {
																								this.precommissioningCompletion = data.payload
																								this.targetCompletionDate = new Date(this.precommissioningCompletion.issuedDate).toLocaleDateString();

																								this.workflowApprovalService.getSystem(this.precommissioningCompletion.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																									.subscribe(
																									data => {
																										console.log("data found 8888" + JSON.stringify(data))
																										this.system = data.payload
																										if (this.system.parentId) {
																											this.isSubSystem = true
																											this.systemNo = this.system.parentSystemNo + '-' + this.system.systemNo
																										} else {
																											this.systemNo = this.system.systemNo
																										}
																										this.workflowApprovalService.getOpenPunchlistItems(this.precommissioningCompletion.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																											.subscribe(
																											data => {
																												console.log("data found 9999" + JSON.stringify(data))
																												this.openPunchlists = []
																												if (data.payload) {
																													this.openPunchlists = data.payload
																												}
																												this.workflowApprovalService.getPCCApprovalData(this.pccId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																													.subscribe(
																													data => {
																														console.log("data found 1111" + JSON.stringify(data))
																														this.approvalData = []
																														if (data.payload) {
																															this.approvalData = data.payload
																															this.approvalData.forEach(item => {
																																if (item.fieldValue) {
																																	this.precommissioningCompletionService.getPCCSignature(item.fieldValue)
																																		.subscribe(
																																		data => {
																																			if (item.fieldName == 'Signature1') {
																																				this.createImageFromBlob(data, 1)
																																			} else if (item.fieldName == 'Signature2') {
																																				this.createImageFromBlob(data, 2)
																																			}
																																		},
																																		error => {
																																			this.loadingService.hideLoading()
																																			this.alertService.clear()
																																			this.alertService.error(error.statusDescription)
																																		});
																																}
																															})
																															this.loadingService.hideLoading()
																															this.setConfigData()
																														} else {
																															this.setConfigData()
																															this.loadingService.hideLoading()
																														}
																													},
																													error => {
																														this.loadingService.hideLoading()
																														this.alertService.clear()
																														this.alertService.error(error.statusDescription)
																													});
																											},
																											error => {
																												this.loadingService.hideLoading()
																												this.alertService.clear()
																												this.alertService.error(error.statusDescription)
																											});
																									},
																									error => {
																										this.loadingService.hideLoading()
																										this.alertService.clear()
																										this.alertService.error(error.statusDescription)
																									});
																							} else {
																								this.loadingService.hideLoading()
																								this.error = true
																								console.log('error 2')
																							}
																						} else {
																							this.loadingService.hideLoading()
																							this.error = true
																						}
																					},
																					error => {
																						this.loadingService.hideLoading()
																						this.alertService.clear()
																						this.alertService.error(error.statusDescription)
																					});
																			} else if (this.cccId) {
																				this.workflowApprovalService.getCCC(this.cccId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																					.subscribe(
																					data => {
																						console.log("data found 7777" + JSON.stringify(data))
																						if (data.payload) {
																							if (data.payload.ccstatus == "Issued") {
																								this.commCompletion = data.payload

																								this.targetCompletionDate = new Date(this.commCompletion.issuedDate).toLocaleDateString();
																								this.workflowApprovalService.getSystem(this.commCompletion.systemId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																									.subscribe(
																									data => {
																										console.log("data found 8888" + JSON.stringify(data))
																										this.system = data.payload
																										if (this.system.parentId) {
																											this.isSubSystem = true
																											this.systemNo = this.system.parentSystemNo + '-' + this.system.systemNo
																										} else {
																											this.systemNo = this.system.systemNo
																										}

																										this.workflowApprovalService.getCCCApprovalData(this.cccId, this.workflowModel.projectId, this.workflowUserId, this.clientId)
																											.subscribe(
																											data => {
																												console.log("data found 1111" + JSON.stringify(data))
																												this.approvalData = []
																												if (data.payload) {
																													this.approvalData = data.payload
																													this.approvalData.forEach(item => {
																														if (item.fieldValue) {
																															this.commCompletionService.getCCCSignature(item.fieldValue)
																																.subscribe(
																																data => {
																																	if (item.fieldName == 'Signature1') {
																																		this.createImageFromBlob(data, 1)
																																	} else if (item.fieldName == 'Signature2') {
																																		this.createImageFromBlob(data, 2)
																																	} else if (item.fieldName == 'Signature3') {
																																		this.createImageFromBlob(data, 3)
																																	}
																																},
																																error => {
																																	this.loadingService.hideLoading()
																																	this.alertService.clear()
																																	this.alertService.error(error.statusDescription)
																																});
																														}
																													})
																													this.loadingService.hideLoading()
																													this.setConfigData()
																												} else {
																													this.setConfigData()
																													this.loadingService.hideLoading()
																												}
																											},
																											error => {
																												this.loadingService.hideLoading()
																												this.alertService.clear()
																												this.alertService.error(error.statusDescription)
																											});
																									},
																									error => {
																										this.loadingService.hideLoading()
																										this.loadingService.hideLoading()
																										this.alertService.clear()
																										this.alertService.error(error.statusDescription)
																									});
																							} else {
																								this.loadingService.hideLoading()
																								this.error = true
																							}
																						} else {
																							this.loadingService.hideLoading()
																							this.error = true
																						}
																					},
																					error => {
																						this.loadingService.hideLoading()
																						this.alertService.clear()
																						console.log('err ==== ++++ ' + JSON.stringify(error))
																						this.alertService.error(error.statusDescription)
																					});
																			}
																			else {
																				this.loadingService.hideLoading()
																				this.error = true
																				console.log('error 3')
																			}
																		},
																		error => {
																			this.loadingService.hideLoading()
																			this.alertService.clear()
																			this.alertService.error(error.statusDescription)
																		});
																},
																error => {
																	this.loadingService.hideLoading()
																	this.alertService.clear()
																	this.alertService.error(error.statusDescription)
																});
														},
														error => {
															this.loadingService.hideLoading()
															this.alertService.clear()
															this.alertService.error(error.statusDescription)
														});
												} else {
													this.loadingService.hideLoading()
													this.error = true
													console.log('error 4')
												}
											},
											error => {
												this.loadingService.hideLoading()
												this.alertService.clear()
												this.alertService.error(error.statusDescription)
											});
									} else {
										this.loadingService.hideLoading()
										this.error = true
										console.log('error 5')
									}
								},
								error => {
									this.loadingService.hideLoading()
									this.alertService.clear()
									this.alertService.error(error.statusDescription)
								});
						} else {
							this.loadingService.hideLoading()
							// this.error = true							
						}
					},
					error => {
						this.loadingService.hideLoading()
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
			} else {
				this.loadingService.hideLoading()
				this.error = true
				console.log('error 6')
			}
		} else {
			this.loadingService.hideLoading();
			this.error = true
			console.log('error 7')
		}
	}

	ngOnInit() {

	}

	getColSize() {
		return 'col-sm-' + Math.floor(12 / this.size)
	}

	getStyle() {
		if (this.show) {
			return "block"
		}
		else {
			return "none"
		}
	}

	getSignature(index: any) {
		if (index == 1) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(this.signature1)
		} else if (index == 2) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(this.signature2)
		} else if (index == 3) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(this.signature3)
		}
	}

	getTaskName(taskName: any) {
		var correctTaskName = taskName.replace(/([a-z])([A-Z])/g, '$1 $2').replace('Endose', 'Endorse');
		return correctTaskName
	}

	IsSignatureAdded(index: any) {
		var found: boolean = false
		this.approvalData.forEach(item => {
			let itemNo = item.fieldName.substring(item.fieldName.length - 1)
			if (index == itemNo && item.fieldValue) {
				found = true
			}
		})
		return found
	}

	createImageFromBlob(image: Blob, index: any) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			if (index == 1) {
				this.signature1 = reader.result;
			} else if (index == 2) {
				this.signature2 = reader.result;
			} else if (index == 3) {
				this.signature3 = reader.result;
			}
		}, false);

		if (image) {
			reader.readAsDataURL(image);
		}
	}


	setConfigData() {
		const parser = new DOMParser();
		const xml = parser.parseFromString(this.workflowModel.configData, 'text/xml');
		let result: any = this.ngxXml2jsonService.xmlToJson(xml);
		if (this.mode == 'STN') {
			this.ch1 = result.ConfigData.AllChecksheetsCompleted == 'true' ? true : false
			this.ch2 = result.ConfigData.AllPunchlistItemsCleared == 'true' ? true : false
			this.ch3 = result.ConfigData.TurnoverCompleted == 'true' ? true : false
			this.ch4 = result.ConfigData.EnteredToSCDb == 'true' ? true : false
			this.ch5 = result.ConfigData.TCPCompleted == 'true' ? true : false
			this.ch6 = result.ConfigData.DrawingsIssued == 'true' ? true : false
			this.ch7 = result.ConfigData.AllPreservationReqCompleted == 'true' ? true : false
			this.ch8 = result.ConfigData.SiteComInformationCleared == 'true' ? true : false
			this.ch9 = result.ConfigData.NCRActionsClosed == 'true' ? true : false
			this.ch10 = result.ConfigData.VendorReportsClosed == 'true' ? true : false
			this.ch11 = result.ConfigData.AllCertificationCompleted == 'true' ? true : false
			this.ch12 = result.ConfigData.RegularityApprovalsObtained == 'true' ? true : false
			this.ch13 = result.ConfigData.SAPCMMSModule == 'true' ? true : false
			this.ch14 = result.ConfigData.SystemFlushed == 'true' ? true : false
			this.ch15 = result.ConfigData.TempIsolationsRemoved == 'true' ? true : false
			this.ch16 = result.ConfigData.AreasCleanSafe == 'true' ? true : false
			this.ch17 = result.ConfigData.PermitsCancelled == 'true' ? true : false
			this.ch18 = result.ConfigData.SystemSafe == 'true' ? true : false
			this.chRemItems = result.ConfigData.PlanApprovedForRemItems == 'true' ? true : false

			if (result.ConfigData.Comments && JSON.stringify({}) != JSON.stringify(result.ConfigData.Comments)) {
				this.comments = result.ConfigData.Comments
			} else {
				this.comments = ''
			}
		} else if (this.mode == 'MCC') {
			console.log(result)
			this.ch1 = result.ConfigData.AllMcCheckSheet == 'true' ? true : false
			this.ch2 = result.ConfigData.AllAPunchList == 'true' ? true : false
			this.ch3 = result.ConfigData.AllComponentsAssembled == 'true' ? true : false
			this.ch4 = result.ConfigData.AcceptanceCompleted == 'true' ? true : false
			this.ch5 = result.ConfigData.ReleaseNoticeIssued == 'true' ? true : false
			this.ch6 = result.ConfigData.AllDocInclude == 'true' ? true : false
			this.ch7 = result.ConfigData.AllRemPunchList == 'true' ? true : false
			this.ch8 = result.ConfigData.CareProcessApproved == 'true' ? true : false
			this.ch9 = result.ConfigData.TCPDocCompleted == 'true' ? true : false
			this.chRemItems = result.ConfigData.PlanApprovedForRemItems == 'true' ? true : false
		} else if (this.mode == 'PCC') {
			console.log(result)
			this.ch1 = result.ConfigData.AllTestCompleted == 'true' ? true : false
			this.ch2 = result.ConfigData.PreshipmentCompleted == 'true' ? true : false
			this.ch3 = result.ConfigData.SiteCommisisoningCompleted == 'true' ? true : false
			this.ch4 = result.ConfigData.AllDocCompleted == 'true' ? true : false
			this.ch5 = result.ConfigData.AllPreCommissioningCheckSheetCompleted == 'true' ? true : false
			this.ch6 = result.ConfigData.AllStaticCommissioningCheckSheetCompleted == 'true' ? true : false
			this.ch7 = result.ConfigData.EnteredToSCDb == 'true' ? true : false
			this.ch8 = result.ConfigData.CareProcessApproved == 'true' ? true : false
			this.ch9 = result.ConfigData.TCPDocCompleted == 'true' ? true : false
			this.chRemItems = result.ConfigData.PlanApprovedForRemItems == 'true' ? true : false
		} else if (this.mode == 'CCC') {
			this.ch1 = result.ConfigData.AllTestCompleted == 'true' ? true : false
			this.ch2 = result.ConfigData.AllCheckSheetCompleted == 'true' ? true : false
			this.ch3 = result.ConfigData.CommissioningCompleted == 'true' ? true : false
			this.ch4 = result.ConfigData.DocIncluded == 'true' ? true : false
			this.ch5 = result.ConfigData.EnteredToSCDb == 'true' ? true : false
			this.ch6 = result.ConfigData.TCPDocCompleted == 'true' ? true : false
			this.chRemItems = result.ConfigData.PlanApprovedForRemItems == 'true' ? true : false
			console.log(result.ConfigData.CCCProcedures)
			this.procedureList = []
			for (var key in result.ConfigData.CCCProcedures) {
				console.log(key)
				let procedureItem = result.ConfigData.CCCProcedures[key]
				console.log(procedureItem.length)
				if (procedureItem.length) {
					for (let i = 0; i < procedureItem.length; i++) {
						let procItem = procedureItem[i]
						if (this.getActualContent(procItem.Date)) {
							let tempDate = new Date(procItem.Date)							
							procItem.Date = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate()).toLocaleDateString()
						}
						this.procedureList.push(procItem)
					}
				} else {
					let procItem = result.ConfigData.CCCProcedures[key]
					if (this.getActualContent(procItem.Date)) {
						let tempDate = new Date(procItem.Date)
						procItem.Date = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate()).toLocaleDateString()
					}
					this.procedureList.push(procItem)
				}
			}
			if (result.ConfigData.ItemList && JSON.stringify({}) != JSON.stringify(result.ConfigData.ItemList)) {
				this.comments = result.ConfigData.ItemList
			} else {
				this.comments = ''
			}
		}
		this.show = true
		setTimeout(() => {
			let w = this.changeSize(this.container1)
			this.signaturePad1.set('canvasWidth', w);
			this.signaturePad1.set('canvasHeight', 120);  // set szimek/signature_pad options at runtime
			this.signaturePad1.clear(); // invoke functions from szimek/signature_pad API
		});

	}

	getActualContent(value) {
		let result = '';
		if (value && JSON.stringify({}) != JSON.stringify(value)) {
			result = value
		}
		return result
	}

	ngAfterViewInit() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.

	}

	drawComplete() {
		// will be notified of szimek/signature_pad's onEnd event
		this.signature = this.signaturePad1.toDataURL()
	}

	submit() {
		if (this.mode == 'STN') {
			this.workflowApprovalService.approveSTNWorkflow(this.systemTurnoverNotice.stnId, this.taskId, this.signature)
				.subscribe(
				data => {
					this.approved = true
					this.show = false
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
		} else if (this.mode == 'MCC') {
			this.workflowApprovalService.approveMCCWorkflow(this.mechanicalCompletion.mcId, this.taskId, this.signature)
				.subscribe(
				data => {
					this.approved = true
					this.show = false
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
		} else if (this.mode == 'PCC') {
			this.workflowApprovalService.approvePCCWorkflow(this.precommissioningCompletion.pcId, this.taskId, this.signature)
				.subscribe(
				data => {
					this.approved = true
					this.show = false
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
		} else if (this.mode == 'CCC') {
			this.workflowApprovalService.approveCCCWorkflow(this.commCompletion.ccid, this.taskId, this.signature)
				.subscribe(
				data => {
					this.approved = true
					this.show = false
				},
				error => {
					this.alertService.clear()
					this.alertService.error(error.statusDescription)
				});
		}
	}

	drawStart() {
		// will be notified of szimek/signature_pad's onBegin event		
	}

	public clearSignature() {
		this.signaturePad1.clear();
		this.signature = null;
	}

	public changeSize(container: ElementRef): number {
		let width = container.nativeElement.clientWidth - 48;
		return width
	}

	public gotoSignatureSection() {
		this.signatureSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
	}

}
