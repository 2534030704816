import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../../util/common/user-variable';
import { ServiceUrls } from '../../util/service-urls';
import { WsResponse } from '../../util/ws-response.model';

@Injectable()
export class FacilityAcceptanceService {

	constructor(private http: HttpClient) {
	}

	getAllFAs(): Observable<WsResponse> {
		console.log("FacilityAcceptanceService.getAllFAs() == > ServiceURL= " + ServiceUrls.GET_FAS)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		return this.http.get(ServiceUrls.GET_FAS, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	downloadFile(projectId: string, docId: string, fileName: string, type: string, mode: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
			responseType: "blob", params: new HttpParams().set("projectId", projectId).
				set("documentId", docId).set("fileName", fileName).set("type", type).set("mode", mode)
		});
	}

	DownloadAllZip(projectId: string, mode: string) {
		return this.http.get(ServiceUrls.DOWNLOAD_FILE, {
			responseType: "blob", params: new HttpParams().set("projectId", projectId).
				set("mode", mode)
		});
	}
}