import { Component } from "@angular/core";

@Component({
	selector: 'child-cell',
	template: ` <div class="ag-header-group-cell-label">
            <div class="customHeaderLabel">{{this.params.displayName}}</div>
            <div (click)="downloadAll()">&nbsp;&nbsp;({{this.params.content}})</div>
        </div>`

})
export class DownloadAllIconRenderer {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}
	
	public downloadAll() {	
		console.log(this.params.type)	
		this.params.context.componentParent.downloadAll(this.params.type)
	}
	
}