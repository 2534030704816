import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'editor-cell',
    template: `
        <div #container class="mood" tabindex="0" (keydown)="onKeyDown($event)">
        <div #container class="moodComplete" (click)="onClick('Complete')">
         <label  
        [ngClass]="{'selected' : complete, 'default' : !complete}">Complete</label>
        </div>
        <div #container class="moodCompletePL" (click)="onClick('CompleteWithPL')">
        <label
        [ngClass]="{'selected' : completePL, 'default' : !completePL}">Complete With  P/L</label>
        </div>  
        <div #container class="moodOutstanding" (click)="onClick('Outstanding')">
        <label
        [ngClass]="{'selected' : outstanding, 'default' : !outstanding}">Outstanding</label>
        </div>    
        <div #container class="moodNA" (click)="onClick('NotApp')">
        <label
        [ngClass]="{'selected' : notApplicable, 'default' : !notApplicable}">N/A</label>
        </div>    
        </div>
    `,
    styles: [`
        .mood {
            border-radius: 15px;
            border: 1px solid grey;
            background: #e6e6e6;
            padding: 15px;
            text-align: left;
            display: inline-block;
            outline: none
        }

        .moodComplete{
            background: #388E3C;
        }

        .moodCompletePL{
            background: #FBC02D;
        }

        .moodOutstanding{
            background: #D32F2F;
        }

        .moodNA{
            background: #757575;
        }

        .default {
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid transparent;
            padding: 4px;
        }

        .selected {
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid transparent;
            padding: 4px;
        }
    `]
})
export class StatusEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    @ViewChild('container', {read: ViewContainerRef}) public container;
    //public happy: boolean = false;
    public complete: boolean = false;
    public completePL: boolean = false;
    public outstanding: boolean = false;
    public notApplicable: boolean = false;
    public status:string ="";

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        window.setTimeout(() => {
            this.container.element.nativeElement.focus();
        })
    }

    agInit(params: any): void {
        this.params = params;
        //this.setHappy(params.value === "Happy");
        this.setStatus(params.value);
    }

    getValue(): any {
        //return this.happy ? "Happy" : "Sad";
        if(this.complete){
            return "Complete";
        }
        else if(this.completePL){
            return "CompleteWithPL";
        }
        else if(this.outstanding){
            return "Outstanding";
        }
        else if(this.notApplicable){
            return "NotApp";
        }
    }

    isPopup(): boolean {
        return true;
    }

    setHappy(happy: boolean): void {
        //this.happy = happy;
    }

    setStatus(status:string){
        this.status = status;
        if(status === 'Complete')
        {
            this.complete = true;
            this.completePL = false;
            this.outstanding = false;
            this.notApplicable = false;
        }
        else if(status === 'CompleteWithPL'){
            this.completePL = true;
            this.complete = false;
            this.outstanding = false;
            this.notApplicable = false;
        }
        else if(status === 'Outstanding'){
            this.complete = false;
            this.completePL = false;
            this.outstanding = true;
            this.notApplicable = false;
        }
        else if(status === 'NotApp'){
            this.complete = false;
            this.completePL = false;
            this.outstanding = false;
            this.notApplicable = true;
        }
    }

    toggleMood(): void {
        //this.setHappy(!this.happy);
    }

    /*onClick(happy: boolean) {
        this.setHappy(happy);
        this.params.api.stopEditing();
    }*/

    onClick(status: string) {
        this.setStatus(status);
        this.params.api.stopEditing();
    }

    onKeyDown(event): void {
        let key = event.which || event.keyCode;
        if (key == 37 ||  // left
            key == 39) {  // right
            this.toggleMood();
            event.stopPropagation();
        }
    }
}