import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button *ngIf="show()" (click)="goToLocation()" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="fas fa-map-marker-alt mx-2" aria-hidden="true"></i>' +
	'</button>'

})
export class PunchListLocationIconRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	show() {
		if (this.params.data) {
			if (this.params.data.location) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}

	goToLocation() {
		window.open("https://www.google.com/maps/search/?api=1&query=" + this.params.data.location, "_blank")
	}

	public downloadImage() {
		this.params.context.componentParent.downloadImage(this.params)
	}

	refresh(): boolean {
		return false;
	}
}