import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorage } from './localstorage.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { UserVariable } from '../util/common/user-variable';
import { ServiceUrls } from '../util/service-urls';
import { WsResponse } from '../util/ws-response.model';

@Injectable({
	providedIn: 'root'
})
export class CapabilityService {

	constructor(private http: HttpClient) {
	}

	getAllCapabilityList(): Observable<WsResponse> {
		console.log("Auth.getAllCapabilityList() == > ServiceURL= " + ServiceUrls.GET_CAPABILITY_LIST)
		let token = UserVariable.getUserToken();
		const options = { params: new HttpParams().set("token", token) }
		console.log("proj token " + token)
		return this.http.get(ServiceUrls.GET_CAPABILITY_LIST, options).
			pipe(
			map(response => {
				var modified = JSON.parse(JSON.stringify(response));
				return new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
			}),
			catchError(error => {
				var modified = JSON.parse(JSON.stringify(error.error));
				var res = new WsResponse(
					modified.status.description,
					modified.status.code,
					modified.status.name,
					modified.payload);
				return throwError(res)
			})
			);
	}

	isCapabilityAssigned(capability: string): boolean {
		let found: boolean = false;
		if (localStorage.getItem(LocalStorage.CAPABILITY_LIST) === null) {
			//do nothing
		} else {
			let capabilityList: any[] = JSON.parse(localStorage.getItem(LocalStorage.CAPABILITY_LIST));
			for (let capabilityItem of capabilityList) {
				if (capabilityItem == capability) {
					found = true;
				}
			}
		}
		return found;
	}

}