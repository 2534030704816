import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
	selector: 'app-samplepiechart',
	templateUrl: './samplepiechart.component.html',
	styleUrls: ['./samplepiechart.component.css']
})
export class SamplepiechartComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	chart = new Chart({
		chart: {
			type: 'pie'
		},
		title: {
			text: 'Pie chart'
		},
		credits: {
			enabled: false
		},
		series: [
			{
				name: 'pie 1',
				data: [1, 2, 3]
			}
		]
	});

	// add point to chart serie
	add() {
		this.chart.addPoint(Math.floor(Math.random() * 10));
	}

}
