import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
   selector: 'child-cell',
   template: `
         <div>
            <div *ngIf="selectedFacilityList.length != undefined">
               <div *ngFor="let facility of selectedFacilityList" style="float:left">
                     <a>{{facility.facilityName}}</a>
               </div>
            </div>
         </div>`,
   styles: [`
          a {
         display: inline-block;
         padding: .4em .5em;
         margin: 2px 2px 2px 0;
         line-height: 1;
         white-space: nowrap;
         text-decoration: none;
         text-align: center;
         border-width: 1px;
         border-style: solid;
         border-radius: 10px;
         border-color: #efefef;
         background-color: #efefef
      }`]

})

export class FacilitiesCellRender implements ICellRendererAngularComp {
   public params: any;
   public selectedFacilityList = [];
   
   constructor()  { }

   agInit(params: any): void {
      this.params = params;
      this.processSelectedFacilityList();
   }   

   //convert id:facilityName pair to facility object
   processSelectedFacilityList(){
      var facilities = this.params.data.facilities;
      for (var key in facilities) {
         var facility = {
            'facilityId': key,
            'facilityName': facilities[key]
         }
         this.selectedFacilityList.push(facility);
      }
   }

   refresh(): boolean {
      return false;
   }

}