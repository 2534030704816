<div class="panel panel-default">
    <div class="panel-heading box-shadow panel-header-border-bottom py-1">
        <div class="row px-2">
            <div class="col-sm-12 px-0">
                <div class="float-left pt-1 px-1">
                    <p class="m-0 pl-1">
                        <!-- translated widget name -->
                        {{ "OPEN_PUNCHLIST.WIDGET_NAME" | translate }}
                    </p>
                </div>
                <div class="float-right pt-1 px-0">
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="isMinimized = !isMinimized">
                        <i class="fa " [ngClass]="{'fa-chevron-down': isMinimized, 'fa-chevron-up': !isMinimized}"
                            aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="refresh()">
                        <i class="fas fa-sync-alt" aria-hidden="true"></i>
                    </button>
                    <!-- other buttons like config button -->
                    <button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor border-0"
                        (click)="showFilterOptions = !showFilterOptions">
                        <i class="fas fa-cog" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="card-block card-body mt-2 pb-1 pt-1 card-primary" [ngClass]="{'content-hide': isMinimized, 'content-show': !isMinimized}">
        <!-- body content -->
        <div class="row" *ngIf="showFilterOptions">
            <div class="col-sm-12 my-2">
                <div class="row px-2">
                    <div class="form-group col-sm-9">
                        <label>{{ "OPEN_PUNCHLIST.SYSTEM" | translate }} :</label>
                        <ng-select [active]="selectedSystem" [allowClear]="true" (removed)="removedSystem($event)"
                            (selected)="selectedSystemId($event)" [items]="systemList" placeholder="{{ 'PUNCHLIST_RUNDOWN.SELECT_SYSTEM' | translate }}"
                            name="system" id="system">
                        </ng-select>
                    </div>

                    <div class="form-group col-sm-3">
                        <label class="container_checkbox" style="position: relative; top: 36px;">
                            <input [(ngModel)]="showSubSysem" type="checkbox" id="subSystemCheckbox" (change)="checkboxFunction($event)">
                            <span class="checkmark rounded" style="border-color: #ced4da"></span>
                        </label>
                        <label class="ml-2" style="position: relative; top: 22px; left: 16px">{{
                            'OPEN_PUNCHLIST.SUB_SYSTEM' | translate }}</label>
                    </div>
                </div>
            </div>
        </div>

        <!-- loading panel -->
        <div *ngIf="isLoadingData" class="row">
            <div class="alert alert-warning mx-auto px-3 py-1">
                <h6 class="mb-0">{{ "GENERAL.LOADING_DATA" | translate }}</h6>
            </div>
        </div>
        <!-- no data panel -->
        <div *ngIf="isNoDataToShow" class="row">
            <div class="alert alert-light-nodata mx-auto px-2 py-1 border-0">
                <h6 class="mb-0">{{ "GENERAL.NO_DATA_TO_SHOW" | translate }}</h6>
            </div>
        </div>

        <div *ngIf="!isLoadingData" class="my-2" [chart]="chart" [ngClass]="{'content-hide': isNoDataToShow, 'content-show': !isNoDataToShow}"></div>
    </div>
</div>