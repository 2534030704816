import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<button *ngIf="show()" (click)="startWorkflow()" id="start-workflow-event_startWorkflowBtn" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0">' +
	'<i class="fa fa-play mx-2" aria-hidden="true"></i>' +
	'</button>'

})
export class CommCompletionStartWorkflowRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;		
	}

	public show() {
		return this.params.value
	}

	public startWorkflow() {
		this.params.context.componentParent.iconClicked = true
		this.params.context.componentParent.startWorkflow(this.params)
	}

	refresh(): boolean {
		return false;
	}
}