import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    template: `<div *ngFor="let irn of this.irnList"><a [routerLink]="['/dashboard']" *ngIf="show(irn)" (click)="!isDisabled(irn) && download(irn)">{{irn.irnNumber}} </a></div>`
})
export class IRNRenderer implements ICellRendererAngularComp {    
    params: any; 
    irnList: any[] = [];
 
    agInit(params: any): void {
        this.params = params;
        if(this.params.data){
            if(this.params.data.irn && this.params.data.irn.length > 0){
                this.irnList = this.params.data.irn;
            }else{
            }
        }
    }
 
    /**
     * Show IRN Number if exists
     * @param irn 
     */
    public show(irn: any): boolean {
        if(irn){
            if (irn.irnNumber != null || irn.irnNumber != undefined) {
                return true
            } else {
                return false
            }
        }else{
            return false
        }
    }
 
    /**
     * Disable download if IRN document is not exist
     * @param irn 
     */
    public isDisabled(irn: any): boolean{
        if(irn){
            if(irn.isUploaded != null){
                return false
            } else{
                return true
            }
        }else{
            return true
        }
    }

    refresh(params: any): boolean {
        return false;
    }    
 
    /**
     * Download IRN document
     * @param irn 
     */
    public download(irn: any){
        this.params.context.componentParent.downloadIRNFile(irn);
    }
}
