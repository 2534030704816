import { Component, OnInit } from '@angular/core';
import { ProceduresExecutedCountWidgetService } from './procedures-executed-count-widget.service';
import { LoggedUser } from '../../user/logged-user.model';
import { LocalStorage } from '../../util/localstorage.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { AlertService } from '../../util/alert/alert.service';
import * as FileSaver from 'file-saver';
import { UserVariable } from '../../util/common/user-variable';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../util/shared.service';

@Component({
    selector: 'app-procedures-executed-count-widget',
    templateUrl: './procedures-executed-count-widget.component.html',
    styleUrls: ['./procedures-executed-count-widget.component.css']
})
export class ProceduresExecutedCountWidgetComponent implements OnInit {

    public isMinimized: boolean = false
	public isHeader: boolean = false
	public isLoadingData: boolean = true
	public executedCount: number
	private projectId: any

    constructor(
        private proceduresExecutedCountWidgetService: ProceduresExecutedCountWidgetService,
		private translate: TranslateService,
		private alertService: AlertService,
		private sharedService: SharedService) { }

    ngOnInit() {
		this.isLoadingData = true
		this.projectId = localStorage.getItem(LocalStorage.PROJECT_ID)
        this.proceduresExecutedCountWidgetService.getCountOfExecutedProcedures(this.projectId).subscribe(
			data => {
				//console.log('[ngOnInit] (data) ' + JSON.stringify(data));
				if (data.payload != null) {
					this.executedCount = data.payload;
					this.isLoadingData = false
				} else {
					this.isLoadingData = false
					//do nothing
				}
			},
			error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.isLoadingData = false
			});
    }

}
