import { Component, OnInit, ViewEncapsulation, ViewChild, Input, TemplateRef } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap';
import { Router, NavigationExtras } from '@angular/router';
import { SharedService } from '../util/shared.service';
import { LocalStorage } from '../util/localstorage.service';
import { LoggedUser } from '../user/logged-user.model';
import { HeaderService } from './header.service';
import { AlertService } from '../util/alert/alert.service';
import { WsCallback } from '../util/ws-callback.interface';
import { WsResponse } from '../util/ws-response.model';
import { WsType } from '../util/ws-type';
import { LocalStorageConst } from '../util/constants'
import { TranslateService } from '@ngx-translate/core';
import { UserVariable } from '../util/common/user-variable';
import { BsModalService } from 'ngx-bootstrap/modal';
declare var require: any;
const { version: appVersion } = require('../../../package.json');

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    encapsulation: ViewEncapsulation.None,
    inputs: ['showHamburger', 'projectName', 'showSetDefault']
})

export class HeaderComponent implements OnInit, WsCallback {

    //input values
    public showHamburger: boolean = true
    public base64Data: string = "";
    public showSetDefault: boolean = true
    appVersion = "";
    public notificationCount: number = 0;
    public isToggleMenu: boolean = false
    private userFullName: String = "";
    private loggedInUser: LoggedUser;
    public firstLetter: String = "";
    public isDefaultProject: number;
    public setDefault

    public clientId: string = localStorage.getItem(LocalStorage.CLIENT_ID)
    public projectType: any = localStorage.getItem(LocalStorage.PROJECT_TYPE);
    public projectId: string = localStorage.getItem(LocalStorage.PROJECT_ID)
    public defaultProjectId: string = localStorage.getItem(LocalStorage.DEFAULT_PROJECT_ID);
    public projectName: string = localStorage.getItem(LocalStorage.PROJECT_NAME)
    public setDefaultBtnText: string = ''
    public defaultBtnIcon: boolean = true;
    public serverVersion: String = ''
    data: any;
    interval: any;
    isOpen = false;
    previousePreference: string;
    bsModalRef: any;
    currentPreference: string;

    constructor(private router: Router, private sharedService: SharedService,
        private headerService: HeaderService, private alertService: AlertService, private translate: TranslateService,
        private modalService: BsModalService) {
        // console.log('[HeaderComponent] (defaultProjectId) ' + this.defaultProjectId);
        // console.log('[HeaderComponent] (projectId) ' + this.projectId);
        this.appVersion = appVersion
        if (this.defaultProjectId == this.projectId && this.defaultProjectId) {
            this.translate.get('MENU.REMOVE').subscribe((res: string) => {
                this.setDefaultBtnText = res;
                this.defaultBtnIcon = false
            });
        } else {
            this.translate.get('MENU.DEFAULT').subscribe((res: string) => {
                this.setDefaultBtnText = res;
                this.defaultBtnIcon = true
            });
        }

        this.headerService.getServerVersion()
        .subscribe(data => {
            this.serverVersion = data.payload;
        
        })
        
    }

    ngOnInit() {
        this.loggedInUser = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
        // this.projectName = JSON.parse(localStorage.getItem(LocalStorage.PROJECT_NAME));
        this.userFullName = this.loggedInUser.firstName + " " + this.loggedInUser.lastName;
        this.firstLetter = this.loggedInUser.firstName.charAt(0) + this.loggedInUser.lastName.charAt(0);
        this.getNotificationCount();
        this.clientLogo();
        // this.refreshData();
        this.interval = setInterval(() => {
            this.getNotificationCount();
        }, 300000);
    }
    ngAfterContentInit() {

        this.sharedService.tnExecute().subscribe(data => this.notificationToggle()) 

        
    }

    // refreshData() {
    //   //  this.notificationCount = JSON.parse(localStorage.getItem(LocalStorage.NOTIFICATION_COUNT));
    //   this.getNotificationCount();
    //     // this.headerService.getNotificationCount()
    //     //     .subscribe(data => {
    //     //         this.notificationCount = data.payload;
            
    //     //     })
    // }

    public notificationToggle(){
        this.isOpen = false;
        this.getNotificationCount();
        //this.refreshData();
    }

    public signOutBtnClickEvent(): void {
        // this.router.navigateByUrl('/login')
        // localStorage.removeItem(LocalStorage.LOGGED_USER);
        // localStorage.removeItem(LocalStorage.IS_CLIENT);
        // localStorage.removeItem(LocalStorage.USER_ID);
        // localStorage.removeItem(LocalStorage.PROJECT_ID);
        // localStorage.removeItem(LocalStorage.PROJECT_NAME);
        // localStorage.removeItem(LocalStorage.CLIENT_ID);
        localStorage.clear();
        this.router.navigateByUrl('/login');
    }

    public changeProject(): void {
        localStorage.setItem(LocalStorage.OLD_PROJECT_ID, this.projectId)
        localStorage.setItem(LocalStorage.OLD_PROJECT_NAME, this.projectName)
        localStorage.setItem(LocalStorage.OLD_CLIENT_ID, this.clientId)
        localStorage.setItem(LocalStorage.CURRENT_PROJECT_ID, this.projectId)

        localStorage.removeItem(LocalStorage.PROJECT_ID);
        localStorage.removeItem(LocalStorage.PROJECT_NAME);
        localStorage.removeItem(LocalStorage.CLIENT_ID);

        let navigationExtras: NavigationExtras = {
            queryParams: {
                'option': 'change_project'
            }
        }
        this.router.navigate(['landing'], navigationExtras)
        // console.log("changed")
    }

    public setasDefault(option: boolean): void {
        // console.log("[setasDefault] (option) " + option);
        // console.log("setasDefault" + localStorage.getItem(LocalStorage.PROJECT_ID));
        if (option) {
            //set default project
            let request = { projectId: this.projectId, isDefaultProject: 1, projectType: '3', userId: this.loggedInUser.userId };
            this.headerService.setDefaultRecentProjects(JSON.stringify(request), this);
        } else {
            //remove default project
            let request2 = { projectId: this.projectId, userId: this.loggedInUser.userId, projectType: '3' };
            this.headerService.deleteUserDefaultRecentProject(JSON.stringify(request2), this);
        }
    }


    public showSlideBar() {
        console.log('[showSlideBar]');
        this.sharedService.csbvRequest()
    }

    public showNotification() {
        this.notificationCount;
    }

    public getNotificationCount() {
        this.headerService.getNotificationCount().subscribe(
            data => {
                if (data.payload) {
                    this.notificationCount = data.payload;
                    //localStorage.setItem(LocalStorage.NOTIFICATION_COUNT, data.payload);
                } else {
                    //do nothing
                    this.notificationCount = 0
                }
            },
            error => {
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            });

    }

    public clientLogo(){
        this.headerService.getClientLogo().subscribe(
            data => {
                if (data.payload) {
                    this.base64Data = data.payload;
                    // localStorage.setItem(LocalStorage.NOTIFICATION_COUNT, data.payload);
                } else {
                    //do nothing
                }
            },
            error => {
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            });

    }


    public changeNotificationNumber() {
     
    }

    public showToggleMenu() {
        this.isToggleMenu = !this.isToggleMenu
    }

    onSuccess(data: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
            if (data.payload) {
                this.alertService.success(data.statusDescription)
                this.translate.get('MENU.REMOVE').subscribe((res: string) => {
                    this.setDefaultBtnText = res;
                    this.defaultBtnIcon = false
                });
                localStorage.setItem(LocalStorage.DEFAULT_PROJECT_ID, UserVariable.projectId);
            }
        } else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
            if (data.payload) {
                this.alertService.success(data.statusDescription)
                this.translate.get('MENU.DEFAULT').subscribe((res: string) => {
                    this.setDefaultBtnText = res;
                    this.defaultBtnIcon = true
                });
                localStorage.removeItem(LocalStorage.DEFAULT_PROJECT_ID)
            }
        }
    }
    onFail(data: WsResponse, serviceType: WsType): void {
        if (serviceType == WsType.SET_DEFAULT_RECENT_PROJECT) {
            this.alertService.error(data.statusDescription)
        } else if (serviceType == WsType.DELETE_USER_DEFAULT_RECENT_PROJECT) {
            this.alertService.error(data.statusDescription)
        }
    }

    openModal(template: TemplateRef<any>, size: string) {
		this.previousePreference = localStorage.getItem('preferences');
		if (size == 'lg') {
			this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
		} else {
			this.bsModalRef = this.modalService.show(template);
		}
    }
    
    cancel() {
		this.bsModalRef.hide();
		localStorage.setItem(LocalStorage.PREFERENCES, this.previousePreference);
    }
    
	save() {
		this.bsModalRef.hide();
		this.currentPreference = localStorage.getItem('preferences');
		let out = JSON.parse(localStorage.getItem('preferences'));
		this.headerService.updateUserPreferences(out).subscribe(
			data => {
				// console.log(data);
			}
		)

	}
}
