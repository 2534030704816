import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
	selector: 'child-cell',
	template: '<div *ngFor="let image of this.images"><button *ngIf="show()" (click)="downloadImage(image)" class="btn btn-sm btn-outline-dark py-0 my-1 px-0 border-0" >' +
	'<i class="fas fa-file-image mx-2" aria-hidden="true"></i>' +
	'</button> {{image.fileName}}</div>'

})
export class PunchListImageIconRenderer implements ICellRendererAngularComp {
	public params: any;
	public images: any[] = [];

	agInit(params: any): void {
		this.params = params;
		this.images = this.params.data.images;
	}

	show() {
		if(this.params.data.images != null){
			return true;
		}
		else{
			return false;
		}
	}

	public downloadImage(image:any) {
		this.params.context.componentParent.downloadImage(image)
	}

	refresh(): boolean {
		return false;
	}
}